import React, { useCallback, useState } from "react";
import { Modal, Button, Spin } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { UploadKtpStyle } from "./style";
import { uploadNpwpBusinessKoinVoice } from "../../stores/actions/koinvoiceLoan";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const allowedFiles = ["image/png", "image/jpeg", "image/jpg"];

function UploadNpwpModal({
  visible,
  handleChangeShowUploadNpwp,
  uploadNpwpBusinessKoinVoice,
  init: { language },
  koinvoice: { upload, uploadNpwpError },
  auth,
  setFieldValue,
  setPathNpwp,
  businessInformation
}) {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      actionChangeSelfieKtpUpload(file, setFieldValue);
      setPathNpwp(file.path);
    });
  });

  function actionChangeSelfieKtpUpload(values) {
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email:
          auth.email ||
          (businessInformation.data && businessInformation.data.email),
      };
      uploadNpwpBusinessKoinVoice(
        params,
        setFieldValue,
        setIsLoading,
        handleChangeShowUploadNpwp
      );
    };
    fileReader.readAsDataURL(values);
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    onDrop: onDrop,
    accept: allowedFiles,
  });

  let validationImage = require("../../assets/images/applyloan/image-npwp.jpg").default;
  let validationHeading = language.modalUpload.headingNpwp;
  let validationParagraph = language.modalUpload.paragraphNpwp1;
  let validationButtonUpload = language.modalUpload.uploadButton;

  let buttonUploadEndpoint = (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Spin spinning={isLoading}>
        <Button type="button" className="kw-button" onClick={open}>
          {validationButtonUpload}
        </Button>
      </Spin>
    </div>
  );

  return (
    <Modal visible={visible} onCancel={handleChangeShowUploadNpwp} footer={null}>
      <UploadKtpStyle>
        <div className="kw-uploadKtp">
          <img
            src={validationImage}
            style={{ width: 350, margin: "auto" }}
            alt=""
          />
          <h5>{validationHeading}</h5>
          <p>{validationParagraph}</p>
          <React.Fragment>
            <p>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ marginRight: 10 }}
              />
              {language.modalUpload.paragraphNpwp2}
            </p>
            <p>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ marginRight: 10 }}
              />
              {language.modalUpload.paragraphNpwp3}
            </p>
          </React.Fragment>
          
          {buttonUploadEndpoint}
          {(uploadNpwpError === undefined || uploadNpwpError) && (
            <Button
              type="button"
              style={{ marginTop: 15 }}
              onClick={handleChangeShowUploadNpwp}
            >
              {language.modalUpload.uploadButtonClose}
            </Button>
          )}
        </div>
      </UploadKtpStyle>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  auth: state.auth,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ uploadNpwpBusinessKoinVoice }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadNpwpModal);
