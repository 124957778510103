import React, { Component } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

import Steps from 'rc-steps';

import ldlnorepayment from "../../../assets/images/dashboard/ldpnorepayment.png"


class LDSchedule extends Component {

  getCurrentSchedule = data => {
    return data.findIndex(data =>(data.amount === null));
  }

  getStatusPaymentDate = status => {
    switch(status) {
      case "early_installment_repayment":
        return "On time";
      case "late_but_paid":
        return "Late, Paid";
      case "paid":
        return "Paid";
      default:
        return "Unpaid";
    }
  }

  render() {
    let { init: { language }, loan } = this.props;
    let loanSchedule = loan.loanSchedule;
    // const LDAmount = ({ type }) => <i className={`icon-${type}`} />;
    // const LDStatus = ({ type }) => <i className={`icon-${type}`} />;
    // const LDBadge = ({ type }) => <i className={`icon-${type}`} />;
    return (
      <>
        <div className="ldcomp">
          <div className="heading">
            <div className="title">{language.label.schedule}</div>
            <div className="text">
              {loanSchedule ? this.getCurrentSchedule(loanSchedule.schedule) : 0}/
              {loanSchedule ? loanSchedule.schedule.length : 0 } &nbsp;
              {language.text.installmentCompleted}
            </div>
          </div>

          {/* notfound */}
          {loan.isGetLoanScheduleFailed && (
            <div className="ldlnotfound">
              <figure className="modal-img mb-4">
                <img src={ldlnorepayment} alt="success" />
              </figure>
              <h3 className="title">No repayment due yet</h3>
              <p className="text w-75">Your repayment will show up here.</p>
            </div>
          )}

          {loanSchedule && (
            <div className="ldstep">
              <Steps current={this.getCurrentSchedule(loanSchedule.schedule)} direction="vertical">
                {loanSchedule.schedule.map(data => (
                  <Steps.Step
                    key={data.id}
                    title={`Installment ${data.installment_number}`}
                    subTitle={data.amount ? 
                      new Date(data.amount[0].attempted_at).toLocaleDateString("ID-id",
                      {year: 'numeric', month: 'short', day: 'numeric'}) : ''
                    }
                    description={
                      <>
                        <p>
                          <span>IDR &nbsp;
                            <NumberFormat
                              value={data.amount ? data.amount[0].amount : data.total_due}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalSeparator="."
                              decimalScale={3}
                            /> &nbsp;
                          </span>  
                          {data.amount && (
                            <span>&#8226; {this.getStatusPaymentDate(data.amount[0].status)}</span>
                          )}
                        </p>
                        {/* <div className="badge badge-secondary">paid</div> */}
                      </>
                    }
                  />
                ))}
                
                {/* <Steps.Step
                  title="Under Review"
                  subTitle="26 Apr 2019"
                  description={
                    <>
                      <p><span>IDR 10,100,000</span> &#8226; <span>On time</span></p>
                      <div className="badge badge-success">pending clearance</div>
                    </>
                  }
                />
                <Steps.Step
                  title="Choose Offer"
                  description={
                    <>
                      <p><span>IDR 10,100,000</span> &#8226; <span>On time</span></p>
                      <p className="danger"><span>IDR 10,100,000</span> &#8226; <span>Late Unpaid</span></p>
                    </>
                  }
                />
                <Steps.Step title="Waiting for Contract" />
                <Steps.Step title="Funding Process" />
                <Steps.Step title="Disbursement Process" />
                <Steps.Step title="Ongoing" /> */}
              </Steps>
            </div>
          )}

        </div>
      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  loan: state.loan
});

export default connect(mapStateToProps, null)(LDSchedule);