import cookies from "react-cookies";

const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;

function createPersonalAccount(data) {
  let path = "/v1/users/register/personal";
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function createBusinessAccount(data) {
    let path = "/v1/users/register/business";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
  }

function getPersonalProfile(token) {
    let path = "/v1/users/profile/personal";
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function putPersonalProfile(data, token) {
    let path = "/v1/users/profile/personal/basic";
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function putLegalProfile(data, token) {
    let path = "/v1/users/profile/personal/legal";
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function putEmergencyContact(data, token) {
    let path = "/v1/users/profile/personal/emergency-contact";
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function putFinancialProfile(data, token) {
    let path = "/v1/users/profile/personal/financial";
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function getPrimaryBank(token) {
    let path = '/v1/users/primary-bank';
    const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        }
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function putPrimaryBank(data,token) {
    let path = '/v1/users/primary-bank';
    const requestOptions = {
        method: "PUT",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function authPreparation(data) {
    let path = '/v1/users/auth-preparation';
    const requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function auth(data) {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
  };

  return fetch(BASE_URL + `/v1/users/auth`, requestOptions)
      .then(handleResponse)
      .then(user => {
          if (user.data.access_token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              cookies.save('token', user.data.access_token, { path: '/', maxAge: 3600 });
              cookies.save('uCode', user.data.user_code, { path: '/', maxAge: 3600 });
          }

          return user;
      });
}

function checkEmail(email) {
    let path = '/v1/users/check-email';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function checkPhoneNumber(number) {
    let path = '/v1/users/check-phonenumber';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone_number: number })
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function connectAccount(data, token) {
    let path = '/v1/users/connect-account/v2';
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    };
  
    return fetch(BASE_URL + path, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function logout() {
  // remove user from local storage to log user out
  cookies.remove('token', { path: '/'});
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        //   if (response.status === 401) {
        //       logout();
        //       window.location.reload(true);
        //   }

          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const userService = {
  createBusinessAccount,  
  auth,
  authPreparation,
  logout,
  createPersonalAccount,
  getPersonalProfile,
  putPersonalProfile,
  putLegalProfile,
  putEmergencyContact,
  putFinancialProfile,
  checkPhoneNumber,
  checkEmail,
  connectAccount,
  getPrimaryBank,
  putPrimaryBank
};