import React from "react";
import { connect } from "react-redux";
import { RefCompanyWrapper } from "./ReferCompany.styles";

import refCompanyPict from "../assets/images/refer-company.png";

class ReferCompanyPage extends React.Component {
  onClickButton = e => {
    this.props.onClick();
  };

  render() {
    let { language } = this.props;

    return (
      <RefCompanyWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <figure className="logo-wrapper"></figure>
            <div className="back-button" style={{ cursor: "pointer" }}>
              <a href="!#" style={{ color: "#C4C4C4", textDecoration: "none" }}>
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span>{language.button.back}</span>
              </a>
            </div>
            <div className="card-body register-body">
              <h2 className="register-title">{language.title.referCompany}</h2>
              <figure className="refcompany-img">
                <img src={refCompanyPict} alt="" />
              </figure>
              <p className="text-center text-note">
                {language.text.referCompany}
              </p>
              <div className="input-wrapper">
                <button
                  onClick={this.onClickButton}
                  className={"btn btn-primary w-100"}
                >
                  {language.button.continue}
                </button>
              </div>
            </div>
          </div>
        </div>
      </RefCompanyWrapper>
    );
  }
}

const mapStateToProps = state => ({
  language: state.init.language
});

export default connect(mapStateToProps, null)(ReferCompanyPage);
