const initState = {
	data: null
};

const offerReducer = (state = initState, action) => {
	switch (action.type) {
    case "GET_OFFER_SUCCESS":
      return { ...state, data: action.payload }
    case "GET_OFFER_FAILED":
      return { ...state, getOfferFailed: true }
    case "CHOOSE_OFFER_SUCCESS":
      return { ...state, isChooseOfferSuccess: true }
    case "CHOOSE_OFFER_FAILED":
      return { ...state, isChooseOfferFailed: true }
    case "REJECT_OFFER_SUCCESS":
      return { ...state, confirmRejection: false, isRejectOfferSuccess: true }
    case "REJECT_OFFER_FAILED":
      return { ...state, confirmRejection: false, isRejectOfferFailed: true }
    case "CONFIRM_REJECTION":
      return { ...state, confirmRejection: true }
    case "RESET_OFFER":
      return { ...initState }
    default:
      return state;
  }
};

export default offerReducer;
