import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Radio,
  Checkbox,
  Spin,
  Select,
  Alert,
} from "antd";
import { Formik } from "formik";
import {
  uploadBusinessOwnerDirectorKoinVoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  actionButtonBackPersonalInfo,
  businessInformationKoinvoice,
  buttonBackBusinessInformation,
  actionGetOwnerDirectorInfoLocal,
  actionButtonBackUploadRefresh,
} from "../../../stores/actions/koinvoiceLoan";
import { actionPutAppGuarantorCreateOwnerDirector } from "../../../stores/actions/lmsLimit";
import UploadKtpModal from "../../ModalUpload/UploadKtpModal";
import UploadSelfieKtpModal from "../../ModalUpload/UploadSelfieKtpModal";
import { CheckCircleTwoTone } from "@ant-design/icons";
import ModalViewKtp from "../../ModalView";
import ModalViewMatchKtp from "../../ModalView";
import ModalViewKK from "../../ModalView";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { ArrowLeftOutlined } from "@ant-design/icons";
import cookie from "react-cookies";
import UploadKkModal from "../../ModalUpload/UploadKkModal";
import convertSchemaToInit from "../../../library/convertSchemaToInit";
import _ from "lodash";
import koinvoiceServices from "../../../services/koinvoice";
import { convertToCategoryName } from "../../../library/regex";
import { trimOssUrl } from "../../../library/fileHelper";
import { PercentageOutlined } from '@ant-design/icons';

const { Option } = Select;

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

function OwnerDirectorInfo({
  init: { language },
  koinvoice: {
    purpose,
    upload,
    uploadKtpSucess,
    uploadKtpError,
    uploadSelfie,
    uploadMatchKtpSucess,
    uploadMatchKtpError,
    uploadKK,
    isShowPutBusinessInfo,
    isShowPutBusinessInfoProfile,
    putPersonalInfoProfileError,
    nextUpdateOwnerDirectorGuarantor,
    appCreatOwnerDirectoreGuarantorError,
    district,
    isShowLoading,
    dataPersonalInformationLocal,
    dataOwnerDirectorInformationLocal,
  },
  business_type,
  marital_status,
  businessInformation,
  actionButtonBackUploadRefresh,
  buttonBackBusinessInformation,
  businessInformationKoinvoice,
  actionPutAppGuarantorCreateOwnerDirector,
  auth,
  actionGetOwnerDirectorInfoLocal,
  actionButtonBackPersonalInfo,
  uploadBusinessOwnerDirectorKoinVoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  nextPage,
  prevPage,
  token,
  userId
}) {
  const paramsSubmitDirectorOwner = JSON.parse(
    localStorage.getItem("paramsSubmitDirectorOwner")
  );
  const addData = JSON.parse(localStorage.getItem(`add-data-${userId}`));

  const [visibleUploadKtp, setVisibleUploadKtp] = useState(false);
  const [visibleUploadSelfieKtp, setVisibleUploadSelfieKtp] = useState(false);
  const [visibleUploadKk, setVisibleUploadKk] = useState(false);
  const [isShowViewKtpModal, setIsShowViewKtpModal] = useState(false);
  const [isShowViewMatchKtpModal, setIsShowViewMatchKtpModal] = useState(false);
  const [isShowViewKKModal, setIsShowViewKKModal] = useState(false);
  const [isShowCurrentlyAddress, setIsShowCurrentlyAddress] = useState(true);
  const [validationImageKtp, setValidationImageKtp] = useState(parseInt(0));
  const [validationImageKtpPersonal, setValidationImageKtpPersonal] = useState(
    parseInt(0)
  );
  const [subDistrict, setSubDistrict] = useState([]);
  const [
    isShowvalidationImageKtpPersonal,
    setIsShowValidationImageKtpPersonal,
  ] = useState(false);
  const [validationMatchImageKtp, setValidationMatchImageKtp] = useState(0);
  const [validationMatchImageKtpFailed, setValidationMatchImageKtpFailed] =
    useState(parseInt(0));
  const [
    isShowvalidationMatchImageKtpFailed,
    setIsShowValidationMatchImageKtpFailed,
  ] = useState(false);
  const [validationImageKk, setValidationImageKk] = useState(parseInt(0));
  const [isShowLoadingSubmitPeronalInfo, setIsShowLoadingSubmitPersonalInfo] =
    useState(false);
  const [homeAddress, setHomeAddress] = useState("different-address");
  const [pathKtp, setPathKtp] = useState("");
  const [pathKK, setPathKK] = useState("");
  const [isShowHideAlertKtp, setIsShowAlertKtp] = useState("");
  const [isShowHideAlertUploadKtp, setIsShowAlertUploadKtp] = useState("");
  const [isSameWithPersonal, setIsSameWithPersonal] = useState(
    paramsSubmitDirectorOwner?.is_same_personal || false
  );
  //const [code, setCode] = useState();
  const [initialValues, setInitialValues] = useState();
  const [checkBusinessTypeInformation, setCheckBusinessTypeInformation] =
    useState(false);
  const mounted = useRef();
  const businessType =
    business_type === "individual" ||
    business_type === "industri_rumah_tangga" ||
    business_type === "home_business";
  const schemaPersonalInfo = yup.object().shape({
    ktp_image: yup.string().required(language.loanShareHolder.requiredKtp),
    nik: yup.string().required(language.loanShareHolder.requiredNik).length(16, language.loanShareHolder.requiredNikLength),
    owner_name: yup.string().required(language.loanShareHolder.requiredOwnerName),
    owner_birthdate: yup.string().required(language.loanShareHolder.requiredDob),
    owner_place_of_birth: yup.string().required(language.loanShareHolder.requiredPob),
    owner_gender: yup.string().required(language.loanShareHolder.requiredGender),
    owner_marital_status: yup.string().required(language.loanShareHolder.requiredMaritalStatus),
    // kk_image: checkBusinessTypeInformation
    //   ? yup.string().required("Required KK Home Business")
    //   : yup.string(),
    owner_address: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredAddress)
      : yup.string(),
    owner_district: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredDistrict)
      : yup.string(),
    owner_sub_district: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredSubDistrict)
      : yup.string(),
    owner_province: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredProvince)
      : yup.string(),
    owner_city: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredCity)
      : yup.string(),
    owner_address_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredAddress),
    owner_district_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredDistrict),
    owner_sub_district_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredSubDistrict),
    owner_province_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredProvince),
    owner_phone: addData?.type === 'special' 
      ? "" 
      : yup.string().required(language.loanShareHolder.requiredMobileNumber)
        .min(6, language.loanShareHolder.requiredMobileNumberLength),
    owner_email: addData?.type === 'special' 
      ? ""  
      : yup.string().required(language.loanShareHolder.requiredEmail).email(language.loanShareHolder.wrongEmail),
    postal_code: yup
      .string()
      .required(language.loanShareHolder.requiredPostalCode)
      .length(5, language.loanShareHolder.requiredPostalCodeLength),
    postal_code_duplicate: isShowCurrentlyAddress
      ? ""
      : yup
          .string()
          .required(language.loanShareHolder.requiredPostalCode)
          .length(5, language.loanShareHolder.requiredPostalCodeLength),
    saham: businessType
      ? yup.string()
      : yup.string().required(language.loanShareHolder.requiredShare),
    jabatan: businessType
      ? ""
      : yup.string().required(language.loanShareHolder.position),
    sibling_relation: businessType ? "" :
      yup.string().required(language.loanShareHolder.requiredSiblingRelation),
  });
  const isInitialRender = useRef(true);
  const paramsSubmitFormPersonalInfo =
    JSON.parse(localStorage.getItem("paramsSubmit")) !== null &&
    JSON.parse(localStorage.getItem("paramsSubmit"));
  const showAddressCurrently =
    JSON.parse(localStorage.getItem("paramsIsCurrentlyAddress")) !== null &&
    JSON.parse(localStorage.getItem("paramsIsCurrentlyAddress"));
  const ownerInfoDate =
    JSON.parse(localStorage.getItem("paramsSubmitDate")) !== null &&
    JSON.parse(localStorage.getItem("paramsSubmitDate"));

  useEffect(() => {
    const initValue = convertSchemaToInit(schemaPersonalInfo);
    setInitialValues({
      ...initValue,
      owner_email: paramsSubmitDirectorOwner?.owner_email || "",
      owner_phone: paramsSubmitDirectorOwner?.owner_phone,
      ktp_image: paramsSubmitDirectorOwner?.ktp_image,
      kk_image: paramsSubmitDirectorOwner?.kk_image,
      nik: paramsSubmitDirectorOwner?.nik,
      owner_birthdate: paramsSubmitDirectorOwner?.owner_birthdate,
      owner_address:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitDirectorOwner?.owner_address
          : "",
      owner_district:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitDirectorOwner?.owner_district
          : "",
      owner_sub_district:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitDirectorOwner?.owner_sub_district
          : "",
      owner_city:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitDirectorOwner?.owner_city
          : "",
      owner_province:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitDirectorOwner?.owner_province
          : "",
      postal_code:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitDirectorOwner?.postal_code
          : "",
      owner_gender: paramsSubmitDirectorOwner?.owner_gender,
      owner_marital_status: paramsSubmitDirectorOwner?.owner_marital_status,
      owner_place_of_birth: paramsSubmitDirectorOwner?.owner_place_of_birth,
      owner_name: paramsSubmitDirectorOwner?.owner_name,
      owner_address_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitDirectorOwner?.owner_address
          : "",
      owner_district_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitDirectorOwner?.owner_district
          : "",
      owner_sub_district_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitDirectorOwner?.owner_sub_district
          : "",
      owner_province_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitDirectorOwner?.owner_province_duplicate
          : "",
      postal_code_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitDirectorOwner?.postalCode
          : "",
      sibling_relation: paramsSubmitDirectorOwner?.sibling_relation,
      jabatan: paramsSubmitDirectorOwner?.jabatan || '',
      saham: paramsSubmitDirectorOwner?.saham || ''
    });
    window.scrollTo(0, 0);
    actionButtonBackUploadRefresh();
    businessInformationKoinvoice(token); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isInitialRender.current
      ? (isInitialRender.current = false)
      : isSameWithPersonal
      ? actionChangeSameWithPersonal()
      : actionChangeNoSameWithPersonal(); // eslint-disable-next-line
  }, [isSameWithPersonal]);

  // function uuIdMaritalStatusCondition(boolean) {
  //   let sibling;
  //   if (
  //     ["MENIKAH", "KAWIN"].includes(
  //       boolean
  //         ? paramsSubmitDirectorOwner?.owner_marital_status
  //         : paramsSubmitFormPersonalInfo.owner_marital_status
  //     )
  //   ) {
  //     sibling = "39a35288-f562-11e9-97fa-00163e010bca";
  //   } else if (
  //     ["BELUM MENIKAH", "BELUM KAWIN"].includes(
  //       boolean
  //         ? paramsSubmitDirectorOwner?.owner_marital_status
  //         : paramsSubmitFormPersonalInfo.owner_marital_status
  //     )
  //   ) {
  //     sibling = "39a35289-f562-11e9-97fa-00163e010bca";
  //   }
  //   return sibling;
  // }

  function getMaritalStatusUuid(status) {
    switch(status.toLowerCase()) {
      case "kawin":
      case "married":
        return "39a35288-f562-11e9-97fa-00163e010bca";
      case "cerai hidup":
      case "cerai_hidup":
      case "divorced":
        return "6d1efcbe-0c47-40a6-aa2f-9b28b5897e86";
      case "cerai mati":
      case "cerai_mati":
      case "widowed":
        return "947c08d9-4588-4eb8-8efb-9892b0c66a7e";
      default:
        return "39a35289-f562-11e9-97fa-00163e010bca";
    }
  }

  function actionChangeSameWithPersonal() {
    const initValue = convertSchemaToInit(schemaPersonalInfo);
    setInitialValues({
      ...initValue,
      owner_email: paramsSubmitFormPersonalInfo.owner_email,
      owner_phone: paramsSubmitFormPersonalInfo.owner_phone,
      ktp_image: paramsSubmitFormPersonalInfo.ktp_image,
      kk_image: paramsSubmitFormPersonalInfo.kk_image,
      nik: paramsSubmitFormPersonalInfo.nik,
      owner_birthdate: ownerInfoDate.date,
      owner_address:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitFormPersonalInfo.owner_address
          : "",
      owner_district:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitFormPersonalInfo.owner_district
          : "",
      owner_city:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitFormPersonalInfo.owner_city
          : "",
      owner_province:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitFormPersonalInfo.owner_province
          : "",
      owner_sub_district:
        showAddressCurrently.currentlyAddress === true
          ? paramsSubmitFormPersonalInfo.owner_sub_district
          : "",
      postal_code:
        showAddressCurrently.currentlyAddress === true
          ? ownerInfoDate.postalCode
          : "",
      owner_gender: paramsSubmitFormPersonalInfo.owner_gender,
      owner_marital_status: paramsSubmitDirectorOwner?.owner_marital_status ||
      getMaritalStatusUuid(paramsSubmitFormPersonalInfo?.owner_marital_status),
      owner_place_of_birth: paramsSubmitFormPersonalInfo.owner_place_of_birth,
      owner_name: paramsSubmitFormPersonalInfo.owner_name,
      owner_address_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitFormPersonalInfo.owner_address
          : "",
      owner_district_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitFormPersonalInfo.owner_district
          : "",
      owner_sub_district_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitFormPersonalInfo.owner_sub_district
          : "",
      owner_province_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? paramsSubmitFormPersonalInfo.owner_province_duplicate
          : "",
      postal_code_duplicate:
        showAddressCurrently.currentlyAddress === false
          ? ownerInfoDate.postalCode
          : "",
      sibling_relation: paramsSubmitFormPersonalInfo.sibling_relation ||
        paramsSubmitDirectorOwner?.sibling_relation,
      jabatan: paramsSubmitDirectorOwner?.jabatan,
      saham: paramsSubmitDirectorOwner?.saham
    });
  }

  useEffect(() => {
    if (isSameWithPersonal && showAddressCurrently.currentlyAddress === false) {
      setIsShowCurrentlyAddress(false);
    } // eslint-disable-next-line
  }, [isSameWithPersonal]);

  function actionChangeNoSameWithPersonal() {
    const initValue = convertSchemaToInit(schemaPersonalInfo);
    setInitialValues({ ...initValue, nik: "" });
  }

  function actionChangeKtpUpload(values, setFieldValue) {
    setPathKtp(values.path);
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email:
          auth.email || (businessInformation && businessInformation.email),
      };
      uploadBusinessOwnerDirectorKoinVoice(
        params,
        handleChangeShowUploadKtp,
        setFieldValue,
        setValidationImageKtp,
        validationImageKtp,
        setValidationImageKtpPersonal,
        validationImageKtpPersonal,
        setIsShowAlertKtp,
        setIsShowAlertUploadKtp,
        token
      );
    };
    fileReader.readAsDataURL(values);
  }

  function handleChangeShowUploadKtp(value) {
    setVisibleUploadKtp(!visibleUploadKtp);
    if (value === "reUploadKtp") {
      setIsShowValidationImageKtpPersonal(true);
      setValidationImageKtpPersonal(0);
    }
  }

  function handleChangeShowUploadKk(value) {
    setVisibleUploadKk(!visibleUploadKk);
  }

  function handleChangeShowUploadSelfieKtp(value) {
    setVisibleUploadSelfieKtp(!visibleUploadSelfieKtp);
    if (value === "reUploadKtp") {
      setIsShowValidationMatchImageKtpFailed(true);
      setValidationMatchImageKtpFailed(0);
    }
  }

  function handleChangeShowViewKtp() {
    setIsShowViewKtpModal(!isShowViewKtpModal);
  }

  function handleChangeShowViewMatchKtp() {
    setIsShowViewMatchKtpModal(!isShowViewMatchKtpModal);
  }

  function handleChangeShowViewKK() {
    setIsShowViewKKModal(!isShowViewKKModal);
  }

  function onChangeAddressCurrentlyLive(e) {
    setIsShowCurrentlyAddress(e.target.checked);
  }

  function onChangeCurrentHomeAddress(e) {
    setHomeAddress(e.target.value);
    if (e.target.value === "same-with-business-address") {
      setIsShowCurrentlyAddress(true);
    }
  }

  function actionChangeBoxSameWithPersonal(e) {
    setIsSameWithPersonal(e.target.checked);
  }

  function handleChangePhoneInput(value, option, setFieldValue) {
    setFieldValue("owner_phone", value);
  }

  function actionChangeSearchDistrict(value) {
    getDistrictKoinvoice(value);
  }

  function actionChangeDistrict(value, option, setFieldValue) {
    // const districtValidation =
    //   district.data && district.data.find((dis) => dis.name === value);
    // setCode(districtValidation.code);
    setFieldValue("owner_district", value);
  }

  function actionChangeDistrictDuplicate(value, option, setFieldValue) {
    // const districtValidation =
    //   district.data && district.data.find((dis) => dis.name === value);
    // setCode(districtValidation.code);
    setFieldValue("owner_district_duplicate", value);
  }

  function actionChangeSearchSubDistrict(value) {
    // getSubDistrictKoinvoice(`${value}&district_code=${code}`);
    koinvoiceServices
      .actionGetLocationThirdParty({ params: value, token:token })
      .then((res) => {
        if (res?.status === 200 && res?.data !== null) {
          setSubDistrict(res.data);
        }
      });
  }

  function actionChangeSubDistrict(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value
    );
    setFieldValue("owner_district", districtSubValidation.district);
    setFieldValue("owner_sub_district", districtSubValidation.sub_district);
    setFieldValue("owner_province", districtSubValidation.province);
    setFieldValue("owner_city", districtSubValidation.city);
    setFieldValue("postal_code", districtSubValidation.postal_code);
  }

  function actionChangeSubDistrictDuplicate(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value
    );
    setFieldValue("owner_district_duplicate", districtSubValidation.district);
    setFieldValue("owner_city_duplicate", districtSubValidation.city);
    setFieldValue("owner_province_duplicate", districtSubValidation.province);
    setFieldValue(
      "owner_sub_district_duplicate",
      districtSubValidation.sub_district
    );
    setFieldValue("postal_code_duplicate", districtSubValidation.postal_code);
  }

  function actionChangeBirthDate(value, option, setFieldValue) {
    setFieldValue("owner_birthdate", option);
  }

  function handleChangeAddressDuplicate(value, option, setFieldValue) {
    setFieldValue("owner_address_duplicate", value.target.value);
  }

  function handleChangeAddress(value, option, setFieldValue) {
    setFieldValue("owner_address", value.target.value);
  }

  function handleChangeNik(value, option, setFieldValue) {
    setFieldValue("nik", value.target.value.toString().replace(/-/g, ""));
  }

  // function actionChangeLivedFor(value, option, setFieldValue) {
  //   setFieldValue("has_lived_for", value);
  // }

  function handleChangeRelationship(value, option, setFieldValue) {
    setFieldValue("sibling_relation", value);
  }

  function handleChangePosition(value, option, setFieldValue) {
    setFieldValue("jabatan", value);
  }

  function actionChangeSaham(value, option, setFieldValue) {
    let tempValue;

    if(
        (value["target"]["value"].includes(",") && value["target"]["value"] !== "") || 
        (value["target"]["value"].includes(".") && value["target"]["value"] !== "")
      ) {
      tempValue = parseFloat(value["target"]["value"].replace(",", ".")).toFixed(2);
      if(tempValue > 100.00) {
        tempValue = 100.00;
        setFieldValue("saham", tempValue.toString());
      } else {
        let tempStringArr = value["target"]["value"].replace(",", ".").split(".");
        let cutTwo = tempStringArr[1].slice(0, 2);
        setFieldValue("saham", tempStringArr[0] + "." + cutTwo);
      }
    } else if(
        (!value["target"]["value"].includes(",") && value["target"]["value"] !== "") || 
        (!value["target"]["value"].includes(".") && value["target"]["value"] !== "")
      ) {
      tempValue = parseInt(value["target"]["value"]);
      if(tempValue > 100) {
        tempValue = 100.00;
        setFieldValue("saham", tempValue.toString());
      } else {
        setFieldValue("saham", value["target"]["value"]);
      }
    } else {
      setFieldValue("saham", "");
    }
  }

  function actionChangeMaritalStatus(value, option, setFieldValue, check) {
    setFieldValue("owner_marital_status", option.value);
    if (check && option.value === "39a35288-f562-11e9-97fa-00163e010bca") {
      setCheckBusinessTypeInformation(true);
    } else {
      setCheckBusinessTypeInformation(false);
    }
  }

  function handleSubmit(e) {
    const {
      ktp_image,
      nik,
      owner_address,
      owner_birthdate,
      owner_district_duplicate,
      owner_gender,
      owner_marital_status,
      owner_name,
      owner_phone,
      home_ownership_status,
      sibling_relation,
      saham,
      jabatan,
      owner_place_of_birth,
      owner_address_duplicate,
      owner_district,
      owner_sub_district,
      owner_city,
      owner_province,
      owner_sub_district_duplicate,
      owner_email,
      postal_code,
      postal_code_duplicate,
    } = e;
    let paramsGuarantor = {
      full_name: owner_name,
      nik,
      pob: owner_place_of_birth,
      dob: owner_birthdate.split("-").reverse().join("-"),
      district: owner_district,
      subdistrict: owner_sub_district,
      city: owner_city,
      province: owner_province,
      address: owner_address,
      postal_code: isShowCurrentlyAddress
        ? postal_code
        : "" || postal_code
        ? postal_code
        : "",
      mobile_number: owner_phone,
      email: owner_email || "",
      ktp: trimOssUrl(ktp_image),
      gender: owner_gender,
      marital_status: owner_marital_status,
      home_ownership_status: home_ownership_status,
      sibling_type: "7dd23736-2e45-11eb-825e-98039b46edc0",
      product: "a1fb40e7-e9c5-11e9-97fa-00163e010bca",
      sibling_relation: sibling_relation || "9f015d32-de00-11ea-87d0-0242ac130008"
    };
    Object.assign(paramsGuarantor, {
      user_id: businessInformation.data && businessInformation.data.user_id,
      properties: {
        saham: saham,
        jabatan: jabatan,
        address: owner_address_duplicate,
        district: owner_district_duplicate,
        subdistrict: owner_sub_district_duplicate,
        postal_code: isShowCurrentlyAddress
          ? ""
          : postal_code_duplicate.toString() || postal_code_duplicate
          ? postal_code_duplicate.toString()
          : "",
      },
      is_address_same: isShowCurrentlyAddress ? 1 : 0,
    });
    actionGetOwnerDirectorInfoLocal(e);
    e["is_same_personal"] = isSameWithPersonal;
    localStorage.setItem("paramsSubmitDirectorOwner", JSON.stringify(e));
    cookie.save("paramsSubmitDirectorOwner", e, {
      path: "/",
      maxAge: 3600,
    });
    actionPutAppGuarantorCreateOwnerDirector(
      paramsGuarantor,
      setIsShowLoadingSubmitPersonalInfo,
      token
    );
  }

  // function handleNpwpInputChange(value, option, setFieldValue) {
  //   setFieldValue(
  //     "npwp",
  //     value.target.value.toString().replace(/[.*+?^_${}()|[\]\\-]/g, "")
  //   );
  // }

  // function handleChangeOwnerShip(value, option, setFieldValue) {
  //   setFieldValue("home_ownership_status", value);
  // }

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (nextUpdateOwnerDirectorGuarantor) {
        nextPage();
      }
    } // eslint-disable-next-line
  }, [nextUpdateOwnerDirectorGuarantor]);

  function handleChangeBackPersonal() {
    actionButtonBackPersonalInfo();
    buttonBackBusinessInformation();
    prevPage();
  }

  const options =
    district.data &&
    _.uniqBy(district.data, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));

  const optionsSubDistrict = _.uniqBy(subDistrict, "name").map(
    (data, index) => <Option key={data.name}>{data.name}</Option>
  );

  const FormItemOwnerDirectorInfo = (
    errors,
    handleBlur,
    handleChange,
    values,
    setFieldValue
  ) => {
    return (
      <React.Fragment>
        <Form.Item
          label={language.form.nik}
          validateStatus={errors.nik && errors.nik && "error"}
          help={errors.nik && errors.nik}
          onBlur={handleBlur}
        >
          <NumberFormat
            style={{
              width: isMobile() ? 500 : "100%",
              height: 40,
              border: "1px solid #d9d9d9",
            }}
            name="nik"
            type="tel"
            placeholder="16***-***3-*34**"
            format="####-####-####-####"
            onChange={(value, option) =>
              handleChangeNik(value, option, setFieldValue)
            }
            value={values.nik}
          />
        </Form.Item>
        <Form.Item
          label={language.form.user_home_business}
          validateStatus={errors.owner_name && "error"}
          help={errors.owner_name}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            name="owner_name"
            placeholder="Jhone Doe"
            onChange={handleChange}
            value={values.owner_name}
          />
        </Form.Item>
        <Form.Item
          label={language.form.pob}
          validateStatus={errors.owner_place_of_birth && "error"}
          help={errors.owner_place_of_birth}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            name="owner_place_of_birth"
            placeholder="Semarang"
            onChange={handleChange}
            value={values.owner_place_of_birth}
          />
        </Form.Item>
        <Form.Item
          label={language.form.dob}
          validateStatus={errors.owner_birthdate && "error"}
          help={errors.owner_birthdate}
          onBlur={handleBlur}
        >
          <DatePicker
            name="owner_birthdate"
            defaultPickerValue={
              values.owner_birthdate
                ? moment(values.owner_birthdate, "DD-MM-YYYY")
                : moment("01-01-1990", "DD-MM-YYYY")
            }
            value={
              values.owner_birthdate
                ? moment(values.owner_birthdate, "DD-MM-YYYY")
                : ""
            }
            format="DD-MM-YYYY"
            onChange={(value, option) =>
              actionChangeBirthDate(value, option, setFieldValue)
            }
            size="large"
            style={{ width: isMobile() ? 500 : "100%" }}
          />
        </Form.Item>
        <Form.Item
          label={language.form.gender}
          validateStatus={errors.owner_gender && "error"}
          help={errors.owner_gender}
        >
          <Radio.Group
            value={values.owner_gender}
            size="large"
            name="owner_gender"
            onChange={handleChange}
            buttonStyle="solid"
          >
            <Radio.Button value="male">{language.label.male}</Radio.Button>
            <Radio.Button value="female">{language.label.female}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={language.form.maritalStatus}
          validateStatus={errors.owner_marital_status && "error"}
          help={errors.owner_marital_status}
        >
          {" "}
          <Select
            showSearch
            size={"large"}
            style={{ width: isMobile() ? 500 : "100%" }}
            value={values.owner_marital_status}
            placeholder="KAWIN"
            onChange={(value, option) =>
              actionChangeMaritalStatus(
                value,
                option,
                setFieldValue,
                businessInformation &&
                  (businessInformation.details.business.company_detail
                    ?.business_type?.value === "individual" ||
                    businessInformation.details.business.company_detail
                      ?.business_type?.value === "home_business")
              )
            }
          >
            <Option value="39a35289-f562-11e9-97fa-00163e010bca">
              {language.label.singleDivorce}
            </Option>
            <Option value="39a35288-f562-11e9-97fa-00163e010bca">
              {language.label.married}
            </Option>
            <Option value="947c08d9-4588-4eb8-8efb-9892b0c66a7e">
              {language.label.deathDivorce}
            </Option>
            <Option value="6d1efcbe-0c47-40a6-aa2f-9b28b5897e86">
              {language.label.divorced}
            </Option>
          </Select>
        </Form.Item>
        { (businessType && checkBusinessTypeInformation) && (
          <Form.Item
            label={language.form.kartuKeluarga}
            validateStatus={errors.kk_image && "error"}
            help={errors.kk_image}
          >            
            {values.kk_image? (
              <div className="kw-personalInfo__verifiy-ktp">
                <img
                  src={values.kk_image}
                  alt=""
                  className="kw-personalInfo__image-upload"
                />
                <div className="kw-personalInfo__verifiy-ktp__itemOne">
                  <p onClick={handleChangeShowViewKK}>View</p>
                  {pathKK}
                </div>
                <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                  <p>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ marginRight: 5 }}
                    />{" "}
                    Verified
                  </p>
                </div>
              </div>
            ) : (
              <Button
                className="kw-personalInfo__button-upload"
                onClick={handleChangeShowUploadKk}
                style={{
                  border: errors.kk_image
                    ? "2px solid red"
                    : "2px solid #2b486d",
                }}
                size="large"
              >
                TAKE A PHOTO
              </Button>
            )}
            <p>{language.loanPersonalInfo.textKartuKeluarga}</p>
          </Form.Item>
        )}
        <p>{language.form.idAddress}</p>
        <Form.Item
          label={language.loanFormBusinessInfo.address}
          validateStatus={errors.owner_address && "error"}
          help={errors.owner_address}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            placeholder="Jl...RT01/RW02 dekat toko"
            name={isShowCurrentlyAddress ? "owner_address" : ""}
            onChange={(value, option) =>
              handleChangeAddress(value, option, setFieldValue)
            }
            value={values.owner_address}
          />
        </Form.Item>
        <Form.Item
          label={language.form.subdistrict}
          validateStatus={errors.owner_sub_district && "error"}
          help={errors.owner_sub_district}
          onBlur={handleBlur}
        >
          <Select
            showSearch
            value={values.owner_sub_district}
            name={isShowCurrentlyAddress ? "owner_sub_district" : ""}
            style={{ width: isMobile() ? 500 : "100%" }}
            placeholder="TEBET TIMUR"
            size="large"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={actionChangeSearchSubDistrict}
            onChange={(value, option) =>
              actionChangeSubDistrict(value, option, setFieldValue)
            }
          >
            {optionsSubDistrict}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.district}
          validateStatus={errors.owner_district && "error"}
          help={errors.owner_district}
          onBlur={handleBlur}
        >
          <Select
            showSearch
            value={values.owner_district}
            name={isShowCurrentlyAddress ? "owner_district" : ""}
            style={{ width: isMobile() ? 500 : "100%" }}
            //  placeholder="JAKARTA SELATAN"
            disabled={true}
            size="large"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={actionChangeSearchDistrict}
            onChange={(value, option) =>
              actionChangeDistrict(value, option, setFieldValue)
            }
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.city}
          validateStatus={
            errors.owner_city && "error"
          }
          help={errors.owner_city}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            //  placeholder={"DKI JAKARTA"}
            disabled={true}
            size="large"
            name="owner_city"
            onChange={handleChange}
            value={values.owner_city}
          />
        </Form.Item>
        <Form.Item
          label={language.form.province}
          validateStatus={errors.owner_province && "error"}
          help={errors.owner_province}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            // placeholder={"DKI JAKARTA"}
            disabled={true}
            size="large"
            name="owner_province"
            onChange={handleChange}
            value={values.owner_province}
          />
        </Form.Item>
        <Form.Item
          label={language.form.postalCode}
          validateStatus={errors.postal_code && "error"}
          help={errors.postal_code}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            placeholder={"30153"}
            type="number"
            size="large"
            name="postal_code"
            onChange={handleChange}
            value={values.postal_code}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isShowCurrentlyAddress}
            onChange={onChangeAddressCurrentlyLive}
          >
            {language.loanPersonalInfo.checklistCurrently}
          </Checkbox>
        </Form.Item>
        {isShowCurrentlyAddress === false && (
          <React.Fragment>
            <p>{language.loanPersonalInfo.currentHomeAddress}</p>
            <Form.Item>
              <Radio.Group
                onChange={onChangeCurrentHomeAddress}
                value={homeAddress}
              >
                <Radio
                  value="same-with-business-address"
                  name="business-address"
                >
                  {language.loanPersonalInfo.sameWithAddress}
                </Radio>
                <Radio value="different-address" name="address-different">
                  {language.loanPersonalInfo.differentAddress}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.address}
              validateStatus={errors.owner_address_duplicate && "error"}
              help={errors.owner_address_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                type="text"
                size="large"
                name={"owner_address_duplicate"}
                placeholder="Jl...RT01/RW02 dekat toko"
                onChange={(value, option) =>
                  handleChangeAddressDuplicate(value, option, setFieldValue)
                }
                value={values.owner_address_duplicate}
              />
            </Form.Item>
            <Form.Item
              label={language.form.subdistrict}
              validateStatus={errors.owner_sub_district_duplicate && "error"}
              help={errors.owner_sub_district_duplicate}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.owner_sub_district_duplicate}
                name={"owner_sub_district_duplicate"}
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder="TEBET TIMUR"
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchSubDistrict}
                onChange={(value, option) =>
                  actionChangeSubDistrictDuplicate(value, option, setFieldValue)
                }
              >
                {optionsSubDistrict}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.district}
              validateStatus={errors.owner_district_duplicate && "error"}
              help={errors.owner_district_duplicate}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.owner_district_duplicate}
                name={"owner_district_duplicate"}
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                placeholder="JAKARTA SELATAN"
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchDistrict}
                onChange={(value, option) =>
                  actionChangeDistrictDuplicate(value, option, setFieldValue)
                }
                disabled
              >
                {options}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.city}
              validateStatus={
                errors.owner_city_duplicate && "error"
              }
              help={errors.owner_city_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                //  placeholder={"DKI JAKARTA"}
                disabled={true}
                size="large"
                name="owner_city_duplicate"
                onChange={handleChange}
                value={values.owner_city_duplicate}
              />
            </Form.Item>
            <Form.Item
              label={language.form.province}
              validateStatus={errors.owner_province_duplicate && "error"}
              help={errors.owner_province_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                // placeholder={"DKI JAKARTA"}
                disabled={true}
                size="large"
                name="owner_province_duplicate"
                onChange={handleChange}
                value={values.owner_province_duplicate}
              />
            </Form.Item>
            <Form.Item
              label={language.form.postalCode}
              validateStatus={errors.postal_code_duplicate && "error"}
              help={errors.postal_code_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder={"30153"}
                type="number"
                size="large"
                name="postal_code_duplicate"
                onChange={handleChange}
                value={values.postal_code_duplicate}
              />
            </Form.Item>
          </React.Fragment>
        )}{" "}
        {/* <Form.Item
          label={language.loanShareHolder.haslivedLabel}
          validateStatus={errors.has_lived_for && "error"}
          help={errors.has_lived_for}
          onBlur={handleBlur}
        >
          {" "}
          <Select
            value={values.has_lived_for}
            style={{ width: isMobile() ? 500 : "100%" }}
            size="large"
            name="has_lived_for"
            placeholder="1-3 Years"
            onChange={(value, option) =>
              actionChangeLivedFor(value, option, setFieldValue)
            }
          >
            <Option key={"5142a8b4-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor1Years}
            </Option>
            <Option key={"5142a8b5-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor13Years}
            </Option>
            <Option key={"5142a8b6-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor35Years}
            </Option>
            <Option key={"5142a8b7-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor5Years}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={language.loanShareHolder.homeStatusOwnerShip}
          validateStatus={errors.home_ownership_status && "error"}
          help={errors.home_ownership_status}
          onBlur={handleBlur}
        >
          <Select
            size={"large"}
            value={values.home_ownership_status}
            onChange={(value, option) =>
              handleChangeOwnerShip(value, option, setFieldValue)
            }
            placeholder={language.loanShareHolder.homeStatusOwner}
            style={{ width: isMobile() ? 500 : "100%" }}
          >
            <Option key={"5142a8b0-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusOwner}
            </Option>
            <Option key={"5142a8b1-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusParents}
            </Option>
            <Option key={"5142a8b2-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusRent}
            </Option>
            <Option key={"5142a8b3-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusBoardingHouse}
            </Option>
          </Select>
        </Form.Item> */}
        {["pt","cv","pt/cv","cv/pt"].includes(business_type.toLowerCase()) && (
          <React.Fragment>
            {" "}
            <Form.Item
              label={language.form.position}
              validateStatus={errors.jabatan && "error"}
              help={errors.jabatan}
              onBlur={handleBlur}
            >
              <Select
                size={"large"}
                showSearch
                value={
                  values.jabatan ||
                  undefined
                }
                placeholder="Director"
                onChange={(value, option) =>
                  handleChangePosition(value, option, setFieldValue)
                }
                style={{ width: isMobile() ? 500 : "100%" }}
              >
                <Option key={"president_director"}>{language.form.pres_director}</Option>
                <Option key={"director"}>{language.form.director}</Option>
                <Option key={"president_commissioner"}>{language.form.pres_commissioner}</Option>
                <Option key={"commissioner"}>{language.form.commissioner}</Option>
                <Option key={"other"}>{language.form.otherPosition}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={language.loanShareHolder.shareHolder}
              validateStatus={errors.saham && "error"}
              help={errors.saham}
              onBlur={handleBlur}
            >
             <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                suffix={<PercentageOutlined/>}
                type="text"
                size="large"
                name="saham"
                placeholder="54,30"
                onChange={(value, option) =>
                  actionChangeSaham(value, option, setFieldValue)
                }
                value={values.saham}
              />
            </Form.Item>
            <Form.Item
              label={language.loanShareHolder.relationshipToBorrower}
              validateStatus={errors.sibling_relation && "error"}
              help={errors.sibling_relation}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                size={"large"}
                value={values.sibling_relation}
                placeholder={language.form.parent}
                onChange={(value, option) =>
                  handleChangeRelationship(value, option, setFieldValue)
                }
                style={{ width: isMobile() ? 500 : "100%" }}
              >
                <Option key={"9f015d32-de00-11ea-87d0-0242ac130003"}>
                  {language.form.parent}
                </Option>
                <Option key={"9f015d32-de00-11ea-87d0-0242ac130004"}>
                  {language.form.sibling}
                </Option>
                <Option key={"9f015d32-de00-11ea-87d0-0242ac130005"}>
                  {language.form.spouse}
                </Option>
                <Option key={"9f015d32-de00-11ea-87d0-0242ac130006"}>
                  {language.form.colleague}
                </Option>
                <Option key={"9f015d32-de00-11ea-87d0-0242ac130007"}>
                  {language.form.profesional}
                </Option>
                <Option key={"9f015d32-de00-11ea-87d0-0242ac130008"}>
                  {language.form.other}
                </Option>
              </Select>
            </Form.Item>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="kw-personalInfo">
        <Button
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 15,
            marginTop: 15,
          }}
          onClick={() => handleChangeBackPersonal()}
        >
          <ArrowLeftOutlined /> {language.button.back}
        </Button>{" "}
        <h3>
          {businessType
            ? language.loanPersonalInfo.headingOwnerInfo
            : language.loanPersonalInfo.headingDirectornfo}
        </h3>
        <Checkbox 
          value={isSameWithPersonal}
          onChange={actionChangeBoxSameWithPersonal}
          checked={isSameWithPersonal}
        >
          {language.label.sameWithPersonalInfo}
        </Checkbox>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schemaPersonalInfo}
          validateOnChange={false}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Item
                label={language.loanShareHolder.ktp}
                validateStatus={
                  touched.ktp_image && errors.ktp_image && "error"
                }
                help={touched.ktp_image && errors.ktp_image}
              >
                {validationImageKtp === 3 ||
                upload.data ||
                (paramsSubmitFormPersonalInfo.ktp_image &&
                  isSameWithPersonal) ||
                dataOwnerDirectorInformationLocal.ktp_image ||
                values.ktp_image ? (
                  <div className="kw-personalInfo__verifiy-ktp">
                    <img
                      src={values.ktp_image}
                      alt=""
                      className="kw-personalInfo__image-upload"
                    />
                    <div className="kw-personalInfo__verifiy-ktp__itemOne">
                      <p onClick={handleChangeShowViewKtp}>View</p>
                      {pathKtp}
                    </div>
                    {upload.data && uploadKtpSucess.data ? (
                      <React.Fragment>
                        {isShowHideAlertUploadKtp && isShowHideAlertKtp && (
                          <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                            <p>
                              <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                style={{ marginRight: 5 }}
                              />{" "}
                              Verified
                            </p>
                          </div>
                        )}
                        <div className="kw-personalInfo__verifiy-ktp__itemOne">
                          <p
                            onClick={() =>
                              handleChangeShowUploadKtp("reUploadKtp")
                            }
                          >
                            Reupload
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      upload.data &&
                      uploadKtpError && (
                        <div className="kw-personalInfo__verifiy-ktp__itemOne">
                          <p
                            onClick={() =>
                              handleChangeShowUploadKtp("reUploadKtp")
                            }
                          >
                            Reupload
                          </p>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <Button
                    className="kw-personalInfo__button-upload"
                    onClick={handleChangeShowUploadKtp}
                    style={{
                      border: errors.ktp_image
                        ? "2px solid red"
                        : "2px solid #2b486d",
                    }}
                    size="large"
                  >
                    UPLOAD PHOTO KTP
                  </Button>
                )}
              </Form.Item>
              
              {FormItemOwnerDirectorInfo(
                errors,
                handleBlur,
                handleChange,
                values,
                setFieldValue
              )}
              <p style={{ marginTop: 20, marginBottom: "0rem" }}>
                {language.loanPersonalInfo.contact}
              </p>
              <Form.Item
                label={language.form.mobileNumber}
                validateStatus={
                  touched.owner_phone && errors.owner_phone && "error"
                }
                help={touched.owner_phone && errors.owner_phone}
              >
                <PhoneInput
                  value={values.owner_phone}
                  inputStyle={{ width: 500 }}
                  name="owner_phone"
                  prefix={""}
                  country={"id"}
                  onChange={(value, option) =>
                    handleChangePhoneInput(value, option, setFieldValue)
                  }
                />
              </Form.Item>
              <Form.Item
                label={language.form.businessEmail}
                validateStatus={
                  touched.owner_email && errors.owner_email && "error"
                }
                help={touched.owner_email && errors.owner_email}
                onBlur={handleBlur}
              >
                <Input
                  style={{ width: isMobile() ? 500 : "100%" }}
                  type="email"
                  placeholder="john@doe.com"
                  size="large"
                  name="owner_email"
                  onChange={handleChange}
                  value={values.owner_email}
                />
              </Form.Item>
              {putPersonalInfoProfileError.data && (
                <Alert
                  type="error"
                  style={{
                    marginBottom: 25,
                    width: isMobile() ? "50%" : "100%",
                  }}
                  message={`${putPersonalInfoProfileError.data.message.en} / ${putPersonalInfoProfileError.data.message.id}`}
                />
              )}
              <Spin
                spinning={isShowLoadingSubmitPeronalInfo}
                style={{
                  width: isMobile() ? "70%" : "100%",
                }}
              >
                <Button
                  htmlType="submit"
                  className="kw-button"
                  size="large"
                  type="primary"
                >
                  {language.button.next}
                </Button>
              </Spin>
              <ModalViewKtp
                visible={isShowViewKtpModal}
                imageModalView={
                  (upload.data && upload.data[0]) ||
                  dataOwnerDirectorInformationLocal.ktp_image
                }
                handleCancel={handleChangeShowViewKtp}
              />
              <ModalViewMatchKtp
                visible={isShowViewMatchKtpModal}
                imageModalView={
                  (uploadSelfie.data && uploadSelfie.data[0]) ||
                  dataOwnerDirectorInformationLocal.selfie_ktp
                }
                handleCancel={handleChangeShowViewMatchKtp}
              />
              <ModalViewKK
                visible={isShowViewKKModal}
                imageModalView={
                  (uploadKK.data && uploadKK.data[0]) ||
                  dataOwnerDirectorInformationLocal.kk_image
                }
                handleCancel={handleChangeShowViewKK}
              />
              <UploadKtpModal
                visible={visibleUploadKtp}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                uploadKtpError={uploadKtpError}
                validationImageKtp={
                  isShowvalidationImageKtpPersonal
                    ? validationImageKtpPersonal
                    : validationImageKtp
                }
                handleChangeShowUploadKtp={handleChangeShowUploadKtp}
                actionChangeKtpUpload={actionChangeKtpUpload}
                token={token}
              />
              <UploadSelfieKtpModal
                visible={visibleUploadSelfieKtp}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                businessInformation={businessInformation}
                uploadKtpError={uploadMatchKtpError}
                //  setSelfieKtp={setSelfieKtp}
                validationMatchImageKtp={
                  isShowvalidationMatchImageKtpFailed
                    ? validationMatchImageKtpFailed
                    : validationMatchImageKtp
                }
                setValidationMatchImageKtp={setValidationMatchImageKtp}
                validationMatchImageKtpFailed={validationMatchImageKtpFailed}
                //  setIsShowAlertMatchKtp={setIsShowAlertMatchKtp}
                // setIsShowAlertUploadMatchKtp={setIsShowAlertUploadMatchKtp}
                setValidationMatchImageKtpFailed={
                  setValidationMatchImageKtpFailed
                }
                handleChangeShowUploadSelfieKtp={
                  handleChangeShowUploadSelfieKtp
                }
              />
              <UploadKkModal
                visible={visibleUploadKk}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                businessInformation={businessInformation}
                setPathKK={setPathKK}
                validationImageKk={validationImageKk}
                setValidationImageKk={setValidationImageKk}
                handleChangeShowUploadKk={handleChangeShowUploadKk}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadBusinessOwnerDirectorKoinVoice,
      actionPutAppGuarantorCreateOwnerDirector,
      businessInformationKoinvoice,
      actionButtonBackUploadRefresh,
      actionButtonBackPersonalInfo,
      getDistrictKoinvoice,
      getSubDistrictKoinvoice,
      buttonBackBusinessInformation,
      actionGetOwnerDirectorInfoLocal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerDirectorInfo);
