const init = {
	data: null
};

const gajiReducer = (state = init, action) => {
  switch (action.type) {    
      case "GET_COMPANY_SUCCESS":
        return {
          ...state,
          companies: action.payload
        }
      case "GET_COMPANY_FAILED":
        return {
          ...state, 
          isGetCompany: false, 
          cpErrors: action.payload
        }
      case "VALIDATE_BANK_ACCOUNT_SUCCESS":
        return {
          ...state,
          isAccountNumberMatch: true
        }
      case "VALIDATE_BANK_ACCOUNT_FAILED":
        return {
          ...state,
          isAccountNumberMatch: false,
          matchErrors: action.payload
        }
      case "VALIDATE_EMPLOYEE_SUCCESS":
        return {
          ...state,
          validatedEmployee: action.payload.validation_status,
          validatedCompany: action.payload.company_detail
        }
      case "VALIDATE_EMPLOYEE_FAILED":
        return {
          ...state,
          validateErrors: action.payload
        }
      case "GET_PARTNER_DATA_SUCCESS":
        return {
          ...state,
          partner: action.payload
        }
      case "GET_PARTNER_DATA_FAILED":
        return {
          ...state,
          getPartnerError: action.payload
        }
      case "APPLY_LOAN_SUCCESS":
        return {
          ...state,
          isApplyLoanSuccess: true
        }
      case "APPLY_LOAN_FAILED":
        return {
          ...state,
          applyErrors: action.payload
        }
      case "LOAN_INFO":
        return {
          ...state,
          loanInfo: action.data
        }
    default:
      return state;
  }
};

export default gajiReducer;