import React from "react";
import FileBase64 from "react-file-base64";
import { Modal } from "react-bootstrap";
import ktpImg from "../../../assets/images/applyloan/ktp-img.png";

const KtpModal = ({
  show,
  handleClose,
  handleDone,
  language
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton className="px-4 pb-0">
      <Modal.Title></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="modal-wrapper px-3">
        <div className="uploadins-wrapper">
          <div className="uploadins-text">
            <h2 className="uploadins-title">{language.title.ktpInstructionModal}</h2>
          </div>

          <figure className="uploadins-img">
            <img src={ktpImg} alt="" />
          </figure>
          <div className="uploadins-text">
            <p className="uploadins-desc">
              {language.text.ktpInstructionModal}
            </p>
            <p className="uploadins-desc mb-1">
              <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
              {language.text.ktpRule1}
            </p>
            <p className="uploadins-desc">
              <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
              {language.text.ktpRule2}
            </p>
            <div className="w-100 btn btn-primary">
              {language.button.chooseImage}
            </div>
            <div className="file-upload">
              <FileBase64
                type="file"
                multiple={false}
                onDone={handleDone}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)

export default KtpModal;