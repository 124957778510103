import React, { Component } from "react";
import { connect } from "react-redux";

import MailResend from "../../../assets/images/dashboard/mailresend.png"
import MailNotSend from "../../../assets/images/dashboard/mailnotsend.png"


class ResendComp extends Component {
  render() {
    let { document } = this.props;
    return (
      <>
        <div className="ldcomp resend">


          {/* resend */}
          {document.isSendLinkSuccess && (
            <div className="ldlnotfound">
              <figure className="modal-img mb-4">
                <img src={MailResend} alt="success" />
              </figure>
              <h3 className="title">We Have Send You An Email</h3>
              <p className="text w-75">Please check your email and upload all the documents required.</p>
            </div>
          )}

          {/* not send */}
          {document.isSendLinkFailed && (
            <div className="ldlnotfound">
              <figure className="modal-img mb-4">
                <img src={MailNotSend} alt="success" />
              </figure>
              <h3 className="title">We Were Unable to Send An Email to You</h3>
              <p className="text w-75">Please make sure your device is connected to the internet to continue.</p>
            </div>
          )}

        </div>
      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  document: state.document
});

export default connect(mapStateToProps, null)(ResendComp);