import React from "react";
import Icon from "./Icon";
import strings from "../../localization/localization";

function PasswordValidator(props) {
  return (
    <div className="password-validator">
      <ul className="password-validator__rules_list">
      <li
          className={
            props.validData.minChars
              ? "password-validator__valid"
              : "password-validator__inValid"
          }
        >
          <i
            className={
              props.validData.minChars ? "icon_valid" : "icon_invalid"
            }
          >
            {props.validData.minChars ? (
              <Icon type="circle_tick_filled" />
            ) : (
              <Icon type="circle_error" />
            )}
          </i>
          &nbsp; &nbsp;
          <span className="error_message">
            {strings.password_validator.characters_count}
          </span>
        </li>
        <li
          className={
            props.validData.lowerCapital
              ? "password-validator__valid"
              : "password-validator__inValid"
          }
        >
          <i
            className={
              props.validData.lowerCapital ? "icon_valid" : "icon_invalid"
            }
          >
            {props.validData.lowerCapital ? (
              <Icon type="circle_tick_filled" />
            ) : (
              <Icon type="circle_error" />
            )}
          </i>
          &nbsp; &nbsp;
          <span className="error_message">
            {strings.password_validator.characters_lower}
          </span>
        </li>
        <li
          className={
            props.validData.capitalLetters
              ? "password-validator__valid"
              : "password-validator__inValid"
          }
        >
          <i
            className={
              props.validData.capitalLetters ? "icon_valid" : "icon_invalid"
            }
          >
            {props.validData.capitalLetters ? (
              <Icon type="circle_tick_filled" />
            ) : (
              <Icon type="circle_error" />
            )}
          </i>
          &nbsp; &nbsp;
          <span className="error_message">
            {strings.password_validator.characters_capital}
          </span>
        </li>

        <li
          className={
            props.validData.numbers
              ? "password-validator__valid"
              : "password-validator__inValid"
          }
        >
          <i
            className={
              props.validData.numbers ? "icon_valid" : "icon_invalid"
            }
          >
            {props.validData.numbers ? (
              <Icon type="circle_tick_filled" />
            ) : (
              <Icon type="circle_error" />
            )}
          </i>
          &nbsp; &nbsp;
          <span className="error_message">
            {strings.password_validator.characters_number}
          </span>
        </li>
        <li
          className={
            props.validData.specChars
              ? "password-validator__valid"
              : "password-validator__inValid"
          }
        >
          <i
            className={
              props.validData.specChars ? "icon_valid" : "icon_invalid"
            }
          >
            {props.validData.specChars ? (
              <Icon type="circle_tick_filled" />
            ) : (
              <Icon type="circle_error" />
            )}
          </i>
          &nbsp; &nbsp;
          <span className="error_message">
            {strings.password_validator.characters_special}
          </span>
        </li>
      </ul>
    </div>
  );
}

export default PasswordValidator;
