import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  en: {
    //securityCode
    security_code: {
      forgot_security_code: "Forgot Security Code / Password ?",
      forgot_security_text_label:
        "Please enter your registered mobile number or email to reset your Security Code / Password.",
      forgot_security_text_label_date_birth:
        "Provide us the information below to continue.",
      forgot_security_phone_and_email: "PHONE NUMBER / EMAIL ADDRESS",
      forgot_security_create_new: "Create New Security Code",
      forgot_security_confirm: "Confirm New Security Code",
      forgot_security_text_label_create_new:
        "Enter your new security code. Your code must be numeric (0-9).",
      forgot_security_text_label_confirm:
        "Enter your new security code once again.",
      forgot_security_success: "LOG IN MY ACCOUNT",
      forgot_security_text_label_success: "Your account successfully recovered",
    },
    otp_code: {
      enter_otp_code: "Enter OTP Code",
      enter_otp_code_text_label:
        "Please enter the verification code we have sent to {0} before continue",
      enter_otp_code_text_label_email:
        "Please enter the verification code we have sent to {0} before continue",
      enter_otp_bussines_account:
        " Hello, your account is registered as a business account. Please click the button below.",
      enter_otp_personal_account:
        "Hello, your account is registered as a personal account. Please click the button below",
    },
    //legend
    legend: {
      submit_button: "SUBMIT",
      button_back: "Back",
      submit_next: "NEXT",
    },
    password_validator: {
      characters_count: "At least 8 characters ",
      characters_lower: "Include a lowercase",
      characters_capital: "Include a uppercase",
      characters_number: "Contain a number (0,1,3,...)",
      characters_special: "Contain a special character (!@#$%. etc)",
      password_new: "New Password",
      password_confirm_new: "Confirm New Password",
      create_new_password: "Create New Password",
      create_new_password_text_label: "Please enter your new password",
    },
    password_security_business: {
      password_forgot_business: "Forgot Your Password?",
      password_forgot_business_text_label:
        "Please enter your registered your Email for {0}",
      password_business_email: "BUSINESS EMAIL",
      password_sucess_confirm: "Your account successfully recovered",
      password_validation_kriteria:
        "Password Criteria Do not meet the requirements yet",
      password_allready_account:
        "Your email is registered as a Personal account",
      password_reset_account: "Reset Password Business",
      password_reset_account_personal: "Reset Password Personal",
    },
    error: {
      errorTitle: "Page Not Found",
      errorDesc:
        "We're sorry, the page you requested was not found. Likely the page has been deleted, or You write down the URL.",
      errorButton: "Back to Home",
      errorFetch: "Unable to fetch data. Please try again in a few minutes.",
      serverError: "Internal Server Error",
      serverDesc: "Please try again in a few minutes.",
      failedFetch: "Failed to fetch",
    },
  },
  id: {
    //securityCode
    security_code: {
      forgot_security_code: "Lupa Kode Keamanan atau Pin?",
      forgot_security_text_label:
        "Silakan masukkan nomor telepon / email yang terdaftar untuk atur ulang Kode Keamanan-mu atau Pin.",
      forgot_security_text_label_date_birth:
        "Silahkan input data berikut untuk melanjutkan.",
      forgot_security_phone_and_email: "NOMOR TELEPON / ALAMAT EMAIL",
      forgot_security_create_new: "Buat Kode Keamanan Baru",
      forgot_security_confirm: "Konfirmasikan Kode Keamanan Baru",
      forgot_security_text_label_create_new:
        "Masukkan kode keamanan baru Anda. Kode Anda harus berupa angka (0-9).",
      forgot_security_text_label_confirm:
        "Masukkan kode keamanan barumu sekali lagi.",
      forgot_security_success: "MASUK KE AKUN SAYA",
      forgot_security_text_label_success: "Kode Keamanan Berhasil Berubah",
    },
    otp_code: {
      enter_otp_code: "Masukkan Kode OTP",
      enter_otp_code_text_label:
        "Silahkan masukkan kode verifikasi yang telah kami kirimkan ke {0} sebelum melanjutkan",
      enter_otp_code_text_label_email:
        "Silahkan masukkan kode verifikasi yang telah kami kirimkan ke {0} sebelum melanjutkan",
      enter_otp_bussines_account:
        "Halo, akun Anda terdaftar sebagai akun bisnis. Silahkan Klik Tombol Di bawah Ini",
      enter_otp_personal_account:
        "Halo, akun Anda terdaftar sebagai akun personal. Silahkan Klik Tombol Di bawah Ini",
    },
    //legend
    legend: {
      submit_button: "KIRIM",
      submit_next: "LANJUT",
      button_back: "Kembali",
    },
    password_validator: {
      characters_count: "Setidaknya 8 karakter ",
      characters_lower: "Termasuk huruf kecil",
      characters_capital: "Termasuk huruf besar",
      characters_number: "Berisi angka (0, 1, 3, ..)",
      characters_special: "Berisi karakter khusus (! @ # $%. Dll)",
      password_new: "Kata Sandi Baru",
      password_confirm_new: "Konfirmasi Kata Sandi Baru",
      create_new_password: "Buat Kata Sandi Baru",
      create_new_password_text_label: "Silahkan masukkan kata sandi yang baru",
    },
    password_security_business: {
      password_forgot_business: "Lupa Kata Sandi Anda?",
      password_forgot_business_text_label:
        "Silakan masukkan Email Anda yang terdaftar untuk {0}",
      password_business_email: "EMAIL BISNIS",
      password_sucess_confirm: "Kata sandi berhasil diubah",
      password_validation_kriteria:
        "Kriteria Kata Sandi Belum memenuhi persyaratan",
      password_allready_account: "Email Anda terdaftar sebagai akun Pribadi",
      password_reset_account: "Atur Ulang Kata Sandi Bisnis",
      password_reset_account_personal: "Atur Ulang Kata Sandi Pribadi",
    },
    error: {
      errorTitle: "Halaman Tidak Ditemukan",
      errorDesc:
        "Mohon maaf, halaman yang Anda cari tidak ditemukan. Kemungkinan halaman telah dihapus, atau Anda salah menuliskan URL.",
      errorButton: "Kembali ke Beranda",
      errorFetch:
        "Tidak dapat mengambil data. Silahkan coba lagi dalam beberapa menit.",
      serverError: "Kesalahan Server Internal",
      serverDesc: "Silahkan coba lagi dalam beberapa menit.",
      failedFetch: "Gagal Memuat",
    },
  },
});

export default strings;
