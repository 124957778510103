import React from "react";
import { Card, Button } from "antd";
import { WaitingForContractStyle } from "../../WaitingForContract/style";
import waitingImg from "../../assets/images/loading.png";


function WaitingForOfferingChild({
  language,
  limitInformation,
  actionChangeShowDocumentList,
  isChild
}) {

  return (
    <Card>
      <div
        style={{
          borderBottom: "1px solid #CCCCCC",
          marginBottom: 20,
        }}
      >
        <div>
          {" "}
          <Button size="large" onClick={() => actionChangeShowDocumentList()}>
            {language.button.back}
          </Button>
        </div>
        <h4 style={{ textAlign: "center" }}>{language.title.offeringProcess}</h4>
      </div>
      <WaitingForContractStyle>
          <div className="kw-waitingFor-contract">
            <figure style={{ textAlign: 'center' }}>
              <img src={waitingImg} alt="" />
            </figure>
            <strong className="kw-waitingFor-contract__label">
              {
                isChild ? (
                  <p style={{ marginBottom: 0, lineHeight: 1.5, textAlign: 'center'}}>
                    {language.text.offeringChild1} <b>{limitInformation.ucl_parent_name}</b>.<br/>
                    {language.text.offeringChild2}
                  </p>
                ) : (
                  <p style={{ marginBottom: 0, lineHeight: 1.5, textAlign: 'center'}}>
                    {language.text.offeringChild2}
                  </p>
                )
              }
            </strong>
            
          </div>
        </WaitingForContractStyle>
    </Card>
  );
}

export default WaitingForOfferingChild;
