import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeLanguage } from "../stores/actions/account";
import { Route, Redirect, Link } from "react-router-dom";
import cookie from "react-cookies";
// import { getDecodedToken } from "../services/token";
import { Button, Badge, Divider, Card, List, Popover, Spin } from "antd";
import { BellOutlined } from "@ant-design/icons";
import koinvoiceLogo from "../assets/images/icon-koininvoice.png";
import { Dropdown } from "react-bootstrap";

import Sidenav from "../Components/Sidenav";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";
import notification from "../services/Notification";
import "./style.css";

function PublicRoute({
  component: Component,
  init: { flag },
  koinvoice: { businessInformation },
  account,
  changeLanguage,
  restricted,
  login,
  ...rest
}) {
  const [listNotifications, setListNotifications] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [notificationTypeFilter, setNotificationTypeFilter] = useState({
    slug: "all",
    label: "ALL NOTIFICATIONS",
  });
  const [notificationStatusFilter, setNotificationStatusFilter] = useState({
    slug: "all",
    label: "ALL STATUS",
  });
  const [
    isNotificationDropdownVisible,
    setNotificationDropdownVisible,
  ] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const toggleLanguage = ({ target }) => {
    localStorage.setItem("language", target.value);
    changeLanguage(target.value);
  };
  const notificationStatus = [
    {
      slug: "all",
      label: "ALL STATUS",
    },
    {
      slug: "unread",
      label: "UNREAD",
    },
    {
      slug: "read",
      label: "READ",
    },
  ];

  const notificationTypes = [
    {
      slug: "all",
      label: "ALL NOTIFICATIONS",
    },
    {
      slug: "company_update",
      label: "COMPANY UPDATE",
    },
    {
      slug: "products",
      label: "PRODUCTS",
    },
    {
      slug: "event",
      label: "EVENT",
    },
    {
      slug: "payment",
      label: "PAYMENT",
    },
  ];

  function handleNotificationDropdownVisibilityChange(flag) {
    setNotificationDropdownVisible(flag);
  }

  function resetAllNotificationFilter() {
    setNotificationTypeFilter(notificationTypes[0]);
    setNotificationStatusFilter(notificationStatus[0]);
  }

  function toggleNotificationType() {
    let notificationTypeIndex = notificationTypes.findIndex(
      (notificationType) =>
        notificationType.slug === notificationTypeFilter.slug
    );
    if (notificationTypeIndex >= notificationTypes.length - 1) {
      setNotificationTypeFilter(notificationTypes[0]);
      return;
    }
    setNotificationTypeFilter(notificationTypes[notificationTypeIndex + 1]);
  }

  function toggleNotificationStatus() {
    let notificationStatusIndex = notificationStatus.findIndex(
      (notificationStatus) =>
        notificationStatus.slug === notificationStatusFilter.slug
    );
    if (notificationStatusIndex >= notificationStatus.length - 1) {
      setNotificationStatusFilter(notificationStatus[0]);
      return;
    }
    setNotificationStatusFilter(
      notificationStatus[notificationStatusIndex + 1]
    );
  }

  async function getStatusUnread() {
    let statusUnread = await notification.statusUnread({
      loading: setIsShowLoading,
    });
    setUnreadNotificationCount(
      statusUnread && statusUnread.data && statusUnread.data.UnreadNotification
    );
  }

  function notificationDocuments() {
    getNotifications(unreadNotificationCount);
  }

  useEffect(() => {
    notificationDocuments();
    getStatusUnread();
    // eslint-disable-next-line
  }, [unreadNotificationCount]);

  useEffect(() => {
    notificationDocuments();
    // eslint-disable-next-line
  }, [notificationTypeFilter, notificationStatusFilter]);

  function getNotifications(limit, page = 1) {
    let paramsGetAll = {
      limit: limit,
    };
    if (notificationTypeFilter.slug !== "all") {
      paramsGetAll.type = notificationTypeFilter.slug;
    }
    if (notificationStatusFilter.slug !== "all") {
      paramsGetAll.status = notificationStatusFilter.slug;
    }
    notification
      .getAll({ params: paramsGetAll, loading: setIsShowLoading })
      .then((allNotifications) => {
        setListNotifications(allNotifications && allNotifications.data);
      });
  }

  function handleNotificationClick(id) {
    notification
      .statusRead({
        id: id,
        loading: setIsShowLoading,
      })
      .then(() => {
        notificationDocuments();
        getStatusUnread();
      });
  }

  const NotificationItem = function (props) {
    const { item, onClick } = props;

    function handleClick() {
      onClick(item.ID);
    }
    return (
      <div style={{ width: 375 }}>
        <Divider style={{ margin: 0 }} />
        <Link
          to="#"
          className={
            item.SendingStatus !== "read"
              ? "notification-link active"
              : "notification-link"
          }
          onClick={handleClick}
        >
          <List.Item style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <List.Item.Meta title={item.Title} description={item.Description} />
          </List.Item>
        </Link>
      </div>
    );
  };

  function handleTogle() {
    var sidenav = document.getElementById("sidenav");
    sidenav.classList.toggle("show");
  }

  function handleLogout() {
    cookie.remove("token", { path: "/" });
    cookie.remove("name", { path: "/" });
    cookie.remove("email", { path: "/" });
    localStorage.removeItem("creditLimitLoan")
    window.location.reload();
  }

  const itemNotification = (
    <Card>
      <div>
        {/* <span onClick={hide} style={{}} className="kw-dashboardLayout-close">
          X
        </span> */}
        <div style={{ padding: 10 }}>
          <Button
            type="primary"
            style={{ margin: 5 }}
            onClick={toggleNotificationType}
            ghost
          >
            {notificationTypeFilter.label}
          </Button>
          <Button
            type="primary"
            style={{ margin: 5 }}
            onClick={toggleNotificationStatus}
            ghost
          >
            {notificationStatusFilter.label}
          </Button>
          <span
            style={{
              margin: 10,
              color: "#5892C2",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={resetAllNotificationFilter}
          >
            Read All
          </span>
        </div>
        <Divider style={{ margin: 0 }} />
        <List
          itemLayout="horizontal"
          loading={isShowLoading}
          style={{
            height:
              listNotifications !== undefined && listNotifications.length > 3
                ? 500
                : "auto",
            overflow: "auto",
          }}
          dataSource={listNotifications}
          renderItem={(item) => (
            <NotificationItem item={item} onClick={handleNotificationClick} />
          )}
        />
      </div>
    </Card>
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        !cookie.load("token") ? (
          <Redirect to="/login" />
        ) : (
          <div className="dashboard-layout">
            {hostNameCheckCondition ? (
              ""
            ) : (
              <aside id="sidenav" className="show">
                <Sidenav />
              </aside>
            )}
            <main className="main">
              <nav className="navbar">
                {hostNameCheckCondition ? (
                  <figure className="logo-wrapper m-0">
                    <img src={koinvoiceLogo} alt="koin employee" />
                  </figure>
                ) : (
                  <button
                    id="nav-button"
                    className="navbar-toggler"
                    type="button"
                    onClick={handleTogle}
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                )}
                <div className="nav-wrapper" style={{ alignItems: "center" }}>
                  <div className="nav-item">
                    {hostNameCheckCondition ? (
                      <Popover
                        content={itemNotification}
                        title=""
                        trigger="click"
                        visible={isNotificationDropdownVisible}
                        onVisibleChange={
                          handleNotificationDropdownVisibilityChange
                        }
                      >
                        <Spin spinning={isShowLoading}>
                          <Badge count={unreadNotificationCount}>
                            <BellOutlined
                              style={{ fontSize: 20, cursor: "pointer" }}
                            />
                          </Badge>
                        </Spin>
                      </Popover>
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-notif"
                          className="btn-profile btn-notif pr-0"
                        >
                          <i className="icon-notification mr-0"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  <div className="nav-item">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        id="dropdown-profile"
                        className="btn-profile"
                      >
                        <i className="icon-user"></i>
                        <span>{cookie.load("name")}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                            style={{ padding: "0px 10px 0px 10px", color: "rgb(142, 142, 142)" }}
                          >
                            {cookie.load("email")}
                          </div>
                        <div
                          style={{ padding: "10px 10px 20px 10px", color: "rgb(142, 142, 142)" }}
                        >
                          {businessInformation?.data?.code}
                        </div>
                        <Dropdown.Item onClick={handleLogout}>
                          Logout
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="nav-item">
                    <div className="lang-selector" onChange={toggleLanguage}>
                      <input
                        id="lang-en"
                        className="lang"
                        type="radio"
                        value="en"
                        name="language"
                        defaultChecked={flag === "en"}
                      />
                      <label htmlFor="lang-en">EN</label>
                      <input
                        id="lang-id"
                        className="lang"
                        type="radio"
                        value="id"
                        name="language"
                        defaultChecked={flag === "id"}
                      />
                      <label htmlFor="lang-id">ID</label>
                    </div>
                  </div>
                </div>
              </nav>
              <div
                className={
                  (hostNameCheckCondition &&
                    window.location.pathname.split("/")[1] === "dashboard-limit-application") ||
                  (hostNameCheckCondition &&
                    window.location.pathname.split("/")[1] === "dashboard-loan-application") ||
                  (hostNameCheckCondition &&
                    window.location.pathname.split("/")[1] === "schedule") ||
                  (hostNameCheckCondition &&
                    window.location.pathname.split("/")[1] === "education") ||
                  (hostNameCheckCondition &&
                    window.location.pathname.split("/")[1] === "document-list")
                    ? ""
                    : "content-wrapper"
                }
              >
                <Component {...props} login={login} />
              </div>
            </main>
          </div>
        )
      }
    />
  );
}

// export default PublicRoute;
const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeLanguage }, dispatch);
};

export const DashboardRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicRoute);
