import styled from "styled-components";

export const DrawerShortKoinInvoiceStyle = styled.div`
  .kw-filterKoinInvoice-wrapper {
    .kw-button {
      background-color: #2b476c;
      border-color: #2b476c;
      color: #ffffff;
      width: 100%;
    }
    h3 {
      text-align: center;
      color: #2b486d;
      font-weight: bold;
      font-size: 28px;
    }
    p {
      margin-top: 20px;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      text-align: center;
      text-decoration-line: underline;
      text-transform: uppercase;
      color: #566068;
      border-radius: 3px;
    }
    background-color: transparent;
    position: relative;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    margin: 0px auto;
    padding: 0px 16px 80px;
  }
`;
