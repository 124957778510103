import { Modal } from "antd";
import React from "react";

export default function ModalImagePreview({
  isModalVisible,
  handleCancel,
  list,
}) {
  return (
    <Modal
      title=" "
      visible={isModalVisible}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={handleCancel}
    >
      <img src={list?.url} style={{ width: "100%" }} alt="" />
    </Modal>
  );
}
