import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBalance } from "../stores/actions/balance";
import NumberFormat from "react-number-format";
import { Link, NavLink } from "react-router-dom";
import employeeLogo from "../assets/images/koinemployee.png";
import koinvoiceLogo from "../assets/images/icon-koininvoice.png";
import gplay from "../assets/images/gplay.png";
import appstore from "../assets/images/appstore.png";
import { Nav } from "react-bootstrap";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";

class Sidenav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabClassName: "dashboard",
    };
  }

  componentDidMount() {
    let { auth } = this.props;
    this.props.getBalance(auth.token);
  }

  render() {
    let {
      balance,
      init: { language },
    } = this.props;

    // const { location } = props;

    return (
      <div className="sidenav-wrapper">
        <div className="top">
          <figure className="logo-wrapper m-0">
            <img
              src={hostNameCheckCondition ? koinvoiceLogo : employeeLogo}
              alt="koin employee"
            />
          </figure>
          <div className="content">
            <div className="cash-wrapper">
              <div className="cash-item">
                <span className="text">Cash</span>
                <span className="num">
                  <NumberFormat
                    value={balance.data ? balance.data.available_cash : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={2}
                  />
                </span>
              </div>
              <div className="cash-item">
                <span className="text">KOIN</span>
                <span className="num">
                  <NumberFormat
                    value={balance.data ? balance.data.available_koin : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalSeparator="."
                    decimalScale={2}
                  />
                </span>
              </div>
            </div>
            <div className="navigation">
              <div className="nav-label">{language.label.navigation}</div>
              <Nav
                className="nav-wrapper"
                //  activeKey={location.pathname}
              >
                {/* <div className={(this.state.activeTabClassName === "dashboard") ? "nav-item active" : "nav-item"}>
                  <Link to="/dashboard">
                    <i className="icon-dashboard"></i>
                    <span>{language.label.dashboard}</span>
                  </Link>
                </div>
                <div className={(this.state.activeTabClassName === "schedule") ? "active" : "nav-item"}>
                  <Link to="/schedule">
                    <i className="icon-schedule"></i>
                    <span>{language.label.schedule}</span>
                  </Link>
                </div>
                <div className={(this.state.activeTabClassName === "education") ? "active" : "nav-item"}>
                  <Link to="education">
                    <i className="icon-education"></i>
                    <span>{language.label.education}</span>
                  </Link>
                </div>
                <div className={(this.state.activeTabClassName === "document") ? "active" : "nav-item"}>
                  <Link to="document">
                    <i className="icon-document"></i>
                    <span>{language.label.document}</span>
                  </Link>
                </div> */}

                <NavLink
                  as={Link}
                  to="/dashboard"
                  className="nav-link"
                  activeclassname="active"
                >
                  <i className="icon-dashboard"></i>
                  <span>{language.label.dashboard}</span>
                </NavLink>
                <NavLink
                  as={Link}
                  to="/schedule"
                  className="nav-link"
                  activeclassname="active"
                >
                  <i className="icon-schedule"></i>
                  <span>{language.label.schedule}</span>
                </NavLink>
                <Nav.Link
                  as={Link}
                  to="/education"
                  className="nav-link"
                  activeclassname="active"
                >
                  <i className="icon-education"></i>
                  <span>{language.label.education}</span>
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/document"
                  className="nav-link"
                  activeclassname="active"
                >
                  <i className="icon-document"></i>
                  <span>{language.label.document}</span>
                </Nav.Link>
              </Nav>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="download-app">
            <figure className="app m-0">
              <a
                href="https://play.google.com/store/apps/details?id=com.koinworks.app&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={gplay} alt="google play" />
              </a>
            </figure>
            <figure className="app m-0">
              <a
                href="https://apps.apple.com/id/app/koinworks/id1457026579"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appstore} alt="app store" />
              </a>
            </figure>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
  auth: state.auth,
  balance: state.balance,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getBalance }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
