const init = {
	data: null
};

const loanReducer = (state = init, action) => {
  switch (action.type) {
    case "GET_COMPANIES_SUCCESS":
      return {
        ...state,
        companies:action.payload.companies,
        vendor: action.payload.vendor
      };
    case "WORK_INFO":
      return { ...state, data: action.data };
    case "LOAN_INFO":
      return { ...state, data: action.data };
      case "PERSONAL_INFO":
        return { ...state, data: action.data };
      case "SKIP_CONNECT":
        return { ...state, data: action.data };
      case "REQUEST_LOADING":
        return { ...state, isLoading: true };
      case "REQUEST_LOAN_SUCCEEDED":
        return { ...state, isLoanSuccess: true }
      case "REQUEST_LOAN_FAILED":
        return { ...state, isLoanFailed: true, errors: action.payload.en }
      case "GET_DISTRICT_SUCCESS":
        return { ...state, listDistrict: action.payload }
      case "CLEAR_DISTRICT":
        return { ...state, listDistrict:[] }
      case "GET_SUBDISTRICT_SUCCESS":
        return { ...state, listSubdistrict: action.payload }
      case "CLEAR_SUBDISTRICT":
        return { ...state, listSubdistrict:[] }
      case "GET_LOANS_SUCCESS":
        return { ...state, loans: action.payload }
      case "GET_ACTIVE_LOANS_SUCCESS":
        return { ...state, activeLoans: action.payload, getActiveLoanFailed: false }
      case "GET_ACTIVE_LOANS_FAILED":
        return { ...state, getActiveLoanFailed: true }
      case "GET_LOANDETAIL_SUCCESS":
        return { ...state, loanDetail: action.payload }
      case "GET_LOANDETAIL_FAILED":
        return { ...state, isGetLoanDetailFailed: true }
      case "GET_LOANPROGRESS_SUCCESS":
        return { ...state, loanProgress: action.payload }
      case "GET_LOANPROGRESS_FAILED":
        return { ...state, isGetLoanProgressFailed: true }
      case "GET_LOANSCHEDULE_SUCCESS":
        return { ...state, loanSchedule: action.payload }
      case "GET_LOANSCHEDULE_FAILED":
        return { ...state, isGetLoanScheduleFailed: true }
      case "GET_LOANCONTRACT_SUCCESS":
        return { ...state, loanContract: action.payload }
      case "GET_LOANCONTRACT_FAILED":
        return { ...state, isGetLoanContractFailed: true }
      case "GET_LOANLENDER_SUCCESS":
        return { ...state, loanLender: action.payload }
      case "GET_LOANLENDER_FAILED":
        return { ...state, isGetLoanLenderFailed: action.payload }
    default:
      return state;
  }
};

export default loanReducer;