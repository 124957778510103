import React from "react";
import { Drawer } from "antd";
import { DrawerStyle } from "./drawer.js";

function DrawerSuccessLink({
  visible,
  actionShowDrawerSucsessResendLink,
  language,
}) {
  return (
    <Drawer
      placement={"bottom"}
      closable={false}
      height={400}
      visible={visible}
    >
      <DrawerStyle>
        <div className="kw-drawer-sucessLink">
          <img
            src={require("../../../assets/images/applyloan/send-email.png").default}
            style={{ width: 100, margin: "auto", marginTop: 35 }}
            alt=""
          />
          <h3>{language.loanUnggahDocument.emailHeading}</h3>
          <p>{language.loanUnggahDocument.paragraphOne}</p>
          <p
            className="kw-drawer-sucessLink__buttonClose"
            onClick={()=> actionShowDrawerSucsessResendLink("closeDariDrawer")
            }
          >
            {language.button.close}
          </p>
        </div>
      </DrawerStyle>
    </Drawer>
  );
}

export default DrawerSuccessLink;
