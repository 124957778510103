export const personalParams = state => {
  let { personal } = state;
  let data = {
    product: "koin_employee",
    fullname: {
      value: personal.name
    },
    date_of_birth: {
      value: personal.dob
    },
    gender: {
      value: personal.gender
    },
    email: {
      value: personal.email
    },
    phone_number: {
      value: personal.phone
    },
    phone_area: {
      value: "62"
    }
  }

  return data;
}

export const legalParams = (state, props) => {
  let { personal, checkedBox } = state;
  let { upload } = props;

  let meta = {};
  if (upload.spouse_ktp) {
    meta['document.personal_spouse_document.ktp'] = {
      value: upload.spouse_ktp[0]
    }
  }

  let ktpVal = upload.ktp ? {
    value: upload.ktp[0],
    ocr_result: null,
    ocr_status: false
  } : {};

  let selfieVal = upload.selfie ? {
    value: upload.selfie[0],
    ocr_result: null,
    ocr_status: false
  } : {};

  let data = {
    product: "koin_employee",
    address: {
      value: personal.address
    },
    currently_live_here: {
      value: checkedBox ? "1" : "0"
    },
    district: {
      value: personal.district
    },
    sub_district: {
      value: personal.subdistrict
    },
    fullname: {
      value: personal.name
    },
    ktp: ktpVal,
    marital_status: {
      value: personal.marital_status
    },
    nik: {
      value: personal.nik
    },
    place_of_birth: {
      value: personal.pob
    },
    religion: {
      value: personal.religion
    },
    residency_address: {
      value: personal.current_address
    },
    residency_district: {
      value: personal.current_district
    },
    residency_sub_district: {
      value: personal.current_subdistrict
    },
    selfie_ktp: selfieVal,
    metas: meta
  }

  return data;
}

export const emergencyParams = state => {
  let { emergency } = state;
  let meta = {};

  meta['personal.emergency_contact.full_name'] = {
    value: emergency.name
  };
  meta['personal.emergency_contact.phone_number'] = {
    value: emergency.phone
  };
  meta['personal.emergency_contact.relationship'] = {
    value: emergency.relation
  }

  let data = {
    metas: meta,
    product: "koin_employee" 
  }

  return data;
}

export const financialParams = (state, profile) => {
  let { occupation } = profile;
  let meta = {};
  meta["personal.occupation.net_salary"] = {
    value: !state.salary && occupation && occupation.net_salary ? 
      occupation.net_salary.value.toString() : state.salary.toString()
  };

  let data = {
    product : "koin_employee",
    occupation: {
      value: state.jobFunction
    },
    source_of_income: {
      value: "Salary"
    },
    position_at_company: {
      value: !state.position && occupation ? occupation.position_at_company : state.position
    },
    years_of_working: {
      value: !state.workingYear && occupation ? occupation.years_of_working : state.workingYear
    },
    company_name: {
      value: state.vendor_data.vendor_name
    },
    company_address: {
      value: state.vendor_data.address
    },
    company_location: {
      value: ""
    },
    relation_with_source_of_income: {
      value: ""
    },
    metas: meta
  }

  return data;
}