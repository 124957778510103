import React, { useEffect, useState } from "react";
import CashKoinWrapper from "../Components/CashKoinWrapper";
import AvailableCreditProgress from "../Components/AvailableCreditProgress";
import {
  getAplicationProsess,
  getLoanLimit,
  getLoanBalance,
} from "../stores/actions/koinvoiceLoanDashboard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApplicationProgressLoan from "../Components/ApplicationProgressLoan/ApplicationProgressLoan";
import koinvoiceServices from "../services/koinvoice";

const mapStateToProps = (state) => ({
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAplicationProsess,
      getLoanLimit,
      getLoanBalance,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function BorrowerDashboardKoinInvoiceLoanList({
  koinvoiceDashboard: { loanApplication, loanLimit, loanBalance },
  getAplicationProsess,
  actionChangeShowYourLoan,
  language,
  getLoanLimit,
  getLoanBalance,
  setCreditUclLimitParent
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [creditUclLimit, setCreditUclLimit] = useState({});
  useEffect(() => {
    getAplicationProsess(setIsShowLoading);
    getLoanLimit();
    getLoanBalance();
    koinvoiceServices.actionGetCreditLimitLoan({}).then((res) => {
      if (res.status === 200) {
        setCreditUclLimit(res.data);
        setCreditUclLimitParent(res.data);
      }
    }); // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <CashKoinWrapper
        loanBalance={loanBalance}
        limit={creditUclLimit}
        language={language} 
      />
      <AvailableCreditProgress
        loanLimit={loanLimit}
        creditUclLimit={creditUclLimit}
        actionChangeShowYourLoan={actionChangeShowYourLoan}
        language={language}
      />
      <ApplicationProgressLoan
        loanApplication={loanApplication}
        language={language}
        isShowLoading={isShowLoading}
        actionChangeShowYourLoan={actionChangeShowYourLoan}
      />
    </React.Fragment>
  );
});
