import React, { useEffect, useState } from "react";
import CashKoinWrapper from "../Components/CashKoinWrapper";
import {
  getAplicationProsess,
  getLoanBalance,
} from "../stores/actions/koinvoiceLoanDashboard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card } from "antd";
import ApplicationProgressLimit from "../Components/ApplicationProgress/ApplicationProgressLimit";

function BorrowerDashboardKoinInvoice({
  koinvoiceDashboard: { loanApplication, loanBalance },
  getAplicationProsess,
  actionChangeShowYourLoan,
  creditLimitLoan,
  language,
  getLoanBalance,
}) {
  const [isShowLoading,setIsShowLoading] = useState(false)
  
  useEffect(() => {
    getAplicationProsess(setIsShowLoading);
    getLoanBalance(); // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <CashKoinWrapper
        loanBalance={loanBalance}
        limit={creditLimitLoan}
        language={language}
      />
      <ApplicationProgressLimit
        loanApplication={loanApplication}
        creditLimitLoan={creditLimitLoan}
        language={language}
        isShowLoading={isShowLoading}
        actionChangeShowYourLoan={actionChangeShowYourLoan}
      />
      <Card style={{height:"30vh"}} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAplicationProsess,
      getLoanBalance,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BorrowerDashboardKoinInvoice);
