import styled from "styled-components";

export const SecurityCodeConfirmPageWrapper = styled.div`
  background: #f2f2f2;

  a {
    text-decoration: underline;
  }

  .page-content-wrapper {
    /* width: 50%; */
    margin: 0 auto;
    padding-top: 90px;
  }
  .logo-wrapper {
    position: relative;
    top: -65px;
    width: 131px;
    margin: 0 auto;
    .logo-img {
      position: absolute;
    }
  }
  .register-body {
    /* padding-top: 80px; */
    padding-bottom: 35px;
    .register-title {
      text-align: center;

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 33px;
      line-height: 41px;
      /* or 121% */

      text-align: center;
      letter-spacing: 0.41px;

      color: #2b486d;
    }
    .text-note {
      font-size: 15px;
    }
  }

  .input-wrapper {
    margin-bottom: 15px;
  }
  .back-button {
    color: #c1c1c1;
    padding: 15px;
    font-size: 18px;
    i {
      margin-right: 7px;
    }
  }
  .otp-input {
    justify-content: center;
    input {
      height: 50px !important;
      width: 50px !important;
      border: none;
      border: 1.5px solid #dcdcdc;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 10px !important;
    }
  }
  .resend-wrapper {
    padding: 25px;
    padding-bottom: 15px;
    .resend-btn {
      justify-content: center !important;
      color: #c4c4c4;
    }
  }
`;
