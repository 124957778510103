import React from 'react';
import firebase from '../firebase';

const FlagsContext = React.createContext({});
const remoteConfig = firebase.remoteConfig();

// For development only
// remoteConfig.settings = {
//   minimumFetchIntervalMillis: 3600000,
// };

const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = React.useState(defaults);

  React.useEffect(() => {
    remoteConfig.defaultConfig = defaults;

    remoteConfig
      .fetchAndActivate()
      .then(activated => {
        return remoteConfig.getAll();
      })
      .then(remoteFlags => {
        const newFlags = {
          ...flags,
        };

        for (const [key, config] of Object.entries(remoteFlags)) {
          newFlags[key] = config.asString();
        }

        setFlags(newFlags);
      })
      .catch(error => console.error(error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlagsContext.Provider value={{
      flags: flags
    }}>
      {children}
    </FlagsContext.Provider>
  );
};

export const useFlags = () => React.useContext(FlagsContext);
export default FlagsProvider;