import React, { useEffect } from "react";
import { ApplyLoanKoinVoiceStyle } from "./style";
import { connect } from "react-redux";
import DetailsLoan from "./components/DetailsLoan/DetailsLoan";
import {
  businessInformationKoinvoice,
  getApplicationList,
} from "../stores/actions/koinvoiceLoan";
import { getLoanLimit } from "../stores/actions/koinvoiceLoanDashboard";
import { bindActionCreators } from "redux";
import { Aes256CbcDecrypt } from "../library/chiper";

function LMSApplyLoan({
  getLoanLimit,
  getApplicationList,
  businessInformationKoinvoice,
  match
}) {
  const { params } = match;
  let decodedString = Aes256CbcDecrypt(params.token, params.id);
  localStorage.setItem('user-' + params.id, JSON.stringify(decodedString));

  useEffect(() => {
    businessInformationKoinvoice(decodedString.token);
    getLoanLimit(decodedString.token);
    getApplicationList(decodedString.token); // eslint-disable-next-line
  }, []);

  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <DetailsLoan
          token={decodedString.token}
          userId={decodedString.userId} />
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInformationKoinvoice,
      getLoanLimit,
      getApplicationList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LMSApplyLoan);
