import styled from "styled-components";

export const CalculatorKoinVoiceStyle = styled.div`
  .kw-button {
    background-color: #2b476c !important;
    border-color: #2b476c !important;
    color: #ffffff !important;
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    .kw-button {
      width: 100%;
    }
  }
  .kw-calCulatorKoinVoice-wrapper {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
    border-radius: 3px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 45px;
      text-align: center;
      -webkit-letter-spacing: 0.41px;
      -moz-letter-spacing: 0.41px;
      -ms-letter-spacing: 0.41px;
      letter-spacing: 0.41px;
      color: #071e35;
      margin-top: 22px;
    }
    &__card-detailsCalculator {
      background: #f4f4f4;
      padding: 20px;
      width: 80%;
      @media screen and (max-width: 500px) {
        width: 100%;
      }
      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
        display: block;
        color: #8e8e8e;
      }
      strong {
        font-weight: normal;
        margin-bottom: 20px;
        display: block;
        font-size: 22px;
        letter-spacing: 0.35px;
        color: #2d2d2d;
      }
      p {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #8e8e8e;
      }
    }
    &__card-labelDetails {
      background: #378df4;
      padding: 15px;
      width: 80%;
      @media screen and (max-width: 500px) {
        width: 100%;
      }
      h5 {
        color: #ffffff;
      }
    }
  }
`;
