import React from "react";
import { convertToCategoryName } from "../../../../library/regex";
import { ShareHolderStyle } from "./ShareHolder.style";

export default function ShareHolderListCard({
  list,
  actionChangeListCardData,
  language
}) {
  const { full_name, properties } = list;
  return (
    <ShareHolderStyle>
      <div
        className="kw-ShareHolderListCard-wrapper"
        onClick={() => actionChangeListCardData(list, "edit")}
      >
        <strong>
          {" "}
          {full_name}
          <label className="kw-ShareHolderListCard-wrapper__edit">
            {language.button.edit}
          </label>
        </strong>
        <span style={{display:"block"}}>{properties && properties.jabatan && convertToCategoryName(properties.jabatan)}</span>
        <span>{properties && properties.saham  && `${properties.saham} % Share`}</span>
      </div>
    </ShareHolderStyle>
  );
}
