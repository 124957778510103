import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  putLoanDetailsKoinvoice,
  actionGetBusinessInfoLocal,
} from "../../../stores/actions/koinvoiceLoan";
import { bindActionCreators } from "redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Select,
  InputNumber,
  Form,
  Button,
  Input,
  DatePicker,
  Spin,
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import cookie from "react-cookies";
import history from "../../../util/history";
import koinvoiceServices from "../../../services/koinvoice";
import CongrulationsStarsLoan from "../../../Components/CongrulationsStarsLoan/CongrulationsStarsLoan";
import { useFlags } from "../../../util/FlagsProvider";

const { Option } = Select;

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putLoanDetailsKoinvoice,
      actionGetBusinessInfoLocal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function DetailsLoan({
  init: { language },
  putLoanDetailsKoinvoice,
  actionGetBusinessInfoLocal,
  koinvoice: { dataBusinessInfoLocal },
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [borrowerInformation, setBorrowerInformation] = useState({});
  const [uclCreditLimitLoan, setUclCreditLimitLoan] = useState({});
  const [payloadSubmit, setPayloadSubmit] = useState({});
  const [loanInvoice, setLoanInvoice] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [
    isShowAlertDrawerCongrulation,
    setIsShowAlertDrawerCongrulation,
  ] = useState(false);
  const [validateBorrowerTypeCsf, setValidateBorrowerTypeCsf] = useState(
    undefined
  );
  const { 
    flags: { ki_maximum_loan } 
  } = useFlags();

  const loanTermDummy = [
    {
      tenor: 1,
      label: "1 Month",
    },
    {
      tenor: 2,
      label: "2 Month",
    },
    {
      tenor: 3,
      label: "3 Month",
    },
    {
      tenor: 4,
      label: "4 Month",
    },
    {
      tenor: 5,
      label: "5 Month",
    },
    {
      tenor: 6,
      label: "6 Month",
    },
  ];
  const calculatorSorts = loanTermDummy;
  const hasilAmountFromInput = (loanInvoice / 100) * ki_maximum_loan;
  const schema = yup.object().shape({
    document_type: yup.string(),
    invoice_deadline: yup.number(),
    invoice_amount: yup
      .number()
      .typeError("you must specify a number")
      .min(100000, "Minimum invoice amount is 100,000")
      .max(2000000000000, "Maximum invoice amount is 2,000,000,000,000")
      .required("Required Invoice Amount"),
    invoice_number: yup.string().required("Required Invoice Number"),
    invoice_date: yup.string().required("Required Invoice Date"),
    loan_amount: yup
      .number()
      .min(100000, "Minimum loan amount is 100,000 ")
      .required("Required Loan Amount"),
    loan_term: yup.number().required("Required Loan Term"),
    client_name: validateBorrowerTypeCsf
      ? yup.string().required("Required Client Name")
      : yup.string(),
    contact_person: validateBorrowerTypeCsf
      ? yup.string().required("Required Contact Person")
      : yup.string(),
    job_position: validateBorrowerTypeCsf
      ? yup.string().required("Required Job Position")
      : yup.string(),
    phone_number: validateBorrowerTypeCsf
      ? yup.string().required("Required Phone Number")
      : yup.string(),
    email_address: validateBorrowerTypeCsf
      ? yup.string().email("Email Wrong").required("Required Email Address")
      : yup.string().email("Email Wroing"),
  });
  const detailsLoans =
    cookie.load("details-loan") !== undefined && cookie.load("details-loan");
  let setFieldValueTemp = () => {};

  useEffect(() => {
    koinvoiceServices.actionGetBorrowerInformation({}).then((res) => {
      if (res?.status === 200) {
        setBorrowerInformation(res.data);
        let validateBorrowerType = ["Supply Chain Financing", "SCF"].includes(
          res.data?.borrower_type
        );
        setValidateBorrowerTypeCsf(validateBorrowerType);
      } else {
        setBorrowerInformation({});
      }
    });
    koinvoiceServices.actionGetCreditLimitLoan({}).then((res) => {
      if (res?.status === 200) {
        setUclCreditLimitLoan(res?.data);
      } else {
        setUclCreditLimitLoan({});
      }
    });
  }, []);

  function handleLaonAmountInputChange(value, option, setFieldValue) {
    setFieldValue("invoice_amount", value);
    setLoanInvoice(value);
    setFieldValue("loan_amount");
  }

  function handleLaonAmountMaxInputChange(value, option, setFieldValue) {
    setFieldValueTemp = setFieldValue;
    if (value > loanInvoice && value < uclCreditLimitLoan.current_limit) {
      setFieldValue("loan_amount", hasilAmountFromInput);
    } else if (value <= loanInvoice && value >= uclCreditLimitLoan.current_limit) {
      setFieldValue("loan_amount", uclCreditLimitLoan.current_limit);
    } else if (value > loanInvoice && value >= uclCreditLimitLoan.current_limit) {
      setFieldValue("loan_amount", uclCreditLimitLoan.current_limit);
    } else {
      if (value > loanInvoice) {
        setFieldValue("loan_amount", hasilAmountFromInput);
      } else if (value <= loanInvoice && value) {
        setFieldValue("loan_amount",  Math.floor(value));
      }
    }
  }

  // function handleChangeDocumentType(value, option, setFieldValue) {
  //   setFieldValue("document_type", value);
  // }

  // function handleChangeInvoiceDeadline(value, option, setFieldValue) {
  //   setFieldValue("invoice_deadline", value);
  // }

  function handleChangeIsShowDrawer() {
    setIsShowAlertDrawerCongrulation(!setIsShowAlertDrawerCongrulation);
  }

  function handleChangeLoanTerm(value, option, setFieldValue) {
    setFieldValue("loan_term", value);
    setFieldValue("invoice_deadline", value);
  }

  function handleChangeInvoiceDate(value, option, setFieldValue) {
    setFieldValue("invoice_date", option);
  }

  function handleSubmit(value) {
    setPayloadSubmit(value);
    if (value.loan_amount <= uclCreditLimitLoan.current_limit) {
      setRemainingAmount(
        uclCreditLimitLoan.current_limit - value.loan_amount
      );
      const {
        invoice_amount,
        loan_amount,
        loan_term,
        invoice_number,
        invoice_date,
        loan_type,
      } = value;
      const id = borrowerInformation?.limit_application_id;
      const loan_terms = parseInt(loan_term);
      const params = {
        limit_application_id: id,
        invoice_amount,
        loan_amount,
        tenure: loan_terms,
        invoice_number,
        invoice_due_date: invoice_date,
        loan_type,
      };
      putLoanDetailsKoinvoice(
        params,
        setIsShowLoading,
        setIsShowAlertDrawerCongrulation
      );
      cookie.save("details-loan", params, {
        path: "/",
        maxAge: 3600,
      });
      actionGetBusinessInfoLocal(params);
    } else {
      const {
        invoice_amount,
        loan_amount,
        loan_term,
        invoice_number,
        invoice_date,
      } = value;
      const id = borrowerInformation?.limit_application_id;
      const loan_terms = parseInt(loan_term);
      const params = {
        limit_application_id: id,
        invoice_amount,
        loan_amount,
        tenure: loan_terms,
        invoice_number,
        invoice_due_date: invoice_date,
      };
      putLoanDetailsKoinvoice(params, setIsShowLoading);
      cookie.save("details-loan", params, {
        path: "/",
        maxAge: 3600,
      });
      actionGetBusinessInfoLocal(params);
    }
  }

  return (
    <React.Fragment>
      <div className="kw-detailsLoan">
        <Button
          size="large"
          style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
          onClick={() => history.push("/dashboard-loan-application")}
        >
          <ArrowLeftOutlined /> {language.button.back}
        </Button>
        {/* <h2>{capitalizeFirstLetter("selectLoan")}</h2> */}
        <h3>{language.loanFormKoinvoice.headingDetails}</h3>
        <Formik
          enableReinitialize
          initialValues={{
            document_type: "invoice",
            loan_term:
              dataBusinessInfoLocal.tenure || detailsLoans.tenure,
            invoice_deadline:
              dataBusinessInfoLocal.invoice_deadline ||
              detailsLoans.invoice_deadline,
            invoice_amount:
              dataBusinessInfoLocal.invoice_amount ||
              detailsLoans.invoice_amount,
            loan_amount:
              dataBusinessInfoLocal.loan_amount || detailsLoans.loan_amount,
            client_name:
              borrowerInformation?.vendor?.vendor_name ||
              borrowerInformation?.vendor?.referral_name ||
              dataBusinessInfoLocal.client_name ||
              detailsLoans.client_name,
            contact_person:
              borrowerInformation?.vendor?.pic_name ||
              dataBusinessInfoLocal.contact_person ||
              detailsLoans.contact_person,
            job_position:
              borrowerInformation?.vendor?.pic_position ||
              dataBusinessInfoLocal.job_position ||
              detailsLoans.job_position,
            phone_number:
              borrowerInformation?.vendor?.pic_phone ||
              dataBusinessInfoLocal.phone_number ||
              detailsLoans.phone_number,
            email_address:
              borrowerInformation?.vendor?.pic_email ||
              dataBusinessInfoLocal.email_address ||
              detailsLoans.email_address,
            invoice_number:
              dataBusinessInfoLocal.invoice_number ||
              detailsLoans.invoice_number,
            invoice_date:
              dataBusinessInfoLocal.invoice_date || detailsLoans.invoice_date,
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* <Form.Item label={language.loanFormKoinvoice.documentTypeDetails}>
              <Select
                size="large"
                name="document_type"
                onChange={(value, option) => {
                  handleChangeDocumentType(value, option, setFieldValue);
                }}
                value={values.document_type}
                style={{ width: isMobile() ? 500 : "100%" }}
              >
                {selectLoan === "Supply Chain Financing" ? (
                  loanDetails.data &&
                  loanDetails.data.master_document_type.map((det, index) => {
                    return (
                      <Option
                        key={index}
                        style={{ marginRight: 15 }}
                        value={det.value}
                      >
                        {det.name}
                      </Option>
                    );
                  })
                ) : (
                  <Option
                    key={"invoice"}
                    style={{ marginRight: 15 }}
                    value={"invoice"}
                  >
                    {"Invoice"}
                  </Option>
                )}
              </Select>
            </Form.Item> */}
              <Form.Item
                label={language.loanFormKoinvoice.invoiceAmountDetails}
                validateStatus={errors.invoice_amount && "error"}
                help={errors.invoice_amount}
                onBlur={handleBlur}
              >
                <InputNumber
                  id="invoice_amount"
                  name="invoice_amount"
                  style={{ width: isMobile() ? 500 : "100%" }}
                  placeholder="100,000,000"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\[Rp]\s?|(,*)/g, "")}
                  size="large"
                  value={values.invoice_amount}
                  onChange={(value, option) => {
                    handleLaonAmountInputChange(value, option, setFieldValue);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={language.loanFormKoinvoice.invoiceNumberDetails}
                validateStatus={
                  touched.invoice_number && errors.invoice_number && "error"
                }
                help={touched.invoice_number && errors.invoice_number}
                onBlur={handleBlur}
              >
                <Input
                  placeholder="INV/II/19110010"
                  style={{ width: isMobile() ? 500 : "100%" }}
                  size="large"
                  name="invoice_number"
                  onChange={handleChange}
                  value={values.invoice_number}
                />
              </Form.Item>
              <Form.Item
                label={language.loanFormKoinvoice.invoiceDateDetails}
                validateStatus={
                  touched.invoice_date && errors.invoice_date && "error"
                }
                help={touched.invoice_date && errors.invoice_date}
                onBlur={handleBlur}
              >
                <DatePicker
                  name="invoice_date"
                  format="YYYY-MM-DD"
                  defaultValue={
                    values.invoice_date
                      ? moment(values.invoice_date, " YYYY-MM-DD")
                      : ""
                  }
                  onChange={(value, option) =>
                    handleChangeInvoiceDate(value, option, setFieldValue)
                  }
                  size="large"
                  style={{ width: isMobile() ? 500 : "100%" }}
                />
              </Form.Item>
              <Form.Item
                label={
                  language.loanFormKoinvoice.totalLoanInvoiceDetails2
                }
                validateStatus={errors.loan_amount && "error"}
                help={errors.loan_amount}
                onBlur={handleBlur}
              >
                <InputNumber
                  id="loan_amount"
                  name="loan_amount"
                  placeholder="100,000,000"
                  style={{ width: isMobile() ? 500 : "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\[Rp]\s?|(,*)/g, "")}
                  size="large"
                  value={values.loan_amount}
                  onChange={(value, option) => {
                    handleLaonAmountMaxInputChange(
                      value,
                      option,
                      setFieldValue
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                label={language.loanFormKoinvoice.loanTermDetails}
                validateStatus={errors.loan_term && "error"}
                help={errors.loan_term && errors.loan_term}
              >
                <Select
                  size="large"
                  name="loan_term"
                  onChange={(value, option) => {
                    handleChangeLoanTerm(value, option, setFieldValue);
                  }}
                  placeholder="1 Month"
                  showSearch
                  value={values.loan_term}
                  style={{ width: isMobile() ? 500 : "100%" }}
                >
                  {calculatorSorts.map((det, index) => {
                    return (
                      <Option
                        key={index}
                        style={{ marginRight: 15 }}
                        value={det.tenor}
                      >
                        {det.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {/* <Form.Item
              label={language.loanFormKoinvoice.invoiceDeadLineDetails}
              validateStatus={
                touched.invoice_deadline && errors.invoice_deadline && "error"
              }
              help={touched.invoice_deadline && errors.invoice_deadline}
              onBlur={handleBlur}
            >
              <Select
                size="large"
                name="invoice_deadline"
                onChange={(value, option) => {
                  handleChangeInvoiceDeadline(value, option, setFieldValue);
                }}
                value={values.loan_term}
                style={{ width: isMobile() ? 500 : "100%" }}
              >
                {calculatorSorts.map((det, index) => {
                  return (
                    <Option
                      key={index}
                      style={{ marginRight: 15 }}
                      value={det.tenor}
                    >
                      {det.tenor}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
              {validateBorrowerTypeCsf && (
                <React.Fragment>
                  <Form.Item
                    label={language.loanFormKoinvoice.clientsNameDetails}
                    validateStatus={
                      touched.client_name && errors.client_name && "error"
                    }
                    help={touched.client_name && errors.client_name}
                    onBlur={handleBlur}
                  >
                    <Input
                      placeholder="PT. xxxx xxxx xxx"
                      disabled={
                        borrowerInformation?.vendor?.vendor_name ? true : false
                      }
                      style={{ width: isMobile() ? 500 : "100%" }}
                      size="large"
                      name="client_name"
                      onChange={handleChange}
                      value={values.client_name}
                    />
                  </Form.Item>
                  <Form.Item
                    label={language.loanFormKoinvoice.contactPersonDetails}
                    validateStatus={
                      touched.contact_person && errors.contact_person && "error"
                    }
                    help={touched.contact_person && errors.contact_person}
                    onBlur={handleBlur}
                  >
                    <Input
                      placeholder="Jhon Doe xxx"
                      disabled={
                        borrowerInformation?.vendor?.pic_phone ? true : false
                      }
                      style={{ width: isMobile() ? 500 : "100%" }}
                      size="large"
                      name="contact_person"
                      onChange={handleChange}
                      value={values.contact_person}
                    />
                  </Form.Item>
                  <Form.Item
                    label={language.loanFormKoinvoice.jobPositionDetails}
                    validateStatus={
                      touched.job_position && errors.job_position && "error"
                    }
                    help={touched.job_position && errors.job_position}
                    onBlur={handleBlur}
                  >
                    <Input
                      placeholder="Financial Director"
                      disabled={
                        borrowerInformation?.vendor?.pic_position ? true : false
                      }
                      style={{ width: isMobile() ? 500 : "100%" }}
                      size="large"
                      name="job_position"
                      onChange={handleChange}
                      value={values.job_position}
                    />
                  </Form.Item>
                  <Form.Item
                    label={language.loanFormKoinvoice.phoneNumberDetails}
                    validateStatus={
                      touched.phone_number && errors.phone_number && "error"
                    }
                    help={touched.phone_number && errors.phone_number}
                    onBlur={handleBlur}
                  >
                    <Input
                      placeholder="08xx xxxx xxxx"
                      disabled={
                        borrowerInformation?.vendor?.pic_phone ? true : false
                      }
                      style={{ width: isMobile() ? 500 : "100%" }}
                      size="large"
                      name="phone_number"
                      onChange={handleChange}
                      value={values.phone_number}
                    />
                  </Form.Item>
                  <Form.Item
                    label={language.loanFormKoinvoice.emailAddressDetails}
                    validateStatus={
                      touched.email_address && errors.email_address && "error"
                    }
                    help={touched.email_address && errors.email_address}
                    onBlur={handleBlur}
                  >
                    <Input
                      placeholder="john@doe.com"
                      style={{ width: isMobile() ? 500 : "100%" }}
                      disabled={
                        borrowerInformation?.vendor?.pic_email ? true : false
                      }
                      type="text"
                      size="large"
                      name="email_address"
                      onChange={handleChange}
                      value={values.email_address}
                    />
                  </Form.Item>
                </React.Fragment>
              )}
              <Spin
                spinning={isShowLoading}
                style={{ width: isMobile() ? "70%" : "100%" }}
              >
                <Button
                  htmlType="submit"
                  className={"kw-button"}
                  size="large"
                  type="primary"
                >
                  {language.button.next}
                </Button>
              </Spin>
            </form>
          )}
        </Formik>
      </div>
      <CongrulationsStarsLoan
        language={language}
        height={555}
        visible={isShowAlertDrawerCongrulation}
        onClose={handleChangeIsShowDrawer}
        remainingAmount={remainingAmount}
        isShowLoading={isShowLoading}
        setIsShowAlertDrawerCongrulation={setIsShowAlertDrawerCongrulation}
      />
    </React.Fragment>
  );
});
