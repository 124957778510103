import styled from "styled-components";

export const DrawerConfirmPaymentStyle = styled.div`
  .kw-drawerConfirm-wrapper {
    .kw-button {
      background-color: #2b476c;
      border-color: #2b476c;
      color: #ffffff;
      margin-top: 20px;
    }
    &__close {
      cursor: pointer;
      margin-top:20px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      text-transform: uppercase;
      color: #566068;
      border-radius: 3px;
    }
    background-color: transparent;
    position: relative;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    margin: 0px auto;
    padding: 0px 16px 80px;
    &__total-due {
      font-style: normal;
      font-weight: 555;
      font-size: 15px;
      text-transform: uppercase;
      display: block;
      color: #878787;
    }
    &__priceMounth {
      font-style: normal;
      font-weight: 444;
      font-size: 17px;
      text-transform: uppercase;
      color: #378df4;
    }
    &__details {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      align-items: center;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 0.35px;
      color: #2b486d;
    }
    &__badge-code {
      background: #2b486d;
      border-radius: 40px;
      width: 30%;
      color: #ffffff;
      text-align: center;
      margin-bottom: 20px;
    }
    &__drawerConfirm-wrapper {
      margin-top: 25px;
    }
  }
`;
