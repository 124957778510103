import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import finfiniImg from "../../../assets/images/finfini-logo.png";

const ConnectModal = ({
  show,
  handleClose,
  handleSubmit,
  handleUsername,
  handlePassword,
  flag, 
  language,
  account,
  state
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton className="px-4 pb-0">
      <Modal.Title></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="modal-wrapper px-3">
        <div className="uploadins-wrapper">
          <div className="uploadins-text">
            <h5 className="uploadins-title">
              {flag === 'en' ? 
                language.title.connectModal1 + ' ' + state.bank_name + ' ' + language.title.connectModal2
              : 
                language.title.connectModal1 + ' ' + language.title.connectModal2 + ' ' + state.bank_name
              }
            </h5>
            <p>{language.subtitle.connectModal}</p>
          </div>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control 
                type="text"
                autoComplete="off"
                placeholder={language.form.username}
                name="username"
                value={state.username}
                onChange={e => handleUsername(e)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Control 
                type="password"
                placeholder={language.form.password}
                name="password"
                value={state.password}
                onChange={e => handlePassword(e)}
              />
            </Form.Group>
            <figure className="uploadins-img">
              <img src={finfiniImg} alt="" />
            </figure>
            <div className="modal-footer-button">
              <Button 
                variant="primary"
                className="btn btn-primary-outline"
                onClick={handleClose}
              >
                {language.button.cancel}
              </Button>
              <Button 
                variant="primary"
                onClick={handleSubmit}
              >
                {language.button.submit}
              </Button>
            </div>
            { account.connErrors && (
              <div 
                className="invalid-feedback"
                style={{ display: "block" }}
              > 
                {account.connErrors[flag]}
              </div>
            )}
          </Form>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)

export default ConnectModal;