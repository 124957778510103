import React, { useRef } from "react";
import { CardYourLoanStyle } from "./style";
import { getApplicationListLoanDashboard } from "../stores/actions/koinvoiceLoanDashboard";
import { Button, Card, Spin } from "antd";
import CardYourLoans from "../Components/CardYourLoans";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useState } from "react";
import NotFound from "../Components/NotFound";
import koinvoiceServices from "../services/koinvoice";
import FilterKoinInvoiceYourLoan from "../Components/FilterKoinInvoiceYourLoan";
import DrawerShortKoinInvoice from "../Components/DrawerShortKoinInvoice";
import DrawerFilterKoinInvoice from "../Components/DrawerFilterKoinInvoice";

function YourLoan({
  actionChangeShowYourLoan,
  documentLoanType,
  actionChangeShowDocumentList,
  language,
  koinvoiceDashboard: { applicationListLoanDashboardSucsess },
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [isShowNotFound, setIsShowNotFound] = useState(true);
  const [isShowDrawerShortFilter, setIsShowDrawerShortFilter] = useState(false);
  const [isShowDrawerFilter, setIsShowDrawerFilter] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [parameter, setParameter] = useState({
    sort_type: "asc",
    filter: documentLoanType,
    sort: undefined,
    page: 1
  });
  const isInitialRender = useRef(true);

  useEffect(() => {
    getScheduleList(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(parameter.page === 1) {
      setIsPrevDisabled(true);
    } else if(parameter.page > 1) {
      setIsPrevDisabled(false);
    }
    
    isInitialRender.current
      ? (isInitialRender.current = false)
      : getScheduleList(); // eslint-disable-next-line
  }, [
    parameter,
    documentLoanType,
    isShowDrawerShortFilter,
    isShowDrawerFilter,
  ]);

  async function getScheduleList() {
    let response = await koinvoiceServices.actionChangeGetYourLoan({
      params: parameter,
      loading: setIsShowLoading,
    });
    if (response.status === 200) {
      if(response.data.length < 10 || response.data.length === 0) {
        setIsNextDisabled(true);
      } else {
        setIsNextDisabled(false);
      }
      setApplicationList(response.data);
      setIsShowNotFound(true);
    } else {
      setIsShowNotFound(false);
      setApplicationList([]);
    }
  }

  function actionShowHideShortDrawerFilter(idCondition, params) {
    setIsShowDrawerShortFilter(!isShowDrawerShortFilter);
    idCondition === "apply" &&
      setParameter({
        ...parameter,
        sort_type: params && params.sort_type,
        sort: params && params.sort,
      });
  }

  function actionShowHideFilterDrawer(idCondition, params) {
    setIsShowDrawerFilter(!isShowDrawerFilter);
    idCondition === "apply" &&
      setParameter({
        ...parameter,
        filter: params && params.filter,
      });
  }

  function handleNext() {
    setParameter(prevState => ({
      ...prevState,
      page: prevState.page + 1
    }))
  }

  function handlePrev() {
    if(parameter.page > 1) {
      setParameter(prevState => ({
        ...prevState,
        page: prevState.page - 1
      }))
    }
  }

  return (
    <Spin spinning={isShowLoading}>
      <Card
        style={{
          height: applicationList.length > 3 ? "auto" : "100vh",
        }}
      >
        <CardYourLoanStyle>
          <div className="kw-yourLoan-wrapper">
            <Button
              style={{ marginBottom: 20 }}
              onClick={() => actionChangeShowYourLoan()}
            >
              {language.button.back}
            </Button>
            <h2
              style={{
                textAlign: "center",
                color: "#2B486D",
                fontWeight: 555,
                marginBottom: 20,
              }}
            >
              {language.loanDashboard.yourLoan}
            </h2>
            <FilterKoinInvoiceYourLoan
              actionShowHideShortDrawerFilter={actionShowHideShortDrawerFilter}
              actionShowHideFilterDrawer={actionShowHideFilterDrawer}
            />
            <br />
            <Button type="link" onClick={() => handlePrev()} disabled={isPrevDisabled}>
              {language.button.prevSchedule}
            </Button>
            <Button type="link" onClick={() => handleNext()} disabled={isNextDisabled}>
            {language.button.nextSchedule}
            </Button>
            {isShowNotFound ? (
              applicationList.length > 0 &&
              applicationList.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <CardYourLoans
                      index={index}
                      item={item}
                      documentLoanType={documentLoanType}
                      isShowLoading={isShowLoading}
                      actionChangeShowDocumentList={
                        actionChangeShowDocumentList
                      }
                      language={language}
                    />
                  </React.Fragment>
                );
              })
            ) : (
              <NotFound
                image={require("../assets/images/notfound.png").default}
                label="Tidak ada Data"
              />
            )}
          </div>
        </CardYourLoanStyle>
      </Card>
      <DrawerShortKoinInvoice
        visible={isShowDrawerShortFilter}
        onClose={actionShowHideShortDrawerFilter}
      />
      <DrawerFilterKoinInvoice
        documentLoanType={documentLoanType}
        visible={isShowDrawerFilter}
        onClose={actionShowHideFilterDrawer}
      />
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationListLoanDashboard,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(YourLoan);
