const initState = {
	data: null
};

const documentReducer = (state = initState, action) => {
	switch (action.type) {
    case "GET_REQUIRED_SUCCESS":
      return { ...state, requiredDocs: action.payload, getRequiredDocs:true }
    case "GET_REQUIRED_FAILED":
      return { ...state, getRequiredFailed: true }
    case "GET_APPROVED_SUCCESS":
      return { ...state, approvedDocs: action.payload, getApprovedDocs:true }
    case "GET_APPROVED_FAILED":
      return { ...state, getApprovedFailed: true }
    case "GET_CONTRACT_SUCCESS":
      return { ...state, loanContract: action.payload, getContract: true }
    case "GET_CONTRACT_FAILED":
      return { ...state, getContractFailed: true }
    case "SEND_LINK_SUCCESS":
      return { ...state, isSendLinkSuccess: true }
    case "SEND_LINK_FAILED":
      return { ...state, isSendLinkFailed: true }
    case "RESET_SEND_LINK":
      return { ...state, isSendLinkFailed: false, isSendLinkSuccess: false }
    default:
      return state;
  }
};

export default documentReducer;
