import React, { useRef, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { bindActionCreators } from "redux";
import { userLogin, actionChangeLogout } from "../stores/actions/account";
import { connect } from "react-redux";
import OTPInput from "otp-input-react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { SecurityCodePageWrapper } from "../SecurityCodePage/SecurityCodePage.styles";
import * as validate from "../commons/validate";
import imgSuccess from "../assets/images/success.png";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";
import SimpleTextInput from "../Components/Form/simpleTextInput";
import { notification, Spin } from "antd";
import history from "../util/history";
import { handleDecrypets } from "../library/decryptes";
import { useState } from "react";
import cookie from "react-cookies";
import koinvoiceServices from "../services/koinvoice";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "2rem",
    flex: "1 1 auto",
    margin: "0",
    textAlign: "center",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    "& p": {
      fontSize: "15px",
      color: "#575756",
      // width: "400px"
    },
    "& h3": {
      color: "#2b486d",
      fontSize: "34px",
      fontWeight: "bold",
      letterSpacing: "0.41px",
      lineHeight: "41px",
      fontFamily: "Source Sans Pro",
    },
    "& span": {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "22px",
      letterSpacing: "-0.41px",
      color: "#CCCCCC",
    },
  },
}))(MuiDialogContent);

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
  });
};

function SecureCodeForm({
  actionChangeLogout,
  userLogin,
  auth,
  pristine,
  handleSubmit,
  submitting,
  init: { language, flag },
}) {
  const [code, setCode] = useState("");
  const [isShowLoading, setIsShowLoading] = useState(false);
  const localStorageData = JSON.parse(localStorage.getItem("dataAccount"));
  const { empat, satu } = localStorageData !== null && localStorageData;
  const [isShowLoginModalSucsess, setIsShowLoginModalSucsess] = useState(false);
  const mounted = useRef();

  const onInputChange = (e) => {
    setCode(e);
  };

  const handleOnSubmit = (event) => {
    let data = {
      email: auth.email,
      pin: code,
      password: event.password,
    };
    let dataKoinInvoice = {
      email: hostNameCheckCondition && handleDecrypets(empat),
      password: event.password,
    };
    userLogin(
      hostNameCheckCondition ? dataKoinInvoice : data,
      actionChangeShowLoading,
      setIsShowLoginModalSucsess
    );
    if (hostNameCheckCondition) {
      cookie.save("name", handleDecrypets(satu));
      cookie.save("email", handleDecrypets(empat));
    }
  };

  const actionChangeShowLoading = (e) => {
    setIsShowLoading(e);
  };

  const handleClose = () => {
    setIsShowLoginModalSucsess(!isShowLoginModalSucsess);
    if (hostNameCheckCondition) {
      actionChangeLogout();
      koinvoiceServices.actionGetCreditLimitLoan({}).then((res) => {
        if (res?.status === 200) {
          if (res?.data?.status === "") {
            history.push("/koininvoice/apply-limit");
          } else {
            if (res?.data?.status !== "") {
              history.push("/dashboard-limit-application");
            }
          }
        } else {
          openNotificationWithIcon("error", res?.message?.en);
        }
      });
    } else {
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (
        hostNameCheckCondition ? isShowLoginModalSucsess : auth.isLoggedIn
      ) {
        setTimeout(() => {
          handleClose();
        }, 1500);
      }
    }
  });

  return (
    <SecurityCodePageWrapper>
      <div className="page-content-wrapper">
        <div className="card">
          <div className="back-button" style={{ cursor: "pointer" }}>
            <a
              href="/login"
              style={{ color: "#C4C4C4", textDecoration: "none" }}
            >
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
              <span>{language.button.back}</span>
            </a>
          </div>
          <div className="card-body register-body">
            <h2 className="register-title">{language.title.enterPin}</h2>
            <p className="text-center text-note">
              {language.subtitle.enterPin}
            </p>
            <form
              id="SecureCodeForm"
              className=""
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              <div className="input-wrapper">
                {hostNameCheckCondition ? (
                  <Field
                    id={"password"}
                    variant="outlined"
                    className="w-100"
                    type="password"
                    name={"password"}
                    component={SimpleTextInput}
                    validate={[validate.required]}
                    label={language.form.password}
                  />
                ) : (
                  <OTPInput
                    value={code}
                    name="code"
                    onChange={onInputChange}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    secure={true}
                    className={`otp-input`}
                    pattern="\d*"
                  />
                )}
              </div>
              <div className="input-wrapper">
                {auth.errors && (
                  <div className="invalid-feedback">{auth.errors[flag]}</div>
                )}
              </div>
              <div className="input-wrapper">
                <Spin spinning={isShowLoading}>
                  <button
                    className={`btn btn-primary w-100`}
                    disabled={
                      hostNameCheckCondition
                        ? pristine || submitting
                        : code.length < 6
                    }
                  >
                    {language.button.submit}
                  </button>
                </Spin>
              </div>
            </form>
          </div>
        </div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isShowLoginModalSucsess}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></DialogTitle>
          <DialogContent className="modal-wrapper">
            <figure>
              <img src={imgSuccess} alt="img-success" />
            </figure>
            <h3 className="text-title">
              {hostNameCheckCondition
                ? language.title.signedInModalBusiness
                : language.title.signedInModal}
            </h3>
            <p
              className="text-note"
              dangerouslySetInnerHTML={{
                __html: language.text.signedInModal,
              }}
            />
            <span>{language.label.redirecting}</span>
          </DialogContent>
        </Dialog>
      </div>
    </SecurityCodePageWrapper>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  init: state.init,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin, actionChangeLogout }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(SecureCodeForm);

export default reduxForm({
  form: "SecureCodeForm",
})(component);
