import React, { useState, useRef, useEffect } from "react";
import _ from "underscore";
import { Button, Input, Typography, Spin, Modal } from "antd";
import { reduxForm, Field } from "redux-form";
import * as validate from "../../commons/validate";
import PasswordValidator from "../PasswordValidator/index";
import { FormGroup } from "reactstrap";
import strings from "../../localization/localization";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SecurityCodePageWrapper } from "./SecurityCode.styles";
import {
  createBusinessAccount,
  actionChangeButtonBack,
} from "../../stores/actions/account";
import { connect } from "react-redux";
import imgSuccess from "../../assets/images/success.png";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import history from "../../util/history";
import koinvoiceServices from "../../services/koinvoice";
import moment from "moment";
import cookie from "react-cookies";
import removeLocalStorageAndCookieLimit from "../../Pages/DashboardKoinInvoice/RemoveLocalStorageAndCookieLimit";

const { Text } = Typography;

const numberInputComponentPassword = ({
  placeholder,
  name,
  type,
  onClick,
  input: { value, onChange },
  handleChange,
  meta: { error, touched },
}) => (
  <FormGroup className={`${touched && error && "has-error"}`}>
    <Input.Password
      name={name}
      type={type}
      onChange={(e) => {
        onChange(e);
        handleChange(e);
      }}
      onClick={onClick}
      size="large"
      className="input"
      value={value}
      autoComplete="off"
      placeholder={placeholder}
    />
    {touched && error && <Text type="danger">{error}</Text>}
  </FormGroup>
);

const numberInputComponentConfirmPassword = ({
  input,
  meta: { error, touched },
  onClick,
}) => (
  <FormGroup className={`${touched && error && "has-error"}`}>
    <Input.Password
      name="passwordConfirmation"
      type="text"
      {...input}
      size="large"
      onClick={onClick}
      className="input"
      autoComplete="off"
      placeholder={strings.password_validator.password_confirm_new}
    />
    {touched && error && <Text type="danger">{error}</Text>}
  </FormGroup>
);

const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? "Passwords do not match" : undefined;

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: "absolute",
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "2rem",
    flex: "1 1 auto",
    margin: "0",
    textAlign: "center",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    "& p": {
      fontSize: "15px",
      color: "#575756",
      width: "400px",
    },
    "& h3": {
      color: "#2b486d",
      fontSize: "34px",
      fontWeight: "bold",
      letterSpacing: "0.41px",
      lineHeight: "41px",
      fontFamily: "Source Sans Pro",
    },
    "& span": {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "22px",
      letterSpacing: "-0.41px",
      color: "#CCCCCC",
    },
  },
}))(MuiDialogContent);

function CreatePasswordBusiness(props) {
  const {
    init: { flag, language },
    account,
  } = props;
  strings.setLanguage(flag)
  const forbiddenWords = ["password", "user", "username"];
  const [isValidatorValid, setIsValidatorValid] = useState({
    minChars: false,
    capitalLetters: false,
    lowerCapital: false,
    specChars: false,
    numbers: false,
    words: false,
    all: false,
  });
  const [allValidatorValid, setAllValidatorValid] = useState(false);
  const [isShowAlertValidation, setIsShowAlertValidation] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowModalSuksessRegister, setIsShowModalSuksesRegister] =
    useState(false);
  const [showMessageError, setShowMessageError] = useState("");
  const {
    handleSubmit,
    pristine,
    submitting,
    prevPage,
    actionChangeButtonBack,
  } = props;
  const isInitialRender = useRef(true);

  function handleChange(event) {
    checkRules(event.target.value);
  }

  function checkRules(value) {
    let validData = {
      minChars: !_.isEmpty(value) ? value.length >= parseInt(8) : false,
      capitalLetters: !_.isEmpty(value) ? countCapitals(value) : false,
      lowerCapital: !_.isEmpty(value) ? countLowerCapital(value) : false,
      specChars: !_.isEmpty(value) ? countSpecCharcters(value) : false,
      numbers: !_.isEmpty(value) ? countNumbers(value) > 0 : false,
      words: !_.isEmpty(value) ? !checkWords(value) : false,
    };
    let allValid =
      validData.minChars &&
      validData.capitalLetters &&
      validData.lowerCapital &&
      validData.specChars &&
      validData.numbers &&
      validData.words;
    setIsValidatorValid(validData);
    setAllValidatorValid(allValid);
  }

  function countCapitals(value) {
    let str = value;
    return str.replace(/[^A-Z]/g, "").length;
  }

  function countLowerCapital(value) {
    let str = value;
    return str.replace(/[^a-z]/g, "").length;
  }

  function countSpecCharcters(value) {
    let format = validate.specialCharacters;
    return format.test(value);
  }

  function countNumbers(value) {
    return /\d/.test(value);
  }

  function checkWords(value) {
    return _.some(forbiddenWords, function (word) {
      let matched = word === value ? true : "";
      return matched;
    });
  }

  function hideShowAlert(value) {
    if (value) {
      setIsShowAlertValidation(false);
    }
  }

  function handleOnSubmit(e) {
    let account = props.account.data;
    let data = {
      account_purpose: "0a374b16-f19d-11ea-86dd-b8599f27b26c",
      fullname: account.full_name,
      owner_name: account.full_name,
      business_type: account.business_type,
      company_name: account.company_name,
      business_phone_area: account.phone_area,
      business_phone_number: account.phone_number,
      business_email: account.email,
      business_duration: account.business_duration ? parseInt(account.business_duration) : 0,
      established_in: moment(account.established_in).format("YYYY-MM-DD"),
      number_of_employee : account.number_of_employee,
      password: e.password,
      confirm_password: e.passwordConfirmation,
      referral_code: account.promo_code,
      letter_of_agreement_date: moment().format("YYYY-MM-DD, HH:mm:ss")
    };
    if (allValidatorValid) {
      koinvoiceServices
        .actionCreateBusinessAccount({
          params: data,
          loading: setIsShowLoading,
          isShowModalSuksessRegister: setIsShowModalSuksesRegister,
          showMessageError: setShowMessageError,
        })
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsShowModalSuksesRegister(true);
            cookie.save("email", account.email);
            removeLocalStorageAndCookieLimit();
            setTimeout(() => {
              handleCloseKoinInvoice();
            }, 2000);
          } else {
            setIsShowModalSuksesRegister(false);
          }
        });
    } else {
      setIsShowAlertValidation(true);
    }
  }

  function handleCloseKoinInvoice() {
    setIsShowModalSuksesRegister(false);
    history.push({
      pathname: "/koininvoice/apply-limit",
      state: {
        loginCheck: true,
      },
    });
  }

  useEffect(() => {
    isInitialRender.current
      ? (isInitialRender.current = false)
      : isShowModalSuksessRegister &&
        setTimeout(() => {
          handleCloseKoinInvoice();
        }, 1500);
  }, [isShowModalSuksessRegister]);

  return (
    <React.Fragment>
      <SecurityCodePageWrapper>
        <div className="page-content-wrapper">
          <div className="card" style={{ padding: 15 }}>
            <div className="reset-password kw-forgot-security-code">
              <div
                className="back-button"
                onClick={() => actionChangeButtonBack(prevPage)}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span>{language.button.back}</span>
              </div>
              <h3>{strings.password_validator.create_new_password}</h3>
              <p style={{ textAlign: "center" }}>
                {strings.password_validator.create_new_password_text_label}
              </p>
              <form onSubmit={handleSubmit(handleOnSubmit)}>
                <Field
                  name="password"
                  type="text"
                  onClick={hideShowAlert}
                  component={numberInputComponentPassword}
                  handleChange={handleChange}
                  placeholder={strings.password_validator.password_new}
                  validate={[validate.required]}
                />
                <Field
                  type="text"
                  name="passwordConfirmation"
                  onClick={hideShowAlert}
                  component={numberInputComponentConfirmPassword}
                  validate={[validate.required, passwordsMustMatch]}
                />
                <PasswordValidator
                  validData={isValidatorValid}
                  valid={allValidatorValid}
                  minCharacters={8}
                  requireCapitals={1}
                  requireLowerCapitals={1}
                  requireNumbers={1}
                />
                {isShowAlertValidation && (
                  <div style={{ margin: 15 }}>
                    <Text type="danger">
                      {
                        strings.password_security_business
                          .password_validation_kriteria
                      }
                    </Text>
                  </div>
                )}
                <div className="input-wrapper">
                  {showMessageError && (
                    <div className="invalid-feedback">{showMessageError}</div>
                  )}
                </div>
                <Spin spinning={isShowLoading}>
                  <Button
                    htmlType="submit"
                    className={pristine === false ? "kw-button" : ""}
                    size="large"
                    disabled={pristine || submitting}
                    style={{ width: "100%" }}
                    onSubmit={handleSubmit}
                  >
                    {strings.legend.submit_next}
                  </Button>
                </Spin>
              </form>
            </div>
          </div>
        </div>
      </SecurityCodePageWrapper>
      <Modal
        visible={isShowModalSuksessRegister}
        onCancel={() => handleCloseKoinInvoice()}
        footer={null}
      >
        <DialogContent className="modal-wrapper">
          <figure>
            <img src={imgSuccess} alt="img-success" />
          </figure>
          <h3 className="text-title">
            {language.title.accountCreateModalBusiness}
          </h3>
          <p className="text-note">
            {language.text.pleaseVerifyEmail}{" "}
            {account.data !== null && account.data.email}{" "}
            {language.text.throughVerificationLink}
          </p>
        </DialogContent>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createBusinessAccount, actionChangeButtonBack },
    dispatch
  );
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePasswordBusiness);

export default withRouter(
  reduxForm({
    form: "CreatePasswordBusiness",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(component)
);
