import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionPostResendEmail } from "../../../stores/actions/koinvoiceLoan";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Spin, Row, Col } from "antd";
import DrawerSuccessLink from "./DrawerSuccessLink";
import history from "../../../util/history";
import cookie from "react-cookies";
import koinvoiceServices from "../../../services/koinvoice";
import { capitalizeFirstLetter, checkTranslate } from "../../../library/checkCapitalize";
import { ApplyLoanKoinVoiceStyle } from "../../style";

function UnggahDokumen({
  actionPostResendEmail,
  koinvoice: { documentUploadSucess, purpose },
  init: { language },
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowLoadingButton, setIsShowLoadingButton] = useState(false);
  const [isShowSucessResendEmail, setIsShowSucessResendEmail] = useState(false);
  const [document, setDocument] = useState([]);
  const [documentRequired, setDocumentRequired] = useState([]);
  const detailSucsess =
    cookie.load("put-loan-detail-sucsess") !== undefined &&
    cookie.load("put-loan-detail-sucsess");
  const { loan_application_id, loan_id } =
    cookie.load("submit-detailsLoan-apply") !== undefined &&
    cookie.load("submit-detailsLoan-apply").data;

  useEffect(() => {
    if (detailSucsess === "loan-detail-sucsess") {
      koinvoiceServices
        .documentListOld({
          params: {
            product: "koin_invoice",
            status: "not_uploaded",
            loan_id: loan_id,
          },
          loading: setIsShowLoading,
        })
        .then((res) => {
          if (res.status === 200) {
            let names = res.data.map(function (item) {
              return capitalizeFirstLetter(item["name" || "key"]);
            });
            setDocument(names);
            setDocumentRequired(res.data);
          } else {
            setDocument([]);
            setDocumentRequired([]);
          }
        });
    } else {
      if (detailSucsess === false) {
        history.push("/koininvoice/apply-loan");
      }
    } // eslint-disable-next-line
  }, []);

  function actionSubmitResendEmail() {
    actionPostResendEmail(
      { required_docs: document },
      setIsShowLoadingButton,
      setIsShowSucessResendEmail,
      loan_application_id
    );
  }

  function actionShowDrawerSucsessResendLink(e) {
    setIsShowSucessResendEmail(!isShowSucessResendEmail);
    if (e === "closeDariDrawer") {
      history.push("/dashboard-loan-application-list");
    }
    window.localStorage.removeItem("personalInfo");
    cookie.remove("loanApply", { path: "/" });
  }

  // function getLoginLink(baseUrl) {
  //   const timestamp = new Date().getTime() / 1000;
  //   const loginCode = btoa(btoa(`${cookie.load("token")}||${timestamp}`));
  //   return `${baseUrl}/login?code=${loginCode}`;
  // }

  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <Button
          size="large"
          style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
          onClick={() => history.push("/koininvoice/apply-loan")}
        >
          <ArrowLeftOutlined /> {language.button.back}
        </Button>
        <div className="kw-documentUnggah-wrapper">
          <h2>{language.loanUnggahDocument.uploadDocuments}</h2>
          <Spin
            style={{ width: "100%", margin: "auto" }}
            spinning={isShowLoading}
          >
            <img
              src={require("../../../assets/images/applyloan/unggah-dokumen.png").default}
              alt=""
            />
            <h3 style={{ marginTop: 20 }}>
              {language.loanUnggahDocument.paragraphHeadingOne}
            </h3>
            <p style={{ marginTop: 20, marginBottom: 20 }}>
              {language.loanUnggahDocument.paragraphHeadingTwo}
            </p>
            {documentRequired.length > 0 &&
              documentRequired.map((list, index) => {
                return (
                  <p key={index}>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ marginRight: 10 }}
                    />
                    {checkTranslate(list.key || list.name, language)}
                  </p>
                );
              })}
            <Row>
              {/* <Col xs={24} md={12}>
            {" "}
            <a
              href={getLoginLink(config.document.url)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                style={{ width: "100%" }}
                size="large"
                className="kw-button"
              >
                UNGGAH DOKUMEN
              </Button>{" "}
            </a>
          </Col> */}
              {/* <Col xs={24} md={24}>
            <Spin spinning={isShowLoadingButton}>
              <Button
                onClick={() => actionSubmitResendEmail()}
                size="large"
                className="kw-documentUnggah-wrapper__button-sendLink"
              >
                {language.button.sendLink}
              </Button>
            </Spin>
          </Col> */}
            </Row>
            <Row>
              <Col xs={24}>
                <Button
                  size="large"
                  loading={isShowLoadingButton}
                  style={{
                    width: "100%",
                    marginTop: 20,
                    border: "1px solid blue",
                    color: "blue",
                  }}
                  onClick={() => actionSubmitResendEmail()}
                >
                  {language.button.toDashboard}
                </Button>
              </Col>
            </Row>
          </Spin>
          <DrawerSuccessLink
            actionShowDrawerSucsessResendLink={
              actionShowDrawerSucsessResendLink
            }
            language={language}
            visible={isShowSucessResendEmail}
          />
        </div>
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionPostResendEmail,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnggahDokumen);
