import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getActionDocumentUpload,
  getActionLimitRequest,
} from "../../../stores/actions/koinvoiceLoan";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Spin, Row, Col } from "antd";
import DrawerSuccessLink from "./DrawerSuccessLink";
import history from "../../../util/history";
import cookie from "react-cookies";
import koinvoiceServices from "../../../services/koinvoice";
import { capitalizeFirstLetter } from "../../../library/checkCapitalize";

function UnggahDokumen({
  getActionDocumentUpload,
  getActionLimitRequest,
  koinvoice: { documentRequestError },
  init: { language },
  token,
  userId
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowSucessResendEmail, setIsShowSucessResendEmail] = useState(false);
  const [documentRequired, setDocumentRequired] = useState([]);
 

  useEffect(() => {
    koinvoiceServices
      .documentListOld({
        params: { product: "koin_invoice", status: "not_uploaded" },
        loading: setIsShowLoading,
        token: token
      })
      .then((res) => {
        if (res.status === 200) {
          setDocumentRequired(res.data);
        } else {
          setDocumentRequired([]);
        }
      });// eslint-disable-next-line
  }, []);

  function actionShowDrawerSucsessResendLink(e) {
    setIsShowSucessResendEmail(!isShowSucessResendEmail);
    if (e === "closeDariDrawer") {
      history.push("/dashboard-limit-application");
    }
    window.localStorage.removeItem("personalInfo");
    cookie.remove("loanApply", { path: "/dashboard-limit-application" });
  }

  function actionChangeDashboardDirect() {
    localStorage.removeItem(userId);
    localStorage.removeItem('bisInfo-'+userId);
    cookie.remove("paramsSubmit", { path: "/" });
    cookie.remove("businessInformation", { path: "/" });
    cookie.remove("business-info", { path: "/" });
    cookie.remove("cl", { path: "/" });
    cookie.remove("name");
    cookie.remove("paramsSubmitShareHolderInformation", { path: "/" });
    cookie.remove("paramsSubmitDirectorOwner", { path: "/" });
    localStorage.removeItem("paramsSubmitDate");
    localStorage.removeItem("paramsIsCurrentlyAddress");
    localStorage.removeItem("businessInfoDate");
    localStorage.removeItem("businessInformation");
    localStorage.removeItem("paramsSubmitShareHolderInformation");
    localStorage.removeItem("paramsSubmitDirectorOwner");
    localStorage.removeItem("paramsSubmit");
    localStorage.removeItem("add-data-" + userId);
    localStorage.removeItem("limit-id-" + userId);

    window.location.href = process.env.REACT_APP_BACKOFFICE_URL + "/v2.1/koininvoice-pre-assessment-limit"; 
  }

  return (
    <div className="kw-documentUnggah-wrapper">
      <h2>{language.loanUnggahDocument.uploadDocuments}</h2>
      <Spin style={{ width: "100%", margin: "auto" }} spinning={isShowLoading}>
        <img
          src={require("../../../assets/images/applyloan/unggah-dokumen.png").default}
          alt=""
        />
        <h3 style={{ marginTop: 20 }}>
          {language.limitUnggahDocument.paragraphHeadingOne}
        </h3>
        <p style={{ marginTop: 20, marginBottom: 20 }}>
          {language.loanUnggahDocument.paragraphLms}
        </p>
        {documentRequired.length > 0 &&
          documentRequired.map((list, index) => {
            return (
              <p key={index}>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {capitalizeFirstLetter(list.key || list.name)}
              </p>
            );
          })}
        <Spin spinning={isShowLoading}>
          <Row>
            <Col xs={24}>
              <Button
                size="large"
                style={{
                  width: "100%",
                  marginTop: 20,
                  border: "1px solid blue",
                  color: "blue",
                }}
                onClick={() => actionChangeDashboardDirect()}
              >
                {language.button.toBackoffice}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Spin>
      <DrawerSuccessLink
        actionShowDrawerSucsessResendLink={actionShowDrawerSucsessResendLink}
        language={language}
        visible={isShowSucessResendEmail}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionDocumentUpload,
      getActionLimitRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnggahDokumen);
