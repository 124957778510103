import styled from "styled-components";

export const LoginWrapper = styled.div`
  background: #f2f2f2;

  a {
    text-decoration: underline;
  }

  .page-content-wrapper {
    /* width: 50%; */
    margin: 0 auto;
    padding-top: 60px;
  }
  .logo-wrapper {
    position: relative;
    top: -65px;
    width: 131px;
    margin: 0 auto;
    .logo-img {
      position: absolute;
    }
  }
  .register-body {
    padding-top: 80px;
    padding-bottom: 35px;
    .register-title {
      text-align: center;

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 41px;
      /* or 121% */

      text-align: center;
      letter-spacing: 0.41px;

      color: #2b486d;

      margin-bottom: 30px;
    }
  }
  .react-tel-input {
    input {
      width: 100%;
    }
  }
  .input-wrapper {
    margin-bottom: 15px;
  }
  .appstore-wraper {
    display: flex;
    justify-content: space-around;
  }
  .remember-text {
    span {
      color: #8e8e8e;
    }
  }
  .error-message {
    margin: 10px;
    text-align: center;
    color: #c53741;
  }
`;
