import styled from "styled-components";

export const StepWorkWrapper = styled.div`
  background: #f2f2f2;

  .step-title {
    text-align: center;
    letter-spacing: 0.41px;
    color: #2b486d;
    margin-bottom: 20px;
  }
  .content-result {
    margin: 20px 10px;
    text-align: center;
    padding: 25px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      color: #2B486D;
    }
  }
  .link-back-button {
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    font-size: 17px;
    color: #575756;
  }
`;
