import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getSchedule,
  getLoanSchedule,
  confirmPayment,
  schedulePayment,
  showScheduleDetail,
  filterSchedule,
} from "../stores/actions/schedule";
import { Breadcrumb, Table, Pagination } from "react-bootstrap";
import NumberFormat from "react-number-format";

import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import noRepayment from "../assets/images/dashboard/norepayment.png"
// import clearanceFailed from "../assets/images/dashboard/notenough.png";
// import paymentLate from "../assets/images/dashboard/late.png";
// import paymentMade from "../assets/images/dashboard/paymentmade.png";
// import paymentToday from "../assets/images/dashboard/today.png";
import SidepanelDetail from "../Components/SidePanel/ClearanceDetail";
import SidepanelPay from "../Components/SidePanel/ClearancePay";
import SortFilter from "../Components/SortFilter";

class Schedule extends Component {
  state = {
    isPaneOpen: false,
    isPanePayOpen: false,
    isPaneOpenLeft: false,
    page: 1,
    sort: 'oldest',
    filter: [],
    filtered: []
  };

  getStatusClass = status => {
    switch (status) {
      case "paid":
        return "badge-success";
      case "upcoming":
        return "badge-secondary";
      case "late":
        return "badge-danger";
      default:
        return "badge-primary";
    }
  }

  getTotalPage = (data) => {
    if (data) {
      let item = data[0].data[0];
       return Math.ceil(item.term / 10);
    } else {
      return 1;
    }
  }

  showSchedulePay = data => {
    this.setState({ isPanePayOpen: true });
    this.props.confirmPayment({data : data});
  }

  showScheduleDetail = data => {
    this.setState({ isPaneOpen: true });
    this.props.showScheduleDetail(data)
  }

  handleSchedulePayment = () => {
    let token = this.props.auth.token;
    let schedule = this.props.schedule.detail.data[0];
    this.props.schedulePayment(schedule.loan_id, schedule.id, true, token);
  }

  handlePaginationArrow = key => {
    let { auth } = this.props;
    let page = key === 'next' ? this.state.page + 1 : this.state.page - 1;
    this.setState({page: page});
    this.props.getSchedule(page, this.state.sort, auth.token);
  }

  changeSorting = ({ target }) => {
    let { auth } = this.props;
    let sort = target.value;
    this.props.getSchedule(this.state.page, sort, auth.token);
  }

  selectStatus = ({target}) => {
    let { filter } = this.state;
    if (target.checked === true) {
      filter.push(target.value)
    } else {
      let index = filter.indexOf(target.value);
      if (index !== -1) filter.splice(index, 1);
    }
    this.setState({ filter })
    this.filterScheduleByStatus(filter);
  }

  filterScheduleByStatus = status => {
    let data = this.props.schedule.data;
    let { filtered } = this.state;
    filtered = data.filter(obj => status.includes(obj.data[0].status))
    this.setState({filtered})
  }

  componentDidMount() {
    let { auth } = this.props;
    Modal.setAppElement(this.el);
    this.props.getSchedule(this.state.page, this.state.sort, auth.token);
  }

  render() {
    let {
      schedule,
      init: { language }
    } = this.props;
    let totalPage = this.getTotalPage(schedule.data);

    let { filter, filtered } = this.state;
    let scheduleData = filtered.length === 0 && filter.length === 0 ? 
        schedule.data : filtered;
    let active = this.state.page;
    let items = [];
    for (let number = 1; number <= totalPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active}>
          {number}
        </Pagination.Item>,
      );
    }

    return (
      <div className="dashboard" ref={ref => this.el = ref}>
        <div className="dashboard-title">
          <Breadcrumb>
            <Breadcrumb.Item href="#">{language.label.home}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{language.label.schedule}</Breadcrumb.Item>
            <Breadcrumb.Item active href="#">{language.title.repayment}</Breadcrumb.Item>
          </Breadcrumb>
          <h3 className="title">
            {language.title.repayment}
          </h3>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card dash-card">
              <div className="card-body">
                {schedule.getSchedule && schedule.data && (
                  <div>
                    <SortFilter 
                      onClick={this.changeSorting}
                      onChange={this.selectStatus}
                      language={language}
                    />
                    <Table responsive className="schedule">
                      <thead>
                        <tr>
                          <th>{language.label.dueDate}</th>
                          <th>Rate</th>
                          <th>{language.label.purpose}</th>
                          <th>{language.label.totalDue}</th>
                          <th>{language.label.installment}</th>
                          <th>{language.label.status}</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {scheduleData.length === 0 && (<tr><td>{language.text.noDataFound}</td></tr>)}
                        {scheduleData.map((schedule) => (
                          <tr
                            className={schedule.data[0].status === "paid" ? "table-paid" : ""}
                            key={schedule.data[0].id}
                          >
                            <td>
                              {new Date(schedule.due_date).toLocaleDateString("ID-id",
                                { year: 'numeric', month: 'short', day: 'numeric' }
                              )}
                            </td>
                            <td
                              className="link"
                              onClick={() => this.showScheduleDetail(schedule.data[0])}>
                              {schedule.data[0].code}
                            </td>
                            <td>{schedule.data[0].description}</td>
                            <td>
                              <NumberFormat
                                value={schedule.data[0].amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalSeparator="."
                                decimalScale={3}
                              />
                            </td>
                            <td>
                              {schedule.data[0].total} / {schedule.data[0].term}
                            </td>
                            <td>
                              <span className={`badge ${this.getStatusClass(schedule.data[0].status)}`}>
                                {schedule.data[0].status}
                              </span>
                            </td>
                            <td>
                              {schedule.data[0].status === "paid" || 
                                schedule.data[0].status === "early_installment_repayment" ||
                                schedule.data[0].status === "late_but_paid" ? (
                                <span className="link" onClick={() => this.showScheduleDetail(schedule.data[0])}>
                                  {language.label.details}
                                </span>
                              ) : (
                                <div>
                                  <span 
                                    className="link"
                                    onClick={() => this.showSchedulePay(schedule)}
                                  >
                                    {language.label.pay}
                                  </span>&nbsp; | &nbsp;
                                  <span 
                                    className="link"
                                    onClick={() => this.showScheduleDetail(schedule.data[0])}
                                  >
                                    {language.label.details}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="funding-pagination">
                      <Pagination>
                        {/* <Pagination.First /> */}
                        {/* <Pagination.Last /> */}
                        <Pagination.Prev 
                          onClick={() => this.handlePaginationArrow('prev')}
                          disabled={this.state.page === 1}
                        />
                        {items}
                        {/* <Pagination.Ellipsis /> */}
                        <Pagination.Next
                          onClick={() => this.handlePaginationArrow('next')}
                          disabled={this.state.page === totalPage}
                        />
                      </Pagination>
                    </div>
                  </div>
                )}
                {/* not found */}
                {schedule.getScheduleFailed && (
                  <div className="notfound-wrapper">
                    <figure className="dashboard-img" style={{ marginTop: "35px" }}>
                      <img src={noRepayment} alt="active loan" />
                    </figure>
                    <h5 className="title">{language.title.noRepaymentDue}</h5>
                    <p className="text">{language.subtitle.noRepaymentDue}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>


        {/* side modal */}
        <div>
          <SlidingPane
            className='side-modal payment-detail-modal'
            overlayClassName='payment-detail-overlay'
            width='540px'
            isOpen={this.state.isPaneOpen}
            onRequestClose={() => {
              this.setState({ isPaneOpen: false });
            }}>
            <SidepanelDetail onClick={() => this.setState({ isPaneOpen: false })} />
          </SlidingPane>

          <SlidingPane
            className='side-modal payment-pay-modal'
            overlayClassName='payment-pay-overlay'
            width='540px'
            isOpen={this.state.isPanePayOpen}
            onRequestClose={() => {
              this.setState({ isPanePayOpen: false });
            }}>
            <SidepanelPay 
              onClose={() => this.setState({ isPanePayOpen: false })}
              onClickPay={this.handleSchedulePayment}
            />
          </SlidingPane>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  balance: state.balance,
  schedule: state.schedule,
  loan: state.loan
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getSchedule,
    getLoanSchedule,
    confirmPayment,
    schedulePayment,
    showScheduleDetail,
    filterSchedule
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);