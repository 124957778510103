import React, { useRef, useEffect } from "react";
import { DashboardKoinInvoiceStyle } from "../BorrowerDashboardKoinInvoice/style";
import DashboardLayoutKoinInvoice from "../Layout/DashboardLayoutKoinInvoice";
import { getApplicationList } from "../stores/actions/koinvoiceLoan";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function EducationWrapper({
  getApplicationList,
  koinvoice: { applicationListError },
}) {
  const mounted = useRef();

  useEffect(()=>{
    getApplicationList(); // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (
        applicationListError.data &&
        applicationListError.data.status === 404
      ) {
       // history.push("/koininvoice/apply-loan");
      }
    }
  });
  return (
    <DashboardKoinInvoiceStyle>
      <div className="kw-dashboardKinInvoiceWrapper">
        <DashboardLayoutKoinInvoice>
          <iframe
            src="https://stag.koinworks.com/blog/v2/koininvoice.html"
            title="Iframe Example"
          ></iframe>
        </DashboardLayoutKoinInvoice>
      </div>
    </DashboardKoinInvoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationList
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationWrapper);
