import {
  serviceWithToken,
  serviceWithoutToken,
  serviceUploadWithToken,
} from "./httpClient";
import { PATH_KOINVOICE_LOAN } from "./path/koinvoice/koinvoice";
import {
  apiGetWithToken,
  apiPostWithToken,
  apiDeleteWithToken,
  apiPostWithoutToken,
  apiPostWithTokenOcr,
  apiPostWithTokenOcrV3,
  apiPutWithToken,
} from ".";
import { PATH_KOINVOICE_ASSESSMENTS } from "./path/koinvoice/assessments";
import cookie from "react-cookies";
import { openNotificationWithIcon } from "../library/notifications";
import history from "../util/history";

const getPrincipalCode = (request, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "POST",
          url: PATH_KOINVOICE_LOAN.GET_PRINCIPAL,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
          console.log(response);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
          reject(error.response);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "POST",
          url: PATH_KOINVOICE_LOAN.GET_PRINCIPAL,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
          console.log(response);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const getFinancingCode = (request, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "POST",
          url: PATH_KOINVOICE_LOAN.GET_REFERRAL,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "POST",
          url: PATH_KOINVOICE_LOAN.GET_REFERRAL,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const getProvince = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.PROVINCE}${request}`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getCity = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.CITY}${request}`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getDistrict = (keyword) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.DISTRICT}${keyword}`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getSubDistrict = (keyword) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.SUB_DISTRICT}${keyword}`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getLoanBalance = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.LOAN_BALANCE}`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getLoanLimit = (token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "GET",
          url: `${PATH_KOINVOICE_LOAN.LOAN_LIMIT}`,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "GET",
          url: `${PATH_KOINVOICE_LOAN.LOAN_LIMIT}`,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const getLoanApplicationProgress = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.LOAN_APPLICATION_PROGRESS}`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getLoanDocumentList = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.DOCUMENT_LIST}`,
        data: request,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getBusinessInfo = (token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "GET",
          url: `${PATH_KOINVOICE_LOAN.BUSINESS_INFO}`,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "GET",
          url: `${PATH_KOINVOICE_LOAN.BUSINESS_INFO}`,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const purposeLoanKoinvoice = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "POST",
        url: PATH_KOINVOICE_LOAN.PURPOSE,
        data: request,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const postResendEmailKoinvice = (request, paramsId) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "POST",
        url: `${PATH_KOINVOICE_LOAN.RESEND_EMAIL}/${paramsId}`,
        data: request,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getDocumentUploadKoinvice = (paramsID) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.GET_DETAILS_LOAN}${paramsID}/document_upload`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getSendDocumentRequestKoinvice = (paramsID) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.GET_DETAILS_LOAN}${paramsID}/send_document_request`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const postSendLimitRequestKoinvice = (params) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "POST",
        url: `${PATH_KOINVOICE_LOAN.LIMIT_DOCUMENT_LINK}`,
        data: params
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const getLoanDetailKoinvice = (paramsID) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "GET",
        url: `${PATH_KOINVOICE_LOAN.GET_DETAILS_LOAN}${paramsID}/details`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const putLoanDetailKoinvice = (request) => {
  if (request.token) {
    let { token, ...params } = request;
    return new Promise((resolve, reject) => {
      serviceWithToken(request.token)
        .request({
          method: "POST",
          url: `${PATH_KOINVOICE_LOAN.LOAN_APPLY_AFTER_LIMIT}`,
          data: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "POST",
          url: `${PATH_KOINVOICE_LOAN.LOAN_APPLY_AFTER_LIMIT}`,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const getBusinessInfoKoinvice = (token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "GET",
          url: `v1/koinrevolver/limit/application/business-info`,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "GET",
          url: `v1/koinrevolver/limit/application/business-info`,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const getBusinessCategory = (token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "GET",
          url: `v1/koinbisnis/master-data/business-industry`,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const getApplicationListLoan = (params, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "GET",
          url: PATH_KOINVOICE_LOAN.YOUR_LOAN_DETAILS,
          data: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "GET",
          url: PATH_KOINVOICE_LOAN.YOUR_LOAN_DETAILS,
          data: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const putBusinessInfoKoinvice = (paramsID, request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "PUT",
        url: `${PATH_KOINVOICE_LOAN.GET_DETAILS_LOAN}${paramsID}/business_info`,
        data: request,
      })

      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const putBusinessInfoProfileKoinvice = (request, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "PUT",
          url: `${PATH_KOINVOICE_LOAN.BUSINESS_INFO_PROFILE}`,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "PUT",
          url: `${PATH_KOINVOICE_LOAN.BUSINESS_INFO_PROFILE}`,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const putPersonalInfoKoinvice = (paramsID, request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "PUT",
        url: `${PATH_KOINVOICE_LOAN.GET_DETAILS_LOAN}${paramsID}/personal_info`,
        data: request,
      })

      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const putPersonalInfoProfileKoinvice = (request, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "PUT",
          url: `${PATH_KOINVOICE_LOAN.PERSONAL_INFO_PROFILE}`,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "PUT",
          url: `${PATH_KOINVOICE_LOAN.PERSONAL_INFO_PROFILE}`,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const calculatorLoanKoinVoice = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithoutToken()
      .request({
        method: "POST",
        url: PATH_KOINVOICE_LOAN.CALCULATOR_LOAN,
        data: request,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

const uploadLoanKoinVoice = (request, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceUploadWithToken(token)
        .request({
          method: "POST",
          url: PATH_KOINVOICE_LOAN.UPLOAD_NEW,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceUploadWithToken()
        .request({
          method: "POST",
          url: PATH_KOINVOICE_LOAN.UPLOAD_NEW,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const putCreteShareHolderLoanKoinVoice = (request, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      serviceWithToken(token)
        .request({
          method: "POST",
          url: PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR_CREATE,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      serviceWithToken()
        .request({
          method: "POST",
          url: PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR_CREATE,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    });
  }
};

const putUpdateShareHolderLoanKoinVoice = (request, paramId, token = null) => {
  return new Promise((resolve, reject) => {
    if (token) {
      serviceWithToken(token)
        .request({
          method: "POST",
          url: `${PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR_UPDATE}${paramId}`,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    } else {
      serviceWithToken()
        .request({
          method: "POST",
          url: `${PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR_UPDATE}${paramId}`,
          data: request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
          if (error?.response?.status === 401) {
            setTimeout(() => { history.push("/login") }, 500)
            cookie.remove("token");
          }
        });
    }
  });
};

async function putDeleteShareHolderLoanKoinVoice(props) {
  let response = "";
  let paramId = props.paramId;
  try {
    response = props.token ?
      await apiDeleteWithToken(
        `${PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR_DELETE}${paramId}`,
        props.token
      ) :
      await apiDeleteWithToken(
        `${PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR_DELETE}${paramId}`
      );
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function listLoanStatusUpdate(props) {
  let response = "";
  let product = props.product;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.DOCUMENT_STATUS_LOAN_UPDATE}`,
      product
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function documentList(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.DOCUMENT_LIST}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function facePhotoValidation(props) {
  let response = "";
  let ktp = props.ktp;
  let img = props.img;
  let token = props?.token || null;
  let loading = props.loading ? props.loading : function () { };
  try {
    if (token) {
      response = await apiPostWithTokenOcr("face-match", {
        ktp_link: ktp,
        selfie: img,
      }, token);
    } else {
      response = await apiPostWithTokenOcr("face-match", {
        ktp_link: ktp,
        selfie: img,
      });
    }
    loading(false);
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
    loading(false);
  }
  return response && response.data;
}

async function ktpValidationKoinVoice(props) {
  let response = "";
  let params = props.params;
  let token = props?.token;
  let loading = props.loading ? props.loading : function () { };
  try {
    if (token) {
      response = await apiPostWithTokenOcrV3("ktp-validation", {
        ktp_link: params,
      }, token);
    } else {
      response = await apiPostWithTokenOcrV3("ktp-validation", {
        ktp_link: params,
      });
    }
    loading(false);
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
    loading(false);
  }
  return response && response.data;
}

async function documentListOld(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    if (props.token) {
      response = await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.DOCUMENT_LIST_OLD}`,
        params,
        props.token
      );
    } else {
      response = await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.DOCUMENT_LIST_OLD}`,
        params
      );
    }
    loading(false);
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
    loading(false);
  }
  return response && response.data;
}

async function documentSendLink(props) {
  let response = "";
  let params = props.params;
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_LOAN.DOCUMENT_SEND_LINK}`,
      params
    );
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function dashboardScheduleList(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      PATH_KOINVOICE_LOAN.DASHBOARD_SCHEDULE_LIST,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
    loading(false);
  }
  return response && response.data;
}

async function actionChangeGetYourLoan(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      PATH_KOINVOICE_LOAN.YOUR_LOAN_DETAILS,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
    loading(false);
  }
  return response && response.data;
}

async function actionGetAssesmentsAppGuarantor(props) {
  let response = "";
  let params = props.params;
  let token = props?.token || null;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    if (token) {
      response = await apiGetWithToken(
        PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR,
        params,
        token
      );
    } else {
      response = await apiGetWithToken(
        PATH_KOINVOICE_ASSESSMENTS.APP_GUARANTOR,
        params
      );
    }
    loading(false);
  } catch (error) {
    response = error.response;
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
    loading(false);
  }
  return response && response.data;
}

async function actionCreateBusinessAccount(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  let isShowModalSuksessRegister = props.isShowModalSuksessRegister
    ? props.isShowModalSuksessRegister
    : function () { };
  let showMessageError = props.showMessageError
    ? props.showMessageError
    : function () { };
  loading(true);
  isShowModalSuksessRegister(false);
  try {
    response = await apiPostWithoutToken('/v1/users/sme/register/business/ex', params);
    cookie.save("token", response.data.data.access_token, {
      path: "/",
      maxAge: 3600,
    });
    loading(false);
    isShowModalSuksessRegister(true);
  } catch (error) {
    response = error.response;
    response &&
      response.data &&
      response.data.message &&
      showMessageError(
        `${response.data.message.en} / ${response.data.message.id}`
      );
    isShowModalSuksessRegister(false);
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionInformationDetailLoan(props) {
  let response = "";
  let paramsId = props.paramsId;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.INFORMATION_LOAN_DETAILS}${paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetDisbursementBank(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.DOCUMENT_PRIMARY_BANK}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetChooseOfferList(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.LOAN_OFFER_LIST}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionRejectChooseOfferList(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let params = props.paramsId;
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_LOAN.REJECT_OFFER_LIST}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionChooseOfferList(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let params = props.paramsId;
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_LOAN.CHOOSE_OFFER_LIST}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetLoanContractList(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.LOAN_CONTRACT}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetInformationLoan(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.INFORMATION_LOAN}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetCreditLimitLoan(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    if (props.token) {
      response = await apiGetWithToken(`${PATH_KOINVOICE_LOAN.CREDIT_LIMIT_GET}`, null, props.token);
    } else {
      response = await apiGetWithToken(`${PATH_KOINVOICE_LOAN.CREDIT_LIMIT_GET}`);
    }
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

// async function actionUpdateCreditLimitLoan(props) {
//   let response = "";
//   let loading = props.loading ? props.loading : function () { };
//   let params = props.params;
//   loading(true);
//   try {
//     response = await apiPostWithToken(
//       `${PATH_KOINVOICE_LOAN.CREDIT_LIMIT_UPDATE}`,
//       params
//     );
//     loading(false);
//   } catch (error) {
//     response = error.response;
//     loading(false);
//     if (error?.response?.status === 401) {
//       setTimeout(() => { history.push("/login") }, 500)
//       cookie.remove("token");
//     }
//   }
//   return response && response.data;
// }

async function actionGetAssesmentsBankDisbursement(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      PATH_KOINVOICE_ASSESSMENTS.APP_DISBURSEMENT_BANK,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetAssesmentsContractLimitApplication(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      PATH_KOINVOICE_ASSESSMENTS.APP_CONTRACT_LIMIT,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionPostAssesmentsContractLimitApplication(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiPostWithToken(
      PATH_KOINVOICE_ASSESSMENTS.APP_CONTRACT_LIMIT_POST
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function getBankValidation(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let url = props.id ?
    `${PATH_KOINVOICE_ASSESSMENTS.GET_POST_BANK_VALIDATION}?loan_id=${props.id}` :
    `${PATH_KOINVOICE_ASSESSMENTS.GET_POST_BANK_VALIDATION}`;
  loading(true);
  try {
    response = await apiGetWithToken(url);
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function getBankValidationById(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_ASSESSMENTS.GET_POST_BANK_VALIDATION}?loan_id=${props.id}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function postBankValidation(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_ASSESSMENTS.GET_POST_BANK_VALIDATION}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionThirdPartyOy(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_ASSESSMENTS.THIRD_PARTY_OY}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionLimitApproved(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_LOAN.LIMIT_APPROVED}`, 
      props.params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionLimitReject(props) {
  let response = "";
  // let paramsId = props.paramsId;
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_LOAN.LIMIT_REJECT}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetBorrowerInformation(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    if (props.token) {
      response = await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.BORROWER_INFORMATION}`,
        null,
        props.token
      );
    } else {
      response = await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.BORROWER_INFORMATION}`
      );
    }
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionPostLimitApplication(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_LOAN.LIMIT_APPLICATION}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionPostLimitConfirm(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let nextPage = props.nextPage ? props.nextPage : function () { };
  let params = props.params;
  loading(true);
  try {
    if (props.token) {
      response = await apiPostWithToken(
        `${PATH_KOINVOICE_LOAN.LIMIT_CONFIRM}${params.id}`,
        { token: props.token, ...params.body }
      );
    } else {
      response = await apiPostWithToken(
        `${PATH_KOINVOICE_LOAN.LIMIT_CONFIRM}${params.id}`,
        { ...params.body }
      );
    }
    loading(false);
    nextPage();
  } catch (error) {
    response = error.response;
    openNotificationWithIcon("error", "Internal Sever Error");
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function submission(props) {
  let params = props.params;
  let id = props.id;
  let response = "";
  try {
    response = await apiPutWithToken(
      `${PATH_KOINVOICE_LOAN.SUBMISSION}${id}`,
      params
    );
  } catch (error) {
    response = error.response;
    openNotificationWithIcon("error", error.response.data.message.en);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function documentCompeleteAssessment(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  loading(true);
  try {
    response = await apiPostWithToken(PATH_KOINVOICE_ASSESSMENTS.DOCUMENT_COMPLETE);
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function actionGetLocationThirdParty(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let params = props.params;
  loading(true);
  try {
    response = props.token ?
      await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.LOCATION}${params}`,
        null,
        props.token
      ) :
      await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.LOCATION}${params}`,
        null
      );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
}

async function getBusinessCategoryKoininvoice(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let params = props.params;
  loading(true);
  try {
    response = props.token ?
      await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.BUSINESS_CATEGORY}${params}`,
        null,
        props.token
      ) :
      await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.BUSINESS_CATEGORY}${params}`,
        null
      );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
};

async function getPrincipalAccount(token = null) {
  let url = `${PATH_KOINVOICE_ASSESSMENTS.GET_PRINCIPAL_ACCOUNT}`;
  
  return new Promise((resolve, reject) => {
    serviceWithToken(token)
      .request({
        method: "GET",
        url: url,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
};

async function getCountryKoininvoice(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let params = props.params;
  loading(true);
  try {
    response = props.token ?
      await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.COUNTRY}${params}`,
        null,
        props.token
      ) :
      await apiGetWithToken(
        `${PATH_KOINVOICE_LOAN.COUNTRY}${params}`,
        null
      );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
};

async function postContractDigisign(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  const params = props.params;
  loading(true);
  try {
    response = await apiPostWithToken(
      `${PATH_KOINVOICE_LOAN.CONTRACT_DIGISIGN}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
};

async function getUserIndustryList(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let params = props.params;
  loading(true);
  try {
    response = await apiGetWithToken(
        `${PATH_KOINVOICE_ASSESSMENTS.GET_USER_INDUSTRY_LIST}${params}`,
        null,
        props.token
      )
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
};

async function getIndustryList(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () { };
  let level = props.level;
  let parentId = props.parentId || '';
  let search = props.search;
  loading(true);
  try {
    response = await apiGetWithToken(
        `${PATH_KOINVOICE_ASSESSMENTS.GET_INDUSTRY_LIST}?level=${level}&parent_id=${parentId}&search=${search}&limit=100`,
        null,
        props.token
      )
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
    if (error?.response?.status === 401) {
      setTimeout(() => { history.push("/login") }, 500)
      cookie.remove("token");
    }
  }
  return response && response.data;
};

const postCreteUserIndustry = (request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "POST",
        url: PATH_KOINVOICE_ASSESSMENTS.CREATE_UPDATE_USER_INDUSTRY,
        data: request,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
}
const putUpdateUserIndustry = (id, request) => {
  return new Promise((resolve, reject) => {
    serviceWithToken()
      .request({
        method: "PUT",
        url: `${PATH_KOINVOICE_ASSESSMENTS.CREATE_UPDATE_USER_INDUSTRY}/${id}`,
        data: request,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        if (error?.response?.status === 401) {
          setTimeout(() => { history.push("/login") }, 500)
          cookie.remove("token");
        }
      });
  });
}

const koinvoiceServices = {
  actionGetLocationThirdParty,
  documentCompeleteAssessment,
  submission,
  actionPostLimitConfirm,
  actionPostLimitApplication,
  actionGetBorrowerInformation,
  postSendLimitRequestKoinvice,
  actionLimitApproved,
  actionLimitReject,
  actionThirdPartyOy,
  postBankValidation,
  getBankValidation,
  getBankValidationById,
  actionGetAssesmentsBankDisbursement,
  actionGetAssesmentsContractLimitApplication,
  actionPostAssesmentsContractLimitApplication,
  actionGetInformationLoan,
  ktpValidationKoinVoice,
  facePhotoValidation,
  actionInformationDetailLoan,
  actionGetLoanContractList,
  actionChooseOfferList,
  actionRejectChooseOfferList,
  actionGetChooseOfferList,
  actionGetDisbursementBank,
  actionCreateBusinessAccount,
  actionGetAssesmentsAppGuarantor,
  putCreteShareHolderLoanKoinVoice,
  putUpdateShareHolderLoanKoinVoice,
  putDeleteShareHolderLoanKoinVoice,
  actionGetCreditLimitLoan,
  // actionUpdateCreditLimitLoan,
  actionChangeGetYourLoan,
  dashboardScheduleList,
  documentSendLink,
  documentList,
  documentListOld,
  listLoanStatusUpdate,
  getLoanApplicationProgress,
  getLoanDocumentList,
  getProvince,
  getCity,
  getLoanBalance,
  getLoanLimit,
  getPrincipalCode,
  getFinancingCode,
  getSendDocumentRequestKoinvice,
  getDocumentUploadKoinvice,
  purposeLoanKoinvoice,
  putLoanDetailKoinvice,
  getLoanDetailKoinvice,
  getBusinessInfoKoinvice,
  putBusinessInfoKoinvice,
  putBusinessInfoProfileKoinvice,
  putPersonalInfoKoinvice,
  putPersonalInfoProfileKoinvice,
  calculatorLoanKoinVoice,
  postResendEmailKoinvice,
  uploadLoanKoinVoice,
  getApplicationListLoan,
  getDistrict,
  getSubDistrict,
  getCountryKoininvoice,
  getBusinessCategoryKoininvoice,
  getBusinessInfo,
  getBusinessCategory,
  getPrincipalAccount,
  postContractDigisign,
  getUserIndustryList,
  getIndustryList,
  postCreteUserIndustry,
  putUpdateUserIndustry
};

export default koinvoiceServices;
