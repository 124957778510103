import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import TextField from "@material-ui/core/TextField";
import { RefCompanyWrapper } from "./ReferCompany.styles";
import * as validate from "../services/validate";
import history from "../util/history";

const textInputComponent = ({ input, label, placeholder, meta: { touched, error }, ...custom }) => (
  <div className={`${touched && (error && "invalid")}`}>
    <TextField
      label={label}
      placeholder={placeholder}
      value={input.value}
      {...input}
      {...custom}
    />
    {(touched && error && (<div className="invalid-feedback"> {error} </div>))}
  </div>
)

const phoneInputComponent = ({ input, placeholder, disabled, type, meta: { error, touched }, handleChange}) => (
	<div className={`${touched && (error && "has-error")}`}>
		<PhoneInput
      prefix={''}
      country={'id'}
      placeholder={placeholder} 
      type={type} 
      disabled={disabled} 
      autoFormat={false}
      enableSearch={false}
      value={input.value}
      onChange={(value, data) => {
        input.onChange(value)
        handleChange(
          data.dialCode,
          (
            value.length && value.substring(0, 1) === '0' ?
              value
            : 
              value.replace(/[^0-9]+/g,'').slice(data.dialCode.length)
          )
        )
      }
      }
    />
    {touched && ((error && <div className="error-text">{error}</div>))}
	</div>
)

class ReferCompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    input: {
      company: "",
      pic_name: "",
      pic_email: "",
      pic_phone: ""
    },
    resp: {},
    msg: ""
  };

  onInputChange = ({ target }) => {
    let { input } = this.state;
    input[target.name] = target.value;
    this.setState({ input: input });
  };

  onPhoneChange = (data, value) => {
    let { input } = this.state;
    input["pic_phone"] = value;
    this.setState({ input: input });
  };

  handleSubmit = async event => {
    event.preventDefault();
    let _this = this;
    let { input, msg } = this.state;
    let data = {
      vendor_name: input.company,
      pic_name: input.pic_name,
      pic_email: input.pic_email,
      pic_phone_number: input.pic_phone
    };

    await fetch(
      "https://sandbox-api.koinworks.com/v1/koinemployee/refer-my-company",
      {
        body: JSON.stringify(data),
        method: "POST",
        headers: new Headers({
          Authorization:
            "Bearer " + this.props.auth.token  })
      }
    )
      .then(res => res.json())
      .then(data => {
        _this.state.resp = data;
      });

    if (_this.state.resp.status !== 200) {
      msg = _this.state.resp.message.en;
      this.setState({ msg: msg });
    } else {
      history.push("/loan-app");
    }
  };

  render() {
    const { input, msg } = this.state;
    const { 
      handleSubmit,
      pristine,
      submitting,
      invalid,
      init : { language } 
    } = this.props;
    return (
      <RefCompanyWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <figure className="logo-wrapper"></figure>
            <div className="back-button" style={{ cursor: "pointer" }}>
              <a href="!#" style={{ color: "#C4C4C4", textDecoration: "none" }}>
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span>{language.button.back}</span>
              </a>
            </div>
            <div className="card-body register-body">
              <h2 className="register-title">{language.title.referCompany}</h2>
              <form
                className="rc-form"
                noValidate
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <div className="input-wrapper">
                  <Field
                    id="company"
                    name="company"
                    label={language.form.companyName}
                    variant="outlined"
                    className="w-100"
                    value={input.company}
                    component={textInputComponent}
                    onChange={this.onInputChange}
                    validate={validate.required}
                  />
                </div>
                <div className="separator">
                  <div className="line"></div>
                </div>
                <span className="sub-title">PIC (HUMAN RESOURCES)</span>
                <div className="input-wrapper">
                  <Field
                    id="pic_name"
                    name="pic_name"
                    label={language.form.picName}
                    variant="outlined"
                    className="w-100"
                    component={textInputComponent}
                    value={input.pic_name}
                    onChange={this.onInputChange}
                    validate={validate.required}
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="pic_email"
                    name="pic_email"
                    label={language.form.picEmail}
                    variant="outlined"
                    className="w-100"
                    value={input.pic_email}
                    onChange={this.onInputChange}
                    component={textInputComponent}
                    validate={[validate.required, validate.email]}
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    placeholder={language.form.mobileNumber}
                    type="text"
                    name="pic_phone"
                    autoFormat={false}
                    enableSearch={false}
                    component={phoneInputComponent}
                    value={input.pic_phone}
                    handleChange={this.onPhoneChange}
                    validate={[validate.required, validate.number, validate.minLengthPhoneNumber]}
                  />
                </div>
                <div className="input-wrapper">
                  <button
                    className="btn btn-primary w-100"
                    disabled={ invalid || pristine || submitting } 
                    onSubmit={handleSubmit}
                  >
                    {language.button.submitContinue}
                  </button>
                  <div className="error-message">{msg.flag}</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </RefCompanyWrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  init: state.init
});

const component = connect(mapStateToProps, null)(ReferCompanyForm);

export default reduxForm({
  form: "ReferCompanyForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(component);

