import React, { Component } from "react";
import { connect } from "react-redux";
import { HowtoWrapper } from "./Howto.styles";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import gplay from "../../../assets/images/gplay.png";
import appstore from "../../../assets/images/appstore.png";

class Howto extends Component {
  render() {
    let { init: { language } } = this.props;
    return (
      <HowtoWrapper>
        <section id="howto" className="howto">
          <div className="container">
            <div className="howto-wrapper">
              <div className="section-heading-wrapper">
                <h3
                  className="section-heading"
                  dangerouslySetInnerHTML={{ __html: language.homepage.howToUse }}
                />
              </div>
              {/* <figure className="phone-frame">
                <img src="/img/howto/phone.png" alt="" />
              </figure> */}
              <Carousel
                className="carousel-howto"
                autoPlay
                infiniteLoop
                transitionTime={1000}
                showStatus={false}
                showThumbs={false}
              >
                <div>
                  <div className="slide-img-wrapper">
                    <figure className="slide-img">
                      <img
                        src="/img/howto/screen1/screenframe.png"
                        alt="berita satu"
                      />
                    </figure>
                    <div className="pop-item">
                      <figure
                        className="item"
                        style={{ top: "210px", left: "50px" }}
                      >
                        <img src="/img/howto/screen1/pop1.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "390px", left: "280px" }}
                      >
                        <img src="/img/howto/screen1/pop2.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <span className="slide-text">
                    <h3 className="heading">
                      {language.homepage.howToUseSlide1Title}
                    </h3>
                    <p className="text">
                      {language.homepage.howToUseSlide1Text}
                    </p>
                    <div className="download">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.koinworks.app&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <figure className="store mr-3">
                          <img src={gplay} alt="" />
                        </figure>
                      </a>
                      <a
                        href="https://apps.apple.com/id/app/koinworks/id1457026579"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <figure className="store">
                          <img src={appstore} alt="" />
                        </figure>
                      </a>
                    </div>
                  </span>
                </div>
                <div>
                  <div className="slide-img-wrapper">
                    <figure className="slide-img">
                      <img
                        src="/img/howto/screen2/screenframe.png"
                        alt="marketeers"
                      />
                    </figure>
                    <div className="pop-item">
                      <figure
                        className="item"
                        style={{ top: "150px", left: "240px" }}
                      >
                        <img src="/img/howto/screen2/pop1.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "300px", left: "40px" }}
                      >
                        <img src="/img/howto/screen2/pop2.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "380px", left: "250px" }}
                      >
                        <img src="/img/howto/screen2/pop3.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <span className="slide-text">
                    <h3 className="heading">
                      {language.homepage.howToUseSlide2Title}
                    </h3>
                    <p className="text">
                      {language.homepage.howToUseSlide2Text}
                    </p>
                  </span>
                </div>
                <div>
                  <div className="slide-img-wrapper">
                    <figure className="slide-img">
                      <img
                        src="/img/howto/screen3/screenframe.png"
                        alt="republika online"
                      />
                    </figure>
                    <div className="pop-item">
                      <figure
                        className="item"
                        style={{ top: "100px", left: "320px" }}
                      >
                        <img src="/img/howto/screen3/pop1.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "120px", left: "130px" }}
                      >
                        <img src="/img/howto/screen3/pop2.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "220px", left: "60px" }}
                      >
                        <img src="/img/howto/screen3/pop3.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "240px", left: "240px" }}
                      >
                        <img src="/img/howto/screen3/pop4.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "350px", left: "80px" }}
                      >
                        <img src="/img/howto/screen3/pop5.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "370px", left: "320px" }}
                      >
                        <img src="/img/howto/screen3/pop6.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <span className="slide-text">
                    <h3 className="heading">{language.homepage.howToUseSlide3Title} </h3>
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{ __html: language.homepage.howToUseSlide3Text }}
                    />
                  </span>
                </div>
                <div>
                  <div className="slide-img-wrapper">
                    <figure className="slide-img">
                      <img
                        src="/img/howto/screen4/screenframe.png"
                        alt="republika online"
                      />
                    </figure>
                    <div className="pop-item">
                      <figure
                        className="item"
                        style={{ top: "50px", left: "30px" }}
                      >
                        <img src="/img/howto/screen4/pop1.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "450px", left: "10px" }}
                      >
                        <img src="/img/howto/screen4/pop2.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "270px", left: "30px" }}
                      >
                        <img src="/img/howto/screen4/pop3.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "340px", left: "230px" }}
                      >
                        <img src="/img/howto/screen4/pop4.png" alt="" />
                      </figure>
                      <figure
                        className="item"
                        style={{ top: "150px", left: "200px" }}
                      >
                        <img src="/img/howto/screen4/pop5.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <span className="slide-text">
                    <h3 className="heading">
                      {language.homepage.howToUseSlide4Text}
                    </h3>
                  </span>
                </div>
              </Carousel>
            </div>
          </div>
        </section>
      </HowtoWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init
});

export default connect(mapStateToProps, null)(Howto);
