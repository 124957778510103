import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FileBase64 from "react-file-base64";
import { Modal, Tabs, Tab, Spinner } from "react-bootstrap";
import { StepLoanWrapper } from "./StepLoan.styles";
import { loanInfo } from "../../stores/actions/loan";
import { uploadKTP } from "../../stores/actions/account";
import { calculation } from "../common/calculation";
import * as validate from "../../services/validate";
import SimpleTextInput from "../../Components/Form/simpleTextInput";
import CurrencyFormatInput from "../../Components/Form/currencyFormatInput";
import SimpleSelectInput from "../../Components/Form/simpleSelectInput";

import regularImg from "../../assets/images/applyloan/regular.png";
import advanceImg from "../../assets/images/applyloan/salary-advance.png";
import ktpImg from "../../assets/images/applyloan/ktp-img.png";


class StepLoan extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    values: {
      advAmount: "",
      regAmount: "",
      advPurpose: "",
      regPurpose: "",
      regTerm: 1,
      advTerm: 1,
      regFile: null,
      advFile: null,
      advFileName: "",
      regFileName: ""
    },
    offer: "",
    loanAmount: 0.0,
    installment: 0.0,
    fee: 0.0,
    show: false,
    show1: false,
    tenures: [],
    errMessage: {
      advAmount: "",
      regAmount: ""
    }
  };

  onSelectTab = val => {
    this.setState({ offer: val });
  };

  handleChange = name => event => {
    const { values } = this.state;
    let loanAmount = this.state.loanAmount;
    let installment = this.state.installment;
    let fee = this.state.fee;
    const loan = this.props.loan.data;
    if (name === "advTerm" || name === "regTerm") {
      values[name] = event.value;
      if (this.state.offer === "reg") {
        [loanAmount, installment, fee] = calculation.regular(
          loan.loan_status,
          loan.e_status,
          values.regAmount,
          values.regTerm,
          loan.vendor_data
        );
      }
    } else if (name === "advAmount" || name === "regAmount") {
      values[name] = event;
      if (this.state.offer === "adv") {
        [fee, loanAmount] = calculation.salaryAdvance(event);
      } else {
        [loanAmount, installment, fee] = calculation.regular(
          loan.loan_status,
          loan.e_status,
          event,
          values.regTerm,
          loan.vendor_data
        );
      }
    } else {
      values[name] = event.target.value;
    }
    this.setState({
      values: values,
      loanAmount: loanAmount,
      installment: installment,
      fee: fee
    });
  };

  // modal
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  handleClose1 = () => this.setState({ show1: false });
  handleShow1 = () => this.setState({ show1: true });
  //image uploader
  imageChange = name => file => {
    let { auth } = this.props;
    let base64string = file.base64;
    let { values } = this.state;
    values[name] = URL.createObjectURL(file.file);
    values[name + "Name"] = file.name;
    this.setState({ values: values, show1: false });
    let data = {
      base64: [base64string],
      email: auth.email
    };
    this.props.uploadKTP(data);
  };

  resetFile = name => event => {
    event.preventDefault();
    let { values } = this.state;
    values[name] = null;
    this.setState({ values: values });
  };

  updateTerm = e => {
    let { values } = this.state;
    values["term"] = e.value;
    this.setState({ values: values });
  };

  renderTenures() {
    let flag = this.props.init.flag;
    let suffix = flag === "en" ? " months" : " bulan";
    if (this.props.loan.data !== null) {
      let data = JSON.parse(this.props.loan.data.tenure);
      return data.map(item => ({ value: item, label: item + suffix }));
    } else {
      return [];
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit();
    let { offer, values, loanAmount, installment, fee } = this.state;
    let loan = this.props.loan.data;
    let file = this.props.upload;
    if (offer === "adv") {
      this.props.loanInfo({
        data: {
          loan_amount: parseInt(values.advAmount),
          disbursement: loanAmount,
          installment: installment,
          fee: fee,
          tenure: values.advTerm,
          nik: file.ktpDetail.nik,
          ktp_url: file.ktp[0],
          loan_purpose: values.advPurpose,
          offer: "Salary Advance",
          employee_status: loan.status,
          loan_status: loan.loan_status,
          vendor_data: loan.vendor_data
        }
      });
    } else {
      this.props.loanInfo({
        data: {
          loan_amount: parseInt(values.regAmount),
          disbursement: loanAmount,
          installment: installment,
          fee: fee,
          tenure: values.regTerm,
          nik: file.ktpDetail.nik,
          ktp_url: file.ktp[0],
          loan_purpose: values.regPurpose,
          offer: "Regular",
          employee_status: loan.status,
          loan_status: loan.loan_status,
          vendor_data: loan.vendor_data
        }
      });
    }
  };

  render() {
    const { values, offer, installment, loanAmount, fee } = this.state;
    const { upload, invalid, pristine, submitting, init: {language, flag} } = this.props;
    const vendor = this.props.loan.data.vendor_data;

    if (upload.ktpDetail && offer === 'adv')
      this.props.change('advNik', upload.ktpDetail.nik);
    if (upload.ktpDetail && offer === 'reg')
      this.props.change('regNik', upload.ktpDetail.nik);

    return (
      <StepLoanWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title">{language.title.chooseOffer}</h2>
          <Tabs
            defaultActiveKey={
              !vendor.loan_type.salary_advance ? "reg" : this.state.offer
            }
            id="offer-type"
            className="offer-box-wrapper"
            onSelect={this.onSelectTab}
          >
            {/* advance */}
            <Tab
              eventKey="adv"
              disabled={!vendor.loan_type.salary_advance}
              title={
                <div>
                  <figure className="choose-offer-img">
                    <img src={advanceImg} alt="salary-advance" />                    
                  </figure>
                  <h5 className="radio-title">Salary Advance</h5>
                  <p className="radio-text">
                    {language.text.salaryAdvance}
                  </p>
                  <span className="badge badge-success">Disburse in 1 day</span>
                </div>
              }
            >
              {offer === "adv" && (
                <div className="offer-content-wrapper">
                  <h2 className="step-title">
                    {language.title.stepLoan}
                  </h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-wrapper">
                      <Field
                        id="adv-amount"
                        name="advAmount"
                        label={language.form.advAmount}
                        variant="outlined"
                        className={`adv-amount w-100`}
                        component={SimpleTextInput}
                        value={values.advAmount}
                        onChange={this.handleChange("advAmount")}
                        validate={[validate.required, validate.floatAmount, validate.advAmountInternal]}
                        InputProps={{
                          inputComponent: CurrencyFormatInput
                        }}
                        helperText={
                          <>
                            <span>
                              {language.text.advAmount}
                            </span>
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                              onClick={this.handleShow}
                            ></i>
                          </>
                        }
                      />
                    </div>
                    <div className="input-wrapper">
                      <Field
                        id="input-adv-purpose"
                        name="advPurpose"
                        label={language.form.purpose}
                        variant="outlined"
                        className="w-100"
                        value={values.advPurpose}
                        component={SimpleTextInput}
                        onChange={this.handleChange("advPurpose")}
                        validate={validate.required}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className="box-total">
                        <div className="box-list camount-req">
                          <span className="text-left">{language.label.amountRequested}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={
                                values.advAmount !== "" ? values.advAmount : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                        <div className="box-list cfee">
                          <span className="text-left">{language.label.fee}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={fee}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                        <div className="box-list ctotal">
                          <span className="text-left">{language.label.totalDisburse}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={loanAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <h2 className="step-title">{language.title.uploadKtp}</h2>
                    <div className="input-wrapper">
                      <div 
                        className="btn btn-upload"
                        onClick={this.handleShow1}
                      >
                        {language.button.uploadImage}
                      </div>
                      <div className="upload-info">
                        <span>{language.label.ktpInstruction}</span>
                      </div>
                      <br />
                      {values.advFile && (
                        <div className="img-preview-wrapper">
                          <div className="img-preview">
                            <img src={values.advFile} alt=""/>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%"
                            }}
                          >
                            <div className="img-link-wrapper">
                              <div className="btn-wrapper">
                                <button
                                  className="link-file"
                                  name="ktp"
                                  disabled
                                >
                                  {language.label.view}
                                </button>
                                <button
                                  className="link-file"
                                  name="advFile"
                                  onClick={this.resetFile("advFile")}
                                >
                                  {language.label.reupload}
                                </button>
                              </div>
                              <span className="img-name">
                                {values.advFileName}
                              </span>
                            </div>
                            {upload.isLoading && (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            )}
                            {upload.isKTPVerified && (
                              <span className="verified-file" name="ktp">
                                <i className="fa fa-check"></i> {language.label.verified}
                              </span>
                            )}
                            {!upload.isLoading && upload.isKTPVerified === false && (
                              <span
                                className="invalid-file"
                                name="ktp"
                                disabled
                              >
                                <i className="fa fa-times"></i> {language.label.invalid}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {this.state.offer === 'adv' && upload.ktpDetail && (
                      <div className="input-wrapper">
                        <Field
                          id="input-adv-nik"
                          name="advNik"
                          label={language.form.nik}
                          variant="outlined"
                          className="w-100"
                          component={SimpleTextInput}
                          value={upload.ktpDetail.nik}
                          onChange={this.handleChange("advNik")}
                          validate={[validate.required, validate.minLength(16)]}
                        />
                      </div>
                    )}
                    <div className="input-wrapper">
                      <button
                        className={"step-next btn btn-primary w-100"}
                        disabled={
                          invalid ||
                          pristine ||
                          submitting ||
                          !upload.isKTPVerified
                        }
                      >
                        {language.button.next}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Tab>

            {/* regular */}
            <Tab
              eventKey="reg"
              disabled={!vendor.loan_type.regular}
              title={
                <div>
                  <figure className="choose-offer-img">
                    <img src={regularImg} alt="regular-loan" />
                  </figure>
                  <h5 className="radio-title">Regular</h5>
                  <p className="radio-text">
                    {language.text.regularLoan}
                  </p>
                </div>
              }
            >
              {offer === "reg" && (
                <div className="offer-content-wrapper">
                  <h2 className="step-title">
                    {language.title.stepLoan}
                  </h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-wrapper">
                      <Field
                        id="input-reg-purpose"
                        name="regAmount"
                        label={language.form.loanAmount}
                        variant="outlined"
                        className="w-100"
                        component={SimpleTextInput}
                        onChange={this.handleChange("regAmount")}
                        InputProps={{
                          inputComponent: CurrencyFormatInput
                        }}
                        validate={[validate.required, validate.floatAmount]}
                      />
                    </div>
                    <div className="input-wrapper">
                      <Field
                        id="regTerm"
                        name="regTerm"
                        label={language.form.loanTerm}
                        placeholder={language.form.chooseTerm}
                        options={this.renderTenures()}
                        component={SimpleSelectInput}
                        onChange={this.handleChange("regTerm")}
                        validate={validate.required}
                      />
                    </div>
                    <div className="input-wrapper">
                      <Field
                        id="input-reg-loan-purpose"
                        name="regPurpose"
                        label={language.form.purpose}
                        variant="outlined"
                        className="w-100"
                        component={SimpleTextInput}
                        onChange={this.handleChange("regPurpose")}
                        validate={validate.required}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className="line"></div>
                    </div>
                    <div className="input-wrapper m-installment">
                      <h5 className="title">{language.label.monthlyInstallment}</h5>
                      <p className="text">
                        <NumberFormat
                          prefix={flag === "en" ? "IDR " : "Rp "}
                          value={installment}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </p>
                    </div>
                    <h2 className="step-title">{language.title.uploadKtp}</h2>
                    <div className="input-wrapper">
                      <div 
                        className="btn btn-upload"
                        onClick={this.handleShow1}
                      >
                        {language.button.uploadImage}
                      </div>
                      <div className="upload-info">
                        <span>{language.label.ktpInstruction}</span>
                      </div>
                      <br />
                      {values.regFile && (
                        <div className="img-preview-wrapper">
                          <div className="img-preview">
                            <img src={values.regFile} alt=""/>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%"
                            }}
                          >
                            <div className="img-link-wrapper">
                              <div className="btn-wrapper">
                                <button className="link-file" name="ktp">
                                  {language.label.view}
                                </button>
                                <button
                                  className="link-file"
                                  name="regFile"
                                  onClick={this.resetFile("regFile")}
                                >
                                  {language.label.reupload}
                                </button>
                              </div>
                              <span className="img-name">
                                {values.regFileName}
                              </span>
                            </div>
                            {upload.isLoading && (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            )}
                            {upload.isKTPVerified && (
                              <span className="verified-file" name="ktp">
                                <i className="fa fa-check"></i> {language.label.verified}
                              </span>
                            )}
                            {!upload.isLoading && upload.isKTPVerified === false && (
                              <span className="invalid-file" name="ktp">
                                <i className="fa fa-times"></i> {language.label.invalid}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {this.state.offer === 'reg' && upload.ktpDetail && (
                      <div className="input-wrapper">
                        <Field
                          id="input-reg-nik"
                          name="regNik"
                          label={language.form.nik}
                          variant="outlined"
                          className="w-100"
                          component={SimpleTextInput}
                          value={upload.ktpDetail.nik}
                          onChange={this.handleChange("regNik")}
                          validate={[validate.required, validate.minLength(16)]}
                        />
                      </div>
                    )}
                    <div className="input-wrapper">
                      <button
                        className={"step-next btn btn-primary w-100"}
                        disabled={
                          invalid ||
                          pristine ||
                          submitting ||
                          !upload.isKTPVerified
                        }
                      >
                        {language.button.next}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Tab>
          </Tabs>

          {/* modal */}
          <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton className="px-4 pb-0">
              <Modal.Title>
                <span className="title-info">
                  {language.title.advAmountModal}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-wrapper px-3">
                <Tabs
                  className="tab-offer"
                  defaultActiveKey="adv"
                  id="offer-info"
                >
                  <Tab
                    className="box-offer"
                    eventKey="adv"
                    title={
                      <div>
                        <h5 className="radio-title">Salary Advance</h5>
                        <p className="radio-text">
                          {language.text.salaryAdvance}
                        </p>
                      </div>
                    }
                  >
                    <div className="input-wrapper">
                      <form noValidate autoComplete="off">
                        <TextField
                          className="salary-info w-100"
                          label={language.form.yourSalary}
                          variant="outlined"
                          value=" IDR 10.000.000"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment className="adorement-info">
                                / {language.label.month}
                              </InputAdornment>
                            )
                          }}
                          disabled
                        />
                      </form>
                    </div>
                    <div className="input-wrapper">
                      <div className="box-total">
                        <div className="box-list ctotal">
                          <span className="text-left">{language.label.availabletoBorrow}</span>
                          <span className="text-right">
                            IDR 3,000,000 - 8,000,000
                          </span>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    className="box-offer"
                    eventKey="regular"
                    title={
                      <div>
                        <h5 className="radio-title">Regular</h5>
                        <p className="radio-text">
                          {language.text.regularLoan}
                        </p>
                      </div>
                    }
                  >
                    <div className="input-wrapper">
                      <form noValidate autoComplete="off">
                        <TextField
                          className="salary-info w-100"
                          label={language.form.yourSalary}
                          variant="outlined"
                          value=" IDR 10.000.000"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment className="adorement-info">
                                / {language.label.month}
                              </InputAdornment>
                            )
                          }}
                          disabled
                        />
                      </form>
                    </div>
                    <div className="input-wrapper">
                      <div className="box-total">
                        <div className="box-list ctotal">
                          <span className="text-left">{language.label.availabletoBorrow}</span>
                          <span className="text-right">
                            IDR 3,000,000 - 8,000,000
                          </span>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </Modal.Body>
          </Modal>

          {/* modal */}
          <Modal show={this.state.show1} onHide={this.handleClose1}>
            <Modal.Header closeButton className="px-4 pb-0">
              <Modal.Title>
                <span className="title-info">
                  {/* Get to Know How Much You Can Borrow */}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-wrapper px-3">
                <div className="uploadins-wrapper">
                  <div className="uploadins-text">
                    <h2 className="uploadins-title">{language.title.ktpInstructionModal}</h2>
                  </div>

                  <figure className="uploadins-img">
                    <img src={ktpImg} alt="" />
                  </figure>
                  <div className="uploadins-text">
                    <p className="uploadins-desc">
                      {language.text.ktpInstructionModal}
                    </p>
                    <p className="uploadins-desc mb-1">
                      <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                      {language.text.ktpRule1}
                    </p>
                    <p className="uploadins-desc">
                      <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                      {language.text.ktpRule2}
                    </p>
                    <div className="w-100 btn btn-primary">
                      Choose Image
                    </div>
                    <div className="file-upload">
                      <FileBase64
                        type="file"
                        multiple={false}
                        onDone={this.imageChange(this.state.offer === 'adv'
                        ? "advFile" : "regFile")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </StepLoanWrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  loan: state.loan,
  upload: state.upload,
  init: state.init
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ loanInfo, uploadKTP }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(StepLoan);

export default reduxForm({
  form: "StepLoan",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(component);
