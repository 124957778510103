import styled from "styled-components";

export const BusinessInfoStyle = styled.div`
  .kw-businessInfo-wrapper {
    margin-top: 35px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 30px;
      align-items: center;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
  }
  .kw-confirmYourRequest {
    h3 {
      margin-top: 35px;
      font-style: normal;
      font-weight: 555;
      font-size: 28px;
      color: #2b486d;
    }
    p {
      font-size: 20px;
      letter-spacing: 0.35px;
      color: #2d2d2d;
    }
    strong {
      font-style: normal;
      display: block;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #566068;
    }
    label {
      font-weight: 600;
      font-size: 15px;
      color: #8e8e8e;
      margin-bottom: 0rem;
    }
  }
  .kw-personalInfo {
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      margin-top: 30px;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
    &__button-upload {
      box-sizing: border-box;
      border-radius: 3px;
    }
    &__image-upload {
      height: 48px;
      width: 72px;
    }
    &__verifiy-ktp {
      display: flex;
      justify-content: flex-start;
      &__itemOne {
        margin-left: 20px;
        p {
          margin-bottom: 0rem;
          font-weight: 555;
          font-size: 15px;
          cursor: pointer;
          letter-spacing: -0.41px;
          text-decoration-line: underline;
          color: #378df4;
        }
      }
      &__itemTwo {
        margin-left: 20px;
        p {
          display: flex;
          align-items: center;
          color: #41af60;
        }
      }
    }
  }
`;
