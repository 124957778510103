import React, { Component } from "react";
import { connect } from "react-redux";

import { Accordion, Card } from "react-bootstrap";
import NumberFormat from "react-number-format";

// import noRepayment from "../../assets/images/dashboard/norepayment.png"
// import clearanceFailed from "../../assets/images/dashboard/notenough.png";
import paymentLate from "../../assets/images/dashboard/late.png";
import paymentMade from "../../assets/images/dashboard/paymentmade.png";
import paymentToday from "../../assets/images/dashboard/today.png";

class SidepanelDetail extends Component {

  render() {
    let { schedule: { detail }, init: { language }, onClick } = this.props;

    return (
      <>
        {/* details */}
        {detail.payment_details.length !== 0 && (
          <div className="side-modal-wrapper">
            <div className="top">
              <h3 className="side-modal-title">Earning #{detail.code}</h3>
              <div className="detail-header">
                <div className="date-status">
                  <span className="date link mr-3"><i className="fa fa-calendar"></i> May 2019</span>
                  <span className="status">
                    <span className={`badge ${detail.status === 'paid' ?'badge-success':'badge-primary'}`}>
                      {detail.status}
                    </span>
                  </span>
                </div>
                <div className="text-wrapper">
                  <div className="remaining">
                    <p className="title">{language.label.remainingDue}</p>
                    <p className="text">
                      <NumberFormat
                        value={0}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </p>
                  </div>
                  <div className="total">
                    <p className="title">Total Received</p>
                    <p className="text">
                      <NumberFormat
                        value={ 
                          detail.payment_details[0].principal_paid +
                          detail.payment_details[0].interest_paid +
                          detail.payment_details[0].late_paid +
                          detail.payment_details[0].commision1 +
                          detail.payment_details[0].commision2
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={3}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <h5 className="side-modal-subtitle">Details</h5>
              <div className="detail-table">
                <div className="title">#{detail.payment_details[0].code}</div>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <div className="row">
                        <div className="col-5">
                          <div className="header">
                            <span className="date">
                              <i className="fa fa-calendar"></i> &nbsp;
                              {new Date(detail.payment_details[0].payment_date).toLocaleDateString("ID-id",
                                { year: 'numeric', month: 'short', day: 'numeric' }
                              )}
                            </span>
                          </div>

                        </div>
                        <div className="col-5">
                          <div className="header">
                            <span className="date"><i className="fa fa-money"></i>
                              &nbsp;
                              <NumberFormat
                                value={ 
                                  detail.payment_details[0].principal_paid +
                                  detail.payment_details[0].interest_paid +
                                  detail.payment_details[0].late_paid +
                                  detail.payment_details[0].commision1 +
                                  detail.payment_details[0].commision2
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalSeparator="."
                                decimalScale={3}
                              />
                            </span>
                          </div>

                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="row">
                          <div className="col-5">
                            <div className="item">
                              <div className="item-title">{language.label.interest}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].principal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                            <div className="item">
                              <div className="item-title">{language.label.interest}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].interest}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                            <div className="item">
                              <div className="item-title">{language.label.lateFee}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].late}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                            <div className="item">
                              <div className="item-title">{language.label.commision}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].commision1}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="item">
                              <div className="item-title">{language.label.received}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].principal_paid}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                            <div className="item">
                              <div className="item-title">{language.label.received}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].interest_paid}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                            <div className="item">
                              <div className="item-title">{language.label.received}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].late_paid}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                            <div className="item">
                              <div className="item-title">{language.label.commision}</div>
                              <div className="item-text">
                                <NumberFormat
                                  value={detail.payment_details[0].commision2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalSeparator="."
                                  decimalScale={3}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                </Accordion>
              </div>
            </div>
            <div className="bottom text-center">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClick}>{language.button.close}</div>
            </div>
          </div>
        )}

        {/* partials */}
        {/* <div className="side-modal-wrapper">
          <div className="top">
            <h3 className="side-modal-title">Earning #BL-AC01-33536</h3>
            <div className="detail-header">
              <div className="date-status">
                <span className="date link mr-3"><i className="fa fa-calendar"></i> May 2019</span>
                <span className="status">
                  <span className="badge badge-note">PARTIALLY PAID</span>
                </span>
              </div>
              <div className="text-wrapper">
                <div className="remaining">
                  <p className="title">Remaining Due</p>
                  <p className="text danger">10,000,000</p>
                </div>
                <div className="total">
                  <p className="title">Total Received</p>
                  <p className="text">2,772,000</p>
                </div>
              </div>
            </div>
            <h5 className="side-modal-subtitle">Details</h5>
            <div className="detail-table">
              <div className="title">#BL88738</div>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <div className="row">
                      <div className="col-5">
                        <div className="header">
                          <span className="date"><i className="fa fa-calendar"></i> 25 May 2019</span>
                        </div>

                      </div>
                      <div className="col-5">
                        <div className="header">
                          <span className="date"><i className="fa fa-money"></i> 2.772.000</span>
                        </div>

                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="row">
                        <div className="col-5">
                          <div className="item">
                            <div className="item-title">Principal</div>
                            <div className="item-text">2.500.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Interest</div>
                            <div className="item-text">300.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">late fee</div>
                            <div className="item-text">0</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Commission</div>
                            <div className="item-text">(28.000)</div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="item">
                            <div className="item-title">Received</div>
                            <div className="item-text">2.500.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Received</div>
                            <div className="item-text">300.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Received</div>
                            <div className="item-text">0</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Commission</div>
                            <div className="item-text">(28.000)</div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    <div className="row">
                      <div className="col-5">
                        <div className="header">
                          <span className="date"><i className="fa fa-calendar"></i> 25 May 2019</span>
                        </div>

                      </div>
                      <div className="col-5">
                        <div className="header">
                          <span className="date"><i className="fa fa-money"></i> 2.772.000</span>
                        </div>

                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <div className="row">
                        <div className="col-5">
                          <div className="item">
                            <div className="item-title">Principal</div>
                            <div className="item-text">2.500.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Interest</div>
                            <div className="item-text">300.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">late fee</div>
                            <div className="item-text">0</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Commission</div>
                            <div className="item-text">(28.000)</div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="item">
                            <div className="item-title">Received</div>
                            <div className="item-text">2.500.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Received</div>
                            <div className="item-text">300.000</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Received</div>
                            <div className="item-text">0</div>
                          </div>
                          <div className="item">
                            <div className="item-title">Commission</div>
                            <div className="item-text">(28.000)</div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
          <div className="bottom text-right">
            <div className="line my-3"></div>
            <div className="btn-modal-cancel">close</div>
            <div className="btn-modal-proceed">pay now</div>
          </div>
        </div> */}

        {/* due today */}
        {/* <div className="side-modal-wrapper">
          <div className="top">
            <h3 className="side-modal-title">Earning #BL-AC01-33536</h3>
            <div className="detail-header">
              <div className="date-status">
                <span className="date link mr-3"><i className="fa fa-calendar"></i> 3 of 12</span>
                <span className="status">
                  <span className="badge badge-primary">due today</span>
                </span>
              </div>
              <div className="text-wrapper">
                <div className="remaining">
                  <p className="title">Remaining Due</p>
                  <p className="text danger">0</p>
                </div>
                <div className="total">
                  <p className="title">Total Received</p>
                  <p className="text">2,772,000</p>
                </div>
              </div>
            </div>
            <div className="modal-img-wrapper">
              <figure className="modal-img mb-4">
                <img src={paymentToday} alt="success" />
              </figure>
              <p className="side-modal-text w-75">You have payment due today. Please don’t make the lenders wait, pay off your loans before it’s late.</p>
            </div>
          </div>
          <div className="bottom text-right">
            <div className="line my-3"></div>
            <div className="btn-modal-cancel">close</div>
            <div className="btn-modal-proceed">pay now</div>
          </div>
        </div> */}

        {/* payment made */}
        {/* <div className="side-modal-wrapper">
          <div className="top">
            <h3 className="side-modal-title">Earning #BL-AC01-33536</h3>
            <div className="detail-header">
              <div className="date-status">
                <span className="date link mr-3"><i className="fa fa-calendar"></i> 3 of 12</span>
                <span className="status">
                  <span className="badge badge-secondary">upcoming</span>
                </span>
              </div>
              <div className="text-wrapper">
                <div className="remaining">
                  <p className="title">Remaining Due</p>
                  <p className="text">50,000,000</p>
                </div>
                <div className="total">
                  <p className="title">Total Received</p>
                  <p className="text">2,772,000</p>
                </div>
              </div>
            </div>
            <div className="modal-img-wrapper">
              <figure className="modal-img mb-4">
                <img src={paymentMade} alt="success" />
              </figure>
              <p className="side-modal-text w-75">Your last payment had been made. Please reserve your next payment for the upcoming schedule.</p>
            </div>
          </div>
          <div className="bottom text-right">
            <div className="line my-3"></div>
            <div className="btn-modal-cancel">close</div>
            <div className="btn-modal-proceed">pay now</div>
          </div>
        </div> */}

        {/* late */}
        {detail.payment_details.length === 0 && (
          <div className="side-modal-wrapper">
            <div className="top">
              <h3 className="side-modal-title">{language.title.repayment} #{detail.code}</h3>
              <div className="detail-header">
                <div className="date-status">
                  <span className="date link mr-3">
                    <i className="fa fa-calendar"></i> {detail.total} of {detail.term}
                  </span>
                  <span className="status">
                    {detail.status === 'late' && (<span className="badge badge-warning">late</span>)}
                    {detail.status === 'upcoming' && (<span className="badge badge-secondary">upcoming</span>)}
                    {detail.status === 'due_today' && (<span className="badge badge-secondary">due today</span>)}
                  </span>
                </div>
                <div className="text-wrapper">
                  <div className="remaining">
                    <p className="title">{language.label.remainingDue}</p>
                    <p className="text danger">
                      <NumberFormat
                        value={detail.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </p>
                  </div>
                  <div className="total">
                    <p className="title">{language.label.dueDate}</p>
                    <p className="text">
                      {new Date(detail.due_date).toLocaleDateString("ID-id",
                        { year: 'numeric', month: 'short', day: 'numeric' }
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-img-wrapper">
                {detail.status === 'late' && (
                  <div>
                    <figure className="modal-img mb-4">
                      <img src={paymentLate} alt="success" />
                    </figure>
                    <p className="side-modal-text w-75">{language.text.repaymentScheduleLate}</p>
                  </div>
                )}
                {detail.status === 'upcoming' && (
                  <div>
                    <figure className="modal-img mb-4">
                      <img src={paymentMade} alt="success" />
                    </figure>
                    <p className="side-modal-text w-75">{language.text.repaymentScheduleUpcoming}</p>
                  </div>
                )}
                {detail.status === 'due_today' && (
                  <div>
                    <figure className="modal-img mb-4">
                      <img src={paymentToday} alt="success" />
                    </figure>
                    <p className="side-modal-text w-75">{language.text.repaymentScheduleToday}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="bottom text-right">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClick}>{language.button.close}</div>
              <div className="btn-modal-proceed">{language.button.payNow}</div>
            </div>
          </div>
        )}


      </>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  schedule: state.schedule
});

export default connect(mapStateToProps, null)(SidepanelDetail);
