import styled from "styled-components";

export const LimitOfferStyle = styled.div`
  .kw-limitOffer {
    h4 {
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.41px;
      text-transform: uppercase;
      color: #2b486d;
    }
    h5 {
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
    table {
      width: 100%;
    }

    td {
      text-align: left;
      padding: 8px;
    }
  }
`;
