const required = value => value ? undefined : 'Required'
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)

const minLength = min => value =>
  value && value.length < min ? `Must be at least ${min} characters` : undefined
const minLengthPassword = minLength(8)

const minLengthPin = minLength(6)

const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined
const aol = value =>
  value && /.+@aol\.com/.test(value) ?
  'Really? You still use AOL for your email?' : undefined
const count = (value = '') => {
  const minimumLength = 100 - value.length;  
  if (minimumLength > 0) return minimumLength
}
const minLengthPhoneNumber = value => value && value.length < 6 ? `Must be at least 6 character` : undefined

const removeCommas = (value) => {
  let numb
  
  if (value)
    numb = value.toString().replace(/,/g,'')

  return Number(numb)
}

//eslint-disable-next-line
const emailCheck = [/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/];


const requiredAmount = value => value ? undefined : { value: 0, label: ` ` }

const floatAmount = value => 
  removeCommas(value) >= 100000 && Number.isInteger(removeCommas(value) / 100000) ? undefined : { value: 1, label: `Must Be A Multiple of 100,000` } 
 
const maxAmount = max => value => 
  removeCommas(value) > max ? { value: 2, label: `Amount Insufficient` } : undefined

const zeroAmount = value => value && Number(value) === 0 ? { value: 3, label: `Amount Cannot Be Zero` } : undefined

const validatePhoneNumber = value =>  {
  let status
  var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  status = undefined

  if (!regex.test(value)) {
    status = `invalid phone number`
  }

  return status
}

//eslint-disable-next-line
const specialCharacters = /[!@#$%^&*`~()_+\-=\[\]{};':"\\|,.<>\/?]/;

const isNumberOrEmail = value => 
  value && isNaN(Number(value)) ? 
    email(value)
  : 
    minLengthPhoneNumber(value)

export {
  required,
  emailCheck,
  specialCharacters,
  requiredAmount,
  maxLength,
  minLength,
  minLengthPassword,
  minLengthPin,
  maxLength15,
  number,
  minValue,
  minValue18,
  email,
  tooOld,
  aol,
  count,
  minLengthPhoneNumber,
  validatePhoneNumber,
  isNumberOrEmail,
  floatAmount,
  maxAmount,
  zeroAmount
}