import React from "react";
import ShareHolderInformation from "./ShareHolderInformation";
import { useState } from "react";
import { useEffect } from "react";
import koinvoiceServices from "../../../../services/koinvoice";
import {
  buttonBackBusinessInformation,
  actionButtonBackShareHolderPhaseOne,
  actionShareHolderAddNewFormRedirect,
  businessInformationKoinvoice,
  actionGetPersonalShareHolderInfoLocalReset
} from "../../../../stores/actions/koinvoiceLoan";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ShareHolderListCard from "./ShareHolderListCard";
import { Button, Spin } from "antd";
import ShareHolderNotFound from "./ShareHolderNotFound";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";
import ClevertapReact from "../../../../util/clevertap";

function ShareHolder({
  businessInformation,
  buttonBackBusinessInformation,
  actionShareHolderAddNewFormRedirect,
  actionGetPersonalShareHolderInfoLocalReset,
  businessInformationKoinvoice,
  guarantorShareHolder,
  koinvoice: { nextUpdateOwnerDirectorGuarantor, businessInformationSucess },
  marital_status,
  business_type,
  prevPage,
  nextPage,
  init: { language },
  token,
  userId
}) {
  const [information, setInformation] = useState([]);
  const bizInfo = 
    JSON.parse(localStorage.getItem("businessInformation")) !== null &&
    JSON.parse(localStorage.getItem("businessInformation"));
  const [infromationEdit, setInformationEdit] = useState({});
  const [actionCreateUpdate, setActionCreateUpdate] = useState("");
  const [isShowEditFormInformation, setIsShowEditFormInformation] = useState(
    false
  );
  const [isShowNotFoundList, setIsShowNotFoundList] = useState(true);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowDrawerDeleteSucsess, setIsShowDrawerDeleteSucsess] = useState(
    false
  );
  const { limit_application_id } = JSON.parse(localStorage.getItem(`limit-id-${userId}`)) || '';
    // cookie.load("limit-application") !== undefined &&
    // cookie.load("limit-application").data;
  const businessType =
    business_type === "individual" ||
    business_type === "home_business" || business_type === "industri_rumah_tangga";

  useEffect(() => {
    businessInformationKoinvoice(token);
    actionGetAppGuarantorList();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (nextUpdateOwnerDirectorGuarantor || information.length < 1) {
      information.length < 1 && setIsShowEditFormInformation(true);
      information.length < 1 && setActionCreateUpdate("add-new");
      setTimeout(() => {
        actionShareHolderAddNewFormRedirect();
      }, 500);
    } // eslint-disable-next-line
  }, [nextUpdateOwnerDirectorGuarantor]);

  useEffect(() => {
    if (information.length > 1) {
      setIsShowEditFormInformation(false)
    }
  }, [information])

  function actionChangeShowDrawerDelete() {
    setIsShowDrawerDeleteSucsess(!isShowDrawerDeleteSucsess);
  }

  function actionBack() {
    buttonBackBusinessInformation();
    prevPage();
  }

  async function actionGetAppGuarantorList() {
    let maritalStatus = bizInfo.data?.details?.personal?.user_information?.marital_status?.value || "";
    let params = {
      // user_id: businessInformation.data && businessInformation.data.user_id,
      product: "koin_invoice",
      sibling_type: businessType
        ? ["KAWIN", "MARRIED"].includes(maritalStatus)
          ? "guarantor,spouse"
          : "guarantor"
        : "shareholder",
    };
    let getAppGuarantor = await koinvoiceServices.actionGetAssesmentsAppGuarantor(
      { params: params, loading: setIsShowLoading, token: token }
    );
    if (getAppGuarantor?.status === 200 && getAppGuarantor?.data?.length >= 1) {
      const tempReversedAuthDocument = [];
      for (let i = getAppGuarantor?.data?.length - 1; i >= 0; i--) {
        tempReversedAuthDocument.push(getAppGuarantor.data[i]);
      }
      setInformation(tempReversedAuthDocument);
      setIsShowEditFormInformation(false);
      setIsShowNotFoundList(false);
      
      let shareholder = JSON.parse(
        localStorage.getItem('paramsSubmitShareHolderInformation')
      );
      if(!shareholder) {
        localStorage.setItem(
          "paramsSubmitShareHolderInformation",
          JSON.stringify(getAppGuarantor.data[0])
        );
      }
    } else {
      setInformation([]);
      setIsShowNotFoundList(true);
    }
  }

  async function actionChangeDeleteList(paramsId) {
    let deleteList = await koinvoiceServices.putDeleteShareHolderLoanKoinVoice({
      paramId: paramsId,
      token: token
    });
    if (deleteList.status === 200) {
      setIsShowEditFormInformation(!isShowEditFormInformation);
      actionGetAppGuarantorList();
      actionChangeShowDrawerDelete();
    }
  }

  function actionChangeListCardData(e, action) {
    setActionCreateUpdate(action);
    setIsShowEditFormInformation(!isShowEditFormInformation);
    setInformationEdit(e);
  }

  function actinChangeShowHideAddNew(e) {
    if (e === "add-new") {
      cookie.remove("paramsSubmitShareHolderInformation", { path: "/" });
      actionGetPersonalShareHolderInfoLocalReset();
      setInformationEdit({});
    }
    setActionCreateUpdate(e);
    (e === "update-data" || e === "create-data") && actionGetAppGuarantorList();
    setIsShowEditFormInformation(!isShowEditFormInformation);
  }

  function actionConfirmRequest() {
    let add_data = JSON.parse(localStorage.getItem(`add-data-${userId}`));
    let shareholder = JSON.parse(
      localStorage.getItem('paramsSubmitShareHolderInformation')
    );
    let director = JSON.parse(
      localStorage.getItem('paramsSubmitDirectorOwner')
    );
    koinvoiceServices.actionPostLimitConfirm({
      params: {
        id: parseInt(limit_application_id),
        body: {
          request_limit: add_data.limit_amount,
          term: add_data.loan_term,
          loan_purpose: add_data.loan_purpose,
          request_type: add_data.type
        }
      },
      nextPage: nextPage,
      loading: setIsShowLoading,
      token: token
    }).then(res =>{
      if (res.status === 200) {
        let paramClevertap = {
          "Product Category": "Koin Invoice",
          "Sub Product Category": add_data.sub_product,
          "limit ID": limit_application_id,
          "Code": add_data.code,
          "Referral Name": add_data.referral,
          "Borrower Name": add_data.borrower_name,
          "Limit Amount Request": add_data.limit_amount,
          "Limit Term": add_data.loan_term,
          "Purpose": add_data.loan_purpose,
          "Principal Name": add_data.principal_name,
          "PIC Job Position": add_data.pic_position,
          "Contact Person": add_data.contact_person,
          "Phone Number": bizInfo.data?.phone_number,
          "Email Address": bizInfo.data?.email,
          "Store Name": bizInfo.data?.details?.business?.company_detail?.company_name?.value,
          "Business Category" : bizInfo.data?.details?.business?.company_detail?.business_category?.value,
          "Established date": bizInfo.data?.details?.business?.company_detail?.established_in?.value,
          "Average Sales per Month": bizInfo.data?.details?.business?.business_performace?.average_sales?.value,
          "NPWP Number": bizInfo.data?.details?.business?.company_detail?.npwp_number?.value,
          "Business Address" : bizInfo.data?.details?.business?.company_detail?.address?.value,
          "Business Sub District": bizInfo.data?.details?.business?.company_detail?.sub_district?.value,
          "Business District" : bizInfo.data?.details?.business?.company_detail?.district?.value,
          "Business Province" : bizInfo.data?.details?.business?.company_detail?.province?.value,
          "Business Postal Code": bizInfo.data?.details?.business?.company_detail?.postal_code?.value,
          "Personal KTP" : bizInfo.data?.details?.documents?.business_owner_document?.ktp?.value,
          "Personal Selfie + KTP" : bizInfo.data?.details?.documents?.business_owner_document?.selfie_ktp?.value,
          "Personal NIK" : bizInfo.data?.details?.personal?.user_information?.nik?.value,
          "Personal Name": bizInfo.data?.details?.personal?.user_information?.fullname?.value,
          "Personal Place of Birth": bizInfo.data?.details?.personal?.user_information?.date_of_birth?.value,
          "Personal DOB": bizInfo.data?.details?.personal?.user_information?.date_of_birth?.value,
          "Personal Gender" : bizInfo.data?.details?.personal?.user_information?.gender?.value,
          "Personal Marital Status" : bizInfo.data?.details?.personal?.user_information?.marital_status?.value,
          "Personal Address" : bizInfo.data?.details?.personal?.user_location?.address?.value,
          "Personal Sub District": bizInfo.data?.details?.personal?.user_location?.sub_district?.value,
          "Personal District": bizInfo.data?.details?.personal?.user_location?.district?.value,
          "Personal Province": bizInfo.data?.details?.personal?.user_location?.province?.value,
          "Personal Postal Code": bizInfo.data?.details?.personal?.user_information?.postal_code?.value,
          "Personal Mobile Number": bizInfo.data?.phone_number,
          "Personal Email": bizInfo.data?.email,
          "Director NIK": director.nik,
          "Director Name": director.owner_name,
          "Director Place of Birth": director.place_of_birth,
          "Director DOB": director.owner_birthdate,
          "Director Gender": director.owner_gender,
          "Director Marital Status": director.owner_marital_status,
          "Director Address" : director.owner_address,
          "Director Sub District": director.owner_sub_district,
          "Director District": director.owner_district,
          "Director Province": director.owner_province,
          "Director Postal Code": director.postal_code,
          "Director Position": director.jabatan,
          "Director Holding Percentage": director.saham + "%",
          "Director Relationship to borrower": director.sibling_relation,
          "Director Mobile Number": director.owner_phone,
          "Director Email": director.owner_email,
          "Shareholder NIK": shareholder.nik || shareholder.npwp,
          "Shareholder Name": shareholder.full_name,
          "Shareholder Place of Birth": "",
          "Shareholder DOB": shareholder.dob,
          "Shareholder Gender": shareholder.gender,
          "Shareholder Marital Status": shareholder.marital_status,
          "Shareholder Address": shareholder.address,
          "Shareholder Sub District": shareholder.sub_district,
          "Shareholder District": shareholder.districtt,
          "Shareholder Province": shareholder.province,
          "Shareholder Postal Code": shareholder.postal_code,
          "Shareholder Position": shareholder.position,
          "Shareholder Holding Percentage": shareholder,
          "Shareholder Relationship to Borrower": shareholder.properties.saham + '%',
          "Shareholder Mobile Number": shareholder.mobile_number,
          "Shareholder Email": shareholder.email
        };
        ClevertapReact.onUserLogin({
          Site: {
            Name: add_data.borrower_name,
            Email: bizInfo.data?.email,
            "origin": "Web KoinInvoice LMS"
          }
        });
        ClevertapReact.event("Request Limit", paramClevertap);
      }
    });
  }

  return (
    <Spin
      spinning={businessInformation === undefined ? true : isShowLoading}
    >
      {isShowEditFormInformation === false ? (
        <React.Fragment>
          <Button
            size="large"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
              marginTop: 15,
            }}
            onClick={() => actionBack()}
          >
            <ArrowLeftOutlined /> {language.button.back}
          </Button>
          <React.Fragment>
            {isShowNotFoundList ? (
              <ShareHolderNotFound />
            ) : (
              information.length > 0 &&
              information.map((list, index) => {
                return (
                  <React.Fragment key={index}>
                    {" "}
                    <ShareHolderListCard
                      language={language}
                      list={list}
                      actionChangeListCardData={actionChangeListCardData}
                    />
                  </React.Fragment>
                );
              })
            )}
          </React.Fragment>
          <Button
            className="kw-button"
            style={{ width: "100%", marginTop: 20 }}
            size="large"
            type="primary"
            onClick={() => actinChangeShowHideAddNew("add-new")}
          >
            {language.button.addNew}
          </Button>
          {information.length > 0 && (
            <Button
              className="kw-button"
              style={{ width: "100%", marginTop: 20 }}
              loading={isShowLoading}
              size="large"
              type="primary"
              onClick={() => actionConfirmRequest()}
            >
              {language.button.confirmRequest}
            </Button>
          )}
        </React.Fragment>
      ) : (
        <ShareHolderInformation
          infromationEdit={infromationEdit}
          guarantorShareHolder={guarantorShareHolder}
          actionChangeDeleteList={actionChangeDeleteList}
          marital_status={marital_status}
          business_type={business_type}
          isShowDrawerDeleteSucsess={isShowDrawerDeleteSucsess}
          actionChangeShowDrawerDelete={actionChangeShowDrawerDelete}
          action={actionCreateUpdate}
          actionGetAppGuarantorList={actionGetAppGuarantorList}
          businessInformation={businessInformation}
          actinChangeShowHideAddNew={actinChangeShowHideAddNew}
          token={token}
          userId={userId}
        />
      )}
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      buttonBackBusinessInformation,
      actionButtonBackShareHolderPhaseOne,
      actionShareHolderAddNewFormRedirect,
      actionGetPersonalShareHolderInfoLocalReset,
      businessInformationKoinvoice
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareHolder);