import React from "react";
import { ShareHolderStyle } from "./ShareHolder.style";
import { CheckCircleTwoTone } from "@ant-design/icons";

export default function ShareHolderNotFound(props) {
  return (
    <ShareHolderStyle>
      <div className="kw-shareHolder-notFound-wrapper">
        <img src={require("../../../../assets/images/uploadins.png").default} alt="" />
        <h4>ShareHolder Information</h4>
        <p>
          We need your company’s shareholder information, including the
          Director. Kindly provide the following:
        </p>
        <p>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ marginRight: 10 }}
          />
          Profile information of each shareholder
        </p>
        <p>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ marginRight: 10 }}
          />
          KTP of each shareholder
        </p>
        <p>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ marginRight: 10 }}
          />
          KTP of each shareholder
        </p>
      </div>
    </ShareHolderStyle>
  );
}
