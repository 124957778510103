import styled from "styled-components";

export const NavigationWrapper = styled.div`
  background: #f2f2f2;
  .navbar {
    padding: 1rem 1.7rem;
  }

  @media screen and (max-width: 800px) {
    .navbar-expand {
      flex-flow: column;
    }
    .justify-content-end {
      justify-content: flex-start !important;
    }
  }
`;
