import React from "react";
import { CashKoinStyle } from "../../BorrowerDashboardKoinInvoice/style";
import { Card, Row, Col } from "antd";
import currencyRupiah from "../../library/currency";

export default function CashKoinWrapper({ limit, loanBalance, language }) {
  return (
    <Card>
      <CashKoinStyle>
        <div className="kw-cashKoinWrapper">
          {!limit.is_parent_ucl && (
            <Row>
              <Col xs={16}>
                <span>{language.loanDashboard.availableCash}</span>
                <strong>{currencyRupiah(loanBalance?.data?.available_cash, "Rp")}</strong>
              </Col>
              <Col xs={8}>
                <span>{language.loanDashboard.availableCoin}</span>
                <strong>{currencyRupiah(loanBalance?.data?.available_koin, " ")}</strong>
              </Col>
            </Row>
          )}
        </div>
      </CashKoinStyle>
    </Card>
  );
}
