import React from "react";

export default function NotAccountBusiness(props) {
  return (
    <div>
      <img
        src={require("../../assets/images/icon-koininvoice.png").default}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          marginBottom: 22,
        }}
        alt=""
      />
      <p style={{ textAlign: "center" }}>
        Halo, akun kamu terdaftar sebagai akun personal. Fitur ini akan muncul
        untuk akun personal dalam waktu dekat, jadi harap menunggu.
      </p>
      <img
        src={require("../../assets/images/img_apps.png").default}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width:"50%",
          display: "block",
          marginBottom: 22,
        }}
        alt=""
      />
    </div>
  );
}
