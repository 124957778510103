import styled from "styled-components";

export const StepConfirmationWrapper = styled.div`
  background: #f2f2f2;

  .step-title {
    text-align: center;
    letter-spacing: 0.41px;
    color: #2b486d;
    margin-bottom: 15px;
  }
  .MuiSelect-iconOutlined {
    display: none;
  }
  .Mui-disabled {
    background: #f2f2f2;
    color: #2d2d2d;
  }
  .adorement-edit {
    p {
      text-decoration: underline;
      color: #378df4;
      cursor: pointer;
    }
  }
  .btn-edit{
    margin: 20px 0;
    text-align: right;
    span {
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      padding: 5px 10px;
      background: #e4e4e4;
      font-size: 14px;
    }
  }
  .box-total {
    background: #f2f2f2;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
    .box-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      color: #8e8e8e;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .cfee {
      .text-right {
        color: #c53741;
      }
    }
    .ctotal {
      color: #566068;
    }
    .error-message {
      margin: 10px 0 20px 0;
      padding-bottom: 15px;
      color: #c53741;
    }
  }
`;
