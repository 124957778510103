import React, { Component } from "react";

import { Dropdown, Form, Accordion, Card } from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";


class SortFilterLoan extends Component {
  render() {
    let { language, handleSortType, handleSortBy, handleFilter, sortInput } = this.props;

    return (
      <div className="sortfilter">
        <Dropdown>
          <Dropdown.Toggle variant="none" id="dropdown-sort">
            <i className="fa fa-sort"></i><span>{language.label.sort}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h5 className="title">{language.label.sortby}</h5>
            <div className="sort">
              <Form>
                <div className="input-wrapper ascdsc-wrapper">
                  <RadioGroup
                    aria-label="position"
                    name="ascdsc"
                    value={sortInput.sortType}
                    onChange={handleSortType}
                    row
                  >
                    <FormControlLabel
                      value="asc"
                      control={<Radio color="primary" />}
                      label="Ascending"
                      labelPlacement="end"
                      className="ascdsc-box"
                    />
                    <FormControlLabel
                      value="desc"
                      control={<Radio color="primary" />}
                      label="Decending"
                      labelPlacement="end"
                      className="ascdsc-box"
                    />
                  </RadioGroup>
                </div>

                <div key="paystat">
                  <RadioGroup
                    aria-label="position"
                    name="sortBy"
                    value={sortInput.sortBy}
                    row
                  >
                    <Form.Check
                      type="radio"
                      name="sort"
                      id="reqdate"
                      label={language.label.requestDate}
                      value="request_date"
                      custom
                      checked={sortInput.sortBy === 'request_date'}
                      onChange={handleSortBy}
                    />

                    <Form.Check
                      type="radio"
                      name="sort"
                      id="paystat"
                      label="Loan Amount"
                      value="loan_amount"
                      custom
                      checked={sortInput.sortBy === 'loan_amount'}
                      onChange={handleSortBy}
                    />

                    <Form.Check
                      type="radio"
                      name="sort"
                      id="lterm"
                      label={language.label.loanTerm}
                      value="loan_term"
                      custom
                      checked={sortInput.sortBy === 'loan_term'}
                      onChange={handleSortBy}
                    />
                  </RadioGroup>
                </div>
              </Form>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="none" id="dropdown-filter">
            <i className="fa fa-sliders"></i><span>{language.label.filter}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h5 className="title">{language.label.filterBy}</h5>
            <Accordion defaultActiveKey="">
              <Card className="filter">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <span>Undisbursed Loan</span> <i className="fa fa-chevron-right"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <div key="">
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="waiting_for_document"
                          label="Waiting for Document"
                          value="waiting_for_document"
                          custom
                          onChange={handleFilter}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="under_review"
                          label="Under Review"
                          value="under_review"
                          custom
                          onChange={handleFilter}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="choose_offer"
                          label="Choose Offer"
                          value="choose_offer"
                          custom
                          onChange={handleFilter}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="waiting_contract"
                          label="Waiting Contract"
                          value="waiting_contract"
                          custom
                          onChange={handleFilter}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="funding_process"
                          label="Funding Process"
                          value="funding_process"
                          custom
                          onChange={handleFilter}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="disbursement_process"
                          label="Disbursement Process"
                          value="disbursement_process"
                          custom
                          onChange={handleFilter}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="on_going"
                          label="On Going"
                          value="on_going"
                          custom
                          onChange={handleFilter}
                        />
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              {/* <Card className="filter">
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  <span>Disbursed Loan</span> <i className="fa fa-chevron-right"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Form>
                      <div key="">
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="On Going"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="Due This Month"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="Paid Off"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="filter">
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  <span>Other</span> <i className="fa fa-chevron-right"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Form>
                      <div key="">
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="Rejected"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="Funding not eligble"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="Canceled"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="Offers Declined"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id=""
                          label="Restructured"
                          value=""
                          custom
                          onChange={this.props.onChange}
                        />
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card> */}
            </Accordion>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}



export default SortFilterLoan;