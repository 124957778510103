import React, { Component } from "react";
import { connect } from "react-redux";
import { FooterTextWrapper } from "./FooterText.styles";

// images
import ojkLogo from "../../../assets/images/ojk-logo.png";
import afpiLogo from "../../../assets/images/afpi.png";

class FooterText extends Component {
  render() {
    let { init: { language }} = this.props;
    return (
      <FooterTextWrapper>
        <div className="footer-text">
          <div className="container">
            <div className="logo-wrapper">
              <div className="single-logo">
                <span className="text">
                  {language.homepage.registerAndSupervisedBy}
                </span>
                <div className="logo-img">
                  <img src={ojkLogo} alt="ojk" />
                </div>
              </div>
              <div className="single-logo">
                <span className="text">{language.homepage.memberOf}</span>
                <div className="logo-img">
                  <img src={afpiLogo} alt="afpi" />
                </div>
              </div>
            </div>
            <div className="attention-wrapper">
              <div className="h5 heading">{language.homepage.attention}</div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <ol className="text-list">
                    <li className="item">
                      {language.homepage.attentionRule1}
                    </li>
                    <li className="item">
                      {language.homepage.attentionRule2}
                    </li>
                    <li className="item">
                      {language.homepage.attentionRule3}
                    </li>
                    <li className="item">
                      {language.homepage.attentionRule4}
                    </li>
                    <li className="item">
                      {language.homepage.attentionRule5}
                    </li>
                    <li className="item">
                      {language.homepage.attentionRule6}
                    </li>
                  </ol>
                </div>
                <div className="col-12 col-sm-6">
                  <ol start="7" className="text-list">
                    <li className="item">
                      {language.homepage.attentionRule7}
                    </li>
                    <li className="item">
                      {language.homepage.attentionRule8}
                    </li>
                    <li className="item">
                      {language.homepage.attentionRule9}
                    </li>
                  </ol>
                </div>
                <div className="col-12">
                  <p className="text">
                    {language.homepage.attentionText}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterTextWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init
});

export default connect(mapStateToProps, null)(FooterText);
