const initState = {
	data: null
};

const balanceReducer = (state = initState, action) => {
	switch (action.type) {
    case "GET_BALANCE_LOADING":
      return { ...state, isLoading: true };
    case "GET_BALANCE_SUCCESS":
      return { 
        ...state,
        isLoading: false,
        data: action.payload 
      }
    case "GET_BALANCE_FAILED":
      return { ...state, isLoading: false, errors: action.payload }
    default:
      return state;
  }
};

export default balanceReducer;
