import React from "react";
import PhoneInput from "react-phone-input-2";

const PhoneNumberInput = ({
    input: { value, onChange },
    placeholder,
    disabled,
    type,
    onClick,
    meta: { error, touched },
    handleChange
  }) => (
    <div>
      <PhoneInput
        prefix={""}
        country={"id"}
        placeholder={placeholder}
        type={type}
        onClick={onClick}
        disabled={disabled}
        autoFormat={false}
        enableSearch={false}
        value={value}
        onChange={(value, data) => {
          onChange(value);
          handleChange(
            data.dialCode,
            value.length && value.substring(0, 1) === "0"
              ? value
              : value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)
          );
        }}
      />
      {touched && error && <span className="error-text">{error}</span>}
    </div>
  );

  export default PhoneNumberInput;