import React from "react";
import { Alert, Button, Col, Drawer, Row, Spin } from "antd";
import MobileLayout from "../../Layout/MobileLayout/MobileLayout";

export default function ConfirmBankAllert({
  language,
  onClose,
  visible,
  messageError,
  dataBank,
  thidrPartyOy,
  isShowLoading,
  handleChangeSubmitPostBankValidation,
  nameBank
}) {
  return (
    <Drawer
      title=""
      placement={"bottom"}
      height={444}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <h3 style={{ textAlign: "center", color: "#2B486D", marginTop: 22 }}>
        {language.text.bankDataConfirm}
      </h3>
      <MobileLayout>
        <div style={{ background: "#F2F2F2", padding: 20 }}>
          <Row style={{ marginLeft: 0 }}>
            <Col xs={12}>
              <span style={{ display: "block" }}>{language.label.ownerName}</span>
            </Col>
            <Col xs={12}>
              {" "}
              <span style={{ display: "block", textAlign: "right" }}>
                {thidrPartyOy?.oy?.recipient_name}
              </span>
            </Col>
            <Col xs={12} style={{ marginTop: 10 }}>
              <span style={{ display: "block" }}>{language.label.accountNumber}</span>
            </Col>
            <Col xs={12} style={{ marginTop: 10 }}>
              <span style={{ display: "block", textAlign: "right" }}>
                {thidrPartyOy?.oy?.recipient_account}
              </span>
            </Col>
            <Col xs={12} style={{ marginTop: 10 }}>
              <span style={{ display: "block" }}>{language.label.bankName}</span>
            </Col>
            <Col xs={12} style={{ marginTop: 10 }}>
              <span style={{ display: "block", textAlign: "right" }}>
                {nameBank}
              </span>
            </Col>
            <Col xs={12}>
              <span style={{ display: "block" }}> </span>
            </Col>
            <Col xs={12}>
              <span style={{ display: "block", textAlign: "right" }}>
                {thidrPartyOy?.oy?.recipient_bank}
              </span>
            </Col>
            {/* {additional_document && (
              <>
                <Col xs={12}>
                  <span style={{ display: "block" }}>ADDITIONAL DOCUMENT </span>
                </Col>
                <Col xs={12}>
                  <span style={{ display: "block", textAlign: "right" }}>
                    {" "}
                    <img
                      src={additional_document}
                      alt=""
                      style={{ height: 48, width: 72 }}
                    />
                  </span>
                </Col>
                <Col xs={12}>
                  <span style={{ display: "block" }}> </span>
                </Col>
                <Col xs={12}>
                  <span
                    onClick={handleChangeShowPreviewImage}
                    style={{
                      display: "block",
                      textAlign: "right",
                      color: " #378DF4",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Preview
                  </span>
                </Col>
              </>
            )} */}
          </Row>
        </div>
        {messageError && (
          <Alert
            message={messageError}
            type="error"
            style={{ marginTop: 5, marginBottom: 5 }}
            showIcon
          />
        )}
        <Spin spinning={isShowLoading}>
          <Button
            htmlType="submit"
            style={{
              backgroundColor: "#2b476c",
              borderColor: "#2b476c",
              color: "#ffffff",
              width: "100%",
              marginTop: 25,
            }}
            onClick={handleChangeSubmitPostBankValidation}
            size="large"
            type="primary"
          >
            {language.text.confirmation}
          </Button>
        </Spin>
        <p
          onClick={() => onClose()}
          style={{
            color: "#566068",
            textAlign: "center",
            fontWeight: 555,
            cursor: "pointer",
            marginTop: 20,
            textDecorationLine: "underline",
          }}
        >
          {language.button.close}
        </p>
      </MobileLayout>
    </Drawer>
  );
}
