import styled from "styled-components";

export const RefCompanyWrapper = styled.div`
  background: #f2f2f2;

  a {
    text-decoration: underline;
  }

  .page-content-wrapper {
    /* width: 50%; */
    margin: 0 auto;
    padding-top: 30px;
    font-family: Source Sans Pro;
  }
  h2 {
    text-align: center;
    color: #2b486d;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
  }
  .refcompany-img {
    margin: 2em;
    text-align: center;
  }
  p {
    margin: 2em;
    font-size: 17px;
    color: #566068;
  }
  .rc-form {
    margin: 2em 0;
  }
  .separator {
    margin: 0 24px;
    padding: 16px 0;
  }
  .sub-title {
    margin: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: #8e8e8e;
  }
  .input-wrapper {
    margin: 20px;
  }
  .react-tel-input {
    .form-control {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 56px;
      box-shadow: none;
      & + div {
        &:before {
          display: none;
        }
      }
    }
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin: 0.25rem 0 0.4rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dc3545;
    }
    label {
      color: #dc3545;
    }
  }
  .back-button {
    color: #c1c1c1;
    padding: 15px;
    font-size: 18px;
    i {
      margin-right: 7px;
    }
  }
  .error-message {
    margin: 10px;
    text-align: center;
    color: #c53741;
  }
`;
