import React, { Component } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

import notenoughPay from "../../assets/images/dashboard/notenoughpay.png";
import successPay from "../../assets/images/dashboard/successpay.png";
import confirmPay from "../../assets/images/dashboard/confirmpay.png";

class SidepanelPay extends Component {


  render() {
    let { schedule, init: { language }, onClose, onClickPay } = this.props;
    let detail = schedule.detail;

    return (
      <>

        {/* confirm */}
        {!schedule.isNotEnoughCash && !schedule.isPaymentSuccess && (
          <div className="side-modal-wrapper">
            <div className="top">
              <h3 className="side-modal-title">{language.title.confirmPayment}</h3>
              <div className="detail-header">
                <p className="loan-name accept">Loan ID #{detail.data[0].code}</p>
              </div>
              <div className="modal-img-wrapper">
                <figure className="modal-img mb-4">
                  <img src={confirmPay} alt="success" />
                </figure>
                <p className="side-modal-text w-75">
                  {language.subtitle.confirmPayment} 
                  <strong>Rp&nbsp;
                    <NumberFormat
                      value={detail.total_due}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator="."
                      decimalScale={2}
                    />
                  </strong> &nbsp;
                  {language.text.fromAvailableCash}
                </p>
              </div>
            </div>
            <div className="bottom text-right">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClose}>{language.button.close}</div>
              <div className="btn-modal-proceed" onClick={onClickPay}>{language.button.pay}</div>
            </div>
          </div>
        )}

        {/* paid */}
        {schedule.isPaymentSuccess && (
          <div className="side-modal-wrapper">
            <div className="top">
              <h3 className="side-modal-title">Installment Paid</h3>
              <div className="detail-header">
                <p className="loan-name accept">Loan ID #BL-ES1-6578</p>
              </div>
              <div className="modal-img-wrapper">
                <figure className="modal-img mb-4">
                  <img src={successPay} alt="success" />
                </figure>
                <p className="side-modal-text w-75">Thank you! 50 lenders are happy because of your repayment.</p>
              </div>
            </div>
            <div className="bottom text-center">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClose}>done</div>
            </div>
          </div>
        )}

        {/* not enough */}
        {schedule.isNotEnoughCash && (
          <div className="side-modal-wrapper">
            <div className="top">
              <h3 className="side-modal-title">{language.title.notEnoughCash}</h3>
              <div className="detail-header">
              </div>
              <div className="modal-img-wrapper">
                <figure className="modal-img mb-4">
                  <img src={notenoughPay} alt="success" />
                </figure>
                <p className="side-modal-text w-75">{language.subtitle.notEnoughCash} &nbsp;
                <strong>
                    <NumberFormat
                      value={schedule.amountTransfer}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator="."
                      decimalScale={2}
                    />
                  </strong> {language.text.toContinue}</p>
              </div>
            </div>
            <div className="bottom text-right">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClose}>{language.button.close}</div>
              <div className="btn-modal-proceed">{language.button.toTransferInstruction}</div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  schedule: state.schedule
});

export default connect(mapStateToProps, null)(SidepanelPay);