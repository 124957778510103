import React, { Component } from "react";
import { connect } from "react-redux";
import { BannerSecondaryWrapper } from "./BannerSecondary.styles";

// images
import secondaryBanner from "../../../assets/images/exp-bg.png";
import secondaryBannerKoinInvoice from "../../../assets/images/koininvoice-syarat-pengajuan.png";
import gplay from "../../../assets/images/gplay.png";
import appstore from "../../../assets/images/appstore.png";
// icon
import iconInstant from "../../../assets/images/icon/instant.svg";
import iconRate from "../../../assets/images/icon/rate.svg";
import iconProcess from "../../../assets/images/icon/process.svg";
import iconRefer from "../../../assets/images/icon/refer.svg";
import iconReferKoinInvoice from "../../../assets/images/icon/Group.png";
//import iconAllkind from "../../../assets/images/icon/allkind.svg";
import iconEmployee from "../../../assets/images/icon/employee.svg";
import iconEmployeeKoinInvoice from "../../../assets/images/icon/Group2500.png";
import iconCashflow from "../../../assets/images/icon/Group2502.png";
import { hostNameCheckCondition } from "../../../library/checkHostNameWeb";

class BannerSecondary extends Component {
  render() {
    let {
      init: { language },
    } = this.props;
    return (
      <BannerSecondaryWrapper>
        <section className="container secondary-banner" id="how-it-play">
          <div className="row">
            <div className="col-12 col-sm-6">
              <figure className="banner-wrapper">
                <img
                  src={
                    hostNameCheckCondition
                      ? secondaryBannerKoinInvoice
                      : secondaryBanner
                  }
                  alt=""
                />
              </figure>
              <div className="store">
                {/* {hostNameCheckCondition && (
                  <React.Fragment>
                    {" "}
                    <h5 className="heading">
                      {language.homepage.makeTheMostTitleInvoice}
                    </h5>
                    <p className="text">
                      {language.homepage.makeTheMostTextInvoice}
                    </p>
                  </React.Fragment>
                )} */}
                <h5 className="heading">{language.homepage.getStarted}</h5>
                <p className="text">
                  {hostNameCheckCondition
                    ? language.homepage.downloadAndStartApplyingKoinInvoice
                    : language.homepage.downloadAndStartApplying}
                </p>
                <div className="store-link">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.koinworks.app&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <figure className="store-wrapper">
                      <img src={gplay} alt="google play" />
                    </figure>
                  </a>
                  <a
                    href="https://apps.apple.com/id/app/koinworks/id1457026579"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <figure className="store-wrapper">
                      <img src={appstore} alt="app store" />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="content">
                <h3 className="heading" style={{ fontSiz: 30 }}>
                  {language.homepage.favorableLoanForBeneficialTitle}
                </h3>
                <div className="content-list-wrapper">
                  <div className="content-list">
                    <div className="icon-wrapper">
                      <figure className="icon">
                        <img
                          src={
                            hostNameCheckCondition
                              ? iconEmployeeKoinInvoice
                              : iconEmployee
                          }
                          className="card-img"
                          alt="secure"
                        />
                      </figure>
                    </div>
                    <div className="text-wrapper">
                      <h5 className="heading">
                        {hostNameCheckCondition
                          ? language.homepage.beneficialBullet2TitleInvoice
                          : language.homepage.beneficialBullet2Title}
                      </h5>
                      <p className="text">
                        {hostNameCheckCondition
                          ? language.homepage.beneficialBullet2TextInvoice
                          : language.homepage.beneficialBullet2Text}
                      </p>
                    </div>
                  </div>
                  {hostNameCheckCondition ? (
                    ""
                  ) : (
                    <div className="content-list">
                      <div className="icon-wrapper">
                        <figure className="icon">
                          <img
                            src={iconInstant}
                            className="card-img"
                            alt="intant"
                          />
                        </figure>
                      </div>
                      <div className="text-wrapper">
                        <h5 className="heading">
                          {language.homepage.beneficialBullet1Title}
                        </h5>
                        <p className="text">
                          {language.homepage.beneficialBullet1Text}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="content-list">
                    <div className="icon-wrapper">
                      <figure className="icon">
                        <img
                          src={hostNameCheckCondition ? iconCashflow : iconRate}
                          className="card-img"
                          alt="intant"
                        />
                      </figure>
                    </div>
                    <div className="text-wrapper">
                      <h5 className="heading">
                        {hostNameCheckCondition
                          ? language.homepage.beneficialBullet3TitleInvoice
                          : language.homepage.beneficialBullet3Title}
                      </h5>
                      <p className="text">
                        {hostNameCheckCondition
                          ? language.homepage.beneficialBullet3TextInvoice
                          : language.homepage.beneficialBullet3Text}
                      </p>
                    </div>
                  </div>
                  {/* {hostNameCheckCondition && (
                    <div className="content-list">
                      <div className="icon-wrapper">
                        <figure className="icon">
                          <img
                            src={iconAllkind}
                            className="card-img"
                            alt="intant"
                          />
                        </figure>
                      </div>
                      <div className="text-wrapper">
                        <h5 className="heading">
                          {language.homepage.beneficialBullet6TitleInvoice}
                        </h5>
                        <p className="text">
                          {language.homepage.beneficialBullet6TextInvoice}
                        </p>
                      </div>
                    </div>
                  )}
                  {hostNameCheckCondition && (
                    <div className="content-list">
                      <div className="icon-wrapper">
                        <figure className="icon">
                          <img
                            src={iconInstant}
                            className="card-img"
                            alt="intant"
                          />
                        </figure>
                      </div>
                      <div className="text-wrapper">
                        <h5 className="heading">
                          {language.homepage.beneficialBullet7TitleInvoice}
                        </h5>
                        <p className="text">
                          {language.homepage.beneficialBullet7TextInvoice}
                        </p>
                      </div>
                    </div>
                  )} */}
                  {hostNameCheckCondition && (
                    <div className="content-list">
                      <div className="icon-wrapper">
                        <figure className="icon">
                          <img
                            src={iconProcess}
                            className="card-img"
                            alt="intant"
                          />
                        </figure>
                      </div>
                      <div className="text-wrapper">
                        <h5 className="heading">
                          {language.homepage.beneficialBullet8TitleInvoice}
                        </h5>
                        <p className="text">
                          {language.homepage.beneficialBullet8TextInvoice}
                        </p>
                      </div>
                    </div>
                  )}
                  {hostNameCheckCondition && (
                    <div className="content-list">
                      <div className="icon-wrapper">
                        <figure className="icon">
                          <img
                            src={iconReferKoinInvoice}
                            className="card-img"
                            alt="intant"
                          />
                        </figure>
                      </div>
                      <div className="text-wrapper">
                        <h5 className="heading">
                          {language.homepage.beneficialBullet9TitleInvoice}
                        </h5>
                        <p className="text">
                          {language.homepage.beneficialBullet9TextInvoice}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    style={{ display: hostNameCheckCondition ? "none" : "" }}
                    className="content-list"
                  >
                    <div className="icon-wrapper">
                      <figure className="icon">
                        <img
                          src={iconRefer}
                          className="card-img"
                          alt="intant"
                        />
                      </figure>
                    </div>
                    <div className="text-wrapper">
                      <h5 className="heading">
                        {language.homepage.beneficialBullet4Title}
                      </h5>
                      <p className="text">
                        {language.homepage.beneficialBullet4Text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BannerSecondaryWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
});

export default connect(mapStateToProps, null)(BannerSecondary);
