import cookie from "react-cookies";

const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;
const token = cookie.load("token");

function sendDocumentLink(document, loan, auth) {
  let path = '/v1/users/document/send-link';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.token
      },
      body: JSON.stringify({
        email: auth.email,
        loan_id: loan.loan_id,
        document: document
      })
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getCompanies(term) {
  let path = '/v1/koingaji/companies';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + '?search=' + term, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function validateEmployee(data) {
  let path = '/v1/koingaji/employee/validate';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data)
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getPartnerData(id) {
  let path = '/v1/koingaji/partner/data/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function applyLoan(data) {
  let path = '/v1/koingaji/loan/apply';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data)
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getEmployeeInfo(id) {
  let path = '/v1/koingaji/partner/employee/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const gajiService = {
  validateEmployee,
  getEmployeeInfo,
  getCompanies,
  sendDocumentLink,
  getPartnerData,
  applyLoan
};