import { Drawer } from "antd";
import React from "react";
import MobileLayout from "../../Layout/MobileLayout/MobileLayout";
import history from "../../util/history";

const TermsConditionsLoan = ({
  onClose,
  language,
  visible,
  checkExpiredDate,
  borrowerInformation,
  creditUclLimit,
}) => {
  const termCondition = () => {
    return (
      <MobileLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h4 style={{ display: "block", textAlign: "center" }}>
            {language.text.termsAndCondition}
          </h4>
          <div>
            <p>
              {language.text.subHeadingTermsAdnCondition}:
            </p>
          </div>
          <div>
            <ol>
              {creditUclLimit?.properties?.disbursement_add_on_req?.map(
                (list, index) => {
                  return <li key={index}>{list}</li>;
                }
              )}
            </ol>
          </div>
          <p
            style={{
              cursor: "pointer",
              marginTop: 20,
              textDecorationLine: "underline",
              textAlign: "center",
            }}
            onClick={() => history.push("/koininvoice/apply-loan")}
          >
            {language.button.acceptTnc}
          </p>
        </div>
      </MobileLayout>
    );
  };

  const expiredDate = () => {
    return (
      <MobileLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={require("../../assets/images/notfound.png").default}
            style={{
              height: 150,
              width: 150,
              display: "block",
              margin: "0 auto",
            }}
            alt=""
          />
          <h4 style={{ display: "block", textAlign: "center", marginTop: 20 }}>
            {language.text.termsAndConditionFailed}
          </h4>
          <p>
            {language.text.termsAndConditionFailedText}
          </p>
          <p
            style={{
              cursor: "pointer",
              marginTop: 20,
              textDecorationLine: "underline",
              textAlign: "center",
            }}
            onClick={() => onClose("expired-date")}
          >
            {language.button.btnClose}
          </p>
        </div>
      </MobileLayout>
    );
  };

  const onGoingDate = () => {
    return (
      <MobileLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={require("../../assets/images/notfound.png").default}
            style={{
              height: 150,
              width: 150,
              display: "block",
              margin: "0 auto",
            }}
            alt=""
          />
          <h4 style={{ display: "block", textAlign: "center", marginTop: 20 }}>
            You cannot make new application
          </h4>
          <p>
            There is on going loan that is passed the due date. Please do the
            payment first before make new application.
          </p>
          <p
            style={{
              cursor: "pointer",
              marginTop: 20,
              textDecorationLine: "underline",
              textAlign: "center",
            }}
            onClick={() => onClose("")}
          >
            CLOSE
          </p>
        </div>
      </MobileLayout>
    );
  };

  return (
    <Drawer
      placement={"bottom"}
      height={555}
      onClose={onClose}
      visible={visible}
    >
      {checkExpiredDate ? (
        <React.Fragment>
          {borrowerInformation?.loan_due ? onGoingDate() : termCondition()}
        </React.Fragment>
      ) : (
        expiredDate()
      )}
    </Drawer>
  );
};

export default TermsConditionsLoan;
