import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  getActiveLoans,
  getLoanDetail,
  getLoanProgress,
  getLoanSchedule,
  getLoanContract,
  getLoanLender,
} from "../stores/actions/loan";
import { getFunding } from "../stores/actions/funding";
import {
  getClearance,
  getClearanceDetail,
  resetClearanceDetail,
  requestClearance,
} from "../stores/actions/clearance";
import { resetOffer } from "../stores/actions/offer";
import history from "../util/history";
import { ProgressBar, Form, Table, Pagination } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import activeLoan from "../assets/images/dashboard/active-loan.png";
import noLoan from "../assets/images/dashboard/noloan.png";
import clearanceSuccess from "../assets/images/dashboard/success.png";
import clearanceFailed from "../assets/images/dashboard/notenough.png";
import DashTitle from "../Components/Dashboard/DashTitle";
import Statusbar from "../Components/Dashboard/Statusbar";
import ReferFriend from "../Components/Dashboard/ReferFriend";
import LoanDetail from "../Components/SidePanel/LoanDetail";
import OffersComp from "../Components/SidePanel/AppProgComp/OffersComp";
import FundingComp from "../Components/SidePanel/AppProgComp/FundingComp";
import DisburseComp from "../Components/SidePanel/AppProgComp/DisburseComp";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";
import cookie from "react-cookies";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneLoanOpen: false,
      isPaneOffersOpen: false,
      isPaneFundingOpen: false,
      isPaneDisburseOpen: false,
      isEnoughCash: true,
    };
  }

  componentDidMount() {
    let { auth } = this.props;
    this.props.getActiveLoans(auth.token);
    this.props.getFunding(auth.token);
    this.props.getClearance(auth.token);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.offer.isChooseOfferSuccess &&
      !prevProps.offer.isChooseOfferSuccess
    ) {
      this.setState({ isPaneDisburseOpen: true });
    }
  }

  getRemainingDays = (date) => {
    let oneDay = 24 * 60 * 60 * 1000;
    let today = new Date();
    let end = new Date(date);

    let remaining = Math.round(Math.abs((end - today) / oneDay)) - 1;
    return isNaN(remaining) ? 0 : remaining;
  };

  openClearance = (id) => {
    let { auth } = this.props;
    this.props.getClearanceDetail(id, auth.token);
    this.setState({ isPaneOpen: true });
  };

  proceedClearance = () => {
    let { auth, clearance } = this.props;
    let loan_ids = [];
    loan_ids.push(clearance.data[0].loan_id);
    this.props.requestClearance(loan_ids, auth.token);
    this.props.resetClearanceDetail();
  };

  openLoanDetail = () => {
    let { auth, balance } = this.props;
    this.props.getLoanDetail(balance.data.loan_id, auth.token);
    this.props.getLoanProgress(balance.data.loan_id, auth.token);
    this.props.getLoanSchedule(balance.data.loan_id, auth.token);
    this.props.getLoanContract(balance.data.loan_id, auth.token);
    this.props.getLoanLender(balance.data.loan_id, auth.token);
    this.setState({ isPaneLoanOpen: true });
  };

  openStepDetail = (step) => {
    if (step === 0) {
      history.push("/waitingdocs");
    } else if (step === 1) {
      history.push("/reqdocs");
    } else if (step === 2) {
      this.setState({ isPaneOffersOpen: true });
    } else if (step === 4) {
      this.setState({ isPaneFundingOpen: true });
    } else if (step === 5) {
      this.setState({ isPaneDisburseOpen: true });
    }
  };

  closePaneOffer = () => {
    this.setState({ isPaneOffersOpen: false });
    this.props.resetOffer();
  };

  getCurrentProgress = (loan) => {
    let status = loan.loan_status;
    switch (status) {
      case "on_going":
        return 6;
      case "disbursement_process":
        return 5;
      case "funding_process":
        return 4;
      case "waiting_for_contract":
        return 3;
      case "choose_offer":
        return 2;
      case "under_review":
        return 1;
      default:
        return 0;
    }
  };

  redirectConditionFunction = () => {
    history.push({
      pathname: "/koininvoice/calculator-loan",
    });
    cookie.remove("loanApply", { path: "/dashboard-limit-application" });
  };

  render() {
    let {
      balance,
      clearance,
      funding,
      loan,
      offer,
      init: { language },
    } = this.props;
    let active = 2;
    let items = [];
    for (let number = 1; number <= 4; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active}>
          {number}
        </Pagination.Item>
      );
    }
    const Icon = ({ type }) => <i className={`icon-${type}`} />;

    return (
      <div className="dashboard">
        <DashTitle />
        <div className="row">
          <div className="col-12">
            <Statusbar />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3">
            <div className="card dash-card">
              <div className="card-body">
                {/* not found */}
                {clearance.isGetClearanceFailed && (
                  <div className="card-content">
                    <div className="top">
                      <h3 className="card-title">
                        {language.title.clearanceNotFound}
                      </h3>
                      <p className="mb-0 card-text">
                        {language.subtitle.clearanceNotFound}
                      </p>
                    </div>
                    <div className="bottom">
                      <div className="btn btn-primary w-100">
                        {hostNameCheckCondition ? (
                          <p
                            onClick={() => this.redirectConditionFunction()}
                            className="btn-apply"
                          >
                            {language.homepage.applyNow}
                          </p>
                        ) : (
                          <Link to={"/gaji/apply-loan"} className="btn-apply">
                            {language.homepage.applyNow}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* found */}
                {clearance.data && (
                  <div className="card-content">
                    <div className="top">
                      <h3 className="card-title">
                        {language.title.requestClearance}
                      </h3>
                      <p className="mb-0 card-text">
                        {language.subtitle.requestClearance}
                      </p>
                    </div>
                    <div className="clearance">
                      <div className="top">
                        <div className="name">
                          {clearance.data[0].loan_code} >
                        </div>
                        <div className="purpose text-truncate">
                          {clearance.data[0].loan_description}
                        </div>
                      </div>
                      <div className="bottom">
                        <div className="left">
                          <div className="title">{language.label.loanTerm}</div>
                          <div className="text">
                            {clearance.data[0].tenure} {language.label.months}
                          </div>
                        </div>
                        <div className="right">
                          <div className="title">
                            {language.label.totalClearance}
                          </div>
                          <div className="text">
                            <NumberFormat
                              value={clearance.data[0].clearance_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalSeparator="."
                              decimalScale={3}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom" ref={(ref) => (this.el = ref)}>
                      <div
                        className="btn btn-primary w-100"
                        onClick={() =>
                          this.openClearance(clearance.data[0].loan_id)
                        }
                      >
                        {language.button.sendRequest}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card dash-card">
              <div className="card-body">
                <div className="card-content">
                  <div className="top">
                    <h3 className="card-title">{language.title.activeLoans}</h3>
                    {loan.getActiveLoanFailed && (
                      <div className="notfound-wrapper">
                        <figure className="dashboard-img">
                          <img src={activeLoan} alt="active loan" />
                        </figure>
                        <h5 className="title">{language.title.noActiveLoan}</h5>
                        <p className="text">{language.subtitle.noActiveLoan}</p>
                      </div>
                    )}
                    {loan.activeLoans && (
                      <div className="active-loan">
                        <div className="text-wrapper">
                          <span className="text">
                            {language.label.dueThisMonth}
                          </span>
                          <span className="num">
                            Rp &nbsp;
                            <NumberFormat
                              value={loan.activeLoans.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalSeparator="."
                              decimalScale={3}
                            />
                          </span>
                        </div>
                        <div className="loan-list">
                          <div className="single-item">
                            <div className="left">
                              <div
                                className="item-wrapper m-0"
                                style={{ width: "30px" }}
                              >
                                <Form.Check type="checkbox" checked readOnly />
                              </div>
                              <div className="item-wrapper">
                                <p className="title">
                                  {language.label.dueDate}
                                </p>
                                <p className="text">
                                  {new Date(
                                    loan.activeLoans.due_date
                                  ).toLocaleDateString("ID-id", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </p>
                              </div>
                              <div className="item-wrapper">
                                <p className="title">{language.label.amount}</p>
                                <p className="text">
                                  <NumberFormat
                                    value={loan.activeLoans.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalSeparator="."
                                    decimalScale={3}
                                  />
                                </p>
                              </div>
                            </div>
                            <div
                              className="right"
                              onClick={this.openLoanDetail}
                            >
                              <i className="fa fa-chevron-right"></i>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <div
                            className="btn btn-primary"
                            onClick={() => history.push("/schedule")}
                          >
                            {language.button.seeSchedule}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <ReferFriend language={language} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card dash-card">
              <div className="card-body">
                <div className="card-content">
                  <div className="top">
                    <h3 className="card-title">
                      {language.title.applicationProgress}
                    </h3>
                    {/* <p className="mb-0 card-text">Whatever your needs, let us help you to make it come true.</p> */}
                    <Steps
                      labelPlacement="vertical"
                      current={
                        balance.data
                          ? this.getCurrentProgress(balance.data)
                          : null
                      }
                      className="app-progress"
                    >
                      <Steps.Step
                        title="Waiting For Document"
                        description={<Icon type="waiting" />}
                        {...(balance.data &&
                        this.getCurrentProgress(balance.data) === 0
                          ? {
                              icon: <span>detail ></span>,
                              onClick: () =>
                                this.openStepDetail(
                                  this.getCurrentProgress(balance.data)
                                ),
                            }
                          : undefined)}
                      />
                      <Steps.Step
                        title="Under Review"
                        description={<Icon type="review" />}
                        {...(balance.data &&
                        this.getCurrentProgress(balance.data) === 1
                          ? {
                              icon: <span>detail ></span>,
                              onClick: () =>
                                this.openStepDetail(
                                  this.getCurrentProgress(balance.data)
                                ),
                            }
                          : undefined)}
                      />
                      <Steps.Step
                        title="Choose Offer"
                        description={<Icon type="offer" />}
                        {...(balance.data &&
                        this.getCurrentProgress(balance.data) === 2
                          ? {
                              icon: <span>detail ></span>,
                              onClick: () =>
                                this.openStepDetail(
                                  this.getCurrentProgress(balance.data)
                                ),
                            }
                          : undefined)}
                      />
                      <Steps.Step
                        title="Waiting for Contract"
                        description={<Icon type="contract" />}
                        {...(balance.data &&
                        this.getCurrentProgress(balance.data) === 3
                          ? {
                              icon: <span>detail ></span>,
                              onClick: () =>
                                this.openStepDetail(
                                  this.getCurrentProgress(balance.data)
                                ),
                            }
                          : undefined)}
                      />
                      <Steps.Step
                        title="Funding Process"
                        description={<Icon type="process" />}
                        {...(balance.data &&
                        this.getCurrentProgress(balance.data) === 4
                          ? {
                              icon: <span>detail ></span>,
                              onClick: () =>
                                this.openStepDetail(
                                  this.getCurrentProgress(balance.data)
                                ),
                            }
                          : undefined)}
                      />
                      <Steps.Step
                        title="Disbursement Process"
                        description={<Icon type="disbursement" />}
                        {...(balance.data &&
                        this.getCurrentProgress(balance.data) === 5
                          ? {
                              icon: <span>detail ></span>,
                              onClick: () =>
                                this.openStepDetail(
                                  this.getCurrentProgress(balance.data)
                                ),
                            }
                          : undefined)}
                      />
                      <Steps.Step
                        title="Ongoing"
                        description={<Icon type="ongoing" />}
                        {...(balance.data &&
                        this.getCurrentProgress(balance.data) === 6
                          ? {
                              icon: <span>detail ></span>,
                              onClick: this.openLoanDetail,
                            }
                          : undefined)}
                      />
                    </Steps>
                  </div>
                  <div className="bottom">
                    {/* <div className="btn btn-primary w-100">APPLY NOW</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card dash-card">
              <div className="card-body">
                <div className="card-content">
                  <div className="top">
                    <h3 className="card-title">{language.title.funding}</h3>
                    {funding.data && (
                      <div>
                        <Table responsive className="funding">
                          <thead>
                            <tr>
                              <th></th>
                              <th>{language.label.terms}</th>
                              <th>{language.label.amountTab}</th>
                              <th>{language.label.purpose}</th>
                              <th>% {language.label.funded}</th>
                              <th>{language.label.timeLeft}</th>
                              <th>{language.label.requestDate}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {funding.data.map((funding) => (
                              <tr key={funding.id}>
                                <td className="name">{funding.code}</td>
                                <td>
                                  {funding.term} {language.label.months}
                                </td>
                                <td>
                                  <NumberFormat
                                    value={funding.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalSeparator="."
                                    decimalScale={2}
                                  />
                                </td>
                                <td>{funding.description}</td>
                                <td>
                                  <ProgressBar
                                    now={funding.funding_percentage}
                                  />
                                  <small>{funding.funding_percentage}%</small>
                                </td>
                                <td>
                                  {this.getRemainingDays(
                                    funding.funding_expired_at
                                  )}{" "}
                                  {language.label.days}
                                </td>
                                <td>
                                  {new Date(
                                    funding.request_date
                                  ).toLocaleDateString("ID-id", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </td>
                                <td className="share">
                                  <i className="icon-share"></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {/* <div className="funding-pagination">
                          <Pagination>
                            <Pagination.First />
                            <Pagination.Prev />
                            {items}
                            <Pagination.Ellipsis />
                            <Pagination.Next />
                            <Pagination.Last />
                          </Pagination>
                        </div> */}
                      </div>
                    )}
                    {funding.getFundingFailed && (
                      <div className="notfound-wrapper">
                        <figure
                          className="dashboard-img"
                          style={{ marginTop: "35px" }}
                        >
                          <img src={noLoan} alt="active loan" />
                        </figure>
                        <h5 className="title">
                          {language.title.noFundingLoan}
                        </h5>
                        <p className="text">
                          {language.subtitle.noFundingLoan}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* side modal */}
        <div>
          <SlidingPane
            className="side-modal req-clearance-modal"
            overlayClassName="side-modal-overlay"
            width="540px"
            ariaHideApp={false}
            isOpen={this.state.isPaneOpen}
            onRequestClose={() => {
              this.props.resetClearanceDetail();
              this.setState({ isPaneOpen: false });
            }}
          >
            <div className="side-modal-wrapper">
              {clearance.data && clearance.detail && (
                <div className="top">
                  <h3 className="side-modal-title">
                    {language.title.requestClearance}
                  </h3>
                  <div className="total-box">
                    <div className="box-item strong">
                      <span>{language.label.totalClearance}</span>
                      <span>
                        IDR &nbsp;
                        <NumberFormat
                          value={clearance.data[0].clearance_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalSeparator="."
                          decimalScale={3}
                        />
                      </span>
                    </div>
                    <div className="box-item light">
                      <span>{language.label.paymentBefore}</span>
                      <span>
                        {new Date(
                          clearance.detail.pay_before
                        ).toLocaleDateString("ID-id", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </div>
                  </div>
                  <p className="side-modal-text">
                    {language.subtitle.requestClearanceModal} &nbsp;
                    <span className="count">
                      IDR &nbsp;
                      <NumberFormat
                        value={clearance.detail.saving}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={3}
                      />
                    </span>
                    .
                  </p>{" "}
                  <br />
                </div>
              )}
              {clearance.detail && (
                <div>
                  <Table responsive className="clearance">
                    <thead>
                      <tr>
                        <th>{language.label.payment}</th>
                        <th className="link">Normal</th>
                        <th className="link">Clearance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Principal</td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.normal.principal}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.clearance.principal}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Interest</td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.normal.interest}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.clearance.interest}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Late</td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.normal.late}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.clearance.late}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.normal.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                        <td className="strong">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.clearance.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="accept">Saving</td>
                        <td></td>
                        <td className="accept">
                          IDR &nbsp;
                          <NumberFormat
                            value={clearance.detail.saving}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalSeparator="."
                            decimalScale={3}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="bottom text-right">
                    <div className="line my-3"></div>
                    <div
                      className="btn-modal-cancel"
                      onClick={() => {
                        this.props.resetClearanceDetail();
                        this.setState({ isPaneOpen: false });
                      }}
                    >
                      {language.button.cancel}
                    </div>
                    <div
                      className="btn-modal-proceed"
                      onClick={this.proceedClearance}
                    >
                      {language.button.proceed}
                    </div>
                  </div>
                </div>
              )}

              {/* success */}
              {clearance.isPaymentSuccess && (
                <div>
                  <div className="top">
                    <h3 className="side-modal-title">
                      {language.title.clearancePaidOff}
                    </h3>
                    <div className="modal-img-wrapper">
                      <figure className="modal-img">
                        <img src={clearanceSuccess} alt="success" />
                      </figure>
                      <p className="side-modal-text">
                        {language.subtitle.clearancePaidOff}
                      </p>
                      <br />
                      <small>{language.label.redirecting}</small>
                    </div>
                  </div>
                  <div className="bottom text-right">
                    <div className="line my-3"></div>
                  </div>
                </div>
              )}
              {/* payment failed */}
              {clearance.isNotEnoughBalance && (
                <div>
                  <div className="top">
                    <h3 className="side-modal-title">
                      {language.title.notEnoughCash}
                    </h3>
                    <div className="modal-img-wrapper">
                      <figure className="modal-img mb-4">
                        <img src={clearanceFailed} alt="success" />
                      </figure>
                      <p className="side-modal-text w-75">
                        {language.subtitle.notEnoughCash} &nbsp;
                        <NumberFormat
                          value={
                            clearance.data
                              ? clearance.data[0].clearance_amount
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalSeparator="."
                          decimalScale={2}
                        />{" "}
                        &nbsp;
                        {language.text.before} &nbsp;
                        {new Date(
                          clearance.data[0].due_date
                        ).toLocaleDateString("ID-id", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                        .
                      </p>
                    </div>
                  </div>
                  <div className="bottom text-right">
                    <div className="line my-3"></div>
                    <div
                      className="btn-modal-cancel"
                      onClick={() => {
                        this.props.resetClearanceDetail();
                        this.setState({ isPaneOpen: false });
                      }}
                    >
                      {language.button.cancel}
                    </div>
                    <div className="btn-modal-proceed">
                      {language.button.toTransferInstruction}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </SlidingPane>

          <SlidingPane
            className="side-modal payment-detail-modal"
            overlayClassName="payment-detail-overlay"
            width="540px"
            ariaHideApp={false}
            isOpen={this.state.isPaneLoanOpen}
            onRequestClose={() => {
              this.setState({ isPaneLoanOpen: false });
            }}
          >
            <LoanDetail
              onClose={() => this.setState({ isPaneLoanOpen: false })}
              language={language}
            />
          </SlidingPane>

          <SlidingPane
            className="side-modal payment-detail-modal"
            overlayClassName="payment-detail-overlay"
            width="540px"
            ariaHideApp={false}
            isOpen={
              offer.isChooseOfferSuccess ? false : this.state.isPaneOffersOpen
            }
            onRequestClose={this.closePaneOffer}
          >
            <OffersComp onClick={this.closePaneOffer} />
          </SlidingPane>

          <SlidingPane
            className="side-modal payment-detail-modal"
            overlayClassName="payment-detail-overlay"
            width="540px"
            ariaHideApp={false}
            isOpen={this.state.isPaneFundingOpen}
            onRequestClose={() => {
              this.setState({ isPaneFundingOpen: false });
            }}
          >
            <FundingComp />
          </SlidingPane>

          <SlidingPane
            className="side-modal payment-detail-modal"
            overlayClassName="payment-detail-overlay"
            width="540px"
            ariaHideApp={false}
            isOpen={this.state.isPaneDisburseOpen}
            onRequestClose={() => {
              this.setState({ isPaneDisburseOpen: false });
            }}
          >
            <DisburseComp
              onClose={() => {
                this.setState({ isPaneDisburseOpen: false });
              }}
            />
          </SlidingPane>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
  auth: state.auth,
  koinvoice: state.koinvoice,
  balance: state.balance,
  clearance: state.clearance,
  funding: state.funding,
  loan: state.loan,
  offer: state.offer,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActiveLoans,
      getFunding,
      getClearance,
      getClearanceDetail,
      resetClearanceDetail,
      requestClearance,
      getLoanDetail,
      getLoanProgress,
      getLoanSchedule,
      getLoanContract,
      getLoanLender,
      resetOffer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
