import styled from "styled-components";

export const LoanAppWrapper = styled.div`
  background: #f2f2f2;

  a {
    text-decoration: underline;
  }

  /* fixing default style */
  .MuiStepper-root {
    padding-bottom: 0;
  }
  .MuiStepConnector-alternativeLabel {
    top: 10px;
    .MuiStepConnector-lineHorizontal {
      border-top-width: 3px;
    }
  }
  .MuiStepConnector-active {
    .MuiStepConnector-line {
      /* border-color: #378df4; */
      border-color: #2b486d;
    }
  }

  .card-note {
    text-align: center;
    text-decoration-line: underline !important;
    text-transform: uppercase;
    color: #566068;
    margin-bottom: 1em;
    cursor: pointer;
  }
  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepIcon-root.MuiStepIcon-completed {
    /* color: #378df4; */
    color: #2b486d;
  }
  .MuiStepLabel-label {
    margin-top: 7px;
    color: #cccccc;
  }
  .step-label {
    span {
      letter-spacing: -0.24px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      &.MuiStepLabel-active,
      &.MuiStepLabel-completed {
        text-decoration-line: underline;
        /* color: #378df4; */
        color: #2b486d;
      }
    }
  }
  .step-btn-wrapper {
    padding: 1.25rem;
    padding-top: 0;
    .step-back {
      display: none;
    }
    .step-next {
      width: 100%;
    }
  }

  .page-content-wrapper {
    /* width: 50%; */
    margin: 0 auto;
    padding-top: 30px;
  }
  .logo-wrapper {
    position: relative;
    top: -65px;
    width: 131px;
    margin: 0 auto;
    .logo-img {
      position: absolute;
    }
  }
  .register-body {
    /* padding-top: 80px; */
    /* padding-bottom: 35px; */
    background-color: #ffffff;
    .register-title {
      text-align: center;

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 33px;
      line-height: 41px;
      /* or 121% */

      text-align: center;
      letter-spacing: 0.41px;

      color: #2b486d;
    }
    .text-note {
      .phone-number {
        color: #007bff;
      }
    }
  }

  .input-wrapper {
    margin-bottom: 15px;
  }
  .back-button {
    color: #c1c1c1;
    padding: 15px;
    font-size: 18px;
    i {
      margin-right: 7px;
    }
  }
  .otp-input {
    justify-content: center;
    input {
      height: 50px !important;
      width: 50px !important;
      border: none;
      border: 1.5px solid #dcdcdc;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
  .resend-wrapper {
    padding: 25px;
    padding-bottom: 15px;
    .resend-btn {
      justify-content: center !important;
      color: #c4c4c4;
    }
  }
  .completed-wrapper {
    text-align: center;
    padding: 25px;
    .completed-text {
      .completed-title {
        letter-spacing: 0.41px;
        color: #2b486d;
        font-size: 29px;
      }
      .completed-desc {
        letter-spacing: -0.41px;
        color: #575756;
      }
    }
  }
`;
