export const ESTATUS = [
  { value: "3b6b220b-2019-4279-8343-cbe6167f7545", label: "Contract" },
  { value: "14b12afe-fbca-11e9-b437-00163e016d4c", label: "Outsource" },
  { value: "3b6b220b-2019-4279-8343-cbe6167f7546", label: "Permanent" },
  { value: "14b12aff-fbca-11e9-b437-00163e016d4c", label: "Partner" }
];

export const positions = [
  { value: "45583428-fc5a-11e9-b437-00163e016d4c", label: "Staff" },
  { value: "45583429-fc5a-11e9-b437-00163e016d4c", label: "Supervisor" },
  { value: "4558342a-fc5a-11e9-b437-00163e016d4c", label: "Manager" }
];

export const workingyears = {
  en: [
    { value: "4558342c-fc5a-11e9-b437-00163e016d4c", label: "< 6 months" },
    { value: "4558342d-fc5a-11e9-b437-00163e016d4c", label: "6 months - 1 years" },
    { value: "4558342e-fc5a-11e9-b437-00163e016d4c", label: "1 - 2 years" },
    { value: "4558342f-fc5a-11e9-b437-00163e016d4c", label: "> 2 years" }
  ],
  id: [
    { value: "4558342c-fc5a-11e9-b437-00163e016d4c", label: "< 6 bulan" },
    { value: "4558342d-fc5a-11e9-b437-00163e016d4c", label: "6 bulan - 1 tahun" },
    { value: "4558342e-fc5a-11e9-b437-00163e016d4c", label: "1 - 2 tahun" },
    { value: "4558342f-fc5a-11e9-b437-00163e016d4c", label: "> 2 tahun" }
  ]
};

export const sRelationship = [
  { value: "parent", label: "Parent" },
  { value: "sibling", label: "Sibling" },
  { value: "uncle/auntie", label: "Uncle/Auntie" },
  { value: "guardian", label: "Guardian" }
];

export const mRelationship = [
  { value: "parent", label: "Parent" },
  { value: "spouse", label: "Spouse" },
  { value: "sibling", label: "Sibling" },
  { value: "uncle/auntie", label: "Uncle/Auntie" },
  { value: "guardian", label: "Guardian" }
];

export const currencies = [
  {
    value: "USD",
    label: "$"
  },
  {
    value: "EUR",
    label: "€"
  },
  {
    value: "BTC",
    label: "฿"
  },
  {
    value: "JPY",
    label: "¥"
  }
];

export const StepTitleInternal = {
  en: ["Work", "Request Loan", "Confirmation"],
  id: ["Pekerjaan", "Permohonan Pinjaman", "Konfirmasi"]
}

export const StepTitleExternal = {
  en: ["Work", "Request Loan", "Personal Info", "Connect", "Confirmation"],
  id: ["Pekerjaan", "Permohonan Pinjaman", "Info Pribadi", "Hubungkan", "Konfirmasi"]
}

export const Offers = [
  { value: "Salary Advance", label: "Salary Advance" },
  { value: "Regular", label: "Regular"}
]

export const Banks = [
  { name: "Bank Mandiri", code: "MANDIRI"},
  { name: "BNI", code: "BNI" },
  { name: "BRI", code: "BRI" },
  { name: "Bank Muamalat", code: "MUAMALAT" },
  { name: "Permata Bank", code: "PERMATA"}
];