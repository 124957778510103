import React from "react";
import { Drawer, Spin } from "antd";

export default function DrawerAllert({
  language,
  visible,
  image,
  labelText,
  paragraphText,
  paragraphTextTwo,
  height = 444,
  closeFunction = false,
  iShowButtonClose = false,
  isContractLimitApplication = false,
  onClose = function () { },
  setIsShowYourLoanDetails = function () { },
  setIsShowDocumentList = function () { },
  getAplicationProsess = function () { },
  handleChangeActionServiceApi = function () { },
  setIsShowloading = function () { },
  actionGenerateContractLimit = function () { },
  generateContractLimit = false,
  documentLoanType,
  thidrPartyOy
}) {
  function handleChangeFalse() {
    onClose();
    handleChangeActionServiceApi();
    if (generateContractLimit) {
      actionGenerateContractLimit();
    } else {
      if (documentLoanType && documentLoanType === 'waiting_for_contract') {
        setIsShowYourLoanDetails(false);
      } else {
        setIsShowYourLoanDetails(true);
        setIsShowDocumentList(false);
      }
    }
    getAplicationProsess(setIsShowloading)
  }
  return (
    <Drawer
      title=""
      placement={"bottom"}
      height={height}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <Spin spinning={isContractLimitApplication}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={image} alt="" />
        </div>
        <h3 style={{ textAlign: "center", color: "#2B486D", marginTop: 22 }}>
          {
            thidrPartyOy?.result === "under_review" 
              ? language.text.informationUpdateFailed
              : labelText
          }
        </h3>
        <p style={{ textAlign: "center", color: "#575756" }}>
          {
            thidrPartyOy?.result === "under_review" 
              ? language.text.holderNameNotSame
              : paragraphTextTwo
          }
        </p>
        <p style={{ textAlign: "center", color: "#575756" }}>{paragraphText}</p>
        {iShowButtonClose === false && (
          <p
            onClick={closeFunction ? () => handleChangeFalse() : () => onClose()}
            style={{
              color: "#566068",
              textAlign: "center",
              fontWeight: 555,
              cursor: "pointer",
              textDecorationLine: "underline",
            }}
          >
            {language.button.btnClose}
          </p>
        )}
      </Spin>
    </Drawer>
  );
}
