import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import SimpleTextInput from "../../Components/Form/simpleTextInput";
import SimpleSelectInput from "../../Components/Form/simpleSelectInput";
import { getCompanies, LoanInfo } from "../../stores/actions/gaji";
import { getPersonalProfile } from "../../stores/actions/account";
import { StepWorkWrapper } from "./StepWork.styles";
import NumberFormat from "react-number-format";
import InputAdornment from "@material-ui/core/InputAdornment";
import CurrencyFormatInput from "../../Components/Form/currencyFormatInput";
import * as validate from "../../services/validate";
import history from "../../util/history";

import imgNotfound from "../../assets/images/not-partner.svg";

const SelectContainer = props => {
  return <div onKeyUp={props.inputChange}>{props.children}</div>;
};

class StepWorkForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    company: "",
    companyId: "",
    isPartner: "",
    salary: 0,
    maxLimit: 0
  };

  handleOptionChange = option => {
    let vendor = this.props.loan.vendor;

    if (option !== null) {
      let item = vendor.find(x => x.vendor_id === option.data);
      this.setState({
        isSelected: 1,
        company: option,
        tenures: item.tenure,
        admin_fee: item.admin.admin_fee,
        vendor_data: item
      });
      if (option.data === 1) {
        this.setState({ loanStatus: "internal" });
      } else {
        this.setState({ loanStatus: "eksternal" });
      }
    } else {
      this.setState({
        isSelected: 0,
        tenures: "",
        loanStatus: "",
        salary: "",
        eStatus: "",
        vendor_data: {}
      });
    }
  };

  handleSalaryChange = value => {
    let maxLimit = 70/100*value;
    this.setState({
      salary: value,
      maxLimit: maxLimit
    })
  }

  handleSubmit = () => {
    this.props.LoanInfo({
      data: {
        netSalary: this.state.salary,
        maxLimit: this.state.maxLimit,
        purpose: "Koin Gaji",
        companyId: this.state.companyId
      }
    });
    this.props.handleSubmit();
  };


  inputValueChange = ({ target }) => {
    let term = target.value;
    if (term.length >= 2) this.props.getCompanies(term);
  };

  updateSelectVal = val => {
    let { company, companyId, isPartner } = this.state;
    if (val) {
      company = val.label;
      companyId = val.value;
      isPartner = val.data;
      this.setState({
        company,
        isPartner,
        companyId
      });
    }
  };

  componentDidMount() {
    this.props.getPersonalProfile(this.props.auth.token);
  }

  render() {
    const { isPartner } = this.state;
    const {
      invalid,
      pristine,
      submitting,
      gaji,
      init: { language }
    } = this.props;

    return (
      <StepWorkWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title">{language.title.stepWork}</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="input-wrapper">
              <SelectContainer inputChange={this.inputValueChange}>
                <Field
                  className="input-search"
                  label={language.form.company}
                  placeholder={language.form.company}
                  id="company"
                  name="company"
                  component={SimpleSelectInput}
                  options={gaji.companies}
                  onChange={this.updateSelectVal}
                  noOptionsMessage={() => "searching..."}
                />
              </SelectContainer>
            </div>
            {isPartner === "partner" && (
              <div className="input-wrapper">
                <Field
                  id="salary"
                  name="salary"
                  className="w-100"
                  label={language.form.netSalary}
                  placeholder={language.form.netSalary}
                  value={this.state.salary}
                  onChange={inputValue => this.handleSalaryChange(inputValue)}
                  variant="outlined"
                  component={SimpleTextInput}
                  InputProps={{
                    inputComponent: CurrencyFormatInput,
                    endAdornment: (
                      <InputAdornment className="adorement-info">
                        / {language.label.month}
                      </InputAdornment>
                    )
                  }}
                  validate={validate.required}
                />
                <p className="amount-description">
                  <b>Max. Limit Loan:</b> Rp{" "}
                  <NumberFormat
                    value={this.state.maxLimit}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </p>
              </div>
              
            )}
            
            { isPartner === "non-partner" && (
              <div className="comp-notfound">
                <figure className="img-notfound">
                  <img src={imgNotfound} alt="" />
                </figure>
                <h3 className="notfound-title">
                  {language.title.companyIsNotPartner}
                </h3>
                <p className="notfound-text">
                  {language.subtitle.companyIsNotPartner}
                </p>
                <div className="input-wrapper">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      history.push("/refer-company");
                    }}
                  >
                    {language.button.referCompany}
                  </button>
                </div>
              </div>
            )}
            { isPartner !== "non-partner" && (
              <div className="input-wrapper">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className={"step-next btn btn-primary w-100"}
                  disabled={ invalid || pristine || submitting }
                >
                  {language.button.next}
                </button>
              </div>
            )}
          </form>
        </div>
      </StepWorkWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  init: state.init,
  gaji: state.gaji,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getCompanies,
    getPersonalProfile,
    LoanInfo
  }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(StepWorkForm);

export default reduxForm({
  form: "StepWorkForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(component);
