const init = {
	ktp: null,
  selfie: null,
  spouse_ktp: null
};

const uploadReducer = (state = init, action) => {
  switch (action.type) {
    case "UPLOAD_LOADING":
      return { ...state, ktp: null, isLoading: true, isKTPVerified: false, isKTPInvalid:false, ktpDetail: null };
    case "UPLOAD_SELFIE_LOADING":
      return { ...state, selfie: null, isSelfieLoading: true, isSelfieVerified: false };
    case "UPLOAD_SPOUSE_LOADING":
      return { ...state, spouse_ktp: null, isSpouseLoading: true, isSpouseVerified: false };
    case "UPLOAD_KTP_SUCCESS":
      return { 
        ...state,
        data: action.data,
        isKTPUploaded: true,
        isLoading: false
      };
    case "UPLOAD_KTP_FAILED":
      return {
        ...state,
        errors: action.payload.en,
        isKTPInvalid: true,
        isLoading: false
      };
    case "VERIFY_KTP_SUCCESS":
      return {
        ...state,
        ktp: action.payload.ktp,
        ktpDetail: action.payload.ktpDetail,
        isKTPVerified: true,
        isLoading: false
      };
    case "VERIFY_KTP_FAILED":
      return {
        ...state,
        errors: action.payload.en,
        isKTPInvalid: true,
        isLoading: false
      };
    case "UPLOAD_SELFIE_FAILED":
      return {
        ...state,
        errors: action.payload.en,
        isSelfieInvalid: true,
        isSelfieLoading: false
      }
    case "VERIFY_SELFIE_FAILED":
      return {
        ...state,
        errors: action.payload.en,
        isSelfieInvalid: true,
        isSelfieLoading: false
      };
    case "VERIFY_SELFIE_SUCCESS":
      return {
        ...state,
        selfie: action.payload,
        isSelfieVerified: true,
        isSelfieLoading: false
      };
    case "UPLOAD_SPOUSE_FAILED":
      return { 
        ...state,
        errors: action.payload.en,
        isSpouseUploaded: false,
        isSpouseLoading: false
      };
    case "VERIFY_SPOUSE_FAILED":
      return {
        ...state,
        errors: action.payload.en,
        isSpouseVerified: false,
        isSpouseLoading: false
      };
    case "VERIFY_SPOUSE_SUCCESS":
      return {
        ...state,
        spouse_ktp: action.payload,
        isSpouseVerified: true,
        isSpouseLoading: false
      };
    case "RESET_UPLOAD_KTP":
      return { ...state, isKTPVerified: false }
    case "RESET_UPLOAD_SELFIE":
      return { ...state, isSelfieVerified: false }
    case "RESET_UPLOAD_SPOUSE":
      return { ...state, isSpouseVerified: false }
    default:
      return state;
  }
};

export default uploadReducer;