import styled from "styled-components";

export const StepInfoWrapper = styled.div`
  background: #f2f2f2;

  .step-title {
    text-align: center;
    letter-spacing: 0.41px;
    color: #2b486d;
    margin-bottom: 15px;
  }

  .adorement-edit {
    p {
      text-decoration: underline;
      color: #378df4;
      cursor: pointer;
    }
  }
  .box-total {
    background: #f2f2f2;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
    .box-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      color: #8e8e8e;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .cfee {
      .text-right {
        color: #c53741;
      }
    }
    .ctotal {
      color: #566068;
    }
  }

  .file-upload input {
    display: block;
    position: relative;
    top: -40px;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .gender-wrapper {
    .gender-title {
      margin-bottom: 10px;
    }
    .gender-box {
      margin: 0;
      width: 50%;
      background: #f2f2f2;
      border-radius: 3px 0px 0px 3px;
      &:first-child {
        border-right: 1px solid #ffffff;
      }
      > span {
        padding: 18px;
        width: 100%;
        text-align: center;
        :first-child {
          display: none;
        }
        &.Mui-checked {
          & + span {
            color: #ffffff;
            background: #2b486d;
          }
        }
      }
    }
  }
  .react-tel-input {
    .form-control {
      width: 100%;
    }
  }
  .input-wrapper {
    position: relative;
  }
  .file-upload {
    display: block;
    opacity: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    width: 135px;
    height: 45px;
    input {
      width: 135px;
      height: 45px;
      top: 0;
      cursor: pointer;
    }
  }
  .img-preview-wrapper {
    display: flex;
    .img-preview {
      margin-right: 15px;
      width: 250px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .verified-file {
      cursor: pointer;
      color: #41af60;
      text-decoration: underline;
      align-self: center;
      /* reset */
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: default;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      &:focus {
        outline: 0;
      }
    }
    .invalid-file {
      color: #c53741;
      text-decoration: underline;
      align-self: center;
      /* reset */
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: default;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      &:focus {
        outline: 0;
      }
    }
    .img-link-wrapper {
      text-align: left;
      align-self: center;
    }
    .link-file {
      cursor: pointer;
      color: #378df4;
      text-decoration: underline;
      align-self: center;
      /* reset */
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      font-size: 17px;
      line-height: 22px;
      &:first-child {
        margin-right: 15px;
      }
      &:focus {
        outline: 0;
      }
    }
    .img-name {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #8e8e8e;
    }
  }
  .form-sec-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    color: #8e8e8e;
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin: 0.25rem 0 0.4rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dc3545;
    }
    label {
      color: #dc3545;
    }
  }
  .input-search .css-26l3qy-menu {
    z-index: 999;
  }
`;
