import React, { useState } from "react";
import { Button, Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import koinvoiceServices from "../../services/koinvoice";
import { PaymentInstructionsStyle } from "./style";
import { CopyToClipboard } from "react-copy-to-clipboard";
import currencyRupiah from "../../library/currency";
import moment from "moment";

function PaymentInstructions({ actionChangePaymentInstructions, paramsId }) {
  const [copiedVirtualAccount, setCopiedVirtualAccount] = useState(false);
  const [infromationPayment, setInformationPayment] = useState({});
  useEffect(() => {
    actionGetInformationDetailLoan(); // eslint-disable-next-line
  }, [paramsId]);

  async function actionGetInformationDetailLoan() {
    let response = await koinvoiceServices.actionInformationDetailLoan({
      paramsId: paramsId,
    });
    if (response.status === 200) {
      setInformationPayment(response.data);
    }
  }
  return (
    <PaymentInstructionsStyle>
      <Card>
        <div className="kw-paymentInstruction-wrapper">
          <Button
            size="large"
            onClick={() => actionChangePaymentInstructions("payment-bank")}
          >
            <ArrowLeftOutlined />
            BACK
          </Button>

          <h4>Payment instruction to payor:</h4>
          <div className="kw-paymentInstruction-wrapper__second-section">
            <p style={{ textAlign: "center" }}>
              {infromationPayment &&
                infromationPayment.business_information &&
                infromationPayment.business_information.va_banK_name}{" "}
              Virtual Account Number
            </p>
            <strong className="kw-paymentInstruction-wrapper__virtual-account">
              {infromationPayment &&
                infromationPayment.business_information &&
                infromationPayment.business_information.va_number}
            </strong>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CopyToClipboard
                text={
                  infromationPayment &&
                  infromationPayment.business_information &&
                  infromationPayment.business_information.va_number
                }
                onCopy={() => setCopiedVirtualAccount(true)}
              >
                <Button className="kw-paymentInstruction-wrapper__copy-virtual-account">
                  COPY NUMBER
                </Button>
              </CopyToClipboard>
            </div>
            <p style={{ textAlign: "center", marginTop: 10 }}>
              {copiedVirtualAccount ? (
                <span style={{ color: "#566068" }}>Berhasil di Copy !</span>
              ) : null}
            </p>
            <p style={{ textAlign: "center", marginBottom: 0 }}>
              Account Name:
            </p>
            <p style={{ textAlign: "center" }}>
              {infromationPayment &&
                infromationPayment.business_information &&
                infromationPayment.business_information.store_name}
            </p>
            <strong className="kw-paymentInstruction-wrapper__user-id">
              User ID:{" "}
              <span style={{ fontWeight: 200 }}>
                {infromationPayment &&
                  infromationPayment.loan_information &&
                  infromationPayment.loan_information.loan_id}
              </span>
            </strong>
            <strong
              style={{ marginTop: 20, display: "block", color: "#566068" }}
            >
              Amount Due:{" "}
              {infromationPayment &&
                infromationPayment.loan_information &&
                currencyRupiah(
                  infromationPayment.loan_information.loan_amount,
                  "Rp"
                )}
            </strong>
            <strong
              style={{ marginTop: 10, display: "block", color: "#566068" }}
            >
              Due Date:{" "}
              <span style={{ fontWeight: 350 }}>
                {infromationPayment &&
                  infromationPayment.loan_information &&
                  infromationPayment.loan_information.invoice_date &&
                  moment(
                    infromationPayment.loan_information.invoice_date
                  ).format("DD MMMM YYYY")}
              </span>
            </strong>
            <strong
              style={{ marginTop: 10, display: "block", color: "#566068" }}
            >
              Instruction:{" "}
              <span style={{ fontWeight: 350 }}>
                Please follow the instruction below to transfer the due amount
                to the aforementioned{" "}
                {infromationPayment &&
                  infromationPayment.business_information &&
                  infromationPayment.business_information.va_banK_name}{" "}
                Virtual Account
              </span>
            </strong>
          </div>
          <h5>Deposit Instruction</h5>
          <ol>
            <li>
              Please{" "}
              <strong style={{ color: "#566068" }}> add your User ID </strong>{" "}
              to your transfer amount, Example: Deposit IDR1.000.000 +{" "}
              {infromationPayment &&
                infromationPayment.loan_information &&
                infromationPayment.loan_information.loan_id}
              (your Lender ID) = IDR1.022.890
            </li>
            <li>
              We will receive and process your fund{" "}
              <strong style={{ color: "#566068" }}>
                within 2-3 wodking days.
              </strong>
            </li>
            <li>
              To process your deposit, please{" "}
              <strong style={{ color: "#566068" }}>
                upload your transfer slip:
              </strong>{" "}
              <Button
                className="kw-paymentInstruction-wrapper__copy-virtual-account"
                style={{ marginRight: 15, marginTop: 10 }}
              >
                TAKE A PHOTO
              </Button>
              <Button
                className="kw-paymentInstruction-wrapper__copy-virtual-account"
                style={{ marginBottom: 10 }}
              >
                SEND EMAIL
              </Button>
            </li>
            <li>
              If you're unwilling to add your Lender ID to your transfer
              amount,please use ATM/Mobile Banking to deposit to your Virtual
              Account
            </li>
          </ol>
        </div>
      </Card>
    </PaymentInstructionsStyle>
  );
}

export default PaymentInstructions;
