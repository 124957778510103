import React from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DatePickerInput = ({
  input,
  id,
  className,
  selected,
  meta: {touched, error},
  KeyboardButtonProps,
  ...custom
}) => (  
  <div className={`${touched && (error && "invalid")}`}>
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
  <KeyboardDatePicker
      variant="inline"
      format={"MM/dd/yyyy"}
      margin="normal"
      id={id}
      label={custom.label}
      name={input.name}
      selected={selected}
      value={input.value ? new Date(input.value) : null}
      onChange={input.onChange}
      KeyboardButtonProps={KeyboardButtonProps}
      className={className}
      inputVariant="outlined"
      onBlur={() => input.onBlur(input.value)}
  />
  </MuiPickersUtilsProvider>
    {touched && error && (<div className="invalid-feedback">{error}</div>)}
  </div>
);

export default DatePickerInput;