import cookie from "react-cookies";
import { userService } from "../../services/user";
import { upload } from "../../services/upload";
import * as loanAction from "../actions/loan";
import { hostNameCheckCondition } from "../../library/checkHostNameWeb";
import authentication from "../../repository/Authentication";

export const changeLanguage = (data) => {
  return { type: "CHANGE_LANGUAGE", payload: data };
};
const requestLoading = () => {
  return { type: "REQUEST_LOADING" };
};

const requestLoadingFalse = () => {
  return { type: "REQUEST_LOADING_FALSE" };
};

const loadingRegisterSubmit = () => {
  return { type: "LOADING_REGISTER_TRUE" };
};

const loadingRegisterSubmitFalse = () => {
  return { type: "LOADING_REGISTER_FALSE" };
};

const createAccountSuccess = (data) => {
  return { type: "CREATE_ACCOUNT_SUCCEEDED", payload: data };
};

const createAccountFailed = (errors) => {
  return { type: "CREATE_ACCOUNT_FAILED", payload: errors };
};

const createAccountBusinessSuccess = (data) => {
  return { type: "CREATE_ACCOUNT_BUSINESS_SUCCEEDED", payload: data };
};

const createAccountBusinessFailed = (errors) => {
  return { type: "CREATE_ACCOUNT_BUSINESS_FAILED", payload: errors };
};

const authPreparationSuccess = (data) => {
  return { type: "AUTH_PREPARATION_SUCCESS", payload: data };
};

const authPreparationFailed = (errors) => {
  return { type: "AUTH_PREPARATION_FAILED", payload: errors };
};

const authPreparationKoinInvoiceSuccess = (data) => {
  return { type: "AUTH_PREPARATION_KOININVOICE_SUCCESS", payload: data };
};

const authPreparationKoinInvoiceFailed = (errors) => {
  return { type: "AUTH_PREPARATION_KOININVOICE_FAILED", payload: errors };
};

export const authPreparationKoinInvoice = (
  body,
  actionChangeAlert,
  actionChangeLoadingShow
) => (dispatch) => {
  authentication
    .checkAuthPreparation({
      params: body,
      actionChangeAlert: actionChangeAlert,
      actionChangeLoadingShow: actionChangeLoadingShow,
    })
    .then((res) => {
      if (res?.status === 200) {
        dispatch(
          authPreparationKoinInvoiceSuccess(res)
        );
      } else {
        dispatch(
          authPreparationKoinInvoiceFailed(res)
        );
      }
    });
};

const authPreparationLogout = (values) => {
  return { type: "LOGOUT_KOININVOICE", payload: values };
};

export const actionChangeLogout = () => dispatch => {
  window.localStorage.removeItem("dataAccount");
  dispatch(authPreparationLogout());
};

export const createPersonalAccount = (data) => (dispatch) => {
  dispatch(requestLoading());
  userService.createPersonalAccount(data).then(
    (res) => {
      dispatch(createAccountSuccess(res.data));
      dispatch(requestLoadingFalse());
    },
    (error) => {
      dispatch(createAccountFailed(error));
      dispatch(requestLoadingFalse);
    }
  );
};

export const createBusinessAccount = (data) => (dispatch) => {
  dispatch(loadingRegisterSubmit());
  userService.createBusinessAccount(data).then(
    (res) => {
      dispatch(createAccountBusinessSuccess(res.data));
      hostNameCheckCondition &&
        cookie.save("token", res && res.data && res.data.access_token, {
          path: "/",
          maxAge: 3600,
        });
      dispatch(loadingRegisterSubmitFalse());
    },
    (error) => {
      dispatch(createAccountBusinessFailed(error));
      dispatch(loadingRegisterSubmitFalse());
    }
  );
};

export const authPreparation = (data) => (dispatch) => {
  userService.authPreparation(data).then(
    (res) => {
      if (res.status === 200) {
        if (hostNameCheckCondition === false) {
          cookie.save("name", res.data[0].fullname);
          cookie.save("email", res.data[0].email);
        }
        dispatch(authPreparationSuccess(res.data));
      } else {
        dispatch(authPreparationFailed(res.message));
      }
    },
    (error) => {
      dispatch(authPreparationFailed(error));
    }
  );
};

export const userLogin = (
  data,
  actionChangeShowLoading,
  setIsShowLoginModalSucsess
) => (dispatch) => {
  actionChangeShowLoading(true);
  userService.auth(data).then(
    (user) => {
      actionChangeShowLoading(false);
      dispatch(loginSucceded(user.data));
      // hostNameCheckCondition && dispatch(getApplicationList(actionChangeShowLoading,setIsShowLoginModalSucsess));
      setIsShowLoginModalSucsess(true);
    },
    (error) => {
      actionChangeShowLoading(false);
      dispatch(loginFailed(error));
    }
  );
};

const loginSucceded = (data) => {
  return { type: "LOGIN_SUCCEDED", payload: data };
};

const loginFailed = (errors) => {
  return { type: "LOGIN_FAILED", payload: errors };
};

export const accountInfo = (action) => {
  return {
    type: "ACCOUNT_INFO",
    ...action,
  };
};

export const pinInfo = (action) => {
  return {
    type: "PIN_INFO",
    ...action,
  };
};

const uploadLoading = () => {
  return { type: "UPLOAD_LOADING" };
};

const uploadFailed = (errors) => {
  return { type: "UPLOAD_KTP_FAILED", payload: errors };
};

const verifySuccess = (data) => {
  return { type: "VERIFY_KTP_SUCCESS", payload: data };
};

const verifyFailed = (errors) => {
  return { type: "VERIFY_KTP_FAILED", payload: errors };
};

const resetUploadKtp = () => {
  return { type: "RESEP_UPLOAD_KTP" };
};
const resetUploadSelfie = () => {
  return { type: "RESET_UPLOAD_SELFIE" };
};
const resetUploadSpouse = () => {
  return { type: "RESET_UPLOAD_SPOUSE" };
};

export const resetUploadFile = (type) => (dispatch) => {
  if (type === "ktp") {
    dispatch(resetUploadKtp());
  } else if (type === "selfi_ktp") {
    dispatch(resetUploadSelfie());
  } else {
    dispatch(resetUploadSpouse());
  }
};

export const uploadKTP = (data) => (dispatch) => {
  dispatch(uploadLoading());
  upload.imgUpload(data).then(
    (data) => {
      dispatch(verifyKTP(data.data));
    },
    (error) => {
      dispatch(uploadFailed(error));
    }
  );
};

const verifyKTP = (ktp) => (dispatch) => {
  upload.ktpValidation(ktp).then(
    (data) => {
      if (data.status === 200) {
        dispatch(verifySuccess({ ktp: ktp, ktpDetail: data.data }));
        dispatch(loanAction.getDistrict(data.data.district));
      } else dispatch(verifyFailed(data.message));
    },
    (error) => {
      dispatch(uploadFailed(error));
    }
  );
};

const uploadSelfieLoading = () => {
  return { type: "UPLOAD_SELFIE_LOADING" };
};

const uploadSelfieFailed = (errors) => {
  return { type: "UPLOAD_SELFIE_FAILED", payload: errors };
};

const verifySelfieSuccess = (data) => {
  return { type: "VERIFY_SELFIE_SUCCESS", payload: data };
};

const verifySelfieFailed = (errors) => {
  return { type: "VERIFY_SELFIE_FAILED", payload: errors };
};

export const uploadSelfie = (data, ktp) => (dispatch) => {
  dispatch(uploadSelfieLoading());
  upload.imgUpload(data).then(
    (data) => {
      dispatch(verifySelfie(data.data, ktp));
    },
    (error) => {
      dispatch(uploadSelfieFailed(error));
    }
  );
};

const verifySelfie = (photo, ktp) => (dispatch) => {
  upload.facePhotoValidation(photo[0], ktp).then(
    (data) => {
      if (data.status === 200)
        /* && data.data.result === true*/

        dispatch(verifySelfieSuccess(photo));
      else dispatch(verifySelfieFailed(data.data.message[0]));
    },
    (error) => {
      dispatch(uploadFailed(error));
    }
  );
};

export const getPersonalProfile = (token) => (dispatch) => {
  userService.getPersonalProfile(token).then(
    (res) => {
      let data = {};
      data["contact"] = {
        phone: res.data.phone_area + res.data.phone_number,
        email: res.data.email,
      };
      data["primary_bank"] = {
        bankName: res.data.primary_bank.name,
        account_number: res.data.primary_bank.account_number,
      };
      data["personal"] = res.data.details.personal.user_information;
      data["currentLoc"] = res.data.details.personal.current_residency;
      data["occupation"] = res.data.details.personal.occupation;
      data["location"] = res.data.details.personal.user_location;
      data["ktp"] = res.data.details.documents.personal_document
        ? res.data.details.documents.personal_document.ktp.value
        : null;
      data["selfieKtp"] =
        res.data.details.documents.personal_document &&
        res.data.details.documents.personal_document.selfie_ktp
          ? res.data.details.documents.personal_document.selfie_ktp.value
          : null;
      dispatch(getPersonalProfileSuccess(data));
    },
    (error) => {
      dispatch(getPersonalProfileFailed(error));
    }
  );
};

export const putPersonalProfile = (data, token) => (dispatch) => {
  userService.putPersonalProfile(data, token).then(
    (data) => {
      dispatch(putPersonalProfileSuccess(data.data));
    },
    (error) => {
      dispatch(putPersonalProfileFailed(error));
    }
  );
};

const getPersonalProfileSuccess = (data) => {
  return { type: "GET_PERSONAL_PROFILE_SUCCESS", payload: data };
};

const getPersonalProfileFailed = (error) => {
  return { type: "GET_PERSONAL_PROFILE_FAILED", payload: error };
};

const putPersonalProfileSuccess = (data) => {
  return { type: "PUT_PERSONAL_PROFILE_SUCCESS", payload: data };
};

const putPersonalProfileFailed = (error) => {
  return { type: "PUT_PERSONAL_PROFILE_FAILED", payload: error };
};

export const putLegalProfile = (data, token) => (dispatch) => {
  userService.putLegalProfile(data, token).then(
    (data) => {
      dispatch(putLegalSuccess(data.data));
    },
    (error) => {
      dispatch(putLegalFailed(error));
    }
  );
};

const putLegalSuccess = (data) => {
  return { type: "PUT_LEGAL_PROFILE_SUCCESS", payload: data };
};

const putLegalFailed = (error) => {
  return { type: "PUT_LEGAL_PROFILE_FAILED", payload: error };
};

const putEmergencyDataSuccess = (data) => {
  return { type: "PUT_EMERGENCY_DATA_SUCCESS", payload: data };
};

const putEmergencyDataFailed = (error) => {
  return { type: "PUT_EMERGENCY_DATA_FAILED", payload: error };
};

export const putEmergencyContact = (data, token) => (dispatch) => {
  userService.putEmergencyContact(data, token).then(
    (data) => {
      dispatch(putEmergencyDataSuccess(data.data));
    },
    (error) => {
      dispatch(putEmergencyDataFailed(error));
    }
  );
};

const putFinancialDataSuccess = (data) => {
  return { type: "PUT_FINANCIAL_DATA_SUCCESS", payload: data };
};

const putFinancialDataFailed = (error) => {
  return { type: "PUT_FINANCIAL_DATA_FAILED", payload: error };
};

export const putFinancialProfile = (data, token) => (dispatch) => {
  userService.putFinancialProfile(data, token).then(
    (data) => {
      dispatch(putFinancialDataSuccess(data.data));
    },
    (error) => {
      dispatch(putFinancialDataFailed(error));
    }
  );
};

const uploadSpouseLoading = () => {
  return { type: "UPLOAD_SPOUSE_LOADING" };
};

const buttonBackType = () => {
  return { type: "BUTTON_BACK_REGISTER" };
};

const uploadSpouseFailed = (errors) => {
  return { type: "UPLOAD_SPOUSE_FAILED", payload: errors };
};

const verifySpouseSuccess = (data) => {
  return { type: "VERIFY_SPOUSE_SUCCESS", payload: data };
};

const verifySpouseFailed = (errors) => {
  return { type: "VERIFY_SPOUSE_FAILED", payload: errors };
};

export const uploadSpouseKTP = (data) => (dispatch) => {
  dispatch(uploadSpouseLoading());
  upload.imgUpload(data).then(
    (data) => {
      dispatch(verifySpouseKTP(data.data));
    },
    (error) => {
      dispatch(uploadFailed(error));
    }
  );
};

export const actionChangeButtonBack = (prevPage) => (dispatch) => {
  dispatch(buttonBackType());
  prevPage();
};

const verifySpouseKTP = (ktp) => (dispatch) => {
  upload.ktpValidation(ktp).then(
    (data) => {
      if (data.status === 200 && data.approval.final_decision === "auto accept")
        dispatch(verifySpouseSuccess(ktp));
      else dispatch(verifySpouseFailed(data.message));
    },
    (error) => {
      dispatch(uploadSpouseFailed(error));
    }
  );
};

export const checkEmail = (email, handleShowIsLoading) => (dispatch) => {
  handleShowIsLoading(true);
  userService.checkEmail(email).then(
    (data) => {
      if (data.status === 202) {
        dispatch(emailAvailable());
        handleShowIsLoading(false);
      } else {
        dispatch(emailRegistered(data));
        handleShowIsLoading(false);
      }
    },
    (error) => {
      dispatch(checkEmailFailed(error));
      handleShowIsLoading(false);
    }
  );
};

const emailAvailable = () => {
  return { type: "EMAIL_AVAILABLE" };
};

const emailRegistered = (data) => {
  return { type: "EMAIL_REGISTERED", payload: data.message };
};

const checkEmailFailed = (errors) => {
  return { type: "CHECK_EMAIL_FAILED", payload: errors };
};

export const checkPhoneNumber = (number, handleShowIsLoading) => (dispatch) => {
  handleShowIsLoading(true);
  userService.checkPhoneNumber(number).then(
    (data) => {
      if (data.status === 202) {
        dispatch(phoneNumberAvailable());
        handleShowIsLoading(false);
      } else {
        dispatch(phoneNumberRegistered(data));
        handleShowIsLoading(false);
      }
    },
    (error) => {
      dispatch(checkPhoneNumberFailed(error));
      handleShowIsLoading(false);
    }
  );
};

const phoneNumberAvailable = () => {
  return { type: "PHONE_NUMBER_AVAILABLE" };
};

const phoneNumberRegistered = (data) => {
  return { type: "PHONE_NUMBER_REGISTERED", payload: data.message };
};

const checkPhoneNumberFailed = (errors) => {
  return { type: "CHECK_PHONENUMBER_FAILED", payload: errors };
};

export const connectAccount = (data, token) => (dispatch) => {
  userService.connectAccount(data, token).then(
    (data) => {
      if (data.status === 202) dispatch(connectAccountSuccess());
      else dispatch(connectAccountFailed(data.message));
    },
    (error) => {
      dispatch(connectAccountFailed(error));
    }
  );
};

const connectAccountSuccess = () => {
  return { type: "CONNECT_ACCOUNT_SUCCESS" };
};

const connectAccountFailed = (errors) => {
  return { type: "CONNECT_ACCOUNT_FAILED", payload: errors };
};

export const getPrimaryBank = (token) => (dispatch) => {
  userService.getPrimaryBank(token).then(
    (data) => {
      if (data.status === 200) dispatch(getPrimaryBankSuccess(data.data));
      else dispatch(getPrimaryBankFailed(data.message));
    },
    (error) => {
      dispatch(getPrimaryBankFailed(error));
    }
  );
};

const getPrimaryBankSuccess = (data) => {
  return { type: "GET_PRIMARY_BANK_SUCCESS", payload: data };
};

const getPrimaryBankFailed = (errors) => {
  return { type: "GET_PRIMARY_BANK_FAILED", payload: errors };
};

export const putPrimaryBank = (data, token) => (dispatch) => {
  userService.putPrimaryBank(data, token).then(
    (data) => {
      if (data.status === 200) dispatch(putPrimaryBankSuccess());
      else dispatch(putPrimaryBankFailed(data.message));
    },
    (error) => {
      dispatch(putPrimaryBankFailed(error));
    }
  );
};

const putPrimaryBankSuccess = () => {
  return { type: "PUT_PRIMARY_BANK_SUCCESS" };
};

const putPrimaryBankFailed = (errors) => {
  return { type: "PUT_PRIMARY_BANK_FAILED", payload: errors };
};
