import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ProgressBar } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

class Statusbar extends Component {

  proceedClearance = () => {
    let { balance, clearance } = this.props;
    if (balance.data.available_cash < clearance.data[0].clearance_amount) {
      this.props.resetClearanceDetail();
      this.setState({ isEnoughCash: false })
    }
  }

  render() {
    let {
      balance,
      init: { language }
    } = this.props;

    return (
      <>
        <div className="statusbar">
          <div className="card dash-card">
            <div className="card-body">
              <div className="wrapper">
                <div className="left">
                  <div className="progress-wrapper">
                    <div className="text-wrapper">
                      <span className="text">
                        {language.label.availableCredit}
                      </span>
                      <span className="num">
                        <NumberFormat
                          value={balance.data ? balance.data.available_credit : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalSeparator="."
                          decimalScale={2}
                        />
                      </span>
                    </div>
                    <ProgressBar now={balance.data ? balance.data.available_credit : 0} />
                  </div>
                  <div className="status-text">
                    <span className="text">
                      {language.label.totalRequested}
                    </span>
                    <span className="num">
                      Rp{" "}
                      <NumberFormat
                        value={balance.data ? balance.data.total_request : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </span>
                  </div>
                  <div className="status-text">
                    <span className="text">
                      {language.label.avgRate}
                    </span>
                    <span className="num">
                      <NumberFormat
                        value={balance.data ? balance.data.avg_rate : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </span>
                  </div>
                </div>
                <div className="right">
                  <span className="btn-view-req">
                    <Link to="/viewloan">{language.label.viewReqLoan}</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  balance: state.balance
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Statusbar);