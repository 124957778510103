import React, { useState, useEffect } from "react";
import { DashboardLayoutKoinInvoiceStyle } from "./style";
import history from "../util/history";
import iconDashboard from "../assets/images/icon-menu-button-dashboard/icon-dashboard.png";
import iconDashboardDisabled from "../assets/images/icon-menu-button-dashboard/icon-dashboard-disabled.png";
import iconDashboardTwo from "../assets/images/icon-menu-button-dashboard/icon-dashboard-two.png";
import iconDashboardTwoDisabled from "../assets/images/icon-menu-button-dashboard/icon-dashboard-two-disabled.png";
import iconDashboardFour from "../assets/images/icon-menu-button-dashboard/icon-dashboard-four.png";
import iconDashboardFourDisabled from "../assets/images/icon-menu-button-dashboard/icon-dashboard-four-disabled.png";
import iconDashboardThreeDisabled from "../assets/images/icon-menu-button-dashboard/icon-dashboard-three-disabled.png";
import { connect } from "react-redux";
import koinvoiceServices from "../services/koinvoice";

function DashboardLayoutKoinInvoice({ init: { language }, children }) {
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    getTabActive();
    koinvoiceServices.actionGetCreditLimitLoan({}).then((res) => {
      if (res?.status === 200) {
        localStorage.setItem("creditLimitLoan", JSON.stringify(res.data));
      }
    });
  }, []);

  function getTabActive() {
    const routeTab = window.location.pathname;
    const splitRoute = routeTab.split("/");
    const tabActive = splitRoute[splitRoute.length - 1];
    setActiveTab(tabActive);
  }

  return (
    <DashboardLayoutKoinInvoiceStyle>
      <div className="kw-mainLayout-wrapper">
        {children}
        <div className="kw-mainLayout-wrapper__second-wrapper">
          {" "}
          <div className="kw-mainLayout-wrapper__second-wrapper-hideDesktop">
            <div
              className={
                activeTab === "dashboard-limit-application"
                  ? "kw-mainLayout-wrapper__active-link"
                  : "kw-mainLayout-wrapper__not_active-link"
              }
              onClick={() => history.push("dashboard-limit-application")}
            >
              <div className="menu-dashboard-layout">
                <img
                  src={
                    activeTab === "dashboard-limit-application"
                      ? iconDashboard
                      : iconDashboardDisabled
                  }
                  alt=""
                  style={{ width: 15, marginLeft: "auto", marginRight: "auto" }}
                />{" "}
                <span>{language.loanDashboard.dashboard}</span>
              </div>
            </div>
            {["rejected_temporary", "rejected_permanently"].includes(
              JSON.parse(localStorage.getItem("creditLimitLoan")) !== null &&
                JSON.parse(localStorage.getItem("creditLimitLoan")).status
            ) ? undefined : (
              <div
                className={
                  activeTab === "schedule"
                    ? "kw-mainLayout-wrapper__active-link"
                    : "kw-mainLayout-wrapper__not_active-link"
                }
                onClick={() => history.push("/schedule")}
              >
                <div className="menu-dashboard-layout">
                  <img
                    src={
                      activeTab === "schedule"
                        ? iconDashboardTwo
                        : iconDashboardTwoDisabled
                    }
                    alt=""
                    style={{
                      width: 15,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />{" "}
                  <span>{language.loanDashboard.schedule}</span>
                </div>
              </div>
            )}
            <div
              className={
                activeTab === "education"
                  ? "kw-mainLayout-wrapper__active-link"
                  : "kw-mainLayout-wrapper__not_active-link"
              }
              onClick={() => history.push("/education")}
            >
              <div className="menu-dashboard-layout">
                <img
                  src={
                    activeTab === "education"
                      ? iconDashboardThreeDisabled
                      : iconDashboardThreeDisabled
                  }
                  alt=""
                  style={{ width: 15, marginLeft: "auto", marginRight: "auto" }}
                />{" "}
                <span>{language.loanDashboard.education}</span>
              </div>
            </div>
            {["rejected_temporary", "rejected_permanently"].includes(
              JSON.parse(localStorage.getItem("creditLimitLoan")) !== null &&
                JSON.parse(localStorage.getItem("creditLimitLoan")).status
            ) ? undefined : (
              <div
                className={
                  activeTab === "document-list"
                    ? "kw-mainLayout-wrapper__active-link"
                    : "kw-mainLayout-wrapper__not_active-link"
                }
                onClick={() => 
                  // history.push(
                  //   activeTab === "dashboard-limit-application"
                  //     ? "/document-list-limit"
                  //     : "/document-list-loan"
                  // )
                  ( 
                    history.push("/document-list-limit")
                  )
                }
              >
                <div className="menu-dashboard-layout">
                  <img
                    src={
                      activeTab === "document-list"
                        ? iconDashboardFour
                        : iconDashboardFourDisabled
                    }
                    alt=""
                    style={{
                      width: 15,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />{" "}
                  <span>{language.loanDashboard.document}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayoutKoinInvoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
});

export default connect(mapStateToProps, {})(DashboardLayoutKoinInvoice);
