import React from "react";
import { useState } from "react";
import { Input, Card, Button, Spin } from "antd";
import { DocumentContractStyle } from "./style";

const { Search } = Input;

function DocumentContract({
  applicationList,
  actionChangeShowDashboard,
  isShowLoading,
}) {
  const [filters, setFilters] = useState("");
  const lowercasedFilter = filters.toLowerCase();
  const filteredData =
    applicationList !== undefined &&
    applicationList.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });

  function actionChangeSearch(event) {
    setFilters(event.target.value);
  }

  return (
    <Spin spinning={isShowLoading}>
      <DocumentContractStyle>
        <Card
          style={{
            height:
              applicationList !== undefined && applicationList.length > 5
                ? "auto"
                : "100vh",
          }}
        >
          <Button
            size="large"
            style={{ marginBottom: 25 }}
            onClick={() => actionChangeShowDashboard("")}
          >
            Back
          </Button>
          <div className="kw-documentContract-wrapper">
            <Search
              placeholder="Search document..."
              onChange={actionChangeSearch}
              size="large"
            />
            {applicationList !== undefined ? (
              filteredData.map((item, index) => {
                return (
                  <div className="kw-documentContract-wrapper__cards">
                    <strong>Loan Contract {item.code}</strong>
                    <span className="kw-documentContract-wrapper__labelId">
                      Loan ID : {item.id}
                    </span>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50%",
                }}
              >
                <img src={require("../assets/images/notfound.png").default} alt="" />
              </div>
            )}
          </div>
        </Card>
      </DocumentContractStyle>
    </Spin>
  );
}

export default DocumentContract;
