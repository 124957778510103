import React from "react";
import Select from "@appgeist/react-select-material-ui";

const SimpleSelectInput = ({
  input,
  defaultValue,
  options,
  name,
  id,
  label,
  meta: { touched, error }
}) => (
  <div className={`${touched && error && "invalid"}`}>
    <Select
      {...input}
      id={id}
      name={name}
      label={label}
      placeholder={label}
      options={options}
      value={input.value}
      defaultValue={defaultValue}
      onChange={value => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      isClearable
    />
    {touched && error && <div className="invalid-feedback"> {error} </div>}
  </div>
);

export default SimpleSelectInput;