function salaryAdvance(plafond, status) {
  let percentage = (status === "internal") ? 0.2 : 0.3;
  let fee = plafond * ( percentage / 100);
  return [fee, plafond - fee];
}

function regular(status, eStatus, plafond, term, data) {
  let { origination, insurance, admin } = data;
  let insure = 0.0;
  let origin = 0.0;
  let monInterest = 0.0;
  let total_disburse = 0.0;
  let installment = 0.0;
  let total_fee = 0.0;
  term = parseFloat(term);
  plafond = parseFloat(plafond);
  if (status === "internal") {
    installment = (plafond + (plafond * (term * (9.0 / 100.0) / 12.0))) / term;
  } else {
    if (term === 3)
      insure = insurance.insurance_1;
    else if (term === 6)
      insure = insurance.insurance_2;
    else if (term === 9)
      insure = insurance.insurance_3;
    else if(term >= 12 && term <= 24)
      insure = insurance.insurance_4;

    if (term >= 3 && term <= 9)
      origin = origination.origination_1;
    else if (term >= 12 && term <=24)
      origin = origination.origination_2;

    //fee calculation
    let origin_fee = plafond * ( origin / 100 );
    let insurance_fee = plafond * ( insure / 100 );
    total_fee = insurance_fee + origin_fee + admin.admin_fee;

    //interest calculation
    if (data.monthly_interest.fix === 0){
      if (eStatus === "contract")
        monInterest = data.monthly_interest.contract;
      else if (eStatus === "permanent")
        monInterest = data.monthly_interest.permanent;
      else if (eStatus === "outsource")
      monInterest = data.monthly_interest.outsource;
    } else {
      monInterest = data.monthly_interest.fix;
    }

    total_disburse = plafond - total_fee;
    installment = (plafond + (plafond * (monInterest / 100) * term)) / term;
  }

  return [total_disburse, installment.toFixed(3), total_fee];
}

export const calculation = {
  regular,
  salaryAdvance
};