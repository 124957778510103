import styled from "styled-components";

export const CashKoinStyle = styled.div`
  .kw-cashKoinWrapper {
    span {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: -0.24px;
      text-transform: uppercase;
      color: #8e8e8e;
    }
    strong {
      display: block;
      font-weight: 555;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.38px;
      color: #566068;
    }
  }
`;

export const DashboardKoinInvoiceStyle = styled.div`
  .kw-dashboardKinInvoiceWrapper {
    background: #e5e5e5;
    height: auto;
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link {
      font-size: 15px;
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border: unset;
      transform:unset;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      color: #2b486d;
      background-color: unset;
      border: unset;
      font-weight: normal;
      text-decoration: underline;
      padding: 0.5rem 1rem;
    }
    @media screen and (max-width: 800px) {
      iframe {
        width: 100% !important;
      }
    }
    &__list-card-documentListPage {
      border-bottom: 1px solid #cccccc;
      border-top: 1px solid #cccccc;
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    iframe {
      height: 700px;
      width: 520px;
    }
  }
  .kw-scheduleList-wrapper {
    &__momenths {
      font-weight: bold;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #2b486d;
    }
    &__total-due {
      font-weight: bold;
      font-size: 15px;
      letter-spacing: -0.24px;
      color: #878787;
    }
  }
`;

export const AvailableCreditProgressStyle = styled.div`
  .kw-availableCredit-wrapper {
    align-items: center;
  }
  .kw-availableCredit-viewRequestedLoans {
    font-size: 17px;
    text-align: center;
    margin-top: 25px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #378df4;
  }
  .kw-availableCredit-used {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    color: #8e8e8e;
  }
  span {
    color: #41af60;
  }
  strong {
    display: block;
  }
  .kw-availableCreedit-availableCredit {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
  }
  .kw-availableCreedit-currency {
    font-style: normal;
    font-weight: 555;
    font-size: 18px;
    text-align: right;
    letter-spacing: 0.35px;
    display: block;
  }
`;

export const ApplicationProgressStyle = styled.div``;
