const hostNameCheck = window.location.host;

//condition if koininvoice

let checkConditionHost = [
  "koininvoice.com",
  "staging.koininvoice.com",
  "yuaiti.koininvoice.com",
  "koinemployee-yuaiti.koin.works",
  "koininvoice.koinp2p.com",
  "uat.koininvoice.com",
  "localhost:3000", // condition if koininvoice local your computer remove if no need koininvoice
  "koininvoice-staging.koin.works",
  "koininvoice-uat.kawe.works",
].includes(hostNameCheck);

export const hostNameCheckCondition = checkConditionHost;
