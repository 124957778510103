import styled from "styled-components";

export const CardDocumentListSyle = styled.div`
  .kw-cardDocument-list-wrapper {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(8, 35, 48, 0.24);
    border-radius: 3px;
    padding: 25px;
    margin: 10px 0px 10px 0px;
    strong {
      font-weight: 555;
      font-size: 17px;
      letter-spacing: 0.38px;
      color: #566068;
    }
    &__label {
      display: flex;
      justify-content: space-between;
    }
    &__label-second {
      margin-top: 25px;
      display: block;
      font-size: 15px;
      letter-spacing: -0.24px;
      color: #cccccc;
    }
  }
`;

export const DocumentListStyle = styled.div`
  .kw-documentList {
    margin-top: 25px;
    margin-bottom: 25px;
    &__redirect-submission {
      background: #60b5df;
      color: #ffffff;
      width: 100%;
      border-radius: 3px;
    }
    &__send-link {
      margin-top: 15px;
      cursor: pointer;
      font-size: 17px;
      width: 100%;
      letter-spacing: -0.41px;
      text-decoration-line: underline;
      color: #378df4;
    }
    strong {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #566068;
    }
  }
`;

export const DocumentContractStyle = styled.div`
  .kw-documentContract-wrapper {
    &__cards {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(8, 35, 48, 0.24);
      border-radius: 3px;
      padding: 25px;
      margin-top: 15px;
    }
    strong {
      display: block;
      font-weight: 555;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    &__labelId {
      display: block;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    &__expired-at {
      font-size: 14px;
      letter-spacing: -0.41px;
      color: #cccccc;
    }
    &__view-list {
      font-size: 14px;
      cursor: pointer;
      line-height: 22px;
      text-align: right;
      letter-spacing: -0.41px;
      text-decoration-line: underline;
      color: #378df4;
    }
  }
`;
