const APP_GUARANTOR = "v1/assessments/app/guarantor";
const APP_GUARANTOR_CREATE = "v1/assessments/app/guarantor/create";
const APP_GUARANTOR_UPDATE = "v1/assessments/app/guarantor/update/";
const APP_GUARANTOR_DELETE = "v1/assessments/app/guarantor/user-sibling/";
const APP_DISBURSEMENT_BANK = "v1/assessments/bank-disbursement";
const APP_CONTRACT_LIMIT = "v1/assessments/contract/limit-application";
const GET_POST_BANK_VALIDATION = "v1/assessments/bank-validation";
const APP_CONTRACT_LIMIT_POST = "v1/assessments/contract/limit-application/generate"
const THIRD_PARTY_OY = "v1/thirdparty/oy/bank-account-inquiry";
const CREATE_ASSESSMENT = "v1/assessments/create";
const DOCUMENT_COMPLETE = "v1/assessments/document/complete";
const GET_PRINCIPAL_ACCOUNT= "v1/koinrevolver/principal/detail"
const GET_INDUSTRY_LIST= "v1/assessments/industry/list"
const GET_USER_INDUSTRY_LIST= "v1/assessments/industry/user?user_id="
const CREATE_UPDATE_USER_INDUSTRY= "v1/assessments/industry/user"

export const PATH_KOINVOICE_ASSESSMENTS = {
  DOCUMENT_COMPLETE,
  CREATE_ASSESSMENT,
  APP_GUARANTOR,
  APP_GUARANTOR_CREATE,
  APP_DISBURSEMENT_BANK,
  APP_GUARANTOR_UPDATE,
  APP_GUARANTOR_DELETE,
  APP_CONTRACT_LIMIT_POST,
  APP_CONTRACT_LIMIT,
  THIRD_PARTY_OY,
  GET_POST_BANK_VALIDATION,
  GET_PRINCIPAL_ACCOUNT,
  GET_INDUSTRY_LIST,
  GET_USER_INDUSTRY_LIST,
  CREATE_UPDATE_USER_INDUSTRY
};
