import React, { Component } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

import LDLoanImg from "../../../assets/images/dashboard/ldloan.png"
import PendingImg from "../../../assets/images/dashboard/pendingimg.png"


class LDLoan extends Component {
  render() {
    let { init: { language }, loan } = this.props;
    return (
      <>
        <div className="ldcomp">
          <div className="heading">
            <div className="title">Loan</div>
            <div className="text">Business Expansion Loan</div>
            <figure className="imgLogo">
              <img src={LDLoanImg} alt="loan" />
            </figure>
          </div>

          <div className="warning-box">
            <div className="media">
              <img src={PendingImg} className="align-self-start mr-3" alt="pending" />
              <div className="media-body">
                <h5 className="title">Pending Clearance</h5>
                <p className="text">Please transfer <strong>IDR 50,100,000</strong> before  26 May 2019, 13.55</p>
                <a href="http://" target="_blank" rel="noopener noreferrer" className="d-block text-right link">Go to Transfer Instruction ></a>
              </div>
            </div>
          </div>
          {loan.loanDetail && (
            <div className="row-wrapper">
              <div className="text-wrapper">
                <div className="remaining">
                  <p className="title">{language.label.requestDate}</p>
                  <p className="text">
                    {new Date(loan.loanDetail.request_date).toLocaleDateString("ID-id",
                      { year: 'numeric', month: 'short', day: 'numeric' }
                    )}
                  </p>
                </div>
                <div className="total">
                  <p className="title">{language.label.disburseDate}</p>
                  <p className="text">
                    {loan.loanDetail.disburse_date ? 
                      new Date(loan.loanDetail.disburse_date).toLocaleDateString("ID-id",
                      { year: 'numeric', month: 'short', day: 'numeric' })
                      : "-"
                    }
                  </p>
                </div>
              </div>

              <div className="text-wrapper">
                <div className="remaining">
                  <p className="title">{language.label.totalLoanAmount}</p>
                  <p className="text">IDR &nbsp;
                    <NumberFormat
                      value={loan.loanDetail.total_loan_amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator="."
                      decimalScale={2}
                    />
                  </p>
                </div>
                <div className="total">
                  <p className="title">{language.label.loanTerm}</p>
                  <p className="text">{loan.loanDetail.tenure} {language.label.months}</p>
                </div>
              </div>
              <div className="text-wrapper">
                <div className="remaining">
                  <p className="title">{language.label.grade}</p>
                  <p className="text">{loan.loanDetail.risk_grade}</p>
                </div>
                <div className="total">
                  <p className="title">{language.label.rate}</p>
                  <p className="text">
                    <NumberFormat
                      value={loan.loanDetail.rate}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator="."
                      decimalScale={2}
                    /> %
                  </p>
                </div>
              </div>
              <div className="text-wrapper">
                <div className="remaining">
                  <p className="title">{language.label.remainingDue}</p>
                  <p className="text">0</p>
                </div>
                <div className="total">
                  <p className="title">{language.label.lender}</p>
                  <p className="text">{loan.loanDetail.total_lender}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  loan: state.loan
});

export default connect(mapStateToProps, null)(LDLoan);