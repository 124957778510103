import React, { useState } from "react";
import { Card, Button, Alert } from "antd";
import { WaitingForContractStyle } from "../../WaitingForContract/style";
// import FilerServer from "file-saver";
import moment from "moment";
import koinvoiceServices from "../../services/koinvoice";


function WaitingForContractLimit({
  waitingForContract,
  actionChangeShowDocumentList,
  language,
}) {

  const businessInfo = JSON.parse(localStorage.getItem("businessInformation"))["data"]
  /* Helper function */
  
  // function download_file(fileURL, fileName) {
  //   var oReq = new XMLHttpRequest();
  //   // The Endpoint of your server
  //   var URLToPDF = fileURL;
  //   // Configure XMLHttpRequest
  //   oReq.open("GET", URLToPDF, true);
  //   // Important to use the blob response type
  //   oReq.responseType = "blob";

  //   // When the file request finishes
  //   // Is up to you, the configuration for error events etc.
  //   oReq.onload = function () {
  //     // Once the file is downloaded, open a new window with the PDF
  //     // Remember to allow the POP-UPS in your browser
  //     let file = new Blob([oReq.response], {
  //       type: "application/pdf",
  //     });
  //     // Generate file download directly in the browser !
  //     FilerServer.saveAs(file, fileName);
  //   };
  //   oReq.send();
  // }

  async function actionSign(businessInfo) {
    setDigisignLoading(true)
    try {
      const params = {
        loan_id: 0,
        user_id: parseInt(businessInfo.user_id),
        product: "a1fb40e7-e9c5-11e9-97fa-00163e010bca"
      }

      const res = await koinvoiceServices.postContractDigisign({ params })

      if (res.status < 300 && res?.data && res.data?.notif) {
        window.open(res.data.notif, "_blank")
      } else {
        throw res.hasOwnProperty("errors") 
          ? res?.errors[0] 
          : { internalMessage: "Unexpected error occured" }
      }
    } catch (error) {
      setDigisignError(`${error.internalMessage}`)
      setAlertDigisign(true)
    } finally {
      setDigisignLoading(false)
    }
  }

  const tenDaysInFuture = (date) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 10);
    return newDate;
  };

  let date = moment().format("YYYY-MM-DD");
  let tenday = tenDaysInFuture(new Date(date));

  const [alertDigisign, setAlertDigisign] = useState(false);
  const [digisignLoading, setDigisignLoading] = useState(false);
  const [digisignError, setDigisignError] = useState("");

  const handleCloseAlertDigisign = () => {
    setAlertDigisign(false);
  };

  return (
    <Card>
      <div
        style={{
          borderBottom: "1px solid #CCCCCC",
          marginBottom: 20,
        }}
      >
        <div>
          {" "}
          <Button size="large" onClick={() => actionChangeShowDocumentList()}>
            {language.button.back}
          </Button>
        </div>
        <h4 style={{ textAlign: "center" }}>{language.title.signContract}</h4>
      </div>
      {["borrower_download"].includes(waitingForContract.contract_type) && (
        <WaitingForContractStyle>
          <div className="kw-waitingFor-contract">
            <strong className="kw-waitingFor-contract__label">
              <p style={{ marginBottom: 0, lineHeight: 1.5 }}>
                {language.text.signContractText}
              </p>
              {/* {moment(tenday).format("DD MMM YYYY")} */}
            </strong>
            {alertDigisign && (
              <Alert 
                type="error"
                message={digisignError}
                closable
                afterClose={handleCloseAlertDigisign}
              />
            )}
            {waitingForContract &&
              waitingForContract.data.map((data, index) => {
                return (
                  <div
                    className="kw-waitingFor-contract__card"
                    style={{ marginTop: 22 }}
                    key={index}
                  >
                    <strong>
                      {data.contract_name}
                      {/*Credit Agreement {""} {waitingForContract &&
                          moment(waitingForContract.last_updated_on).format(
                            "DD/MM/YYYY"
                          )} */}
                    </strong>
                    {/* <p>Loan ID : {waitingForContract}</p> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 22,
                      }}
                    >
                      {/* <Button
                        size="large"
                        onClick={() =>
                          actionChangeDownloadOrSign(
                            data.contract_url,
                            data.contract_url.split("/").pop()
                          )
                        }
                        className="kw-button"
                      >
                        {"Download"}
                      </Button> */}
                      <Button
                        size="large"
                        onClick={() => actionSign(businessInfo)}
                        style={{
                          backgroundColor: "#2b476c",
                          borderColor: "#2b476c",
                          color: "#ffffff"
                        }}
                        loading={digisignLoading}
                      >
                        {language.text.signContractButton}
                      </Button>
                    </div>
                  </div>
                );
              })}
          </div>
        </WaitingForContractStyle>
      )}
      {["manual_digisign"].includes(waitingForContract.contract_type) && (
        <div style={{ height: "50vh", paddingTop: 111 }}>
          <p style={{ color: "#566068", fontSize: 17, fontWeight: 500 }}>
            We will use digital signature for signing process
          </p>
          <p style={{ color: "#566068", fontSize: 17, fontWeight: 500 }}>
            Please check your email regularly for the contract.
          </p>
        </div>
      )}
    </Card>  
  );
}

export default WaitingForContractLimit;
