import React, { useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import { connect } from "react-redux";
import moment from "moment";

function UserConsent({
  init: { language, flag },
  visible,
  handleChangeVisible,
  businessInformation,
  actionConfirmRequest,
}) {
  const borrower_name = businessInformation?.data?.details?.business
    .company_detail?.president_director_name?.value
    ? businessInformation?.data?.details?.business.company_detail
        ?.president_director_name?.value
    : businessInformation?.data?.details?.business.company_detail
          ?.active_persero_name?.value
      ? businessInformation?.data?.details?.business.company_detail
          ?.active_persero_name?.valu
      : businessInformation?.data?.details?.business.company_detail
            ?.business_principal_fullname?.value
        ? businessInformation?.data?.details?.business.company_detail
            ?.business_principal_fullname?.value
        : businessInformation?.data?.details?.business.company_detail
              ?.owner_name?.value
          ? businessInformation?.data?.details?.business.company_detail
              ?.owner_name?.value
          : businessInformation?.data?.fullname
            ? businessInformation?.data?.fullname
            : "-";
  const [isAgree, setIsAgree] = useState(false);
  function handleChangeIsAgree(e) {
    setIsAgree(e.target.checked);
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={handleChangeVisible}
      width="700px"
      maskClosable={false}
    >
      <h4>
        <u>{language.title.userConsentTitle}</u>
      </h4>
      <br />
      <p align="justify">{language.text.userConsentParagraph1}</p>
      <ol type="a">
        <li align="justify">{language.text.userConsentPointA}</li>
        <li align="justify">{language.text.userConsentPointB}</li>
        <li align="justify">{language.text.userConsentPointC}</li>
      </ol>
      <p align="justify">{language.text.userConsentParagraph2}</p>
      <p align="justify">{language.text.userConsentParagraph3}</p>
      <br />
      <br />
      <p>{language.text.userConsentAgreeing}</p>
      <p>{moment(new Date()).locale(flag).format("DD MMMM YYYY")}</p>
      <p>{borrower_name}</p>
      <br />
      <br />
      <Checkbox
        id="is_agree_consent_letter"
        name="is_agree_consent_letter"
        checked={isAgree}
        onChange={handleChangeIsAgree}
      >
        {language.text.userConsentCheckbox}
      </Checkbox>
      <div style={{ display: "flex" }}>
        <Button
          id="letter_submit"
          style={{ marginLeft: "auto" }}
          onClick={actionConfirmRequest}
          disabled={!isAgree}
          type="primary"
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
});

export default connect(mapStateToProps)(UserConsent);
