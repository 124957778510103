import styled from "styled-components";

export const DisbursementBankAccountStyle = styled.div`
  .kw-disbursementBank-wrapper {
    .ant-form-item {
      display: unset !important;
    }
    h3 {
      font-size: 28px;
      line-height: 34px;
      align-items: center;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
    p {
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    .kw-button {
      background-color: #2b476c;
      border-color: #2b476c;
      color: #ffffff;
      width: 100%;
      margin-top: 25px;
    }
    .kw-button-disabled {
      width: 100%;
      margin-top: 25px;
    }
  }
`;
