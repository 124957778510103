import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Breadcrumb } from "react-bootstrap";

class DashTitle extends Component {



  render() {
    let {
      init: { language }
    } = this.props;

    return (
      <>
        <div className="dashboard-title">
          <Breadcrumb>
            <Breadcrumb.Item active href="#">{language.label.home}</Breadcrumb.Item>
          </Breadcrumb>
          <h3 className="title">
            {language.label.dashboard}
          </h3>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashTitle);