import React, { useState } from "react";
import { SecurityCodeConfirmPageWrapper } from "./SecurityCodeConfirmPage.styles";
import OTPInput, { ResendOTP } from "otp-input-react";

import { Modal } from "react-bootstrap";

import imgSuccess from "../assets/images/success.png";

function SecurityCodeConfirmPage() {
  const [OTP, setOTP] = useState("");
  const renderButton = buttonProps => {
    return (
      <p className="mb-0" {...buttonProps}>
        {buttonProps.remainingTime !== 0
          ? `Resend code in ${buttonProps.remainingTime} sec`
          : "Resend"}
      </p>
    );
  };
  const renderTime = () => React.Fragment;

  // modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <SecurityCodeConfirmPageWrapper>
      <div className="page-content-wrapper">
        <div className="card">
          <figure className="logo-wrapper">
            {/* <img src={logoimg} alt="" className="logo-img" /> */}
          </figure>
          <div className="back-button">
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
            <span>Back</span>
          </div>
          <div className="card-body register-body">
            <h2 className="register-title">Confirm Security Code</h2>
            <p className="text-center text-note">
              Insert your Security Code below to login to your account.
            </p>
            <form className="" noValidate autoComplete="off">
              <div className="input-wrapper">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  className="otp-input"
                />
                <div className="resend-wrapper">
                  {/* <ResendOTP
                    handelResendClick={() => console.log("Resend clicked")}
                    className="resend-btn"
                  /> */}
                  <ResendOTP
                    renderButton={renderButton}
                    renderTime={renderTime}
                    className="resend-btn"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                {/* note: remove btn-disable saat opt di input */}
                {/* note: add onclick untuk test */}
                <div
                  className="btn btn-primary btn-disable w-100"
                  onClick={handleShow}
                >
                  NEXT
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modal-wrapper">
              <figure className="status-img">
                <img src={imgSuccess} alt="" />
              </figure>
              {/* note register */}
              <div className="modal-text">
                <h2 className="modal-title">Personal Account Created</h2>
                <p className="modal-desc">
                  Please verify your email (
                  <span className="modal-email">
                    fitri.febriana@koinworks.com
                  </span>
                  ) through the verification link we have sent.
                </p>
              </div>
              {/* note login */}
              {/* <div className="modal-text">
                <h2 className="modal-title">Signed in to Personal Account</h2>
                <p className="modal-desc">
                  To complete your loan application, please provide these
                  information.
                </p>
              </div> */}
              <p className="modal-note">
                <small>Redirecting...</small>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </SecurityCodeConfirmPageWrapper>
  );
}

export default SecurityCodeConfirmPage;
