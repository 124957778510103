import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LDLoan from "./LoanDetailComp/LDLoan";
import LDProgress from "./LoanDetailComp/LDProgress";
import LDSchedule from "./LoanDetailComp/LDSchedule";
import LDList from "./LoanDetailComp/LDList";
import LDDocs from "./LoanDetailComp/LDDocs";



class LoanDetail extends Component {
  render() {
    let { language, onClose } = this.props;
    return (
      <>
        <div className="side-modal-wrapper">
          <div className="top">
            <h3 className="side-modal-title">#BL-ES1-6578</h3>
            {/* misalnya butuh yg controlled, kalau gak butuh hapus aja */}
            {/* <Tabs id="controlled-tab-example" activeKey={key} onSelect={k => setKey(k)}>
              <Tab eventKey="home" title="Home">
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minus repellat dolorem adipisci incidunt quos eos laboriosam, totam ab quaerat, fugiat quod quibusdam sed quam voluptates dolorum error libero, enim tempora.</p>
              </Tab>
              <Tab eventKey="profile" title="Profile">
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minus repellat dolorem adipisci incidunt quos eos laboriosam, totam ab quaerat, fugiat quod quibusdam sed quam voluptates dolorum error libero, enim tempora.</p>
              </Tab>
              <Tab eventKey="contact" title="Contact" disabled>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minus repellat dolorem adipisci incidunt quos eos laboriosam, totam ab quaerat, fugiat quod quibusdam sed quam voluptates dolorum error libero, enim tempora.</p>
              </Tab>
            </Tabs> */}

            <Tabs defaultActiveKey="loan" id="loan-tab">
              <Tab eventKey="loan" title="Loan">
                <LDLoan />
              </Tab>
              <Tab eventKey="progress" title="Progress">
                <LDProgress />
              </Tab>
              <Tab eventKey="schedule" title="Schedule">
                <LDSchedule />
              </Tab>
              <Tab eventKey="lenderlist" title="Lender List">
                <LDList />
              </Tab>
              <Tab eventKey="documents" title="Documents">
                <LDDocs />
              </Tab>
            </Tabs>

            {/* <div className="modal-img-wrapper">
              <figure className="modal-img mb-4">
                <img src="" alt="success" />
              </figure>
              <p className="side-modal-text w-75">Thank you! 50 lenders are happy because of your repayment.</p>
            </div> */}
          </div>
          <div className="bottom text-center">
            <div className="line my-3"></div>
            <div className="btn-modal-cancel" onClick={onClose}>{language.button.close}</div>
          </div>
          {/* <div className="bottom text-right">
            <div className="line my-3"></div>
            <div className="btn-modal-cancel">Cancel</div>
            <div className="btn-modal-proceed">Download Payoff Letter</div>
          </div> */}
        </div>
      </>
    );
  }
}

export default LoanDetail;