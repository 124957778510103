import React, { Component } from "react";
import { connect } from "react-redux";
import { SolutionWrapper } from "./Solution.styles";
import { Link } from "react-router-dom";

// icon
import iconAllkind from "../../../assets/images/icon/allkind.svg";
import iconCashflow from "../../../assets/images/icon/cashflow.svg";
import iconProcess from "../../../assets/images/icon/process.svg";
import iconCreateProject from "../../../assets/images/icon/Group2501.svg";
import iconFinancingApplication from "../../../assets/images/icon/employee.svg";
import iconLoanOffer from "../../../assets/images/icon/loan-offer.svg";
import { hostNameCheckCondition } from "../../../library/checkHostNameWeb";

class Solution extends Component {
  render() {
    let {
      init: { language },
    } = this.props;
    return (
      <SolutionWrapper>
        <section className="solution" id="solution">
          <div className="container">
            <h2 className="heading">
              {hostNameCheckCondition
                ? language.homepage.strategicSolutionTitleInvoice
                : language.homepage.strategicSolutionTitle}
            </h2>
            <div className="row">
              {hostNameCheckCondition && (
                <div className="col-12 col-sm-4">
                  <div className="content">
                    <figure className="icon-wrapper">
                      <img src={iconCreateProject} alt="employee" />
                    </figure>
                    <div className="text-wrapper">
                      <h3 className="heading">
                        {language.homepage.strategicSolution1TitleInvoice}
                      </h3>
                      <p className="text">
                        {language.homepage.strategicSolution1TextInvoice}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {hostNameCheckCondition && (
                <div className="col-12 col-sm-4">
                  <div className="content">
                    <figure className="icon-wrapper">
                      <img src={iconFinancingApplication} alt="employee" />
                    </figure>
                    <div className="text-wrapper">
                      <h3 className="heading">
                        {language.homepage.strategicSolution2TitleInvoice}
                      </h3>
                      <p className="text">
                        {language.homepage.strategicSolution2TextInvoice}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  hostNameCheckCondition ? "col-12 col-sm-4" : "col-12 col-sm-3"
                }
              >
                <div className="content">
                  <figure className="icon-wrapper">
                    <img src={iconProcess} alt="employee" />
                  </figure>
                  <div className="text-wrapper">
                    <h3 className="heading">
                      {hostNameCheckCondition
                        ? language.homepage.strategicSolution3TitleInvoice
                        : language.homepage.strategicSolution3Title}
                    </h3>
                    <p className="text">
                      {hostNameCheckCondition
                        ? language.homepage.strategicSolution3TextInvoice
                        : language.homepage.strategicSolution3Text}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={
                  hostNameCheckCondition ? "col-12 col-sm-4" : "col-12 col-sm-3"
                }
              >
                <div className="content">
                  <figure className="icon-wrapper">
                    <img src={iconLoanOffer} alt="employee" />
                  </figure>
                  <div className="text-wrapper">
                    <h3 className="heading">
                      {hostNameCheckCondition
                        ? language.homepage.strategicSolution4TitleInvoice
                        : language.homepage.strategicSolution4Title}
                    </h3>
                    <p className="text">
                      {hostNameCheckCondition
                        ? language.homepage.strategicSolution4TextInvoice
                        : language.homepage.strategicSolution4Text}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={
                  hostNameCheckCondition ? "col-12 col-sm-4" : "col-12 col-sm-3"
                }
              >
                <div className="content">
                  <figure className="icon-wrapper">
                    <img src={iconCashflow} alt="employee" />
                  </figure>
                  <div className="text-wrapper">
                    <h3 className="heading">
                      {language.homepage.strategicSolution5TitleInvoice}
                    </h3>
                    <p className="text">
                      {language.homepage.strategicSolution5TextInvoice}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={
                  hostNameCheckCondition ? "col-12 col-sm-4" : "col-12 col-sm-3"
                }
              >
                <div className="content">
                  <figure className="icon-wrapper">
                    <img src={iconAllkind} alt="employee" />
                  </figure>
                  <div className="text-wrapper">
                    <h3 className="heading">
                      {language.homepage.strategicSolution6TitleInvoice}
                    </h3>
                    <p className="text">
                      {language.homepage.strategicSolution6TextInvoice}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div
                className={
                  hostNameCheckCondition ? "col-12 col-sm-4" : "col-12 col-sm-3"
                }
                style={{ display: hostNameCheckCondition ? "" : "none" }}
              >
                <div className="content">
                  <figure className="icon-wrapper">
                    <img src={iconRefer} alt="employee" />
                  </figure>
                  <div className="text-wrapper">
                    <h3 className="heading">
                      {language.homepage.strategicSolution5TitleInvoice}
                    </h3>
                    <p className="text">
                      {language.homepage.strategicSolution5TextInvoice}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{ display: hostNameCheckCondition ? "" : "none" }}
                className="col-12 col-sm-4"
              >
                <div className="content">
                  <figure className="icon-wrapper">
                    <img src={iconInstant} alt="employee" />
                  </figure>
                  <div className="text-wrapper">
                    <h3 className="heading">
                      {language.homepage.strategicSolution6TitleInvoice}
                    </h3>
                    <p className="text">
                      {language.homepage.strategicSolution6TextInvoice}
                    </p>
                  </div>
                </div>
              </div>
            */}
            </div>
            <div className="reg-wrapper">
              <h5 className="heading">
                {hostNameCheckCondition
                  ? language.homepage.areYouKoinInvocieProject
                  : language.homepage.areYouEmployer}
              </h5>
              <Link to="/register">
                <span className="text">
                  {language.homepage.registerHere}{" "}
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </Link>
            </div>
          </div>
        </section>
      </SolutionWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
});

export default connect(mapStateToProps, null)(Solution);
