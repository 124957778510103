import styled from "styled-components";

export const StepWorkWrapper = styled.div`
  background: #f2f2f2;

  .step-title {
    text-align: center;
    letter-spacing: 0.41px;
    color: #2b486d;
    margin-bottom: 20px;
  }
  .search-company {
    .basic-single {
      .select__menu {
        z-index: 1;
        background: white;
      }
    }
  }
  .comp-search {
    position: relative;
    &[aria-expanded="true"] {
      ul {
        list-style: none;
        padding-left: 0;
        border: 1px solid rgba(0, 0, 0, 0.100241);
        box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        position: absolute;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        overflow: auto;
        max-height: 500px;
        margin-top: -15px;
        li {
          text-align: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.100241);

          padding: 10px;
          &:hover {
            color: #ffffff;
            background: #378df4;
            cursor: pointer;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }

    /* div {
        padding: 10px;
        &:hover {
          color: #ffffff;
          background: #378df4;
          cursor: pointer;
        }
      }
    } */
  }
  .comp-notfound {
    text-align: center;
    .img-notfound {
      margin-bottom: 30px;
      margin-top: 25px;
    }
    .notfound-title {
      letter-spacing: 0.38px;
      color: #566068;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
    .notfound-text {
      letter-spacing: -0.41px;
      color: #8e8e8e;
    }
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin: 0.25rem 0 0.4rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dc3545;
    }
    label {
      color: #dc3545;
    }
  }
  .search-company {
    .select__indicators {
      display: none;
    }
  }
  .amount-description {
    margin: 15px 0;
  }
`;
