import { fundingService } from "../../services/funding";

const getFundingSuccess = data => {
  return {
    type: "GET_FUNDING_SUCCESS",
    payload: data
  }
}

const getFundingFailed = errors => {
  return {
    type: "GET_FUNDING_FAILED",
    payload: errors
  }
}

export const getFunding = (token) => (dispatch) => {
  fundingService.getFunding(token)
    .then(res => {
      dispatch(getFundingSuccess(res.data));
    },
    error => {
      dispatch(getFundingFailed(error));
  });
}