import React from "react";
import { Card, Button } from "antd";
import { RejectLimitOverStyle } from "./style";

const RejectLimitOver = ({ creditLimitLoan }) => {
  return (
    <RejectLimitOverStyle>
      <Card>
        <div
          style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
        >
          <img
            src={require("../../assets/images/dashboard/rejected.png").default}
            alt=""
          />
        </div>
        <p style={{ textAlign: "center", color: "#41AF60", fontWeight: 555 }}>
          Good News! You can now re-apply for a limit.
        </p>
        <p style={{ textAlign: "center", color: "#8A8686", fontWeight: 555 }}>
          The waiting period is over!<br/>You are now eligible to apply for a limit again.
        </p>
        <Button className="kw-button" size="large" type="primary">
          APPLY NOW
        </Button>
      </Card>
    </RejectLimitOverStyle>
  );
};

export default RejectLimitOver;
