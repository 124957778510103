import { Steps } from "antd";
import React from "react";
import Icon from "@ant-design/icons";
import { CheckCircle } from "@material-ui/icons";
import { boxOrder, boxOrderNotActive } from "./progress";
import { ProgressLoanStyle } from "../style..js";

const { Step } = Steps;

const ProgressLoan = (props) => {
  const { dataRepayment } = props;
  return (
    <ProgressLoanStyle>
      <div className="kw-progressLoan" style={{ marginTop: 25 }}>
        <strong
          style={{
            display: "block",
            color: "#2B486D",
            fontSize: 25,
          }}
        >
          Loan ID : {dataRepayment && `${dataRepayment.code}`}
        </strong>
        {/* <span>{`${"0/7 Steps Completed"}`}</span> */}
        <Steps direction="vertical">
          <Step
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <span>Waiting for Document</span>
                {/* <span>14 May 2019</span> */}
              </div>
            }
            status={
              dataRepayment.status === "waiting_for_document"
                ? "process"
                : "finish"
            }
            icon={
              dataRepayment.status === "waiting_for_document" ? (
                <Icon component={boxOrder} />
              ) : (
                <CheckCircle />
              )
            }
            description={<div style={{ height: 55 }} />}
          />
          <Step
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Under Review</span>
                {/* <span>16 May 2019</span> */}
              </div>
            }
            status={
              dataRepayment.status === "under_review"
                ? "process"
                : dataRepayment.status !== "waiting_for_document"
                ? "finish"
                : "wait"
            }
            icon={
              dataRepayment.status === "under_review" ? (
                <Icon component={boxOrder} />
              ) : dataRepayment.status !== "waiting_for_document" ? (
                <CheckCircle />
              ) : (
                <Icon component={boxOrderNotActive} />
              )
            }
            description={<div style={{ height: 55 }} />}
          />
          <Step
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Choose Offer</span>
                {/* <span>"Finished"</span> */}
              </div>
            }
            status={
              dataRepayment.status === "choose_offer"
                ? "process"
                : dataRepayment.status === "waiting_for_document" ||
                  dataRepayment.status === "under_review"
                ? "wait"
                : "finish"
            }
            icon={
              dataRepayment.status === "choose_offer" ? (
                <Icon component={boxOrder} />
              ) : dataRepayment.status === "waiting_for_document" ||
                dataRepayment.status === "under_review" ? (
                  <Icon component={boxOrderNotActive} /> 
              ) : (
             <CheckCircle />
              )
            }
            description={<div style={{ height: 55 }} />}
          />
          <Step
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Waiting for Contract</span>
              </div>
            }
            status={
              dataRepayment.status === "waiting_for_contract"
                ? "process"
                : dataRepayment.status === "waiting_for_document" ||
                  dataRepayment.status === "under_review" ||
                  dataRepayment.status === "choose_offer"
                ? "wait"
                : "finish"
            }
            icon={
              dataRepayment.status === "waiting_for_contract" ? (
                <Icon component={boxOrder} />
              ) : dataRepayment.status === "waiting_for_document" ||
                dataRepayment.status === "under_review" ||
                dataRepayment.status === "choose_offer" ? (
                  <Icon component={boxOrderNotActive} /> 
              ) : (
               <CheckCircle />
              )
            }
            description={<div style={{ height: 55 }} />}
          />
          <Step
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Funding Process</span>
              </div>
            }
            status={
              dataRepayment.status === "funding_process"
                ? "process"
                : dataRepayment.status === "waiting_for_document" ||
                  dataRepayment.status === "under_review" ||
                  dataRepayment.status === "choose_offer" ||
                  dataRepayment.status === "waiting_for_contract"
                ? "wait"
                : "finish"
            }
            icon={
              dataRepayment.status === "funding_process" ? (
                <Icon component={boxOrder} />
              ) : dataRepayment.status === "waiting_for_document" ||
                dataRepayment.status === "under_review" ||
                dataRepayment.status === "choose_offer" ||
                dataRepayment.status === "waiting_for_contract" ? (
                  <Icon component={boxOrderNotActive} />   
              ) : (
                <CheckCircle />
              )
            }
            description={<div style={{ height: 55 }} />}
          />
          <Step
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Disbursement Process</span>
              </div>
            }
            status={
              dataRepayment.status === "disbursement_process"
                ? "process"
                : dataRepayment.status === "waiting_for_document" ||
                  dataRepayment.status === "under_review" ||
                  dataRepayment.status ==="choose_offer" ||
                  dataRepayment.status === "funding_process" ||
                  dataRepayment.status === "waiting_for_contract"
                ? "wait"
                : "finish"
            }
            icon={
              dataRepayment.status === "disbursement_process" ? (
                <Icon component={boxOrder} />
              ) : dataRepayment.status === "waiting_for_document" ||
                dataRepayment.status === "under_review" ||
                dataRepayment.status === "choose_offer" ||
                dataRepayment.status === "funding_process" ||
                dataRepayment.status === "waiting_for_contract" ? (
                  <Icon component={boxOrderNotActive} />
              ) : (
                <CheckCircle />
              )
            }
            description={<div style={{ height: 55 }} />}
          />
          <Step
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Ongoing</span>
              </div>
            }
            status={dataRepayment.status === "on_going" ? "finish" : "wait"}
            icon={
              dataRepayment.status === "on_going" ? (
                <CheckCircle />
              ) : (
                <Icon component={boxOrderNotActive} />
              )
            }
            description={<div style={{ height: 55 }} />}
          />
        </Steps>
      </div>
    </ProgressLoanStyle>
  );
};

export default ProgressLoan;
