import api, { setToken } from "./init";
import { getDecodedToken } from "./token";
import history from "../util/history";

// Sends a POST request to /auth/sign-up on the server, with first name, last name, email & password registering the user and returning the JWT
export function signUp({ firstName, lastName, email, password }) {
  return api
    .post("/auth/register", { firstName, lastName, email, password })
    .then(res => {
      const token = res.data.token;
      setToken(token);
      return getDecodedToken();
    })
    .catch(error => {
      return error;
    });
}

// Sends a POST request to /auth on the server, with the email & password returning the JWT
// Belonging to the user with supplied credentials
export function signIn({ email, password }) {
  return api
    .post("/auth/login", { email, password })
    .then(res => {
      const token = res.data.token;
      if (token) {
        setToken(token);
        return getDecodedToken();
      } else {
        return res.data;
      }
    })
    .catch(res => {
      if (res && (res.response.status === 400 || res.response.status === 401)) {
        alert(
          "There was an error with your email or password. Please try again."
        );
      } else {
        setToken(null);
        return { status: 404, message: "user not found" };
      }
    });
}

export function signOut() {
  setToken(null);
}

export function profile() {
  return api
    .get("/auth/profile", { crossdomain: true })
    .then(user => {
      return user.data;
    })
    .catch(err => {
      if (err && err.response && err.response.status === 401) {
        setToken(null);
        history.push("/");
      }
      return null;
    });
}

export function updateProfile(data) {
  return api
    .post("/auth/update", data)
    .then(user => {
      return user.data;
    })
    .catch(err => {
      if (err && err.response && err.response.status === 401) {
        setToken(null);
        window.location.reload();
      }
      return null;
    });
}

export function issuerInfo() {
  return api
    .get("/issuers/info")
    .then(issuer => {
      return issuer.data;
    })
    .catch(err => {
      return null;
    });
}
