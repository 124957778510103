import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import SimpleTextInput from "../../Components/Form/simpleTextInput";
import AsyncSelectInput from "../../Components/Form/asyncSelectInput";
import SimpleSelectInput from "../../Components/Form/simpleSelectInput";
import CurrencyFormatInput from "../../Components/Form/currencyFormatInput";
import { workInfo, getCompanies } from "../../stores/actions/loan";
import { getPersonalProfile, putFinancialProfile } from "../../stores/actions/account";
import { ESTATUS, positions, workingyears } from "../common/data";
import { financialParams } from "../common/convertParams";

import { StepWorkWrapper } from "./StepWork.styles";
import imgNotfound from "../../assets/images/notfound.png";
import history from "../../util/history";
import * as validate from "../../services/validate";

const SelectContainer = props => {
  return <div onKeyUp={props.inputChange}>{props.children}</div>;
};

class StepWorkForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    company: null,
    isNotFound: 0,
    isSelected: 0,
    eStatus: "",
    tenures: "",
    salary: "",
    position: "",
    jobFunction: "",
    workingYear: "",
    admin_fee: 0,
    loanStatus: "",
    vendor_data: {}
  };

  handleOptionChange = option => {
    let vendor = this.props.loan.vendor;

    if (option !== null) {
      let item = vendor.find(x => x.vendor_id === option.data);
      this.setState({
        isSelected: 1,
        company: option,
        tenures: item.tenure,
        admin_fee: item.admin.admin_fee,
        vendor_data: item
      });
      if (option.data === 1) {
        this.setState({ loanStatus: "internal" });
      } else {
        this.setState({ loanStatus: "eksternal" });
      }
    } else {
      this.setState({
        isSelected: 0,
        tenures: "",
        loanStatus: "",
        salary: "",
        eStatus: "",
        vendor_data: {}
      });
    }
  };

  handleSubmit = () => {
    let { auth, account: {profile} } = this.props;
    let { vendor_data } = this.state;
    let data = financialParams(this.state, profile);
    this.props.putFinancialProfile(data, auth.token);

    let loan_status;
    if (profile.occupation) {
      loan_status = profile.occupation.company_name.value.toLowerCase().indexOf('koinworks') > -1 
      ? 'internal' : 'eksternal';
    }
    this.props.workInfo({
      data: {
        tenure: this.state.tenures || vendor_data.tenure,
        status: this.state.eStatus,
        salary: this.state.salary,
        loan_status: this.state.loanStatus ? this.state.loanStatus : loan_status,
        vendor_data: this.state.vendor_data
      }
    });
    this.props.handleSubmit();
  };

  selectChange = name => change => {
    if (change) {
      let values = this.state;
      values[name] = change.value;
      this.setState({ values: values });
    }
  };

  inputChange = ({target}) => {
    let term = target.value;
    this.setState({ isSelected: 0, isNotFound: 0 });
    let companies = this.props.loan.companies;
    let showOption = !companies ? '' : 
      companies.find(opt => opt.value.toLowerCase().includes(term));
    if (!showOption) {
      this.setState({ isNotFound: 1 });
    }
  };

  getAsyncOptions(inputValue) {
    let { loan } = this.props;
    return new Promise((resolve) => {
      const filtered = loan.companies.filter(
        item => item.label.toLowerCase().includes(inputValue.toLowerCase()))
      resolve(filtered);
    });
  }

  componentDidMount() {
    this.props.getCompanies(this.props.auth.token);
    this.props.getPersonalProfile(this.props.auth.token);
  }

  static getDerivedStateFromProps(props, state) {
    const { loan, account: { profile }} = props;
    if (profile && profile.occupation) {
      let { occupation } = profile;
      let vendor_data;
      if (occupation.company_name && occupation.company_name.value && loan.vendor) {
        vendor_data = loan.vendor.find(item => item.vendor_name === occupation.company_name.value);
      }

      return {
        position: occupation.position_at_company && !state.position ? 
          occupation.position_at_company.value : state.position,
        jobFunction: occupation.occupation && !state.jobFunction ? 
          occupation.occupation.value : state.jobFunction,
        workingYear: occupation.years_of_working && !state.position ?
          occupation.years_of_working.value : state.workingYear,
        eStatus: occupation.employment_status && !state.eStatus ?
          occupation.employment_status.value : state.eStatus,
        salary: occupation.net_salary && !state.salary ?
          occupation.net_salary.value : state.salary,
        company: occupation && loan.companies && !state.company ?
          loan.companies.find(x => x.value === occupation.company_name.value) : state.company,
        vendor_data: vendor_data ? vendor_data : state.vendor_data
      };
    } else {
      return state;
    }
  }

  render() {
    const { isSelected, isNotFound, loanStatus } = this.state;
    const {
      invalid,
      pristine,
      submitting,
      account,
      loan,
      init: { language, flag }
    } = this.props;
    let isInternal, isEksternal;

    if(account.profile && account.profile.occupation) {
      const { change } = this.props;
      let { profile: { occupation }} = account;
      isInternal = occupation.company_name.value.toLowerCase().indexOf('koinworks') > -1 ? true : false;
      isEksternal = occupation.company_name.value ? true : false;
      let setPosition = positions.filter( opt => opt.value === this.state.position)[0];
      let setWorkingYear = workingyears[flag].filter(opt => opt.value === this.state.workingYear)[0];
      change('company', this.state.company);
      change('jobFunction', this.state.jobFunction);
      change('salary', this.state.salary);
      change('position', setPosition);
      change('workingYear', setWorkingYear);
    }

    return (
      <StepWorkWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title">{language.title.stepWork}</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="input-wrapper">
              <div className="search-company">
                <SelectContainer inputChange={this.inputChange}>
                  <Field
                    id="company"
                    name="company"
                    label={language.form.company}
                    placeholder={language.form.company}
                    component={AsyncSelectInput}
                    onChange={this.handleOptionChange}
                    loadOptions={inputValue => this.getAsyncOptions(inputValue)}
                    noOptionsMessage={() => null}
                  />
                </SelectContainer>
              </div>
            </div>
            {((isSelected === 1 && loanStatus === "internal") || isInternal) && (
              <React.Fragment>
                <div className="input-wrapper">
                  <Field
                    id="eStatus"
                    name="eStatus"
                    label={language.form.estatus}
                    placeholder={language.form.estatus}
                    value={this.state.eStatus}
                    options={ESTATUS}
                    onChange={this.selectChange("eStatus")}
                    component={SimpleSelectInput}
                    validate={validate.required}
                  />
                </div>
              </React.Fragment>
            )}
            {((isSelected === 1 && loanStatus === "eksternal") || (!isInternal && isEksternal)) 
              && (
              <React.Fragment>
                <div className="input-wrapper">
                  <Field
                    id="eStatus"
                    name="eStatus"
                    label={language.form.estatus}
                    placeholder={language.form.estatus}
                    value={this.state.eStatus}
                    options={ESTATUS}
                    onChange={this.selectChange("eStatus")}
                    component={SimpleSelectInput}
                    validate={validate.required}
                  />
                </div>
                <div className="input-wrapper">
                  <Field 
                    id="jobFunction"
                    name="jobFunction"
                    label={"JOB FUNCTION"}
                    placeholder="JOB FUNCTION"
                    component={SimpleTextInput}
                    onChange={e => this.setState({ jobFunction: e.target.value })}
                    className="w-100"
                    variant="outlined"
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="position"
                    name="position"
                    label={language.form.position}
                    placeholder={language.form.position}
                    options={positions}
                    component={SimpleSelectInput}
                    onChange={this.selectChange("position")}
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="workingYear"
                    name="workingYear"
                    label={language.form.workingYear}
                    placeholder={language.form.workingYear}
                    options={workingyears[flag]}
                    component={SimpleSelectInput}
                    onChange={this.selectChange("workingYear")}
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="salary"
                    name="salary"
                    className="w-100"
                    label={language.form.netSalary}
                    placeholder={language.form.netSalary}
                    value={this.state.salary}
                    onChange={inputValue => this.setState({ salary: inputValue })}
                    variant="outlined"
                    component={SimpleTextInput}
                    InputProps={{
                      inputComponent: CurrencyFormatInput,
                      endAdornment: (
                        <InputAdornment className="adorement-info">
                          / {language.label.month}
                        </InputAdornment>
                      )
                    }}
                    validate={validate.required}
                  />
                </div>
              </React.Fragment>
            )}
            {isNotFound === 1 ? (
              <div className="comp-notfound">
                <figure className="img-notfound">
                  <img src={imgNotfound} alt="" />
                </figure>
                <h3 className="notfound-title">
                  {language.title.koinEmployeeNotAvailable}
                </h3>
                <p className="notfound-text">
                  {language.subtitle.koinEmployeeNotAvailable}
                </p>
                <div className="input-wrapper">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      history.push("/refer-company");
                    }}
                  >
                    {language.button.referToGetCash}
                  </button>
                </div>
              </div>
            ) : null}
            {isNotFound === 0 && (
              <div className="input-wrapper">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className={"step-next btn btn-primary w-100"}
                  disabled={ invalid || pristine || submitting || !loan.vendor }
                >
                  {language.button.next}
                </button>
              </div>
            )}
          </form>
        </div>
      </StepWorkWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  init: state.init,
  loan: state.loan,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    workInfo,
    getPersonalProfile,
    putFinancialProfile,
    getCompanies
  }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(StepWorkForm);

export default reduxForm({
  form: "StepWorkForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(component);
