import { offerService } from "../../services/offer";

export const getOffer = (id,token) => (dispatch) => {
  offerService.getLoanOffer(id,token)
    .then(res => {
      if (res.status === 200) {
        dispatch(getOfferSuccess(res.data));
      } else {
        dispatch(getOfferFailed(res.message));
      }
    },
    error => {
      dispatch(getOfferFailed(error));
  });
}

export const chooseOffer = (id,token) => (dispatch) => {
  offerService.postChooseOffer(id,token)
    .then(res => {
      if (res.status === 200) {
        dispatch(chooseOfferSuccess(res.data));
      } else {
        dispatch(chooseOfferFailed(res.message));
      }
    },
    error => {
      dispatch(chooseOfferFailed(error));
  });
}

export const rejectOffer = (id,token) => (dispatch) => {
  offerService.postRejectOffer(id,token)
    .then(res => {
      if (res.status === 200) {
        dispatch(rejectOfferSuccess(res.data));
      } else {
        dispatch(rejectOfferFailed(res.message));
      }
    },
    error => {
      dispatch(rejectOfferFailed(error));
  });
}

export const confirmRejection = action => {
  return { type: "CONFIRM_REJECTION", ...action }
}

export const resetOffer = action => {
  return { type: "RESET_OFFER", ...action }
}

const getOfferSuccess = data => {
  return { type: "GET_OFFER_SUCCESS", payload: data }
}

const getOfferFailed = errors => {
  return { type: "GET_OFFER_FAILED", payload: errors }
}

const chooseOfferSuccess = data => {
  return { type: "CHOOSE_OFFER_SUCCESS", payload: data }
}

const chooseOfferFailed = errors => {
  return { type: "CHOOSE_OFFER_FAILED", payload: errors }
}

const rejectOfferSuccess = data => {
  return { type: "REJECT_OFFER_SUCCESS", payload: data }
}

const rejectOfferFailed = errors => {
  return { type: "REJECT_OFFER_FAILED", payload: errors }
}