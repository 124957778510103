import React, { Component } from "react";
import { connect } from "react-redux";

import Steps from 'rc-steps';

class LDProgress extends Component {

  getCurrentStep = loan => {
    let status = loan.loan_status;
    switch (status) {
      case "on_going":
        return 6;
      case "disbursement_process":
        return 5;
      case "funding_process":
        return 4;
      case "waiting_for_contract":
        return 3;
      case "choose_offer":
        return 2;
      case "under_review":
        return 1;
      default:
        return 0;
    }
  }
  
  render() {
    let { init: { language }, loan: { loanProgress } } = this.props;
    return (
      <>
        <div className="ldcomp">
          <div className="heading">
            <div className="title">{language.title.progress}</div>
            <div className="text">
              {loanProgress ? this.getCurrentStep(loanProgress) + 1 : 0}/7 &nbsp;
              {language.text.stepCompleted}
            </div>
          </div>

          {loanProgress && (
            <div>
              <div className="ldstep">
                <Steps current={this.getCurrentStep(loanProgress)} direction="vertical">
                  <Steps.Step title="Waiting for Document" />
                  <Steps.Step title="Under Review" />
                  <Steps.Step title="Choose Offer" />
                  <Steps.Step title="Waiting for Contract" />
                  <Steps.Step title="Funding Process" />
                  <Steps.Step title="Disbursement Process" />
                  <Steps.Step title="Ongoing" />
                </Steps>
              </div>

              <div
                className="textnote"
                dangerouslySetInnerHTML={ {__html: language.text.loanProgressModal} }
              />
            </div>
          )}

        </div>
      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  loan: state.loan
});

export default connect(mapStateToProps, null)(LDProgress);