import TYPE from "./type";

const dataBusinessInfoAction = (authData) => {
  return {
    type: TYPE.DATA_BUSINESS_INFO_LOCAL,
    payload: authData,
  };
};

const dataBackUnggahDokument = (authData) => {
  return {
    type: TYPE.BUTTON_BACK_UNGGAH_DOKUMENT,
    payload: authData,
  };
};

const buttonNextShareHolderPhaseOne = (authData) => {
  return {
    type: TYPE.BUTTON_BACK_NEXT_SHARE_HOLDER,
    payload: authData,
  };
};

const dataBusinessInfoActionSubmit = (authData) => {
  return {
    type: TYPE.DATA_BUSINESS_INFO_LOCAL_SUBMIT,
    payload: authData,
  };
};

const dataPersonalInfoAction = (authData) => {
  return {
    type: TYPE.DATA_PERSONAL_BUSINESS_INFO_LOCAL,
    payload: authData,
  };
};

const dataPersonalInfoShareHolderAction = (authData) => {
  return {
    type: TYPE.DATA_SHARE_HOLDER_LOCAL,
    payload: authData,
  };
};

const dataPersonalInfoShareHolderActionReset = (authData) => {
  return {
    type: TYPE.DATA_SHARE_HOLDER_LOCAL_RESETL,
    payload: authData,
  };
};

const buttonBackAction = (authData) => {
  return {
    type: TYPE.BUTTON_BACK,
    payload: authData,
  };
};

const buttonBackActionSelectLoan = (authData) => {
  return {
    type: TYPE.BUTTON_BACK_SELECT_LOAN,
    payload: authData,
  };
};

const buttonBackActionBusinessInformation = (authData) => {
  return {
    type: TYPE.BUTTON_BACK_BUSINESS_INFO,
    payload: authData,
  };
};

const buttonBackActionPersonalInfo = (authData) => {
  return {
    type: TYPE.BUTTON_BACK_PERSONAL_INFO,
    payload: authData,
  };
};

const buttonBackActionShareHolder = (authData) => {
  return {
    type: TYPE.BUTTON_BACK_SHARE_HOLDER,
    payload: authData,
  };
};

const loanAplicationProsessSucess = (authData) => {
  return {
    type: TYPE.LOAN_APPLICATION_PROGRESS_SUCSESS,
    payload: authData,
  };
};

const loanAplicationProsessError = (authData) => {
  return {
    type: TYPE.LOAN_APPLICATION_PROGRESS_ERROR,
    payload: authData,
  };
};

const loanLimitSucess = (authData) => {
  return {
    type: TYPE.LOAN_LIMIT_SUCSESS,
    payload: authData,
  };
};

const loanLimitError = (authData) => {
  return {
    type: TYPE.LOAN_LIMIT_ERROR,
    payload: authData,
  };
};

const loanBalanceSucsess = (authData) => {
  return {
    type: TYPE.LOAN_BALANCE_SUCSESS,
    payload: authData,
  };
};

const loanBalanceError = (authData) => {
  return {
    type: TYPE.LOAN_BALANCE_ERROR,
    payload: authData,
  };
};

const ProvinceSucess = (authData) => {
  return {
    type: TYPE.PROVINCE_SUCSESS,
    payload: authData,
  };
};

const ProvinceError = (authData) => {
  return {
    type: TYPE.PROVINCE_ERROR,
    payload: authData,
  };
};

const citySucess = (authData) => {
  return {
    type: TYPE.CITY_SUCSESS,
    payload: authData,
  };
};

const cityError = (authData) => {
  return {
    type: TYPE.CITY_ERROR,
    payload: authData,
  };
};

const districtSucess = (authData) => {
  return {
    type: TYPE.DISTRICT_SUCESS,
    payload: authData,
  };
};

const districtError = (authData) => {
  return {
    type: TYPE.DISTRICT_ERROR,
    payload: authData,
  };
};

const subDistrictSucess = (authData) => {
  return {
    type: TYPE.SUB_DISTRICT_SUCESS,
    payload: authData,
  };
};

const subDistrictError = (authData) => {
  return {
    type: TYPE.SUB_DISTRICT_ERROR,
    payload: authData,
  };
};

const countrySucess = (authData) => {
  return {
    type: TYPE.COUNTRY_SUCESS,
    payload: authData,
  };
};

const countryError = (authData) => {
  return {
    type: TYPE.COUNTRY_ERROR,
    payload: authData,
  };
};

const districtShareHolderSucess = (authData) => {
  return {
    type: TYPE.DISTRICT_SHARE_HOLDER_SUCESS,
    payload: authData,
  };
};

const districtShareHolderError = (authData) => {
  return {
    type: TYPE.DISTRICT_SHARE_HOLDER_ERROR,
    payload: authData,
  };
};

const subDistrictShareHolderSucess = (authData) => {
  return {
    type: TYPE.SUB_DISTRICT_SHARE_HOLDER_SUCESS,
    payload: authData,
  };
};

const subDistrictShareHolderError = (authData) => {
  return {
    type: TYPE.SUB_DISTRICT_SHARE_HOLDER_ERROR,
    payload: authData,
  };
};

const principalCodeSucess = (authData) => {
  return {
    type: TYPE.PRINCIPAL_SUCESS,
    payload: authData,
  };
};

const principalCodeError = (authData) => {
  return {
    type: TYPE.PRINCIPAL_ERROR,
    payload: authData,
  };
};

const financingCodeSucess = (authData) => {
  return {
    type: TYPE.FINANCING_SUCESS,
    payload: authData,
  };
};

const financingCodeError = (authData) => {
  return {
    type: TYPE.FINANCING_ERROR,
    payload: authData,
  };
};

const purposeLoanSucess = (authData) => {
  return {
    type: TYPE.PURPOSE_SUCESS,
    payload: authData,
  };
};

const purposeLoanError = (authData) => {
  return {
    type: TYPE.PURPOSE_ERROR,
    payload: authData,
  };
};

const sendDocumentRequestSucess = (authData) => {
  return {
    type: TYPE.SEND_DOCUMENT_REQUEST_SUCESS,
    payload: authData,
  };
};

const sendDocumentRequestError = (authData) => {
  return {
    type: TYPE.SEND_DOCUMENT_REQUEST_ERROR,
    payload: authData,
  };
};

const sendDocumentRequestLoanSucess = (authData) => {
  return {
    type: TYPE.SEND_DOCUMENT_LOAN_REQUEST_SUCESS,
    payload: authData,
  };
};

const sendDocumentRequestLoanError = (authData) => {
  return {
    type: TYPE.SEND_DOCUMENT_LOAN_REQUEST_ERROR,
    payload: authData,
  };
};

const loanDetailsSucess = (authData) => {
  return {
    type: TYPE.LOAN_DETAILS_SUCESS,
    payload: authData,
  };
};

const loanDetailsError = (authData) => {
  return {
    type: TYPE.LOAN_DETAILS_ERROR,
    payload: authData,
  };
};

const loanPutDetailsSucess = (authData) => {
  return {
    type: TYPE.PUT_LOAN_DETAILS_SUCESS,
    payload: authData,
  };
};

const loanPutDetailsError = (authData) => {
  return {
    type: TYPE.PUT_LOAN_DETAILS_ERROR,
    payload: authData,
  };
};

const businessInformationSucess = (authData) => {
  return {
    type: TYPE.BUSINESS_INFORMATION_SUCESS,
    payload: authData,
  };
};

const businessInformationError = (authData) => {
  return {
    type: TYPE.BUSINESS_INFORMATION_ERROR,
    payload: authData,
  };
};

const businessInfoSucess = (authData) => {
  return {
    type: TYPE.BUSINESS_INFO_SUCESS,
    payload: authData,
  };
};

const businessInfoError = (authData) => {
  return {
    type: TYPE.BUSINESS_INFO_ERROR,
    payload: authData,
  };
};

const putBusinessInfoSucess = (authData) => {
  return {
    type: TYPE.PUT_BUSINESS_INFO_SUCESS,
    payload: authData,
  };
};

const putBusinessInfoError = (authData) => {
  return {
    type: TYPE.PUT_BUSINESS_INFO_ERROR,
    payload: authData,
  };
};

const putBusinessInfoProfileSucess = (authData) => {
  return {
    type: TYPE.BUSINESS_INFORMATION_PROFILE_SUCESS,
    payload: authData,
  };
};

const putBusinessInfoProfileError = (authData) => {
  return {
    type: TYPE.BUSINESS_INFORMATION_PROFILE_ERROR,
    payload: authData,
  };
};

const putPersonalInfoSucess = (authData) => {
  return {
    type: TYPE.PUT_PERSONAL_INFO_SUCESS,
    payload: authData,
  };
};

const putPersonalInfoError = (authData) => {
  return {
    type: TYPE.PUT_PERSONAL_INFO_ERROR,
    payload: authData,
  };
};

const putPersonalInfoProfileSucess = (authData) => {
  return {
    type: TYPE.PUT_PERSONAL_INFO_PROFILE_SUCESS,
    payload: authData,
  };
};

const putPersonalInfoProfileError = (authData) => {
  return {
    type: TYPE.PUT_PERSONAL_INFO_PROFILE_ERROR,
    payload: authData,
  };
};

const calculatorKoinVoiceSucess = (authData) => {
  return {
    type: TYPE.CALCULATOR_LANDING_PAGE_SUCESS,
    payload: authData,
  };
};

const calculatorKoinVoiceError = (authData) => {
  return {
    type: TYPE.CALCULATOR_LANDING_PAGE_ERROR,
    payload: authData,
  };
};

const uploadNewShareHolderSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_NEW_SHARE_HOLDER_SUCESS,
    payload: authData,
  };
};

const uploadNewShareHolderError = (authData) => {
  return {
    type: TYPE.UPLOAD_NEW_SHARE_HOLDER_ERROR,
    payload: authData,
  };
};

const uploadKtpValidationShareHolderSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_KTP_VALIDATION_SHARE_HOLDER_SUCESS,
    payload: authData,
  };
};

const uploadKtpValidationShareHolderError = (authData) => {
  return {
    type: TYPE.UPLOAD_KTP_VALIDATION_SHARE_HOLDER_ERROR,
    payload: authData,
  };
};

const uploadNewSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_NEW_SUCESS,
    payload: authData,
  };
};

const uploadNewError = (authData) => {
  return {
    type: TYPE.UPLOAD_NEW_SUCESS,
    payload: authData,
  };
};

const uploadKtpValidationSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_KTP_VALIDATION_SUCESS,
    payload: authData,
  };
};

const uploadKtpValidationError = (authData) => {
  return {
    type: TYPE.UPLOAD_KTP_VALIDATION_ERROR,
    payload: authData,
  };
};

const uploadSelfieKtpValidationSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_KTP_VALIDATION_SUCESS,
    payload: authData,
  };
};

const uploadSelfieKtpValidationError = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_KTP_VALIDATION_ERROR,
    payload: authData,
  };
};

const uploadSelfieMatchKtpValidationSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_MATCH_KTP_VALIDATION_SUCESS,
    payload: authData,
  };
};

const uploadSelfieMatchKtpValidationError = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_MATCH_KTP_VALIDATION_ERROR,
    payload: authData,
  };
};

const uploadSelfieKtpShareHolderValidationSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_SUCESS,
    payload: authData,
  };
};

const uploadSelfieKtpShareHolderValidationError = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_ERROR,
    payload: authData,
  };
};

const uploadSelfieMatchKtpShareHolderValidationSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_SUCESS,
    payload: authData,
  };
};

const uploadSelfieMatchKtpShareHolderValidationError = (authData) => {
  return {
    type: TYPE.UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_ERROR,
    payload: authData,
  };
};

const uploadKKSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_KK_SUCESS,
    payload: authData,
  };
};

const uploadKKError = (authData) => {
  return {
    type: TYPE.UPLOAD_KK_ERROR,
    payload: authData,
  };
};

const uploadNpwpSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_KK_SUCESS,
    payload: authData,
  };
};

const uploadNpwpError = (authData) => {
  return {
    type: TYPE.UPLOAD_KK_ERROR,
    payload: authData,
  };
};

const uploadKKValidationSucess = (authData) => {
  return {
    type: TYPE.UPLOAD_KK_VALIDATION_SUCSESS,
    payload: authData,
  };
};

const uploadKKValidationError = (authData) => {
  return {
    type: TYPE.UPLOAD_KK_VALIDATION_ERROR,
    payload: authData,
  };
};

const documentUploadSucess = (authData) => {
  return {
    type: TYPE.DOCUMENT_UPLOAD_SUCESS,
    payload: authData,
  };
};

const documentUploadError = (authData) => {
  return {
    type: TYPE.DOCUMENT_UPLOAD_ERROR,
    payload: authData,
  };
};

const resendEmailSucess = (authData) => {
  return {
    type: TYPE.RESEND_EMAIL_SUCESS,
    payload: authData,
  };
};

const resendEmailError = (authData) => {
  return {
    type: TYPE.RESEND_EMAIL_ERROR,
    payload: authData,
  };
};

const applicationListLoanDashboardSucsess = (authData) => {
  return {
    type: TYPE.APPLICATION_LIST_DASHBOARD_SUCESS,
    payload: authData,
  };
};

const applicationListLoanDashboardError = (authData) => {
  return {
    type: TYPE.APPLICATION_LIST_DASHBOARD_ERROR,
    payload: authData,
  };
};

const applicationListSucsess = (authData) => {
  return {
    type: TYPE.APPLICATION_LIST_SUCESS,
    payload: authData,
  };
};

const applicationListError = (authData) => {
  return {
    type: TYPE.APPLICATION_LIST_ERROR,
    payload: authData,
  };
};

const documentListSucsess = (authData) => {
  return {
    type: TYPE.DOCUMENT_LIST_SUCSESS,
    payload: authData,
  };
};

const documentListError = (authData) => {
  return {
    type: TYPE.DOCUMENT_LIST_ERROR,
    payload: authData,
  };
};

const userProfileSucsess = (authData) => {
  return {
    type: TYPE.USER_PROFILE_SUCSESS,
    payload: authData,
  };
};

const userProfileError = (authData) => {
  return {
    type: TYPE.USER_PROFILE_ERROR,
    payload: authData,
  };
};

const putPrimaryBankSucsess = (authData) => {
  return {
    type: TYPE.DOCUMENT_PRIMARY_BANK_SUCSESS,
    payload: authData,
  };
};

const putPrimaryBankError = (authData) => {
  return {
    type: TYPE.DOCUMENT_PRIMARY_BANK_ERROR,
    payload: authData,
  };
};

const putAppGuarantorCreateSucsess = (authData) => {
  return {
    type: TYPE.APP_GUARANTOR_CREATE_SUCSESS,
    payload: authData,
  };
};

const putAppGuarantorCreateError = (authData) => {
  return {
    type: TYPE.APP_GUARANTOR_UPDATE_ERROR,
    payload: authData,
  };
};

const putAppGuarantorUpdateSucsess = (authData) => {
  return {
    type: TYPE.APP_GUARANTOR_UPDATE_SUCSESS,
    payload: authData,
  };
};

const putAppGuarantorUpdateError = (authData) => {
  return {
    type: TYPE.APP_GUARANTOR_UPDATE_ERROR,
    payload: authData,
  };
};

const uploadKtpSelfieKtp = (authData) => {
  return {
    type: TYPE.UPLOAD_KTP_SELFIE_KTP,
    payload: authData,
  };
};

const loadingShowShow = () => {
  return {
    type: TYPE.LOADING_SHOW,
  };
};

const loadingShowNoShow = () => {
  return {
    type: TYPE.LOADING_NO_SHOW,
  };
};

const dispatchType = {
  buttonNextShareHolderPhaseOne,
  uploadKtpSelfieKtp,
  dataBackUnggahDokument,
  buttonBackActionSelectLoan,
  buttonBackActionShareHolder,
  dataPersonalInfoShareHolderAction,
  dataPersonalInfoShareHolderActionReset,
  putAppGuarantorCreateSucsess,
  putAppGuarantorCreateError,
  putAppGuarantorUpdateSucsess,
  putAppGuarantorUpdateError,
  districtShareHolderSucess,
  districtShareHolderError,
  subDistrictShareHolderSucess,
  subDistrictShareHolderError,
  uploadKtpValidationShareHolderSucess,
  uploadKtpValidationShareHolderError,
  uploadNewShareHolderSucess,
  uploadNewShareHolderError,
  uploadSelfieKtpShareHolderValidationSucess,
  uploadSelfieKtpShareHolderValidationError,
  uploadSelfieMatchKtpShareHolderValidationSucess,
  uploadSelfieMatchKtpShareHolderValidationError,
  buttonBackActionPersonalInfo,
  dataBusinessInfoActionSubmit,
  dataBusinessInfoAction,
  dataPersonalInfoAction,
  userProfileSucsess,
  userProfileError,
  putPrimaryBankSucsess,
  putPrimaryBankError,
  applicationListLoanDashboardSucsess,
  applicationListLoanDashboardError,
  documentListSucsess,
  documentListError,
  loanAplicationProsessSucess,
  loanAplicationProsessError,
  loanLimitSucess,
  loanLimitError,
  loanBalanceSucsess,
  loanBalanceError,
  buttonBackActionBusinessInformation,
  buttonBackAction,
  principalCodeSucess,
  principalCodeError,
  financingCodeSucess,
  financingCodeError,
  purposeLoanSucess,
  purposeLoanError,
  loanDetailsSucess,
  loanDetailsError,
  loanPutDetailsSucess,
  loanPutDetailsError,
  calculatorKoinVoiceSucess,
  calculatorKoinVoiceError,
  businessInfoSucess,
  businessInfoError,
  putBusinessInfoProfileSucess,
  putBusinessInfoProfileError,
  putBusinessInfoSucess,
  putBusinessInfoError,
  putPersonalInfoSucess,
  putPersonalInfoError,
  putPersonalInfoProfileSucess,
  putPersonalInfoProfileError,
  uploadNewSucess,
  uploadNewError,
  uploadKtpValidationSucess,
  uploadKtpValidationError,
  uploadSelfieKtpValidationSucess,
  uploadSelfieKtpValidationError,
  uploadSelfieMatchKtpValidationSucess,
  uploadSelfieMatchKtpValidationError,
  uploadKKValidationSucess,
  uploadKKValidationError,
  uploadKKSucess,
  uploadKKError,
  uploadNpwpSucess,
  uploadNpwpError,
  sendDocumentRequestSucess,
  sendDocumentRequestError,
  sendDocumentRequestLoanSucess,
  sendDocumentRequestLoanError,
  documentUploadSucess,
  documentUploadError,
  resendEmailSucess,
  resendEmailError,
  applicationListSucsess,
  applicationListError,
  ProvinceSucess,
  ProvinceError,
  citySucess,
  cityError,
  districtSucess,
  districtError,
  subDistrictSucess,
  subDistrictError,
  countrySucess,
  countryError,
  businessInformationSucess,
  businessInformationError,
  loadingShowShow,
  loadingShowNoShow,
};

export default dispatchType;
