import React, { useCallback, useEffect } from "react";
import { Modal, Button, Spin, notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { UploadKtpStyle } from "./style";
import { connect } from "react-redux";
import TakePhoto from "./TakePhoto";

const allowedFiles = ["image/png", "image/jpeg", "image/jpg"];

function UploadKtpModal({
  visible,
  handleChangeShowUploadKtp,
  init: { language, flag },
  actionChangeKtpUpload,
  uploadKtpError,
  setFieldValue,
  isLoading,
  validationImageKtp,
  currentOccupation
}) {
  // eslint-disable-next-line
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      actionChangeKtpUpload(file, setFieldValue, currentOccupation);
    });
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    onDrop: onDrop,
    accept: allowedFiles,
  });

  let validationImage = require("../../assets/images/uploadins.png").default;
  let validationHeading = language.modalUpload.headingKtp;
  let validationParagraph = language.modalUpload.paragraphKtpOne;
  let validationButtonUpload = language.modalUpload.uploadButton;
  const [imageTarget, setImageTarget] = React.useState("");

  useEffect(() => {
    setImageTarget("");
  }, [visible])

  const handleOnClick = () => {
    let message = "", description = "";

    if(imageTarget) {
      actionChangeKtpUpload(imageTarget, setFieldValue);
    } else {
      if(flag === "en") {
        message = "Failed"
        description = "Please capture image first"
      } else if(flag === "id") {
        message = "Gagal"
        description = "Mohon untuk mengambil gambar terlebih dahulu"
      }
      
      notification["error"]({
        message: message,
        description: description,
        placement: 'bottomRight'
      });
    }
  }

  if (validationImageKtp === 1 || validationImageKtp === 2) {
    validationHeading = language.modalUpload.headingKtpError;
    validationImage = require("../../assets/images/applyloan/ktp-img.png").default;
    validationParagraph = language.modalUpload.paragraphKtpError;
    validationButtonUpload = language.modalUpload.retakeKtpButton;
  } else if (validationImageKtp === 3) {
    validationHeading = language.modalUpload.headingKtpFailed;
    validationParagraph = language.modalUpload.paragraphKtpFailed;
    validationImage = require("../../assets/images/notfound.png").default;
  }
  let buttonUploadEndpoint;
  if (
    validationImageKtp === 0 ||
    validationImageKtp === 1 ||
    validationImageKtp === 2
  ) {
    buttonUploadEndpoint = (
      <div>
        <Spin spinning={isLoading}>
          <Button type="button" className="kw-button" onClick={handleOnClick}>
            {validationButtonUpload}
          </Button>
        </Spin>
      </div>
    );
  }

  return (
    <Modal
      visible={visible}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={handleChangeShowUploadKtp}
      destroyOnClose={true}
      footer={null}
    >
      <UploadKtpStyle>
        <div className="kw-uploadKtp">
          {/* <img
            src={validationImage}
            style={{ width: 300, margin: "auto" }}
            alt=""
          /> */}
          <TakePhoto 
            setImageTarget={setImageTarget}
          />
          <h5>
            {
              /* {validationImageKtp === 1 ||
            validationImageKtp === 2 ||
            validationImageKtp === 3
              ? `${validationHeading}(${validationImageKtp})`
              : validationHeading}}*/ validationHeading
            }
          </h5>
          <p>{validationParagraph}</p>
          {validationImageKtp === 0 && (
            <React.Fragment>
              <p>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {language.modalUpload.paragraphKtpTwo}
              </p>
              <p>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {language.modalUpload.paragraphKtpThree}
              </p>
            </React.Fragment>
          )}
          {/* {uploadKtpError && (
            <Alert
              message={`${uploadKtpError[0]} (${validationImageKtp})`}
              style={{ marginBottom: 10 }}
              type="error"
            />
          )} */}
          {validationImageKtp === 3 && (
            <Button type="button" onClick={handleChangeShowUploadKtp}>
              {language.modalUpload.uploadButtonClose}
            </Button>
          )}
          {buttonUploadEndpoint}
        </div>
      </UploadKtpStyle>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
});

export default connect(mapStateToProps)(UploadKtpModal);
