import styled from "styled-components";

export const PaymentInstructionsStyle = styled.div`
  .kw-paymentInstruction-wrapper {
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #566068;
      margin-top: 20px;
    }
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.35px;
      color: #2b486d;
      margin-top: 20px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    li {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    &__copy-virtual-account {
      border: 2px solid #2b486d;
      box-sizing: border-box;
      border-radius: 3px;
      color: #2b486d;
      font-weight: 555;
      margin: auto;
    }
    &__user-id {
      font-style: normal;
      background-color: #bad6f9;
      font-weight: bold;
      font-size: 17px;
      display: block;
      align-items: center;
      text-align: center;
      letter-spacing: -0.24px;
      color: #566068;
      width: 35%;
      margin: auto;
      border-radius: 3px;
    }
    &__virtual-account {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      display: block;
      text-align: center;
      letter-spacing: 0.337647px;
      color: #378df4;
    }
    &__second-section {
      margin-top: 20px;
      background: #ffffff;
      border: 1px solid #efefef;
      padding: 15px;
      box-sizing: border-box;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }
  }
`;
