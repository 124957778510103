import React from "react";
import currencyRupiah from "../../library/currency";
import moment from "moment";
import { Col, Row } from "antd";
import NotFound from "../../Components/NotFound";

const Lender = (props) => {
  const { loanLenderKoinBisnis, dataRepayment, language } = props;
  return (
    <div>
      <strong
        style={{
          display: "block",
          color: "#2B486D",
          fontSize: 25,
        }}
      >
        Loan ID : {`${dataRepayment && dataRepayment.code}`}
      </strong>
      <span>{`${language.label.totalLender}: ${
        loanLenderKoinBisnis && loanLenderKoinBisnis.length > 0
          ? loanLenderKoinBisnis.length
          : 0
      }`}</span>
      <Row>
        {loanLenderKoinBisnis && loanLenderKoinBisnis.length > 0 ? (
          loanLenderKoinBisnis.map((loan, index) => {
            return (
              <React.Fragment key={index}>
                <Col xs={8} style={{ marginTop: 20 }}>
                  {moment(loan.date).format("DD MMM")}
                </Col>
                <Col xs={8} style={{ marginTop: 20 }}>
                  {loan.lender_id}
                </Col>
                <Col xs={8} style={{ marginTop: 20 }}>
                  {currencyRupiah(loan.amount, " ", ",")}
                </Col>
              </React.Fragment>
            );
          })
        ) : (
          <NotFound
            image={require("../../assets/images/dashboard/norepayment.png").default}
            label={"No lender yet"}
            isShow={true}
            paragraph={"Yourloan lender will show up here."}
          />
        )}
      </Row>
    </div>
  );
};

export default Lender;
