const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;

function reqLoan(url, data, token) {
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify(data)
  };

  return fetch(BASE_URL + url, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function sendDocumentLink(document, loan, auth) {
  let path = '/v1/users/document/send-link';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.token
      },
      body: JSON.stringify({
        email: auth.email,
        loan_id: loan.loan_id,
        document: document
      })
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getDistrict(term) {
  let path = '/v1/users/location/district';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
      }
  };

  return fetch(BASE_URL + path + '?keyword=' + term, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getSubdistrict(code,term) {
  let path = '/v1/users/location/sub-district';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
      }
  };

  return fetch(BASE_URL + path + '?keyword=' + term + '&district_code=' + code, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getCompanies(token) {
  let path = '/v1/koinemployee/companies';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoans(token, page, sort, sortBy) {
  let path = '/v1/koinemployee/loans';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + '?page=' + page + '&sort_type=' + sort + '&sort=' + sortBy, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getActiveLoans(token) {
  let path = '/v1/koinemployee/loans/active';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoanDetail(id,token) {
  let path = '/v1/koinemployee/loan/detail/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoanSchedule(id,token) {
  let path = '/v1/koinemployee/loan/schedule/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoanProgress(id,token) {
  let path = '/v1/koinemployee/loan/progress/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoanLender(id,token) {
  let path = '/v1/koinemployee/loan/lender/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoanContract(id,token) {
  let path = '/v1/koinemployee/loan/contract/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const loanService = {
  reqLoan,
  getDistrict,
  getSubdistrict,
  getCompanies,
  sendDocumentLink,
  getLoans,
  getActiveLoans,
  getLoanDetail,
  getLoanSchedule,
  getLoanProgress,
  getLoanLender,
  getLoanContract
};