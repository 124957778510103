import styled from "styled-components";

export const NavMainWrapper = styled.div`
  background: #ffffff;
  .main-nav {
    background: #ffffff;
    @media screen and (max-width:500px){
      .lang-selector {
        margin-top: 20px;
      }
    }
    .navbar {
      background-color: #ffffff;
      @media (max-width: 575.98px) {
        padding-left: 0;
        padding-right: 0;
      }
      .navlogo {
        height: 32px;
        margin-bottom: 0;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .nav-item,
      .nav-link {
        margin-right: 25px;
      }
      .nav-link {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.38px;
        color: #2d2d2d;
      }
      .dropdown-toggle {
        position: relative;
        margin-right: 15px;
        &::after {
          position: absolute;
          top: 19px;
        }
      }
      .btn-app {
        padding: 7px 25px !important;
      }
    }
  }
`;
