import React from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  accountInfo,
  checkEmail,
  checkPhoneNumber,
} from "../../stores/actions/account";
import { Link } from "react-router-dom";
import { Section } from "./style";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import logoimg from "../../assets/images/logo.png";
import gplay from "../../assets/images/gplay.png";
import astore from "../../assets/images/appstore.png";
import * as validate from "../../services/validate";
import { hostNameCheckCondition } from "../../library/checkHostNameWeb";
import { NativeSelect } from "@material-ui/core";
import { Button, DatePicker, Select, Spin } from "antd";
import moment from "moment";

const { Option } = Select;

const textInputComponent = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  account,
  flag,
  ...custom
}) => (
  <div
    className={`${
      (touched && error && "invalid") ||
      (account && account.emailErrors && "invalid")
    }`}
  >
    <TextField
      label={label}
      placeholder={placeholder}
      value={input.value}
      onChange={input.onChange}
      {...input}
      {...custom}
    />
    {account && account.emailErrors && (
      <div className="invalid-feedback">{account.emailErrors[flag]}</div>
    )}
    {touched && error && (
      <div className="invalid-feedback">
        {typeof error === "string" ? error : error.label}
      </div>
    )}
  </div>
);

const selectInputComponent = ({
  input,
  meta: { touched, error },
  account,
  language,
  flag,
  ...custom
}) => (
  <div className={`${touched && error && "invalid"}`}>
    <Select
      size={"large"}
      onChange={input.onChange}
      placeholder={language.loanFormBusinessInfo.numberOfEmployee}
      value={input.value ? input.value : undefined}
      {...custom}
    >
      <Option value={"1 - 50"}>1 - 50</Option>
      <Option value={"51 - 100"}> 51 - 100 </Option>
      <Option value={"101 - 250"}> 101 - 250 </Option>
      <Option value={"251 - 500"}>251 - 500 </Option>
      <Option value={"501 - 1000"}> 501 - 1000 </Option>
      <Option value={"1000+"}>1000+ </Option>
    </Select>
    {touched && error && (
      <div className="invalid-feedback">
        {typeof error === "string" ? error : error.label}
      </div>
    )}
  </div>
);

const phoneInputComponent = ({
  input,
  placeholder,
  disabled,
  type,
  meta: { error, touched },
  handleChange,
  account,
  flag,
}) => (
  <div
    className={`${
      (touched && error && "invalid") ||
      (account.errors &&
        [
          "Your phone number already exists",
          "Nomor telepon kamu sudah terdaftar",
        ].includes(account.errors[flag]) &&
        "invalid")
    }`}
  >
    <PhoneInput
      prefix={""}
      country={"id"}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      autoFormat={false}
      enableSearch={false}
      value={input.value}
      onBlur={() => input.onBlur(input.value)}
      onChange={(value, data) => {
        input.onChange(value);
        handleChange(
          data.dialCode,
          value.length && value.substring(0, 1) === "0"
            ? value
            : value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)
        );
      }}
    />
    {((touched && error) ||
      (account.errors &&
        [
          "Your phone number already exists",
          "Nomor telepon kamu sudah terdaftar",
        ].includes(account.errors[flag]))) && (
      <div className="invalid-feedback">{error || account.errors[flag]}</div>
    )}
  </div>
);

const dateInputComponent = ({
  input,
  id,
  className,
  meta: { touched, error },
  label,
  name,
}) => (
  <div className={`${touched && error && "invalid"}`}>
    <DatePicker
      name={name}
      id={id}
      placeholder={label}
      className={className}
      value={input.value ? moment(input.value, "MM/DD/YYYY") : ""}
      format="MM/DD/YYYY"
      onChange={input.onChange}
      size="large"
    />
    {touched && error && <div className="invalid-feedback">{error}</div>}
  </div>
);

class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    formValues: {
      dob: "2020-01-01T21:11:54",
      established_in: this.props.account?.data?.established_in,
      business_duration: this.props.account?.data?.business_duration,
      number_of_employee: this.props.account?.data?.number_of_employee,
      email: this.props.account?.data?.email,
      phone: this.props.account?.data?.phone_number,
      phone_area: this.props.account?.data?.phone_area,
      gender: "",
      name: this.props.account?.data?.full_name,
      promo: this.props.account?.data?.promo_code,
      business_type: this.props.account?.data?.business_type || "pt",
      company_name: this.props.account?.data?.company_name,
    },
    isShowLoading: false,
  };

  handleChange = ({ target }) => {
    const { formValues } = this.state;
    // console.log(target);
    formValues[target.name] = target.value;
    this.setState({ formValues });
  };

  handleChangeNumberOfEmployee = (values) => {
    const { formValues } = this.state;
    formValues["number_of_employee"] = values;
    this.setState({ formValues });
  };

  handleDateChange = (name) => (date) => {
    let { formValues } = this.state;
    if(!date) {
      formValues[name] = null;
    } else {
      formValues[name] = moment(date, "MM/DD/YYYY");
    }
    this.setState({ formValues });
  };

  handlePhoneChange = (code, values) => {
    let formValues = this.state.formValues;
    formValues["phone"] = values;
    formValues["phone_area"] = code;
    this.setState({ formValues });
  };

  handleShowIsLoading = (value) => {
    this.setState({
      isShowLoading: value,
    });
  };

  handlePhoneNumberPayload = (phoneArea, phoneNumber) => {
    if (phoneNumber[0] === '0') {
      phoneNumber = phoneNumber.slice(1)
    }

    return `+${phoneArea}${phoneNumber}`;
  }

  handleSubmit = () => {
    let values = this.state.formValues;
    this.props.accountInfo({
      data: {
        full_name: values.name,
        gender: values.gender,
        date_of_birth: values.dob,
        phone_number: values.phone,
        business_type: values.business_type,
        established_in: moment(values.established_in, "YYYY-MM-DD"),
        business_duration: values.business_duration,
        number_of_employee: values.number_of_employee,
        company_name: values.company_name,
        email: values.email,
        promo_code: values.promo,
        phone_area: values.phone_area,
      },
    });
    this.props.checkEmail(values.email, this.handleShowIsLoading);
    this.props.checkPhoneNumber(this.handlePhoneNumberPayload(values.phone_area, values.phone), this.handleShowIsLoading);
    localStorage.setItem("dataRegister", JSON.stringify(values));
  };

  componentDidMount() {
    let { account } = this.props;
    account.isEmailAvailable = false;
    account.isPhoneAvailable = false;
  }

  componentDidUpdate() {
    let { account, nextPage } = this.props;
    if(!this.state.isShowLoading) {
      if (account.isEmailAvailable && account.isPhoneAvailable) {
        nextPage();
      }
    }
  }

  handleInputName = () => {
    let {
      init: { language },
    } = this.props;
    let labelNamePlaceHolder = language.form.user_cvPT_business_type;
    if (
      this.state.formValues.business_type === "pt" ||
      this.state.formValues.business_type === "cv"
    ) {
      labelNamePlaceHolder = language.form.user_cvPT_business_type;
    } else if (this.state.formValues.business_type === "individual") {
      labelNamePlaceHolder = language.form.user_home_nameBusiness;
    }
    return labelNamePlaceHolder;
  };

  handleInputOwnerName = () => {
    let {
      init: { language },
    } = this.props;
    let labelNamePlaceHolder = language.form.borrowerNameOjk;
    if (
      this.state.formValues.business_type === "pt" ||
      this.state.formValues.business_type === "cv"
    ) {
      labelNamePlaceHolder = language.form.borrowerNameOjk;
    } else if (this.state.formValues.business_type === "individual") {
      labelNamePlaceHolder = language.form.borrowerNameOjk;
    }
    return labelNamePlaceHolder;
  };

  render() {
    let { formValues } = this.state;
    let {
      account,
      init: { language, flag },
      invalid,
      handleSubmit,
      submitting,
    } = this.props;
    return (
      <Section>
        <div className="page-content-wrapper">
          <div className="card">
            <figure className="logo-wrapper">
              <img src={logoimg} alt="" className="logo-img" />
            </figure>
            <div className="card-body register-body">
              <h2 className="register-title">
                {hostNameCheckCondition
                  ? language.title.registerKoinInvoice
                  : language.title.register}
              </h2>
              <form
                id="AccountForm"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(this.handleSubmit)}
              >
                {hostNameCheckCondition && (
                  <div className="input-wrapper">
                    <p className="gender-title">
                      {language.form.business_type}
                    </p>
                    <NativeSelect
                      defaultValue={"pt"}
                      name="business_type"
                      onChange={this.handleChange}
                      value={formValues.business_type}
                    >
                      <option value="pt">PT</option>
                      <option value="cv">CV</option>
                      <option value="individual">Individual</option>
                    </NativeSelect>
                  </div>
                )}
                <div className="input-wrapper">
                  <Field
                    id="established_in"
                    name="established_in"
                    label={language.loanFormBusinessInfo.establishedIn}
                    value={formValues.established_in}
                    onChange={this.handleDateChange("established_in")}
                    className="w-100 dataob"
                    component={dateInputComponent}
                    validate={
                      this.state.formValues['business_duration'] ?
                      "" : 
                      [validate.required]
                    }
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="business_duration"
                    name="business_duration"
                    label={language.loanFormBusinessInfo.businessDuration}
                    value={formValues.business_duration}
                    onChange={this.handleChange}
                    className="w-100"
                    type="number"
                    component={textInputComponent}
                    validate={
                      this.state.formValues['established_in'] ? 
                      [validate.zeroAmount] :
                      [validate.required, validate.zeroAmount]
                    }
                    variant="outlined"
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="number_of_employee"
                    name="number_of_employee"
                    onChange={this.handleChangeNumberOfEmployee}
                    value={formValues.number_of_employee}
                    language={language}
                    className="w-100"
                    component={selectInputComponent}
                    validate={[validate.required]}
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id={hostNameCheckCondition ? "company_name" : "name"}
                    label={
                      hostNameCheckCondition
                        ? this.handleInputName()
                        : language.form.fullname
                    }
                    variant="outlined"
                    className="w-100"
                    name={hostNameCheckCondition ? "company_name" : "name"}
                    component={textInputComponent}
                    value={formValues.name}
                    onChange={this.handleChange}
                    validate={validate.required}
                  />
                  {hostNameCheckCondition ? (
                    ""
                  ) : (
                    <small>{language.label.fullname}</small>
                  )}
                </div>
                {hostNameCheckCondition && (
                  <div className="input-wrapper">
                    <Field
                      id="name"
                      label={this.handleInputOwnerName()}
                      variant="outlined"
                      className="w-100"
                      name="name"
                      component={textInputComponent}
                      value={formValues.name}
                      onChange={this.handleChange}
                      validate={validate.required}
                    />
                    <small>{language.label.fullname}</small>
                  </div>
                )}
                {hostNameCheckCondition ? (
                  ""
                ) : (
                  <React.Fragment>
                    <div className="input-wrapper gender-wrapper">
                      <p className="gender-title">{language.form.gender}</p>
                      <RadioGroup
                        aria-label="position"
                        name="gender"
                        value={formValues.gender}
                        onChange={this.handleChange}
                        row
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio color="primary" />}
                          label={language.label.male}
                          labelPlacement="end"
                          className="gender-box"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio color="primary" />}
                          label={language.label.female}
                          labelPlacement="end"
                          className="gender-box"
                        />
                      </RadioGroup>
                    </div>
                    <div className="input-wrapper">
                      <Field
                        id="dob"
                        name="dob"
                        label={language.form.dob}
                        selected={formValues.dob}
                        value={formValues.dob}
                        onChange={this.handleDateChange("dob")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        className="w-100 dataob"
                        component={dateInputComponent}
                        validate={[validate.required, validate.restrictAge(21)]}
                      />
                    </div>{" "}
                  </React.Fragment>
                )}
                <div className="input-wrapper">
                  <Field
                    id="phone"
                    name="phone"
                    placeholder={language.form.mobileNumber}
                    className={`form-control w-100`}
                    country={"id"}
                    type="text"
                    component={phoneInputComponent}
                    account={account}
                    flag={flag}
                    autoFormat={false}
                    enableSearch={false}
                    value={formValues.phone}
                    handleChange={this.handlePhoneChange}
                    validate={[
                      validate.required,
                      validate.minLength(7),
                      validate.maxLength(15),
                    ]}
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="email"
                    label={
                      hostNameCheckCondition
                        ? language.form.businessEmailRegister
                        : language.form.personalEmail
                    }
                    variant="outlined"
                    account={account}
                    flag={flag}
                    type="email"
                    name="email"
                    component={textInputComponent}
                    className="w-100 form-control"
                    onChange={this.handleChange}
                    validate={[validate.required, validate.email]}
                  />
                </div>
                <div className="input-wrapper">
                  <Field
                    id="code"
                    name="code"
                    label={language.form.promoCode}
                    variant="outlined"
                    className="w-100"
                    component={textInputComponent}
                  />
                </div>
                <div className="input-wrapper">
                  <p className="text-center">
                    <small>
                      {language.text.register}{" "}
                      <a
                        href={
                          hostNameCheckCondition
                            ? "https://koinworks.com/super-app/syarat-ketentuan/"
                            : "/toc"
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {language.label.toc}
                      </a>
                      ,{" "}
                      <a
                        href={
                          hostNameCheckCondition
                            ? "https://koinworks.com/super-app/syarat-penggunaan/"
                            : "/tos"
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {language.label.tou}
                      </a>{" "}
                      {language.text.and}{" "}
                      <a
                        href={
                          hostNameCheckCondition
                            ? "https://koinworks.com/super-app/kebijakan-privasi/"
                            : "/policy"
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {language.label.privacyPolicy}
                      </a>
                      {flag === "id" && " Koinworks"}.
                    </small>
                  </p>
                </div>
                <div className="input-wrapper">
                  <Spin spinning={this.state.isShowLoading}>
                    <Button
                      className={invalid || submitting ? "" : "kw-button"}
                      htmlType="submit"
                      style={{ width: "100%" }}
                      size="large"
                      disabled={invalid || submitting}
                    >
                      {language.button.signupContinue}
                    </Button>
                  </Spin>
                </div>
                <p className="text-center">
                  {language.label.alreadyHaveAccount}
                </p>
                <div className="input-wrapper">
                  <Link to="/login">
                    <Button
                      style={{ width: "100%" }}
                      size="large"
                      className="kw-button"
                    >
                      {language.button.signin}
                    </Button>
                  </Link>
                </div>
                <p className="text-center">{language.label.applyThrough}</p>
                <div className="appstore-wraper">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.koinworks.app&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="appstore-item">
                      <img src={gplay} alt="" />
                    </span>
                  </a>
                  <a
                    href="https://apps.apple.com/id/app/koinworks/id1457026579"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="appstore-item">
                      <img src={astore} alt="" />
                    </span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      accountInfo,
      checkPhoneNumber,
      checkEmail,
    },
    dispatch
  );
};

const component = connect(mapStateToProps, mapDispatchToProps)(AccountForm);

export default reduxForm({
  form: "AccountForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(component);
