import React from "react";
import { Card } from "antd";

function NotFoundListDocuments(props) {
  return (
    <Card style={{ width: "100%", height: "100vh" }}>
      <img
        src={require("../../assets/images/P2P-Empty.png").default}
        alt=""
        style={{ display: "block", margin: "auto" }}
      />
    </Card>
  );
}

export default NotFoundListDocuments;
