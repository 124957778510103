import React from "react";
import { Card, Progress, Row, Col } from "antd";
import { progressBar } from "../../library/progressBar";
import currencyRupiah from "../../library/currency";
import { AvailableCreditProgressStyle } from "../../BorrowerDashboardKoinInvoice/style";
import moment from "moment";

export default function AvailableCreditProgressLoanNotApply({
  loanLimit,
  language,
  expiredDate,
}) {
  const { used_limit, expired_at, initial_limit, current_limit } = loanLimit;
  return (
    <Card style={{ marginTop: 15 }}>
      <AvailableCreditProgressStyle>
        <div className="kw-availableCredit-wrapper">
          <Row>
            <Col xs={12}>
              <span className="kw-availableCreedit-availableCredit">
                {language.loanDashboard.availableCredit}
              </span>
            </Col>
            <Col xs={12}>
              <span className="kw-availableCreedit-currency">
                {currencyRupiah(current_limit <= 0 ? 0 : current_limit, "Rp ")}
              </span>
            </Col>
          </Row>
        </div>
        <Progress
          strokeColor={{
            "0%": "#D2D2D2",
            "100%": "#D2D2D2",
          }}
          percent={100}
          showInfo={false}
          strokeWidth={10}
          successPercent={progressBar(used_limit, current_limit)}
        />
        <Row style={{ marginTop: 10 }}>
          <Col xs={12}>
            <span className="kw-availableCredit-used">
              {language.loanDashboard.totalUsed}
            </span>
            <strong>{currencyRupiah(used_limit, "Rp ")}</strong>
          </Col>
          <Col xs={12}>
            <span className="kw-availableCredit-used">
              {language.loanDashboard.totalLimit}
            </span>
            <strong>{currencyRupiah(initial_limit <= 0 ? 0 : initial_limit, "Rp ")}</strong>
          </Col>
        </Row>
        <p
          style={{
            color: expiredDate === "expired-date" ? "#C53741" : "#566068",
            textAlign: "center",
            marginTop: 15,
            fontWeight: 500,
            fontSize: 15,
          }}
        >
          {expiredDate === "expired-date"
            ? `Limit has expired on ${moment(expired_at).format(
                "DD MMMM YYYY"
              )}`
            : `${language.text.activeUntil} ${moment(expired_at).format("DD MMMM YYYY")}`}
        </p>
      </AvailableCreditProgressStyle>
    </Card>
  );
}
