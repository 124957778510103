module.exports = {
  api: {
    url: process.env.REACT_APP_API_MAIN_SERVICE
  },
  host: {
    name: "https://koininvoice.com/",
  },
  document: {
    url: "https://docs.koininvoice.com",
    urlLoanKoininvoice: process.env.REACT_APP_DOCUMENT_KOININVOICE
  },
  ocr: {
    prod: "https://ocr.finfini.com/app",
    staging: "https://staging-ocr.finfini.com/app",
  },
  ocrNew:{
    prod: "https://asgard.koinworks.com/v1/superapp/finfini/",
    staging: "https://asgard.koin.works/v1/superapp/finfini/"
  },
  ocrV3:{
    prod: "https://asgard.koinworks.com/v1/superapp/finfini/v3",
    staging: "https://asgard.koin.works/v1/superapp/finfini/v3"
  },
  link: {
    submission: "https://koininvoice.com/",
  },
  checkLoanId: {
    loan: process.env.REACT_APP_LOAN_ID,
  },
};
