const GET_PRINCIPAL = "v1/koinrevolver/principal";
const GET_REFERRAL = "v1/koinrevolver/referral";
const PURPOSE = "v1/koinrevolver/loan_application/purpose";
const GET_DETAILS_LOAN = "v1/koinrevolver/loan_application/";
const CALCULATOR_LOAN = "v1/koinrevolver/calculator/loan";
const RESEND_EMAIL = "v1/koinrevolver/loan/application/send-link";
const LOAN_APPLICATION_PROGRESS = "v1/koinrevolver/loan-application/progress";
const LOAN_LIMIT = "v1/koinrevolver/loan-limit";
const LOAN_BALANCE = "v1/koinrevolver/balance";
const UPLOAD_NEW = "v1/common/upload-new";
const PROVINCE = "v1/thirdparty/location/province?keyword=";
const CITY = "v1/thirdparty/location/city?keyword=";
const DISTRICT = "v1/thirdparty/location/district?keyword=";
const SUB_DISTRICT = "v1/thirdparty/location/sub-district?keyword=";
const COUNTRY = "v1/thirdparty/location/country?keyword=";
const BUSINESS_CATEGORY = "v1/koinrevolver/limit/application/business-info?desc=";
const BUSINESS_INFO = "v1/users/profile/business";
const BUSINESS_INFO_PROFILE = "v1/users/profile/business/business-info";
const PERSONAL_INFO_PROFILE = "v1/users/profile/business/pic";
const YOUR_LOAN_DETAILS = "v1/koinrevolver/loan-application/list";
const USER_PROFILE = "v1/users/profile";
const DOCUMENT_PRIMARY_BANK = "v1/users/primary-bank";
const DOCUMENT_SEND_LINK = "v1/users/document/send-link";
const DOCUMENT_LIST = "v1/users/document/list";
const DOCUMENT_LIST_OLD = "v1/users/document/required";
const DOCUMENT_STATUS_LOAN_UPDATE = "v1/koinbisnis/loan/submission-loan";
const DASHBOARD_SCHEDULE_LIST = "v1/koinrevolver/schedule/list";
const INFORMATION_LOAN_DETAILS = "v1/koinrevolver/loan/loan-detail/";
const BORROWER_INFORMATION = "v1/koinrevolver/borrower/information";
const INFORMATION_LOAN = "v1/koinrevolver/loan/information/";
const LOAN_OFFER_LIST = "v1/koinrevolver/loan/available-offers/";
const REJECT_OFFER_LIST = "v1/koinrevolver/loan/reject-offer";
const CHOOSE_OFFER_LIST = "v1/koinrevolver/loan/choose-offer";
const LOAN_CONTRACT = "v1/koinrevolver/loan/contract/";
const CREDIT_LIMIT_GET = "v1/loans/app/ucl/get";
// const CREDIT_LIMIT_UPDATE = "v1/loans/app/ucl/update";
const LIMIT_APPROVED = "v1/assessments/app/limit-application/approve-offer";
const LIMIT_REJECT = "v1/assessments/app/limit-application/reject-offer";
const LIMIT_APPLICATION = "v1/koinrevolver/limit/application";
const LIMIT_CONFIRM = "v1/koinrevolver/limit/application/confirm/";
const LIMIT_DOCUMENT_LINK = "v1/koinrevolver/limit/application/send-link";
const LOAN_APPLY_AFTER_LIMIT = "v1/koinrevolver/loan/apply";
const SUBMISSION = "v1/users/document/submission/";
const LOCATION = "v1/thirdparty/location?sub_district="
const CONTRACT_DIGISIGN = "v1/thirdparty/digisign/borrower/register"

export const PATH_KOINVOICE_LOAN = {
  LOCATION,
  SUBMISSION,
  LOAN_APPLY_AFTER_LIMIT,
  LIMIT_DOCUMENT_LINK,
  LIMIT_CONFIRM,
  LIMIT_APPLICATION,
  LIMIT_APPROVED,
  LIMIT_REJECT,
  INFORMATION_LOAN,
  LOAN_CONTRACT,
  CHOOSE_OFFER_LIST,
  LOAN_OFFER_LIST,
  REJECT_OFFER_LIST,
  INFORMATION_LOAN_DETAILS,
  BORROWER_INFORMATION,
  CREDIT_LIMIT_GET,
  // CREDIT_LIMIT_UPDATE,
  CITY,
  PROVINCE,
  DASHBOARD_SCHEDULE_LIST,
  DOCUMENT_PRIMARY_BANK,
  USER_PROFILE,
  DOCUMENT_SEND_LINK,
  LOAN_APPLICATION_PROGRESS,
  DOCUMENT_LIST,
  DOCUMENT_LIST_OLD,
  DOCUMENT_STATUS_LOAN_UPDATE,
  YOUR_LOAN_DETAILS,
  LOAN_LIMIT,
  LOAN_BALANCE,
  GET_PRINCIPAL,
  GET_REFERRAL,
  PURPOSE,
  GET_DETAILS_LOAN,
  CALCULATOR_LOAN,
  RESEND_EMAIL,
  UPLOAD_NEW,
  DISTRICT,
  SUB_DISTRICT,
  COUNTRY,
  BUSINESS_CATEGORY,
  BUSINESS_INFO,
  BUSINESS_INFO_PROFILE,
  PERSONAL_INFO_PROFILE,
  CONTRACT_DIGISIGN
};
