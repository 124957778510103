import koinvoiceServices from "../../services/koinvoice";
import dispatchType from "./dispatchType";
import { upload } from "../../services/upload";
import history from "../../util/history";
import cookie from "react-cookies";
import TYPE from "./type";
import { convertReverseTimeFormat } from "../../library/convertReverseTimeFormat";
import { openNotificationWithIcon } from "../../library/notifications";

export const actionGetBusinessInfoLocal = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.dataBusinessInfoAction(request));
  } catch (error) {
    console.log(error);
  }
};

const ShareHolderAddNewFormRedirectSucsess = (authData) => {
  return {
    type: TYPE.BUTTON_SHARE_HOLDER_NEW_FORM_LIMIT,
    payload: authData,
  };
};

export const actionShareHolderAddNewFormRedirect = (request) => async (
  dispatch
) => {
  try {
    dispatch(ShareHolderAddNewFormRedirectSucsess(request));
  } catch (error) {
    console.log(error);
  }
};

const uploadBackActionRefresh = (authData) => {
  return {
    type: TYPE.UPLOAD_BACK_ACTION_REFRESH,
    payload: authData,
  };
};

export const actionButtonBackUploadRefresh = (request) => async (dispatch) => {
  try {
    dispatch(uploadBackActionRefresh(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionButtonBackShareHolderPhaseOne = (request) => async (
  dispatch
) => {
  try {
    dispatch(dispatchType.buttonNextShareHolderPhaseOne(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionUploadKtpSelfieKtp = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.uploadKtpSelfieKtp(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionButtonBackUnggahDokument = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.dataBackUnggahDokument(request));
    cookie.save("displayPage", 1);
    cookie.remove("principal", { path: "/" });
    cookie.remove("financing", { path: "/" });
    cookie.remove("current");
    localStorage.removeItem("paramsSubmit");
    cookie.remove("current", { path: "/" });
    cookie.remove("businessInformation", { path: "/" });
  } catch (error) {
    console.log(error);
  }
};

export const actionButtonBackPersonalInfo = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.buttonBackActionPersonalInfo(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionButtonBackShareHolder = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.buttonBackActionShareHolder(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionGetBusinessInfoLocalSubmit = (request) => async (
  dispatch
) => {
  try {
    dispatch(dispatchType.dataBusinessInfoActionSubmit(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionGetPersonalInfoLocal = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.dataPersonalInfoAction(request));
  } catch (error) {
    console.log(error);
  }
};

const dataOwnerDirectorInfoAction = (authData) => {
  return {
    type: TYPE.DATA_OWNER_DIRECTOR_BUSINESS_INFO_LOCAL,
    payload: authData,
  };
};

export const actionGetOwnerDirectorInfoLocal = (request) => async (
  dispatch
) => {
  try {
    dispatch(dataOwnerDirectorInfoAction(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionGetPersonalShareHolderInfoLocal = (request) => async (
  dispatch
) => {
  try {
    dispatch(dispatchType.dataPersonalInfoShareHolderAction(request));
  } catch (error) {
    console.log(error);
  }
};

export const actionGetPersonalShareHolderInfoLocalReset = (request) => async (
  dispatch
) => {
  try {
    dispatch(dispatchType.dataPersonalInfoShareHolderActionReset(request));
  } catch (error) {
    console.log(error);
  }
};

export const buttonBackApplyLoan = (
  request,
  setPage = function () {}
) => async (dispatch) => {
  try {
    dispatch(dispatchType.buttonBackAction(request));
    cookie.save("displayPage", request, {
      // path: "/",
      maxAge: 3600,
    });
    console.log("buttonBackApplyLoan");
    setPage(1);
  } catch (error) {
    console.log(error);
  }
};

export const buttonBackSelectLoan = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.buttonBackActionSelectLoan(request));
    cookie.remove("financing", { path: "/" });
    cookie.remove("principal", { path: "/" });
  } catch (error) {
    console.log(error);
  }
};

export const buttonBackBusinessInformation = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.buttonBackActionBusinessInformation(request));
  } catch (error) {
    console.log(error);
  }
};

export const getProvinceKoinvoice = (request, setIsShowLoading) => async (
  dispatch
) => {
  // setIsShowLoading(true);
  try {
    const responseDistrict = await koinvoiceServices.getProvince(request);
    dispatch(dispatchType.ProvinceSucess(responseDistrict));
    // setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.ProvinceError(error));
    if (error.status === 401) {
      history.push("/login");
    }
    // setIsShowLoading(false);
  }
};

export const getCityKoinvoice = (request, setIsShowLoading) => async (
  dispatch
) => {
  // setIsShowLoading(true);
  try {
    const responseDistrict = await koinvoiceServices.getCity(request);
    dispatch(dispatchType.citySucess(responseDistrict));
    // setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.cityError(error));
    if (error.status === 401) {
      history.push("/login");
    }
    // setIsShowLoading(false);
  }
};

export const getDistrictShareHolderKoinvoice = (
  request,
  setIsShowLoading
) => async (dispatch) => {
  // setIsShowLoading(true);
  try {
    const responseDistrict = await koinvoiceServices.getDistrict(request);
    dispatch(dispatchType.districtShareHolderSucess(responseDistrict));
    // setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.districtShareHolderError(error));
    if (error.status === 401) {
      history.push("/login");
    }
    // setIsShowLoading(false);
  }
};

export const getSubDistrictShareHolderKoinvoice = (
  request,
  setIsShowLoading
) => async (dispatch) => {
  // setIsShowLoading(true);
  try {
    const responseSubDistrict = await koinvoiceServices.getSubDistrict(request);
    dispatch(dispatchType.subDistrictShareHolderSucess(responseSubDistrict));
    //setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.subDistrictShareHolderError(error));
    if (error.status === 401) {
      history.push("/login");
    }
    //setIsShowLoading(false);
  }
};

export const getDistrictKoinvoice = (request, setIsShowLoading) => async (
  dispatch
) => {
  // setIsShowLoading(true);
  try {
    const responseDistrict = await koinvoiceServices.getDistrict(request);
    dispatch(dispatchType.districtSucess(responseDistrict));
    // setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.districtError(error));
    console.log(error);
    if (error.status === 401) {
      history.push("/login");
    }
    // setIsShowLoading(false);
  }
};

export const getSubDistrictKoinvoice = (request, setIsShowLoading) => async (
  dispatch
) => {
  // setIsShowLoading(true);
  try {
    const responseSubDistrict = await koinvoiceServices.getSubDistrict(request);
    dispatch(dispatchType.subDistrictSucess(responseSubDistrict));
    //setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.subDistrictError(error));
    if (error.status === 401) {
      history.push("/login");
    }
    //setIsShowLoading(false);
  }
};

export const getActionLimitRequest = (
  request,
  setIsShowLoading,
  setIsShowSucessResendEmail,
  params
) => async (dispatch) => {
  setIsShowLoading(true);
  setIsShowSucessResendEmail(false);
  try {
    const responseDocumentRequest = await koinvoiceServices.postSendLimitRequestKoinvice(
      request
    );
    dispatch(dispatchType.sendDocumentRequestSucess(responseDocumentRequest));
    // cookie.save("displayPage", 4, {
    //   // path: "/",
    //   maxAge: 3600,
    // });
    if (params === "unggah-dokumen") {
      history.push("/dashboard-upload-limit-application");
    }
    if (params === "unggah-dashboard") {
      history.push("/dashboard-limit-application");
    }
    cookie.remove("paramsSubmit", { path: "/" });
    cookie.remove("business-info", { path: "/" });
    cookie.remove("details-loan", { path: "/" });
    cookie.remove("principal", { path: "/" });
    cookie.remove("financing", { path: "/" });
    cookie.remove("businessInformation", { path: "/" });
    cookie.remove("loanApply", { path: "/dashboard-limit-application" });
    localStorage.removeItem("personalInfo");
    localStorage.removeItem("businessInformation");
    setIsShowLoading(false);
    setIsShowSucessResendEmail(false);
  } catch (error) {
    dispatch(dispatchType.sendDocumentRequestError(error));
    openNotificationWithIcon("error", "Internal Server Error");
    setIsShowLoading(false);
    setIsShowSucessResendEmail(false);
  }
};

export const getActionDocumentRequest = (
  request,
  setIsShowLoading,
  setIsDisabled
) => async (dispatch) => {
  setIsShowLoading(true);
  setIsDisabled(true);
  try {
    const responseDocumentRequest = await koinvoiceServices.getSendDocumentRequestKoinvice(
      request
    );
    dispatch(dispatchType.sendDocumentRequestSucess(responseDocumentRequest));
    // cookie.save("displayPage", 4, {
    //   // path: "/",
    //   maxAge: 3600,
    // });
    cookie.remove("paramsSubmit", { path: "/" });
    cookie.remove("business-info", { path: "/" });
    cookie.remove("details-loan", { path: "/" });
    cookie.remove("principal", { path: "/" });
    cookie.remove("financing", { path: "/" });
    cookie.remove("businessInformation", { path: "/" });
    localStorage.removeItem("businessInformation");
    setIsShowLoading(false);
    setIsDisabled(true);
  } catch (error) {
    dispatch(dispatchType.sendDocumentRequestError(error));
    console.log("getActionDocumentRequestGagal");
    setIsShowLoading(false);
    setIsDisabled(false);
  }
};

export const getActionDocumentUpload = (request, setIsShowLoading) => async (
  dispatch
) => {
  setIsShowLoading(true);
  try {
    const responseDocumentUpload = await koinvoiceServices.getDocumentUploadKoinvice(
      request
    );
    dispatch(dispatchType.documentUploadSucess(responseDocumentUpload));
    setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.documentUploadError(error));
    setIsShowLoading(false);
  }
};

export const actionPostResendEmail = (
  request,
  setIsShowLoading,
  setIsShowSucessResendEmail,
  paramsId
) => async (dispatch) => {
  setIsShowLoading(true);
  setIsShowSucessResendEmail(false);
  try {
    const responseResendEmail = await koinvoiceServices.postResendEmailKoinvice(
      request,
      paramsId
    );
    dispatch(dispatchType.resendEmailSucess(responseResendEmail));
    setIsShowSucessResendEmail(false);
    setIsShowLoading(false);
    history.push("/dashboard-loan-application-list");
    window.localStorage.removeItem("personalInfo");
    cookie.remove("loanApply", { path: "/" });
  } catch (error) {
    dispatch(dispatchType.resendEmailError(error));
    openNotificationWithIcon("error", error.data.message.id);
    setIsShowLoading(false);
    setIsShowSucessResendEmail(false);
  }
};

export const postCalculatorLoan = (request) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseCalculatorLoan = await koinvoiceServices.calculatorLoanKoinVoice(
      request
    );
    dispatch(dispatchType.calculatorKoinVoiceSucess(responseCalculatorLoan));
    dispatch(dispatchType.loadingShowNoShow());
  } catch (error) {
    dispatch(dispatchType.calculatorKoinVoiceError(error));
    dispatch(dispatchType.loadingShowNoShow());
  }
};

export const getLoanPrincipal = (request, token = null) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responsePrincipal = token ? 
      await koinvoiceServices.getPrincipalCode(request, token) :
      await koinvoiceServices.getPrincipalCode(request);
    cookie.save("principal", responsePrincipal, {
      path: "/",
      maxAge: 3600,
    });
    cookie.remove("financing", { path: "/" });
    dispatch(dispatchType.principalCodeSucess(responsePrincipal.data));
    dispatch(dispatchType.loadingShowNoShow());
  } catch (error) {
    dispatch(dispatchType.principalCodeError(error));
    cookie.remove("principal", { path: "/" });
    if (error?.status === 401) {
      history.push("/login");
    }
    dispatch(dispatchType.loadingShowNoShow());
  }
};

export const getLoanFinancing = (request, token) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseFinancing = await koinvoiceServices.getFinancingCode(request, token);
    if (responseFinancing.status === 200) {
      dispatch(dispatchType.financingCodeSucess(responseFinancing.data));
      cookie.save("financing", responseFinancing, {
        path: "/",
        maxAge: 3600,
      });
    } else {
      dispatch(dispatchType.financingCodeError(
        {data : responseFinancing}
      ));
    }
    cookie.remove("principal", { path: "/" });
    dispatch(dispatchType.loadingShowNoShow());
  } catch (error) {
    dispatch(dispatchType.financingCodeError(error));
    cookie.remove("financing", { path: "/" });
    // if (error?.status === 401) {
    //   history.push("/login");
    // }
    dispatch(dispatchType.loadingShowNoShow());
  }
};

export const getApplicationList = () => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseApplicationList = await koinvoiceServices.getApplicationListLoan();
    dispatch(dispatchType.applicationListSucsess(responseApplicationList));
    dispatch(dispatchType.loadingShowNoShow());
  } catch (error) {
    dispatch(dispatchType.applicationListError(error));
    dispatch(dispatchType.loadingShowNoShow());
  }
};

export const purposeLoanKoinVoice = (request, setIsShowLoading) => async (
  dispatch
) => {
  setIsShowLoading(true);
  try {
    const responsePurposeLoan = await koinvoiceServices.purposeLoanKoinvoice(
      request
    );
    cookie.save("purpose", responsePurposeLoan, {
      //path: "/",
      maxAge: 3600,
    });
    cookie.save("displayPage", 2, {
      //path: "/",
      maxAge: 3600,
    });
    dispatch(dispatchType.purposeLoanSucess(responsePurposeLoan));
    setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.purposeLoanError(error));
    cookie.save("displayPage", 1);
    cookie.remove("purpose", { path: "/" });
    setIsShowLoading(false);
    if (error.status === 401) {
      history.push("/login");
    }
  }
};

export const loanDetailsKoinvoice = (paramId) => async (dispatch) => {
  try {
    const responseLoanDetails = await koinvoiceServices.getLoanDetailKoinvice(
      paramId
    );
    dispatch(dispatchType.loanDetailsSucess(responseLoanDetails));
  } catch (error) {
    dispatch(dispatchType.loanDetailsError(error));
  }
};

export const putLoanDetailsKoinvoice = (
  request,
  setIsShowLoading = function () {},
  setIsShowAlertDrawerCongrulation = function () {}
) => async (dispatch) => {
  setIsShowLoading(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putLoanDetailKoinvice(
      request
    );
    dispatch(dispatchType.loanPutDetailsSucess(responseLoanDetails));
    setIsShowLoading(false);
    setIsShowAlertDrawerCongrulation(false);
    cookie.save("submit-detailsLoan-apply", responseLoanDetails, {
      path: "/",
      maxAge: 3600,
    });
    cookie.save("put-loan-detail-sucsess", "loan-detail-sucsess");
    setTimeout(() => {
      history.push("/koininvoice/apply-loan/upload-document");
    }, 500);
  } catch (error) {
    dispatch(dispatchType.loanPutDetailsError(error));
    openNotificationWithIcon("error", error.data.message.en);
    cookie.save("displayPage", 1);
    setIsShowLoading(false);
    setIsShowAlertDrawerCongrulation(true);
    cookie.remove("put-loan-detail-sucsess");
  }
};

export const actionPutAppGuarantorCreate = (
  request,
  setIsShowLoading,
  token
) => async (dispatch) => {
  setIsShowLoading(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putCreteShareHolderLoanKoinVoice(
      request, token
    );
    dispatch(dispatchType.putAppGuarantorCreateSucsess(responseLoanDetails));
    setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.putAppGuarantorCreateError(error));
    setIsShowLoading(false);
  }
};

const putAppGuarantorCreateOwnerDirectorSucsess = (authData) => {
  return {
    type: TYPE.APP_GUARANTOR_CREATE_OWNER_DIRECTOR_SUCSESS,
    payload: authData,
  };
};

const putAppGuarantorCreateOwnerDirectorError = (authData) => {
  return {
    type: TYPE.APP_GUARANTOR_CREATE_OWNER_DIRECTOR_ERROR,
    payload: authData,
  };
};

export const actionPutAppGuarantorCreateOwnerDirector = (
  request,
  setIsShowLoading,
  token
) => async (dispatch) => {
  setIsShowLoading(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putCreteShareHolderLoanKoinVoice(
      request, token
    );
    dispatch(putAppGuarantorCreateOwnerDirectorSucsess(responseLoanDetails));
    setIsShowLoading(false);
  } catch (error) {
    dispatch(putAppGuarantorCreateOwnerDirectorError(error));
    setIsShowLoading(false);
  }
};

export const actionPutAppGuarantorUpdate = (
  paramId,
  request,
  setIsShowLoading
) => async (dispatch) => {
  setIsShowLoading(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putUpdateShareHolderLoanKoinVoice(
      request,
      paramId
    );
    dispatch(dispatchType.putAppGuarantorUpdateSucsess(responseLoanDetails));
    setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.putAppGuarantorUpdateError(error));
    setIsShowLoading(false);
  }
};

export const businessInformationKoinvoice = (token, userId) => async (dispatch) => {
  const responseInformationDetails = await koinvoiceServices.getBusinessInfo(token);
  if (responseInformationDetails?.status === 200) {
    cookie.save("name", responseInformationDetails.data.fullname);
    cookie.save(
      "businessInformation",
      JSON.stringify(responseInformationDetails),
      {
        path: "/",
        maxAge: 3600,
      }
    );
    localStorage.setItem(
      `bisInfo-${userId}`,
      JSON.stringify(responseInformationDetails.data)
    );
    dispatch(
      dispatchType.businessInformationSucess(responseInformationDetails)
    );
  } else {
    cookie.remove("businessInformation", { path: "/" });
    dispatch(dispatchType.businessInformationError(responseInformationDetails));
  }
};

export const businessInfoKoinvoice = (token) => async (dispatch) => {
  try {
    const responseLoanDetails = await koinvoiceServices.getBusinessInfoKoinvice(token);
    dispatch(dispatchType.businessInfoSucess(responseLoanDetails));
  } catch (error) {
    dispatch(dispatchType.businessInfoError(error));
  }
};

export const putBusinessInfoKoinvoice = (
  paramId,
  request,
  setIsShowLoading,
  nextPage
) => async (dispatch) => {
  setIsShowLoading(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putBusinessInfoKoinvice(
      paramId,
      request
    );
    dispatch(dispatchType.putBusinessInfoSucess(responseLoanDetails));
    nextPage();
    setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.putBusinessInfoError(error));
    setIsShowLoading(false);
    if (error.status === 401) {
      history.push("/login");
    }
  }
};

export const putBusinessInfoProfileKoinvoice = (
  request,
  setIsShowLoading,
  nextPage = function () {},
  token
) => async (dispatch) => {
  setIsShowLoading(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putBusinessInfoProfileKoinvice(
      request, token
    );
    dispatch(dispatchType.putBusinessInfoProfileSucess(responseLoanDetails));
    setIsShowLoading(false);
    nextPage();
  } catch (error) {
    dispatch(dispatchType.putBusinessInfoProfileError(error));
    setIsShowLoading(false);
    if (error.status === 401) {
      history.push("/login");
    }
  }
};

export const putPersonalInfoKoinvoice = (
  paramId,
  request,
  setIsShowLoadingSubmitPersonalInfo
) => async (dispatch) => {
  setIsShowLoadingSubmitPersonalInfo(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putPersonalInfoKoinvice(
      paramId,
      request
    );
    dispatch(dispatchType.putPersonalInfoSucess(responseLoanDetails));
    setIsShowLoadingSubmitPersonalInfo(false);
  } catch (error) {
    dispatch(dispatchType.putPersonalInfoError(error));
    setIsShowLoadingSubmitPersonalInfo(false);
    if (error.status === 401) {
      history.push("/login");
    }
  }
};

export const putPersonalInfoProfileKoinvoice = (
  request,
  setIsShowLoadingSubmitPersonalInfo,
  token
) => async (dispatch) => {
  setIsShowLoadingSubmitPersonalInfo(true);
  try {
    const responseLoanDetails = await koinvoiceServices.putPersonalInfoProfileKoinvice(
      request, token
    );
    dispatch(dispatchType.putPersonalInfoProfileSucess(responseLoanDetails));
    setIsShowLoadingSubmitPersonalInfo(false);
  } catch (error) {
    dispatch(dispatchType.putPersonalInfoProfileError(error));
    console.log(error);
    if (error.status === 401) {
      history.push("/login");
    }
    setIsShowLoadingSubmitPersonalInfo(false);
  }
};

const uploadKtpValidation = (
  request,
  handleChangeShowUploadKtp,
  setFieldValue,
  setValidationImageKtp,
  validationImageKtp,
  setValidationImageKtpPersonal,
  validationImageKtpPersonal,
  setIsShowAlertKtp,
  token,
  currentOccupation
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  const responseKtpValidation = await koinvoiceServices.ktpValidationKoinVoice({
    params: request, token: token
  });
  if ([200, 201].includes(responseKtpValidation.status) && [200, 201].includes(responseKtpValidation.data.status)) {
    setIsShowAlertKtp(responseKtpValidation);
    let dateTimeKtpValidation =
      responseKtpValidation.data.data.ktp_assessment.date_of_birth &&
      convertReverseTimeFormat(responseKtpValidation.data.data.ktp_assessment.date_of_birth);
    setFieldValue(
      "owner_gender",
      responseKtpValidation.data.data.ktp_assessment.gender === "PEREMPUAN" ||
        responseKtpValidation.data.data.ktp_assessment.gender === "WANITA"
        ? "female"
        : "male"
    );
    setFieldValue("owner_birthdate", dateTimeKtpValidation);
    setFieldValue(
      "owner_place_of_birth",
      responseKtpValidation.data.data.ktp_assessment.place_of_birth
    );
    setFieldValue(
      "owner_name",
      responseKtpValidation.data.data.ktp_assessment.name?.replace(/[:_]/g, " ")
    );
    setFieldValue("nik", responseKtpValidation.data.data.ktp_assessment.nik);
    setFieldValue(
      "owner_marital_status",
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "BELUM KAWIN" ||
        responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "BELUM MENIKAH"
        ? "BELUM KAWIN"
        : "KAWIN"
    );
    setFieldValue("owner_address", responseKtpValidation.data.data.ktp_assessment.address);
    setFieldValue(
      "owner_district",
      responseKtpValidation.data.data.ktp_assessment.district.suggestion
    );
    setFieldValue(
      "owner_sub_district",
      responseKtpValidation.data.data.ktp_assessment.subdistrict.suggestion
    );
    setFieldValue(
      "owner_province",
      responseKtpValidation.data.data.ktp_assessment.province.suggestion
    );
    setFieldValue(
      "owner_city",
      responseKtpValidation.data.data.ktp_assessment.city.suggestion
    );
    setFieldValue(
      "ktp_occupation",
      responseKtpValidation.data.data.ktp_assessment.occupation.suggestion
    );
    if(!currentOccupation) {
      setFieldValue(
        "current_occupation",
        responseKtpValidation.data.data.ktp_assessment.occupation.suggestion
      );
    }
    dispatch(dispatchType.uploadKtpValidationSucess(responseKtpValidation));
    handleChangeShowUploadKtp();
    dispatch(dispatchType.loadingShowNoShow());
  } else {
    dispatch(dispatchType.uploadKtpValidationError(responseKtpValidation));
    dispatch(dispatchType.loadingShowNoShow());
    setValidationImageKtp(validationImageKtp + 1);
    setIsShowAlertKtp("");
    setValidationImageKtpPersonal(validationImageKtpPersonal + 1);
  }
};

export const uploadBusinessKoinVoice = (
  request,
  handleChangeShowUploadKtp,
  setFieldValue,
  setValidationImageKtp,
  validationImageKtp,
  setValidationImageKtpPersonal,
  validationImageKtpPersonal,
  setIsShowAlertKtp,
  setIsShowAlertUploadKtp,
  token,
  currentOccupation
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseUpload = await koinvoiceServices.uploadLoanKoinVoice(request);
    dispatch(dispatchType.uploadNewSucess(responseUpload));
    setFieldValue("ktp_image", responseUpload.data[0]);
    setIsShowAlertUploadKtp(responseUpload);
    dispatch(
      uploadKtpValidation(
        responseUpload.data[0],
        handleChangeShowUploadKtp,
        setFieldValue,
        setValidationImageKtp,
        validationImageKtp,
        setValidationImageKtpPersonal,
        validationImageKtpPersonal,
        setIsShowAlertKtp,
        token,
        currentOccupation
      )
    );
  } catch (error) {
    dispatch(dispatchType.loadingShowNoShow());
    setIsShowAlertUploadKtp("");
    dispatch(dispatchType.uploadNewError(error));
  }
};

const uploadKtpOwnerDirectorValidation = (
  request,
  handleChangeShowUploadKtp,
  setFieldValue,
  setValidationImageKtp,
  validationImageKtp,
  setValidationImageKtpPersonal,
  validationImageKtpPersonal,
  setIsShowAlertKtp
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  const responseKtpValidation = await koinvoiceServices.ktpValidationKoinVoice({
    params: request,
  });
  if (
    (responseKtpValidation && responseKtpValidation.status === 200 && [200, 201].includes(responseKtpValidation.data.status)) ||
    (responseKtpValidation && responseKtpValidation.status === 201 && [200, 201].includes(responseKtpValidation.data.status))
  ) {
    let maritalStatus = "39a35289-f562-11e9-97fa-00163e010bca";
    if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "BELUM MENIKAH" ||
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "BELUM KAWIN"
    ) {
      maritalStatus = "39a35289-f562-11e9-97fa-00163e010bca";
    } else if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "MENIKAH" ||
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "KAWIN"
    ) {
      maritalStatus = "39a35288-f562-11e9-97fa-00163e010bca";
    } else if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "CERAI MATI"
    ) {
      maritalStatus = "947c08d9-4588-4eb8-8efb-9892b0c66a7e";
    } else if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "CERAI MATI"
    ) {
      maritalStatus = "6d1efcbe-0c47-40a6-aa2f-9b28b5897e86";
    }
    setIsShowAlertKtp(responseKtpValidation);
    let dateTimeKtpValidation =
      responseKtpValidation.data.data.ktp_assessment.date_of_birth &&
      convertReverseTimeFormat(responseKtpValidation.data.data.ktp_assessment.date_of_birth);
    setFieldValue(
      "owner_gender",
      responseKtpValidation.data.data.ktp_assessment.gender === "PEREMPUAN" ||
        responseKtpValidation.data.data.ktp_assessment.gender === "WANITA"
        ? "female"
        : "male"
    );
    setFieldValue("owner_birthdate", dateTimeKtpValidation);
    setFieldValue(
      "owner_place_of_birth",
      responseKtpValidation.data.data.ktp_assessment.place_of_birth
    );
    setFieldValue(
      "owner_name",
      responseKtpValidation.data.data.ktp_assessment.name?.replace(/[:_]/g, " ")
    );
    setFieldValue("nik", responseKtpValidation.data.data.ktp_assessment.nik);
    setFieldValue("owner_marital_status", maritalStatus);
    setFieldValue("owner_address", responseKtpValidation.data.data.ktp_assessment.address);
    setFieldValue(
      "owner_district",
      responseKtpValidation.data.data.ktp_assessment.district.suggestion
    );
    setFieldValue(
      "owner_sub_district",
      responseKtpValidation.data.data.ktp_assessment.subdistrict.suggestion
    );
    dispatch(dispatchType.uploadKtpValidationSucess(responseKtpValidation));
    handleChangeShowUploadKtp();
    dispatch(dispatchType.loadingShowNoShow());
  } else {
    dispatch(dispatchType.uploadKtpValidationError(responseKtpValidation));
    dispatch(dispatchType.loadingShowNoShow());
    setValidationImageKtp(validationImageKtp + 1);
    setIsShowAlertKtp("");
    setValidationImageKtpPersonal(validationImageKtpPersonal + 1);
  }
};

export const uploadBusinessOwnerDirectorKoinVoice = (
  request,
  handleChangeShowUploadKtp,
  setFieldValue,
  setValidationImageKtp,
  validationImageKtp,
  setValidationImageKtpPersonal,
  validationImageKtpPersonal,
  setIsShowAlertKtp,
  setIsShowAlertUploadKtp
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseUpload = await koinvoiceServices.uploadLoanKoinVoice(request);
    dispatch(dispatchType.uploadNewSucess(responseUpload));
    setFieldValue("ktp_image", responseUpload.data[0]);
    setIsShowAlertUploadKtp(responseUpload);
    dispatch(
      uploadKtpOwnerDirectorValidation(
        responseUpload.data[0],
        handleChangeShowUploadKtp,
        setFieldValue,
        setValidationImageKtp,
        validationImageKtp,
        setValidationImageKtpPersonal,
        validationImageKtpPersonal,
        setIsShowAlertKtp
      )
    );
  } catch (error) {
    dispatch(dispatchType.loadingShowNoShow());
    setIsShowAlertUploadKtp("");
    dispatch(dispatchType.uploadNewError(error));
  }
};

const uploadKtpShareHolderValidation = (
  request,
  handleChangeShowUploadKtp,
  setFieldValue,
  setValidationImageKtp,
  validationImageKtp,
  setValidationImageKtpPersonal,
  validationImageKtpPersonal,
  setIsShowAlertKtp,
  token
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  const responseKtpValidation = await koinvoiceServices.ktpValidationKoinVoice({
    params: request, token: token
  });
  if (
    (responseKtpValidation && responseKtpValidation.status === 200 && [200, 201].includes(responseKtpValidation.data.status)) ||
    (responseKtpValidation && responseKtpValidation.status === 201 && [200, 201].includes(responseKtpValidation.data.status))
  ) {
    setIsShowAlertKtp(responseKtpValidation);
    let dateTimeKtpValidation =
      responseKtpValidation.data.data.ktp_assessment.date_of_birth &&
      convertReverseTimeFormat(responseKtpValidation.data.data.ktp_assessment.date_of_birth);
    let maritalStatus = "39a35289-f562-11e9-97fa-00163e010bca";
    if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "BELUM MENIKAH" ||
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "BELUM KAWIN"
    ) {
      maritalStatus = "39a35289-f562-11e9-97fa-00163e010bca";
    } else if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "MENIKAH" ||
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "KAWIN"
    ) {
      maritalStatus = "39a35288-f562-11e9-97fa-00163e010bca";
    } else if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "CERAI MATI"
    ) {
      maritalStatus = "947c08d9-4588-4eb8-8efb-9892b0c66a7e";
    } else if (
      responseKtpValidation.data.data.ktp_assessment.marital_status.suggestion === "CERAI MATI"
    ) {
      maritalStatus = "6d1efcbe-0c47-40a6-aa2f-9b28b5897e86";
    }
    setFieldValue(
      "gender",
      responseKtpValidation.data.data.ktp_assessment.gender === "LAKI-LAKI" ||
        responseKtpValidation.data.data.ktp_assessment.gender === "PRIA"
        ? "male"
        : "female"
    );
    setFieldValue(
      "nationality",
      responseKtpValidation.data.data.ktp_assessment.nationality === "WNI"
        ? "indonesia"
        : "foreigner"
    );
    setFieldValue("marital_status", maritalStatus);
    setFieldValue("dob", dateTimeKtpValidation);
    setFieldValue("pob", responseKtpValidation.data.data.ktp_assessment.place_of_birth);
    setFieldValue(
      "full_name",
      responseKtpValidation.data.data.ktp_assessment.name?.replace(/[:_]/g, " ")
    );
    setFieldValue("nik", responseKtpValidation.data.data.ktp_assessment.nik);
    setFieldValue("address", responseKtpValidation.data.data.ktp_assessment.address);
    setFieldValue("province", responseKtpValidation.data.data.ktp_assessment.province.suggestion);
    setFieldValue("city", responseKtpValidation.data.data.ktp_assessment.city.suggestion);
    setFieldValue("district", responseKtpValidation.data.data.ktp_assessment.district.suggestion);
    setFieldValue(
      "sub_district",
      responseKtpValidation.data.data.ktp_assessment.subdistrict.suggestion
    );
    dispatch(
      dispatchType.uploadKtpValidationShareHolderSucess(responseKtpValidation)
    );
    handleChangeShowUploadKtp();
    dispatch(dispatchType.loadingShowNoShow());
  } else {
    dispatch(
      dispatchType.uploadKtpValidationShareHolderError(responseKtpValidation)
    );
    dispatch(dispatchType.loadingShowNoShow());
    setValidationImageKtp(validationImageKtp + 1);
    setIsShowAlertKtp("");
    setValidationImageKtpPersonal(validationImageKtpPersonal + 1);
  }
};

export const uploadBusinessShareHolderKoinVoice = (
  request,
  handleChangeShowUploadKtp,
  setFieldValue,
  setValidationImageKtp,
  validationImageKtp,
  setValidationImageKtpPersonal,
  validationImageKtpPersonal,
  setIsShowAlertKtp,
  setIsShowAlertUploadKtp,
  token
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseUpload = await koinvoiceServices.uploadLoanKoinVoice(request, token);
    dispatch(dispatchType.uploadNewShareHolderSucess(responseUpload));
    setFieldValue("ktp", responseUpload.data[0]);
    setIsShowAlertUploadKtp(responseUpload);
    dispatch(
      uploadKtpShareHolderValidation(
        responseUpload.data[0],
        handleChangeShowUploadKtp,
        setFieldValue,
        setValidationImageKtp,
        validationImageKtp,
        setValidationImageKtpPersonal,
        validationImageKtpPersonal,
        setIsShowAlertKtp,
        token
      )
    );
  } catch (error) {
    dispatch(dispatchType.loadingShowNoShow());
    setIsShowAlertUploadKtp("");
    dispatch(dispatchType.uploadNewShareHolderError(error));
  }
};

const uploadFaceMatchingValidation = (
  request,
  uploadKtp,
  handleChangeShowUploadKtp,
  setValidationMatchImageKtp,
  validationMatchImageKtp,
  setValidationMatchImageKtpFailed,
  validationMatchImageKtpFailed,
  setIsShowAlertMatchKtp,
  token
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  const responseKtpValidation = await koinvoiceServices.facePhotoValidation({
    img: request,
    ktp: uploadKtp,
    token: token
  });
  if (
    (responseKtpValidation && responseKtpValidation.status === 200 && [200, 201].includes(responseKtpValidation.data.status)) ||
    (responseKtpValidation && responseKtpValidation.status === 201 && [200, 201].includes(responseKtpValidation.data.status))
  ) {
    setIsShowAlertMatchKtp(responseKtpValidation);
    dispatch(
      dispatchType.uploadSelfieMatchKtpValidationSucess(responseKtpValidation)
    );
    handleChangeShowUploadKtp();
    dispatch(dispatchType.loadingShowNoShow());
  } else {
    dispatch(
      dispatchType.uploadSelfieMatchKtpValidationError(responseKtpValidation)
    );
    dispatch(dispatchType.loadingShowNoShow());
    setValidationMatchImageKtp(validationMatchImageKtp + 1);
    setValidationMatchImageKtpFailed(validationMatchImageKtpFailed + 1);
    setIsShowAlertMatchKtp("");
  }
};

export const uploadSelfieKtpBusinessKoinVoice = (
  request,
  uploadKtp,
  handleChangeShowUploadFaceKtp,
  setFieldValue,
  setValidationMatchImageKtp,
  validationMatchImageKtp,
  setValidationMatchImageKtpFailed,
  validationMatchImageKtpFailed,
  setIsShowAlertUploadMatchKtp,
  setIsShowAlertMatchKtp,
  token
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseUploadSelfie = token ? 
      await koinvoiceServices.uploadLoanKoinVoice(request, token) :
      await koinvoiceServices.uploadLoanKoinVoice(request);
    setFieldValue("ktp_selfie_image", responseUploadSelfie.data[0]);
    dispatch(
      dispatchType.uploadSelfieKtpValidationSucess(responseUploadSelfie)
    );
    setIsShowAlertUploadMatchKtp(responseUploadSelfie);
    dispatch(
      uploadFaceMatchingValidation(
        responseUploadSelfie.data[0],
        uploadKtp,
        handleChangeShowUploadFaceKtp,
        setValidationMatchImageKtp,
        validationMatchImageKtp,
        setValidationMatchImageKtpFailed,
        validationMatchImageKtpFailed,
        setIsShowAlertMatchKtp,
        token
      )
    );
  } catch (error) {
    dispatch(dispatchType.loadingShowNoShow());
    setIsShowAlertUploadMatchKtp("");
    dispatch(dispatchType.uploadSelfieKtpValidationError(error));
  }
};

const uploadFaceMatchingShareHolderValidation = (
  request,
  uploadKtp,
  handleChangeShowUploadKtp,
  setValidationMatchImageKtp,
  validationMatchImageKtp,
  setValidationMatchImageKtpFailed,
  validationMatchImageKtpFailed,
  setIsShowAlertMatchKtp,
  token
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  const responseKtpValidation = await koinvoiceServices.facePhotoValidation({
    img: request,
    ktp: uploadKtp,
    token: token
  });
  if (
    (responseKtpValidation && responseKtpValidation.status === 200 && [200, 201].includes(responseKtpValidation.data.status)) ||
    (responseKtpValidation && responseKtpValidation.status === 201 && [200, 201].includes(responseKtpValidation.data.status))
  ) {
    setIsShowAlertMatchKtp(responseKtpValidation);
    dispatch(
      dispatchType.uploadSelfieMatchKtpShareHolderValidationSucess(
        responseKtpValidation
      )
    );
    handleChangeShowUploadKtp();
    dispatch(dispatchType.loadingShowNoShow());
  } else {
    dispatch(
      dispatchType.uploadSelfieMatchKtpShareHolderValidationError(
        responseKtpValidation
      )
    );
    dispatch(dispatchType.loadingShowNoShow());
    setValidationMatchImageKtp(validationMatchImageKtp + 1);
    setValidationMatchImageKtpFailed(validationMatchImageKtpFailed + 1);
    setIsShowAlertMatchKtp("");
  }
};

export const uploadSelfieKtpShareHolderBusinessKoinVoice = (
  request,
  uploadKtp,
  handleChangeShowUploadFaceKtp,
  setFieldValue,
  setValidationMatchImageKtp,
  validationMatchImageKtp,
  setValidationMatchImageKtpFailed,
  validationMatchImageKtpFailed,
  setIsShowAlertUploadMatchKtp,
  setIsShowAlertMatchKtp,
  token
) => async (dispatch) => {
  dispatch(dispatchType.loadingShowShow());
  try {
    const responseUploadSelfie = await koinvoiceServices.uploadLoanKoinVoice(
      request, token
    );
    setFieldValue("additional_document", responseUploadSelfie.data[0]);
    dispatch(
      dispatchType.uploadSelfieKtpShareHolderValidationSucess(
        responseUploadSelfie
      )
    );
    setIsShowAlertUploadMatchKtp(responseUploadSelfie);
    dispatch(
      uploadFaceMatchingShareHolderValidation(
        responseUploadSelfie.data[0],
        uploadKtp,
        handleChangeShowUploadFaceKtp,
        setValidationMatchImageKtp,
        validationMatchImageKtp,
        setValidationMatchImageKtpFailed,
        validationMatchImageKtpFailed,
        setIsShowAlertMatchKtp,
        token
      )
    );
  } catch (error) {
    dispatch(dispatchType.loadingShowNoShow());
    setIsShowAlertUploadMatchKtp("");
    dispatch(dispatchType.uploadSelfieKtpShareHolderValidationError(error));
  }
};

export const uploadKkValidationKoinvoice = (request, setIsLoading) => async (
  dispatch
) => {
  setIsLoading(true);
  try {
    setIsLoading(false);
    const responseKKValidation = await upload.kkValidation(request);
    dispatch(dispatchType.uploadKKValidationSucess(responseKKValidation));
  } catch (error) {
    setIsLoading(false);
    dispatch(dispatchType.uploadKKValidationError(error));
  }
};

export const uploadKKBusinessKoinVoice = (
  request,
  setFieldValue,
  setIsLoading,
  setValidationImageKk,
  validationImageKk,
  handleChangeShowUploadKk
) => async (dispatch) => {
  setIsLoading(true);
  try {
    const responseUploadKK = await koinvoiceServices.uploadLoanKoinVoice(
      request
    );
    // dispatch(uploadKkValidationKoinvoice(responseUploadKK.data[0],setIsLoading));
    setFieldValue("kk_image", responseUploadKK.data[0]);
    dispatch(dispatchType.uploadKKSucess(responseUploadKK));
    setIsLoading(false);
    handleChangeShowUploadKk();
  } catch (error) {
    setIsLoading(false);
    dispatch(dispatchType.uploadKKError(error));
  }
};
