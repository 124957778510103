const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;

function getBalance(token) {
  let path = '/v1/koinemployee/balance';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };
  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const balanceService = {
  getBalance
};