export const convertReverseTimeFormat = (value) => {
  let formatTime;
    let [year, month, day] = value && value.split("-");
    formatTime = `${day}-${month}-${year}`;
  return formatTime;
};

export const convertReverseTimeFormatYear = (value) => {
  let formatTime;
    const [year, month, day] = value && value.split("-");
    formatTime = `${year}-${month}-${day}`;
  return formatTime;
};
