import React, { useCallback, useEffect, useState } from "react";
import koinvoiceServices from "../../services/koinvoice";
import { Alert, Button, Row, Spin, notification } from "antd";
import ProgressUploadKoinInvoice from "../../Components/ProgressUploadKoinInvoice/ProgressUploadKoinInvoice";
import NotFoundListDocuments from "../../Components/NotFoundListDocuments";
import ThankYouSubmitUploadLimit from "./ThankYouSubmitUploadLimit";
import { DashboardKoinInvoiceStyle } from "../../BorrowerDashboardKoinInvoice/style";
import DashboardLayoutKoinInvoice from "../../Layout/DashboardLayoutKoinInvoice";
import { connect } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import history from "../../util/history";
import cookie from "react-cookies";

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(function DashboardUploadLimitApplication({ init: { language, flag } }) {
  const [isShowLoadingNotUploaded, setIsShowLoadingNotUploaded] = useState(
    false
  );
  const [notUploadedRejected, setNotUploadedRejected] = useState([]);
  const [isSuccsessUpploadDocument, setIsSuccessUploadDocument] = useState(
    false
  );
  const [isShowLoadingSubmit, setIsShowLoadingSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [messageError, setMessageError] = useState();
  const [lengthNotUploadedRejected, setLengthNotUploadedRejected] = useState(0);
  const [filterName, setFilterName] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    fetchNotUploadedRejected(); // eslint-disable-next-line
  }, []);

  const fetchNotUploadedRejected = async () => {
    let paramsOld = {
      product: "koin_invoice",
    };
    let response = await koinvoiceServices.documentListOld({
      params: paramsOld,
      loading: setIsShowLoadingNotUploaded,
    });
    if ([200, 201].includes(response.status)) {
      let filterDocs = response.data.filter(
        (item) => item.name !== "siup"
      );
      let filterNames = filterDocs.map(val => val.name);
      setFilterName(filterNames);
      
      let filterStatus = response.data.filter(
        (status) => status.status === "not_uploaded"
      );
      let filterStatusRejected = response.data.filter(
        (status) => status.status === "rejected"
      );
      let filtered = [...filterStatus, ...filterStatusRejected];      
      setNotUploadedRejected(filtered);
      
      filtered = filtered.filter(
        (item) => item.name !== "siup"
      );
      let docNames = filtered.map(val => val.name);
      if (docNames.every(i => filterNames.includes(i))) {
        setDisableSubmit(true);
      }
      setLengthNotUploadedRejected(
        filterStatusRejected.length + filterStatus.length
      );
    } else {
      setNotUploadedRejected([]);
      setLengthNotUploadedRejected(0);
    }
  };
  // eslint-disable-next-line
  const dataChange = useCallback((e) => {
    setData([...data, e]);
  });

  const handleChangeUpload = (e) => {
    dataChange(e);
  };

  const reUploadChange = (e, setProgress, setDataUpload) => {
    setProgress(undefined);
    setDataUpload({});
    const newList = data.filter((item) => item.type !== e.name);
    setData(newList);
  };

  const convertLabel = (target) => {
    let temp = target.split("_");
    temp = temp.map(val => {
      return val.charAt(0).toUpperCase() + val.slice(1);
    });
    return temp.join(" ");
  }

  const handleSubmitUpload = async (e) => {
    setIsShowLoadingSubmit(true);
    let dataNames = data.map(val => val.name);
    // if (data.length >= lengthNotUploadedRejected) {
    if (!filterName.includes(dataNames)) {
      let response = await koinvoiceServices.documentCompeleteAssessment({
        loading: setIsShowLoadingSubmit,
      });
      if (response?.status === 200) {
        setIsSuccessUploadDocument(true);
        cookie.save("upload-limitApplication-sucsess", "upload-limitApplication-sucsessDashboard", {
          path: "/",
          maxAge: 3600,
        });
        setTimeout(() => {
          history.push("/dashboard-limit-application");
        }, 3000);
      } else {
        setIsSuccessUploadDocument(false);
        [400, 404, 500, 502].includes(response?.status) &&
          setMessageError(`${response?.message?.id}/${response?.message?.en}`);
      }
    } else {
      if (data.length >= 1) {
        setIsSuccessUploadDocument(true);
        setIsShowLoadingSubmit(false);
        cookie.save("upload-limitApplication-sucsess", "upload-limitApplication-sucsessDashboard", {
          path: "/",
          maxAge: 3600,
        });
        setTimeout(() => {
          history.push("/dashboard-limit-application");
        }, 3000);
      }
    }
    // let result = await Promise.all(
    //   data.map((item) => {
    //     return documents.submission({
    //       params: {
    //         document_url: item.document_url,
    //         properties: {
    //           koin_invoice: {
    //             loans: [
    //               {
    //                 loan_id: "0",
    //                 notes: "Sedang di review",
    //                 status: "pending",
    //                 tag: "Uploaded, Unverified",
    //               },
    //             ],
    //           },
    //         },
    //       },
    //       id: item.document_id,
    //     });
    //   })
    // );
    // result.forEach((list) => {
    //   if (list.status === 201) {
    //     setIsSuccessUploadDocument(true);
    //     setIsShowLoadingSubmit(false);
    //   } else {
    //     setIsSuccessUploadDocument(false);
    //     setIsShowLoadingSubmit(false);
    //     setMessageError(`${list.message.id}/${list.message.en}`);
    //   }
    // });
    // let submissionOldKoinInvoice = {
    //   data: data,
    // };
    // let response = await documents.submission({
    //   params: submissionOldKoinInvoice,
    // });
    // if (response.status === 201) {
    //   setIsSuccessUploadDocument(true);
    //   setIsShowLoadingSubmit(false);
    // } else {
    //   setIsSuccessUploadDocument(false);
    //   setIsShowLoadingSubmit(false);
    //   setMessageError(`${response.message.id}/${response.message.en}`);
    //}
  };

  const openNotification = (type, fileType) => {
    let message = "", description = "";

    if(flag === "en") {
      if(type === "success") {
        message = language.text.success;
        description = language.text.docUploadDescUpload + ` ${language.text[fileType] ? language.text[fileType] : convertLabel(fileType)} ` + language.text.docUploadDescSuccess;
      } else if(type === "error") {
        message = language.text.failed;
        description = language.text.docUploadDescUpload + ` ${language.text[fileType] ? language.text[fileType] : convertLabel(fileType)} ` + language.text.docUploadDescFailed;
      }
    } else if(flag === "id") {
      if(type === "success") {
        message = language.text.success;
        description = language.text.docUploadDescSuccess + " " + language.text.docUploadDescUpload + ` ${language.text[fileType] ? language.text[fileType] : convertLabel(fileType)}`;
      } else if(type === "error") {
        message = language.text.failed;
        description = language.text.docUploadDescFailed + " " + language.text.docUploadDescUpload + ` ${language.text[fileType] ? language.text[fileType] : convertLabel(fileType)}`;
      }
    }

    notification[type]({
      message: message,
      description: description,
      placement: 'bottomRight'
    });
  };
  
  return (
    <DashboardKoinInvoiceStyle>
      <div className="kw-dashboardKinInvoiceWrapper">
        <DashboardLayoutKoinInvoice>
          <Button
            size="large"
            style={{
              marginTop: 25,
              marginBottom: 15,
            }}
            onClick={() => history.push("/dashboard-limit-application")}
          >
            <ArrowLeftOutlined /> {language.text.dashboardLimitApplication}
            {/*{language.button.back}*/}
          </Button>
          <Spin spinning={isShowLoadingNotUploaded}>
            {isSuccsessUpploadDocument === false ? (
              <div
                className="kw-uploadKoinInvoice-wrapper"
                style={{ paddingTop: 22, background: "#FFFFFF", padding: 20 }}
              >
                <Row>
                  {lengthNotUploadedRejected >= 1 ? (
                    <React.Fragment>
                      <h3>{language.text.pleaseUploadDoc}:</h3>
                      {notUploadedRejected.map((file, index) => {
                        if(file.document_id !== 0) {
                          return (
                            <React.Fragment key={index}>
                              <ProgressUploadKoinInvoice
                                language={language}
                                data={file}
                                index={index}
                                reUploadChange={reUploadChange}
                                handleChangeUpload={handleChangeUpload}
                                setMessageError={setMessageError}
                                openNotification={openNotification}
                              />
                            </React.Fragment>
                          );
                        }
                      })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h3>{language.text.pleaseUploadDoc}:</h3>
                      <NotFoundListDocuments />
                    </React.Fragment>
                  )}
                </Row>
                {messageError && (
                  <Alert
                    message={messageError}
                    style={{ marginBottom: 15, marginTop: 15 }}
                    type="error"
                  />
                )}
                {lengthNotUploadedRejected >= 1 && (
                  <Button
                    type="primary"
                    loading={isShowLoadingSubmit}
                    style={{ width: "100%", marginTop: 15 }}
                    onClick={handleSubmitUpload}
                    disabled={disableSubmit}
                    size="large"
                  >
                    {language.text.submitDoc}
                  </Button>
                )}
              </div>
            ) : (
              <ThankYouSubmitUploadLimit />
            )}
          </Spin>
        </DashboardLayoutKoinInvoice>
      </div>
    </DashboardKoinInvoiceStyle>
  );
});
