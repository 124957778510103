import { loanService } from "../../services/loan";

export const workInfo = action => {
  return {
    type: "WORK_INFO",
    ...action
  }
}

export const loanInfo = action => {
  return {
    type: "LOAN_INFO",
    ...action
  }
}

export const personalInfo = action => {
  return {
    type: "PERSONAL_INFO",
    ...action
  }
}

export const skipConnect = action => {
  return {
    type: "SKIP_CONNECT",
    ...action
  }
}

export const clearDistrictList = action => {
  return {
    type: "CLEAR_DISTRICT",
    ...action
  }
}

export const clearSubdistrictList = action => {
  return {
    type: "CLEAR_SUBDISTRICT",
    ...action
  }
}

const reqLoanSucceded = data => {
  return {
    type: "REQUEST_LOAN_SUCCEEDED",
    payload: data
  }
}

const reqLoanFailed = errors => {
  return {
    type: "REQUEST_LOAN_FAILED",
    payload: errors
  }
}

const requestLoading = ( )=> {
  return {
    type: "REQUEST_LOADING"
  }
}

export const applyLoan = (url, data, auth) => (dispatch) => {
  dispatch(requestLoading());
  loanService.reqLoan(url, data, auth.token)
    .then(res => {
      if (typeof res.data === "string")
        dispatch(reqLoanSucceded(res.data));
      else {
        dispatch(sendDocumentLink(data.document, res.data, auth));
      }
    },
    error => {
      dispatch(reqLoanFailed(error));
  });
}

const sendDocumentLink = (document, loan, auth) => (dispatch) => {
  loanService.sendDocumentLink(document, loan, auth)
    .then(res => {
      if (res.status === 200 )
        dispatch(reqLoanSucceded(res.data));
      else
        dispatch(reqLoanFailed(res.data));
    },
    error => {
      dispatch(reqLoanFailed(error));
  });
}

export const getDistrict = (term) => (dispatch) => {
  loanService.getDistrict(term)
    .then(data => {
      let district = [];
      if (data.status === 200) {
        let arr = data.data;
        arr.map(function(data) {
          district.push({
            value: data.name,
            label: data.name,
            data: data.code
          });
          return district;
        });
      }
      dispatch(getDistrictSuccess(district));
    },
    error => {
      dispatch(getDistrictFailed(error));
  });
}

const getDistrictSuccess = data => {
  return { type: "GET_DISTRICT_SUCCESS", payload: data }
}

const getDistrictFailed = errors => {
  return { type: "GET_DISTRICT_FAILED", payload: errors }
}

const getSubDistrictSuccess = data => {
  return { type: "GET_SUBDISTRICT_SUCCESS", payload: data }
}

const getSubDistrictFailed = errors => {
  return { type: "GET_SUBDISTRICT_FAILED", payload: errors }
}

export const getSubdistrict = (code,term) => (dispatch) => {
  loanService.getSubdistrict(code,term)
    .then(data => {
      let district = [];
      if (data.status === 200) {
        let arr = data.data;
        arr.map(function(data) {
          district.push({
            value: data.name.split(',')[0],
            label: data.name.split(',')[0]
          });
          return district;
        });
      }
      dispatch(getSubDistrictSuccess(district));
    },
    error => {
      dispatch(getSubDistrictFailed(error));
  });
}

export const getCompanies = (token) => (dispatch) => {
  loanService.getCompanies(token)
    .then(data => {
      if (data.status === 200) {
        let arr = [];
        data.data.map(function(data) {
          arr.push({
            value: data.vendor_name,
            label: data.vendor_name,
            data: data.vendor_id
          });
          return arr;
        });
        dispatch(getCompaniesSuccess({vendor: data.data, companies: arr}));
      } else {
        dispatch(getCompaniesFailed(data.message));
      }      
    },
    error => {
      dispatch(getCompaniesFailed(error));
  });
}

const getCompaniesSuccess = data => {
  return { type: "GET_COMPANIES_SUCCESS", payload: data }
}

const getCompaniesFailed = errors => {
  return { type: "GET_COMPANIES_FAILED", payload: errors }
}

export const getLoans = (token, page, sort, sortBy) => (dispatch) => {
  loanService.getLoans(token, page, sort, sortBy)
    .then(res => {
      dispatch(getLoansSuccess(res.data));
    },
    error => {
      dispatch(getLoansFailed(error));
  });
}

const getLoansSuccess = data => {
  return { type: "GET_LOANS_SUCCESS", payload: data }
}

const getLoansFailed = errors => {
  return { type: "GET_LOANS_FAILED", payload: errors }
}

export const getActiveLoans = (token, page, sort) => (dispatch) => {
  loanService.getActiveLoans(token, page, sort)
    .then(res => {
      dispatch(getActiveLoansSuccess(res.data));
    },
    error => {
      dispatch(getActiveLoansFailed(error));
  });
}

const getActiveLoansSuccess = data => {
  return { type: "GET_ACTIVE_LOANS_SUCCESS", payload: data }
}

const getActiveLoansFailed = errors => {
  return { type: "GET_ACTIVE_LOANS_FAILED", payload: errors }
}

export const getLoanDetail = (id, token) => (dispatch) => {
  loanService.getLoanDetail(id, token)
    .then(res => {
      dispatch(getLoanDetailSuccess(res.data));
    },
    error => {
      dispatch(getLoanDetailFailed(error));
  });
}

export const getLoanProgress = (id, token) => (dispatch) => {
  loanService.getLoanProgress(id, token)
    .then(res => {
      dispatch(getLoanProgressSuccess(res.data));
    },
    error => {
      dispatch(getLoanProgressFailed(error));
  });
}

export const getLoanSchedule = (id, token) => (dispatch) => {
  loanService.getLoanSchedule(id, token)
    .then(res => {
      dispatch(getLoanScheduleSuccess(res.data));
    },
    error => {
      dispatch(getLoanScheduleFailed(error));
  });
}

export const getLoanContract = (id, token) => (dispatch) => {
  loanService.getLoanContract(id, token)
    .then(res => {
      dispatch(getLoanContractSuccess(res.data));
    },
    error => {
      dispatch(getLoanContractFailed(error));
  });
}

export const getLoanLender = (id, token) => (dispatch) => {
  loanService.getLoanLender(id, token)
    .then(res => {
      dispatch(getLoanLenderSuccess(res.data));
    },
    error => {
      dispatch(getLoanLenderFailed(error));
  });
}

const getLoanDetailSuccess = data => {
  return { type: "GET_LOANDETAIL_SUCCESS", payload: data }
}

const getLoanDetailFailed = errors => {
  return { type: "GET_LOANDETAIL_FAILED", payload: errors }
}

const getLoanProgressSuccess = data => {
  return { type: "GET_LOANPROGRESS_SUCCESS", payload: data }
}

const getLoanProgressFailed = errors => {
  return { type: "GET_LOANPROGRESS_FAILED", payload: errors }
}

const getLoanScheduleSuccess = data => {
  return { type: "GET_LOANSCHEDULE_SUCCESS", payload: data }
}

const getLoanScheduleFailed = errors => {
  return { type: "GET_LOANSCHEDULE_FAILED", payload: errors }
}

const getLoanContractSuccess = data => {
  return { type: "GET_LOANCONTRACT_SUCCESS", payload: data }
}

const getLoanContractFailed = errors => {
  return { type: "GET_LOANCONTRACT_FAILED", payload: errors }
}

const getLoanLenderSuccess = data => {
  return { type: "GET_LOANLENDER_SUCCESS", payload: data }
}

const getLoanLenderFailed = errors => {
  return { type: "GET_LOANLENDER_FAILED", payload: errors }
}
