import React, { useState, useEffect } from "react";
import { Button, Select, Card, Spin } from "antd";
import koinvoiceServices from "../services/koinvoice";
import { capitalizeFirstLetter } from "../library/checkCapitalize";
import CardDocumentList from "../Components/CardDocumentList";
import { DocumentListStyle } from "./style";
import DrawerAllert from "./DrawerAllert";
import DrawerAllertError from "./DrawerAllert";
import { getApplicationList } from "../stores/actions/koinvoiceLoan";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../config";

const { Option } = Select;

function DocumentListAfterLoanWrapper({
  actionChangeShowDocumentList,
  buttonIsShow = false,
  buttonIsShowPage = false,
  actionChangeShowDashboard,
  getApplicationList,
  isShow = false,
  init: { language },
  dataRepayment,
  koinvoice: { applicationListError },
}) {
  const [reverseAuthDocument, setReverseAuthDocument] = useState([]);
  const [reverseFilter, setReverseFilter] = useState([]);
  const [listDocumentLast, setListDocumentLast] = useState();
  const [listDocumentNotUploaded, setListDocumentNotUploaded] = useState([]);
  const [listDocumentPending, setListDocumentPending] = useState([]);
  const [listDocumentRejected, setListDocumentRejected] = useState([]);
  const [isShowAlertSucsess, setIsShowAlertSucsess] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowLoadingNotUploaded, setIsShowLoadingNotUploaded] = useState(
    false
  );
  const [isShowLoadingPending, setIsShowLoadingPending] = useState(false);
  const [isShowLoadingRejected, setIsShowLoadingRejected] = useState(false);
  const [isShowAlertError, setIsShowAlertError] = useState(false);
  const baseUrl = config.document.urlLoanKoininvoice;
  //const mounted = useRef();

  useEffect(() => {
    //3b6b220b-2019-4279-8343-cbe6167f7547
    let productsIdType = {
      product: "a1fb40e7-e9c5-11e9-97fa-00163e010bca",
    };
    koinvoiceServices
      .listLoanStatusUpdate({
        product: productsIdType,
        loading: setIsShowLoading,
      })
      .then((result) => {
        setReverseAuthDocument(result.data);
        // const tempReversedAuthDocument = [];
        // if (result && result.data) {
        //   if (result && result.data !== null) {
        //     for (let i = result && result.data.length - 1; i >= 0; i--) {
        //       tempReversedAuthDocument.push(result.data[i]);
        //     }
        //     setReverseAuthDocument(tempReversedAuthDocument);
        //   }
        // }
      });
    getApplicationList(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShow) {
      setReverseFilter(
        reverseAuthDocument.filter((loans) => {
          return loans.id === dataRepayment.id;
        })
      );
    } //eslint-disable-next-line
  }, [reverseAuthDocument, dataRepayment]);

  useEffect(() => {
    setListDocumentLast(
      isShow
        ? reverseFilter[0] && reverseFilter[0].id
        : reverseAuthDocument[0]
        ? reverseAuthDocument[0].id
        : undefined
    ); // eslint-disable-next-line
  }, [reverseAuthDocument, reverseFilter]);

  function refreshDocuments() {
    getDocumentListNotUploaded();
    getDocumentListPending();
    getDocumentListRejected();
  }

  useEffect(() => {
    if (listDocumentLast) {
      refreshDocuments();
    }
    // eslint-disable-next-line
  }, [listDocumentLast]);

  async function getDocumentListNotUploaded() {
    let params = {
      loan_id: listDocumentLast,
      status: "not_uploaded",
    };
    let documentList = await koinvoiceServices.documentList({
      params: params,
      loading: setIsShowLoadingNotUploaded,
    });
    setListDocumentNotUploaded(documentList && (documentList.data || []));
  }

  async function getDocumentListPending() {
    let params = {
      loan_id: listDocumentLast,
      status: "pending",
    };
    let documentList = await koinvoiceServices.documentList({
      params: params,
      loading: setIsShowLoadingPending,
    });
    setListDocumentPending(documentList && (documentList.data || []));
  }

  async function getDocumentListRejected() {
    let params = {
      loan_id: listDocumentLast,
      status: "rejected",
    };
    let documentList = await koinvoiceServices.documentList({
      params: params,
      loading: setIsShowLoadingRejected,
    });
    setListDocumentRejected(documentList && (documentList.data || []));
    // setListDocumentNotUploaded((currentDocuments) => {
    //   return [
    //     ...(currentDocuments ? currentDocuments : []),
    //     ...(documentList && documentList.data ? documentList.data : []),
    //   ];
    // });
  }

  function actionChangeSelectListDocument(value) {
    let txt = value;
    let numb = txt.match(/\d/g);
    numb = numb.join("");
    setListDocumentLast(parseInt(numb));
  }

  function resolveCurrentSelectedLoanValue() {
    let selectedLoan =
      reverseAuthDocument.length > 0 &&
      reverseAuthDocument.find((loan) => {
        return loan.id === listDocumentLast;
      });
    if (
      !selectedLoan &&
      (!reverseAuthDocument || reverseAuthDocument.length < 1)
    ) {
      return "No Loans";
    }
    if (!selectedLoan) {
      return `${capitalizeFirstLetter(reverseAuthDocument[0].description)} (${
        reverseAuthDocument[0].id
      })`;
    }
    return `${capitalizeFirstLetter(selectedLoan.description)} (${
      selectedLoan.id
    })`;
  }

  function listCardNotUploaded() {
    return (
      listDocumentNotUploaded &&
      listDocumentNotUploaded.length > 0 &&
      listDocumentNotUploaded.map((list, index) => {
        return (
          <React.Fragment key={index}>
            <CardDocumentList
              language={language}
              list={list}
              label={list.key || list.name}
              labelUploaded="Not Uploaded"
            />
          </React.Fragment>
        );
      })
    );
  }

  function listCardPending() {
    return (
      listDocumentNotUploaded &&
      listDocumentPending.length > 0 &&
      listDocumentPending.map((list, index) => {
        return (
          <React.Fragment key={index}>
            <CardDocumentList
              language={language}
              list={list}
              label={list.key || list.name}
              labelUploaded="Pending"
            />
          </React.Fragment>
        );
      })
    );
  }

  function listCardRejected() {
    return (
      listDocumentNotUploaded &&
      listDocumentRejected.length > 0 &&
      listDocumentRejected.map((list, index) => {
        return (
          <React.Fragment key={index}>
            <CardDocumentList
              language={language}
              list={list}
              label={list.key || list.name}
              labelUploaded="Rejected"
            />
          </React.Fragment>
        );
      })
    );
  }

  function actionChangeShowAlerDrawer() {
    setIsShowAlertSucsess(!isShowAlertSucsess);
  }

  function actionChangeShowAlerDrawerError() {
    setIsShowAlertError(!isShowAlertError);
  }

  function getLoginLink(baseUrl) {
    //const timestamp = new Date().getTime() / 1000;
    //const loginCode = btoa(btoa(`${cookie.load("token")}||${timestamp}`));
    return `${baseUrl}login`;
  }

  // Object.values(
  //   listDocumentNotUploaded !== undefined &&
  //     listDocumentNotUploaded.length > 0 &&
  //     listDocumentNotUploaded
  // ).forEach((value) => {
  //   const listValue = {
  //     group: value.category || value.group,
  //     type: value.key || value.name,
  //   };
  //   documents.push(listValue);
  // });

  // Object.values(
  //   listDocumentPending !== undefined &&
  //     listDocumentPending.length > 0 &&
  //     listDocumentPending
  // ).forEach((value) => {
  //   const listValue = {
  //     group: value.category || value.group,
  //     type: value.key || value.name,
  //   };
  //   documents.push(listValue);
  // });

  // Object.values(
  //   listDocumentRejected !== undefined &&
  //     listDocumentRejected.length > 0 &&
  //     listDocumentRejected
  // ).forEach((value) => {
  //   const listValue = {
  //     group: value.category || value.group,
  //     type: value.key || value.name,
  //   };
  //   documents.push(listValue);
  // });

  // function actionChangeDocumentSendLink() {
  //   let sendLinkDocuments = {
  //     email: email,
  //     loan_id: listDocumentLast,
  //   };
  //   koinvoiceServices
  //     .documentSendLink({
  //       params: sendLinkDocuments,
  //     })
  //     .then(() => {
  //       actionChangeShowAlerDrawer();
  //     })
  //     .catch(() => {
  //       actionChangeShowAlerDrawerError();
  //     });
  // }

  return (
    <Spin
      spinning={
        isShowLoading ||
        isShowLoadingNotUploaded ||
        isShowLoadingPending ||
        isShowLoadingRejected
      }
    >
      <Card>
        <div>
          {buttonIsShow && (
            <Button size="large" onClick={() => actionChangeShowDocumentList()}>
              {language.button.back}
            </Button>
          )}
          {buttonIsShowPage && (
            <Button size="large" onClick={() => actionChangeShowDashboard("")}>
              {language.button.back}
            </Button>
          )}
          <h2
            style={{
              textAlign: "center",
              fontSize: 20,
              color: " #2B486D",
            }}
          >
            {language.loanDashboard.requiredDocument}
          </h2>
        </div>
        <Select
          size={"large"}
          style={{ marginTop: 25, width: "100%" }}
          value={resolveCurrentSelectedLoanValue()}
          onChange={actionChangeSelectListDocument}
        >
          {reverseAuthDocument.length > 0 &&
            reverseAuthDocument.map((data, index) => {
              return (
                <Option
                  value={`${capitalizeFirstLetter(data.description)} (${
                    data.id
                  })`}
                  key={index}
                >
                  {`${capitalizeFirstLetter(data.description)} (${data.code})`}
                </Option>
              );
            })}
        </Select>
        {listDocumentNotUploaded.length < 1 &&
        listDocumentPending.length < 1 &&
        listDocumentRejected.length < 1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50%",
            }}
          >
            <img src={require("../assets/images/notfound.png").default} alt="" />
          </div>
        ) : (
          <React.Fragment>
            <DocumentListStyle>
              <div className="kw-documentList">
                {/*<strong>
                Please upload the document(s) through &nbsp;
                <a
                  href={getLoginLink(baseUrl)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  this link
                </a>
                  or through the link sent to your email.
              </strong> */}
                {/* <Button
                  size="large"
                  className="kw-documentList__send-link"
                  onClick={() => actionChangeDocumentSendLink()}
                >
                  {language.loanDashboard.resendEmail}
                </Button> */}
                 <a
                  href={getLoginLink(baseUrl)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button
                    size="large"
                    style={{ marginTop: 15 }}
                    className="kw-documentList__redirect-submission"
                    //onClick={()=>getLoginLink(baseUrl)}
                  >
                    {language.loanDashboard.goToDocumentSubmission}
                  </Button></a>
              </div>
            </DocumentListStyle>
            {listCardNotUploaded()}
            {listCardPending()}
            {listCardRejected()}
          </React.Fragment>
        )}
        <DrawerAllert
          visible={isShowAlertSucsess}
          image={require("../assets/images/dashboard/mailresend.png").default}
          onClose={actionChangeShowAlerDrawer}
          labelText={"We Have Sent You An Email"}
          paragraphText={
            "Please check your email and upload all the documents required."
          }
        />
        <DrawerAllertError
          visible={isShowAlertError}
          image={require("../assets/images/dashboard/mailnotsend.png").default}
          onClose={actionChangeShowAlerDrawerError}
          labelText={"We Were Unable to Send An Email to You "}
          paragraphText={
            "Please make sure your device is connected to the internet to continue. "
          }
        />
      </Card>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
  init: state.init,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentListAfterLoanWrapper);
