import styled from "styled-components";
import bgSolution from "../../assets/images/bg2.png";

export const HomepageWrapper = styled.div`
  background: #ffffff;

  .main-banner {
    padding-top: 30px;
    padding-bottom: 30px;
    .main-heading {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 54px;
      line-height: 64px;
      letter-spacing: 0.41px;
      color: #2b486d;
    }
    .main-text {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.38px;
      color: #566068;
    }
    .store {
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #2d2d2d;
        margin-bottom: 7px;
      }
      .store-link {
        display: flex;
        .store-wrapper {
          width: 115px;
          &:first-child {
            margin-right: 15px;
          }
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .reg-wrapper {
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #2d2d2d;
        margin-top: 15px;
        margin-bottom: 0;
      }
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #378df4;
        i {
          font-size: 18px;
        }
      }
    }
    .main-banner-img {
      height: 100%;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      img {
        object-fit: contain;
        width: 100%;
      }
    }
    .btn-simulate {
      margin-left: 10px;
    }
    @media screen and (max-width: 500px){
      .btn-simulate {
      margin-left: 0px;
    }
    }
  }
  .calculator {
    background: #f8fcff;
    padding-top: 130px;
    .heading {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 54px;
      line-height: 64px;
      text-align: center;
      letter-spacing: 0.41px;
      color: #2b486d;
      margin-bottom: 60px;
    }
    .card {
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
      border-radius: 3px;

      .card-title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 41px;
        letter-spacing: 0.41px;
      }
      .card-text {
        width: 325px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        span {
          color: #378df4;
          cursor: pointer;
        }
      }
      .btn-simulate {
        padding: 10px 25px;
        border-radius: 3px;
      }
      &.card-adv {
        .card-title {
          color: #2b486d;
        }
        .card-text {
          color: #2b486d;
        }
        .btn-simulate {
          background: #378df4;
          color: #ffffff;
        }
      }
      &.card-reg {
        background: #2b486d;
        .card-title {
          color: #ffffff;
        }
        .card-text {
          color: #ffffff;
        }
        .btn-simulate {
          background: #ffffff;
          color: #071e35;
        }
      }
    }
    .accordion {
      .btn-apply {
        width: 100%;
        background-color: #378df4;
        color: #ffffff;
      }
    }
    .collapse,
    .collapsing {
      margin-top: 25px;
      .MuiFormControl-root {
        background-color: #fff;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background: #fff;
      }
    }
  }
  .secondary-banner {
    padding-top: 130px;
    padding-bottom: 130px;
    .banner-wrapper {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .store {
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #2d2d2d;
        margin-bottom: 0;
      }
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #2d2d2d;
        margin-bottom: 10px;
      }
      .store-link {
        display: flex;
        .store-wrapper {
          height: 40px;
          &:first-child {
            margin-right: 10px;
          }
          img {
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .content {
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 54px;
        line-height: 64px;
        letter-spacing: 0.41px;
        color: #071e35;
        margin-bottom: 70px;
      }
      .content-list-wrapper {
        .content-list {
          display: flex;
          margin-bottom: 55px;
          &:last-child {
            margin-bottom: 0;
          }
          .icon-wrapper {
            margin-right: 15px;
            .icon {
              width: 80px;
              img {
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
      .text-wrapper {
        .heading {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 0.35px;
          color: rgba(45, 45, 45, 0.85);
          margin-bottom: 5px;
        }
        .text {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.41px;
          color: #566068;
          margin-bottom: 0;
        }
      }
    }
  }
  .solution {
    padding-top: 130px;
    padding-bottom: 130px;
    background-color: #f8fcff;
    background-image: url(${bgSolution});
    .heading {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 54px;
      line-height: 64px;
      letter-spacing: 0.41px;
      color: #071e35;
      margin-bottom: 80px;
    }
    .content {
      .icon-wrapper {
        height: 75px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .text-wrapper {
        .heading {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: 0.38px;
          color: #2b486d;
          margin-top: 15px;
          margin-bottom: 10px;
        }
        .text {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.41px;
          color: #566068;
        }
      }
    }
    .reg-wrapper {
      text-align: center;
      margin-top: 25px;
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #2d2d2d;
        margin-top: 15px;
        margin-bottom: 0;
      }
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #378df4;
        i {
          font-size: 18px;
        }
      }
    }
  } 
`;
