import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import DetailsLoan from "./components/DetailsLoan";
import koinvoiceServices from "../services/koinvoice";
import koinbisnisServices from "../services/koinbisnis";
import ProgressLoan from "./components/ProgressLoan.jsx";
import Schedule from "./components/Schedule";
import Lender from "./components/Lender";
import WaitingForContract from "./components/WaitingForContract";

function DetailInformationLoan({
  dataRepayment,
  actionChangeShowDocumentList,
  language,
}) {
  const [activeTab, setActiveTab] = useState("1");
  const [
    informationDetailLoanKoinBisnis,
    setInformationDetailLoanKoinBisnis,
  ] = useState("");
  const [informationDetailLoan, setInformationDetailLoan] = useState("");
  const [loanProgressKoinBisnis, setLoanProgressKoinBisnis] = useState("");
  const [loanScheduleKoinBisnis, setLoanScheduleKoinBisnis] = useState("");
  const [loanLenderKoinBisnis, setLoanLenderKoinBisnis] = useState("");
  const [loanContractKoinBisnis, setLoanContractKoinBisnis] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    koinvoiceServices
      .actionInformationDetailLoan({ paramsId: dataRepayment.id })
      .then((response) => {
        setInformationDetailLoan(response.data);
      });
    koinbisnisServices
      .actionGetInformationLoanDetailKoinBisnis({ paramsId: dataRepayment.id })
      .then((response) => {
        setInformationDetailLoanKoinBisnis(response.data);
      });
    koinbisnisServices
      .actionGetLoanProgress({ paramsId: dataRepayment.id })
      .then((response) => {
        setLoanProgressKoinBisnis(response.data);
      });
    koinbisnisServices
      .actionGetLoanSchedule({ paramsId: dataRepayment.id })
      .then((response) => {
        setLoanScheduleKoinBisnis(response.data);
      });
    koinbisnisServices
      .actionGetLoanLender({ paramsId: dataRepayment.id })
      .then((response) => {
        setLoanLenderKoinBisnis(response.data);
      });
    koinbisnisServices
      .actionGetLoanContract({ paramsId: dataRepayment.id })
      .then((response) => {
        setLoanContractKoinBisnis(response.data);
      }); // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      {" "}
      <Button
        size="large"
        style={{ marginBottom: 25 }}
        onClick={() => actionChangeShowDocumentList()}
      >
        {language.button.back}
      </Button>
      <Card>
        <h4 style={{ textAlign: "center" }}>
          {language.loanFormKoinvoice.headingDetails}
        </h4>{" "}
        <Nav tabs style={{ borderBottom: "unset", marginTop: 20 }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {language.label.loan}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {language.title.progress}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              {language.loanDashboard.schedule}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              {language.label.lender}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggle("5");
              }}
            >
              {"Document"}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col md={24}>
                <DetailsLoan
                  dataRepayment={dataRepayment}
                  language={language}
                  informationDetailLoan={informationDetailLoan}
                  informationDetailLoanKoinBisnis={
                    informationDetailLoanKoinBisnis
                  }
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col md={24}>
                <ProgressLoan
                  loanProgressKoinBisnis={loanProgressKoinBisnis}
                  dataRepayment={dataRepayment}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col md={24}>
                <Schedule
                  loanScheduleKoinBisnis={loanScheduleKoinBisnis}
                  dataRepayment={dataRepayment}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col md={24}>
                <Lender
                  loanLenderKoinBisnis={loanLenderKoinBisnis}
                  language={language}
                  dataRepayment={dataRepayment}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col md={24}>
                <WaitingForContract
                  language={language}
                  waitingForContract={loanContractKoinBisnis}
                  dataRepayment={dataRepayment}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Card>
    </React.Fragment>
  );
}

export default DetailInformationLoan;
