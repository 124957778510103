const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;

function getClearance(token) {
  let path = '/v1/koinemployee/loan/request/clearance';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getClearanceDetail(id,token) {
  let path = '/v1/koinemployee/loan/request/clearance/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function requestClearance(ids,token) {
  let path = '/v1/koinemployee/loan/request/clearance';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        loan_ids: ids
      })
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function requestPayment(ids, amount, token) {
  let path = '/v1/koinemployee/loan/request/clearance/payment';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        loan_id: ids[0],
        payment_amount : amount
      })
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const clearanceService = {
  getClearance,
  getClearanceDetail,
  requestClearance,
  requestPayment
};