import {
  serviceWithToken,
  serviceWithoutToken, serviceOcrUpload, serviceOcrUploadV3
} from "./httpClient";

/** Service With Token */
export const apiGetWithToken = (url, params, token = null) => {
  if (token) {
    return serviceWithToken(token).get(url, {
      params: params
    });
  } else {
    return serviceWithToken().get(url, {
      params: params
    });
  }
};

export const apiPostWithToken = (url, params) => {
  if (params && params.token) {
    return serviceWithToken(params.token).post(url, params)
  } else {
    return serviceWithToken().post(url, params)
  }
};

export const apiPostWithTokenOcr = (url,params, token = null)=>{
  if (token) {
    return serviceOcrUpload(token).post(url,params)
  } else {
    return serviceOcrUpload().post(url,params)
  }
}

export const apiPostWithTokenOcrV3 = (url,params, token = null)=>{
  if (token) {
    return serviceOcrUploadV3(token).post(url,params)
  } else {
    return serviceOcrUploadV3().post(url,params)
  }
}

export const apiPatchWithToken = (url, params) => {
  return serviceWithToken().patch(url, params);
};

export const apiPutWithToken = (url, params) => {
  return serviceWithToken().put(url, params);
};

export const apiDeleteWithToken = (url, token = null) => {
  if(token){
    return serviceWithToken(token).delete(url);
  } else {
    return serviceWithToken().delete(url);
  }
};

/** Service Without Token */
export const apiGetWithoutToken = (url, params = null) => {
  return serviceWithoutToken().get(url, {
    params: params
  });
};

export const apiPostWithoutToken = (url, params) => {
  return serviceWithoutToken().post(url, params);
};

export const apiPatchWithoutToken = (url, params) => {
  return serviceWithoutToken().patch(url, params);
};

export const apiPutWithoutToken = (url, params) => {
  return serviceWithoutToken().put(url, params);
};

export const apiDeleteWithoutToken = url => {
  return serviceWithoutToken().delete(url);
};