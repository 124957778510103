import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, Accordion } from "react-bootstrap";
import { StepConnectWrapper } from "./StepConnect.styles";
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { skipConnect } from "../../stores/actions/loan";
import { connectAccount } from "../../stores/actions/account";
import { Banks } from "../common/data";

import ConnectModal from "./Components/ConnectModal";
import SidePanelIns from "./Components/SidePanelIns";

class StepConnect extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    open: false,
    show: false,
    show1: false,
    collapse: false,
    bank_name: null,
    bank_code: null,
    username: "",
    password: "",
    isPaneOpen: false
  };

  // handleOpen = () => {
  //   this.setState({ open: true });
  // };
  // handleClose = () => {
  //   this.setState({ open: false });
  // };
  onChangeUsername = e => {
    this.setState({ username: e.target.value });
  }

  onChangePassword = e => {
    this.setState({ password: e.target.value });
  }

  handleConnect = e => {
    e.preventDefault()
    let companyRef = this.props.loan.data.company_referral;
    let { auth } = this.props;
    let data = {
      username: this.state.username,
      password: this.state.password,
      vendor_id: companyRef.vendor_id,
      type: "bank",
      bank: this.state.bank_name
    }
    this.props.connectAccount(data, auth.token);
  }

  handleSubmit = e => {
    e.preventDefault();
    let { auth, loan } = this.props;
    let companyRef = loan.data.company_referral;

    loan.data['company_referal'] = {
      vendor_name: companyRef.vendor_name,
      pic_name: companyRef.pic_name,
      pic_phone_number: companyRef.pic_phone_number,
      pic_email: auth.email
    };

    this.props.skipConnect({data : loan.data});
    this.props.handleSubmit();
  };

  handleSkip = e => {
    e.preventDefault();
    let { auth, loan } = this.props;
    let companyRef = loan.data.company_referral;
    let document = [];
    document.push({
      group: "personal_finance_document",
      type: "bank_account_mutation"
    })
    if (loan.data.loan_amount >= 15000000) {
      document.push({
        group: "personal_spouse_document",
        type: "ktp"
      })
    }

    loan.data['company_referal'] = {
      vendor_name: companyRef.vendor_name,
      pic_name: companyRef.pic_name,
      pic_phone_number: companyRef.pic_phone_number,
      pic_email: auth.email
    };
    loan.data['document'] = document;

    this.props.skipConnect({data : loan.data});
    this.props.handleSubmit();
  };

  // modal
  handleClose = () => this.setState({ show: false });
  handleShow = (code,bank) => {
    this.setState({
      show: true,
      bank_name: bank,
      bank_code: code
    });
  };
  handleClose1 = () => this.setState({ show1: false });
  handlePane = () => this.setState({ isPaneOpen: true });

  handleCollapse = (val) => {
    this.setState({ collapse: !val })
  }

  render() {
    let { account, init:{language, flag} } = this.props;

    return (
      <StepConnectWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title">{language.title.stepConnect}</h2>
          <div className="input-wrapper mb-4">
            <p className="conf-text">
              {language.text.stepConnect}
            </p>
            <p className="conf-attention-text">
              {language.text.stepConnectAttention}
            </p>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="connect-bank">
              <div className="input-wrapper">
                <div className="single-bank">
                  <span className="bank-name">BCA</span>
                  <div className="btn btn-connect" onClick={() => this.handleShow("BCA", "BCA")}>
                    <p>+ {account.isConnected ? 
                          language.button.disconnect : language.button.connect
                        }
                    </p>
                  </div>
                </div>
                {account.isConnected && (
                  <div className="connected-account">
                    <p className="account-name">{language.label.accountConnected}</p>
                    <p className="account-edit">
                      <i className="fa fa-ellipsis-h"></i>
                    </p>
                  </div>
                )}
                <div className="line"></div>
              </div>
              <Accordion>
                <Accordion.Collapse eventKey="0">
                  <div>
                    {Banks.map(data => (
                      <div className="input-wrapper" key={data.code}>
                        <div className="single-bank">
                          <span className="bank-name">{data.name}</span>
                          <div
                            className="btn btn-connect" 
                            onClick={() => this.handleShow(data.code, data.name)} >
                            <p>+ {account.isConnected ? 
                                  language.button.disconnect : language.button.connect
                                }
                            </p>
                          </div>
                        </div>
                        <div className="line"></div>
                      </div>
                    ))}    
                  </div>                
                </Accordion.Collapse>
                <div className="other-bank text-center">
                  <Accordion.Toggle 
                    as={Button}
                    variant="link"
                    eventKey="0"
                    onClick={ () => this.handleCollapse(this.state.collapse)} 
                  >
                    {this.state.collapse ? (
                      <div>
                        {language.label.showLess} <i className="fa fa-chevron-up" aria-hidden="true"></i>
                      </div>
                    ) : (
                      <div>
                        {language.label.otherBank} <i className="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                    )}
                  </Accordion.Toggle>
                </div>
              </Accordion>
            </div>
            {/* <div className="input-wrapper">
              <button
                className={"step-next btn btn-primary btn-disable-hover w-100"}
                disabled={!account.isConnected}
              >
                {language.button.proceed}
              </button>
            </div> */}
          </form>

          <div className="bank-mutation">
            <div className="side-line float-left"></div>
            <div className="side-line float-right"></div>
            <div className="bank-mutation-head">
              <span>{language.homepage.or}</span>
            </div>
            <p className="bank-mutation-text">
              {language.text.last3months}
            </p>
            <p>{language.text.bankMutationFileSize}</p>
            <p className="example-text">{language.text.exampleBankMutation}
              <span onClick={this.handlePane}>
                <i className="fa fa-question-circle" aria-hidden="true"></i>
              </span>
            </p>
          </div>
          <div className="input-wrapper">
            <div className="btn btn-primary-outline">
              {language.button.upload} {language.text.stMonth}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="btn btn-primary-outline">
            {language.button.upload} {language.text.ndMonth}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="btn btn-primary-outline">
            {language.button.upload} {language.text.rdMonth}
            </div>
          </div>
          {/* <div className="input-wrapper">
            <button
              className={"step-next btn btn-primary btn-disable-hover w-100"}
              onClick={this.handleSkip}
            >
              {language.button.skip}
            </button>
          </div> */}
        </div>

        {/* modal connect */}
        <ConnectModal
          show={account.isConnected ? false :this.state.show}
          handleClose={this.handleClose}
          handleSubmit={this.handleConnect}
          handleUsername={this.onChangeUsername}
          handlePassword={this.onChangePassword}
          state={this.state}
          account={account}
          flag={flag}
          language={language}
        />

        {/* side panel bank mutation instruction */}
        <SlidingPane
          className='side-modal payment-detail-modal'
          overlayClassName='payment-detail-overlay'
          width='540px'
          ariaHideApp={false}
          isOpen={this.state.isPaneOpen}
          onRequestClose={() => {
            this.setState({ isPaneOpen: false });
          }}>
          <SidePanelIns
            language={language} 
            onClose={() => {
              this.setState({ isPaneOpen: false });
            }} 
          />
        </SlidingPane>
        
      </StepConnectWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  loan: state.loan,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ skipConnect, connectAccount }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(StepConnect);

export default reduxForm({
  form: "StepConnect",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(component);
