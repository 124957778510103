import React, { useEffect, useState, useRef } from "react";
import DashboardLayoutKoinInvoice from "../../Layout/DashboardLayoutKoinInvoice";
import { DashboardKoinInvoiceStyle } from "../../BorrowerDashboardKoinInvoice/style";
import CardScheduleKoinInvoice from "../../Components/CardScheduleKoinInvoice";
import koinvoiceServices from "../../services/koinvoice";
import schemaScheduleFilter from "./schemaScheduleFilter";
import { Spin, Card } from "antd";
import NotFound from "../../Components/NotFound";
import moment from "moment";
import currencyRupiah from "../../library/currency";
import {
  getApplicationList,
  businessInformationKoinvoice,
} from "../../stores/actions/koinvoiceLoan";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DrawerConfirmPayment from "../../Components/DrawerConfirmPayment";
import PaymentInstructions from "../../Components/PaymentInstructions";
import DrawerShortKoinInvoice from "../../Components/DrawerShortKoinInvoice";
import DrawerFilterKoinInvoice from "../../Components/DrawerFilterKoinInvoice";
import FilterKoinInvoiceYourLoan from "../../Components/FilterKoinInvoiceYourLoan";

function ScheduleKoinInvoice({
  getApplicationList,
  koinvoice: { applicationListError, businessInformation },
}) {
  const [listSchedule, setListSchedule] = useState([]);
  const [parameter, setParameter] = useState(schemaScheduleFilter);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [dataRepayment, setDataRepayment] = useState({});
  const [isShowNotFoundSchedule, setIsShowNotFoundSchedule] = useState(true);
  const [isShowPaymentInstructions, setIsShowPaymentInstructions] =
    useState(false);
  const [isShowRepayment, setIsShowRepayment] = useState(false);
  const [isShowDrawerShortFilter, setIsShowDrawerShortFilter] = useState(false);
  const [isShowDrawerFilter, setIsShowDrawerFilter] = useState(false);
  const isInitialRender = useRef(true);
  const mounted = useRef();

  useEffect(() => {
    getScheduleList();
    getApplicationList();
    businessInformationKoinvoice(); //eslint-disable-next-line
  }, []);

  useEffect(() => {
    isInitialRender.current
      ? (isInitialRender.current = false)
      : getScheduleList(); // eslint-disable-next-line
  }, [parameter]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (
        applicationListError.data &&
        applicationListError.data.status === 404
      ) {
        //history.push("/koininvoice/apply-loan");
      }
    }
  });

  function actionChangeShowRepayment(item) {
    setIsShowRepayment(!isShowRepayment);
    setDataRepayment(item);
  }

  function actionChangePaymentInstructions() {
    setIsShowPaymentInstructions(!isShowPaymentInstructions);
  }

  async function getScheduleList() {
    let response = await koinvoiceServices.dashboardScheduleList({
      params: parameter,
      loading: setIsShowLoading,
    });
    if (response.status === 200) {
      setListSchedule(response);
      setIsShowNotFoundSchedule(true);
    } else {
      setIsShowNotFoundSchedule(false);
      setListSchedule([]);
    }
  }
  function actionShowHideShortDrawerFilter(idCondition, params) {
    setIsShowDrawerShortFilter(!isShowDrawerShortFilter);
    idCondition === "apply" &&
      setParameter({
        ...parameter,
        sort_type: params && params.sort_type,
      });
  }

  function actionShowHideFilterDrawer(idCondition, params) {
    setIsShowDrawerFilter(!isShowDrawerFilter);
    idCondition === "apply" &&
      setParameter({
        ...parameter,
        filter: params && params.filter,
      });
  }

  return (
    <DashboardKoinInvoiceStyle>
      <div className="kw-dashboardKinInvoiceWrapper">
        <DashboardLayoutKoinInvoice>
          <div className="kw-scheduleList-wrapper">
            <Card>
              {isShowPaymentInstructions === true ? (
                ""
              ) : (
                <FilterKoinInvoiceYourLoan
                  actionShowHideShortDrawerFilter={
                    actionShowHideShortDrawerFilter
                  }
                  actionShowHideFilterDrawer={actionShowHideFilterDrawer}
                />
              )}
              <Spin
                spinning={isShowLoading}
                style={{ height: isShowLoading ? "100vh" : "auto" }}
              >
                {isShowNotFoundSchedule ? (
                  isShowRepayment === false ? (
                    <React.Fragment>
                      {listSchedule?.data?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: 25,
                              }}
                            >
                              <strong className="kw-scheduleList-wrapper__momenths">
                                {moment(item.requestDate).format(
                                  "DD MMMM YYYY"
                                )}
                              </strong>
                              <strong className="kw-scheduleList-wrapper__total-due">
                                Total Due: {currencyRupiah(item.total_due, " ")}
                              </strong>
                            </div>
                            <CardScheduleKoinInvoice
                              listData={item.data}
                              actionChangeShowRepayment={
                                actionChangeShowRepayment
                              }
                            />
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  ) : isShowPaymentInstructions === false ? (
                    <DrawerConfirmPayment
                      onClose={actionChangeShowRepayment}
                      visible={isShowRepayment}
                      actionChangePaymentInstructions={
                        actionChangePaymentInstructions
                      }
                      dataRepayment={dataRepayment}
                    />
                  ) : (
                    <PaymentInstructions
                      paramsId={dataRepayment && dataRepayment.id}
                      actionChangePaymentInstructions={
                        actionChangePaymentInstructions
                      }
                    />
                  )
                ) : (
                  <NotFound
                    image={
                      require("../../assets/images/dashboard/norepayment.png")
                        .default
                    }
                    label={"No repayment due yet"}
                    paragraph={"Repayment will show up here."}
                  />
                )}
              </Spin>
            </Card>
          </div>
        </DashboardLayoutKoinInvoice>
      </div>
      <DrawerShortKoinInvoice
        visible={isShowDrawerShortFilter}
        isShow={true}
        onClose={actionShowHideShortDrawerFilter}
      />
      <DrawerFilterKoinInvoice
        visible={isShowDrawerFilter}
        isShow={true}
        onClose={actionShowHideFilterDrawer}
      />
    </DashboardKoinInvoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationList,
      businessInformationKoinvoice,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleKoinInvoice);
