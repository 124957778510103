import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRequiredDocuments, sendDocumentLink, resetSendLink } from "../../stores/actions/document";
import { Breadcrumb } from "react-bootstrap";
import ResendComp from "../SidePanel/AppProgComp/ResendComp";
import config from "../../config";

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';


class ReqDocs extends Component {
  componentDidMount() {
    let { auth } = this.props;
    this.props.getRequiredDocuments(auth.token);
  }

  resendEmail = () => {
    let { auth, balance: { data } } = this.props;
    let document = [];
    document.push({
      group: "personal_finance_document",
      type: "bank_account_mutation"
    })
    document.push({
      group: "personal_document",
      type: "ktp"
    })
    this.props.sendDocumentLink(document, data.loan_id, auth)
  }

  closeSendLink = () => {
    this.props.resetSendLink();
    this.setState({ isPaneResendOpen: false });
  }

  generateLink = () => {
    let { auth, balance } = this.props;
    let base_url = config.document.url;
    let path = '/submission?code=';
    let timestamp = Date.now() / 1000;
    let urlData = `${auth.token}|${balance.data.loan_id}|${timestamp}`
    let encode1 = new Buffer(urlData).toString("base64");
    let encode2 = new Buffer(encode1).toString("base64");
    window.open(base_url + path + encode2, '_blank'); 
  }

  state = {
    isPaneResendOpen: false
  };


  render() {
    let { document, init: { language } } = this.props;

    return (
      <div className="dashboard" ref={ref => this.el = ref}>
        <div className="dashboard-title">
          <Breadcrumb>
            <Breadcrumb.Item href="#">{language.label.home}</Breadcrumb.Item>
            <Breadcrumb.Item active href="#">{language.title.applicationProgress}</Breadcrumb.Item>
          </Breadcrumb>
          <h3 className="title">
            {language.title.applicationProgress}
          </h3>
        </div>

        <div className="row">

          <div className="col-sm-12">
            <div className="card dash-card">
              <div className="card-body">
                <div className="card-content">
                  <div className="top">
                    <div className="appprogress">
                      <div className="heading">
                        <div className="title">{language.title.requiredDocument}</div>
                      </div>

                      {document.requiredDocs && (
                        document.requiredDocs.map(data => data.status !== 'not_uploaded'? (
                          <div className="content" key={data.name}>
                            <div className="card card-progress card-prog-head">
                              <div className="title">
                                <span>{data.name.replace(/_/g, ' ').toUpperCase()}</span>
                                <span className="badge badge-secondary ml-3">{data.tag}</span>
                              </div>
                              <div className="text-wrapper">
                                <span className="left">{data.notes}</span>
                                {/* <span className="link">Retake photo</span> */}
                              </div>
                            </div>
                          </div>
                        ) : '')
                      )}

                      <div className="heading">
                        <div className="text">
                          {language.text.pleaseUploadDocumentThrough} &nbsp;
                          <span href="" className="link" onClick={this.generateLink}>{language.text.thisLink}</span> &nbsp;
                          {language.text.throughLinkEmail}
                        </div>
                        <div className="action" onClick={this.resendEmail}>{language.label.resendEmail}</div>
                      </div>

                      {document.requiredDocs && (
                        document.requiredDocs.map(data => data.status === 'not_uploaded'? (
                          <div className="content" key={data.name}>
                            <div className="card card-progress">
                              <div className="title">
                                {data.name.replace(/_/g, ' ').toUpperCase()}
                              </div>
                              <div className="text-wrapper">
                                <span className="left">{language.text.pleaseUploadThroughEmailOrLink}</span>
                                <span className="right">
                                  <div className="badge badge-secondary">{data.status.replace(/_/g, ' ')}</div>
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : '')
                      )}
                    </div>
                  </div>
                  <div className="bottom text-right">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* side modal */}
        <div>

          <SlidingPane
            className='side-modal payment-detail-modal'
            overlayClassName='payment-detail-overlay'
            width='540px'
            isOpen={document.isSendLinkSuccess || document.isSendLinkFailed ? true : this.state.isPaneResendOpen }
            onRequestClose={this.closeSendLink}>
            <ResendComp />
          </SlidingPane>

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  balance: state.balance,
  document: state.document
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getRequiredDocuments, sendDocumentLink, resetSendLink
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReqDocs);