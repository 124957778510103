import React, { Component } from "react";
import { HomepageWrapper } from "./Homepage.styles";

import BannerMain from "./BannerMain";
import Calculator from "./Calculator";
import NavMain from "./NavMain";
import Solution from "./Solution";
import BannerSecondary from "./BannerSecondary";
import Testimonials from "./Testimonials";
import Partners from "./Partners";
import Footer from "./Footer";
import FooterText from "./FooterText";
import Howto from "./Howto";
import { hostNameCheckCondition } from "../../library/checkHostNameWeb";
import SubmissionType from "../../Components/SubMissionType";
import cookie from "react-cookies";

class Homepage extends Component {
  componentDidMount() {
    cookie.save("displayPage", 1);
    cookie.remove("principal", { path: "/" });
    cookie.remove("financing", { path: "/" });
    cookie.remove("current");
    localStorage.removeItem("paramsSubmit");
    localStorage.removeItem("businessInformation")
    cookie.remove("current", { path: "/" });
    cookie.remove("businessInformation", { path: "/" });
  }
  render() {
    return (
      <HomepageWrapper>
        <NavMain />
        <BannerMain />
        {hostNameCheckCondition && <SubmissionType />}
        {hostNameCheckCondition ? undefined: <Calculator />}
        <BannerSecondary />
        <Solution />
        <Testimonials />
        {hostNameCheckCondition ? undefined : <Partners />}
        <Howto />
        <Footer />
        <FooterText />
      </HomepageWrapper>
    );
  }
}

export default Homepage;
