import React from "react";
import { ApplyLoanKoinVoiceStyle } from "./style";
import SelectLoanPurpose from "./components/SelectLoanYourPurpose/SelectLoanYourPurpose.jsx";
import { connect } from "react-redux";
import {
  businessInformationKoinvoice,
  getApplicationList,
} from "../stores/actions/koinvoiceLoan";
import { getActionProfileUsers } from "../stores/actions/koinvoiceLoanDashboard";
import { getLoanLimit } from "../stores/actions/koinvoiceLoanDashboard";
import { bindActionCreators } from "redux";
import { Aes256CbcDecrypt } from "../library/chiper";

function LMSApplyLimit(props) {
  const params = props.match.params;
  let decodedString = Aes256CbcDecrypt(params.token, params.id);
  localStorage.setItem(params.id, JSON.stringify(decodedString));

  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <SelectLoanPurpose userId={decodedString.userId} />
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInformationKoinvoice,
      getLoanLimit,
      getActionProfileUsers,
      getApplicationList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LMSApplyLimit);
