import React from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { pinInfo } from "../../stores/actions/account";
import { connect } from "react-redux";
import OTPInput from "otp-input-react";
import { SecurityCodePageWrapper } from "./SecurityCode.styles";

class CreateSecurityForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    code: "",
    resp: {},
    isMatch: 0,
    message: "",
    open: false
  };

  onInputChange = e => {
    this.setState({ code: e });
  };

  handleSubmit = e => {
    e.preventDefault();
    let account = this.props.account.data;
    account["pincode"] = this.state.code;
    this.props.pinInfo({
      data: account
    });
    this.props.handleSubmit();
  };

  render() {
    const { code } = this.state;
    const { init: { language} } = this.props;
    return (
      <SecurityCodePageWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <div className="back-button" style={{ cursor: "pointer" }}>
              <a href="!#" style={{ color: "#C4C4C4", textDecoration: "none" }}>
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span>{language.button.back}</span>
              </a>
            </div>
            <div className="card-body register-body">
              <h2 className="register-title">{language.title.createCode}</h2>
              <p className="text-center text-note">
                {language.subtitle.createCode}
              </p>
              <form
                id="CreateSecurityForm"
                className=""
                noValidate
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <div className="input-wrapper">
                  <OTPInput
                    value={code}
                    name="code"
                    onChange={this.onInputChange}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    secure={true}
                    className={`otp-input`}
                  />
                </div>
                <div className="input-wrapper">
                  <button
                    className={`btn btn-primary w-100`}
                    disabled={code.length < 6}
                  >
                    {language.button.next}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </SecurityCodePageWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ pinInfo }, dispatch);
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSecurityForm);

export default reduxForm({
  form: "CreateSecurityForm"
})(component);
