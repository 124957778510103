import { Button, Drawer } from "antd";
import React from "react";
import MobileLayout from "../../Layout/MobileLayout/MobileLayout";
import currencyRupiah from "../../library/currency";
import history from "../../util/history";
import { CongrulationsStarsLoanStyle } from "./style";

export default function CongrulationsStarsLoan({
  language,
  onClose,
  visible,
  height,
  remainingAmount,
  setIsShowAlertDrawerCongrulation,
  isShowLoading,
  userId
}) {
  const handleSubmitAccept = () => {
    setIsShowAlertDrawerCongrulation(false);
    setTimeout(() => {
      history.push( userId ? 
        "/lms/apply-loan/submit-result/" + userId : 
        "/koininvoice/apply-loan/upload-document");
    }, 500);
  };
  return (
    <Drawer
      placement={"bottom"}
      height={height}
      onClose={onClose}
      visible={visible}
    >
      <MobileLayout>
        <CongrulationsStarsLoanStyle>
          <div className="kw-CongrulationsStarsLoan">
            <h3>
              {language.title.congratulation}
              <img
                src={require("../../assets/images/congrulations_stars.png").default}
                alt=""
              />
            </h3>
            <p>
              {language.text.congratulationLimitApplication}
              {currencyRupiah(remainingAmount, " ")}{language.text.congratulationLimitApplicationAmount}
            </p>
            <p>
              {language.text.limitApplicationText}
            </p>
            <p>{language.text.moreInformation}:</p>
            <p style={{ marginBottom: 0 }}>{language.text.phone} : 021 2932 3996</p>{" "}
            <p>Email &nbsp; : support@koinworks.com</p>
            <Button
              onClick={() => handleSubmitAccept()}
              loading={isShowLoading}
              type="text"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                textDecorationLine: "underline",
              }}
            >
              {language.button.acceptTnc}
            </Button>
          </div>
        </CongrulationsStarsLoanStyle>
      </MobileLayout>
    </Drawer>
  );
}
