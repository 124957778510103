import cookie from "react-cookies";
import { decrypetsData } from "../../library/reducerUtils";

const token = cookie.load("token") || null;
const name = cookie.load("name") || null;
const email = cookie.load("email") || null;

const init = {
  token: token,
  phone: "",
  email: email,
  name: name,
  isLoggedIn: false,
  authPreparationCheck: "",
  data: {
    fullname: "",
    phone_area: "",
    phone_number: "",
    email: "",
  },
  status: "",
  checkBorrower: "",
  authPreparationError: "",
  confirmOtp: "",
  confirmOtpError: "",
  isConfirmOtp: false,
  confirmForgotSecurity: "",
  confirmForgotSecurityError: "",
  isConfirmForgotSecurity: false
};

const authReducer = (state = init, action) => {
  switch (action.type) {
    case "AUTH_PREPARATION_SUCCESS":
      return {
        ...state,
        isAuthPreparationSuccess: true,
        email: action.payload[0].email,
        isLoggedIn: false,
      };
    case "AUTH_PREPARATION_FAILED":
      return { ...state, prepErrors: action.payload };
    case "AUTH_PREPARATION_KOININVOICE_SUCCESS":
      return {
        ...state,
        authPreparationCheck: action.payload.data,
        checkBorrower: action.payload.data
          .map((bid) => decrypetsData(bid))
          .filter((bid) => bid.account_type === "business"),
        status: action.payload,
        data: action.payload.data,
        isErrorPreparations: false,
      };
    case "AUTH_PREPARATION_KOININVOICE_FAILED":
      return {
        ...state,
        authPreparationError:
          action.payload !== undefined &&
          `${action.payload.message.en} / ${action.payload.message.id}`,
        isErrorPreparations: true,
      };
    case "LOGOUT_KOININVOICE":
      return {
        ...state,
      };
    case "LOGIN_SUCCEDED":
      return { ...state, token: action.payload.access_token, isLoggedIn: true };
    case "LOGIN_FAILED":
      return { ...state, errors: action.payload, isLoggedIn: false };
    case "SEND_OTP_SUCCESS":
      return {
        ...state,
        auth: action.payload,
        isError: false
      };
    case "SEND_OTP_ERROR":
      return {
        ...state,
        authError: action.payload,
        isError: true
      };
    case "CONFIRM_OTP_SUCCESS":
      return {
        ...state,
        confirmOtp: action.payload,
        isConfirmOtp: false
      };
    case "CONFIRM_OTP_ERROR":
      return {
        ...state,
        confirmOtpError: action.payload,
        isConfirmOtp: true
      };
    case "CONFIRM_FORGOT_PASSWORD_SUCCESS":
      return {
        ...state,
        confirmForgotSecurity: action.payload,
        isConfirmForgotSecurity: false
      };
    case "CONFIRM_FORGOT_PASSWORD_ERROR":
      return {
        ...state,
        confirmForgotSecurityError: action.payload,
        isConfirmForgotSecurity: true
      };
    default:
      return state;
  }
};

export default authReducer;
