import { gajiService } from "../../services/gaji";

export const getCompanies = (term) => (dispatch) => {
  gajiService.getCompanies(term)
    .then(res => {
      let companies = [];
      if (res.status === 200 && res.data) {
        let arr = res.data;
        arr.map(function(data) {
          companies.push({
            value: data.company_id,
            label: data.company_name,
            data: data.is_partner ? "partner" : "non-partner"
          });
          return companies;
        });
        dispatch(getCompaniesSuccess(companies));
      } else {
        dispatch(getCompaniesFailed(res.message));
      }
    },
    error => {
      dispatch(getCompaniesFailed(error));
  });
}

const getCompaniesSuccess = data => {
  return { type: "GET_COMPANY_SUCCESS", payload: data }
}

const getCompaniesFailed = errors => {
  return { type: "GET_COMPANY_FAILED", payload: errors }
}

export const validateEmployee = (body) => (dispatch) => {
  gajiService.validateEmployee(body)
    .then(res => {
      if (res.status === 200 && res.data) {        
        dispatch(validateSuccess(res.data));
      } else {
        dispatch(validateFailed(res.message));
      }
    },
    error => {
      dispatch(validateFailed(error));
  });
}

const validateSuccess = data => {
  return { type: "VALIDATE_EMPLOYEE_SUCCESS", payload: data }
}

const validateFailed = errors => {
  return { type: "VALIDATE_EMPLOYEE_FAILED", payload: errors }
}

export const validateBankAccount = (data) => (dispatch) => {
  gajiService.getEmployeeInfo(data.id)
    .then(res => {
      if (res.status === 200 && res.data) {   
        if(res.data.account_number === data.account_number){
          dispatch(validateBankAccountSuccess());
        } else {
          dispatch(validateBankAccountFailed(data.attempt));
        } 
      } else {
        dispatch(validateBankAccountFailed(data.attempt));
      }
    });
}

const validateBankAccountSuccess = () => {
  return { type: "VALIDATE_BANK_ACCOUNT_SUCCESS" }
}

const validateBankAccountFailed = (data) => {
  let attempt = 3 - data;
  return { 
    type: "VALIDATE_BANK_ACCOUNT_FAILED",
    payload: {
      en: "Matching account number failed, please try again. Remaining attempts: " + attempt.toString(),
      id: "Pencocokan nomor rekening gagal, silakan coba lagi. Percobaan tersisa: " + attempt.toString()
    }
  }
}

export const LoanInfo = action => {
  return { type: "LOAN_INFO", ...action }
}

export const getPartnerData = (body) => (dispatch) => {
  gajiService.getPartnerData(body.partnerId)
    .then(res => {
      if (res.status === 200 && res.data) {        
        dispatch(getPartnerDataSuccess(res.data));
      } else {
        dispatch(getPartnerDataFailed(res.message));
      }
    },
    error => {
      dispatch(getPartnerDataFailed(error));
  });
}

const getPartnerDataSuccess = data => {
  return { type: "GET_PARTNER_DATA_SUCCESS", payload: data }
}

const getPartnerDataFailed = errors => {
  return { type: "GET_PARTNER_DATA_SUCCESS", payload: errors }
}

export const applyLoan = (body) => (dispatch) => {
  gajiService.applyLoan(body)
    .then(res => {
      if (res.status === 200) {        
        dispatch(applyLoanSuccess(res.data));
      } else {
        dispatch(applyLoanFailed(res.message));
      }
    },
    error => {
      dispatch(applyLoanFailed(error));
  });
}

const applyLoanSuccess = data => {
  return { type: "APPLY_LOAN_SUCCESS", payload: data }
}

const applyLoanFailed = errors => {
  return { type: "APPLY_LOAN_FAILED", payload: errors }
}