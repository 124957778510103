import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DashTitle from "./DashTitle";
import ReferFriend from "./ReferFriend";

import CountdownTimer from "react-component-countdown-timer";

import imgRejected from "../../assets/images/dashboard/rejected.png"

class PageRejected extends Component {


  render() {

    return (
      <div className="dashboard">
        <DashTitle />


        <div className="row">

          <div className="col-sm-9">
            <div className="card dash-card app-rejected">
              <div className="card-body">
                <div className="card-content">
                  <div className="top">
                    <div className="notfound-wrapper">
                      <figure className="dashboard-img">
                        <img src={imgRejected} alt="active loan" />
                      </figure>
                      <h5 className="title">We’re sorry. Your application has been rejected.</h5>
                      <p className="text">For some reason, we can not proceed your application. Kindly contact us for further information.</p>
                    </div>
                    <div className="box-support">
                      <div className="text">
                        <i className="fa fa-envelope"></i>
                        <span>support@koinworks.com</span>
                      </div>
                      <div className="text">
                        <i className="fa fa-phone"></i>
                        <span>(021) 5283883</span>
                      </div>
                    </div>
                    <div className="countdown-wrapper">
                      <div className="title">You can reapply in:</div>
                      <div className="countdown">
                        <CountdownTimer count={54320} border showTitle direction="right" />
                      </div>
                      <div className="text">on 2 January 2020</div>
                    </div>
                  </div>
                  <div className="bottom text-right">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <ReferFriend />
          </div>
        </div>



      </div>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PageRejected);