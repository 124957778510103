import React, { useState } from "react";
import { capitalizeFirstLetter, checkTranslate } from "../../library/checkCapitalize";
import { Alert, Button, Col, Progress, Tooltip, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import ModalView from "../ModalView";
import moment from "moment";
import { PATH_KOINVOICE_LOAN } from "../../services/path/koinvoice/koinvoice";
import koinvoiceServices from "../../services/koinvoice";
import { dummyTokenUpload } from "../../services/httpClient";
import cookie from "react-cookies";
import { ProgressUploadKoinInvoiceStyle } from "./style";
import FilerServer from "file-saver";

const ProgressUploadKoinInvoice = ({
  language,
  data,
  reUploadChange,
  handleChangeUpload,
  setMessageError,
  openNotification
}) => {
  const [isErrorFormat, setIsErrorFormat] = useState(false);
  const [isErrorSize, setIsErrorSize] = useState(false);
  const [progress, setProgress] = useState(undefined);
  const [isShowModalView, setIsShowModalView] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [typeHandle, setTypeHandle] = useState("");
  const [dataUpload, setDataUpload] = useState({});
  const [dateTime, setDateTime] = useState();
  const email = cookie.load("email");
  const token = cookie.load("token");

  async function createBase64({ file }) {
    setTypeHandle(file.type);
    setDataUpload(file);
    setDateTime(moment().format("DD MMMM YYYY, HH:mm"));
    setIsErrorFormat(false);
    setIsErrorSize(false);
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      handleChangUploadCustomRequest([fileReader.result]);
    };
    fileReader.readAsDataURL(file);
    async function handleChangUploadCustomRequest(file) {
      try {
        let params = {
          base64: file,
          email: email,
        };
        const res = await axios({
          baseURL: process.env.REACT_APP_API_MAIN_SERVICE,
          url: PATH_KOINVOICE_LOAN.UPLOAD_NEW,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${token}`,
          },
          method: "post",
          data: params,
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            setProgress(percentageProgress);
          },
        });
        setImageUrl(res.data.data[0]);
        if (res.data.data[0]) {
          const response = await koinvoiceServices.submission({
            params: {
              document_url: res.data.data[0],
              properties: {
                koin_invoice: {
                  loans: [
                    {
                      loan_id: "0",
                      notes: "Sedang di review",
                      status: "pending",
                      tag: "Uploaded, Unverified",
                      docs_origin: "borrower",
                      upload_at: moment().format("YYYY-MM-DD"),
                    },
                  ],
                },
              },
            },
            id: data.document_id,
          });
          if ([400, 404, 500, 502].includes(response.status)) {
            openNotification('error', data.name);
          } else if([200, 201].includes(response.status)) {
            openNotification('success', data.name);
          }
          handleChangeUpload({
            group: data.group,
            type: data.name,
            document_url: res.data.data[0],
            document_id: data.document_id,
            properties: {
              koin_invoice: {
                loans: [
                  {
                    loan_id: "0",
                    status: "pending",
                    notes: "Sedang di review",
                    tag: "Uploaded, Unverified",
                  },
                ],
              },
            },
          });
        }
      } catch (error) {}
    }
  }

  const handleChangeShowModalView = () => {
    let link = document.createElement("a");
    if (["image/png", "image/jpeg", "image/jpg"].includes(typeHandle)) {
      setIsShowModalView(!isShowModalView);
    } else {
      if (typeHandle === "application/pdf") {
        link.href = imageUrl;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        download_file(imageUrl, dataUpload.name, "application/pdf");
      } else {
        download_file(imageUrl, dataUpload.name, "application/zip");
      }
    }
  };

  function download_file(fileURL, fileName, fileType) {
    var oReq = new XMLHttpRequest();
    // The Endpoint of your server
    var URLToPDF = fileURL;

    // Configure XMLHttpRequest
    oReq.open("GET", URLToPDF, true);

    // Important to use the blob response type
    oReq.responseType = "blob";

    //let validationImage = /\.(gif|jpg|jpeg|tiff|png)$/i.test(imagePending);
    // When the file request finishes
    // Is up to you, the configuration for error events etc.
    oReq.onload = function () {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser
      var file = new Blob([oReq.response], {
        type: fileType,
      });

      // Generate file download directly in the browser !
      FilerServer.saveAs(file, fileName);
    };

    oReq.send();
  }

  const propsUpload = {
    beforeUpload: (file) => {
      const isPng = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";
      const isJPG = file.type === "image/jpg";
      const isZip = file.type === "application/zip";
      const isXZip = file.type === "application/x-zip-compressed";
      const isPdf = file.type === "application/pdf";
      const isLt2M = file.size > 10000000;
      if (!isJPG && !isJpeg && !isPng && !isZip && !isPdf && !isXZip) {
        setIsErrorFormat(true);
        return isJPG || isJpeg || isPng || isZip || isPdf || isXZip;
      }
      if (isLt2M) {
        setIsErrorSize(true);
        return isLt2M;
      }
    },
    customRequest: ({ file }) => createBase64({ file }),
  };

  return (
    <React.Fragment>
      {["not_uploaded", "rejected"].includes(data.status) ? (
        <Col md={24} xs={24} style={{ marginTop: 15 }}>
          <div style={{ marginBottom: 20 }}>
            <div>
              <strong style={{ color: "#566068" }}>
                {checkTranslate(data.name, language)}
              </strong>
              {data.notes && (
                <Tooltip
                  placement="bottom"
                  style={{ marginLeft: 20 }}
                  title={data.notes}
                >
                  <div
                    style={{
                      background: "#566068",
                      display: "inline-grid",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                      marginLeft: 10,
                      width: 15,
                    }}
                  >
                    <span style={{ fontSize: 12, color: "#FFFFFF" }}>?</span>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: -15,
              }}
            >
              <strong>{dataUpload.name}</strong>
              {dataUpload.name !== undefined && progress !== undefined && (
                <p
                  style={{
                    color: "#378DF4",
                    marginRight: 15,
                    cursor: "pointer",
                  }}
                  onClick={handleChangeShowModalView}
                >
                  View
                </p>
              )}
              {/* <div>
            {dataUpload.name !== undefined && progress !== undefined && (
              <p
                style={{ color: "#378DF4", cursor: "pointer" }}
                onClick={() => reUploadChange(data, setProgress, setDataUpload)}
              >
                Reupload
              </p>
            )}
          </div> */}
            </div>
            {dataUpload.name !== undefined && progress !== undefined && (
              <>
                <Progress
                  style={{ marginTop: 15 }}
                  percent={progress}
                  showInfo={false}
                />
                {dateTime && (
                  <span
                    style={{
                      color: "#C2C2C2",
                      fontSize: 10,
                      textAlign: "right",
                      display: "block",
                    }}
                  >
                    Diunggah pada {dateTime}
                  </span>
                )}
              </>
            )}
          </div>
          {(isErrorFormat || isErrorSize) && (
            <Alert
              style={{ width: "100%", marginBottom: 15 }}
              message={
                isErrorFormat
                  ? "Upload Hanya PDF,JPG/JPEG,PNG,ZIP"
                  : isErrorSize && "Upload Hanya 10Mb Max Size"
              }
              type="error"
            />
          )}
          {progress !== undefined
            ? null
            : dataUpload.name === undefined &&
              progress === undefined && (
                <ProgressUploadKoinInvoiceStyle>
                  <div className="kw-progress-upload">
                    <Upload
                      {...propsUpload}
                      multiple={false}
                      showUploadList={false}
                    >
                      <Button
                        style={{
                          color: "#378DF4",
                          border: "1px solid #378DF4",
                          width: "100%",
                        }}
                        icon={<UploadOutlined />}
                        size="large"
                      >
                        {language.text.clickToUpload}
                      </Button>
                    </Upload>
                  </div>
                </ProgressUploadKoinInvoiceStyle>
              )}
          <ModalView
            visible={isShowModalView}
            handleCancel={handleChangeShowModalView}
            imageModalView={imageUrl}
          />
        </Col>
      ) : undefined}
    </React.Fragment>
  );
};

export default ProgressUploadKoinInvoice;
