import { apiGetWithToken, apiPutWithToken } from "..";
import { PATH_NOTIFICATION } from "../path/koinvoice/notification";


async function getAll(props) {
  let params = props.params;
  let loading = props.loading ? props.loading : function(){}
  let response = "";
  loading(true)
  try {
    response = await apiGetWithToken(PATH_NOTIFICATION.GET_ALL, params);
    loading(false)
  } catch (error) {
    response = error.response;
    loading(false)
  }
  return response && response.data;
}

async function statusUnread(props) {
  let response = "";
  let loading = props.loading ? props.loading : function(){}
  loading(true)
  try {
    response = await apiGetWithToken(PATH_NOTIFICATION.STATUS_UNREAD);
    loading(false)
  } catch (error) {
    response = error.response;
    loading(false)
  }
  return response && response.data;
}

async function status(props) {
  let response = "";
  let loading = props.loading ? props.loading : function(){}
  loading(true)
  try {
    response = await apiPutWithToken(PATH_NOTIFICATION.STATUS_NOTIFICATIONS);
    loading(false)
  } catch (error) {
    response = error.response;
    loading(false)
  }
  return response && response.data;
}

async function statusRead(props) {
  let id = props.id;
  let response = "";
  let loading = props.loading ? props.loading : function(){}
  loading(true)
  try {
    response = await apiPutWithToken(PATH_NOTIFICATION.ROOT_PATH+`/${id}/status`);
    loading(false)
  } catch (error) {
    response = error.response;
    loading(false)
  }
  return response && response.data;
}

const notification = {
  getAll: getAll,
  statusUnread: statusUnread,
  statusRead: statusRead,
  status: status
};

export default notification;
