import styled from "styled-components";
import bgFooter from "../../../assets/images/footer-bg.png";

export const FooterWrapper = styled.div`
  background: #f6f6f8;
  .footer {
    background-image: url(${bgFooter});
    background-repeat: no-repeat;
    background-position: 97% 77%;
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: 575.98px) {
      background-position: 95% 85%;
    }
    .left {
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        color: #071e35;
        margin-bottom: 17px;
        @media (max-width: 575.98px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #071e35;
        margin-bottom: 20px;
        @media (max-width: 575.98px) {
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 0;
        }
      }
      .download-wrapper {
        display: flex;
        vertical-align: middle;
        align-items: baseline;
        @media (max-width: 575.98px) {
          margin-bottom: 15px;
          flex-direction:column;
        }
        .store {
          height: 40px;
          margin-bottom: 0;
          margin-right: 10px;
          img {
            height: 100%;
            object-fit: contain;
          }
        }
        .qrcode {
          height: 70px;
          margin-bottom: 0;
          img {
            height: 100%;
            object-fit: contain;
          }
        }
        .text {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: 0.38px;
          color: #566068;
          margin-right: 10px;
        }
      }
    }
    .right {
      .col-heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.38px;
        color: #2b486d;
        margin-bottom: 10px;
      }
      .footer-nav {
        margin-bottom: 30px;
        .heading {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.38px;
          color: #566068;
          margin-bottom: 8px;
        }
        .text {
          display: block;
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 32px;
          color: #566068;
          margin-bottom: 0;
        }
      }
    }
  }
`;
