import { Steps } from "antd";
import React from "react";
import Icon from "@ant-design/icons";
import { CheckCircle } from "@material-ui/icons";
import {  boxOrderNotActive } from "./progress";
import { ScheduleLoanStyle } from "../style.";
import NotFound from "../../Components/NotFound";

const { Step } = Steps;

const Schedule = (props) => {
  const { loanScheduleKoinBisnis, dataRepayment } = props;
  return (
    <ScheduleLoanStyle>
      <div className="kw-scheduleLoanDetails" style={{ marginTop: 25 }}>
        <strong
          style={{
            display: "block",
            color: "#2B486D",
            fontSize: 25,
          }}
        >
          Loan ID : {`${dataRepayment && dataRepayment.code}`}
        </strong>
        <span>{`0/${
          loanScheduleKoinBisnis && loanScheduleKoinBisnis.schedule.length
        } Installment`}</span>
        <Steps direction="vertical">
          {loanScheduleKoinBisnis &&
          loanScheduleKoinBisnis.schedule.length > 0 ? (
            loanScheduleKoinBisnis.schedule.map((loan, index) => {
              return (
                <Step
                  key={index}
                  title={<span>Installment {loan.installment_number}</span>}
                  status={loan.amount !== null ? "finish" : "wait"}
                  icon={
                    loan.amount === null ? (
                      <Icon component={boxOrderNotActive} />
                    ) : (
                      <CheckCircle />
                    )
                  }
                  description={<div style={{ height: 55 }} />}
                />
              );
            })
          ) : (
            <NotFound
              image={require("../../assets/images/dashboard/norepayment.png").default}
              label={"No repayment due yet"}
              isShow={true}
              paragraph={"Repayment will show up here."}
            />
          )}
        </Steps>
      </div>
    </ScheduleLoanStyle>
  );
};

export default Schedule;
