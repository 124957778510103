import React, { useEffect, useRef, useState } from "react";
import BorrowerDashboardKoinInvoice from "../../BorrowerDashboardKoinInvoice";
import { DashboardKoinInvoiceStyle } from "../../BorrowerDashboardKoinInvoice/style";
import DashboardLayoutKoinInvoice from "../../Layout/DashboardLayoutKoinInvoice";
import {
  actionButtonBackUnggahDokument,
  businessInformationKoinvoice,
  getApplicationList,
} from "../../stores/actions/koinvoiceLoan";
import { getAplicationProsess } from "../../stores/actions/koinvoiceLoanDashboard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentListWrapper from "../../DocumentList";
import koinvoiceServices from "../../services/koinvoice";
import LimitOffer from "../../Components/LimitOffer";
import WaitingForContractLimit from "../../Components/WaitingForContractLimit";
import WaitingForOfferingChild from "../../Components/WaitingForOfferingChild";
import LimitOfferingParent from "../../Components/LimitOfferingParent";
import UpdateBank from "../../Components/UpdateBank";
import history from "../../util/history";
import RejectLimitKoinInvoice from "./RejectLimitKoinInvoice";
import RejectLimitOver from "../../Components/RejectLimitOver/RejectLimitOver";
import DrawerAllert from "../../Components/UpdateBank/DrawerAllert";
import { notification, Spin } from "antd";
import removeLocalStorageAndCookieLimit from "./RemoveLocalStorageAndCookieLimit";
import cookie from "react-cookies";

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
  });
};

function DashboardKoinInvoiceLimit({
  init: { language },
  businessInformationKoinvoice,
  koinvoice: { applicationList },
  actionButtonBackUnggahDokument,
  getAplicationProsess,
  getApplicationList,
}) {
  const [isShowYourLoanDetails, setIsShowYourLoanDetails] = useState(false);
  const [isShowDocumentList, setIsShowDocumentList] = useState(false);
  const [documentLoanType, setDocumentLoanType] = useState("");
  const [disbursementBankData, setDisbursementBankData] = useState({});
  const [creditLimitLoan, setCreditLimitLoan] = useState({});
  const [contractLimitLoan, setContractLimitLoan] = useState([]);
  const [isShowWaitingContract, setIsShowWaitingContract] = useState(false); // eslint-disable-next-line
  const [isContractLimitApplication, setIsContractLimitApplication] = useState(
    false
  );
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isChild, setIsChild] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [isContainDocNotUploaded, setIsContainDocNotUploaded] = useState(true);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(true);
  const token = cookie.load("token");
  const mounted = useRef();

  //itu kalo loan == true dia ke ongoing mas, kalo false ke term and condition

  const handleChangeActionServiceApi = () => {
    koinvoiceServices.actionGetCreditLimitLoan({}).then((res) => {
      if (res?.status === 200) {  
        if (res?.data?.status === "approved") {
          history.push("/dashboard-loan-application");
        }
        if( res?.data?.is_parent_ucl === false && 
           res?.data?.ucl_parent_uuid !== "00000000-0000-0000-0000-000000000000") {
          setIsChild(true);
        }
        if (res?.data?.is_parent_ucl === true) {
          setIsParent(true);
        }
        setCreditLimitLoan(res.data);
      }
    });
    koinvoiceServices
      .actionGetAssesmentsBankDisbursement({})
      .then((bankDisbursement) => {
        if (bankDisbursement.status === 200) {
          setDisbursementBankData(bankDisbursement.data);
        }
      });
    koinvoiceServices
      .actionGetAssesmentsContractLimitApplication({
        loading: setIsShowLoading,
      })
      .then((res) => {
        if (res.status === 200) {
          setContractLimitLoan(res.data);
        }
      });
  };

  function actionChangeKoinInvoiceDashboardLimit() {
    actionButtonBackUnggahDokument();
    businessInformationKoinvoice();
    handleChangeActionServiceApi();
    getApplicationList();
    removeLocalStorageAndCookieLimit();
    if (
      cookie.load("upload-limitApplication-sucsess") ===
      "upload-limitApplication-sucsessDashboard"
    ) {
      setDocumentLoanType("waiting_for_document");
      setIsShowYourLoanDetails(true);
    }
  }

  function fetchDocument() {
    let temp = [];

    koinvoiceServices
      .documentListOld({
        params: { product: "koin_invoice" },
        loading: setIsShowLoading,
      })
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach(val => {
            temp.push(val);
          })
          setDocumentList(temp);
        }
      });

    temp = [];
  }

  useEffect(() => {
    actionChangeKoinInvoiceDashboardLimit(); // eslint-disable-next-line
    fetchDocument();
  }, []);

  useEffect(() => {
    setIsContainDocNotUploaded(documentList.filter(val => val.status === "not_uploaded"));
  }, [documentList])

  useEffect(() => {
    if(documentList.length > 0 && isContainDocNotUploaded.length === 0) {
      actionChangeShowYourLoan("under_review");
      // updateUcl();
    }
  }, [isContainDocNotUploaded])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (applicationList?.status === 200) {
        history.push("/dashboard-loan-application-list");
      }
    }
  }, [applicationList]);

  const handleChangeShowModalUploadProgress = () => {
    setIsShowWaitingContract(!isShowWaitingContract);
  };

  const actionChangeShowYourLoan = (value) => {
    if(!isFirstTimeLogin) {
      setIsShowYourLoanDetails(!isShowYourLoanDetails);
    }
    setDocumentLoanType(value);
    handleChangeActionServiceApi();
    setIsFirstTimeLogin(false);
  };

  const actionGenerateContractLimit = () => {
    koinvoiceServices
      .actionPostAssesmentsContractLimitApplication({
        loading: setIsContractLimitApplication,
      })
      .then((assesContract) => {
        if (assesContract?.status === 200) {
          openNotificationWithIcon(
            "success",
            "Contract Limit Application Berhasil"
          );
          handleChangeActionServiceApi();
          getAplicationProsess(setIsShowLoading);
          setIsShowYourLoanDetails(false);
          setIsShowDocumentList(false);
        } else {
          openNotificationWithIcon("error", assesContract?.message?.en);
          setIsShowYourLoanDetails(false);
          setIsShowDocumentList(false);
        }
      });
  };
  
  return (
    <DashboardKoinInvoiceStyle>
      <div className="kw-dashboardKinInvoiceWrapper">
        <DashboardLayoutKoinInvoice>
          <Spin spinning={isShowLoading}>
            <React.Fragment>
              {(isFirstTimeLogin === true && isContainDocNotUploaded.length > 0) ? (	
                <DocumentListWrapper	
                  actionChangeShowDocumentList={actionChangeShowYourLoan}	
                  actionChangeKoinInvoiceDashboardLimit={	
                    actionChangeKoinInvoiceDashboardLimit	
                  }	
                  documentList={documentList}
                  creditLimitLoan={creditLimitLoan}
                />	
              ) : isShowYourLoanDetails === false ? (
                <React.Fragment>
                  {["rejected_temporary", "rejected_permanently"].includes(
                    creditLimitLoan.status
                  ) ? (
                    (creditLimitLoan.properties.rejected_until !== null
                      && Date.parse(creditLimitLoan.properties.rejected_until) > Date.now()
                    ) ? (
                      <RejectLimitKoinInvoice
                        creditLimitLoan={creditLimitLoan}
                        language={language}
                      />
                    ) : (
                      <RejectLimitOver
                        creditLimitLoan={creditLimitLoan}
                      />
                    )
                    
                  ) : (
                    <BorrowerDashboardKoinInvoice
                      language={language}
                      creditLimitLoan={creditLimitLoan}
                      actionChangeShowYourLoan={actionChangeShowYourLoan}
                    />
                  )}
                </React.Fragment>
              ) : isShowDocumentList === false ? (
                <React.Fragment>
                  {documentLoanType === "waiting_for_document" && (	
                    <DocumentListWrapper	
                      actionChangeShowDocumentList={actionChangeShowYourLoan}	
                      actionChangeKoinInvoiceDashboardLimit={	
                        actionChangeKoinInvoiceDashboardLimit	
                      }	
                      documentList={documentList}
                    />	
                  )}

                  { documentLoanType === "choose_offer" && (
                    !isParent && (
                          <LimitOffer
                              creditLimitLoan={creditLimitLoan}
                              setIsShowDocumentList={setIsShowDocumentList}
                              setIsShowYourLoanDetails={setIsShowYourLoanDetails}
                              setDisbursementBankData={setDisbursementBankData}
                              language={language}
                              actionChangeShowDocumentList={actionChangeShowYourLoan}
                              getAplicationProsess={getAplicationProsess}
                              openNotificationWithIcon={openNotificationWithIcon}
                              handleChangeActionServiceApi={
                                handleChangeActionServiceApi
                              }
                              disbursementBankData={disbursementBankData}
                          />
                      ))}

                  {documentLoanType === "user_approved" &&
                    (disbursementBankData.disbursed_to === "borrower" &&
                    !disbursementBankData.is_verified ? (
                      <UpdateBank
                        setIsShowYourLoanDetails={setIsShowYourLoanDetails}
                        setIsShowDocumentList={setIsShowDocumentList}
                        language={language}
                        getAplicationProsess={getAplicationProsess}
                        handleChangeActionServiceApi={
                          handleChangeActionServiceApi
                        }
                        documentLoanTypes={documentLoanType}
                        creditLimitLoan={creditLimitLoan}
                      />
                    ) : (
                      <DrawerAllert
                        image={require("../../assets/images/loading.png").default}
                        labelText={"Please Wait"}
                        height={555}
                        paragraphText={
                          "We Are Preparing Your Generate Contract Limit"
                        }
                        visible={true}
                        setIsShowYourLoanDetails={setIsShowYourLoanDetails}
                        iShowButtonClose={false}
                        closeFunction={true}
                        setIsShowDocumentList={setIsShowDocumentList}
                        actionGenerateContractLimit={
                          actionGenerateContractLimit
                        }
                        generateContractLimit={true}
                        isContractLimitApplication={isContractLimitApplication}
                        language={language}
                      />
                    ))}
                  
                  {(isParent && 
                  ["choose_offer"].includes(documentLoanType))
                    && (
                      <LimitOfferingParent
                        language={language}
                        actionChangeShowDocumentList={actionChangeShowYourLoan}
                        limitInformation={creditLimitLoan}
                      />
                  )}
                  {(isChild && 
                  ["under_review", "waiting_for_approval_ceo_coo"].includes(documentLoanType))
                    && (
                      <WaitingForOfferingChild
                        language={language}
                        actionChangeShowDocumentList={actionChangeShowYourLoan}
                        limitInformation={creditLimitLoan}
                        isChild={isChild}
                      />
                  )}
                  {(!isChild && !isParent &&
                  ["under_review", "waiting_for_approval_ceo_coo"].includes(documentLoanType))
                    && (
                      <WaitingForOfferingChild
                        language={language}
                        actionChangeShowDocumentList={actionChangeShowYourLoan}
                        limitInformation={creditLimitLoan}
                        isChild={isChild}
                      />
                  )}
                  {(!isChild && isParent &&
                  ["under_review", "waiting_for_approval_ceo_coo"].includes(documentLoanType))
                    && (
                      <WaitingForOfferingChild
                        language={language}
                        actionChangeShowDocumentList={actionChangeShowYourLoan}
                        limitInformation={creditLimitLoan}
                        isChild={isChild}
                      />
                  )}
                  {documentLoanType === "waiting_for_contract" &&
                    (["borrower_download", "manual_digisign"].includes(
                      contractLimitLoan.contract_type
                    ) ? (
                      <WaitingForContractLimit
                        language={language}
                        actionChangeShowDocumentList={actionChangeShowYourLoan}
                        waitingForContract={contractLimitLoan}
                      />
                    ) : (
                      <DrawerAllert
                        image={require("../../assets/images/loading.png").default}
                        labelText={language.text.pleaseWait}
                        height={555}
                        paragraphText={language.text.documentPreparing}
                        visible={true}
                        closeFunction={true}
                        setIsShowYourLoanDetails={setIsShowYourLoanDetails}
                        setIsShowDocumentList={setIsShowDocumentList}
                        onClose={handleChangeShowModalUploadProgress}
                        documentLoanType={documentLoanType}
                        language={language}
                      />
                    ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {disbursementBankData.disbursed_to === "borrower" && (
                    <UpdateBank
                      setIsShowYourLoanDetails={setIsShowYourLoanDetails}
                      setIsShowDocumentList={setIsShowDocumentList}
                      language={language}
                      getAplicationProsess={getAplicationProsess}
                      handleChangeActionServiceApi={
                        handleChangeActionServiceApi
                      }
                      documentLoanTypes={documentLoanType}
                      creditLimitLoan={creditLimitLoan}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          </Spin>
        </DashboardLayoutKoinInvoice>
      </div>
    </DashboardKoinInvoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInformationKoinvoice,
      actionButtonBackUnggahDokument,
      getAplicationProsess,
      getApplicationList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardKoinInvoiceLimit);
