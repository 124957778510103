import React from "react";
import { CashKoinStyle } from "../../BorrowerDashboardKoinInvoice/style";
import { Card, Row, Col } from "antd";
import currencyRupiah from "../../library/currency";

export default function CashKoinWrapperLoanNotApply({ loanBalance, language }) {
  const { available_cash, available_koin } = "";
  return (
    <Card>
      <CashKoinStyle>
        <div className="kw-cashKoinWrapper">
          <Row>
            <Col xs={16}>
              <span>{language.loanDashboard.availableCash}</span>
              <strong>{currencyRupiah(available_cash, "Rp")}</strong>
            </Col>
            <Col xs={8}>
              <span>{language.loanDashboard.availableCoin}</span>
              <strong>{currencyRupiah(available_koin, " ")}</strong>
            </Col>
          </Row>
        </div>
      </CashKoinStyle>
    </Card>
  );
}
