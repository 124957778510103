import React from "react";
import TextField from "@material-ui/core/TextField";

const SimpleTextInput = ({
  input,
  label,
  placeholder,
  variant,
  meta: { touched, error },
  ...custom
}) => (
  <div className={`${touched && error && "invalid"}`}>
    <TextField
      label={label}
      placeholder={placeholder}
      value={input.value}
      variant={variant}
      {...input}
      {...custom}
    />
    {touched && error && (
      <div className="invalid-feedback">
        {" "}
        {typeof error === "string" ? error : error.label}{" "}
      </div>
    )}
  </div>
);

export default SimpleTextInput;