import React from "react";
import eStatementImg from "../../../assets/images/applyloan/e-statement.png";
import mutationImg from "../../../assets/images/applyloan/mutation-teller.png";

const SidePanelIns = ({language, onClose}) => (
  <div className="side-modal-wrapper mutation">
    <div className="top">
      <div className="heading">
        <div className="title">{language.title.accountMutationInstruction}</div>
      </div>
      <div className="uploadins-img-wrapper">
        <span className="text-center">EXAMPLE FOR E-STATEMENT</span>
        <figure className="uploadins-img mb-4">
          <img src={eStatementImg} alt="offer" />
        </figure>
      </div>
      <div className="uploadins-img-wrapper">
        <span className="text-center">EXAMPLE FOR ACCOUNT MUTATION FROM SELLER</span>
        <figure className="uploadins-mutation mb-4">
          <img src={mutationImg} alt="offer" />
        </figure>
      </div>
      <div className="modal-desc-wrapper">
        <p className="uploadins-desc mb-1">{language.text.accountMutationInstruction}</p>
        <p className="uploadins-desc mb-1">
          <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
          {language.text.mutationRule1}
        </p>
        <p className="uploadins-desc mb-1">
          <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
          {language.text.mutationRule2}
        </p>
        <div className="uploadins-desc">
          <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
          {language.text.mutationRule3}
          <ol className="list-wrapper">
            <li>{language.text.mutationContain1}</li>
            <li>{language.text.mutationContain2}</li>
            <li>{language.text.mutationContain3}</li>
            <li>{language.text.mutationContain4}</li>
            <li>{language.text.mutationContain5}</li>
          </ol>
        </div>
      </div>
    </div>
    <div className="bottom text-center">
      <div className="line my-3"></div>
      <div className="btn-modal-cancel" onClick={onClose}>{language.button.close}</div>
    </div>
  </div>
);

export default SidePanelIns;