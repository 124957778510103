import React from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { SecurityCodePageWrapper } from "./SecurityCodePage.styles";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

import imgSuccess from "../assets/images/success.png";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "2rem",
    flex: "1 1 auto",
    margin: "0",
    textAlign: "center",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    "& p": {
      fontSize: "15px",
      color: "#575756",
      width: "400px"
    },
    "& h3": {
      color: "#2b486d",
      fontSize: "34px",
      fontWeight: "bold",
      letterSpacing: "0.41px",
      lineHeight: "41px",
      fontFamily: "Source Sans Pro"
    },
    "& span": {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "22px",
      letterSpacing: "-0.41px",
      color: "#CCCCCC"
    }
  }
}))(MuiDialogContent);

export default class SecureCodeForm extends React.Component {
  state = {
    formValues: {
      otp: ""
    },
    formErrors: "",
    isMatch: 0,
    open: false,
    resp: {}
  };

  renderTime = () => this.Fragment;

  handleCodeChange = async e => {
    this.state.formValues.otp = e;
    this.state.isMatch = 0;
    let msg = "";
    var _this = this;
    if (e.length === 6) {
      await fetch("https://sandbox-api.koinworks.com/v1/users/confirmotp", {
        body: JSON.stringify({
          email: "moonad.studio@gmail.com",
          token: this.state.formValues.otp
        }),
        method: "POST"
      })
        .then(res => res.json())
        .then(data => {
          _this.state.resp = data;
        });
      console.log(this.state.resp);
      if (_this.state.resp.status !== 200) {
        msg = _this.state.resp.message.en;
      } else {
        this.state.isMatch = 1;
      }
    }

    this.setState({
      formErrors: msg
    });
  };

  handleClickOpen = e => {
    this.setState({ open: true });
    e.preventDefault();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClick = e => {};

  render() {
    const { formValues, formErrors, isMatch } = this.state;
    return (
      <SecurityCodePageWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <figure className="logo-wrapper">
              {/* <img src={logoimg} alt="" className="logo-img" /> */}
            </figure>
            <div className="back-button">
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
              <span>Back</span>
            </div>
            <div className="card-body register-body">
              <h2 className="register-title">Create Security Code</h2>
              <p className="text-center text-note">
                Insert your Security Code below to login to your account.
              </p>
              <form className="" noValidate autoComplete="off">
                <div className="input-wrapper">
                  <OTPInput
                    value={formValues.otp}
                    onChange={this.handleCodeChange}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    secure={true}
                    className={`otp-input ${formErrors ? "invalid" : ""}`}
                  />
                  <div className={`${formErrors ? "invalid-feedback" : ""}`}>
                    {formErrors}
                  </div>
                  <div className="resend-wrapper">
                    {/* <ResendOTP
                                handelResendClick={() => console.log("Resend clicked")}
                                className="resend-btn"
                            /> */}
                    <ResendOTP
                      renderButton={this.renderButton}
                      renderTime={this.renderTime}
                      className="resend-btn"
                    />
                  </div>
                </div>
                <div className="input-wrapper">
                  <button
                    onClick={this.handleClickOpen.bind(this)}
                    disabled={!isMatch}
                    className={`btn btn-primary ${
                      isMatch ? "" : "btn-disable"
                    } w-100`}
                  >
                    NEXT
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            ></DialogTitle>
            <DialogContent className="modal-wrapper">
              <figure>
                <img src={imgSuccess} alt="img-success" />
              </figure>
              <h3 className="text-title">Personal Account created</h3>
              <p className="text-note">
                Please verify your email (fitri.febriana@koinworks.com) through
                the verification link we have sent.
              </p>
              <span>Redirecting...</span>
            </DialogContent>
          </Dialog>
        </div>
      </SecurityCodePageWrapper>
    );
  }
}
