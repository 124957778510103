import * as yup from "yup";

export const schema = yup.object().shape({
  id: yup.number().required("Required ID"),
  code: yup.string().required("Required Code Bank"),
  name: yup.string().required("Required Name Bank Account"),
  branch: yup.string().required("Required Branch"),
  account_number: yup.string().required("Required Account Number"),
  account_under_name: yup.string().required("Required Account Under Name"),
});
