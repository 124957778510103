import React from "react";
import ApplicationProgressLoanNotApply from "../Components/ApplicationProgressLoan/ApplicationProgressLoanNotApply";
import AvailableCreditProgressLoanNotApply from "../Components/AvailableCreditProgress/AvailableCreditProgressLoanNotApply";
import CashKoinWrapperLoanNotApply from "../Components/CashKoinWrapper/CashKoinWrapperLoanNotApply";

export default function BorrowerDashboardKoinInvoiceLoan({
  actionChangeShowYourLoan,
  creditUclLimit,
  language,
  expiredDate,
}) {
  return (
    <React.Fragment>
      <CashKoinWrapperLoanNotApply
        loanBalance={creditUclLimit}
        language={language}
      />
      {(creditUclLimit && !creditUclLimit.is_parent_ucl) && (
        <AvailableCreditProgressLoanNotApply
          loanLimit={creditUclLimit}
          actionChangeShowYourLoan={actionChangeShowYourLoan}
          language={language}
          expiredDate={expiredDate}
        />
      )}

      {(creditUclLimit && !creditUclLimit.is_parent_ucl) && (
        <ApplicationProgressLoanNotApply
          loanApplication={creditUclLimit}
          language={language}
          actionChangeShowYourLoan={actionChangeShowYourLoan}
        />
      )}
    </React.Fragment>
  );
}
