import React from "react";
import { ApplicationProgressStyle } from "../../BorrowerDashboardKoinInvoice/style";
import { Card } from "antd";

export default function ApplicationProgressLoanNotApply(props) {
  return (
    <Card
      style={{ marginTop: 15, height: "100vh" }}
      //  title={<strong>{language.loanDashboard.applicationProgress}</strong>}
    >
      <ApplicationProgressStyle>
        <h3 style={{ color: "#2B486D" }}>{props.language.text.dashboardNoApplicaton}</h3>
        <span>{props.language.text.subHeadingNoApplication}</span>
      </ApplicationProgressStyle>
    </Card>
  );
}
