import React from "react";
import { Modal } from "antd";

export default function ModalView({ visible, handleCancel, imageModalView }) {
  return (
    <Modal visible={visible} footer={null} onCancel={handleCancel}>
      <img
        src={imageModalView}
        alt=""
        style={{ marginTop: 50, width: "100%" }}
      />
    </Modal>
  );
}
