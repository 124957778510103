import React, { useCallback, useState } from "react";
import { Modal, Button, Spin } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { UploadKtpStyle } from "./style";
import { uploadKKBusinessKoinVoice } from "../../stores/actions/koinvoiceLoan";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const allowedFiles = ["image/png", "image/jpeg", "image/jpg"];

function UploadKkModal({
  visible,
  handleChangeShowUploadKk,
  uploadKKBusinessKoinVoice,
  init: { language },
  koinvoice: { upload, uploadKKError },
  auth,
  setFieldValue,
  setPathKK,
  validationImageKk,
  businessInformation,
  setValidationImageKk,
}) {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      actionChangeSelfieKtpUpload(file, setFieldValue);
      setPathKK(file.path);
    });
  });

  function actionChangeSelfieKtpUpload(values) {
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email:
          auth.email ||
          (businessInformation && businessInformation.data && businessInformation.data.email) ||
          (businessInformation && businessInformation.email),
      };
      uploadKKBusinessKoinVoice(
        params,
        setFieldValue,
        setIsLoading,
        setValidationImageKk,
        validationImageKk,
        handleChangeShowUploadKk
      );
    };
    fileReader.readAsDataURL(values);
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    onDrop: onDrop,
    accept: allowedFiles,
  });

  let validationImage = require("../../assets/images/applyloan/image-kk.png").default;
  let validationHeading = language.modalUpload.headingKk;
  let validationParagraph = language.modalUpload.paragraphKkOne;
  let validationButtonUpload = language.modalUpload.uploadButton;

  if (validationImageKk === 1 || validationImageKk === 2) {
    validationHeading = language.modalUpload.headingSelifeKtpError;
    validationParagraph = language.modalUpload.paragraphSelfieKtpError;
    validationButtonUpload = language.modalUpload.uploadButtonSelfieKtp;
  } else if (validationImageKk === 3) {
    validationHeading = language.modalUpload.headingSelfieKtpFailed;
    validationParagraph = language.modalUpload.paragraphSelfieFailed;
    validationImage = require("../../assets/images/notfound.png").default;
  }
  let buttonUploadEndpoint;
  if (
    validationImageKk === 0 ||
    validationImageKk === 1 ||
    validationImageKk === 2
  ) {
    buttonUploadEndpoint = (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Spin spinning={isLoading}>
          <Button type="button" className="kw-button" onClick={open}>
            {validationButtonUpload}
          </Button>
        </Spin>
      </div>
    );
  }

  return (
    <Modal visible={visible} onCancel={handleChangeShowUploadKk} footer={null}>
      <UploadKtpStyle>
        <div className="kw-uploadKtp">
          <img
            src={validationImage}
            style={{ width: 350, margin: "auto" }}
            alt=""
          />
          <h5>{validationHeading}</h5>
          <p>{validationParagraph}</p>
          {validationImageKk === 0 && (
            <React.Fragment>
              <p>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {language.modalUpload.paragraphKkTwo}
              </p>
              <p>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {language.modalUpload.paragraphKkThree}
              </p>
            </React.Fragment>
          )}
          {/* {uploadKtpError && (
            <Alert
              message={`${uploadKtpError[0]} (${validationImageKk})`}
              style={{ marginBottom: 10 }}
              type="error"
            />
          )} */}
          {buttonUploadEndpoint}
          {(uploadKKError === undefined || uploadKKError) && (
            <Button
              type="button"
              style={{ marginTop: 15 }}
              onClick={handleChangeShowUploadKk}
            >
              {language.modalUpload.uploadButtonClose}
            </Button>
          )}
        </div>
      </UploadKtpStyle>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  auth: state.auth,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ uploadKKBusinessKoinVoice }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadKkModal);
