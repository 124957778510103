import { combineReducers } from 'redux';
import { reducer as reduxReducer } from 'redux-form';

import loanReducer from './loan';
import authReducer from './auth';
import accountReducer from './account';
import balanceReducer from './balance';
import clearanceReducer from './clearance';
import fundingReducer from './funding';
import uploadReducer from './upload';
import scheduleReducer from './schedule';
import documentReducer from './document';
import offerReducer from './offer';
import koinvoiceLoan from './koinvoiceLoan';
import gajiReducer from './gaji';

import initialLanguage from '../utils/appTranslation.json';
import koinvoiceLoanDashboard from './koinvoiceLoanDashboard';
import koininvoiceLimit from './koininvoiceLimit';



const flag = localStorage.getItem('language') || 'id';

const initState = {
  flag: flag,
  language: initialLanguage[flag]
};

const initReducer = (state = initState, action) => {
	switch (action.type) {
    case "CHANGE_LANGUAGE":
      return {
        ...state,
        flag: action.payload,
        language: initialLanguage[action.payload]  
      }
		default:
			return state;
	}
};

const rootReducer = combineReducers({
  init: initReducer,
  form: reduxReducer,
  loan: loanReducer,
  auth: authReducer,
  account: accountReducer,
  balance: balanceReducer,
  clearance: clearanceReducer,
  funding: fundingReducer,
  upload: uploadReducer,
  schedule: scheduleReducer,
  document: documentReducer,
  offer: offerReducer,
  koinvoice : koinvoiceLoan,
  koinvoiceDashboard : koinvoiceLoanDashboard,
  koinvoiceLimit: koininvoiceLimit,
  gaji: gajiReducer
});

export default rootReducer;