import React from "react";
import { FilterKoinInvoiceScheduleStyle } from "./style";

export default function FilterKoinInvoiceYourLoan({
  actionShowHideShortDrawerFilter,
  actionShowHideFilterDrawer
}) {

  return (
    <FilterKoinInvoiceScheduleStyle>
      <div className="kw-filterKoinInvoiceSchedule-wrapper">
        <span
          className="kw-filterKoinInvoiceSchedule-wrapper__button-filter"
          onClick={() => actionShowHideShortDrawerFilter()}
        >
          <img
            src={require("../../assets/images/icon-menu-button-dashboard/sort-button.png").default}
            alt={""}
          />
        </span>
          <span
            className="kw-filterKoinInvoiceSchedule-wrapper__button-filter"
            onClick={() => actionShowHideFilterDrawer()}
          >
            <img
              src={require("../../assets/images/icon-menu-button-dashboard/filter-button.png").default}
              alt={""}
            />
          </span>
      </div>
    </FilterKoinInvoiceScheduleStyle>
  );
}
