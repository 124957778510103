import React from "react";
import { CardDocumentListSyle } from "../../DocumentList/style";
import { Button, Tag } from "antd";
import { capitalizeFirstLetter, checkTranslate } from "../../library/checkCapitalize";

const CardDocumentList = ({
  language,
  list,
  label,
  labelUploaded,
  actionChangeModalImagePreview,
}) => {
  let colorValid = "#41AF60";

  if (labelUploaded === "pending") {
    colorValid = "#EEAC00";
  } else if (labelUploaded === "rejected") {
    colorValid = "#C53741";
  } else if (labelUploaded === "not_uploaded") {
    colorValid = "LightGray";
  }

  function secondText(values) {
    return (
      <span
        style={{ color: "#C53741"}}
        className="kw-cardDocument-list-wrapper__label-second"
      >
        {values}
      </span>
    );
  }

  function checkNotes(value, label, group) {
    if(label === "not_uploaded" && group !== "additional_document") {
      return language.text.notUploadedDocText;
    } else if(label === "not_uploaded" && group === "additional_document") {
      return value;
    }

    if(value === "Sedang di review" && label === "pending") {
      return language.loanDashboard.beingReview;
    } else if(value !== "Sedang di review" && label === "pending" && group !== "additional_document") {
      return language.loanDashboard.beingReview;
    } else if(value === "Sedang di review" && (label === "approved" || label === "rejected")) {
      return '';
    }
    return value;
  }

  return (
    <CardDocumentListSyle>
      <div className="kw-cardDocument-list-wrapper">
        <div className="kw-cardDocument-list-wrapper__label">
          <strong>{checkTranslate(label, language)}</strong>
          <Tag style={{ height: "100%" }} color={colorValid}>
            {checkTranslate(labelUploaded, language)}
          </Tag>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {labelUploaded === "not_uploaded" &&
            secondText(
              `${capitalizeFirstLetter(
                list.notes
                  ? checkNotes(list.notes, labelUploaded, list.group)
                  : language.text.notUploadedDocText
              )}`
            )}
          {["approved", "rejected"].includes(labelUploaded) &&
            secondText(`${capitalizeFirstLetter(checkNotes(list.notes, labelUploaded, list.group))}`)}
          {labelUploaded === "pending" &&
            secondText(
              `${capitalizeFirstLetter(
                list.notes
                  ? checkNotes(list.notes, labelUploaded, list.group)
                  : language.text.pendingDocText
              )}`
            )}
          {list?.url && (
            <Button
              onClick={() => actionChangeModalImagePreview(list)}
              type="primary"
              style={{ marginTop: 15 }}
            >
              {language.label.view}
            </Button>
          )}
        </div>
        {/* {labelUploaded === "rejected" &&
          secondText(
            `${"Your"} ${capitalizeFirstLetter(
              list.notes
            )} has rejected. Here is the note...`
          )} */}
        {/* {list && list.properties.koin_invoice.loans.map((list, index) => {
          return (
            list.notes && (
              <Input key={index} style={{ marginTop: 15 }} disabled value={list.notes} />
            )
          );
        })} */}
      </div>
    </CardDocumentListSyle>
  );
};

export default CardDocumentList;
