import { Alert, Button, Card } from "antd";
import React, { useState } from "react";
import currencyRupiah from "../../library/currency";
import { LimitOfferStyle } from "./style";
import DocumentListWrapper from "../../DocumentList";
import koinvoiceServices from "../../services/koinvoice";
import DrawerConfirmationReject from "./DrawerConfirmationReject";
import ConfirmationReject from "./ConfirmationReject";

export default function LimitOffer({
  creditLimitLoan,
  actionChangeShowDocumentList,
  setIsShowDocumentList,
  setDisbursementBankData,
  setIsShowYourLoanDetails,
  language,
  getAplicationProsess,
  openNotificationWithIcon,
  handleChangeActionServiceApi,
  disbursementBankData,
}) {
  const [isShowRejectModalAlert, setIsShowRejectModalAlert] = useState(false);
  const [
    isShowRejectConfirmModalAlert,
    setIsShowRejectConfirmModalAlert,
  ] = useState(false);
  const [rejectReason, setRejectReason] = useState([
    "Disagree with the interest rate",
  ]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [messageError, setMessageError] = useState();

  function actionChangeShowHideConfirmationReject() {
    setIsShowRejectModalAlert(!isShowRejectModalAlert);
  }

  function actionChangeShowHideConfirmationApprovedReject() {
    setIsShowRejectConfirmModalAlert(!isShowRejectConfirmModalAlert);
  }

  const dataOffering = [
    {
      label: "Limit amount",
      value: `Rp. ${currencyRupiah(creditLimitLoan.current_limit)}`,
    },
    {
      label: "Duration",
      value: creditLimitLoan.max_tenur,
    },
    {
      label: "Rate",
      value: `${creditLimitLoan.rate} %`,
    },
    {
      label: "Grade",
      value: `${creditLimitLoan.grade}`,
    },
    // {
    //   label: "Interest",
    //   value: `${creditLimitLoan.interest} %`,
    // },
    {
      label: "Origination 1 Month",
      value: `${creditLimitLoan.properties.fees.origination_fee.origination_1} %`,
    },
    {
      label: "Origination 2 Month",
      value: `${creditLimitLoan.properties.fees.origination_fee.origination_2} %`,
    },
    {
      label: "Origination 3 Month",
      value: `${creditLimitLoan.properties.fees.origination_fee.origination_3} %`,
    },
    {
      label: "Origination 4 Month",
      value: `${creditLimitLoan.properties.fees.origination_fee.origination_4} %`,
    },
    {
      label: "Origination 5 Month",
      value: `${creditLimitLoan.properties.fees.origination_fee.origination_5} %`,
    },
    {
      label: "Origination 6 Month",
      value: `${creditLimitLoan.properties.fees.origination_fee.origination_6} %`,
    },
    {
      label: "Admin",
      value: `${creditLimitLoan.properties.fees.admin_fee.admin_1} % / month`,
    },
  ];

  const getDisbursementDataFirst = () => {
      koinvoiceServices
          .actionGetAssesmentsBankDisbursement({})
          .then((bankDisbursement) => {
              if (bankDisbursement.status === 200) {
                  setDisbursementBankData(bankDisbursement.data);
                  acceptOfferContinue()
              } else {
                  setMessageError(
                      `${bankDisbursement?.message?.id} / Unable get disbursment bank`
                  );
              }
          });
  }

  const acceptOfferContinue = () => {
      if (!disbursementBankData) {
          getDisbursementDataFirst()
          return
      }
      if (disbursementBankData.disbursed_to === "borrower" && !disbursementBankData.is_verified){
          // show component update bank
          setIsShowYourLoanDetails(true);
          setIsShowDocumentList(true);
      } else {
          koinvoiceServices
              .actionLimitApproved({
                  params: {
                      bank: {
                          bank_code: disbursementBankData.bank_code,
                          bank_name: disbursementBankData.bank_name,
                          account_number: disbursementBankData.bank_account_number,
                          account_name: disbursementBankData.bank_account_name
                      }
                  },
                  loading: setIsShowLoading
              })
              .then((limitApproved) => {
                  if (limitApproved.status === 200) {
                      setIsShowDocumentList(false);
                      setIsShowYourLoanDetails(false);
                      getAplicationProsess(setIsShowLoading);
                      openNotificationWithIcon(
                          "success",
                          "Contract Limit Application Berhasil"
                      );
                      handleChangeActionServiceApi();
                  } else {
                      setMessageError(
                          `${limitApproved?.message?.id} / Limit Approved Gagal`
                      );
                  }
              });
      }
  };

  const actionRejectChooseOffer = () => {
    let params = {
      reject_reasons: rejectReason,
    };

    koinvoiceServices
      .actionLimitReject({
        paramsId: creditLimitLoan.ucl_id,
        params: params,
        loading: isShowLoading,
      })
      .then((res) => {
        if (res.status === 200) {
          setIsShowDocumentList(false);
          setIsShowYourLoanDetails(false);
          handleChangeActionServiceApi();
        } else {
          setMessageError(`${res?.message?.id} / Reject Offer Failed`);
        }
      });
  };

  const contentValidationStatus = () => {
    let statusContent = (
      <div style={{ height: "50vh" }}>
        <div>
          {["waiting_for_approval_ceo_coo", "choose_offer"].includes(
            creditLimitLoan.status
          ) ? undefined : (
            <Button size="large" onClick={() => actionChangeShowDocumentList()}>
              {language.button.back}
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={require("../../assets/images/notfound.png").default} alt="" />
        </div>
      </div>
    );
    if (creditLimitLoan.status === "choose_offer") {
      statusContent = (
        <React.Fragment>
          <h5>{language.title.congratulation}!</h5>
          <strong
            style={{
              color: "#2B486D",
              fontSize: 17,
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            {language.title.limitReady}!
          </strong>
          <p style={{ marginBottom: 10, marginTop: 20 }}>
            {language.title.detailLimit}:
          </p>
          <table>
            <tbody>
              {dataOffering.map((list, index) => {
                return (
                  <tr key={index}>
                    <td style={{ width: "50%" }}>{list.label}</td>
                    <td style={{ width: "50%" }}>
                      : <strong>{list.value}</strong>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p style={{ marginBottom: 10, marginTop: 20 }}>
            {language.title.additionalDesibursement}
          </p>
          <ol style={{ paddingInlineStart: 17 }}>
            {creditLimitLoan?.properties?.disbursement_add_on_req?.map(
              (e, index) => {
                return <li key={index}>{e}</li>;
              }
            )}
          </ol>
          {messageError && (
            <Alert
              message={messageError}
              type="error"
              style={{ marginBottom: 10, marginTop: 10 }}
            />
          )}
          <Button
            style={{ width: "100%", background: "#2B486D" }}
            size="large"
            type="primary"
            loading={isShowLoading}
            onClick={() => acceptOfferContinue()}
          >
            {language.button.acceptContinue}
          </Button>
          <Button
            style={{
              width: "100%",
              background: "#EFEFEF",
              color: "#C53741",
              marginTop: 10,
            }}
            onClick={() => actionChangeShowHideConfirmationReject()}
            size="large"
          >
            {language.button.rejectOffer}
          </Button>
        </React.Fragment>
      );
    } else if (creditLimitLoan.status === "under_review") {
      statusContent = (
        <DocumentListWrapper
          actionChangeShowDocumentList={actionChangeShowDocumentList}
        />
      );
    }
    return statusContent;
  };

  return (
    <LimitOfferStyle>
      <Card>
        <div className="kw-limitOffer">
          {["waiting_for_approval_ceo_coo", "choose_offer"].includes(
            creditLimitLoan.status
          ) && (
            <div
              style={{ borderBottom: "1px solid #CCCCCC", marginBottom: 20 }}
            >
              <div>
                {" "}
                <Button
                  size="large"
                  onClick={() => actionChangeShowDocumentList()}
                >
                  {language.button.back}
                </Button>
              </div>
              <h4>LIMIT OFFER</h4>
            </div>
          )}
          {contentValidationStatus()}
        </div>
      </Card>
      <DrawerConfirmationReject
        actionChangeShowHideConfirmationApprovedReject={
          actionChangeShowHideConfirmationApprovedReject
        }
        actionChangeShowDocumentList={actionChangeShowDocumentList}
        onClose={actionChangeShowHideConfirmationReject}
        visible={isShowRejectModalAlert}
      />
      <ConfirmationReject
        actionRejectChooseOffer={actionRejectChooseOffer}
        isShowLoading={isShowLoading}
        setRejectReason={setRejectReason}
        actionChangeShowDocumentList={actionChangeShowDocumentList}
        onClose={actionChangeShowHideConfirmationApprovedReject}
        visible={isShowRejectConfirmModalAlert}
      />
    </LimitOfferStyle>
  );
}
