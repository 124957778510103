import React, { useEffect, useRef } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { bindActionCreators } from "redux";
import * as validate from "../commons/validate";
import { connect } from "react-redux";
import { authPreparation,authPreparationKoinInvoice } from "../stores/actions/account";
import { LoginWrapper } from "./Login.styles";
import { Link } from "react-router-dom";
import { FormGroup } from "reactstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneInput from "react-phone-input-2";
import { Input, Typography, Spin } from "antd";
import logoimg from "../assets/images/logo.png";
import gplay from "../assets/images/gplay.png";
import astore from "../assets/images/appstore.png";
import { useState } from "react";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";
import SelectedAccount from "../Components/SelectedAccount";

const { Text } = Typography;
const textInputComponent = ({
  input,
  placeholder,
  disabled,
  type,
  onClick,
  key,
  meta: { error, touched },
}) => (
  <FormGroup>
    <Input
      key={key}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      size="large"
      onClick={(e) => onClick(e)}
      autoComplete="false"
      {...input}
    />

    {touched && error && <Text type="danger">{error}</Text>}
  </FormGroup>
);

const PhoneNumberInput = ({
  input: { value, onChange },
  placeholder,
  disabled,
  type,
  onClick,
  meta: { error, touched },
  handleChange,
}) => (
  <FormGroup>
    <PhoneInput
      prefix={""}
      country={"id"}
      placeholder={placeholder}
      type={type}
      onClick={onClick}
      disabled={disabled}
      autoFormat={false}
      enableSearch={false}
      inputProps={{
        autoFocus: true,
      }}
      value={value}
      onChange={(value, data) => {
        onChange(value);
        handleChange(
          data.dialCode,
          value.length && value.substring(0, 1) === "0"
            ? value
            : value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)
        );
      }}
    />
    {touched && error && <Text type="danger">{error}</Text>}
  </FormGroup>
);

function LoginForm(props) {
  const {
    init: { language, flag },
    auth,
    authPreparation,
    authPreparationKoinInvoice,
    email,
    pristine,
    handleSubmit,
    submitting,
    nextPage,
  } = props;
  const [checkedBox, setCheckedBox] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);

  const [isShowLoading, setIshowLoading] = useState(false);
  const [isShowDrawerSelectAccount, setisShowDrawerSelectAccount] = useState(
    false
  );
  const [phone_area, setPhone_area] = useState("62");
  const [phonemail, setPhoneemail] = useState("");
  const [isGetIdentifierInputTouched, setGetIdentifierInputTouched] = useState(
    false
  );
  const mounted = useRef();

  let isNumber = email && email.length >= 3 && Number(email) ? true : false;

  const handleChange = (event) => {
    setCheckedBox(event.target.checked);
  };

  useEffect(() => {
    if (auth.authPreparationCheck) {
      setisShowDrawerSelectAccount(true);
      return;
    }
    // eslint-disable-next-line
  }, [auth.authPreparationCheck]);

  function onClickShowHideAllert(value) {
    if (value) {
      setIsShowAlert(false);
    }
  }

  function actionCloseshowDrawerSelectAccount() {
    setisShowDrawerSelectAccount(false);
  }

  function getIdentifierInput() {
    return isNumber
      ? PhoneNumberInput
      : isGetIdentifierInputTouched
      ? (props) => {
          props.input.autoFocus = true;
          return textInputComponent(props);
        }
      : textInputComponent;
  }

  function handleChangeEmailOrPhone(code, val) {
    setGetIdentifierInputTouched(true);
    if (isNumber) {
      setPhone_area(code);
      setPhoneemail(val);
    }
  }
  function actionChangeAlert(value) {
    setIsShowAlert(value);
  }

  function actionChangeLoadingShow(value) {
    setIshowLoading(value);
  }

  function handleOnSubmit(values) {
    let body, code, number;
    if (Number(values.email)) {
      code = phone_area;
      number = phonemail || values.email.replace(/[^0-9]+/g, "");
      body = {
        phone_area: code,
        phone_number: number,
      };
    } else {
      body = {
        email: values.email,
      };
    }
    if (hostNameCheckCondition) {
      authPreparationKoinInvoice(
        body,
        actionChangeAlert,
        actionChangeLoadingShow
      );
    } else {
      authPreparation(body);
    }
  }

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (
        hostNameCheckCondition === false &&
        auth &&
        auth.isAuthPreparationSuccess
      ) {
        nextPage();
      }
    }
  });

  return (
    <React.Fragment>
      <LoginWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <figure className="logo-wrapper">
              <img src={logoimg} alt="" className="logo-img" />
            </figure>
            <div className="card-body register-body">
              <h2 className="register-title mb-2">{language.title.signin}</h2>
              {hostNameCheckCondition ? (
                ""
              ) : (
                <p className="login-desc text-center">
                  {language.subtitle.signin}
                </p>
              )}
              <form autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
                <div className="input-wrapper">
                  <Field
                    name="email"
                    type="text"
                    placeholder={language.form.phoneMail}
                    onClick={onClickShowHideAllert}
                    component={getIdentifierInput()}
                    handleChange={handleChangeEmailOrPhone}
                    validate={[validate.required]}
                  />
                </div>
                <div className="input-wrapper">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedBox}
                        onChange={handleChange}
                        value="checkedBox"
                        color="primary"
                      />
                    }
                    label={language.label.rememberMe}
                    className="remember-text"
                  />
                </div>
                <div className="input-wrapper">
                  {" "}
                  {isShowAlert && (
                    <div style={{ margin: 15 }}>
                      <Text type="danger">{auth.authPreparationError}</Text>
                    </div>
                  )}
                  <Spin spinning={isShowLoading}>
                    {" "}
                    <button
                      className="btn btn-primary w-100"
                      onSubmit={handleSubmit}
                      disabled={pristine || submitting}
                    >
                      {language.button.signin}
                    </button>
                  </Spin>
                  {auth.prepErrors && (
                    <div className="error-message">{auth.prepErrors[flag]}</div>
                  )}
                </div>
                <p className="text-center">{language.label.dontHaveAccount}</p>
                <div className="input-wrapper">
                  <Link to="/register">
                    <button className="btn btn-primary w-100">
                      {language.button.signupApply}
                    </button>
                  </Link>
                </div>
                <p className="text-center">{language.label.registerThrough}</p>
                <div className="appstore-wraper">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.koinworks.app&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="appstore-item">
                      <img src={gplay} alt="" />
                    </span>
                  </a>
                  <a
                    href="https://apps.apple.com/id/app/koinworks/id1457026579"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="appstore-item">
                      <img src={astore} alt="" />
                    </span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LoginWrapper>{" "}
      <SelectedAccount
        visible={isShowDrawerSelectAccount}
        onClose={actionCloseshowDrawerSelectAccount}
        nextPage={nextPage}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  init: state.init,
  email: formValueSelector("LoginForm")(state, "email"),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ authPreparation,authPreparationKoinInvoice }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default reduxForm({
  form: "LoginForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(component);
