import styled from "styled-components";

export const NotFoundStyle = styled.div`
  .kw-notFound-wrapper {
    &__image {
      display: flex;
      justify-content: center;
    }
    &__text-content {
      text-align: center;
      margin-top: 15px;
    }
    strong {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.38px;
      color: #566068;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      margin-top: 15px;
      letter-spacing: -0.41px;
      color: #8e8e8e;
    }
  }
`;
