import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import "./style.css";
import { connect } from "react-redux";
import {
  Select,
  InputNumber,
  Form,
  Button,
  Input,
  DatePicker,
  Spin,
  Alert,
} from "antd";
import {
  businessInfoKoinvoice,
  putBusinessInfoKoinvoice,
  putBusinessInfoProfileKoinvoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  actionGetBusinessInfoLocalSubmit,
} from "../../../stores/actions/koinvoiceLoan";
import { Formik } from "formik";
import NumberFormat from "react-number-format";
import cookie from "react-cookies";
import moment from "moment";
import _ from "lodash";
import koinvoiceServices from "../../../services/koinvoice";
import * as yup from "yup";

const { Option } = Select;

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

function BusinessInfo({
  koinvoice: {
    district,
    dataBusinessInfoLocalSubmit,
    businessInfo,
    putBusinessInfoProfileError,
  },
  init: { language, flag },
  actionGetBusinessInfoLocalSubmit,
  businessInformation,
  business_type,
  putBusinessInfoProfileKoinvoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  businessInfoKoinvoice,
  nextPage,
  nameStore,
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const source_of_income = businessInfo?.data?.source_of_income;
  //const [code, setCode] = useState();
  const businessInfoCookie =
    cookie.load("business-info") !== undefined && cookie.load("business-info");
  const dateInfoProfile =
    JSON.parse(localStorage.getItem("businessInfoDate")) !== null &&
    JSON.parse(localStorage.getItem("businessInfoDate"));
  const [subDistrict, setSubDistrict] = useState([]);
  const businessType =
    business_type === "home_business" ||
    business_type === "individual" ||
    business_type === "industri_rumah_tangga";
  const [npwpValidation, setNpwpValidation] = useState(false);
  const [initialUserIndustry, setInitialUserIndustry] = useState({
    isAvailable: false,
    userCategoryId: 0,
  });
  const [userIndustryList, setUserIndustryList] = useState({
    1: { label: "", value: "" },
    2: { label: "", value: "" },
  });
  const [industryList1, setIndustryList1] = useState("");
  const [industryList2, setIndustryList2] = useState("");

  const userCode = cookie.load("uCode") || "";
  const addData = JSON.parse(localStorage.getItem(`add-data-${userCode}`));
  const borrower_name = addData?.borrower_name || "";

  const schemaBusinessInfo = yup.object().shape(
    {
      store_name: yup.string().required("Required Store Name"),
      business_type_form: !business_type
        ? yup.string().required("Required Business Type")
        : "",
      established_in: yup
        .string()
        .typeError("You must specify Established In")
        .when("business_duration", {
          is: (business_duration) => {
            return (
              business_duration === false ||
              business_duration === undefined ||
              business_duration === null ||
              business_duration === ""
            );
          },
          then: yup.string().required("Required Established Date"),
          otherwise: yup.string().typeError("You must specify Established In"),
        }),
      business_duration: yup.string().when("established_in", {
        is: (established_in) => {
          return (
            established_in === undefined ||
            established_in === null ||
            established_in === ""
          );
        },
        then: yup
          .string()
          .required("Required Business Duration")
          .max(2, "Business duration cannot exceed 2 digits"),
        otherwise: yup
          .string()
          .max(2, "Business duration cannot exceed 2 digits"),
      }),
      financial_information_type: yup
        .string()
        .required("Required Financial Information Type"),
      average_sales: yup
        .number()
        .typeError("You must specify a number")
        .required("Required Average Sales")
        .min(0, "Average sales cannot be less than 0."),
      npwp: yup
        .string()
        .required("Required Npwp")
        .max(16, "Npwp Must be 15 or 16 Digits")
        .min(15, "Npwp Must be 15 or 16 Digits"),
      postal_code: yup
        .string()
        .required("Required Postal Code")
        .length(5, "Must be 5 Digits"),
      business_address: yup.string().required("Required Business Address"),
      business_district: yup.string().required("Required Business District"),
      business_sub_district: yup
        .string()
        .required("Required Business Sub District"),
      source_of_income: businessType
        ? yup.string().required("Required Source of Income")
        : "",
      business_province: yup.string(),
      total_other_income: yup.number().typeError("You must specify a number"),
      industry_list_1: yup.string().required("Required Industry List 1"),
      industry_list_2: yup.string().required("Required Industry List 2"),
      nib: !businessType ? yup.string().required("Required No. SIUP/NIB") : "",
      deed_of_change_number: !businessType
        ? yup.string().required("Required Deed of Change Number")
        : "",
      deed_of_change_date: !businessType
        ? yup.string().required("Required Deed of Change Date")
        : "",
    },
    ["established_in", "business_duration"],
  );

  function getUserIndustry() {
    koinvoiceServices
      .getUserIndustryList({ params: businessInformation.data.user_id })
      .then((res) => {
        if (res?.status === 200 && res?.data !== null) {
          let filteredData = res?.data.find(
            (item) => item.stated_by === "borrower",
          ); // array
          let industryLevel1 = filteredData?.industry_levels.find(
            (item) => item.level === 1,
          );
          let industryLevel2 = filteredData?.industry_levels.find(
            (item) => item.level === 2,
          );

          setInitialUserIndustry({
            isAvailable: true,
            userCategoryId: filteredData.user_industry_category_id,
          });
          setUserIndustryList({
            1: {
              ...industryLevel1,
              label: industryLevel1.industry_name_id,
              value: industryLevel1.kw_id,
            },
            2: {
              ...industryLevel2,
              label: industryLevel2.industry_name_id,
              value: industryLevel2.kw_id,
            },
          });
        } else if (res?.status === 200 && res?.data === null) {
          setInitialUserIndustry({
            ...initialUserIndustry,
            isAvailable: false,
          });
        }
      });
  }
  function searchIndustryList(level, search, parentId) {
    koinvoiceServices
      .getIndustryList({ level: level, search: search, parentId: parentId })
      .then((res) => {
        if (res?.status === 200) {
          let data = res.data !== null ? res.data : [];
          if (level === 1) {
            setIndustryList1(data);
          } else if (level === 2) {
            setIndustryList2(data);
          }
        }
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserIndustry();
    searchIndustryList(1, "", "");
    if (initialUserIndustry.isAvailable) {
      searchIndustryList(2, "", userIndustryList[1].value);
    }
    businessInfoKoinvoice(); // eslint-disable-next-line
  }, [initialUserIndustry.isAvailable]);

  function handleChangeEstablishedIn(value, option, setFieldValue) {
    setFieldValue("established_in", option);
  }

  function handleBusinessDurationInputChange(value, option, setFieldValue) {
    const regExp = /[a-zA-Z]/g;

    if (value === null) {
      setFieldValue("business_duration", "");
    } else {
      if (regExp.test(value)) {
        setFieldValue("business_duration", "");
      }
      setFieldValue("business_duration", value);
    }
  }

  function actionChangeFinancialInformationType(value, option, setFieldValue) {
    setFieldValue("financial_information_type", option.value);
  }

  function handleAverageSalesInputChange(value, option, setFieldValue) {
    setFieldValue("average_sales", value);
  }

  function handleTotalOtherIncomeInputChange(value, option, setFieldValue) {
    setFieldValue("total_other_income", value);
  }

  function handleChangeSourceOfIncome(value, option, setFieldValue) {
    setFieldValue("source_of_income", value);
  }

  function handleNpwpInputChange(value, option, setFieldValue) {
    setFieldValue(
      "npwp",
      value.target.value.toString().replace(/[.*+?^_${}()\s|[\]\\-]/g, ""),
    );
  }

  function handleNibInputChange(value, option, setFieldValue) {
    setFieldValue("nib", value.target.value.toString());
  }

  function actionChangeSearchDistrict(value) {
    // getDistrictKoinvoice(value);
  }

  function actionChangeDistrict(value, option, setFieldValue) {
    // const districtValidation =
    //   district.data && district.data.find((dis) => dis.name === value);
    // setCode(districtValidation.code);
    //setFieldValue("business_district", value);
  }

  function actionChangeSearchSubDistrict(value) {
    // getSubDistrictKoinvoice(`${value}&district_code=${code}`);
    koinvoiceServices
      .actionGetLocationThirdParty({ params: value })
      .then((res) => {
        if (res?.status === 200 && res?.data !== null) {
          setSubDistrict(res.data);
        }
      });
  }

  function actionChangeSubDistrict(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value,
    );
    setFieldValue("business_district", districtSubValidation.district);
    setFieldValue("business_sub_district", districtSubValidation.sub_district);
    setFieldValue("business_province", districtSubValidation.province);
    setFieldValue("business_city", districtSubValidation.city);
    setFieldValue("postal_code", districtSubValidation.postal_code);
  }

  function actionChangeBusinessType(value, option, setFieldValue) {
    setFieldValue("business_type_form", value);
  }

  function actionChangeSearchIndustryList1(value) {
    searchIndustryList(1, value, "");
  }
  function actionChangeIndustryList1(value, option, setFieldValue) {
    setFieldValue("industry_list_1", value);
    setUserIndustryList({
      1: { label: option.children, value: option.value },
      2: { label: "", value: "" },
    });
    searchIndustryList(2, "", value);
    setFieldValue("industry_list_2", "");
  }
  function actionChangeSearchIndustryList2(value) {
    searchIndustryList(2, value, userIndustryList[1].value);
  }
  function actionChangeIndustryList2(value, option, setFieldValue) {
    setFieldValue("industry_list_2", value);
    setUserIndustryList({
      ...userIndustryList,
      2: { label: option.children, value: option.value },
    });
  }

  function handleDeedOfChangeNumberInputChange(value, option, setFieldValue) {
    setFieldValue("deed_of_change_number", value.target.value.toString());
  }

  function handleChangeDeedOfChangeDate(value, option, setFieldValue) {
    setFieldValue("deed_of_change_date", option);
  }

  function handleSubmit(value) {
    const {
      store_name,
      business_type_form,
      // business_category,
      established_in,
      business_duration,
      financial_information_type,
      average_sales,
      npwp,
      nib,
      postal_code,
      business_address,
      business_district,
      business_sub_district,
      business_province,
      business_city,
      industry_list_1,
      industry_list_2,
      deed_of_change_number,
      deed_of_change_date,
    } = value;
    if (npwp.length < 15) {
      setNpwpValidation(true);
      return;
    } else {
      setNpwpValidation(false);
    }
    let addBorrower = borrower_name
      ? {
        "business.company_detail.president_director_name": {
          value: borrower_name,
        },
        "business.company_detail.active_persero_name": {
          value: borrower_name,
        },
        "business.company_detail.business_principal_fullname": {
          value: borrower_name,
        },
        "business.company_detail.owner_name": {
          value: borrower_name,
        },
      }
      : {};
    // let params = {
    //   store_name,
    //   business_category,
    //   established_in,
    //   average_sales,
    //   npwp,
    //   business_address,
    //   business_district,
    //   business_sub_district,
    // };
    // putBusinessInfoKoinvoice(
    //   params,
    //   setIsShowLoading,
    //   nextPage
    // );
    let businessInfoProfile = {
      product: "koin_invoice",
      company_name: {
        value: store_name,
      },
      established_in: {
        value: !established_in
          ? businessInformation.data.details?.business?.company_detail?.established_in?.value
            ?.split("-")
            .reverse()
            .join("-") ||
          dateInfoProfile.date ||
          businessInformation.data.details?.business?.company_detail
            ?.established_in?.value
          : established_in.split("-").reverse().join("-"),
      },
      business_duration: {
        value: !business_duration
          ? dataBusinessInfoLocalSubmit.business_age ||
          businessInformation.data.details?.business?.company_detail
            ?.business_age?.value ||
          businessInfoCookie.business_age
          : business_duration.toString(),
      },
      financial_information_type: {
        value: financial_information_type,
      },
      average_sales: {
        value: average_sales.toString(),
      },
      npwp_number: {
        value: npwp,
      },
      address: {
        value: business_address,
      },
      district: {
        value: business_district,
      },
      sub_district: {
        value: business_sub_district,
      },
      province: {
        value: business_province,
      },
      metas: {
        "business.company_detail.business_category": {
          value: userIndustryList[1].label,
        },
        "business.company_detail.postal_code": {
          value: postal_code.toString(),
        },
        "business.company_detail.city": {
          value: business_city,
        },
        "business.company_detail.province": {
          value: business_province,
        },
        "business.company_detail.siup_or_nib": {
          value: nib,
        },
      },
      user_business_legal_information: {
        deed_of_change_number,
        deed_of_change_date: deed_of_change_date
          ? deed_of_change_date.split("-").reverse().join("-")
          : "",
      },
    };
    if (!business_type) {
      let businessTypePutProperty = { value: business_type_form };
      businessInfoProfile.business_type = businessTypePutProperty;
    }

    if (borrower_name) {
      Object.assign(businessInfoProfile.metas, addBorrower);
    }
    Object.assign(
      businessInfoProfile,
      value.source_of_income &&
      value.total_other_income && {
        source_of_income: {
          value: value.source_of_income,
        },
        total_other_income: {
          value: value.total_other_income.toString(),
        },
      },
    );
    function requestPutBusinessInfo() {
      putBusinessInfoProfileKoinvoice(
        businessInfoProfile,
        setIsShowLoading,
        nextPage,
      );
      cookie.save("business-info", value, {
        path: "/",
        maxAge: 3600,
      });
      localStorage.setItem(
        "businessInfoDate",
        JSON.stringify({ date: established_in }),
      );
      actionGetBusinessInfoLocalSubmit(value);
    }

    // if initial user Industry is null
    if (!initialUserIndustry.isAvailable) {
      koinvoiceServices
        .postCreteUserIndustry({
          user_id: businessInformation.data.user_id,
          product: "koin_invoice",
          stated_by: "borrower",
          level_1: industry_list_1,
          level_2: industry_list_2,
        })
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            requestPutBusinessInfo();
          }
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    } else {
      koinvoiceServices
        .putUpdateUserIndustry(initialUserIndustry.userCategoryId, {
          product: "koin_invoice",
          stated_by: "borrower",
          level_1: industry_list_1,
          level_2: industry_list_2,
        })
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            requestPutBusinessInfo();
          }
        })
        .catch((error) => {
          console.error(error);
          return;
        });
    }
  }

  const options =
    district.data &&
    _.uniqBy(district.data, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));

  const optionsSubDistrict =
    subDistrict &&
    _.uniqBy(subDistrict, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));

  let optionIndustryList1 =
    industryList1 &&
    _.uniqBy(industryList1, "industry_name_id").map((data, index) => (
      <Option key={data.industry_name_id} value={data.kw_id}>
        {data.industry_name_id}
      </Option>
    ));
  let optionIndustryList2 =
    industryList2 &&
    _.uniqBy(industryList2, "industry_name_id").map((data, index) => (
      <Option key={data.industry_name_id} value={data.kw_id}>
        {data.industry_name_id}
      </Option>
    ));

  return (
    <div className="kw-businessInfo-wrapper">
      <h3>{language.text.businessInfo}</h3>
      <Formik
        enableReinitialize={true}
        initialValues={{
          store_name:
            businessInformation.data && businessInformation.data.fullname,
          established_in:
            businessInformation.data.details?.business?.company_detail?.established_in?.value
              ?.split("-")
              .reverse()
              .join("-") ||
            dateInfoProfile.date ||
            businessInformation.data.details?.business?.company_detail
              ?.established_in?.value,
          business_duration:
            dataBusinessInfoLocalSubmit.business_age ||
            businessInformation.data.details?.business?.company_detail
              ?.business_age?.value ||
            businessInfoCookie.business_age,
          financial_information_type:
            dataBusinessInfoLocalSubmit.financial_information_type ||
            businessInformation.data.details?.business?.company_detail
              ?.financial_information_type?.value ||
            businessInfoCookie.financial_information_type,
          average_sales:
            dataBusinessInfoLocalSubmit.average_sales ||
            (businessInformation.data.details?.business?.business_performace
              ?.average_sales?.value &&
              businessInformation.data.details?.business?.business_performace
                ?.average_sales?.value !== "0" &&
              businessInformation.data.details?.business?.business_performace
                ?.average_sales?.value) ||
            businessInfoCookie.average_sales,
          npwp:
            dataBusinessInfoLocalSubmit.npwp ||
            businessInformation.data.details?.business?.company_detail
              ?.npwp_number?.value ||
            businessInfoCookie.npwp,
          nib:
            dataBusinessInfoLocalSubmit.nib ||
            businessInformation.data.details?.business?.company_detail
              ?.siup_or_nib?.value ||
            businessInfoCookie.nib,
          business_address:
            dataBusinessInfoLocalSubmit.business_address ||
            businessInformation.data.details?.business?.company_detail?.address
              ?.value ||
            businessInfoCookie.business_address,
          business_district:
            dataBusinessInfoLocalSubmit.business_district ||
            businessInformation.data.details?.business?.company_detail?.district
              ?.value ||
            businessInfoCookie.business_district,
          business_sub_district:
            dataBusinessInfoLocalSubmit.business_sub_district ||
            businessInformation.data.details?.business?.company_detail
              ?.sub_district?.value ||
            businessInfoCookie.business_sub_district,
          business_province:
            dataBusinessInfoLocalSubmit.business_province ||
            businessInformation.data.details?.business?.company_detail?.province
              ?.value ||
            businessInfoCookie.business_province,
          business_city:
            dataBusinessInfoLocalSubmit.business_city ||
            businessInformation.data.details?.business?.company_detail?.city
              ?.value ||
            businessInfoCookie.business_city,
          postal_code:
            dataBusinessInfoLocalSubmit.postal_code ||
            businessInformation.data.details?.business?.company_detail
              ?.postal_code?.value ||
            businessInfoCookie.postal_code,
          source_of_income: dataBusinessInfoLocalSubmit.business_sub_district,
          total_other_income: dataBusinessInfoLocalSubmit.total_other_income,
          industry_list_1: userIndustryList[1].value,
          industry_list_2: userIndustryList[2].value,
          deed_of_change_number:
            dataBusinessInfoLocalSubmit.deed_of_change_number ||
            businessInformation.data?.user_business_legal_information
              ?.deed_of_change_number ||
            businessInfoCookie.deed_of_change_number,
          deed_of_change_date:
            dataBusinessInfoLocalSubmit.deed_of_change_date ||
            businessInformation.data?.user_business_legal_information?.deed_of_change_date
              .split("-")
              .reverse()
              .join("-") ||
            businessInfoCookie.deed_of_change_date,
        }}
        validationSchema={schemaBusinessInfo}
        validateOnChange={false}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {" "}
            <Form.Item
              label={language.loanFormBusinessInfo.storeName}
              validateStatus={
                touched.store_name && errors.store_name && "error"
              }
              help={touched.store_name && errors.store_name}
              onBlur={handleBlur}
            >
              {" "}
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                disabled={
                  nameStore ||
                    (businessInformation.data &&
                      businessInformation.data.fullname)
                    ? true
                    : false
                }
                type="text"
                size="large"
                placeholder="STORE NAME"
                name="store-name"
                onChange={handleChange}
                value={values.store_name}
                id="store-name"
              />
            </Form.Item>
            {!business_type && (
              <Form.Item
                label={language.loanFormBusinessInfo.businessType}
                validateStatus={errors.business_type_form && "error"}
                help={errors.business_type_form}
                onBlur={handleBlur}
              >
                {" "}
                <Select
                  showSearch
                  name="business-type"
                  size={"large"}
                  style={{ width: isMobile() ? 500 : "100%" }}
                  value={values.business_type_form}
                  placeholder="PT"
                  onChange={(value, option) =>
                    actionChangeBusinessType(value, option, setFieldValue)
                  }
                  id="business-type"
                >
                  <Option value="pt">PT</Option>
                  <Option value="cv">CV</Option>
                  <Option value="individual">Individual</Option>
                </Select>
              </Form.Item>
            )}
            {/* form business category */}
            <Form.Item
              label={language.loanFormBusinessInfo.establishedIn}
              validateStatus={
                touched.established_in && errors.established_in && "error"
              }
              help={touched.established_in && errors.established_in}
              onBlur={handleBlur}
            >
              <DatePicker
                name="established-in"
                defaultPickerValue={
                  values.established_in
                    ? moment(values.established_in, "DD-MM-YYYY")
                    : moment("01-01-1990", "DD-MM-YYYY")
                }
                value={
                  values.established_in
                    ? moment(values.established_in, "DD-MM-YYYY")
                    : ""
                }
                format="DD-MM-YYYY"
                onChange={(value, option) =>
                  handleChangeEstablishedIn(value, option, setFieldValue)
                }
                size="large"
                style={{ width: isMobile() ? 500 : "100%" }}
                id="established-in"
              />
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.businessDuration}
              validateStatus={
                touched.business_duration && errors.business_duration && "error"
              }
              help={touched.business_duration && errors.business_duration}
              onBlur={handleBlur}
            >
              {" "}
              <InputNumber
                id="business-duration"
                name="business-duration"
                placeholder="5"
                min="0"
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                value={values.business_duration}
                onChange={(value, option) => {
                  handleBusinessDurationInputChange(
                    value,
                    option,
                    setFieldValue,
                  );
                }}
              />
            </Form.Item>
            {/* Industry List */}
            <Form.Item
              label={language.loanFormBusinessInfo.industryList + " 1"}
              validateStatus={
                touched.industry_list_1 && errors.industry_list_1 && "error"
              }
              help={touched.industry_list_1 && errors.industry_list_1}
              onBlur={handleBlur}
            >
              <Select
                id="Industry List 1"
                name="industry-list-1"
                showSearch
                value={values.industry_list_1}
                placeholder={language.loanFormBusinessInfo.industryList}
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchIndustryList1}
                onChange={(value, option) =>
                  actionChangeIndustryList1(value, option, setFieldValue)
                }
              >
                {optionIndustryList1}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.industryList + " 2"}
              validateStatus={
                touched.industry_list_2 && errors.industry_list_2 && "error"
              }
              help={touched.industry_list_2 && errors.industry_list_2}
              onBlur={handleBlur}
            >
              <Select
                id="Industry List 2"
                name="industry-list-2"
                showSearch
                value={values.industry_list_2}
                placeholder={language.loanFormBusinessInfo.industryList}
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchIndustryList2}
                onChange={(value, option) =>
                  actionChangeIndustryList2(value, option, setFieldValue)
                }
                disabled={values.industry_list_1 === ""}
              >
                {optionIndustryList2}
              </Select>
            </Form.Item>
            {/* END FIELD */}
            <Form.Item
              label={language.loanFormBusinessInfo.financialInformationType}
              validateStatus={errors.financial_information_type && "error"}
              help={errors.financial_information_type}
            >
              {" "}
              <Select
                size={"large"}
                style={{ width: isMobile() ? 500 : "100%" }}
                value={values.financial_information_type}
                placeholder={language.label.averageMonthlySales}
                onChange={(value, option) =>
                  actionChangeFinancialInformationType(
                    value,
                    option,
                    setFieldValue,
                  )
                }
                name="financial-information-type"
                id="financial-information-type"
              >
                <Option value="Average Monthly Sales">
                  {language.label.averageMonthlySales}
                </Option>
                <Option value="Average Purchase Report">
                  {" "}
                  {language.label.averagePurchaseReport}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.everageSalesPerMonth}
              validateStatus={
                touched.average_sales && errors.average_sales && "error"
              }
              help={touched.average_sales && errors.average_sales}
              onBlur={handleBlur}
            >
              <InputNumber
                id="average-sales"
                name="average-sales"
                placeholder="32,333,333"
                style={{ width: isMobile() ? 500 : "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\[Rp]\s?|(,*)/g, "")}
                size="large"
                value={values.average_sales}
                onChange={(value, option) => {
                  handleAverageSalesInputChange(value, option, setFieldValue);
                }}
              />
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.personalNpwp}
              validateStatus={
                (touched.npwp && errors.npwp && "error") ||
                (npwpValidation && "error")
              }
              help={
                (touched.npwp && errors.npwp) ||
                (npwpValidation && "Npwp Must be 15 or 16 Digits")
              }
              onBlur={handleBlur}
            >
              <NumberFormat
                style={{
                  width: isMobile() ? 500 : "100%",
                  height: 40,
                  border: "1px solid #d9d9d9",
                  padding: 10,
                }}
                name="npwp"
                type="tel"
                placeholder="23.233.323.4-323.3234"
                format="##.###.###.#-###.####"
                onChange={(value, option) =>
                  handleNpwpInputChange(value, option, setFieldValue)
                }
                value={values.npwp}
                id="npwp"
              />
            </Form.Item>
            <Form.Item
              label={`${language.loanFormBusinessInfo.nib} ${businessType ? "(Optional)" : ""
                }`}
              validateStatus={touched.nib && errors.nib && "error"}
              help={touched.nib && errors.nib}
              onBlur={handleBlur}
            >
              <NumberFormat
                style={{
                  width: isMobile() ? 500 : "100%",
                  height: 40,
                  border: "1px solid #d9d9d9",
                  padding: 10,
                }}
                name="nib"
                type="tel"
                placeholder="1231234"
                onChange={(value, option) =>
                  handleNibInputChange(value, option, setFieldValue)
                }
                value={values.nib}
                id="nib"
              />
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.deedOfChangeNumber}
              validateStatus={
                touched.deed_of_change_number &&
                errors.deed_of_change_number &&
                "error"
              }
              help={
                touched.deed_of_change_number && errors.deed_of_change_number
              }
              onBlur={handleBlur}
            >
              <NumberFormat
                style={{
                  width: isMobile() ? 500 : "100%",
                  height: 40,
                  border: "1px solid #d9d9d9",
                  padding: 10,
                }}
                name="deed-of-change-number"
                type="tel"
                placeholder="1231234"
                onChange={(value, option) =>
                  handleDeedOfChangeNumberInputChange(
                    value,
                    option,
                    setFieldValue,
                  )
                }
                value={values.deed_of_change_number}
                id="deed-of-change-number"
              />
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.deedOfChangeDate}
              validateStatus={
                touched.deed_of_change_date &&
                errors.deed_of_change_date &&
                "error"
              }
              help={touched.deed_of_change_date && errors.deed_of_change_date}
              onBlur={handleBlur}
            >
              <DatePicker
                name="deed-of-change-date"
                defaultPickerValue={
                  values.deed_of_change_date
                    ? moment(values.deed_of_change_date, "DD-MM-YYYY")
                    : moment("01-01-1990", "DD-MM-YYYY")
                }
                value={
                  values.deed_of_change_date
                    ? moment(values.deed_of_change_date, "DD-MM-YYYY")
                    : ""
                }
                format="DD-MM-YYYY"
                onChange={(value, option) =>
                  handleChangeDeedOfChangeDate(value, option, setFieldValue)
                }
                size="large"
                style={{ width: isMobile() ? 500 : "100%" }}
                id="deed-of-change-date"
              />
            </Form.Item>
            <p style={{ color: "#8E8E8E" }}>
              {language.loanFormBusinessInfo.businessAddress}
            </p>
            <Form.Item
              label={language.loanFormBusinessInfo.address}
              validateStatus={
                touched.business_address && errors.business_address && "error"
              }
              help={touched.business_address && errors.business_address}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder={"Jl.Peningakatan Raya xx - Sebelah Toko"}
                type="text"
                size="large"
                name="business_address"
                onChange={handleChange}
                value={values.business_address}
                id="business-address"
              />
            </Form.Item>
            <Form.Item
              validateStatus={
                touched.business_sub_district &&
                errors.business_sub_district &&
                "error"
              }
              help={
                touched.business_sub_district && errors.business_sub_district
              }
              label={language.form.subdistrict}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.business_sub_district}
                name="business-sub-district"
                //placeholder={"Bojong menteng"}
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchSubDistrict}
                onChange={(value, option) =>
                  actionChangeSubDistrict(value, option, setFieldValue)
                }
                id="business-sub-district"
              >
                {optionsSubDistrict}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.district}
              validateStatus={
                touched.business_district && errors.business_district && "error"
              }
              help={touched.business_district && errors.business_district}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.business_district}
                // placeholder={"TEBET"}
                name="business-district"
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                disabled={true}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchDistrict}
                onChange={(value, option) =>
                  actionChangeDistrict(value, option, setFieldValue)
                }
                id="business-district"
              >
                {options}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.city}
              validateStatus={
                touched.business_city && errors.business_city && "error"
              }
              help={touched.business_city && errors.business_city}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                disabled={true}
                size="large"
                name="business-city"
                onChange={handleChange}
                value={values.business_city}
                id="business-city"
              />
            </Form.Item>
            <Form.Item
              label={language.form.province}
              validateStatus={
                touched.business_province && errors.business_province && "error"
              }
              help={touched.business_province && errors.business_province}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                //placeholder={"DKI JAKARTA"}
                disabled={true}
                size="large"
                name="business-province"
                onChange={handleChange}
                value={values.business_province}
                id="business-province"
              />
            </Form.Item>
            <Form.Item
              label={language.form.postalCode}
              validateStatus={
                touched.postal_code && errors.postal_code && "error"
              }
              help={touched.postal_code && errors.postal_code}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder={"30153"}
                type="number"
                size="large"
                name="business-postal-code"
                onChange={handleChange}
                value={values.postal_code}
                id="business-postal-code"
              />
            </Form.Item>
            {businessType && (
              <>
                {/* <p style={{ color: "#8E8E8E" }}> */}
                {/*   {language.loanFormBusinessInfo.otherSourceOfIncome} */}
                {/* </p> */}
                <Form.Item
                  label={language.loanFormBusinessInfo.sourceOfIncome}
                  validateStatus={errors.source_of_income && "error"}
                  help={errors.source_of_income}
                  onBlur={handleBlur}
                >
                  <Select
                    name="source-of-income"
                    size={"large"}
                    placeholder={language.label.salary}
                    onChange={(value, option) =>
                      handleChangeSourceOfIncome(value, option, setFieldValue)
                    }
                    style={{ width: isMobile() ? 500 : "100%" }}
                    id="source-of-income"
                  >
                    {source_of_income &&
                      source_of_income.map((list, index) => {
                        return (
                          <Option value={list.value} key={index}>
                            {flag === "en" ? list.desc_en : list.desc_id}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={language.loanFormBusinessInfo.totalOtherIncome}
                  validateStatus={errors.total_other_income && "error"}
                  help={errors.total_other_income}
                  onBlur={handleBlur}
                >
                  <InputNumber
                    id="total-other-income"
                    name="total-other-income"
                    placeholder="43,000,000"
                    style={{ width: isMobile() ? 500 : "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\[Rp]\s?|(,*)/g, "")}
                    size="large"
                    value={values.total_other_income}
                    onChange={(value, option) => {
                      handleTotalOtherIncomeInputChange(
                        value,
                        option,
                        setFieldValue,
                      );
                    }}
                  />
                </Form.Item>
              </>
            )}
            {putBusinessInfoProfileError.data && (
              <Alert
                type="error"
                style={{
                  marginBottom: 25,
                  width: isMobile() ? "50%" : "100%",
                }}
                message={`${putBusinessInfoProfileError.data.message.en} / ${putBusinessInfoProfileError.data.message.id}`}
              />
            )}
            <Spin
              spinning={isShowLoading}
              style={{ width: isMobile() ? "70%" : "100%" }}
            >
              <Button
                htmlType="submit"
                className="kw-button"
                size="large"
                type="primary"
                id="btn-next-business"
              >
                {language.button.next}
              </Button>
            </Spin>
          </form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInfoKoinvoice,
      putBusinessInfoKoinvoice,
      putBusinessInfoProfileKoinvoice,
      getDistrictKoinvoice,
      getSubDistrictKoinvoice,
      actionGetBusinessInfoLocalSubmit,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfo);
