import React from "react";
import { Drawer, Radio, Button } from "antd";
import { DrawerShortKoinInvoiceStyle } from "./style";
import { useState } from "react";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

function DrawerShortKoinInvoice({ onClose, visible, isShow = false }) {
  const [parameterFilter, setParameterFilter] = useState({
    sort_type: undefined,
    sort: undefined,
  });
  const actionChangeAscDesc = (e) => {
    setParameterFilter({
      ...parameterFilter,
      sort_type: e.target.value,
    });
  };

  const actionChangeFilter = (e) => {
    setParameterFilter({
      ...parameterFilter,
      sort: e.target.value,
    });
  };
  return (
    <Drawer
      title=" "
      placement={"bottom"}
      closable={false}
      onClose={onClose}
      height={444}
      visible={visible}
    >
      <DrawerShortKoinInvoiceStyle>
        <div className="kw-filterKoinInvoice-wrapper">
          <h3>Sort</h3>
          {isShow === false && (
            <div>
              <Radio.Group
                defaultValue="asc"
                onChange={actionChangeAscDesc}
                size="large"
                buttonStyle="solid"
              >
                <Radio.Button value="asc">ASCENDING</Radio.Button>
                <Radio.Button value="desc">DESCENDING</Radio.Button>
              </Radio.Group>
            </div>
          )}
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Radio.Group
              onChange={
                isShow === false ? actionChangeFilter : actionChangeAscDesc
              }
              value={
                isShow === false
                  ? parameterFilter.sort
                  : parameterFilter.sort_type
              }
            >
              <Radio style={radioStyle} value={undefined}>
                All Status
              </Radio>
              <Radio
                style={radioStyle}
                value={isShow === false ? "request_date" : "newest"}
              >
                {isShow === false ? "Request Date" : "Newest"}
              </Radio>
              <Radio
                style={radioStyle}
                value={isShow === false ? "loan_amount" : "oldest"}
              >
                {isShow === false ? "Loan Amount" : "Oldest"}
              </Radio>
              {isShow === false && (
                <Radio style={radioStyle} value={"loan_term"}>
                  Loan Term
                </Radio>
              )}
            </Radio.Group>
          </div>
          <Button
            className="kw-button"
            size="large"
            onClick={() => onClose("apply", parameterFilter)}
          >
            APPLY
          </Button>
          <p onClick={() => onClose("cancel")}>CANCEL</p>
        </div>
      </DrawerShortKoinInvoiceStyle>
    </Drawer>
  );
}

export default DrawerShortKoinInvoice;
