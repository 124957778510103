import styled from "styled-components";

export const UpdateBankWrapper = styled.div`
  .kw-updateBank-wrapper {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .ant-form-item-label {
      text-align: left;
    }
    .ant-form-item {
      display: block;
    }
    .kw-button {
      background-color: #2b476c;
      border-color: #2b476c;
      color: #ffffff;
      width: 100%;
    }
    &__image-upload {
      height: 48px;
      width: 72px;
      margin-right: 25px;
    }
    h4 {
      font-weight: bold;
      font-size: 28px;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
  }
`;
