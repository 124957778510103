import React from "react";
import { connect } from "react-redux";
import { LoanAppWrapper } from "./LoanApp.styles";

import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";

import StepWork from "./Work";
import StepLoan from "./Loan";
import StepLoanExt from "./LoanExt";
import StepConfirmation from "./Confirmation";

import imgSuccess from "../assets/images/success.png";
import StepInfo from "./Info/StepInfo";
import StepConnect from "./Connect/StepConnect";
import { StepTitleInternal, StepTitleExternal } from "./common/data";

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

class LoanApp extends React.Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
  }

  state = {
    activeStep: 0
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step
    });
  };

  handleNext = () => {
    let data = this.props.loan.data;
    let step = data && data.offer === 'Salary Advance' && data.loan_status === 'eksternal' ?
     this.state.activeStep + 2 : this.state.activeStep + 1;

    this.setState({
      activeStep: step
    });
  };

  render() {
    const { classes, loan, init: { language, flag } } = this.props;
    const { activeStep } = this.state;

    const steps = loan.data && loan.data.loan_status === "eksternal" ?
          StepTitleExternal[flag] : StepTitleInternal[flag];

    return (
      <LoanAppWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <div className={classes.root}>
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        onClick={this.handleStep(index)}
                        className="step-label"
                        {...labelProps}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
              <div>
                {loan.isLoanSuccess ? (
                  <div>
                    {/* completed */}
                    <div className="completed-wrapper">
                      <figure className="status-img">
                        <img src={imgSuccess} alt="" />
                      </figure>
                      <div className="completed-text">
                        <h2 className="completed-title">
                          {language.title.loanAppComplete}
                        </h2>
                        <p className="completed-desc">
                          {language.text.loanAppComplete}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.instructions}>
                      {activeStep === 0 && (
                        <StepWork onSubmit={this.handleNext} />
                      )}
                      {activeStep === 1 &&
                        loan.data.loan_status === "internal" && (
                          <StepLoan onSubmit={this.handleNext} />
                        )}
                      {activeStep === 1 &&
                        loan.data.loan_status === "eksternal" && (
                          <StepLoanExt onSubmit={this.handleNext} />
                        )}
                      {activeStep === 2 &&
                        loan.data.loan_status === "internal" && (
                          <StepConfirmation onSubmit={this.handleNext} />
                        )}
                      {activeStep === 2 &&
                        loan.data.loan_status === "eksternal" && (
                          <StepInfo onSubmit={this.handleNext} />
                        )}
                      {activeStep === 3 &&
                        loan.data.loan_status === "eksternal" && (
                          <StepConnect onSubmit={this.handleNext} />
                        )}
                      {activeStep === 4 &&
                        loan.data.loan_status === "eksternal" && (
                          <StepConfirmation onSubmit={this.handleNext} />
                        )}
                    </div>
                    <div className="step-btn-wrapper">
                      <Button
                        disabled={this.state.activeStep === 0}
                        onClick={this.handleBack}
                        className={(classes.button, "step-back")}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoanAppWrapper>
    );
  }
}

const Component = withStyles(styles, { withTheme: true })(LoanApp);

const mapStateToProps = state => ({
  init: state.init,
  loan: state.loan
});

export default connect(mapStateToProps)(Component);
