import styled from "styled-components";

export const Section = styled.div`
  background: #f2f2f2;

  .kw-button {
    background-color: #2b476c !important;
    border-color: #2b476c !important;
    color: #ffffff !important;
  }

  .react-tel-input .country-list .country {
    padding: 7px 41px;
  }

  a {
    text-decoration: underline;
  }

  .page-content-wrapper {
    /* width: 60%; */
    margin: 0 auto;
    padding-top: 60px;
    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
      width: 100%;
    }
    .kw-button {
    background-color: #2b476c !important;
    border-color: #2b476c !important;
    color: #ffffff !important;
  }
    .MuiInput-underline:before {
      border-bottom: unset !important;
    }

    .MuiNativeSelect-select.MuiNativeSelect-select {
      border: 1px solid #ced4da !important;
      border-radius: 4px;
      padding: 10px 26px 10px 12px !important;
    }
  }
  .logo-wrapper {
    position: relative;
    top: -65px;
    width: 131px;
    margin: 0 auto;
    .logo-img {
      position: absolute;
    }
  }
  .register-body {
    padding: 30px;
    .register-title {
      text-align: center;
      padding-top: 2em;

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 41px;
      /* or 121% */

      text-align: center;
      letter-spacing: 0.41px;

      color: #2b486d;

      margin-bottom: 30px;
    }
  }
  .gender-wrapper {
    /* note: remove comment setelah checked fix */
    /* .MuiIconButton-label {
      visibility: hidden;
    } */
    .gender-title {
      margin-bottom: 10px;
    }
    .gender-box {
      margin: 0;
      width: 50%;
      background: #f2f2f2;
      border-radius: 3px 0px 0px 3px;
      &:first-child {
        border-right: 1px solid #ffffff;
      }
      > span {
        padding: 18px;
        width: 100%;
        text-align: center;
        :first-child {
          display: none;
        }
        &.Mui-checked {
          & + span {
            color: #ffffff;
            background: #2b486d;
          }
        }
      }
    }
  }
  .input-wrapper {
    /* margin-bottom: 25px; */
    .react-tel-input {
      .form-control {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        height: 56px;
        box-shadow: none;
        & + div {
          &:before {
            display: none;
          }
        }
      }
      .country-list {
        width: 380px;
      }
    }
  }
  .appstore-wraper {
    display: flex;
    justify-content: space-around;
    .appstore-item {
      width: 48%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin: 0.25rem 0 0.4rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dc3545;
    }
    .react-tel-input input {
      border-color: #dc3545;
    }
    label {
      color: #dc3545;
    }
  }
`;
