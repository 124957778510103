import React from "react";
import { Drawer } from "antd";

export default function DrawerAllert({
  onClose,
  visible,
  image,
  labelText,
  paragraphText,
}) {
  return (
    <Drawer
      title=""
      placement={"bottom"}
      height={444}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={image} alt="" />
      </div>
      <h3 style={{ textAlign: "center", color: "#2B486D", marginTop: 22 }}>
        {labelText}
      </h3>
      <p style={{ textAlign: "center", color: "#575756" }}>{paragraphText}</p>
      <p
        onClick={() => onClose()}
        style={{
          color: "#566068",
          textAlign: "center",
          fontWeight: 555,
          cursor: "pointer",
          textDecorationLine: "underline",
        }}
      >
        CLOSE
      </p>
    </Drawer>
  );
}
