import TYPE from "../actions/type";

const initialState = {
  loanApplication: "",
  loanApplicationError: "",
  loanLimit: "",
  loanLimitError: "",
  loanBalance: "",
  loanBalanceError: "",
  documentList: "",
  documentListError: "",
  applicationListLoanDashboardSucsess: {},
  applicationListLoanDashboardError: "",
  usePorfile: "",
  usePorfileError: "",
  primaryBank: "",
  primaryBankError: "",
};

const invoiceLoanDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.DOCUMENT_PRIMARY_BANK_SUCSESS:
      return {
        ...state,
        primaryBank: action.payload,
      };
    case TYPE.DOCUMENT_PRIMARY_BANK_ERROR:
      return {
        ...state,
        primaryBankError: action.payload,
      };
    case TYPE.USER_PROFILE_SUCSESS:
      return {
        ...state,
        usePorfile: action.payload,
      };
    case TYPE.USER_PROFILE_ERROR:
      return {
        ...state,
        usePorfileError: action.payload,
      };
    case TYPE.APPLICATION_LIST_DASHBOARD_SUCESS:
      return {
        ...state,
        applicationListLoanDashboardSucsess: action.payload,
      };
    case TYPE.APPLICATION_LIST_DASHBOARD_ERROR:
      return {
        ...state,
        applicationListLoanDashboardError: action.payload,
      };
    case TYPE.DOCUMENT_LIST_SUCSESS:
      return {
        ...state,
        documentList: action.payload,
      };
    case TYPE.DOCUMENT_LIST_ERROR:
      return {
        ...state,
        documentListError: action.payload,
      };
    case TYPE.LOAN_APPLICATION_PROGRESS_SUCSESS:
      return {
        ...state,
        loanApplication: action.payload,
      };
    case TYPE.LOAN_APPLICATION_PROGRESS_ERROR:
      return {
        ...state,
        loanApplicationError: action.payload,
      };
    case TYPE.LOAN_LIMIT_SUCSESS:
      return {
        ...state,
        loanLimit: action.payload,
      };
    case TYPE.LOAN_LIMIT_ERROR:
      return {
        ...state,
        loanLimitError: action.payload,
      };
    case TYPE.LOAN_BALANCE_SUCSESS:
      return {
        ...state,
        loanBalance: action.payload,
      };
    case TYPE.LOAN_BALANCE_ERROR:
      return {
        ...state,
        loanBalanceError: action.payload,
      };
    default:
      return state;
  }
};

export default invoiceLoanDashboardReducer;
