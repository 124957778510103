import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Alert, Spin,Divider } from "antd";
import Select from "@appgeist/react-select-material-ui";
import CurrencyFormatInput from "../../../Components/Form/currencyFormatInput";
import cookie from "react-cookies";

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

export default function ProjectFinancingLoan({
  flag, 
  language,
  handleChangeFinancingLoan,
  koinvoice,
  financingCode,
  handleChangeSubmitFinancing,
  isShowLoading,
}) {
  let businessInformation =
    JSON.parse(localStorage.getItem("businessInformation")) !== null &&
    JSON.parse(localStorage.getItem("businessInformation")).data;
  let business_type = 
    businessInformation?.details?.business?.company_detail?.business_type?.value ||
    '';

  const userCode = cookie.load("uCode");
  const addData = JSON.parse(localStorage.getItem(`add-data-${userCode}`)) || null;
  const [loanTerm, setLoanTerm] = useState(addData ? addData.loan_term : 0);
  const [loanPurpose, setLoanPurpose] = useState(addData ? addData.loan_purpose : "");
  const [limitRequest, setLimitRequest] = useState(addData ? addData.limit_amount : 0);
  const [errLimitMessage, setErrLimitMessage] = useState(language.text.cantBeEmpty);
  const [borrowerName, setBorrowerName] = useState(
    addData?.borrower_name  ||
    businessInformation?.details?.business.company_detail?.president_director_name?.value ||
    businessInformation?.details?.business.company_detail?.active_persero_name?.value ||
    businessInformation?.details?.business.company_detail?.business_principal_fullname?.value ||
    businessInformation?.details?.business.company_detail?.owner_name?.value ||
    businessInformation?.fullname
  );
  const [errLoanTerm, setErrLoanTerm] = useState(!loanTerm && true);
  const [errLoanPurpose, setErrLoanPurpose] = useState(!loanPurpose && true);
  const [errLimitRequest, setErrLimitRequest] = useState(!limitRequest && true);  
  const [errBorrowerName, setErrBorrowerName] = useState(!borrowerName && true);

  const optTerm = [
    {value: 6, label: `6 ${language.label.months}`},
    {value: 12, label: `12 ${language.label.months}`}
  ];

  const optPurpose = [
    {value: "bridging_loan", label: language.label.bridgingLoan},
    {value: "working_capital", label: language.label.workingCapital},
    {value: "expansion", label: language.label.expansion},
    {value: "other", label: language.label.other}
  ];
  
  function handleChangeTerm(val) {
    if(val) {
      setLoanTerm(val.value);
      setErrLoanTerm(false);
    } else {
      setLoanTerm(null);
    }
  }

  function handleChangePurpose(val) {
    if (val) {
      setLoanPurpose(val.value);
      setErrLoanPurpose(false);
    } else {
      setLoanPurpose("");
    }
  }

  function handleChangeBorrower(val) {
    setBorrowerName(val.target.value);
    if(val.target.value === "")
      setErrBorrowerName(true);
    else
    setErrBorrowerName(false);
  }

  function handleChangeLimit(value) {
    setLimitRequest(value);
    if(!value|| value === 0){
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.cantBeEmpty);
    } else if (value < 0) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.minimumLimitRequest);
    } else if (value % 100000 !== 0) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.moduloHundredThousand);
    } else if (value > 2000000000) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.maximumLimitRequest);
    } else {
      setErrLimitRequest(false);
      setErrLimitMessage("");
    }
  }

  function handleSubmit() {
    if (!errLoanPurpose && !errLoanTerm && !errLimitRequest) {
      localStorage.setItem(`add-data-${userCode}`, 
        JSON.stringify({
          loan_term: loanTerm,
          loan_purpose: loanPurpose,
          limit_amount: limitRequest,
          borrower_name: borrowerName
        })
      );
      handleChangeSubmitFinancing();
    }
  }
    
  return (
    <React.Fragment>
      <h5>{language.loanKoinVoice.labelLoanCodeFinancing}</h5>
      <div>
        {" "}
        <TextField
          label={language.loanKoinVoice.financingCode}
          variant="outlined"
          onChange={handleChangeFinancingLoan}
          type="text"
          style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
          id="referral-number"
          name="referral-number"
        />
      </div>
      {koinvoice.isShowFinancingAlert ? (
        <div>
          <div>
            <TextField
              disabled
              label={language.loanKoinVoice.financingName}
              style={{ marginTop: 20 }}
              defaultValue={financingCode && financingCode.referral_name}
              variant="outlined"
              id="referral-name"
              name="referral-name"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              disabled={business_type === 'home_business' || business_type === 'individual'}
              label={language.form.borrowerName}
              defaultValue={borrowerName}
              onChange={handleChangeBorrower}
              variant="outlined"
              type="text"
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
              error={!borrowerName && errBorrowerName}
              helperText={errBorrowerName && language.label.cantBeEmpty}
              id="borrower-name"
              name="borrower-name"
            />
          </div>
          <Divider orientation="left">{language.label.limitInfo}</Divider>
          <div style={{ marginTop: 20 }}>
            <TextField
              label={language.form.limitRequest}
              defaultValue={limitRequest}
              variant="outlined"
              type="text"
              onChange={handleChangeLimit}
              error={!limitRequest || errLimitRequest}
              helperText={errLimitRequest ? errLimitMessage : ""}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
              InputProps={{
                inputComponent: CurrencyFormatInput
              }}
              id="limit-amount"
              name="limit-amount"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              id="loan-term"
              name="loan-term"
              label={language.form.loanTermLms}
              placeholder={language.form.chooseTermLms}
              value={
                optTerm.filter(option => 
                   option.value === loanTerm)
              }
              options={optTerm}
              onChange={handleChangeTerm}
              error={!loanTerm && errLoanTerm}
              helperText={errLoanTerm ? language.label.cantBeEmpty : ""}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              id="loan-purpose"
              name="loan-purpose"
              label={language.form.loanPurpose}
              placeholder={language.form.chooseLoanPurpose}
              value={
                optPurpose.filter(option => 
                   option.value === loanPurpose)
              }
              options={optPurpose}
              onChange={handleChangePurpose}
              error={!loanPurpose && errLoanPurpose}
              helperText={errLoanPurpose ? language.label.cantBeEmpty : ""}
            />
          </div>
        </div>
      ) : (
        koinvoice.financingError && (
          <Alert
            message={koinvoice.financingError.data.message[flag]}
            style={{ width: isMobile() ? "40%" : "100%", marginTop: 20 }}
            type="error"
          />
        )
      )}{" "}
      <Spin spinning={isShowLoading}>
        <button
          className="btn btn-primary w-100"
          style={{ marginTop: 20 }}
          disabled={koinvoice.isEnabledButtonFinancing}
          onClick={handleSubmit}
          id="btn-next-loan-purpose"
        >
          {language.button.next}
        </button>
      </Spin>
    </React.Fragment>
  );
}
