import React from "react";
import { withRouter } from "react-router-dom";
import ReferCompanyForm from "./RefCompanyForm";
import ReferCompanyPage from "./ReferCompany";

class ReferCompany extends React.Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.state = {
      page: 1
    }
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 })
  }
    
  render() {
    const { page } = this.state;
    const { onSubmit } = this.props;
    return (
      <div>
        {page === 1 && <ReferCompanyPage onClick={this.nextPage}/>}
        {page === 2 && <ReferCompanyForm onSubmit={onSubmit}/>}
      </div>
    )
  }
}

export default withRouter(ReferCompany);
