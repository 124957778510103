import styled from "styled-components";

export const UploadKtpStyle = styled.div`
  .kw-button {
    background-color: #2b476c !important;
    border-color: #2b476c !important;
    color: #ffffff !important;
    width: 100%;
  }
  .kw-uploadKtp {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h5 {
      font-weight: 555;
      margin-top: 35px;
      font-size: 24px;
      line-height: 34px;
      color: #2b486d;
    }
    p {
      display: flex;
      align-items: center;
      color: #566068;
    }
  }
`;
