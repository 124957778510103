import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getActionDocumentUpload,
  getActionLimitRequest,
} from "../../../stores/actions/koinvoiceLoan";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Spin, Row, Col, Alert } from "antd";
import DrawerSuccessLink from "./DrawerSuccessLink";
import history from "../../../util/history";
import cookie from "react-cookies";
import koinvoiceServices from "../../../services/koinvoice";
import { capitalizeFirstLetter, checkTranslate } from "../../../library/checkCapitalize";

function UnggahDokumen({
  getActionDocumentUpload,
  getActionLimitRequest,
  koinvoice: { documentRequestError },
  init: { language, flag },
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowLoadingButton, setIsShowLoadingButton] = useState(false);
  const [isShowSucessResendEmail, setIsShowSucessResendEmail] = useState(false);
  const [document, setDocument] = useState([]);
  const [documentRequired, setDocumentRequired] = useState([]);
  // const { limit_application_id } =
  //   cookie.load("limit-application") !== undefined &&
  //   cookie.load("limit-application").data;
  //const baseUrl = config.document.url;

  useEffect(() => {
    koinvoiceServices
      .documentListOld({
        params: { product: "koin_invoice", status: "not_uploaded" },
        loading: setIsShowLoading,
      })
      .then((res) => {
        if (res.status === 200) {
          let names = res.data.map(function (item) {
            return capitalizeFirstLetter(item["name" || "key"]);
          });
          setDocument(names);
          setDocumentRequired(res.data);
        } else {
          setDocument([]);
          setDocumentRequired([]);
        }
      });// eslint-disable-next-line
  }, []);

  function actionSubmitResendEmail(params) {
    getActionLimitRequest(
      { required_docs: document },
      setIsShowLoadingButton,
      setIsShowSucessResendEmail,
      params
    );
  }

  function actionShowDrawerSucsessResendLink(e) {
    setIsShowSucessResendEmail(!isShowSucessResendEmail);
    if (e === "closeDariDrawer") {
      history.push("/dashboard-limit-application");
    }
    window.localStorage.removeItem("personalInfo");
    cookie.remove("loanApply", { path: "/dashboard-limit-application" });
  }

  function actionUnggahDokumen() {
    actionSubmitResendEmail("unggah-dokumen");
  }

  function actionChangeDashboardDirect() {
    actionSubmitResendEmail("unggah-dashboard");
  }

  return (
    <div className="kw-documentUnggah-wrapper">
      <h2>{language.loanUnggahDocument.uploadDocuments}</h2>
      <Spin style={{ width: "100%", margin: "auto" }} spinning={isShowLoading}>
        <img
          src={require("../../../assets/images/applyloan/unggah-dokumen.png").default}
          alt=""
        />
        <h3 style={{ marginTop: 20 }}>
          {language.limitUnggahDocument.paragraphHeadingOne}
        </h3>
        <p style={{ marginTop: 20, marginBottom: 20 }}>
          {language.loanUnggahDocument.paragraphHeadingTwo}
        </p>
        {documentRequired.length > 0 &&
          documentRequired.map((list, index) => {
            return (
              <p key={index}>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {checkTranslate(list.key || list.name, language)}
              </p>
            );
          })}
        <Spin spinning={isShowLoadingButton}>
          <Row>
            <Col xs={24} md={24}>
              <Button
                onClick={() => actionUnggahDokumen()}
                style={{ width: "100%" }}
                size="large"
                className="kw-button"
              >
                {language.button.uploadDocument}
              </Button>{" "}
            </Col>
            <Col xs={24} md={24}>
              {documentRequestError && (
                <Alert
                  type="error"
                  style={{ marginTop: 15, marginBottom: 15 }}
                  message={
                    documentRequestError.status === 504
                      ? "Request Time Out 504"
                      : documentRequestError.data.message.en
                  }
                />
              )}
              {/* <Spin spinning={isShowLoadingButton}>
              <Button
                onClick={() => actionSubmitResendEmail()}
                size="large"
                className="kw-documentUnggah-wrapper__button-sendLink"
              >
                {language.button.sendLink}
              </Button>
            </Spin> */}
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Button
                size="large"
                style={{
                  width: "100%",
                  marginTop: 20,
                  border: "1px solid blue",
                  color: "blue",
                }}
                onClick={() => actionChangeDashboardDirect()}
              >
                {language.button.toDashboard}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Spin>
      <DrawerSuccessLink
        actionShowDrawerSucsessResendLink={actionShowDrawerSucsessResendLink}
        language={language}
        visible={isShowSucessResendEmail}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionDocumentUpload,
      getActionLimitRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnggahDokumen);
