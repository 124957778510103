import React, { useEffect } from "react";
import CashKoinWrapper from "../../Components/CashKoinWrapper";
import {
  getAplicationProsess,
  getLoanLimit,
  getLoanBalance,
} from "../../stores/actions/koinvoiceLoanDashboard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RejectLimitProgress from "../../Components/RejectLimitProgress/RejectLimitProgress";

function RejectLimitKoinInvoice({
  koinvoiceDashboard: { loanLimit, loanBalance },
  creditLimitLoan,
  getLoanLimit,
  getLoanBalance,
  language,
}) {
  useEffect(() => {
    getLoanLimit();
    getLoanBalance(); // eslint-disable-next-line
  }, []);
  return (
    <div>
      <CashKoinWrapper
        loanBalance={loanBalance}
        limit={creditLimitLoan}
        language={language}
      />
      <RejectLimitProgress
        creditLimitLoan={creditLimitLoan}
        language={language} 
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAplicationProsess,
      getLoanLimit,
      getLoanBalance,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RejectLimitKoinInvoice);
