import React from "react";
import { Drawer, Button } from "antd";

function DrawerConfirmationReject({
  visible,
  onClose,
  actionRejectChooseOffer,
  isShowRejecetSucsess,
  actionChangeShowHideSucsess,
  actionChangeShowYourLoan,
  actionChangeShowDocumentList,
}) {
  function actionChangeAllClose() {
    actionChangeShowHideSucsess();
    actionChangeShowYourLoan();
    actionChangeShowDocumentList();
  }

  return (
    <Drawer
      title=" "
      placement={"bottom"}
      height={444}
      closable={false}
      onClose={onClose}
      visible={visible}
      key={"bottom"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={
            isShowRejecetSucsess
              ? require("../assets/images/dashboard/offerrejected.png").default
              : require("../assets/images/dashboard/offerreject.png").default
          }
          alt=""
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            height: 120,
          }}
        />
        <h4
          style={{
            color: "#2B486D",
            textAlign: "center",
            fontWeight: 555,
            fontSize: 28,
          }}
        >
          {isShowRejecetSucsess
            ? " We’re sorry that you rejected our offer."
            : "Reject Offer?"}
        </h4>
        <p style={{ textAlign: "center", color: "#566068" }}>
          {isShowRejecetSucsess
            ? "Please retry your application in a few months."
            : " You are going to reject our offers. You need to re-apply to get your KoinInvoice loan. Are you sure you want to reject offer?"}
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isShowRejecetSucsess ? (
            <p
              onClick={() => actionChangeAllClose()}
              style={{
                color: "#566068",
                textDecorationLine: "underline",
                textTransform: "uppercase",
                cursor: "pointer",
                fontWeight: 555,
                textAlign: "center",
                marginTop: 15,
              }}
            >
              {" "}
              CLOSE
            </p>
          ) : (
            <React.Fragment>
              <Button
                size="large"
                style={{
                  backgroundColor: "#2b476c",
                  color: "#FFFFFF",
                  borderColor: "#2b476c",
                  width: 350,
                  marginRight: 15,
                }}
                onClick={() => onClose()}
              >
                CANCEL
              </Button>
              <Button
                size="large"
                style={{
                  backgroundColor: "#EFEFEF",
                  color: "#C53741",
                  borderColor: "#EFEFEF",
                  width: 350,
                }}
                onClick={() => actionRejectChooseOffer()}
              >
                YES, REJECT
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    </Drawer>
  );
}

export default DrawerConfirmationReject;
