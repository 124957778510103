export function labelCondition(item) {
  let background;
  let width;
  let color;
  let varLabelCondition = {
      background,
      width,
      color
  }
  if (item.status === "late") {
    varLabelCondition.background = "#FEF2D3";
    varLabelCondition.color = "#EEAC00";
    varLabelCondition.width = 63;
  } else if (item.status === "paid") {
    varLabelCondition.background = "#D9EFDF";
    varLabelCondition.color = "#41AF60";
    varLabelCondition.width = 63;
  } else if (
    item.status === "Partially PAID" ||
    item.status === "partially_paid"
  ) {
    varLabelCondition.background = "#FFDFCF";
    varLabelCondition.color = "#ED6E2E";
    varLabelCondition.width = 85;
  } else if (item.status === "upcoming") {
    varLabelCondition.background = "#EFEFEF";
    varLabelCondition.color = "#8E8E8E";
    varLabelCondition.width = 110;
  } else if (
    item.status === "due_today" ||
    item.status === "Due Today" ||
    item.status === "DUE TODAY"
  ) {
    varLabelCondition.background = "rgba(55, 141, 244, 0.1)";
    varLabelCondition.color = "#378DF4";
    varLabelCondition.width = 70;
  } else if (
    item.status === "due_soon" ||
    item.status === "Due Soon" ||
    item.status === "DUE SOON"
  ) {
    varLabelCondition.background = "#41AF60";
    varLabelCondition.color = "#FFFFFF";
    varLabelCondition.width = 129;
  }
  return varLabelCondition;
}
