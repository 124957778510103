import React, { useEffect } from "react";
import DocumentListWrapper from ".";
import { DashboardKoinInvoiceStyle } from "../BorrowerDashboardKoinInvoice/style";
import DashboardLayoutKoinInvoice from "../Layout/DashboardLayoutKoinInvoice";
import { useState } from "react";
import { Card } from "antd";
import DocumentContract from "./DocumentContract";
import koinvoiceServices from "../services/koinvoice";
import DocumentApproved from "./DocumentApproved";
import { getApplicationList } from "../stores/actions/koinvoiceLoan";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function DocumentListPage({
  getApplicationList,
  koinvoice: { applicationListError },
}) {
  const [isShowDetailDocuments, setIsShowDetailDocuments] = useState(false);
  const [isDocumentListShow, setIsDocumentListShow] = useState("");
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [applicationList, setApplicationList] = useState({});

  useEffect(() => {
    koinvoiceServices
      .actionChangeGetYourLoan({
        params: { filter: "waiting_for_contract" },
        loading: setIsShowLoading,
      })
      .then((list) => {
        if (list.status === 200) {
          setApplicationList(list);
        } else {
          setApplicationList(undefined);
        }
      });
    getApplicationList(); // eslint-disable-next-line
  }, []);

  function actionChangeShowDashboard(e) {
    setIsDocumentListShow(e);
    setIsShowDetailDocuments(!isShowDetailDocuments);
  }

  return (
    <DashboardKoinInvoiceStyle>
      <div className="kw-dashboardKinInvoiceWrapper">
        <DashboardLayoutKoinInvoice>
          {isShowDetailDocuments === false ? (
            <Card style={{ height: "70vh" }}>
              <div
                onClick={() => actionChangeShowDashboard("required-documents")}
                className="kw-dashboardKinInvoiceWrapper__list-card-documentListPage"
              >
                <p>Required Documents</p>{" "}
                <p
                  style={{ color: "#071E35", fontSize: 16, fontWeight: 555 }}
                >{`>`}</p>
              </div>{" "}
              <div
                onClick={() => actionChangeShowDashboard("loan-contract")}
                className="kw-dashboardKinInvoiceWrapper__list-card-documentListPage"
              >
                <p>Loan Contract</p>
                <p
                  style={{ color: "#071E35", fontSize: 16, fontWeight: 555 }}
                >{`>`}</p>
              </div>{" "}
              <div
                onClick={() => actionChangeShowDashboard("approved")}
                className="kw-dashboardKinInvoiceWrapper__list-card-documentListPage"
              >
                <p>Approved</p>
                <p
                  style={{ color: "#071E35", fontSize: 16, fontWeight: 555 }}
                >{`>`}</p>
              </div>
            </Card>
          ) : (
            <React.Fragment>
              {isDocumentListShow === "required-documents" && (
                <DocumentListWrapper
                  actionChangeShowDocumentList={actionChangeShowDashboard}
                />
              )}
              {isDocumentListShow === "loan-contract" && (
                <DocumentContract
                  isShowLoading={isShowLoading}
                  actionChangeShowDashboard={actionChangeShowDashboard}
                  applicationList={applicationList.data}
                />
              )}
              {isDocumentListShow === "approved" && (
                <DocumentApproved
                  actionChangeShowDashboard={actionChangeShowDashboard}
                />
              )}
            </React.Fragment>
          )}
        </DashboardLayoutKoinInvoice>
      </div>
    </DashboardKoinInvoiceStyle>
  );
}

const mapStateToProps = (state) => ({ koinvoice: state.koinvoice });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListPage);
