import React from "react";
import { NotFoundStyle } from "./style";

export default function NotFound({ image, label, paragraph, isShow = false }) {
  return (
    <NotFoundStyle style={{width:"100%"}}>
      <div className="kw-notFound-wrapper">
        <div
          className="kw-notFound-wrapper__image"
          style={{ marginTop: isShow ? "0%" : "35%" }}
        >
          <img src={image} alt="" />
        </div>
        <div className="kw-notFound-wrapper__text-content">
          <strong>{label}</strong>
          <span>{paragraph}</span>
        </div>
      </div>
    </NotFoundStyle>
  );
}
