import React from "react";
import { withRouter } from "react-router-dom";
import LoginForm from "./Login";
import SecureCodeForm from "./SecureCodeForm";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.state = {
      page: 1,
    };
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  render() {
    const { page } = this.state;
    const { onSubmit } = this.props;
    return (
      <div>
        {page === 1 && <LoginForm nextPage={this.nextPage} />}
        {page === 2 && (
          <SecureCodeForm onSubmit={onSubmit} landingForm={true} />
        )}
      </div>
    );
  }
}

export default withRouter(Login);
