import styled from "styled-components";
// import "react-owl-carousel2/style.css";

export const PartnersWrapper = styled.div`
  background: #ffffff;
  .partners {
    .content-wrapper {
      margin-top: 120px;
      margin-bottom: 160px;
      @media (max-width: 575.98px) {
        margin-top: 40px;
        margin-bottom: 50px;
      }
      .text-wrapper {
        text-align: center;
        margin-bottom: 60px;
        .heading {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 54px;
          line-height: 64px;
          text-align: center;
          letter-spacing: 0.41px;
          color: #2b486d;
          margin-bottom: 40px;
          @media (max-width: 575.98px) {
            font-size: 34px;
            line-height: 44px;
            margin-bottom: 20px;
          }
        }
        .text {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 0.38px;
          color: #566068;
          max-width: 610px;
          margin: 0 auto;
        }
      }
      .img-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .single-partner {
          display: flex;
          align-self: center;
          width: auto;
          margin-right: 45px;
          @media (max-width: 575.98px) {
            flex: 0 0 50%;
            max-width: 50%;
            margin-right: 0;
            margin-bottom: 45px;
          }
          img {
            margin: 0 auto;
          }
        }
      }
    }
  }
`;
