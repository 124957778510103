import styled from "styled-components";

export const SubmissionTypeStyle = styled.div`
  .kw-submissionType-wrapper {
    background: #f8fcff;
    padding: 25px;
    &__heading-label {
      text-align: center;
      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 54px;
        text-align: center;
        letter-spacing: 0.41px;
        color: #071e35;
      }
    }
    &__card-content {
      margin: 10px;
      img {
        float: right;
        height: 70px;
      }
      ul {
        margin-top: 18px;
      }
      li {
        line-height: 35px;
      }
      h4 {
        color: #2b486d;
        font-weight: 555;
      }
      strong {
        color: #2b486d;
        font-weight: 400;
        margin-bottom: 25px;
      }
    }
    &__card-content-paragraph {
      .img-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 25px;
        .single-partner {
          display: flex;
          align-self: center;
          width: 15%;
          margin-right: 45px;
          @media (max-width: 575.98px) {
            flex: 0 0 50%;
            max-width: 50%;
            margin-right: 0;
            margin-bottom: 45px;
          }
          img {
            margin: 0 auto;
            width:100%;
          }
        }
      }
      span {
        margin-top: 15px;
        display: block;
      }
    }
  }
`;
