import React, { useEffect } from "react";
import { ApplyLoanKoinVoiceStyle } from "./style";
import SelectLoanYourPurpose from "./components/SelectLoanYourPurpose/SelectLoanYourPurpose.jsx";
import { connect } from "react-redux";
import {
  businessInformationKoinvoice,
  getApplicationList,
} from "../stores/actions/koinvoiceLoan";
import { getActionProfileUsers } from "../stores/actions/koinvoiceLoanDashboard";
import { getLoanLimit } from "../stores/actions/koinvoiceLoanDashboard";
import { bindActionCreators } from "redux";

function ApplyLimitKoinVoice({
  getLoanLimit,
  getApplicationList,
  getActionProfileUsers,
  businessInformationKoinvoice,
}) {
  useEffect(() => {
    businessInformationKoinvoice();
    getLoanLimit();
    getActionProfileUsers();
    getApplicationList(); // eslint-disable-next-line
  }, []);
  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <SelectLoanYourPurpose />
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInformationKoinvoice,
      getLoanLimit,
      getActionProfileUsers,
      getApplicationList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyLimitKoinVoice);
