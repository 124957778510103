import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLoanLender } from "../../../stores/actions/loan";
import { Table, ProgressBar } from "react-bootstrap";
import NumberFormat from "react-number-format";

// import MailResend from "../../../assets/images/dashboard/mailresend.png"
// import MailNotSend from "../../../assets/images/dashboard/mailnotsend.png"
import LDNolender from "../../../assets/images/dashboard/nolender.png"


class FundingComp extends Component {
  
  componentDidMount() {
    let { auth, balance: { data } } = this.props;
    this.props.getLoanLender(data.loan_id, auth.token);
  }

  render() {
    let { loan, balance, init: { language } } =this.props;
    return (
      <>
        <div className="funding">
          <div className="heading">
            <div className="title">Funding Progress</div>
            <div className="subtitle">Loan ID #{balance.data.loan_id}</div>
            {loan.loanLender && (
              <div className="progbar w-75">
                <ProgressBar now={60} label={60} />
                <div className="count success">50,000,000</div>
                <div className="text">is collected from total amount 100,000,000.</div>
              </div>
            )}
          </div>

          {loan.loanLender && (
            <div>
              <div className="text-total">Total Lenders: {loan.loanLender.length}</div>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Lender ID</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loan.loanLender.map(data => (
                    <tr key={data.lender_id}>
                      <td>
                        { new Date(data.date).toLocaleDateString("ID-id",
                          { month: 'short', day: 'numeric' })}
                      </td>
                      <td>{data.lender_id}</td>
                      <td>
                        <NumberFormat
                          value={data.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalSeparator="."
                          decimalScale={3}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {loan.isGetLoanLenderFailed && (
            <div className="ldlnotfound">
              <figure className="modal-img mb-4">
                <img src={LDNolender} alt="success" />
              </figure>
              <h3 className="title">{language.title.noRepaymentDue}</h3>
              <p className="text w-75">Your repayment will show up here.</p>
            </div>
          )}
        </div>
      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  balance: state.balance,
  loan: state.loan
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getLoanLender
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FundingComp);