import React from "react";
import { Drawer, Button, Checkbox, Row, Col } from "antd";
import MobileLayout from "../../Layout/MobileLayout/MobileLayout";

function ConfirmationReject({
  visible,
  onClose,
  actionRejectChooseOffer,
  actionChangeShowHideSucsess,
  actionChangeShowYourLoan,
  actionChangeShowDocumentList,
  isShowLoading,
  setRejectReason
}) {
  // function actionChangeAllClose() {
  //   actionChangeShowHideSucsess();
  //   actionChangeShowYourLoan();
  //   actionChangeShowDocumentList();
  // }

  function onChangeRejectReason(checkedValues) {
    setRejectReason(checkedValues)
  }

  return (
    <Drawer
      title=" "
      placement={"bottom"}
      height={555}
      closable={false}
      onClose={onClose}
      visible={visible}
      key={"bottom"}
    >
      <MobileLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={require("../../assets/images/dashboard/offerrejected.png").default}
            alt=""
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              height: 120,
            }}
          />
          <h4
            style={{
              color: "#2B486D",
              textAlign: "center",
              fontWeight: 555,
              fontSize: 28,
            }}
          >
            {" We’re sorry that you rejected our offer."}
          </h4>
          <p style={{ textAlign: "left", color: "#566068" }}>
            {"Please state the reason for rejection"}
          </p>
          <Checkbox.Group
            style={{ width: "100%", marginBottom: 20 }}
            defaultValue={"Disagree with the interest rate"}
            onChange={onChangeRejectReason}
          >
            <Row>
              <Col span={24}>
                <Checkbox value="Disagree with the interest rate">
                  Disagree with the interest rate
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Disagree with the plafond">
                  Disagree with the plafond
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox value="Invoice has been paid">
                  Invoice has been paid
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <React.Fragment>
              <Button
                size="large"
                style={{
                  backgroundColor: "#2b476c",
                  color: "#FFFFFF",
                  borderColor: "#2b476c",
                  width: 350,
                  marginRight: 15,
                }}
                onClick={() => onClose()}
              >
                CANCEL
              </Button>
              <Button
                size="large"
                style={{
                  backgroundColor: "#EFEFEF",
                  color: "#C53741",
                  borderColor: "#EFEFEF",
                  width: 350,
                }}
                loading={isShowLoading}
                onClick={() => actionRejectChooseOffer()}
              >
                YES, REJECT
              </Button>
            </React.Fragment>
          </div>
        </div>
      </MobileLayout>
    </Drawer>
  );
}

export default ConfirmationReject;
