import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  validateEmployee,
  getPartnerData,
  validateBankAccount
} from "../../stores/actions/gaji";
import { StepWorkWrapper } from "./StepValidation.styles";
import SimpleTextInput from "../../Components/Form/simpleTextInput";
import history from "../../util/history";

import imgNotPartner from "../../assets/images/no-bank-detail.svg";
import imgAccountFrozen from "../../assets/images/account-freeze.svg";
import imgInsertBankDetail from "../../assets/images/bank-detail.svg";

class StepValidation extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    bankAccount: "",
    attempt: 0
  };

  handleSubmit = () => {
    let { bankAccount, attempt } = this.state;
    let {
      validatedEmployee,
      isAccountNumberMatch
    } = this.props.gaji;
    attempt += 1;
    this.setState({ attempt: attempt });
    this.props.validateBankAccount({
      attempt: attempt,
      account_number: bankAccount,
      id: validatedEmployee.employee_id
    })
    if (isAccountNumberMatch) {
      this.props.handleSubmit();
    }
  };

  componentDidMount() {
    let { personal } = this.props.account.profile;
    let { loanInfo } = this.props.gaji;

    if (personal.fullname) {
      this.props.validateEmployee({
        name: personal.fullname.value,
        dob: personal.date_of_birth.value,
        nik: personal.nik.value
      });
    }
    if (!personal.fullname) {
      this.props.getPersonalProfile(this.props.auth.token);
    }
    this.props.getPartnerData({
      partnerId: loanInfo.companyId
    })
  }

  render() {
    const {
      invalid,
      pristine,
      submitting,
      gaji : { 
        validatedEmployee,
        validatedCompany,
        matchErrors,
        isAccountNumberMatch
      },
      init: { language, flag }
    } = this.props;

    return (
      <StepWorkWrapper>
        <div className="card-body register-body pb-0">
          { validatedEmployee && validatedCompany &&
            validatedEmployee.employee_id === 0 &&
            validatedCompany.company_id === 0 && (
            <div className="content-result">
              <figure className="img-notfound">
                <img src={imgNotPartner} alt="" />
              </figure>
              <h3 className="notfound-title">
                {language.title.validatedEmployeeCantVerify}
              </h3>
              <p className="notfound-text">
                {language.subtitle.validatedEmployeeCantVerify}
              </p>
              <span
                className="link-back-button"
                onClick={() => history.push("/")}
              >
                {language.button.back}
              </span>
            </div>
          )}
          {/* account frozen */}
          { validatedEmployee && validatedCompany &&
            validatedEmployee.employee_id !== 0 &&
            validatedCompany.company_id !== 0 &&
            validatedCompany.freeze_account === true && (
            <div className="content-result">
              <figure className="img-notfound">
                <img src={imgAccountFrozen} alt="" />
              </figure>
              <h3 className="notfound-title">
                {language.title.validatedButAccountFrozen}
              </h3>
              <p className="notfound-text">
                {language.subtitle.validatedButAccountFrozen}
              </p>
              <span
                className="link-back-button"
                onClick={() => history.push("/")}
              >
                {language.button.back}
              </span>
            </div>
          )}
          {/* bank Account invalid / doesnt match */}
          { this.state.attempt === 3 && 
            !isAccountNumberMatch && (
            <div className="content-result">
              <figure className="img-notfound">
                <img src={imgNotPartner} alt="" />
              </figure>
              <h3 className="notfound-title">
                {language.title.validatedEmployeeCantVerify}
              </h3>
              <p className="notfound-text">
                {language.subtitle.validatedEmployeeCantVerify}
              </p>
              <span
                className="link-back-button"
                onClick={() => history.push("/")}
              >
                {language.button.back}
              </span>
            </div>
          )}

          {/* valid to next step */}
          { validatedEmployee && validatedCompany
            && validatedEmployee.employee_id !== 0
            && validatedCompany.freeze_status === false 
            && this.state.attempt < 3
            && (
            <div className="content-result">
              <figure className="img-notfound">
                <img src={imgInsertBankDetail} alt="" />
              </figure>
              <h3 className="notfound-title">
                {language.title.enterBankAccount}
              </h3>
              <p className="notfound-text">
                {language.subtitle.enterBankAccount}
              </p>
              <form onSubmit={this.handleSubmit}>
                <div className="input-wrapper">
                  <Field 
                    id="bankAccount"
                    name="bankAccount"
                    label={language.label.bankAccountNumber}
                    placeholder={language.label.bankAccountNumber}
                    component={SimpleTextInput}
                    onChange={e => this.setState({ bankAccount: e.target.value })}
                    className="w-100"
                    variant="outlined"
                  />
                </div>
                <div className="input-wrapper">
                  <button
                    type="button"
                    onClick={this.handleSubmit}
                    className={"step-next btn btn-primary w-100"}
                    disabled={ invalid || pristine || submitting }
                  >
                    {language.button.next}
                  </button>
                </div>
                <div className="input-wrapper">
                  {matchErrors && (
                    <p style={{ color: "#C53741"}}>
                      {matchErrors[flag]}
                    </p>
                  )}
                </div>
              </form>              
            </div>
          )}
        </div>
      </StepWorkWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  init: state.init,
  gaji: state.gaji,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    validateEmployee,
    validateBankAccount,
    getPartnerData
  }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(StepValidation);

export default reduxForm({
  form: "StepValidation",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(component);
