import React from "react";
import { Card, Button } from "antd";
import NotFound from "../../Components/NotFound";
import { WaitingForContractStyle } from "../../WaitingForContract/style";
import FilerServer from "file-saver";
import moment from "moment";

function WaitingForContract({ waitingForContract }) {
  /* Helper function */
  function download_file(fileURL, fileName) {
    var oReq = new XMLHttpRequest();
    // The Endpoint of your server
    var URLToPDF = fileURL;

    // Configure XMLHttpRequest
    oReq.open("GET", URLToPDF, true);

    // Important to use the blob response type
    oReq.responseType = "blob";

    // When the file request finishes
    // Is up to you, the configuration for error events etc.
    oReq.onload = function () {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser
      var file = new Blob([oReq.response], {
        type: "application/pdf",
      });

      // Generate file download directly in the browser !
      FilerServer.saveAs(file, fileName);
    };

    oReq.send();
  }

  function actionChangeDownloadOrSign(fileURL, fileName) {
    let link = document.createElement("a");
    (waitingForContract.action === "Download" ||
      waitingForContract.action === "download") &&
      download_file(fileURL, fileName);
    link.href = waitingForContract.link || waitingForContract.signed_contract;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const tenDaysInFuture = (date) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 10);
    return newDate;
  };
  let date = waitingForContract &&  moment(waitingForContract.last_updated_on).format("YYYY-MM-DD");
  let tenday = tenDaysInFuture(new Date(date));

  return (
    <Card>
      {waitingForContract && waitingForContract ? (
        <React.Fragment>
          <WaitingForContractStyle>
            <div className="kw-waitingFor-contract">
              <strong className="kw-waitingFor-contract__label">
                <p style={{ marginBottom: 0, lineHeight: 1.5 }}>
                Please send signed version of documents below (hard copy) to KoinWorks headquater or send email to c.admin.lumpsum@koinworks.com before
                </p>
                {waitingForContract.last_updated_on &&
                  moment(tenday).format("DD MMM YYYY")}
              </strong>
              <div className="kw-waitingFor-contract__card">
                <strong>
                  Credit Agreement{" "}
                  {waitingForContract && moment(waitingForContract.last_updated_on).format(
                    "DD/MM/YYYY"
                  )}
                </strong>
                <p>Loan ID : {waitingForContract.code}</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="large"
                    onClick={() =>
                      actionChangeDownloadOrSign(
                        waitingForContract.blank_contract,
                        waitingForContract &&
                          waitingForContract.blank_contract &&
                          waitingForContract.blank_contract.split("/").pop()
                      )
                    }
                    className="kw-button"
                  >
                    {waitingForContract.action}
                  </Button>
                </div>
              </div>
            </div>
          </WaitingForContractStyle>
        </React.Fragment>
      ) : (
        <NotFound
          image={require("../../assets/images/dashboard/norepayment.png").default}
          label={"Waiting contract not found"}
          paragraph={""}
          isShow={true}
        />
      )}
    </Card>
  );
}

export default WaitingForContract;
