import styled from "styled-components";
// import "react-owl-carousel2/style.css";

export const TestimonialsWrapper = styled.div`
  background: #ffffff;
  .testimonials {
    margin-top: 45px;
    .carousel {
      .slide {
        background: transparent;
        img {
          display: none;
        }
      }
      .thumbs {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        cursor: pointer;
        .thumb {
          display: flex;
          align-self: center;
          width: auto;
          margin-right: 75px;
          height: 70px;
          @media (max-width: 575.98px) {
            margin-right: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          &.selected,
          &:hover {
            border: transparent;
          }
          img {
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
`;
