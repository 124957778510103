import React from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createPersonalAccount } from "../../stores/actions/account";
import { Link } from "react-router-dom";
import OTPInput from "otp-input-react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import history from "../../util/history";
import { SecurityCodePageWrapper } from "./SecurityCode.styles";
import imgSuccess from "../../assets/images/success.png";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "2rem",
    flex: "1 1 auto",
    margin: "0",
    textAlign: "center",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    "& p": {
      fontSize: "15px",
      color: "#575756",
      width: "400px"
    },
    "& h3": {
      color: "#2b486d",
      fontSize: "34px",
      fontWeight: "bold",
      letterSpacing: "0.41px",
      lineHeight: "41px",
      fontFamily: "Source Sans Pro"
    },
    "& span": {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "22px",
      letterSpacing: "-0.41px",
      color: "#CCCCCC"
    }
  }
}))(MuiDialogContent);

class ConfirmSecurityForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    code: "",
    resp: {},
    isMatch: "",
    message: "",
    open: false
  };

  onInputChange = e => {
    this.setState({ code: e });
    let account = this.props.account.data;

    if (e !== account.pincode) {
      this.setState({
        isMatch: 0,
        message: "You inserted a wrong code."
      });
    } else {
      this.setState({
        isMatch: 1,
        message: ""
      });
    }
  };

  handleBack = e => {
    e.preventDefault();
    window.location.reload();
  }

  handleSubmit = () => {
    let account = this.props.account.data;
    let data = {
      full_name: account.full_name,
      gender: account.gender,
      date_of_birth: account.date_of_birth,
      phone_area: account.phone_area,
      phone_number: account.phone_number,
      email: account.email,
      pin: account.pincode,
      promo_code: account.promo_code,
      letter_of_agreement_date: new Date().toLocaleString()
    };
    this.props.createPersonalAccount(data);

  };

  handleClose = () => {
    this.setState({ open: false });
    history.push("/login");
  };

  render() {
    const { code } = this.state;
    const {
      account,
      handleSubmit,
      submitting,
      init: { language, flag }
    } = this.props;
    
    return (
      <SecurityCodePageWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <div className="back-button" style={{ cursor: "pointer" }}>
              <Link
                style={{ color: "#C4C4C4", textDecoration: "none" }}
                to="/"
                onClick={this.handleBack}
              >
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span>{language.button.back}</span>
              </Link>
            </div>
            <div className="card-body register-body">
              <h2 className="register-title">{language.title.confirmCode}</h2>
              <p className="text-center text-note">
                {language.subtitle.createCode}
              </p>
              <form
                id="ConfirmSecurityForm"
                className=""
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(this.handleSubmit)}
              >
                <div className="input-wrapper">
                  <OTPInput
                    value={code}
                    name="code"
                    onChange={this.onInputChange}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    secure={true}
                    className={`otp-input ${
                      this.state.message ? "invalid" : ""
                    }`}
                  />
                  <div
                    className={`${
                      this.state.message ? "invalid-feedback" : ""
                    }`}
                  >
                    {this.state.message}
                  </div>
                </div>
                <div className="input-wrapper">
                  <button
                    className={`btn btn-primary w-100`}
                    disabled={!this.state.isMatch || account.isLoading || submitting}
                  >
                    {language.button.submit}
                  </button>
                </div>
                <div className="input-wrapper">
                  {account.regErrors && (
                    <div className="invalid-feedback">{account.regErrors[flag]}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={account.isCreated ? account.isCreated : false }
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleClose}
          ></DialogTitle>
          <DialogContent className="modal-wrapper">
            <figure>
              <img src={imgSuccess} alt="img-success" />
            </figure>
            <h3 className="text-title">{language.title.accountCreatedModal}</h3>
            <p className="text-note">
              {language.text.pleaseVerifyEmail} 
              {" "}{account.data.email}
              {" "}{language.text.throughVerificationLink}
            </p>
          </DialogContent>
        </Dialog>
      </SecurityCodePageWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ createPersonalAccount }, dispatch);
};

const component = connect(mapStateToProps,mapDispatchToProps)(ConfirmSecurityForm);

export default reduxForm({
  form: "ConfirmSecurityForm"
})(component);
