import React, { useEffect, useState } from "react";
import { CardYourLoanStyle } from "../../YourLoan/style";
import { List, Row, Col, Spin, Progress} from "antd";
import { capitalizeFirstLetter } from "../../library/checkCapitalize";
import currencyRupiah from "../../library/currency";
import koinvoiceServices from "../../services/koinvoice";
import moment from "moment";

export default function CardYourLoans({
  item,
  index,
  actionChangeShowDocumentList,
  documentLoanType,
  isShowLoading,
  language
}) {
  const [isAllUploaded, setIsAllUploaded] = useState(true);

  const translateStatus = (target) => {
    if(target === "waiting_for_document") {
      return language.label.waitingForDocumentLoan
    } else if(target === "under_review") {
      return language.label.underReviewLoan
    } else if(target === "funding_process") {
      return language.label.fundingProcessLoan
    } else if(target === "on_going") {
      return language.label.onGoingLoan
    } else if(target === "waiting_for_contract") {
      return language.label.waitingForContractLoan
    } else if(target === "choose_offer") {
      return language.label.chooseOfferLoan
    } else {
      return target.toUpperCase().replace(/_/g, " ")
    }
  }

  useEffect(() => {
    getDocumentListNotUploaded();
  }, []);

  async function getDocumentListNotUploaded() {
    let params = {
      loan_id: item.id,
      status: "not_uploaded",
    };
    let documentList = await koinvoiceServices.documentList({
      params: params
    });
    
    if(documentList.data && documentList.data.length > 0) {
      setIsAllUploaded(false);
    } else if(documentList.length === 0 || !documentList.data) {
      setIsAllUploaded(true);
    }
  }

  return (
    <Spin spinning={isShowLoading} key={index} style={{ width: "100%", marginTop: "50%" }}>
      <CardYourLoanStyle>
        <div className="kw-cards-wrapper">
          <div className="kw-cardYourLoans-wrapper">
            <div
              key={index}
              onClick={() =>
                actionChangeShowDocumentList(item, "details-loans")
              }
              style={{ cursor: "pointer"}}
            >
              <List itemLayout="horizontal">
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={require("../../assets/images/dashboard-icon-koininvoice/your-loands.png").default}
                        alt=""
                      />
                    }
                    title={
                      <React.Fragment>
                        <strong
                          style={{
                            display: "block",
                            color: "#378DF4",
                            fontSize: 12,
                          }}
                        >
                          {`${item.code} >`} &nbsp;
                          <span
                            style={{
                              background: "rgba(55, 141, 244, 0.1)",
                              borderRadius: 100,
                            }}
                          >
                            {translateStatus(item.status)}
                          </span>
                        </strong>
                        <strong
                          style={{
                            fontSize: 12,
                            color: "#566068",
                            fontWeight: 555,
                          }}
                        >{`${"KoinInvoice"} : ${capitalizeFirstLetter(
                          item.description
                        )}`}</strong>
                      </React.Fragment>
                    }
                  />
                </List.Item>
              </List>
              <Row>
                <Col xs={8}>
                  <span style={{ display: "block", fontSize: 12 }}>
                    {" "}
                    {language.label.amount}{" "}
                  </span>
                  <strong style={{ fontSize: 11 }}>
                    {currencyRupiah(item.amount, " ")}
                  </strong>
                </Col>
                <Col xs={8}>
                  <span style={{ display: "block", fontSize: 12 }}>{language.label.term}</span>
                  <strong style={{ fontSize: 11 }}>{item.term} {language.label[item.tenure_unit + "Loan"]}</strong>
                </Col>
                <Col xs={8}>
                  <span style={{ display: "block", fontSize: 12 }}>
                    {language.label.requestDateLoan}
                  </span>
                  <strong style={{ fontSize: 11 }}>
                    {moment(item.request_date).format("DD MMMM YYYY")}
                  </strong>
                </Col>
              </Row>
            </div>
            <Row>
              {(item.status === "funding_process" ||
                documentLoanType === "funding_process") && (
                <Col style={{ marginTop: 20 }} xs={24}>
                  <Progress
                    percent={item.funding_percentage}
                    strokeColor={{
                      "0%": "#41AF60",
                      "100%": "#41AF60",
                    }}
                  />
                </Col>
              )}
              {(item.status === "on_going" ||
                documentLoanType === "on_going") && (
                <React.Fragment>
                  <Col xs={24} style={{ marginTop: 20 }}>
                    <div style={{ display: "flex" }}>
                      <Progress
                        percent={item.funding_percentage}
                        strokeColor={{
                          "0%": "#41AF60",
                          "100%": "#41AF60",
                        }}
                        style={{ width: "85%" }}
                        showInfo={false}
                      />
                      <span style={{ color: "#878787", fontWeight: 555 }}>
                        &nbsp; {item.funding_percentage} %
                      </span>
                    </div>
                  </Col>
                  <Col xs={20} style={{ marginTop: 10 }}>
                    <span
                      style={{
                        color: "#566068",
                        fontSize: 13,
                      }}
                    >
                      <strong>{currencyRupiah(item.amount, " ")}</strong>
                      &nbsp; is collected from &nbsp;
                      <strong>{item.total_lender} Lenders</strong>
                    </span>
                  </Col>
                </React.Fragment>
              )}

              <Col xs={24} style={{ marginTop: 10 }}>
                {" "}
                {(item.status === "funding_process" ||
                  documentLoanType === "funding_process") && (
                  <React.Fragment>
                    {" "}
                    <Col xs={24} style={{ marginTop: 20 }}>
                      <div style={{ display: "flex" }}>
                        <Progress
                          percent={item.funding_percentage}
                          strokeColor={{
                            "0%": "#41AF60",
                            "100%": "#41AF60",
                          }}
                          style={{ width: "85%" }}
                          showInfo={false}
                        />
                        <span style={{ color: "#878787", fontWeight: 555 }}>
                          &nbsp; {item.funding_percentage} %
                        </span>
                      </div>
                    </Col>
                    <span
                      style={{
                        color: "#566068",
                        fontSize: 13,
                      }}
                    >
                      <strong>{currencyRupiah(item.amount, " ")}</strong>
                      &nbsp; is collected from &nbsp;
                      <strong>{item.total_lender} Lenders</strong>
                    </span>{" "}
                  </React.Fragment>
                )}
              </Col>
              <Col xs={20} style={{ marginTop: 10 }}>
                {((item.status === "waiting_for_document" ||
                  documentLoanType === "waiting_for_document") && !isAllUploaded) && (
                  <>
                    <span
                      style={{
                        color: "#C53741",
                        fontSize: 13,
                        fontWeight: 555,
                      }}
                    >
                      {language.label.waitingForMandatoryDocument}
                    </span>
                    <br />
                  </>
                )}
                {((item.status === "under_review" ||
                  documentLoanType === "under_review") && !isAllUploaded) && (
                  <span
                    style={{
                      color: "#C53741",
                      fontSize: 13,
                      fontWeight: 555,
                    }}
                  >
                    {language.label.moreDocumentsRequired}
                  </span>
                )}
              </Col>
              <Col xs={4} style={{ marginTop: 10 }}>
                {/* {documentLoanType === "on_going" && (
                          <Button
                            onClick={() => actionChangeShowDocumentList(item)}
                            style={{
                              width: "100%",
                              background: "#2B486D",
                              color: "#FFFFFF",
                            }}
                            size="large"
                          >
                            PAY
                          </Button>
                        )} */}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={
                    item.status === "under_review" ||
                    documentLoanType === "under_review" ||
                    item.status === "waiting_for_document" ||
                    documentLoanType === "waiting_for_document" ||
                    item.status === "choose_offer" ||
                    documentLoanType === "choose_offer" ||
                    item.status === "waiting_for_contract" ||
                    documentLoanType === "waiting_for_contract"
                      ? () => actionChangeShowDocumentList(item, true)
                      : function () {}
                  }
                >
                  {(item.status === "under_review" ||
                    documentLoanType === "under_review" ||
                    item.status === "waiting_for_document" ||
                    documentLoanType === "waiting_for_document" ||
                    item.status === "waiting_for_contract" ||
                    documentLoanType === "waiting_for_contract") && (
                    <img
                      src={require("../../assets/images/dashboard-icon-koininvoice/button-documentList.png").default}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  )}
                  {(item.status === "choose_offer" ||
                    documentLoanType === "choose_offer") && (
                    <img
                      src={require("../../assets/images/dashboard-icon-koininvoice/button-document-offecer.png").default}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  )}
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </CardYourLoanStyle>
    </Spin>
  );
}
