import React, { useEffect, useState, useRef } from "react";
import { DashboardKoinInvoiceStyle } from "../../BorrowerDashboardKoinInvoice/style";
import DashboardLayoutKoinInvoice from "../../Layout/DashboardLayoutKoinInvoice";
import { ButtonMenuKoinInvoice } from "../../Components/ButtonMenuKoinInvoice";
import {
  actionButtonBackUnggahDokument,
  getApplicationList,
  businessInformationKoinvoice,
} from "../../stores/actions/koinvoiceLoan";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import YourLoan from "../../YourLoan";
import DisbursementBankAccount from "../../DisbursementBankAccount";
import bankAccount from "./bank.json";
import WaitingForContract from "../../WaitingForContract";
import DetailInformationLoan from "../../DetailInformationLoan";
import BorrowerDashboardKoinInvoiceLoanList from "../../BorrowerDashboardKoinInvoice/BorrowerDashboardKoinInvoiceLoanList";
import removeLocalStorageAndCookieLimit from "./RemoveLocalStorageAndCookieLimit";
import DocumentListAfterLoanWrapper from "../../DocumentList/DocumentListAfterLoanWrapper";

function DashboardKoinInvoiceLoanList({
  init: { language },
  getApplicationList,
  koinvoice: { applicationListError },
  businessInformationKoinvoice,
  actionButtonBackUnggahDokument,
}) {
  const [isShowYourLoanDetails, setIsShowYourLoanDetails] = useState(false);
  const [isShowDocumentList, setIsShowDocumentList] = useState(false);
  const [dataRepayment, setDataRepayment] = useState({});
  const [documentLoanType, setDocumentLoanType] = useState("");
  const [detailInformationLoans, setDetailInformationLoans] = useState();
  const [isShowListYourLoan, setIsShowListYourLoan] = useState("");
  const [creditUclLimit, setCreditUclLimit] = useState({});
  const mounted = useRef();

  useEffect(() => {
    getApplicationList();
    actionButtonBackUnggahDokument();
    businessInformationKoinvoice();
    removeLocalStorageAndCookieLimit(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (applicationListError?.data?.status === 404) {
        // history.push("/koininvoice/apply-loan");
      }
    }
  });

  const actionChangeShowYourLoan = (value, viewRequestedLoans) => {
    setDocumentLoanType(value);
    setIsShowListYourLoan(viewRequestedLoans);
    setIsShowYourLoanDetails(!isShowYourLoanDetails);
  };

  function actionChangeShowDocumentList(item, information) {
    setDetailInformationLoans(information);
    setDataRepayment(item);
    information === true && setDocumentLoanType(item && item.status);
    setIsShowDocumentList(!isShowDocumentList);
  }

  return (
    <DashboardKoinInvoiceStyle>
      <div className="kw-dashboardKinInvoiceWrapper">
        <DashboardLayoutKoinInvoice>
          <React.Fragment>
            {isShowYourLoanDetails === false ? (
              <React.Fragment>
                <BorrowerDashboardKoinInvoiceLoanList
                  language={language}
                  actionChangeShowYourLoan={actionChangeShowYourLoan}
                  setCreditUclLimitParent={setCreditUclLimit}
                />
                <ButtonMenuKoinInvoice 
                  creditLimitUcl={creditUclLimit}
                />
              </React.Fragment>
            ) : isShowDocumentList === false ? (
              <React.Fragment>
                <YourLoan
                  actionChangeShowYourLoan={actionChangeShowYourLoan}
                  language={language}
                  documentLoanType={documentLoanType}
                  isShowListYourLoan={isShowListYourLoan}
                  actionChangeShowDocumentList={actionChangeShowDocumentList}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {detailInformationLoans === "details-loans" && (
                  <DetailInformationLoan
                    dataRepayment={dataRepayment}
                    language={language}
                    actionChangeShowDocumentList={actionChangeShowDocumentList}
                  />
                )}
                {detailInformationLoans === true &&
                  (documentLoanType === "waiting_for_document" ||
                    documentLoanType === "under_review") && (
                    <DocumentListAfterLoanWrapper
                      isShow={true}
                      language={language}
                      dataRepayment={dataRepayment}
                      actionChangeShowDocumentList={
                        actionChangeShowDocumentList
                      }
                      buttonIsShow={true}
                    />
                  )}
                {detailInformationLoans === true &&
                  documentLoanType === "choose_offer" && (
                    <DisbursementBankAccount
                      actionChangeShowDocumentList={
                        actionChangeShowDocumentList
                      }
                      actionChangeShowYourLoan={actionChangeShowYourLoan}
                      bankAccount={bankAccount}
                      dataRepayment={dataRepayment}
                    />
                  )}
                {detailInformationLoans === true &&
                  documentLoanType === "waiting_for_contract" && (
                    <WaitingForContract
                      actionChangeShowYourLoan={actionChangeShowYourLoan}
                      dataRepayment={dataRepayment}
                    />
                  )}
              </React.Fragment>
            )}
          </React.Fragment>
        </DashboardLayoutKoinInvoice>
      </div>
    </DashboardKoinInvoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationList,
      businessInformationKoinvoice,
      actionButtonBackUnggahDokument,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardKoinInvoiceLoanList);
