import React from "react";
import { withRouter } from "react-router-dom";
import AccountFom from "./components/AccountForm";
import CreateSecurityForm from "./components/CreateSecurityForm";
import ConfirmSecurityForm from "./components/ConfirmSecurityForm";
import CreatePasswordBusiness from "./components/CreatePasswordBusiness";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";
import cookie from "react-cookies"
import OtpValidator from "./components/OtpValidator";

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.state = {
      page: 1,
    };
  }

  componentDidMount(){
    cookie.save("displayPage", 1);
    cookie.remove("principal", { path: "/" });
    cookie.remove("financing", { path: "/" });
    localStorage.removeItem("businessInformation")
    cookie.remove("current");
    localStorage.removeItem("paramsSubmit");
    cookie.remove("current", { path: "/" });
    cookie.remove("businessInformation", { path: "/" });
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  prevPage() {
    this.setState({ page: this.state.page - 1 });
  }

  

  render() {
    const { page } = this.state;
    return (
      <div>
        {page === 1 && <AccountFom nextPage={this.nextPage} />}
        {page === 2 && <OtpValidator nextPage={this.nextPage} prevPage={this.prevPage}/>}
        {hostNameCheckCondition
          ? page === 3 && (
              <CreatePasswordBusiness
              prevPage={this.prevPage}
              />
            )
          : page === 3 && <CreateSecurityForm onSubmit={this.nextPage} />}
        {page === 4 && <ConfirmSecurityForm />}
      </div>
    );
  }
}

export default withRouter(CreateAccount);
