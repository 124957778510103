import React, { Component } from "react";
import { connect } from "react-redux";

// import LDLoanImg from "../../../assets/images/dashboard/ldloan.png"
// import PendingImg from "../../../assets/images/dashboard/pendingimg.png"


class LDDocs extends Component {
  render() {
    let { balance, loan: { loanContract }, init: { language } } = this.props;

    return (
      <>
        <div className="ldcomp">
          <div className="heading">
            <div className="title">{language.label.document}</div>
          </div>

          <div className="card ldcard">
            <div className="card-body">
              <div className="title">Signed Borrower Agreement</div>
              <div className="text">Loan ID : {balance.data.loan_id}</div>
              {loanContract && (
                <div className="text-wrapper">
                  <span className="date">{language.text.lastUpdatedOn} {loanContract.last_updated_on}</span>
                  <span className="action link">
                    <a href={loanContract.link} target="_blank" rel="noopener noreferrer">{loanContract.action}</a>
                  </span>
                </div>
              )}
            </div>
          </div>

        </div>
      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  loan: state.loan,
  balance: state.balance
});

export default connect(mapStateToProps, null)(LDDocs);