const BUTTON_BACK = "BUTTON_BACK";
const UPLOAD_BACK_ACTION_REFRESH = "UPLOAD_BACK_ACTION_REFRESH"
const BUTTON_BACK_NEXT_SHARE_HOLDER = "BUTTON_BACK_NEXT_SHARE_HOLDER"
const BUTTON_SHARE_HOLDER_NEW_FORM_LIMIT ="BUTTON_SHARE_HOLDER_NEW_FORM_LIMIT"
const BUTTON_BACK_SELECT_LOAN = "BUTTON_BACK_SELECT_LOAN";
const BUTTON_BACK_UNGGAH_DOKUMENT = "BUTTON_BACK_UNGGAH_DOKUMENT";
const BUTTON_BACK_PERSONAL_INFO = "BUTTON_BACK_PERSONAL_INFO";
const BUTTON_BACK_BUSINESS_INFO = "BUTTON_BACK_BUSINESS_INFO";
const BUTTON_BACK_SHARE_HOLDER = "BUTTON_BACK_SHARE_HOLDER";
const DATA_BUSINESS_INFO_LOCAL = "DATA_BUSINESS_INFO_LOCAL";
const DATA_BUSINESS_INFO_LOCAL_SUBMIT = "DATA_BUSINESS_INFO_LOCAL_SUBMIT";
const DATA_PERSONAL_BUSINESS_INFO_LOCAL = "DATA_PERSONAL_BUSINESS_INFO_LOCAL";
const DATA_OWNER_DIRECTOR_BUSINESS_INFO_LOCAL = "DATA_OWNER_DIRECTOR_BUSINESS_INFO_LOCAL";
const DATA_SHARE_HOLDER_LOCAL = "DATA_SHARE_HOLDER_LOCAL";
const DATA_SHARE_HOLDER_LOCAL_RESET = "DATA_SHARE_HOLDER_LOCAL_RESET";
const UPLOAD_KTP_SELFIE_KTP = "UPLOAD_KTP_SELFIE_KTP"
const PRINCIPAL_SUCESS = "PRINCIPAL_SUCESS";
const PRINCIPAL_ERROR = "PRINCIPAL_ERROR";
const FINANCING_SUCESS = "FINANCING_SUCESS";
const FINANCING_ERROR = "FINANCING_ERROR";
const PURPOSE_SUCESS = "PURPOSE_SUCESS";
const PURPOSE_ERROR = "PURPOSE_ERROR";
const LOAN_DETAILS_SUCESS = "LOAN_DETAILS_SUCESS";
const LOAN_DETAILS_ERROR = "LOAN_DETAILS_ERROR";
const PUT_LOAN_DETAILS_SUCESS = "PUT_LOAN_DETAILS_SUCESS";
const PUT_LOAN_DETAILS_ERROR = "PUT_LOAN_DETAILS_ERROR";
const CALCULATOR_LANDING_PAGE_SUCESS = "CALCULATOR_LANDING_PAGE_SUCESS";
const CALCULATOR_LANDING_PAGE_ERROR = "CALCULATOR_LANDING_PAGE_ERROR";
const BUSINESS_INFO_SUCESS = "BUSINESS_INFO_SUCESS";
const BUSINESS_INFO_ERROR = "BUSINESS_INFO_ERROR";
const PUT_BUSINESS_INFO_SUCESS = "PUT_BUSINESS_INFO_SUCESS";
const PUT_BUSINESS_INFO_ERROR = "PUT_BUSINESS_INFO_ERROR";
const PUT_PERSONAL_INFO_SUCESS = "PUT_PERSONAL_INFO_SUCESS";
const PUT_PERSONAL_INFO_ERROR = "PUT_PERSONAL_INFO_ERROR";
const PUT_PERSONAL_INFO_PROFILE_SUCESS = "PUT_PERSONAL_INFO_PROFILE_SUCESS";
const PUT_PERSONAL_INFO_PROFILE_ERROR = "PUT_PERSONAL_INFO_PROFILE_ERROR";
const UPLOAD_NEW_SUCESS = "UPLAOD_NEW_SUCESS";
const UPLOAD_NEW_ERROR = "UPLOAD_NEW_ERROR";
const UPLOAD_KTP_VALIDATION_SUCESS = "UPLOAD_KTP_VALIDATION_SUCESS";
const UPLOAD_KTP_VALIDATION_ERROR = "UPLOAD_KTP_VALIDATION_ERROR";
const UPLOAD_NEW_SHARE_HOLDER_SUCESS = "UPLOAD_NEW_SHARE_HOLDER_SUCESS";
const UPLOAD_NEW_SHARE_HOLDER_ERROR = "UPLOAD_NEW_SHARE_HOLDER_ERROR";
const UPLOAD_KTP_VALIDATION_SHARE_HOLDER_SUCESS =
  "UPLOAD_KTP_VALIDATION_SHARE_HOLDER_SUCESS";
const UPLOAD_KTP_VALIDATION_SHARE_HOLDER_ERROR =
  "UPLOAD_KTP_VALIDATION_SHARE_HOLDER_ERROR";
const UPLOAD_SELFIE_KTP_VALIDATION_SUCESS =
  "UPLOAD_SELFIE_KTP_VALIDATION_SUCESS";
const UPLOAD_SELFIE_KTP_VALIDATION_ERROR = "UPLOAD_SELFIE_KTP_VALIDATION_ERROR";
const UPLOAD_SELFIE_MATCH_KTP_VALIDATION_SUCESS =
  "UPLOAD_SELFIE_MATCH_KTP_VALIDATION_SUCESS";
const UPLOAD_SELFIE_MATCH_KTP_VALIDATION_ERROR =
  "UPLOAD_SELFIE_MATCH_KTP_VALIDATION_ERROR";
const UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_SUCESS =
  "UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_SUCESS";
const UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_ERROR =
  "UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_ERROR";
const UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_SUCESS =
  "UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_SUCESS";
const UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_ERROR =
  "UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_ERROR";
const UPLOAD_KK_SUCESS = "UPLOAD_KK_SUCESS";
const UPLOAD_KK_ERROR = "UPLOAD_KK_ERROR";
const SEND_DOCUMENT_REQUEST_SUCESS = "SEND_DOCUMENT_REQUEST_SUCESS";
const SEND_DOCUMENT_REQUEST_ERROR = "SEND_DOCUMENT_REQUEST_ERROR";
const SEND_DOCUMENT_LOAN_REQUEST_SUCESS = "SEND_DOCUMENT_LOAN_REQUEST_SUCESS";
const SEND_DOCUMENT_LOAN_REQUEST_ERROR = "SEND_DOCUMENT_LOAN_REQUEST_ERROR";
const DOCUMENT_UPLOAD_SUCESS = "DOCUMENT_UPLOAD_SUCESS";
const DOCUMENT_UPLOAD_ERROR = "DOCUMENT_UPLOAD_ERROR";
const RESEND_EMAIL_SUCESS = "RESEND_EMAIL_SUCESS";
const RESEND_EMAIL_ERROR = "RESEND_EMAIL_ERROR";
const APPLICATION_LIST_SUCESS = "APPLICATION_LIST_SUCESS";
const APPLICATION_LIST_ERROR = "APPLICATION_LIST_ERROR";
const APPLICATION_LIST_DASHBOARD_SUCESS = "APPLICATION_LIST_DASHBOARD_SUCESS";
const APPLICATION_LIST_DASHBOARD_ERROR = "APPLICATION_LIST_DASHBOARD_ERROR";
const PROVINCE_SUCSESS = "PROVINCE_SUCSESS";
const PROVINCE_ERROR = "PROVINCE_ERROR";
const CITY_SUCSESS = "CITY_SUCSESS";
const CITY_ERROR = "CITY_ERROR";
const DISTRICT_SUCESS = "DISTRICT_SUCESS";
const DISTRICT_ERROR = "DISTRICT_ERROR";
const SUB_DISTRICT_SUCESS = "SUB_DISTRICT_SUCESS";
const SUB_DISTRICT_ERROR = "SUB_DISTRICT_ERROR";
const COUNTRY_SUCESS = "COUNTRY_SUCESS";
const COUNTRY_ERROR = "COUNTRY_ERROR";
const DISTRICT_SHARE_HOLDER_SUCESS = "DISTRICT_SHARE_HOLDER_SUCESS";
const DISTRICT_SHARE_HOLDER_ERROR = "DISTRICT_SHARE_HOLDER_ERROR";
const SUB_DISTRICT_SHARE_HOLDER_SUCESS = "SUB_DISTRICT_SHARE_HOLDER_SUCESS";
const SUB_DISTRICT_SHARE_HOLDER_ERROR = "SUB_DISTRICT_SHARE_HOLDER_ERROR";
const BUSINESS_INFORMATION_SUCESS = "BUSINESS_INFORMATION_SUCESS";
const BUSINESS_INFORMATION_ERROR = "BUSINESS_INFORMATION_ERROR";
const BUSINESS_INFORMATION_PROFILE_SUCESS =
  "BUSINESS_INFORMATION_PROFILE_SUCESS";
const BUSINESS_INFORMATION_PROFILE_ERROR = "BUSINESS_INFORMATION_PROFILE_ERROR";
const UPLOAD_KK_VALIDATION_SUCSESS = "UPLOAD_KK_VALIDATION_SUCSESS";
const UPLOAD_KK_VALIDATION_ERROR = "UPLOAD_KK_VALIDATION_ERROR";
const LOAN_APPLICATION_PROGRESS_SUCSESS = "LOAN_APPLICATION_PROGRESS_SUCSESS";
const LOAN_APPLICATION_PROGRESS_ERROR = "LOAN_APPLICATION_PROGRESS_ERROR";
const LOAN_LIMIT_SUCSESS = "LOAN_LIMIT_SUCSESS";
const LOAN_LIMIT_ERROR = "LOAN_LIMIT_ERROR";
const LOAN_BALANCE_SUCSESS = "LOAN_BALANCE_SUCSESS";
const LOAN_BALANCE_ERROR = "LOAN_BALANCE_ERROR";
const DOCUMENT_LIST_SUCSESS = "DOCUMENT_LIST_SUCSESS";
const DOCUMENT_LIST_ERROR = "DOCUMENT_LIST_ERROR";
const USER_PROFILE_SUCSESS = "USER_PROFILE_SUCSESS";
const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
const DOCUMENT_PRIMARY_BANK_SUCSESS = "DOCUMENT_PRIMARY_BANK_SUCSESS";
const DOCUMENT_PRIMARY_BANK_ERROR = "DOCUMENT_PRIMARY_BANK_ERROR";
const APP_GUARANTOR_UPDATE_SUCSESS = "APP_GUARANTOR_UPDATE_SUCSESS";
const APP_GUARANTOR_UPDATE_ERROR = "APP_GUARANTOR_UPDATE_ERROR";
const APP_GUARANTOR_CREATE_SUCSESS = "APP_GUARANTOR_CREATE_SUCSESS";
const APP_GUARANTOR_CREATE_ERROR = "APP_GUARANTOR_CREATE_ERROR";
const APP_GUARANTOR_CREATE_OWNER_DIRECTOR_SUCSESS = "APP_GUARANTOR_CREATE_OWNER_DIRECTOR_SUCSESS";
const APP_GUARANTOR_CREATE_OWNER_DIRECTOR_ERROR = "APP_GUARANTOR_CREATE_OWNER_DIRECTOR_ERROR";
const LOADING_SHOW = "LOADING_SHOW";
const LOADING_NO_SHOW = "LOADING_NO_SHOW";
const LIMIT_APPLICATION_SUCSESS = "LIMIT_APPLICATION_SUCSESS";
const LIMIT_APPLICATION_ERROR = "LIMIT_APPLICATION_ERROR";

const TYPE = {
  DATA_SHARE_HOLDER_LOCAL_RESET,
  LIMIT_APPLICATION_SUCSESS,
  LIMIT_APPLICATION_ERROR,
  UPLOAD_KTP_SELFIE_KTP,
  BUTTON_SHARE_HOLDER_NEW_FORM_LIMIT,
  UPLOAD_BACK_ACTION_REFRESH,
  BUTTON_BACK_NEXT_SHARE_HOLDER,
  BUTTON_BACK_SELECT_LOAN,
  BUTTON_BACK_SHARE_HOLDER,
  BUTTON_BACK_UNGGAH_DOKUMENT,
  APP_GUARANTOR_CREATE_SUCSESS,
  APP_GUARANTOR_CREATE_ERROR,
  APP_GUARANTOR_CREATE_OWNER_DIRECTOR_SUCSESS,
  APP_GUARANTOR_CREATE_OWNER_DIRECTOR_ERROR,
  APP_GUARANTOR_UPDATE_SUCSESS,
  APP_GUARANTOR_UPDATE_ERROR,
  DATA_SHARE_HOLDER_LOCAL,
  UPLOAD_KTP_VALIDATION_SHARE_HOLDER_SUCESS,
  UPLOAD_KTP_VALIDATION_SHARE_HOLDER_ERROR,
  UPLOAD_NEW_SHARE_HOLDER_SUCESS,
  UPLOAD_NEW_SHARE_HOLDER_ERROR,
  UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_SUCESS,
  UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_ERROR,
  UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_SUCESS,
  UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_ERROR,
  DISTRICT_SHARE_HOLDER_SUCESS,
  DISTRICT_SHARE_HOLDER_ERROR,
  SUB_DISTRICT_SHARE_HOLDER_SUCESS,
  SUB_DISTRICT_SHARE_HOLDER_ERROR,
  DOCUMENT_PRIMARY_BANK_SUCSESS,
  DOCUMENT_PRIMARY_BANK_ERROR,
  USER_PROFILE_SUCSESS,
  USER_PROFILE_ERROR,
  APPLICATION_LIST_DASHBOARD_SUCESS,
  APPLICATION_LIST_DASHBOARD_ERROR,
  DOCUMENT_LIST_SUCSESS,
  DOCUMENT_LIST_ERROR,
  LOAN_APPLICATION_PROGRESS_SUCSESS,
  LOAN_APPLICATION_PROGRESS_ERROR,
  LOAN_LIMIT_SUCSESS,
  LOAN_LIMIT_ERROR,
  LOAN_BALANCE_SUCSESS,
  LOAN_BALANCE_ERROR,
  DATA_BUSINESS_INFO_LOCAL,
  BUTTON_BACK_PERSONAL_INFO,
  DATA_PERSONAL_BUSINESS_INFO_LOCAL,
  DATA_OWNER_DIRECTOR_BUSINESS_INFO_LOCAL,
  DATA_BUSINESS_INFO_LOCAL_SUBMIT,
  BUTTON_BACK,
  BUTTON_BACK_BUSINESS_INFO,
  DOCUMENT_UPLOAD_SUCESS,
  DOCUMENT_UPLOAD_ERROR,
  BUSINESS_INFORMATION_SUCESS,
  BUSINESS_INFORMATION_ERROR,
  BUSINESS_INFORMATION_PROFILE_SUCESS,
  BUSINESS_INFORMATION_PROFILE_ERROR,
  PROVINCE_SUCSESS,
  PROVINCE_ERROR,
  CITY_SUCSESS,
  CITY_ERROR,
  DISTRICT_SUCESS,
  DISTRICT_ERROR,
  SUB_DISTRICT_SUCESS,
  SUB_DISTRICT_ERROR,
  COUNTRY_SUCESS,
  COUNTRY_ERROR,
  PRINCIPAL_SUCESS,
  PRINCIPAL_ERROR,
  FINANCING_SUCESS,
  FINANCING_ERROR,
  PURPOSE_SUCESS,
  PURPOSE_ERROR,
  LOAN_DETAILS_SUCESS,
  LOAN_DETAILS_ERROR,
  PUT_LOAN_DETAILS_SUCESS,
  PUT_LOAN_DETAILS_ERROR,
  CALCULATOR_LANDING_PAGE_SUCESS,
  CALCULATOR_LANDING_PAGE_ERROR,
  BUSINESS_INFO_ERROR,
  BUSINESS_INFO_SUCESS,
  PUT_BUSINESS_INFO_SUCESS,
  PUT_BUSINESS_INFO_ERROR,
  PUT_PERSONAL_INFO_SUCESS,
  PUT_PERSONAL_INFO_ERROR,
  PUT_PERSONAL_INFO_PROFILE_SUCESS,
  PUT_PERSONAL_INFO_PROFILE_ERROR,
  UPLOAD_NEW_SUCESS,
  UPLOAD_NEW_ERROR,
  UPLOAD_KTP_VALIDATION_SUCESS,
  UPLOAD_KTP_VALIDATION_ERROR,
  UPLOAD_SELFIE_KTP_VALIDATION_SUCESS,
  UPLOAD_SELFIE_KTP_VALIDATION_ERROR,
  UPLOAD_SELFIE_MATCH_KTP_VALIDATION_SUCESS,
  UPLOAD_SELFIE_MATCH_KTP_VALIDATION_ERROR,
  UPLOAD_KK_SUCESS,
  UPLOAD_KK_ERROR,
  UPLOAD_KK_VALIDATION_SUCSESS,
  UPLOAD_KK_VALIDATION_ERROR,
  SEND_DOCUMENT_REQUEST_SUCESS,
  SEND_DOCUMENT_REQUEST_ERROR,
  SEND_DOCUMENT_LOAN_REQUEST_SUCESS,
  SEND_DOCUMENT_LOAN_REQUEST_ERROR,
  RESEND_EMAIL_SUCESS,
  RESEND_EMAIL_ERROR,
  APPLICATION_LIST_SUCESS,
  APPLICATION_LIST_ERROR,
  LOADING_SHOW,
  LOADING_NO_SHOW,
};

export default TYPE;
