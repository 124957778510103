const LOAN_DETAIL = "v1/koinbisnis/loan/detail/";
const LOAN_PROGRESS = "v1/koinbisnis/loan/progress/";
const LOAN_SCHEDULE = "v1/koinbisnis/loan/schedule/";
const LOAN_LENDER = "v1/koinbisnis/loan/lender/";
const LOAN_CONTRACT = "/v1/koinbisnis/loan/contract/";

export const PATH_KOINBISNIS_LOAN = {
  LOAN_DETAIL,
  LOAN_PROGRESS,
  LOAN_SCHEDULE,
  LOAN_LENDER,
  LOAN_CONTRACT
};
