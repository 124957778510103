import styled from "styled-components";

export const BannerMainWrapper = styled.div`
  background: #ffffff;

  .main-banner {
    padding-top: 30px;
    padding-bottom: 30px;
    .main-heading {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 54px;
      line-height: 64px;
      letter-spacing: 0.41px;
      color: #2b486d;
      @media (max-width: 575.98px) {
        margin-bottom: 20px;
        font-size: 34px;
        line-height: 44px;
      }
    }
    .main-text {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.38px;
      color: #566068;
      @media (max-width: 575.98px) {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 30px;
      }
    }
    .btn-apply {
      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
    .store {
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #2d2d2d;
        margin-bottom: 7px;
      }
      .store-link {
        display: flex;
        .store-wrapper {
          width: 115px;
          &:first-child {
            margin-right: 15px;
          }
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .reg-wrapper {
      @media (max-width: 575.98px) {
        margin-bottom: 25px;
      }
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #2d2d2d;
        margin-top: 15px;
        margin-bottom: 0;
      }
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #378df4;
        i {
          font-size: 18px;
        }
      }
    }
    .main-banner-img {
      height: 100%;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
`;
