import React from "react";
import { ApplicationProgressStyle } from "../../BorrowerDashboardKoinInvoice/style";
import { List, Card } from "antd";
import currencyRupiah from "../../library/currency";

export default function ApplicationProgressLoan({
  loanApplication,
  isShowLoading,
  actionChangeShowYourLoan,
  language,
}) {
  const {
    //choose_offers,
    funding_process,
    go_to_disbursement,
    on_going,
    under_review,
  //  waiting_for_contract,
    waiting_for_document,
  } = loanApplication && loanApplication.data;

  const data = [
    {
      title: language.loanDashboard.waitingForDocument,
      value: "waiting_for_document",
      amount: loanApplication && waiting_for_document.amount,
      action: loanApplication && waiting_for_document.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-documents-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-documents.png").default,
      length: loanApplication && waiting_for_document.total,
    },
    {
      title: language.loanDashboard.underReview,
      value: "under_review",
      amount: loanApplication && under_review.amount,
      action: loanApplication && under_review.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/under-review-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/under-review.png").default,
      length: loanApplication && under_review.total,
    },
    // {
    //   title: language.loanDashboard.loanApproval,
    //   value: "choose_offer",
    //   amount: loanApplication && choose_offers.amount,
    //   action: loanApplication && choose_offers.action,
    //   imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/loan-aproval-disabled.png").default,
    //   imageActive: require("../../assets/images/dashboard-icon-koininvoice/loan-aproval.png").default,
    //   length: loanApplication && choose_offers.total,
    // },
    // {
    //   title: language.loanDashboard.waitingForContract,
    //   value: "waiting_for_contract",
    //   amount: loanApplication && waiting_for_contract.amount,
    //   action: loanApplication && waiting_for_contract.action,
    //   imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-contract-disabled.png").default,
    //   imageActive: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-contract.png").default,
    //   length: loanApplication && waiting_for_contract.total,
    // },
    {
      title: language.loanDashboard.fundingProcess,
      value: "funding_process",
      amount: loanApplication && funding_process.amount,
      action: loanApplication && funding_process.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/funding-process-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/funding-process.png").default,
      length: loanApplication && funding_process.total,
    },
    {
      title: language.loanDashboard.disbursementProcess,
      value: undefined,
      amount: loanApplication && go_to_disbursement.amount,
      action: loanApplication && go_to_disbursement.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/disbursement-process-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/disbursement-process.png").default,
      length: loanApplication && go_to_disbursement.total,
    },
    {
      title: language.loanDashboard.onGoing,
      value: "on_going",
      amount: loanApplication && on_going.amount,
      action: loanApplication && on_going.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/ongoing-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/ongoing.png").default,
      length: loanApplication && on_going.total,
    },
  ];

  return (
    <Card
      style={{ marginTop: 15 }}
      title={<strong>{language.text.applicationPogress}</strong>}
    >
      <ApplicationProgressStyle>
        <List
          itemLayout="horizontal"
          dataSource={data}
          loading={isShowLoading}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <img
                    src={
                      item.amount === 0 ? item.imageDisabled : item.imageActive
                    }
                    alt=""
                  />
                }
                title={
                  <strong
                    style={{
                      color: item.amount === 0 ? "#8E8E8E" : "#2B486D",
                      cursor:
                        item.value === undefined || item.amount === 0
                          ? ""
                          : "pointer",
                    }}
                    onClick={
                      item.value === undefined || item.amount === 0
                        ? function () {}
                        : () => actionChangeShowYourLoan(item.value, undefined)
                    }
                  >
                    {item.amount !== 0 && (
                      <div
                        style={{
                          backgroundColor: "red",
                          borderRadius: "50%",
                          height: "16px",
                          width: "16px",
                          display: "inline-block",
                          marginRight: 15,
                        }}
                      />
                    )}
                    {item.title} ({item.length})
                  </strong>
                }
                description={
                  <span
                    style={{
                      color: item.amount === 0 ? "#8E8E8E" : "#2B486D",
                      cursor:
                        item.value === undefined || item.amount === 0
                          ? ""
                          : "pointer",
                    }}
                    onClick={
                      item.value === undefined || item.amount === 0
                        ? function () {}
                        : () => actionChangeShowYourLoan(item.value, undefined)
                    }
                  >
                    {currencyRupiah(item.amount, " ")}
                  </span>
                }
              />
            </List.Item>
          )}
        />
      </ApplicationProgressStyle>
    </Card>
  );
}
