const ROOT_PATH = "v1/notification";
const STATUS_UNREAD = "v1/notification/status/unread";
const STATUS_NOTIFICATIONS = "v1/notification/status";
const STATUS = "v1//status";

export const PATH_NOTIFICATION = {
  ROOT_PATH: ROOT_PATH,
  GET_ALL : ROOT_PATH,
  STATUS_UNREAD: STATUS_UNREAD,
  STATUS: STATUS,
  STATUS_NOTIFICATIONS: STATUS_NOTIFICATIONS
};
