import styled from "styled-components";

export const StepConnectWrapper = styled.div`
  background: #f2f2f2;

  .step-title {
    text-align: center;
    letter-spacing: 0.41px;
    color: #2b486d;
    margin-bottom: 15px;
  }

  .adorement-edit {
    p {
      text-decoration: underline;
      color: #378df4;
      cursor: pointer;
    }
  }
  .box-total {
    background: #f2f2f2;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
    .box-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      color: #8e8e8e;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .cfee {
      .text-right {
        color: #c53741;
      }
    }
    .ctotal {
      color: #566068;
    }
  }
  .conf-attention-text {
    background: #F3FBFF;
    border: 1px solid #9EE4FD;
    box-sizing: border-box;
    padding: 12px;
    margin: 10px 0 25px;
    font-size: 13px;
    color: #878787;
  }
  .single-bank {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .bank-name {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #2b486d;
    }
    .btn-connect {
      border: 2px solid #2b486d;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 7px 15px;
      p {
        margin: 0;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        color: #2b486d;
      }
    }
  }
  .line {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .other-bank {
    margin: 10px 0 20px;
  }
  .connected-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    p {
      margin-bottom: 0;
    }
  }
  .bank-mutation {
    font-family: Source Sans Pro;
    .link {
      color: #378df4;
      cursor: pointer;
      text-decoration: underline;
    }
    .side-line {
      border-bottom: 2px solid #CCCCCC;
      width: 40%;
      display: block;
      position: relative;
      top:15px;
    }
    .bank-mutation-head {
      width: 100%;
      position: relative;
      display: table;
      right: 0;
      left: 0;
      text-align: center;
      margin: 0 auto;
      font-weight: 600;
    }
    .bank-mutation-text {
      margin: 15px 0;
      width: 85%;
    }
    .example-text {
      color: #8E8E8E;
      font-size: 13px;
    }
  }
  .invalid-feedback {
    display: block!important;
    margin-top: 1em!important;
  }
`;
