const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;

function getLoanOffer(id,token) {
  let path = '/v1/koinemployee/loan/available-offers/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function postChooseOffer(id,token) {
  let path = '/v1/koinemployee/loan/choose-offer';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        offer_id: id
      })
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function postRejectOffer(id,token) {
  let path = '/v1/koinemployee/loan/reject-offer';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        loan_id: id
      })
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const offerService = {
  getLoanOffer,
  postChooseOffer,
  postRejectOffer
};