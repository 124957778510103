import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import CurrencyFormatInput from "../../Components/Form/currencyFormatInput";
import { StepConfirmationWrapper } from "./StepConfirmation.styles";
import { applyLoan } from "../../stores/actions/gaji";

class StepConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    loanAmount: 0,
    disbursed: 0,
    fee: 0,
  };

  handleSubmit = () => {
    let {
      loanInfo,
      validatedEmployee
    } = this.props.gaji;

    this.props.applyLoan({
      amount: this.state.loanAmount,
      tenure: 1,
      purpose: loanInfo.purpose,
      disbursed_amount: this.state.disbursed,
      fee: this.state.fee,
      employee_id: validatedEmployee.employee_id,
      special_case: true
    });
  }

  inputChange = (value) => {
    let amount = value;
    let fee = 2/100*amount;
    let disbursed = amount - fee;
    this.setState({
      loanAmount: amount,
      disbursed: disbursed,
      fee: fee,
    })
  }

  componentDidMount() {
    let { loanInfo } = this.props.gaji;
    let fee = 2/100 * loanInfo.maxLimit;
    this.setState({ 
      loanAmount: loanInfo.maxLimit,
      fee: fee,
      disbursed: loanInfo.maxLimit - fee
    });
  }

  render() {
    const {
      gaji : { loanInfo, applyErrors },
      init: { language, flag },
      submitting,
      handleSubmit
    } = this.props;

    return (
      <StepConfirmationWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title">{language.title.confirmRequestAmount}</h2>
          
          <form>
            <div className="input-wrapper mb-4">
              <div className="input-wrapper m-installment">
                <h5 className="title">{language.label.availableLimit}</h5>
                <p className="text">
                  <NumberFormat
                  value={loanInfo.maxLimit}
                  displayType={"text"}
                  thousandSeparator={true}
                  />
                </p>
              </div>
            </div>
            <div className="input-wrapper">
              <TextField
                id="amount-type"
                name="amount"
                label={language.form.amount}
                value={this.state.loanAmount}
                variant="outlined"
                className="w-100"
                onChange={value => this.inputChange(value)}
                InputProps={{
                  inputComponent: CurrencyFormatInput
                }}
              />
            </div>
            <div className="input-wrapper">
              <div className="box-total">
                <div className="box-list camount-req">
                  <span className="text-left">{language.label.amountRequested}</span>
                  <span className="text-right">
                    <NumberFormat
                      value={this.state.loanAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
                <div className="box-list cfee">
                  <span className="text-left">{language.label.fee}</span>
                  <span className="text-right">
                    -
                    <NumberFormat
                      value={this.state.fee}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
                <div className="box-list ctotal">
                  <span className="text-left">{language.label.totalDisburse}</span>
                  <span className="text-right">
                    <span className="curency">IDR </span>
                    <NumberFormat
                      value={this.state.disbursed}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="input-wrapper">
              <button
                className={
                  "step-next btn btn-primary w-100"
                }
                disabled={submitting}
                onClick={handleSubmit(this.handleSubmit)}
              >
                {language.button.confirm}
              </button>
            </div>
            {applyErrors && (
              <p style={{ color: '#c53741' }}>
                {applyErrors[flag]}
              </p>
            )}
          </form>          
        </div>
      </StepConfirmationWrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  gaji: state.gaji,
  init: state.init
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ applyLoan }, dispatch);
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(StepConfirmation);

export default reduxForm({
  form: "StepConfirmation",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(component);
