import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getActiveLoans } from "../stores/actions/loan";
import { getFunding } from "../stores/actions/funding";
import { getClearance, getClearanceDetail, resetClearanceDetail } from "../stores/actions/clearance";
import { Table, Pagination } from "react-bootstrap";
import NumberFormat from "react-number-format";
import 'rc-steps/assets/index.css';

// import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

class TableLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isEnoughCash: true
    };
  }

  getStatusClass = status => {
    switch (status) {
      case "on_going":
        return "badge-success";
      case "disbursement_process":
        return "badge-secondary";
      case "funding_process":
        return "badge-secondary";
      case "waiting_for_contract":
        return "badge-secondary";
      case "choose_offer":
        return "badge-secondary";
      case "under_review":
        return "badge-secondary";
      default:
        return "badge-primary";
    }
  }
  
  render() {
    let {
      loan,
      init: { language },
      loanData
    } = this.props;
    let active = 2;
    let items = [];
    for (let number = 1; number <= 4; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active}>
          {number}
        </Pagination.Item>,
      );
    }
    
    return (
      <>
        <div>
          <Table responsive className="funding">
            <thead>
              <tr>
                <th></th>
                <th>{language.label.purpose}</th>
                <th>{language.label.amount}</th>
                <th>{language.label.terms}</th>
                <th>{language.label.requestDate}</th>
                <th>{language.label.status}</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loan.loans ? (
                loanData.map(data => (
                  <tr key={data.id}>
                    <td className="name">#{data.code}</td>
                    <td>{data.description}</td>
                    <td>
                      <NumberFormat
                        value={data.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator="."
                      />
                    </td>
                    <td>{data.term} {language.label.month}</td>
                    <td>{data.request_date}</td>
                    <td>
                      <span className={`badge ${this.getStatusClass(data.status)}`}>
                        {data.status}
                      </span>
                    </td>
                    <td className="share"><i className="icon-share"></i></td>
                  </tr>
                ))                
              ) : (
                <tr><td>No data found</td></tr>
              )}
            </tbody>
          </Table>
          {/* <div className="funding-pagination">
            <Pagination>
              <Pagination.First />
              <Pagination.Prev />
              {items}
              <Pagination.Ellipsis />
              <Pagination.Next />
              <Pagination.Last />
            </Pagination>
          </div> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  balance: state.balance,
  clearance: state.clearance,
  funding: state.funding,
  loan: state.loan
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getActiveLoans,
    getFunding,
    getClearance,
    getClearanceDetail,
    resetClearanceDetail
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TableLoan);