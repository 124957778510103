import React, { useEffect, useState } from "react";
import { List } from "antd";
import moment from "moment";
import currencyRupiah from "../../library/currency";

function DetailsLoan({
  dataRepayment,
  informationDetailLoanKoinBisnis,
  informationDetailLoan,
  language,
}) {
  const [vaAccount, setVaAccount] = useState([]);
  const [vaAccountValue, setVaAccountValue] = useState([]);
  const [vaSource, setVaSource] = useState([]);

  useEffect(() => {
    let tempVa = informationDetailLoan && informationDetailLoan.filter(val => val.detail_name === "virtual_accounts")[0];
    let tempSourceFund = informationDetailLoan && informationDetailLoan.filter(val => val.detail_name === "source_fund")[0];
    
    setVaAccount((tempVa && tempVa.detail_value) && JSON.parse(tempVa.detail_value));
    setVaSource(tempSourceFund);
  }, [informationDetailLoan])

  useEffect(() => {
    let tempVaAccountValue = []
    if(vaAccount && vaAccount.length > 0) {
      vaAccount.forEach(val => {
        tempVaAccountValue.push(`${val.bank_code} - ${val.account_number}`)
      })
    }

    if (vaSource && vaSource.detail_value) {
      tempVaAccountValue.unshift([`VA Source : ${vaSource.detail_value === "instamoney" ? "IM VA" : "FINTECH VA"}`])
    } else {
      tempVaAccountValue.unshift([`VA Source : -`])
    }
  
    setVaAccountValue(tempVaAccountValue);
  }, [vaAccount])
  
  const data = [
    {
      title: language.label.requestDate,
      value:
        dataRepayment &&
        moment(dataRepayment.request_date).format("DD MMMM YYYY"),
    },
    {
      title: language.loanDashboard.disbursementDate,
      value:
        informationDetailLoanKoinBisnis &&
        informationDetailLoanKoinBisnis.transfer_date
          ? moment(informationDetailLoanKoinBisnis.transfer_date).format(
              "DD MMMM YYYY"
            )
          : "-",
    },
    {
      title: language.label.totalLoanAmount,
      value: dataRepayment && currencyRupiah(dataRepayment.amount, " ", ","),
    },
    {
      title: language.loanDashboard.tenure,
      value: dataRepayment && `${dataRepayment.term} Month`,
    },
    {
      title: language.loanDashboard.riskGrade,
      value:
        informationDetailLoanKoinBisnis &&
        informationDetailLoanKoinBisnis.risk_grade
          ? informationDetailLoanKoinBisnis.risk_grade
          : "-",
    },
    {
      title: language.loanDashboard.interesetRate,
      value:
        informationDetailLoanKoinBisnis &&
        `${informationDetailLoanKoinBisnis.rate}%`,
    },
    {
      title: language.loanDashboard.numberOfLenders,
      value:
        informationDetailLoanKoinBisnis &&
        informationDetailLoanKoinBisnis.total_lender,
    },
    {
      title: language.loanDashboard.numberVA,
      value: vaAccountValue,
    },
  ];
  return (
    <div style={{ marginTop: 25 }}>
      <strong
        style={{
          display: "block",
          color: "#2B486D",
          fontSize: 25,
        }}
      >
        Loan ID : {dataRepayment && `${dataRepayment.code}`}
      </strong>
      <span>{dataRepayment && dataRepayment.description}</span>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={
          (item, x) => {
            if(item.title === 'VA Number' || item.title === 'VA Nomor') {
              return (
                <List.Item >
                  <List.Item.Meta
                    title={item.title}
                    description={
                      <strong>
                        {
                          item.value.length > 0 ? item.value.map((val, index) => {
                            return (
                              <React.Fragment key={`${index}-list-description`} >
                                <span>{val}</span>
                                <br />
                              </React.Fragment >
                            )
                          }) : "-"
                        }
                      </strong>
                    }
                  />
                </List.Item>
              )
            } else {
              return (
                <List.Item>
                  <List.Item.Meta
                    title={item.title}
                    description={<strong>{item.value}</strong>}
                  />
                </List.Item>
              )
            }
          }
        }
      />
      {/* <hr style={{ marginTop: '0px' }}/>
      <h4 className="ant-list-item-meta-title">{language.loanDashboard.numberVA}</h4>
      <div className="ant-list-item-meta-description">
        <strong>
          { vaAccount && vaAccount.map(val => val.bank_code) }
        </strong>
      </div> */}
    </div>
  );
}

export default DetailsLoan;
