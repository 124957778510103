import { apiGetWithToken } from "./index";
import { PATH_KOINBISNIS_LOAN } from "./path/koinbisnis/koinbisnis";
import { PATH_KOINVOICE_LOAN } from "./path/koinvoice/koinvoice";

async function actionGetInformationLoanDetailKoinBisnis(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINBISNIS_LOAN.LOAN_DETAIL}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function actionGetLoanProgress(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINBISNIS_LOAN.LOAN_PROGRESS}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function actionGetLoanSchedule(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINBISNIS_LOAN.LOAN_SCHEDULE}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function actionGetLoanLender(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINBISNIS_LOAN.LOAN_LENDER}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function actionGetLoanContract(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_KOINVOICE_LOAN.LOAN_CONTRACT}${props.paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

const koinbisnisServices = {
  actionGetInformationLoanDetailKoinBisnis,
  actionGetLoanProgress,
  actionGetLoanSchedule,
  actionGetLoanLender,
  actionGetLoanContract
};

export default koinbisnisServices;
