import React, { Component } from "react";
import { TestimonialsWrapper } from "./Testimonials.style";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// images
// import bwBeritasatu from "../../assets/images/testimonials/bw-beritasatu.png";
// import bwCnn from "../../assets/images/testimonials/bw-cnn.png";
// import bwKompas from "../../assets/images/testimonials/bw-kompas.png";
// import bwMarketeers from "../../assets/images/testimonials/bw-marketeers.png";
// import bwRol from "../../assets/images/testimonials/bw-rol.png";
// import bwSwa from "../../assets/images/testimonials/bw-swa.png";

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.changeImageColor = this.changeImageColor.bind(this);
    this.state = {
      colorImage: 0
    };
  }

  changeImageColor(n) {
    this.setState({ colorImage: n });
  }

  render() {
    let { colorImage } = this.state;
    return (
      <TestimonialsWrapper>
        <section className="testimonials">
          <div className="container">
            <Carousel
              autoPlay
              infiniteLoop
              showStatus={false}
              showIndicators={false}
              showArrows={false}
              axis="vertical"
              transitionTime={1000}
              selectedItem={this.state.colorImage}
              onChange={e => {
                this.setState({
                  colorImage: e
                });
                this.changeImageColor(e);
              }}
            >
              <div>
                <img
                  src={
                    colorImage === 0
                      ? `/img/testimonials/cl/swa.png`
                      : `/img/testimonials/swa.png`
                  }
                  alt="berita satu"
                />
                <q className="slide-testimonial">
                  “KoinWorks memanfaatkan model bisnis sosial yang diyakini
                  merupakan solusi ampuh untuk menjalankan misi menolong sesama
                  secara berkelanjutan.” - SWA
                </q>
              </div>
              <div>
                <img
                  src={
                    colorImage === 1
                      ? `/img/testimonials/cl/kontan.png`
                      : `/img/testimonials/kontan.png`
                  }
                  alt="marketeers"
                />
                <q className="slide-testimonial">
                  “Yang terpenting bagi KoinWorks ialah memberikan nilai tambah
                  bagi stakeholder dan membangun bisnis secara berkelanjutan
                  tanpa mengambil risiko berlebihan.” - Kontan
                </q>
              </div>
              <div>
                <img
                  src={
                    colorImage === 2
                      ? `/img/testimonials/cl/kompas.png`
                      : `/img/testimonials/kompas.png`
                  }
                  alt="republika online"
                />
                <q className="slide-testimonial">
                  “Pendanaan yang bergerak di bidang produktif seperti KoinWorks
                  lebih aman karena meminimalisir risiko gagal bayar yang kerap
                  terjadi di pendanaan konsumtif.” - Kompas
                </q>
              </div>
              <div>
                <img
                  src={
                    colorImage === 3
                      ? `/img/testimonials/cl/investordaily.png`
                      : `/img/testimonials/investordaily.png`
                  }
                  alt="republika online"
                />
                <q className="slide-testimonial">
                  “KoinWorks selalu memegang teguh prinsip responsible lending
                  dimana keamanan data dan kepercayaan para pengguna menjadi hal
                  yang paling utama bagi KoinWorks.” - Investor Daily
                </q>
              </div>
            </Carousel>
          </div>
        </section>
      </TestimonialsWrapper>
    );
  }
}

export default Testimonials;
