import React from "react";

function ThankYouSubmitUploadLimit(props) {
  return (
    <div style={{ background: "#FFFFFF", padding: 20 }}>
      <h3 style={{ textAlign: "center" }}>
        Thank you for submitting the required documents.
      </h3>
      <p style={{ textAlign: "center" }}>
        Your documents will be reviewed for verification, and we'll get back to
        you soon.
      </p>
      <img
        src={require("../../assets/images/success.png").default}
        style={{ display: "block", margin: "0 auto" }}
        alt=""
      />
    </div>
  );
}

export default ThankYouSubmitUploadLimit;
