import styled from "styled-components";

export const CongrulationsStarsLoanStyle = styled.div`
  .kw-CongrulationsStarsLoan {
    h3 {
      color: #2b486d;
      text-align: center;
    }
    p {
      color: #8e8e8e;
    }
  }
`;
