import React, { useState, useEffect } from "react";
import { Alert, Spin, Divider } from "antd";
import TextField from "@material-ui/core/TextField";
import Select from "@appgeist/react-select-material-ui";
import CurrencyFormatInput from "../../../Components/Form/currencyFormatInput";
import cookie from "react-cookies";

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

export default function PrincipalCodeLoan({
  flag,
  language,
  handleChangeCodeLoan,
  handleChangeSubmitPrincipal,
  koinvoice,
  principalCode,
  isShowLoading,
  isDisabledButton,
}) {
  let businessInformation =
    JSON.parse(localStorage.getItem("businessInformation")) !== null &&
    JSON.parse(localStorage.getItem("businessInformation")).data;
  let business_type = 
    businessInformation?.details?.business?.company_detail?.business_type?.value ||
    '';

  const userCode = cookie.load("uCode");
  const addData = JSON.parse(localStorage.getItem(`add-data-${userCode}`)) || null;
  const [loanTerm, setLoanTerm] = useState(addData ? addData.loan_term : 0);
  const [loanPurpose, setLoanPurpose] = useState(addData ? addData.loan_purpose : "");
  const [limitRequest, setLimitRequest] = useState(addData ? addData.limit_amount : 0);
  const [errLoanTerm, setErrLoanTerm] = useState(!loanTerm && true);
  const [errLoanPurpose, setErrLoanPurpose] = useState(!loanPurpose && true);
  const [errLimitRequest, setErrLimitRequest] = useState(!limitRequest && true);
  const [errLimitMessage, setErrLimitMessage] = useState(language.text.cantBeEmpty);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [borrowerName, setBorrowerName] = useState(
    addData?.borrower_name  ||
    businessInformation?.details?.business.company_detail?.president_director_name?.value ||
    businessInformation?.details?.business.company_detail?.active_persero_name?.value ||
    businessInformation?.details?.business.company_detail?.business_principal_fullname?.value ||
    businessInformation?.details?.business.company_detail?.owner_name?.value ||
    businessInformation?.fullname
  ); 
  const [errBorrowerName, setErrBorrowerName] = useState(!borrowerName && true);

  const optTerm = [
    {value: 6, label: `6 ${language.label.months}`},
    {value: 12, label: `12 ${language.label.months}`}
  ];

  const optPurpose = [
    {value: "bridging_loan", label: language.label.bridgingLoan},
    {value: "working_capital", label: language.label.workingCapital},
    {value: "expansion", label: language.label.expansion},
    {value: "other", label: language.label.other}
  ];

  useEffect(() => {
    if(isFirstTime && businessInformation) {
      setBorrowerName(
        addData?.borrower_name  ||
        businessInformation?.details?.business.company_detail?.president_director_name?.value ||
        businessInformation?.details?.business.company_detail?.active_persero_name?.value ||
        businessInformation?.details?.business.company_detail?.business_principal_fullname?.value ||
        businessInformation?.details?.business.company_detail?.owner_name?.value ||
        businessInformation?.fullname
      )

      setIsFirstTime(false);
    }
  }, [businessInformation]);

  function handleChangeTerm(val) {
    if(val) {
      setLoanTerm(val.value);
      setErrLoanTerm(false);
    } else {
      setLoanTerm(null);
    }
  }

  function handleChangePurpose(val) {
    if (val) {
      setLoanPurpose(val.value);
      setErrLoanPurpose(false);
    } else {
      setLoanPurpose("");
    }
  }

  function handleChangeLimit(value) {
    setLimitRequest(value);
    if(!value|| value === 0){
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.cantBeEmpty);
    } else if (value < 0) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.minimumLimitRequest);
    } else if (value % 100000 !== 0) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.moduloHundredThousand);
    } else if (value > 2000000000) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.maximumLimitRequest);
    } else {
      setErrLimitRequest(false);
      setErrLimitMessage("");
    }
  }

  function handleChangeBorrower(val) {
    setBorrowerName(val.target.value);
    if(val.target.value === "")
      setErrBorrowerName(true);
    else
    setErrBorrowerName(false);
  }

  function handleSubmit() {
    if (!errLoanPurpose && !errLoanTerm && !errLimitRequest) {
      localStorage.setItem(`add-data-${userCode}`, 
        JSON.stringify({
          loan_term: loanTerm,
          loan_purpose: loanPurpose,
          limit_amount: limitRequest,
          borrower_name: borrowerName
        })
      );
      handleChangeSubmitPrincipal();
    }
  }

  return (
    <React.Fragment>
      <h5>{language.loanKoinVoice.labelLoanCodePrincipal}</h5>
      <div>
        {" "}
        <TextField
          label={language.loanKoinVoice.principalCode}
          variant="outlined"
          name="principal-code"
          onChange={handleChangeCodeLoan}
          type="text"
          style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
          id="principal-code"
        />
      </div>
      {koinvoice.isShowPrincipalAlert ? (
        <React.Fragment>
          <div>
            <TextField
              disabled
              label={language.loanKoinVoice.principalName}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={principalCode && principalCode.principal_name}
              name="principal-name"
              variant="outlined"
              id="principal-name"
            />
          </div>
          <div>
            <TextField
              disabled
              label={"Principal PIC JOB POSITION"}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={principalCode && principalCode.principal_contact_position}
              name="principal-contact-position"
              variant="outlined"
              id="principal-contact-position"
            />
          </div>
          <div>
            <TextField
              disabled
              label={language.loanFormKoinvoice.contactPersonDetails}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={
                principalCode && principalCode.principal_contact_name
              }
              name="principal-contact-name"
              variant="outlined"
              id="principal-contact-name"
            />
          </div>
          <div>
            <TextField
              disabled
              label={language.loanFormKoinvoice.phoneNumberDetails}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={
                principalCode && principalCode.principal_contact_phone
              }
              name="principal-contact-phone"
              variant="outlined"
              id="principal-contact-phone"
            />
          </div>
          <div>
            <TextField
              disabled
              label={language.loanFormKoinvoice.emailAddressDetails}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={
                principalCode && principalCode.principal_contact_email
              }
              name="principal-contact-email"
              variant="outlined"
              id="principal-contact-email"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              disabled={business_type === 'home_business' || business_type === 'individual'}
              label={language.form.borrowerName}
              value={businessInformation && borrowerName}
              onChange={handleChangeBorrower}
              variant="outlined"
              type="text"
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
              error={!borrowerName && errBorrowerName}
              helperText={errBorrowerName && language.text.cantBeEmpty}
              id="borrower-name"
              name="borrower-name"
            />
          </div>
          <Divider orientation="left">{language.label.limitInfo}</Divider>
          <div style={{ marginTop: 20 }}>
            <TextField
              label={language.form.limitRequest}
              value={limitRequest}
              variant="outlined"
              type="text"
              onChange={handleChangeLimit}
              error={!limitRequest || errLimitRequest}
              helperText={errLimitRequest ? errLimitMessage : ""}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
              InputProps={{
                inputComponent: CurrencyFormatInput
              }}
              id="limit-amount"
              name="limit-amount"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              id="loan-term"
              name="loan-term"
              label={language.form.loanTermLms}
              placeholder={language.form.chooseTermLms}
              value={
                optTerm.filter(option => 
                   option.value === loanTerm)
              }
              options={optTerm}
              onChange={handleChangeTerm}
              error={!loanTerm && errLoanTerm}
              helperText={errLoanTerm ? "Cannot be empty!" : ""}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              id="loan-purpose"
              name="loan-purpose"
              label={language.form.loanPurpose}
              placeholder={language.form.chooseLoanPurpose}
              value={
                optPurpose.filter(option => 
                   option.value === loanPurpose)
              }
              options={optPurpose}
              onChange={handleChangePurpose}
              error={!loanPurpose && errLoanPurpose}
              helperText={errLoanPurpose ? "Cannot be empty!" : ""}
            />
          </div> 
        </React.Fragment>
      ) : (
        koinvoice.principalError && (
          <Alert
            message={koinvoice.principalError.data.message[flag]}
            style={{ width: isMobile() ? "40%" : "100%", marginTop: 20 }}
            type="error"
          />
        )
      )}
      <Spin spinning={isShowLoading}>
        <button
          className="btn btn-primary w-100"
          style={{ marginTop: 20 }}
          onClick={handleSubmit}
          disabled={koinvoice.isEnabledButton}
          id="btn-next-loan-purpose"
        >
          {language.button.next}
        </button>
      </Spin>
    </React.Fragment>
  );
}
