const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;

function getSchedule(page, sort, token) {
  let path = '/v1/koinemployee/schedule/list';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + '?page=' + page + '&sort_type=' + sort, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoanSchedule(id, token) {
  let path = '/v1/koinemployee/loan/schedule/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + '?page=' + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function checkPaymentBalance(id, token) {
  let path = '/v1/koinemployee/schedule/payment/check/';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };

  return fetch(BASE_URL + path + id, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function requestPayment(loanId, scheduleId, amount, status, token) {
  let path = '/v1/koinemployee/schedule/payment/';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        payment_amount: amount,
        early_installment_repayment: status,
        schedule_id: scheduleId
      })
  };

  return fetch(BASE_URL + path + loanId, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const scheduleService = {
  getSchedule,
  getLoanSchedule,
  checkPaymentBalance,
  requestPayment
};