import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getActionProfileUsers,
  putActionPrimaryBank,
} from "../stores/actions/koinvoiceLoanDashboard";
import { Card, Form, Input, Button, Spin, Select } from "antd";
import { Formik } from "formik";
import { schema } from "./schema";
import { DisbursementBankAccountStyle } from "./style";
import DrawerAllert from "./DrawerAllert";
import koinvoiceServices from "../services/koinvoice";
import ChooseOfferKoinInvoice from "../ChooseOfferKoinInvoice";

const { Option } = Select;

function DisbursementBankAccount({
  getActionProfileUsers,
  putActionPrimaryBank,
  actionChangeShowDocumentList,
  actionChangeShowYourLoan,
  bankAccount,
  dataRepayment,
  koinvoiceDashboard: { primaryBank, usePorfile },
}) {
  const [isShowLoading, setIsShowloading] = useState(false);
  const [isShowSucsessSubmitBank, setIsShowSucsessSubmitBank] = useState(false);
  const [disbursementBank, setDisbursementBank] = useState({});
  const [isShowChooseOffer, setIsShowChooseOffer] = useState(false);
  useEffect(() => {
    getActionProfileUsers();
    getDisbursementBank(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      (dataRepayment &&
        dataRepayment.description !== "Invoice Financing") ||
      (dataRepayment && dataRepayment.description !== "invoice_financing")
    ) {
      setIsShowChooseOffer(true);
    }
    if (
      (dataRepayment &&
        dataRepayment.description === "Invoice Financing") ||
      (dataRepayment && dataRepayment.description === "invoice_financing")
    ) {
      setIsShowChooseOffer(false);
    }
  }, [dataRepayment]);

  function handleSubmit(value) {
    const { account_number, branch, code, name, id } = value;
    const params = {
      id,
      name,
      account_under_name: name,
      account_number: account_number.toString(),
      branch,
      code,
    };
    putActionPrimaryBank(
      params,
      setIsShowloading,
      actionChangeShowHideSucsess,
      setIsShowChooseOffer
    );
  }

  function handleChangeActionBank(value, option, setFieldValue) {
    setFieldValue("code", value);
  }

  async function getDisbursementBank() {
    let response = await koinvoiceServices.actionGetDisbursementBank({});
    if (response.status === 200) {
      setDisbursementBank(response.data);
    } else {
      setDisbursementBank({});
    }
  }

  function actionChangeShowHideSucsess() {
    setIsShowSucsessSubmitBank(!isShowSucsessSubmitBank);
  }

  if (
    primaryBank.data &&
    primaryBank.status === 200 &&
    isShowSucsessSubmitBank === true
  ) {
    setTimeout(() => {
      actionChangeShowHideSucsess();
      // actionChangeShowDocumentList();
      // actionChangeShowYourLoan();
    }, 1500);
  }
  const options = bankAccount.map((dis) => (
    <Option key={dis.key}>{dis.name}</Option>
  ));

  let accountNumber = disbursementBank.account_number;

  return (
    <Card>
      {" "}
      <DisbursementBankAccountStyle>
        <Button
          style={{ marginBottom: 20 }}
          size="large"
          onClick={() => actionChangeShowDocumentList()}
        >
          Back
        </Button>
        <div className="kw-disbursementBank-wrapper">
          {isShowChooseOffer ? (
            <ChooseOfferKoinInvoice
              dataRepayment={dataRepayment}
              actionChangeShowHideSucsess={actionChangeShowHideSucsess}
              actionChangeShowDocumentList={actionChangeShowDocumentList}
              actionChangeShowYourLoan={actionChangeShowYourLoan}
            />
          ) : (
            ((dataRepayment &&
              dataRepayment.description === "Invoice Financing") ||
              (dataRepayment &&
                dataRepayment.description === "invoice_financing")) && (
              <React.Fragment>
                <h3>Disbursement Bank Account</h3>
                <p>
                  Please make sure the info you enter is correct to fasten
                  disbursement process.
                </p>
                <Formik
                  enableReinitialize
                  initialValues={{
                    id:
                      disbursementBank && disbursementBank.id
                        ? disbursementBank.id
                        : 0,
                    name:
                      (usePorfile.data && usePorfile.data.data.fullname) ||
                      (disbursementBank && disbursementBank.name),
                    account_under_name:
                      (usePorfile.data && usePorfile.data.data.fullname) ||
                      (disbursementBank && disbursementBank.account_under_name),
                    code: disbursementBank && disbursementBank.code,
                    branch: disbursementBank && disbursementBank.branch,
                    account_number:
                      disbursementBank.account_number &&
                      accountNumber.toString(),
                  }}
                  validationSchema={schema}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, resetForm);
                  }}
                  validateOnChange={false}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {" "}
                      <Form.Item
                        label={"OWNER"}
                        validateStatus={errors.name && "error"}
                        help={errors.name}
                        onBlur={handleBlur}
                      >
                        <Input
                          size="large"
                          disabled={
                            (usePorfile.data &&
                              usePorfile.data.data.fullname) ||
                            (disbursementBank &&
                              disbursementBank.account_under_name)
                              ? true
                              : false
                          }
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                        />
                      </Form.Item>
                      <Form.Item
                        label={"BANK"}
                        validateStatus={errors.code && "error"}
                        help={errors.code}
                        onBlur={handleBlur}
                      >
                        <Select
                          showSearch
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="BANK BCA"
                          value={values.code}
                          onChange={(value, option) =>
                            handleChangeActionBank(value, option, setFieldValue)
                          }
                        >
                          {options}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={"BANK BRANCH"}
                        validateStatus={errors.branch && "error"}
                        help={errors.branch}
                        onBlur={handleBlur}
                      >
                        <Input
                          placeholder="BCA KCP Kelapa Gading"
                          size="large"
                          name="branch"
                          onChange={handleChange}
                          value={values.branch}
                        />
                      </Form.Item>
                      <Form.Item
                        label={"ACCOUNT NUMBER"}
                        validateStatus={errors.account_number && "error"}
                        help={errors.account_number}
                        onBlur={handleBlur}
                      >
                        <Input
                          size="large"
                          type="number"
                          name="account_number"
                          placeholder="12345678910"
                          onChange={handleChange}
                          value={values.account_number}
                        />
                      </Form.Item>
                      <Spin spinning={isShowLoading}>
                        <Button
                          htmlType="submit"
                          className="kw-button"
                          size="large"
                          type="primary"
                        >
                          CONTINUE
                        </Button>
                      </Spin>
                    </form>
                  )}
                </Formik>
              </React.Fragment>
            )
          )}
        </div>
        <DrawerAllert
          visible={isShowSucsessSubmitBank}
          image={require("../assets/images/dashboard/disbursesubmit.png").default}
          onClose={actionChangeShowHideSucsess}
          iShowButtonClose={true}
          labelText={"Successfully submitted"}
          paragraphText={"Redirecting... "}
        />
      </DisbursementBankAccountStyle>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionProfileUsers,
      putActionPrimaryBank,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisbursementBankAccount);
