import { balanceService } from "../../services/balance";

const requestLoading = () => {
  return { type: "GET_BALANCE_LOADING" }
}

const getBalanceSuccess = data => {
  return {
    type: "GET_BALANCE_SUCCESS",
    payload: data
  }
}

const getBalanceFailed = errors => {
  return {
    type: "GET_BALANCE_FAILED",
    payload: errors
  }
}

export const getBalance = (token) => (dispatch) => {
  dispatch(requestLoading());
  balanceService.getBalance(token)
    .then(res => {
      dispatch(getBalanceSuccess(res.data));
    },
    error => {
      dispatch(getBalanceFailed(error));
  });
}