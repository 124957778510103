import React from "react";
import { Drawer, Button } from "antd";

function DrawerConfirmationReject({
  visible,
  onClose,
  actionChangeShowHideSucsess,
  actionChangeShowYourLoan,
  actionChangeShowDocumentList,
  actionChangeShowHideConfirmationApprovedReject
}) {
  // function actionChangeAllClose() {
  //   actionChangeShowHideSucsess();
  //   actionChangeShowYourLoan();
  //   actionChangeShowDocumentList();
  // }

  return (
    <Drawer
      title=" "
      placement={"bottom"}
      height={444}
      closable={false}
      onClose={onClose}
      visible={visible}
      key={"bottom"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../../assets/images/dashboard/offerreject.png").default}
          alt=""
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            height: 120,
          }}
        />
        <h4
          style={{
            color: "#2B486D",
            textAlign: "center",
            fontWeight: 555,
            fontSize: 28,
          }}
        >
          {"Reject Offer?"}
        </h4>
        <p style={{ textAlign: "center", color: "#566068" }}>
          {"If you reject the offer, you must start over the process. Are you sure to reject the limit offer?"}
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <React.Fragment>
            <Button
              size="large"
              style={{
                backgroundColor: "#2b476c",
                color: "#FFFFFF",
                borderColor: "#2b476c",
                width: 350,
                marginRight: 15,
              }}
              onClick={() => onClose()}
            >
              CANCEL
            </Button>
            <Button
              size="large"
              style={{
                backgroundColor: "#EFEFEF",
                color: "#C53741",
                borderColor: "#EFEFEF",
                width: 350,
              }}
              onClick={() => actionChangeShowHideConfirmationApprovedReject()}
            >
              YES, REJECT
            </Button>
          </React.Fragment>
        </div>
      </div>
    </Drawer>
  );
}

export default DrawerConfirmationReject;
