import styled from "styled-components";
import bgSolution from "../../../assets/images/bg2.png";

export const SolutionWrapper = styled.div`
  background: #ffffff;

  .solution {
    /* padding-top: 130px; */
    padding-bottom: 130px;
    background-color: #f8fcff;
    background-image: url(${bgSolution});
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 575.98px) {
      padding-top: 30px;
      padding-bottom: 30px;
      background-image: none;
    }
    .heading {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 54px;
      line-height: 64px;
      letter-spacing: 0.41px;
      color: #071e35;
      margin-bottom: 80px;
      @media (max-width: 575.98px) {
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 40px;
      }
    }
    .content {
      @media (max-width: 575.98px) {
        display: flex;
      }
      .icon-wrapper {
        height: 75px;
        @media (max-width: 575.98px) {
          margin-right: 15px;
        }
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .text-wrapper {
        .heading {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: 0.38px;
          color: #2b486d;
          margin-top: 15px;
          margin-bottom: 10px;
        }
        .text {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.41px;
          color: #566068;
        }
      }
    }
    .reg-wrapper {
      text-align: center;
      margin-top: 25px;
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #2d2d2d;
        margin-top: 15px;
        margin-bottom: 0;
      }
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #378df4;
        i {
          font-size: 18px;
        }
      }
    }
  }
`;
