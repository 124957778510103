import CryptoJS from 'crypto-js';

const key = 'Koinworks!2021!secret!Backoffice';
const iv = 'Secret#2021!kinv';
 
export const Aes256CbcDecrypt = (text, id) => {
  try {
    let plainText = CryptoJS.AES.decrypt(
      text,
      CryptoJS.enc.Utf8.parse(key), 
      { iv: CryptoJS.enc.Utf8.parse(iv) }
    );
    
    let decrypted = JSON.parse(plainText.toString(CryptoJS.enc.Utf8));
    
    if (id !== decrypted.userId.toString())
      return false;
    else 
      return decrypted;

  } catch (error) {
    return false;
  }

}