import TYPE from "../actions/type";
import cookie from "react-cookies";

const initialState = {
  limitApplication: undefined,
  limitApplicationError: undefined
};

const invoiceLimitReducer  = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.LIMIT_APPLICATION_SUCSESS:
      return {
        ...state,
        limitApplication: action.payload,
        limitApplicationError:undefined,
        displayPage:cookie.save("displayPage", 2, {
          //path: "/",
          maxAge: 3600,
        })
      };
    case TYPE.LIMIT_APPLICATION_ERROR:
      return {
        ...state,
        limitApplicationError: action.payload,
        limitApplication:undefined,
        displayPage:cookie.save("displayPage", 1)
      };
    default:
      return state;
  }
}

export default invoiceLimitReducer;
