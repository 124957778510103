import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";

import LDNolender from "../../../assets/images/dashboard/nolender.png"


class LDList extends Component {
  render() {
    let { init: {language}, loan } = this.props;

    return (
      <>
        <div className="ldcomp">
          <div className="heading">
            <div className="title">{language.title.lenderList}</div>
            {/* <div className="text"><strong>Total Lenders: 20</strong></div> */}
          </div>

          {/* notfound */}
          {loan.isGetLoanLenderFailed && (
            <div className="ldlnotfound">
              <figure className="modal-img mb-4">
                <img src={LDNolender} alt="success" />
              </figure>
              <h3 className="title">{language.title.noRepaymentDue}</h3>
              <p className="text w-75">Your repayment will show up here.</p>
            </div>
          )}

          {loan.loanLender && (
            <Table responsive>
            <thead>
              <tr>
                <th>{language.label.date}</th>
                <th>{language.label.lender} ID</th>
                <th>{language.label.amount}</th>
              </tr>
            </thead>
            <tbody>
              {loan.loanLender.map(data =>(
                <tr key={data.lender_id}>
                  <td>
                    { new Date(data.date).toLocaleDateString("ID-id",
                      { month: 'short', day: 'numeric' })}
                  </td>
                  <td>{data.lender_id}</td>
                  <td>
                    <NumberFormat
                      value={data.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator="."
                      decimalScale={3}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          )}

        </div>
      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  loan: state.loan
});

export default connect(mapStateToProps, null)(LDList);