import { clearanceService } from "../../services/clearance";

const getClearanceSuccess = data => {
  return {
    type: "GET_CLEARANCE_SUCCESS",
    payload: data
  }
}

const getClearanceFailed = errors => {
  return { type: "GET_CLEARANCE_FAILED", payload: errors }
}

export const getClearance = (token) => (dispatch) => {
  clearanceService.getClearance(token)
    .then(res => {
      dispatch(getClearanceSuccess(res.data));
    },
    error => {
      dispatch(getClearanceFailed(error));
  });
}

const getDetailSuccess = data => {
  return {
    type: "GET_DETAIL_SUCCESS",
    payload: data
  }
}

const getDetailFailed = errors => {
  return {
    type: "GET_DETAIL_FAILED",
    payload: errors
  }
}

const getDetailLoading = () => {
  return { type: "GET_DETAIL_LOADING" }
}

export const resetClearanceDetail = () => {
  return { type: "RESET_CLEARANCE_DETAIL" }
}

export const getClearanceDetail = (id,token) => (dispatch) => {
  dispatch(getDetailLoading());
  clearanceService.getClearanceDetail(id,token)
    .then(res => {
      dispatch(getDetailSuccess(res.data));
    },
    error => {
      dispatch(getDetailFailed(error));
  });
}

export const requestClearance = (ids,token) => (dispatch) => {
  clearanceService.requestClearance(ids,token)
    .then(res => {
      if (res.data && res.data.enough_cash === true){
        dispatch(requestClearancePayment(ids, res.data.clearance_amount, token))
      } else {
        dispatch(notEnoughBalance(res.data));
      }
    },
    error => {
      dispatch(requestClearanceFailed(error));
  });
}

export const requestClearancePayment = (ids,amount, token) => (dispatch) => {
  clearanceService.requestPayment(ids, amount, token)
    .then(res => {
      if (res.status === 200){
        dispatch(clearancePaymentSuccess())
      } else {
        dispatch(clearancePaymentFailed(res.message))
      }
    },
    error => {
      dispatch(clearancePaymentFailed(error));
  });
}

const notEnoughBalance = data => {
  return { type: "NOT_ENOUGH_BALANCE", payload: data.clearance_amount }
}

const requestClearanceFailed = errors => {
  return { type: "REQUEST_CLEARANCE_FAILED", payload: errors }
}

const clearancePaymentSuccess = () => {
  return { type: "REQUEST_PAYMENT_SUCCESS" }
}

const clearancePaymentFailed = errors => {
  return { type: "REQUEST_PAYMENT_FAILED", payload: errors }
}