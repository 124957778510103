const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;

function getRequiredDocument(token, status) {
  let path = '/v1/users/document/required?product=koin_employee';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };
  return fetch(BASE_URL + path + status, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getApprovedDocument(token) {
  let path = '/v1/users/document/required?product=koin_employee&status=approved';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };
  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function getLoanContract(token) {
  let path = '/v1/koinemployee/document/contract';
  const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
  };
  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const docService = {
  getRequiredDocument,
  getApprovedDocument,
  getLoanContract
};