import React from "react";
import { Drawer, Button } from "antd";

function DrawerConfirmationDelete({
  visible,
  onClose,
  actionChangeDeleteList,
  infromationEdit,
}) {
  return (
    <Drawer
      title=" "
      placement={"bottom"}
      height={444}
      closable={false}
      onClose={onClose}
      visible={visible}
      key={"bottom"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../../../../assets/images/dashboard/offerreject.png").default}
          alt=""
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            height: 120,
          }}
        />
        <h4
          style={{
            color: "#2B486D",
            textAlign: "center",
            fontWeight: 555,
            fontSize: 28,
          }}
        >
          Delete Shareholder?
        </h4>
        <p style={{ textAlign: "center", color: "#566068" }}>
          You are going to delete this shareholder. Once the information has
          been deleted, it cannot be retrieved again. Are you sure you want to
          delete?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="large"
            style={{
              backgroundColor: "#2b476c",
              color: "#FFFFFF",
              borderColor: "#2b476c",
              width: 350,
              marginRight: 15,
            }}
            onClick={() => onClose()}
          >
            CANCEL
          </Button>
          <Button
            size="large"
            style={{
              backgroundColor: "#EFEFEF",
              color: "#C53741",
              borderColor: "#EFEFEF",
              width: 350,
            }}
            onClick={() =>
              actionChangeDeleteList(infromationEdit.user_sibling_id)
            }
          >
            DELETE
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default DrawerConfirmationDelete;
