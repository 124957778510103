import React, {useEffect, useState} from 'react';
import {Button, Card, Form, message } from "antd";
import ReactCodeInput from "react-code-input";
import './style.css';
import Countdown, { zeroPad } from "react-countdown";
import {serviceRegisterOtp, serviceWithoutToken} from "../../services/httpClient";
import { connect } from "react-redux";

const kwButtonStyle = {
  backgroundColor: '#2b476c',
  borderColor: '#2b476c',
  color: '#ffffff',
  width: '100%'
}

const disabledCodeInput = {
  className: "reactCodeInput",
  inputStyle: {
    fontFamily: "system-ui",
    margin: "4px",
    MozAppearance: "textfield",
    width: "35px",
    borderRadius: "3px",
    fontSize: "14px",
    height: "45px",
    paddingLeft: "12px",
    color: "black",
    border: "1px solid black",
  },
  inputStyleInvalid: {
    fontFamily: "system-ui",
    margin: "4px",
    MozAppearance: "textfield",
    width: "15px",
    borderRadius: "3px",
    fontSize: "14px",
    height: "26px",
    paddingLeft: "12px",
    backgroundColor: "black",
    color: "red",
    border: "1px solid red",
  },
};

const OtpValidator = ({init: { language, flag }, prevPage, nextPage }) => {
  const { email } = JSON.parse(localStorage.getItem("dataRegister"));
  const [otp, setOtp] = useState("");
  const [count, setCount] = useState(Date.now() + (60 * 1000));
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const countdownDetail = ({ seconds, minutes, completed }) => {
    return (
      <Button
        type={completed ? "ghost" : "text"}
        disabled={!completed}
        onClick={() => prevPage()}
      >
        Resend OTP {!completed && `in ${zeroPad(minutes)}:${zeroPad(seconds)}`}
      </Button>
    );
  }

  const handleChange = (value) => {
    setOtp(value);
  }

  const handleSubmit = () => {
    setLoading(true);
    serviceWithoutToken().post("v1/users/confirmotp", {
      email: email,
      token: otp
    })
      .then(() => {
        message.success('OTP Success');
        nextPage();
        setLoading(false);
      })
      .catch(({ response: { data } }) => {
        message.error(data.message[flag]);
        setLoading(false);
      });
  }

  useEffect(() => {
    serviceRegisterOtp().post("v1/users/sendotp", {
      email: email,
      otp_choice: 'email'
    }).catch(({ response: { data } }) => {
      setErrorMessage(data.message[flag]);
      setVisible(false);
    });
  }, []);

  return (
    <div className="page-content-wrapper">
      <Card style={{ width: 500 }}>
        <Button onClick={prevPage}>{language.label.backLabel}</Button>
        <div className="card-body register-body">
          <h2 className="register-title mb-2">
            {language.label.enterOtpCode}
          </h2>
          <div style={{ textAlign: 'center' }}>
            {language.label.firstSentenceOtp + email + language.label.secondSentenceOtp}
          </div>
          <Form>
            <Form.Item style={{ textAlign: "center" }}>
              <ReactCodeInput
                {...disabledCodeInput}
                fields={6}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              {
                visible ? (
                  <Countdown
                    date={count}
                    renderer={countdownDetail}
                    zeroPadTime={2}
                  />
                ) : <p style={{ color: 'red', fontSize: '14px' }}>{errorMessage}</p>
              }
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={loading}
                style={kwButtonStyle}
                block
              >{language.button.submit}</Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
    init: state.init,
});

export default connect(mapStateToProps)(OtpValidator);