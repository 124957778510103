import styled from "styled-components";
import calcBG from "../../../assets/images/calcbg.png";
import calcBGInv from "../../../assets/images/calcbg-inv.png";

export const CalculatorWrapper = styled.div`
  background: #ffffff;

  .calculator {
    background: #f8fcff;
    padding-top: 130px;
    padding-bottom: 130px;
    @media (max-width: 575.98px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .heading {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 54px;
      line-height: 64px;
      text-align: center;
      letter-spacing: 0.41px;
      color: #2b486d;
      margin-bottom: 60px;
      @media (max-width: 575.98px) {
        margin-bottom: 20px;
        font-size: 34px;
        line-height: 44px;
        text-align: left;
      }
    }
    .card {
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
      border-radius: 3px;

      .card-title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 41px;
        letter-spacing: 0.41px;
        @media (max-width: 575.98px) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      .card-text {
        width: 325px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        span {
          color: #378df4;
          cursor: pointer;
        }
      }
      .btn-simulate {
        padding: 10px 25px;
        border-radius: 3px;
      }
      &.card-adv {
        background-image: url(${calcBG});
        background-repeat: no-repeat;
        background-position: bottom right;
        @media (max-width: 575.98px) {
          margin-bottom: 15px;
          background-size: 120px;
        }
        .card-title {
          color: #2b486d;
        }
        .card-text {
          color: #2b486d;
        }
        .btn-simulate {
          background: #378df4;
          color: #ffffff;
        }
        .text-right {
          /* color: #378DF4; */
          align-self: flex-end;
        }
      }
      &.card-reg {
        background-image: url(${calcBGInv});
        background-repeat: no-repeat;
        background-position: bottom right;
        background-color: #2b486d;
        @media (max-width: 575.98px) {
          background-size: 120px;
        }
        .card-title {
          color: #ffffff;
        }
        .card-text {
          color: #ffffff;
        }
        .btn-simulate {
          background: #ffffff;
          color: #071e35;
        }
      }
    }
    .accordion {
      .btn-apply {
        width: 100%;
        background-color: #378df4;
        color: #ffffff;
      }
    }
    .collapse,
    .collapsing {
      margin-top: 25px;
      .MuiFormControl-root {
        background-color: #fff;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background: #fff;
      }
    }
  }
  .box-list {
    .text-left {
      .label{
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: rgba(142, 142, 142, 0.85);
      }
      .value {
        color: #378DF4;
      }
      .value-black {
        color: #566068;
      }
    }
    .text-right {
      .end-text {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: rgba(142, 142, 142, 0.85);
      }
    }
  }
`;
