import React, { useEffect, useRef, useState } from "react";
import { DashboardKoinInvoiceStyle } from "../../BorrowerDashboardKoinInvoice/style";
import DashboardLayoutKoinInvoice from "../../Layout/DashboardLayoutKoinInvoice";
import { ButtonMenuKoinInvoice } from "../../Components/ButtonMenuKoinInvoice.jsx";
import {
  actionButtonBackUnggahDokument,
  getApplicationList,
  businessInformationKoinvoice,
} from "../../stores/actions/koinvoiceLoan";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import YourLoan from "../../YourLoan";
import DocumentListApplyLoan from "../../DocumentList/DocumentListApplyLoan";
import DisbursementBankAccount from "../../DisbursementBankAccount";
import history from "../../util/history";
import bankAccount from "./bank.json";
import WaitingForContract from "../../WaitingForContract";
import DetailInformationLoan from "../../DetailInformationLoan";
import BorrowerDashboardKoinInvoiceLoan from "../../BorrowerDashboardKoinInvoice/BorrowerDashboardKoinInvoiceLoan";
import koinvoiceServices from "../../services/koinvoice";
import moment from "moment";
import TermsConditionsLoan from "../../Components/TermsConditionsLoan";
import removeLocalStorageAndCookieLimit from "./RemoveLocalStorageAndCookieLimit";
import { getAplicationProsess } from "../../stores/actions/koinvoiceLoanDashboard";

function DashboardKoinInvoiceApplyLoan({
  init: { language },
  getApplicationList,
  koinvoiceDashboard: { loanApplication },
  koinvoice:{applicationList},
  getAplicationProsess,
  businessInformationKoinvoice,
  actionButtonBackUnggahDokument,
}) {
  const [isShowYourLoanDetails, setIsShowYourLoanDetails] = useState(false);
  const [isShowDocumentList, setIsShowDocumentList] = useState(false);
  const [checkExpiredDate, setCheckExpiredDate] = useState(false);
  const [isShowTermConditionExpDate, setIsShowTermConditionExpDate] = useState(
    false
  );
  const [dataRepayment, setDataRepayment] = useState({});
  const [documentLoanType, setDocumentLoanType] = useState("");
  const [detailInformationLoans, setDetailInformationLoans] = useState();
  const [creditUclLimit, setCreditUclLimit] = useState({});
  const [borrowerInformation, setBorrowerInformation] = useState({});
  const [isShowListYourLoan, setIsShowListYourLoan] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const mounted = useRef();

  useEffect(() => {
    getApplicationList();
    actionButtonBackUnggahDokument();
    businessInformationKoinvoice();
    getAplicationProsess();
    koinvoiceServices.actionGetCreditLimitLoan({}).then((res) => {
      // console.log("actionGetCreditLimitLoan", res);
      if (res.status === 200) {
        setCreditUclLimit(res.data);
        if (res.data.status !== "approved") {
          history.push("/dashboard-limit-application");
        }
      }
    });
    koinvoiceServices.actionGetBorrowerInformation({}).then((res) => {
      if (res.status === 200) {
        setBorrowerInformation(res.data);
      }
    });
    removeLocalStorageAndCookieLimit(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (applicationList?.status === 200) {
        if(applicationListCheck(applicationList)) {
          history.push("/dashboard-loan-application-list");
        }
      }
    }
  },[applicationList]);

  const applicationListCheck = target => {
    let length = target.data.length;

    for(let x = 0; x < length; x++) {
      if(target.data[x].status !== "paid_off") {
        return true;
      }
    }

    return false;
  }

  const actionChangeShowYourLoan = (value, viewRequestedLoans) => {
    setDocumentLoanType(value);
    setIsShowListYourLoan(viewRequestedLoans);
    setIsShowYourLoanDetails(!isShowYourLoanDetails);
  };

  const actionChangeShowDocumentList = (item, information) => {
    setDetailInformationLoans(information);
    setDataRepayment(item);
    information === true && setDocumentLoanType(item && item.status);
    setIsShowDocumentList(!isShowDocumentList);
  };

  const actionTermConditionExpDate = (value) => {
    setIsShowTermConditionExpDate(!isShowTermConditionExpDate);
    setExpiredDate(value);
  };

  useEffect(() => {
    if (creditUclLimit.expired_at) {
      let trueFalseExpired = false;
      let expiredDateMonthYear = moment(creditUclLimit.expired_at).format(
        "DD-MM-YYYY"
      );
      let expiredValidate = expiredDateMonthYear.split(" ");
      let expiredValidateSplit = expiredValidate[0].split("-");
      let dateMonthNowYear = moment().format("DD-MM-YYYY");
      let dateNowValidate = dateMonthNowYear.split(" ");
      let dateNowValidateSplit = dateNowValidate[0].split("-");
      if (
        dateNowValidateSplit[2] < expiredValidateSplit[2] &&
        dateNowValidateSplit[1] < expiredValidateSplit[1] &&
        dateNowValidateSplit[0] < expiredValidateSplit[0]
      ) {
        trueFalseExpired = false;
      } else {
        trueFalseExpired = true;
      }
      setCheckExpiredDate(trueFalseExpired);
    }
  }, [creditUclLimit]);

  return (
    <DashboardKoinInvoiceStyle>
      <div className="kw-dashboardKinInvoiceWrapper">
        <DashboardLayoutKoinInvoice>
          <React.Fragment>
            {isShowYourLoanDetails === false ? (
              <React.Fragment>
                <BorrowerDashboardKoinInvoiceLoan
                  language={language}
                  expiredDate={expiredDate}
                  creditUclLimit={creditUclLimit}
                  loanApplication={loanApplication}
                  actionChangeShowYourLoan={actionChangeShowYourLoan}
                />
                <ButtonMenuKoinInvoice
                  condition={true}
                  setIsShowTermConditionExpDate={setIsShowTermConditionExpDate}
                  creditLimitUcl={creditUclLimit}
                />
              </React.Fragment>
            ) : isShowDocumentList === false ? (
              <React.Fragment>
                <YourLoan
                  actionChangeShowYourLoan={actionChangeShowYourLoan}
                  language={language}
                  documentLoanType={documentLoanType}
                  isShowListYourLoan={isShowListYourLoan}
                  actionChangeShowDocumentList={actionChangeShowDocumentList}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {detailInformationLoans === "details-loans" && (
                  <DetailInformationLoan
                    dataRepayment={dataRepayment}
                    language={language}
                    actionChangeShowDocumentList={actionChangeShowDocumentList}
                  />
                )}
                {detailInformationLoans === true &&
                  (documentLoanType === "waiting_for_document" ||
                    documentLoanType === "under_review") && (
                    <DocumentListApplyLoan
                      isShow={true}
                      language={language}
                      dataRepayment={dataRepayment}
                      actionChangeShowDocumentList={
                        actionChangeShowDocumentList
                      }
                      buttonIsShow={true}
                    />
                  )}
                {detailInformationLoans === true &&
                  documentLoanType === "choose_offer" && (
                    <DisbursementBankAccount
                      actionChangeShowDocumentList={
                        actionChangeShowDocumentList
                      }
                      actionChangeShowYourLoan={actionChangeShowYourLoan}
                      bankAccount={bankAccount}
                      dataRepayment={dataRepayment}
                    />
                  )}
                {detailInformationLoans === true &&
                  documentLoanType === "waiting_for_contract" && (
                    <WaitingForContract
                      actionChangeShowYourLoan={actionChangeShowYourLoan}
                      dataRepayment={dataRepayment}
                    />
                  )}
              </React.Fragment>
            )}
          </React.Fragment>
        </DashboardLayoutKoinInvoice>
      </div>
      <TermsConditionsLoan
        visible={isShowTermConditionExpDate}
        onClose={actionTermConditionExpDate}
        creditUclLimit={creditUclLimit}
        language={language}
        borrowerInformation={borrowerInformation}
        checkExpiredDate={checkExpiredDate}
      />
    </DashboardKoinInvoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationList,
      businessInformationKoinvoice,
      actionButtonBackUnggahDokument,
      getAplicationProsess,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardKoinInvoiceApplyLoan);
