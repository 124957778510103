import styled from "styled-components";

export const ProgressLoanStyle = styled.div`
  .kw-progressLoan {
    .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      position: absolute;
      top: 0;
      left: 13px;
      width: 1px;
      height: 115%;
      padding: 30px 1px 0px;
    }

    .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      width: 5px;
      height: 100%;
    }

    /* .ant-steps {
  line-height: 3.5715;
} */

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: #378df4;
      font-size: 15px;
      font-weight: 500;
    }

    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: #2b486d;
      font-size: 16px;
      font-weight: bold;
    }

    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: #8e8e8e;
      font-size: 15px;
      font-weight: 500;
    }

    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      width: 100%;
    }

    .anticon {
      vertical-align: 4px;
    }

    .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
      line-height: 40px;
    }
  }
`;

export const ScheduleLoanStyle = styled.div`
  .kw-scheduleLoanDetails {
    .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      position: absolute;
      top: 0;
      left: 13px;
      width: 1px;
      height: 115%;
      padding: 30px 1px 0px;
    }

    .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      width: 5px;
      height: 100%;
    }

    /* .ant-steps {
  line-height: 3.5715;
} */

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: rgba(55, 141, 244, 0.5);
    }

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: #378df4;
      font-size: 15px;
      font-weight: 500;
    }

    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: #2b486d;
      font-size: 18px;
      font-weight: bold;
    }

    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: #8e8e8e;
      font-size: 15px;
      font-weight: 500;
    }

    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      width: 100%;
    }

    .anticon {
      vertical-align: 4px;
    }

    .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
      line-height: 40px;
    }
  }
`;
