const init = {
  data: null,
  isLoadingRegister: false,
  isCreatedBusiness: false,
};

const accountReducer = (state = init, action) => {
  switch (action.type) {
    case "ACCOUNT_INFO":
      return { ...state, data: action.data };
    case "EMAIL_REGISTERED":
      return {
        ...state,
        isEmailAvailable: false,
        errors: action.payload,
        emailErrors: action.payload,
      };
    case "EMAIL_AVAILABLE":
      return {
        ...state,
        isCreatedBusiness: false,
        isEmailAvailable: true,
        errors: undefined,
        emailErrors: undefined,
      };
    case "CHECK_EMAIL_FAILED":
      return {
        ...state,
        isEmailAvailable: false,
        isCreatedBusiness: false,
        errors: action.payload,
        emailErrors: action.payload,
      };
    case "BUTTON_BACK_REGISTER":
      return {
        ...state,
        isEmailAvailable: false,
        isPhoneAvailable: false,
      };
    case "PHONE_NUMBER_AVAILABLE":
      return {
        ...state,
        isPhoneAvailable: true,
        isCreatedBusiness: false,
        errors: undefined,
      };
    case "PHONE_NUMBER_REGISTERED":
      return {
        ...state,
        isPhoneAvailable: false,
        isCreatedBusiness: false,
        errors: action.payload,
      };
    case "CHECK_PHONENUMBER_FAILED":
      return { ...state, isPhoneAvailable: false, errors: action.payload };
    case "PIN_INFO":
      return { ...state, data: action.data };
    case "REQUEST_LOADING":
      return { ...state, isLoading: true };
    case "REQUEST_LOADING_FALSE":
      return { ...state, isLoading: false };
    case "LOADING_REGISTER_TRUE":
      return { ...state, isLoadingRegister: true };
    case "LOADING_REGISTER_FALSE":
      return { ...state, isLoadingRegister: false };
    case "CREATE_ACCOUNT_SUCCEEDED":
      return { ...state, isCreated: true };
    case "CREATE_ACCOUNT_FAILED":
      return { ...state, isCreated: false, regErrors: action.payload };
    case "CREATE_ACCOUNT_BUSINESS_SUCCEEDED":
      return { ...state, isCreatedBusiness: true };
    case "CREATE_ACCOUNT_BUSINESS_FAILED":
      return { ...state, isCreatedBusiness: false, regErrors: action.payload };
    case "GET_PERSONAL_PROFILE_SUCCESS":
      return { ...state, profile: action.payload };
    case "PUT_PERSONAL_PROFILE_SUCCESS":
      return { ...state, isPutPersonal: true };
    case "PUT_PERSONAL_PROFILE_FAILED":
      return { ...state, isPutPersonal: false, errors: action.payload };
    case "PUT_LEGAL_PROFILE_SUCCESS":
      return { ...state, isPutLegal: true };
    case "PUT_LEGAL_PROFILE_FAILED":
      return { ...state, isPutLegal: false, errors: action.payload };
    case "PUT_EMERGENCY_DATA_SUCCESS":
      return { ...state, isPutEmergency: true };
    case "PUT_EMERGENCY_DATA_FAILED":
      return { ...state, isPutEmergency: false, errors: action.payload };
    case "PUT_FINANCIAL_DATA_SUCCESS":
      return { ...state, isPutFinancial: true };
    case "PUT_FINANCIAL_DATA_FAILED":
      return { ...state, isPutFinancial: false };
    case "CONNECT_ACCOUNT_SUCCESS":
      return { ...state, isConnected: true };
    case "CONNECT_ACCOUNT_FAILED":
      return { ...state, isConnected: false, connErrors: action.payload };
    case "GET_PRIMARY_BANK_SUCCESS":
      return { ...state, isGetBankSuccess: true, bankInfo: action.payload };
    case "GET_PRIMARY_BANK_FAILED":
      return { ...state, isGetBankFailed: true };
    case "PUT_PRIMARY_BANK_SUCCESS":
      return { ...state, isPutBankSuccess: true };
    case "PUT_PRIMARY_BANK_FAILED":
      return { ...state, isPutBankFailed: true };
    default:
      return state;
  }
};

export default accountReducer;
