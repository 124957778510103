import React, { Component } from "react";
import { connect } from "react-redux";
import { PartnersWrapper } from "./Partners.styles";

// images
import dekoruma from "../../../assets/images/partners/dekoruma.png";
import fabelio from "../../../assets/images/partners/fabelio.png";
import gadjian from "../../../assets/images/partners/gadjian.png";
import indosat from "../../../assets/images/partners/indosat.png";
import kopikenangan from "../../../assets/images/partners/kopikenangan.png";
import talenta from "../../../assets/images/partners/talenta.png";

class Partners extends Component {
  render() {
    let { init: { language }} = this.props;
    return (
      <PartnersWrapper>
        <section className="partners">
          <div className="container">
            <div className="content-wrapper">
              <div className="text-wrapper">
                <h2 className="heading">
                  {language.homepage.trustedPartner}
                </h2>
                <p className="text">
                  {language.homepage.trustedPartnerText}{" "}
                </p>
              </div>
              <div className="img-wrapper">
                <figure className="single-partner">
                  <img src={fabelio} alt="" />
                </figure>
                <figure className="single-partner">
                  <img src={indosat} alt="" />
                </figure>
                <figure className="single-partner">
                  <img src={talenta} alt="" />
                </figure>
                <figure className="single-partner">
                  <img src={kopikenangan} alt="" />
                </figure>
                <figure className="single-partner">
                  <img src={gadjian} alt="" />
                </figure>
                <figure className="single-partner">
                  <img src={dekoruma} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </PartnersWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init
});

export default connect(mapStateToProps, null)(Partners);
