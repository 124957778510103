import React from "react";
import { Card, Progress, Row, Col } from "antd";
import { progressBar } from "../../library/progressBar";
import currencyRupiah from "../../library/currency";
import { AvailableCreditProgressStyle } from "../../BorrowerDashboardKoinInvoice/style";

function AvailableCreditProgress({
  loanLimit,
  actionChangeShowYourLoan,
  language,
  creditUclLimit,
}) {
  // const {
  //   // available_credit,
  //   //available_credit_percentage,
  //   total_limit,
  //   // total_used,
  // } = loanLimit && loanLimit.data;
  return (
    <Card style={{ marginTop: 15 }}>
      <AvailableCreditProgressStyle>
        <div className="kw-availableCredit-wrapper">
          <Row>
            <Col xs={12}>
              <span className="kw-availableCreedit-availableCredit">
                {language.loanDashboard.availableCredit}
              </span>
            </Col>
            <Col xs={12}>
              <span className="kw-availableCreedit-currency">
                {currencyRupiah(creditUclLimit?.current_limit <= 0 ? 0 : creditUclLimit?.current_limit, "Rp ")}
              </span>
            </Col>
          </Row>
        </div>
        <Progress
          strokeColor={{
            "0%": "#D2D2D2",
            "100%": "#D2D2D2",
          }}
          percent={100}
          showInfo={false}
          strokeWidth={10}
          successPercent={progressBar(
            creditUclLimit?.initial_limit,
            creditUclLimit?.current_limit
          )}
        />
        <Row style={{ marginTop: 10 }}>
          <Col xs={12}>
            <span className="kw-availableCredit-used">
              {language.loanDashboard.totalUsed}
            </span>
            <strong>{currencyRupiah(creditUclLimit?.used_limit, "Rp ")}</strong>
          </Col>
          <Col xs={12}>
            <span className="kw-availableCredit-used">
              {language.loanDashboard.totalLimit}
            </span>
            <strong>{currencyRupiah(creditUclLimit?.initial_limit <= 0 ? 0 : creditUclLimit?.initial_limit, "Rp ")}</strong>
          </Col>
        </Row>
        {/* <p
          className="kw-availableCredit-viewRequestedLoans"
          onClick={() =>
            actionChangeShowYourLoan(undefined, "viewRequestedLoans")
          }
        >
           {language.loanDashboard.viewRequestedLoans}
        </p> */}
      </AvailableCreditProgressStyle>
    </Card>
  );
}

export default AvailableCreditProgress;
