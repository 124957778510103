import React, { useState, useEffect } from "react";
import { Radio, Spin, Modal } from "antd";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  getLoanPrincipal,
  getLoanFinancing,
  purposeLoanKoinVoice,
  buttonBackSelectLoan,
} from "../../../stores/actions/lmsLimit";
import { actionPostLimitApplicationRedux } from "../../../stores/actions/koininvoiceLimit";
import { bindActionCreators } from "redux";
import PrincipalCodeLoan from "./PrincipalCodeLoan";
import ProjectFinancingLoan from "./ProjectFinancingLoan";
import cookie from "react-cookies";
import koinvoiceServices from "../../../services/koinvoice";
import history from "../../../util/history";

function SelectLoanPurpose({
  init,
  getLoanPrincipal,
  getLoanFinancing,
  purposeLoanKoinVoice,
  actionPostLimitApplicationRedux,
  koinvoice,
  buttonBackSelectLoan,
  setPage,
  userId
}) {
  const { language, flag } = init;
  const [labelLoanCode, setLabelLoanCode] = useState("Supply Chain Financing"); //supply_chain_financing
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [code, setCode] = useState("");
  const [laCode, setLaCode] = useState("");
  const [errlaCode, setErrlaCode] = useState(!laCode && true);
  let principalCode = koinvoice.principal;
    // cookie.load("principal") !== undefined && cookie.load("principal").data;
  let financingCode = koinvoice.financing;
    // cookie.load("financing") !== undefined && cookie.load("financing").data;
  
  let paramBO = userId ? JSON.parse(localStorage.getItem(userId)) : {};


  useEffect(() => {
    window.scrollTo(0, 0);

    koinvoiceServices.getBusinessInfo(paramBO.token).then((res) => {
      if (res?.status === 200) {
        localStorage.setItem(`bisInfo-${paramBO.userId}`, JSON.stringify(res.data));
      } else {
        history.push('/lms/apply-limit/0/0');
      }
    });

    buttonBackSelectLoan();// eslint-disable-next-line
  }, []);

  function handleChangeFinancingLoan(value) {
    getLoanFinancing({ reference_code: value.target.value }, paramBO.token);
    setCode(value.target.value);
  }

  function handleChangeCodeLoan(value) {
    getLoanPrincipal({ reference_code: value.target.value }, paramBO.token);
    setCode(value.target.value);
  }

  function handleChangeLaCode(value) {
    setLaCode(value.target.value);
    if(value.target.value === "")
      setErrlaCode(true);
    else
    setErrlaCode(false);
  }

  function handleChangeTypeSelectLoan(value) {
    setLabelLoanCode(value.target.value);
    buttonBackSelectLoan();
    setIsDisabledButton(true);
  }

  function handleChangeSubmitPrincipal() {
    actionPostLimitApplicationRedux(
      {
        vendor_uuid: principalCode?.vendor_uuid,
        principal_uuid: principalCode?.principal_uuid,
        admin_email: paramBO.admin,
        userId: paramBO.userId,
        origin: 'backoffice',
        token: paramBO.token,
        la_code: laCode.toUpperCase()
      },
      setIsShowLoading,
      setPage,
      init
    );
    const indexesToLocalstorage = JSON.stringify(labelLoanCode);
    localStorage.setItem("selectLoan", indexesToLocalstorage);
    localStorage.setItem("vendor", JSON.stringify(principalCode));
  }
  function handleChangeSubmitFinancing() {
    actionPostLimitApplicationRedux(
      {
        vendor_uuid: financingCode?.vendor_uuid,
        referral_uuid: financingCode?.referral_uuid,  
        admin_email: paramBO.admin,
        userId: paramBO.userId,
        origin: 'backoffice',      
        token: paramBO.token,
        la_code: laCode.toUpperCase()
      },
      setIsShowLoading,
      setPage,
      init
    );
    const indexesToLocalstorage = JSON.stringify(labelLoanCode);
    localStorage.setItem("selectLoan", indexesToLocalstorage);
    localStorage.setItem("vendor", JSON.stringify(financingCode));
  }
  const isMobile = () => {
    const width = window.innerWidth;
    if (width > 700) return true;
    return false;
  };
  
  return (
    <React.Fragment>
      <h4>{language.loanKoinVoice.selectLoanProduct}</h4>
      <div className="kw-selectLoanYourPurpose">
        <Radio.Group
          onChange={handleChangeTypeSelectLoan}
          defaultValue="Supply Chain Financing"
          size="large"
        >
          <Radio.Button
            className="kw-selectLoanYourPurpose__selectLoanWrapper"
            value="Supply Chain Financing"
          >
            {" "}
            <img
              src={require("../../../assets/images/applyloan/koinvoice/icon-supply-chain-financing.png").default}
              alt=""
              style={{ height: 70 }}
            />
            <br />
            <p className="kw-selectLoanYourPurpose__label">
              {language.loanKoinVoice.supplyChain}
            </p>
            <p className="kw-selectLoanYourPurpose__text">
              {language.loanKoinVoice.supplyChainText}
            </p>
          </Radio.Button>
          <Radio.Button
            className="kw-selectLoanYourPurpose__selectLoanWrapper"
            value="Invoice Financing"
          >
            <img
              src={require("../../../assets/images/applyloan/koinvoice/icon-project-financing.png").default}
              alt=""
              style={{ height: 70 }}
            />
            <br />
            <p className="kw-selectLoanYourPurpose__label">
              {language.loanKoinVoice.projectFinancing}
            </p>
            <p className="kw-selectLoanYourPurpose__text">
              {language.loanKoinVoice.projectFinancingText}
            </p>
          </Radio.Button>
        </Radio.Group>
        <br />
        <h5>{language.loanKoinVoice.laCode}</h5>
        {
          process.env.REACT_APP_LA_CODE_SWITCHER === "true" ? (
            <div>
              <TextField
                label={language.loanKoinVoice.laCode}
                variant="outlined"
                name="la_code"
                onChange={handleChangeLaCode}
                type="text"
                style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
                error={!laCode && errlaCode}
                helperText={errlaCode && language.text.cantBeEmpty}
              />
            </div>
          ) :
          (
            <div>
              <TextField
                label={language.loanKoinVoice.laCode}
                variant="outlined"
                name="la_code"
                onChange={handleChangeLaCode}
                type="text"
                style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
              />
            </div>
          )
        }
        <Spin spinning={koinvoice.isShowLoading}>
          {labelLoanCode === "Supply Chain Financing" ? (
            <div style={{ marginTop: 20 }}>
              <PrincipalCodeLoan
                flag={flag}
                language={language}
                handleChangeCodeLoan={handleChangeCodeLoan}
                koinvoice={koinvoice}
                isDisabledButton={isDisabledButton}
                isShowLoading={isShowLoading}
                handleChangeSubmitPrincipal={handleChangeSubmitPrincipal}
                principalCode={principalCode}
                userId={paramBO.userId}
                code={code}
                isMobile={isMobile}
                laCode={laCode}
                errlaCode={errlaCode}
              />
            </div>
          ) : (
            <div style={{ marginTop: 20 }}>
              <ProjectFinancingLoan
                flag={flag}
                language={language}
                handleChangeFinancingLoan={handleChangeFinancingLoan}
                koinvoice={koinvoice}
                isShowLoading={isShowLoading}
                handleChangeSubmitFinancing={handleChangeSubmitFinancing}
                financingCode={financingCode}
                userId={paramBO.userId}
                code={code}
                isMobile={isMobile}
                laCode={laCode}
                errlaCode={errlaCode}
              />
            </div>
          )}
        </Spin>
      </div>
      {!userId && (
        <Modal 
          visible={true} 
          footer={null}
          width={400}
          closable={false}
          keyboard={false}
          mask={true}
          maskStyle={{backgroundColor: "rgba(1,1,1,0.6)"}} 
        >
          <figure style={{ textAlign: 'center', margin: '20px 10px'}}>
            <img
              src={require("../../../assets/images/notfound.png").default}
              style={{ width: 200, marginTop: 35, marginBottom: 25 }}
              alt=""
            />
            <h4>Ooops...</h4>
            <p>It seems that your link is broken. Please generate the correct link via BackOffice</p>
          </figure>
        </Modal>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  koinvoiceLimit: state.koinvoiceLimit,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLoanPrincipal,
      getLoanFinancing,
      purposeLoanKoinVoice,
      buttonBackSelectLoan,
      actionPostLimitApplicationRedux,
    },
    dispatch
  );
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectLoanPurpose);

export default reduxForm({
  form: "SelectLoanYourPurpose",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(component);
