import styled from "styled-components";

export const DrawerStyle = styled.div`
  .kw-drawer-sucessLink {
    display: flex;
    justify-content: center;
    flex-direction: column;
    &__buttonClose {
      font-weight: bold;
      font-size: 13px;
      text-align: center;
      text-decoration-line: underline;
      text-transform: uppercase;
      color: #566068;
      cursor: pointer;
    }
    h3 {
      text-align: center;
      margin-top: 30px;
      font-weight: bold;
      font-size: 28px;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
    p {
      margin-top: 10px;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.41px;
      color: #575756;
    }
  }
`;
