import styled from "styled-components";

export const WaitingForContractStyle = styled.div`
  .kw-waitingFor-contract {
    .kw-button {
      background-color: #2b476c;
      border-color: #2b476c;
      color: #ffffff;
      width: 150px;
    }
    &__label {
      display: block;
      font-style: normal;
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 17px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    &__card {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(8, 35, 48, 0.24);
      border-radius: 3px;
      padding: 25px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #566068;
    }
    .tbChild {
      font-size: 14px;
      font-weight: 400;
      margin: 20px;
      tr td {
        padding: 10px 0;
      }
      tr td:last-child {
        vertical-align: bottom;
        text-align: center;
      }
    }
  }
`;
