import React, { Component } from "react";

import { Dropdown, Form, Accordion, Card } from "react-bootstrap";


class SortFilter extends Component {

  render() {
    let { language } = this.props;

    return (
      <div className="sortfilter">
        <Dropdown>
          <Dropdown.Toggle variant="none" id="dropdown-sort">
            <i className="fa fa-sort"></i><span>{language.label.sort}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h5 className="title">{language.label.sortBy}</h5>
            <div className="sort">
              <Form>
                <div key="newest">
                  <Form.Check
                    type="radio"
                    name="sort"
                    id="newest"
                    label={language.label.newestFirst}
                    value="newest"
                    custom
                    // checked
                    onClick={this.props.onClick}
                  />

                  <Form.Check
                    type="radio"
                    name="sort"
                    id="oldest"
                    label={language.label.oldestFirst}
                    value="oldest"
                    custom
                    onClick={this.props.onClick}
                  />
                </div>
              </Form>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="none" id="dropdown-filter">
            <i className="fa fa-sliders"></i><span>{language.label.filter}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h5 className="title">{language.label.filterBy}</h5>
            <Accordion defaultActiveKey="">
              <Card className="filter">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <span>Status</span> <i className="fa fa-chevron-right"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Form>
                      <div key="newest">
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="today"
                          label="Due Today"
                          value="due_today"
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="upcoming"
                          label="Upcoming"
                          value="upcoming"
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="late"
                          label="Late"
                          value="late"
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="partial"
                          label="Partially Paid"
                          value="partially_paid"
                          custom
                          onChange={this.props.onChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="filter"
                          id="paid"
                          label="Paid"
                          value="paid"
                          custom
                          onChange={this.props.onChange}
                        />
                      </div>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}



export default SortFilter;