import React from "react";
import { Route, Redirect } from "react-router-dom";
import cookie from "react-cookies";
// import { getDecodedToken } from "../services/token";

import bgimg from "../assets/images/bgimg.png";
import imgAcc from "../assets/images/img-acc1.png";
import imgDot from "../assets/images/dot.png";
import Navigation from "../Navigation/Navigation";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        cookie.load("token") ? (
          <div className="container-fluid layout">
            <div className="row">
              <div className="col-6 p-0 .d-none .d-sm-block">
                <figure className="bg-wrapper">
                  <img className="img-acc" src={imgAcc} alt="" />
                  <img className="img-bg" src={bgimg} alt="" />
                </figure>
              </div>
              <div className="col-12 col-sm-6 p-0">
                <Navigation />
                <div className="bgcontent-wrapper">
                  {hostNameCheckCondition ? (
                    ""
                  ) : (
                    <figure className="bg-dot">
                      <img className="img-dot" src={imgDot} alt="" />
                    </figure>
                  )}
                  <div className="bg-content">
                    <Component {...props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
