const initState = {
	data: null
};

const fundingReducer = (state = initState, action) => {
	switch (action.type) {
    case "GET_FUNDING_SUCCESS":
      return { ...state, data: action.payload, getFundingFailed: false }
    case "GET_FUNDING_FAILED":
      return { ...state, getFundingFailed: true }
    default:
      return state;
  }
};

export default fundingReducer;
