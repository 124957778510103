import React from "react";
import { Card } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import moment from "moment";
import history from "../../util/history";

const RejectLimitProgress = ({ creditLimitLoan, language }) => {
  return (
    <Card>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 25 }}
      >
        <img
          src={require("../../assets/images/dashboard/rejected.png").default}
          alt=""
        />
      </div>
      <p style={{ textAlign: "center", color: "#9E2C34", fontWeight: 600, fontSize: "18px" }}>
        {language.title.rejectedLimit}
      </p>
      <p style={{ textAlign: "center", color: "#8A8686", fontWeight: 555 }}>
        {["rejected_temporary"].includes(creditLimitLoan.status)
          ? language.text.rejectTemporaryText
          : language.text.rejectPermanentText }
      </p>
      <div
        style={{
          background: "rgba(239, 239, 239, 0.5)",
          border: "1px solid #8E8E8E",
          borderRadius: 3,
          padding: 15,
          marginBottom: creditLimitLoan.status === 'rejected_temporary' ? 25 : 50,
        }}
      >
        <p style={{ textAlign: "center", color: "#8A8686", fontWeight: 555 }}>
          <MailOutlined /> support@koinworks.com
        </p>
        <p
          style={{
            textAlign: "center",
            color: "#8A8686",
            fontWeight: 555,
            alignItems: "center",
          }}
        >
          <PhoneOutlined /> (021) 5283883
        </p>
      </div>
      {["rejected_temporary"].includes(creditLimitLoan.status) ? 
      ( creditLimitLoan.properties.rejected_until !== '' 
        && creditLimitLoan.properties.rejected_until !== null
        && Date.parse(creditLimitLoan.properties.rejected_until) > Date.now() ? (
        <React.Fragment>
          <div
            style={{
              paddingBottom: "50px"
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#575756",
                fontWeight: "bold",
                marginBottom: "0"
              }}
            >
              You can reapply on:
            </p>
            <p
              style={{
                textAlign: "center",
                color: "#2B486D",
                fontSize: 20,
                fontWeight: "bold"
              }}
            >
              {moment(creditLimitLoan.properties.rejected_until).format(
                "DD MMMM YYYY"
              )}
            </p>
          </div>
        </React.Fragment>
      ) : history.push("/koininvoice/apply-limit")
      ) : undefined}
    </Card>
  );
};

export default RejectLimitProgress;
