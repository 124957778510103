import React from "react";
import { Radio, Button, Drawer } from "antd";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Section } from "./style";
import "./style.css";
import { handleDecrypets } from "../../library/decryptes";
import NotAccountBusiness from "./NotAccountBusiness";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {}
)(
  withRouter(function SelectAccount({
    visible,
    onClose,
    nextPage,
    auth: { authPreparationCheck, checkBorrower },
  }) {
    const [checked, setChecked] = useState();

    useEffect(() => {
      setChecked(
        authPreparationCheck && handleDecrypets(authPreparationCheck[0].empat)
      );
    }, [authPreparationCheck]);

    function onChange(e) {
      setChecked(e.target.value);
    }

    function actionChangeContinue() {
      const checkRoleType = authPreparationCheck.find(
        (cek) => handleDecrypets(cek.empat) === checked
      );
      if (checkRoleType && process.env.REACT_APP_ENCRYPT_ACCOUNT_TYPE_FIELD_ENABLED === 'true' ? handleDecrypets(checkRoleType.lima) : checkRoleType.lima === "business") {
        const indexesToLocalstorage = JSON.stringify(checkRoleType);
        localStorage.setItem("dataAccount", indexesToLocalstorage);
        onClose();
        nextPage();
      } // else if (checkRoleType && checkRoleType.lima === "personal") {
      //   const indexesToLocalstorage = JSON.stringify(checkRoleType);
      //   localStorage.setItem("dataAccount", indexesToLocalstorage);
      //   onClose();
      //   nextPage();
      // }
    }

    const radioStyle = {
      marginTop: "10px",
    };

    function transformEntry(item, type) {
      switch (type) {
        case "email":
          let parts = item.split("@"),
            len = parts[0].length;
          const name = parts[0].replace(
            parts[0].slice(0, -4),
            "*".repeat(len - 1)
          );
          const domain = parts[1].replace(parts[1].slice(0, 4), "*".repeat(4));
          return `${name}@${domain}`;
        case "phone":
          return item[0] + "*".repeat(item.length - 4) + item.slice(-3);
        default:
          throw new Error("Undefined type: " + type);
      }
    }
    
    return (
      <Drawer
        onClose={onClose}
        className="kw-drawer-login"
        placement="bottom"
        visible={visible}
        height={checkBorrower && checkBorrower.length <= 0 ? "555" : "455"}
      >
        <Section>
          <div className="kw-mainLayout-wrapper">
            {checkBorrower && checkBorrower.length > 0 ? (
              <React.Fragment>
                <h3>Choose Account </h3>
                <p>Please chosee your account before continue</p>
                <Radio.Group
                  style={
                    authPreparationCheck && authPreparationCheck.length > 4
                      ? { height: "7rem", overflowY: "scroll" }
                      : {}
                  }
                  onChange={onChange}
                  value={checked}
                >
                  {authPreparationCheck &&
                    authPreparationCheck.map((auth, index) => {
                      return (
                        <Radio
                          key={index}
                          style={radioStyle}
                          value={handleDecrypets(auth.empat)}
                        >
                          Account <strong>{process.env.REACT_APP_ENCRYPT_ACCOUNT_TYPE_FIELD_ENABLED === 'true' ? handleDecrypets(auth.lima) : auth.lima}</strong> &nbsp;
                          {transformEntry(handleDecrypets(auth.empat), "email")}
                        </Radio>
                      );
                    })}
                </Radio.Group>
                <div style={{ marginTop: 10 }}>
                  <Button
                    onClick={actionChangeContinue}
                    size="large"
                    type="primary"
                    className="kw-button"
                    block
                  >
                    Continue
                  </Button>
                  <Button onClick={onClose} size="large" type="link" block>
                    Cancel
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <div>
                <NotAccountBusiness />
                <Button onClick={onClose} size="large" type="link" block>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Section>
      </Drawer>
    );
  })
);
