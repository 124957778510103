import React from "react";
import Webcam from "react-webcam";
import {Button } from "antd";

const TakePhoto = ({ setImageTarget }) => {
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        let temp = dataURItoBlob(imageSrc);
        setImageTarget(temp);
    }, [webcamRef, setImgSrc]);

    const dataURItoBlob = (dataURI) => {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
      
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
      
        // create a view into the buffer
        var ia = new Uint8Array(ab);
      
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
      
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
      
      }

    return (
        <>
        <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
        />
        <br />
        <Button type="button" className="kw-button" onClick={capture}>
            CAPTURE PHOTO
        </Button>
        <br />
        {
            imgSrc && (
                <img
                    src={imgSrc}
                />
        )}
        </>
    );
};

export default TakePhoto;