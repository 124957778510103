import React from "react";

export const boxOrder = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="6" fill="#378DF4" />
      <circle
        opacity="0.5"
        cx="10"
        cy="10"
        r="8"
        stroke="#378DF4"
        strokeWidth="4"
      />
    </svg>
  );
};

export const boxOrderNotActive = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="6" fill="#CCCCCC" />
      <circle
        opacity="0.5"
        cx="10"
        cy="10"
        r="8"
        stroke="#CCCCCC"
        strokeWidth="4"
      />
    </svg>
  );
};
