import React from "react";
import NumberFormat from "react-number-format";

const CurrencyFormatInput = ({ 
  inputRef,
  onChange,
  ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    placeholder="E.g.: 10,000,000"
    onValueChange={values => {
      if(values.floatValue) {
        onChange(values.floatValue);
      } else {
        onChange(0);
      }
    }}
    thousandSeparator
    isNumericString
  />
);

export default CurrencyFormatInput;