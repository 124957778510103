import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import NumberFormat from "react-number-format";
import { Tabs, Tab } from "react-bootstrap";

import SimpleTextInput from "../../Components/Form/simpleTextInput";
import SimpleSelectInput from "../../Components/Form/simpleSelectInput";
import CurrencyFormatInput from "../../Components/Form/currencyFormatInput";
import { StepLoanWrapper } from "./StepLoan.styles";
import { loanInfo } from "../../stores/actions/loan";
import { uploadKTP } from "../../stores/actions/account";
import { calculation } from "../common/calculation";
import * as validate from "../../services/validate";

import regularImg from "../../assets/images/applyloan/regular.png";
import advanceImg from "../../assets/images/applyloan/salary-advance.png";

class StepLoan extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    values: {
      advAmount: "",
      regAmount: "",
      advPurpose: "",
      regPurpose: "",
      regTerm: 1,
      advTerm: 1,
      regNik: "",
      advNik: "",
      regFile: null,
      advFile: null,
      advFileName: "",
      regFileName: ""
    },
    offer: "",
    loanAmount: 0.0,
    installment: 0.0,
    fee: 0.0,
    tenures: [],
    errMessage: {
      advAmount: "",
      regAmount: ""
    }
  };

  onSelectTab = val => {
    this.setState({ offer: val });
  };

  handleChange = name => event => {
    const { values } = this.state;
    let loanAmount = this.state.loanAmount;
    let installment = this.state.installment;
    let fee = this.state.fee;
    const loan = this.props.loan.data;
    if (name === "advTerm" || name === "regTerm") {
      values[name] = event.value;
      if (this.state.offer === "reg") {
        [loanAmount, installment, fee] = calculation.regular(
          loan.loan_status,
          loan.e_status,
          values.regAmount,
          values.regTerm,
          loan.vendor_data
        );
      }
    } else if (name === "advAmount" || name === "regAmount") {
      values[name] = event;
      if (this.state.offer === "adv") {
        [fee, loanAmount] = calculation.salaryAdvance(event);
      } else {
        [loanAmount, installment, fee] = calculation.regular(
          loan.loan_status,
          loan.e_status,
          event,
          values.regTerm,
          loan.vendor_data
        );
      }
    } else {
      values[name] = event.target.value;
    }
    this.setState({
      values: values,
      loanAmount: loanAmount,
      installment: installment,
      fee: fee
    });
  };  

  updateTerm = e => {
    let { values } = this.state;
    values["term"] = e.value;
    this.setState({ values: values });
  };

  renderTenures() {
    if (this.props.loan.data !== null) {
      let data = JSON.parse(this.props.loan.data.tenure);
      return data.map(item => ({ value: item, label: item + " months" }));
    } else {
      return [];
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit();
    let { offer, values, loanAmount, installment, fee } = this.state;
    let loan = this.props.loan.data;
    let referral = {
      vendor_name: loan.vendor_data.vendor_name,
      vendor_id: loan.vendor_data.vendor_id,
      pic_name: "",
      pic_phone_number: "",
      pic_email: ""
    }
    if (offer === "adv") {
      this.props.loanInfo({
        data: {
          loan_amount: parseInt(values.advAmount),
          disbursement: loanAmount,
          installment: installment,
          fee: fee,
          tenure: values.advTerm,
          nik: values.advNik,
          loan_purpose: values.advPurpose,
          offer: "Salary Advance",
          employee_status: loan.status,
          loan_status: loan.loan_status,
          company_referral: referral,
          vendor_data: loan.vendor_data
        }
      });
    } else {
      this.props.loanInfo({
        data: {
          loan_amount: parseInt(values.regAmount),
          disbursement: loanAmount,
          installment: installment,
          fee: fee,
          tenure: values.regTerm,
          nik: values.regNik,
          loan_purpose: values.regPurpose,
          offer: "Regular",
          employee_status: loan.status,
          loan_status: loan.loan_status,
          company_referral: referral,
          vendor_data: loan.vendor_data
        }
      });
    }
  };

  render() {
    const { values, offer, installment, loanAmount, fee } = this.state;
    const { invalid, pristine, submitting, init: {language} } = this.props;
    const vendor = this.props.loan.data.vendor_data;
    const salary = this.props.loan.data.salary;

    return (
      <StepLoanWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title">{language.title.chooseOffer}</h2>
          <Tabs
            defaultActiveKey={
              !vendor.loan_type.salary_advance ? "reg" : this.state.offer
            }
            id="offer-type"
            className="offer-box-wrapper"
            onSelect={this.onSelectTab}
          >
            {/* advance */}
            <Tab
              eventKey="adv"
              disabled={!vendor.loan_type.salary_advance}
              title={
                <div>
                  <figure className="choose-offer-img">
                    <img src={advanceImg} alt="salary-advance" />                    
                  </figure>
                  <h5 className="radio-title">Salary Advance</h5>
                  <p className="radio-text">
                    {language.text.salaryAdvance}{" "}
                  </p>
                </div>
              }
            >
              {offer === "adv" && (
                <div className="offer-content-wrapper">
                  <h2 className="step-title">
                    {language.title.stepLoan}
                  </h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-wrapper">
                      <Field
                        id="adv-amount"
                        name="advAmount"
                        label={language.form.advAmount}
                        variant="outlined"
                        className={`adv-amount w-100`}
                        component={SimpleTextInput}
                        value={values.advAmount}
                        onChange={this.handleChange("advAmount")}
                        validate={[
                          validate.required,
                          validate.floatAmount,
                          validate.advAmountInternal,
                          validate.maxAdvanceAmount(salary)
                        ]}
                        InputProps={{
                          inputComponent: CurrencyFormatInput
                        }}
                        helperText={
                          <>
                            <span>
                              {language.text.advAmount}
                            </span>
                          </>
                        }
                      />
                    </div>
                    <div className="input-wrapper">
                      <Field
                        id="input-adv-purpose"
                        name="advPurpose"
                        label={language.form.purpose}
                        variant="outlined"
                        className="w-100"
                        value={values.advPurpose}
                        component={SimpleTextInput}
                        onChange={this.handleChange("advPurpose")}
                        validate={validate.required}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className="box-total">
                        <div className="box-list camount-req">
                          <span className="text-left">{language.label.amountRequested}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={
                                values.advAmount !== "" ? values.advAmount : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                        <div className="box-list cfee">
                          <span className="text-left">{language.label.fee}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={fee}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                        <div className="box-list ctotal">
                          <span className="text-left">{language.label.totalDisburse}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={loanAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <button
                        className={"step-next btn btn-primary w-100"}
                        disabled={invalid || pristine || submitting }
                      >
                        {language.button.next}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Tab>

            {/* regular */}
            <Tab
              eventKey="reg"
              disabled={!vendor.loan_type.regular}
              title={
                <div>
                  <figure className="choose-offer-img">
                    <img src={regularImg} alt="regular-loan" />
                  </figure>
                  <h5 className="radio-title">Regular</h5>
                  <p className="radio-text">
                    {language.text.regularLoan}
                  </p>
                </div>
              }
            >
              {offer === "reg" && (
                <div className="offer-content-wrapper">
                  <h2 className="step-title">
                    {language.title.stepLoan}
                  </h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-wrapper">
                      <Field
                        id="input-reg-purpose"
                        name="regAmount"
                        label={language.form.loanAmount}
                        variant="outlined"
                        className="w-100"
                        component={SimpleTextInput}
                        onChange={this.handleChange("regAmount")}
                        InputProps={{
                          inputComponent: CurrencyFormatInput
                        }}
                        validate={[
                          validate.required,
                          validate.floatAmount,
                          validate.regularAmount(salary)
                        ]}
                      />
                    </div>
                    <div className="input-wrapper">
                      <Field
                        id="regTerm"
                        name="regTerm"
                        label={language.form.loanTerm}
                        placeholder={language.form.chooseTerm}
                        options={this.renderTenures()}
                        component={SimpleSelectInput}
                        onChange={this.handleChange("regTerm")}
                        validate={validate.required}
                      />
                    </div>
                    <div className="input-wrapper">
                      <Field
                        id="input-reg-loan-purpose"
                        name="regPurpose"
                        label={language.form.purpose}
                        variant="outlined"
                        className="w-100"
                        component={SimpleTextInput}
                        onChange={this.handleChange("regPurpose")}
                        validate={validate.required}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className="box-total">
                        <div className="box-list camount-req">
                          <span className="text-left">{language.label.monthlyInstallment}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={installment}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                        <div className="box-list cfee">
                          <span className="text-left">{language.label.totalFee}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={fee}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                        <div className="box-list ctotal">
                          <span className="text-left">{language.label.totalDisburse}</span>
                          <span className="text-right">
                            <NumberFormat
                              value={loanAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <button
                        className={"step-next btn btn-primary w-100"}
                        disabled={invalid || pristine || submitting }
                      >
                        {language.button.next}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </StepLoanWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  loan: state.loan,
  upload: state.upload
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ loanInfo, uploadKTP }, dispatch);
};

const component = connect(mapStateToProps, mapDispatchToProps)(StepLoan);

export default reduxForm({
  form: "StepLoan",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(component);
