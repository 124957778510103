import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSchedule, getLoanSchedule } from "../stores/actions/schedule";
import { Breadcrumb } from "react-bootstrap";


class Education extends Component {


  render() {


    return (
      <div className="dashboard" ref={ref => this.el = ref}>
        <div className="dashboard-title">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#">schedule</Breadcrumb.Item>
            <Breadcrumb.Item active href="#">Education</Breadcrumb.Item>
          </Breadcrumb>
          <h3 className="title">
            Education
          </h3>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  balance: state.balance,
  schedule: state.schedule
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getSchedule, getLoanSchedule }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Education);