import React, { useEffect, useState } from "react";
import { Steps, Button } from "antd";
import cookie from "react-cookies";
import BusinessInfo from "./BusinessInfo";
import { BusinessInfoStyle } from "./style";
import Personalnfo from "./Personalnfo";
import { businessInformationKoinvoice } from "../../../stores/actions/koinvoiceLoan";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShareHolder from "./ShareHolder";
import OwnerDirectorInfo from "./OwnerDirectorInfo";
import UnggahDokumen from "../UnggahDokumen";
import history from "../../../util/history";
import { ApplyLoanKoinVoiceStyle } from "../../style";

const { Step } = Steps;

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 1180) return true;
  return false;
};

function BusinessInfoConfirmation({
  init: { language },
  businessInformationKoinvoice,
}) {
  const nameStore = cookie.load("name");
  const businessInformation =
    JSON.parse(localStorage.getItem("businessInformation")) !== null &&
    JSON.parse(localStorage.getItem("businessInformation"));
  const [current, setCurrent] = useState(
    cookie.load("current") !== undefined ? parseInt(cookie.load("current")) : 0,
  );
  const [guarantorShareHolder, setGuarantorShareHolder] =
    useState("Shareholder");
  let { business_type } =
    businessInformation?.data?.details?.business?.company_detail;
  let marital_status = businessInformation?.data?.details?.personal
    ?.user_information
    ? businessInformation?.data?.details?.personal?.user_information
      ?.marital_status
    : "";

  useEffect(() => {
    window.scrollTo(0, 0);
    businessInformationKoinvoice();
    // if (parseInt(cookie.load("displayPage")) === 2) {
    //   return;
    // } else {
    //   history.push("/koininvoice/apply-limit");
    // }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (businessInformation) {
      if (
        (business_type?.value === "individual" ||
          business_type?.value === "home_business") &&
        ["KAWIN", "MENIKAH"].includes(marital_status.value)
      ) {
        setGuarantorShareHolder("Spouse");
      } else if (
        (business_type?.value === "individual" ||
          business_type?.value === "home_business") &&
        ["BELUM KAWIN", "CERAI MATI", "CERAI HIDUP"].includes(
          marital_status.value,
        )
      ) {
        setGuarantorShareHolder("Guarantor");
      }
      if (business_type?.value === "pt" || business_type?.value === "pt/cv") {
        setGuarantorShareHolder("Share Holder");
      }
    } // eslint-disable-next-line
  }, [businessInformation]);

  function next() {
    const currents = current + 1;
    cookie.save("current", currents);
    setCurrent(
      parseInt(cookie.load("current")) !== undefined &&
      parseInt(cookie.load("current")),
    );
  }

  function prev() {
    const currents = current - 1;
    cookie.save("current", currents);
    setCurrent(
      parseInt(cookie.load("current")) !== undefined &&
      parseInt(cookie.load("current")),
    );
  }

  const steps = [
    {
      title: "Business",
      content: (
        <BusinessInfo
          nextPage={next}
          business_type={business_type?.value}
          businessInformation={businessInformation}
          nameStore={nameStore}
        />
      ),
    },
    {
      title: "Personal",
      content: (
        <Personalnfo
          nextPage={next}
          businessInformation={businessInformation}
          prevPage={prev}
          business_type={business_type?.value}
        />
      ),
    },
    {
      title:
        business_type?.value === "individual" ||
          business_type?.value === "home_business"
          ? "Owner"
          : "Director",
      content: (
        <OwnerDirectorInfo
          marital_status={marital_status.value}
          business_type={business_type?.value}
          nextPage={next}
          businessInformation={businessInformation}
          prevPage={prev}
        />
      ),
    },
    {
      title:
        business_type?.value === "cv" ||
          business_type?.value === "pt" ||
          business_type?.value === "pt/cv"
          ? "Shareholder"
          : "Guarantor",
      content: (
        <ShareHolder
          nextPage={next}
          guarantorShareHolder={guarantorShareHolder}
          marital_status={marital_status.value}
          business_type={business_type?.value}
          prevPage={prev}
          businessInformation={businessInformation}
        />
      ),
    },
    {
      title: "Done",
      content: <UnggahDokumen business_type={business_type?.value} />,
    },
  ];
  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <BusinessInfoStyle>
          <Steps
            progressDot
            direction={isMobile() ? "horizontal" : "vertical"}
            current={current}
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>{" "}
          {current === 0 && (
            <Button
              size="large"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                marginTop: 15,
              }}
              onClick={() => history.push("/koininvoice/apply-limit")}
            >
              <ArrowLeftOutlined /> {language.button.back}
            </Button>
          )}
          {steps[current].content}
        </BusinessInfoStyle>
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInformationKoinvoice,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusinessInfoConfirmation);
