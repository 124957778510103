import React, { useEffect, useState } from "react";
import { Drawer, Collapse, Row, Col, Button, Spin } from "antd";
// import schemaScheduleFilter from "../../Pages/DashboardKoinInvoice/schemaScheduleFilter";
import koinvoiceServices from "../../services/koinvoice";
import moment from "moment";
import currencyRupiah from "../../library/currency";
import NotFound from "../NotFound";
import { DrawerConfirmPaymentStyle } from "./style";
import { labelCondition } from "../CardScheduleKoinInvoice/labelCondition";

const { Panel } = Collapse;

export default function DrawerConfirmPayment({
  visible,
  onClose,
  dataRepayment,
  actionChangePaymentInstructions,
}) {
  const [payor, setPayor] = useState("");
  const [virtualAccount, setVirtualAccount] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowNotFoundDetailLoan, setIsShowNotFoundDetailLoan] =
    useState(false);

  useEffect(() => {
    getInformationDetailLoan();
  }, []);

  async function getInformationDetailLoan() {
    let response = await koinvoiceServices.actionInformationDetailLoan({
      paramsId: dataRepayment.loan_id,
      loading: setIsShowLoading,
    });
    if (response.status === 200) {
      setPayor(response.data.find((e) => e.detail_name === "name"));
      const virtualAccount = response.data.find(
        (e) => e.detail_name === "virtual_accounts"
      );
      setVirtualAccount(JSON.parse(virtualAccount.detail_value));
    } else {
      setIsShowNotFoundDetailLoan(true);
    }
  }

  const convertToCategoryName = (string) => {
    return (
      <span style={{ textTransform: "uppercase" }}>
        {string.replace(/[.*+?^$_{}()|[\]\\-]/g, " ")}
      </span>
    );
  };

  const contentHeaderRepayment = (list, requestDate) => {
    return (
      <Row style={{ marginBottom: 20 }}>
        <Col xs={12}>
          <strong className="kw-drawerConfirm-wrapper__total-due">
            {" "}
            DISBURSED ON
          </strong>
          <strong className="kw-drawerConfirm-wrapper__details">
            <img
              src={
                require("../../assets/images/icon/calendar-week.png").default
              }
              alt=""
            />{" "}
            {moment(requestDate).format("DD MMMM YYYY")}{" "}
          </strong>
        </Col>
        <Col xs={12}>
          <strong className="kw-drawerConfirm-wrapper__total-due">TERM</strong>
          <strong className="kw-drawerConfirm-wrapper__details">
            <img
              src={
                require("../../assets/images/icon/calendar-week.png").default
              }
              alt=""
            />{" "}
            {list.term}
          </strong>
        </Col>
      </Row>
    );
  };

  const buttonValidation = (value) => {
    let textButton;
    if (value === "upcoming") {
      textButton = "CHOOSE BANK";
    }
    // else if (["late", "due_today", "partially_paid"].includes(value)) {
    //   textButton = "PAY NOW";
    // }
    return textButton;
  };

  const contentStatus = (image, text) => {
    return (
      <Col xs={24} style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={image} alt="" />
        </div>
        <p>{text}</p>
      </Col>
    );
  };

  const contentDrawerConfirmPayment = (list) => {
    let dataContent;
    if (list.status === "upcoming") {
      dataContent = contentStatus(
        require("../../assets/images/dashboard/upcoming_loan.png").default,
        "You have an upcoming payment. Please prepare your fund. You may pay off your loan before it’s late so you don’t need to make the lenders wait."
      );
    } else if (list.status === "paid" || list.status === "late_but_paid") {
      dataContent = (
        <React.Fragment>
          <Col xs={12} style={{ marginBottom: 20 }}>
            <strong className="kw-drawerConfirm-wrapper__total-due">
              LOAN AMOUNT
            </strong>
            <strong className="kw-drawerConfirm-wrapper__details">
              <img
                src={require("../../assets/images/icon/coins.png").default}
                alt=""
              />{" "}
              {currencyRupiah(list.payment_details[0].principal, " ")}
            </strong>
          </Col>
          <Col xs={12} style={{ marginBottom: 20 }}>
            <strong className="kw-drawerConfirm-wrapper__total-due">
              INTEREST AMOUNT
            </strong>
            <strong className="kw-drawerConfirm-wrapper__details">
              {currencyRupiah(list.payment_details[0].interest, " ")}
            </strong>
          </Col>
          <Col xs={12} style={{ marginBottom: 20 }}>
            <strong className="kw-drawerConfirm-wrapper__total-due">
              LATE AMOUNT
            </strong>
            <strong className="kw-drawerConfirm-wrapper__details">
              {currencyRupiah(list.payment_details[0].late, " ")}
            </strong>
          </Col>
          <Col xs={12} style={{ marginBottom: 20 }}>
            <strong className="kw-drawerConfirm-wrapper__total-due">
              PAYOR
            </strong>
            <strong className="kw-drawerConfirm-wrapper__details">
              {payor.detail_value}
            </strong>
          </Col>
          <Col xs={12} style={{ marginBottom: 20 }}>
            <strong className="kw-drawerConfirm-wrapper__total-due">
              NUMBER OF LENDERS
            </strong>
            <strong className="kw-drawerConfirm-wrapper__details">
              {list.total_lender}
            </strong>
          </Col>
          <Col span={24} style={{ marginBottom: 20 }}>
            <strong className="kw-drawerConfirm-wrapper__total-due">
              VA ACCOUNT
            </strong>
            {virtualAccount.map((va, i) => {
              return (
                <Row key={`va-${i}`}>
                  <Col span={8}>
                    <strong className="kw-drawerConfirm-wrapper__details">
                      {va.bank_code}
                    </strong>
                  </Col>
                  <Col span={16}>
                    <strong className="kw-drawerConfirm-wrapper__details">
                      {va.account_number}
                    </strong>
                  </Col>
                </Row>
              );
            })}
          </Col>{" "}
        </React.Fragment>
      );
    } else if (list.status === "late") {
      dataContent = contentStatus(
        require("../../assets/images/dashboard/late_loan.png").default,
        "Oh no, your payment is past due. Please don’t make the lenders wait any longer; complete your payment right away."
      );
    } else if (list.status === "due_today") {
      dataContent = contentStatus(
        require("../../assets/images/dashboard/due_today_loan.png").default,
        "Your payment is due today. Please don’t make the lenders wait; pay off your loans before it’s late."
      );
    } else if (list.status === "partially_paid") {
      dataContent = undefined;
    }
    return dataContent;
  };

  return (
    <Drawer
      title=" "
      placement="bottom"
      height={500}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <Spin spinning={isShowLoading}>
        {!isShowNotFoundDetailLoan ? (
          <DrawerConfirmPaymentStyle>
            <div className="kw-drawerConfirm-wrapper">
              <div>
                <div className="kw-scheduleList-wrapper__drawerConfirm-wrapper">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>Repayment</h4>
                    {
                      <span
                        style={{
                          background: labelCondition(dataRepayment).background,
                          borderRadius: 100,
                          width: labelCondition(dataRepayment).width,
                          height: labelCondition(dataRepayment).height,
                          color: labelCondition(dataRepayment).color,
                          display: "inline-flex",
                          justifyContent: "center",
                        }}
                      >
                        {dataRepayment &&
                          convertToCategoryName(dataRepayment.status)}
                      </span>
                    }
                  </div>
                  <div className="kw-drawerConfirm-wrapper__badge-code">
                    {dataRepayment && dataRepayment.code}
                  </div>
                  <Row style={{ marginBottom: 20 }}>
                    <Col xs={12}>
                      <strong className="kw-drawerConfirm-wrapper__total-due">
                        TOTAL DUE
                      </strong>
                      <strong className="kw-drawerConfirm-wrapper__priceMounth">
                        {currencyRupiah(dataRepayment.amount, " ")}
                      </strong>
                    </Col>
                    <Col xs={12}>
                      <strong className="kw-drawerConfirm-wrapper__total-due">
                        DUE DATE
                      </strong>
                      <strong className="kw-drawerConfirm-wrapper__priceMounth">
                        <img
                          src={
                            require("../../assets/images/icon/calendar-week-enabled.png")
                              .default
                          }
                          alt=""
                        />{" "}
                        {moment(dataRepayment.due_date).format("DD MMMM YYYY")}
                      </strong>
                    </Col>
                  </Row>
                  <h5>Details</h5>
                </div>
                <Collapse
                  defaultActiveKey={[0]}
                  style={{
                    border: "unset",
                    borderBottom: "unset",
                    borderTop: "unset",
                  }}
                >
                  {
                    <Panel
                      header={contentHeaderRepayment(
                        dataRepayment,
                        dataRepayment.disbursed
                      )}
                      style={{
                        border: "unset",
                        borderBottom: "unset",
                        borderTop: "unset",
                      }}
                    >
                      <Row>{contentDrawerConfirmPayment(dataRepayment)}</Row>
                    </Panel>
                  }
                </Collapse>
                {dataRepayment &&
                dataRepayment.status === "paid" &&
                ["late", "due_today", "partially_paid"].includes(
                  dataRepayment && dataRepayment.status
                ) ? undefined : (
                  <Button
                    size="large"
                    className="kw-button"
                    onClick={() =>
                      actionChangePaymentInstructions("", dataRepayment.id)
                    }
                    style={{ width: "100%" }}
                  >
                    {buttonValidation(dataRepayment && dataRepayment.status)}
                  </Button>
                )}
                <p
                  className="kw-drawerConfirm-wrapper__close"
                  onClick={() => onClose("drawer-confirm")}
                >
                  CLOSE
                </p>
              </div>
            </div>
          </DrawerConfirmPaymentStyle>
        ) : (
          <NotFound
            image={
              require("../../assets/images/dashboard/norepayment.png").default
            }
            label={"No repayment due yet"}
            paragraph={"Repayment will show up here."}
          />
        )}
      </Spin>
    </Drawer>
  );
}
