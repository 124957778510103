import crypto from "crypto";

export function handleDecrypets(value) {
  const key = "Bhvrc3p3ivy3GqzBV62Dn2sW12s408dx";
  const encrypt = value;
  let result = "";
  let keyBytes = new Buffer(key);
  let ivBytes = Buffer.alloc(16, 0);
  let decipher =
    encrypt && crypto.createDecipheriv("aes-256-cfb", keyBytes, ivBytes);
  encrypt && decipher.write(encrypt, "base64");
  let data = encrypt &&  decipher.read();
  let cText = encrypt && data.slice(ivBytes.length, data.length);
  result = encrypt && cText.toString("utf8");
  encrypt && decipher.end();
  return result;
}
