import React from "react";
import { ApplicationProgressStyle } from "../../BorrowerDashboardKoinInvoice/style";
import { List, Card } from "antd";

export default function ApplicationProgressLimit({
  loanApplication,
  isShowLoading,
  actionChangeShowYourLoan,
  language,
  creditLimitLoan,
}) {


  let statusValue = "under_review";

  if (creditLimitLoan.status === "waiting_for_approval_ceo_coo") {
    statusValue = "waiting_for_approval_ceo_coo";
  } else if (creditLimitLoan.status === "choose_offer") {
    statusValue = "choose_offer";
  } else if (creditLimitLoan.status === "user_approved") {
    statusValue = "user_approved";
  }

  const data = [
    {
      title: language.loanDashboard.waitingForDocument,
      value: "waiting_for_document",
      amount:  loanApplication?.data?.waiting_for_document?.amount,
      action:  loanApplication?.data?.waiting_for_document?.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-documents-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-documents.png").default,
      length: loanApplication?.data?.waiting_for_document?.total,
    },
    {
      title: language.loanDashboard.underReview,
      value: statusValue,
      amount: loanApplication?.data?.under_review?.amount,
      action: loanApplication?.data?.under_review?.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/under-review-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/under-review.png").default,
      length: loanApplication?.data?.under_review?.total,
    },
    {
      title: language.loanDashboard.waitingForContract,
      value: "waiting_for_contract",
      amount: loanApplication?.data?.waiting_for_contract?.amount,
      action: loanApplication?.data?.waiting_for_contract?.action,
      imageDisabled: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-contract-disabled.png").default,
      imageActive: require("../../assets/images/dashboard-icon-koininvoice/waiting-for-contract.png").default,
      length: loanApplication?.data?.waiting_for_contract?.total,
    }
  ];

  return (
    <Card
      style={{ marginTop: 15 }}
      title={<strong>{language.loanDashboard.applicationProgress}</strong>}
    >
      <ApplicationProgressStyle>
        <List
          itemLayout="horizontal"
          dataSource={data}
          loading={isShowLoading}
          renderItem={(item) => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <img
                      src={
                        (item.status === "user_approved" ||
                        creditLimitLoan.status === item.value)
                          ? item.imageActive
                          : item.imageDisabled
                      }
                      alt=""
                    />
                  }
                  title={
                    <strong
                      style={{
                        color:
                          (item.status === "user_approved" ||
                          creditLimitLoan.status === item.value)
                            ? "#2B486D"
                            : "#8E8E8E",
                        cursor:
                          (item.status === "user_approved" ||
                          creditLimitLoan.status === item.value)
                            ? "pointer"
                            : "",
                      }}
                      onClick={
                        (item.status === "user_approved" ||
                        creditLimitLoan.status === item.value)
                          ? () =>
                              actionChangeShowYourLoan(item.value, undefined)
                          : function () {}
                      }
                    >
                      {item.title}
                      {(item.status === "user_approved" ||
                        creditLimitLoan.status === item.value) && (
                        <div
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            height: "16px",
                            width: "16px",
                            display: "inline-block",
                            marginLeft: 15,
                          }}
                        />
                      )}
                    </strong>
                  }
                />
              </List.Item>
            );
          }}
        />
      </ApplicationProgressStyle>
    </Card>
  );
}
