import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionPostResendEmail } from "../../../stores/actions/koinvoiceLoan";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Spin, Row, Col } from "antd";
import DrawerSuccessLink from "./DrawerSuccessLink";
import history from "../../../util/history";
import cookie from "react-cookies";
import koinvoiceServices from "../../../services/koinvoice";
import { capitalizeFirstLetter } from "../../../library/checkCapitalize";
import { ApplyLoanKoinVoiceStyle } from "../../style";

function SubmitResultPage({
  init: { language },
  match
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowSucessResendEmail, setIsShowSucessResendEmail] = useState(false);
  const [documentRequired, setDocumentRequired] = useState([]);
  const detailSucsess =
    cookie.load("put-loan-detail-sucsess") !== undefined &&
    cookie.load("put-loan-detail-sucsess");
  const { loan_id } =
    cookie.load("submit-detailsLoan-apply") !== undefined &&
    cookie.load("submit-detailsLoan-apply").data;
  const userId = match.params.id;
  const paramBO = JSON.parse(localStorage.getItem("user-"+userId));

  useEffect(() => {
    if(!paramBO) {
      history.push("/");
    }
    if (detailSucsess === "loan-detail-sucsess") {
      koinvoiceServices
        .documentListOld({
          params: {
            product: "koin_invoice",
            status: "not_uploaded",
            loan_id: loan_id
          },
          loading: setIsShowLoading,          
          token: paramBO?.token
        })
        .then((res) => {
          if (res.status === 200) {
            setDocumentRequired(res.data);
          } else {
            setDocumentRequired([]);
          }
        });
    } else {
      if (detailSucsess === false) {
        history.push("/koininvoice/apply-loan");
      }
    } // eslint-disable-next-line
  }, []);

  function actionGoToBackoffice() {
    localStorage.removeItem("user-"+userId);
    window.location.href = process.env.REACT_APP_BACKOFFICE_URL + "/v2.1/koininvoice-pre-assessment-loan"; 
  }

  function actionShowDrawerSucsessResendLink(e) {
    setIsShowSucessResendEmail(!isShowSucessResendEmail);
    if (e === "closeDariDrawer") {
      history.push("/dashboard-loan-application-list");
    }
    window.localStorage.removeItem("personalInfo");
    cookie.remove("loanApply", { path: "/" });
  }

  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <Button
          size="large"
          style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
          onClick={() => history.push("/koininvoice/apply-loan")}
        >
          <ArrowLeftOutlined /> {language.button.back}
        </Button>
        <div className="kw-documentUnggah-wrapper">
          <h2>{language.loanUnggahDocument.uploadDocuments}</h2>
          <Spin
            style={{ width: "100%", margin: "auto" }}
            spinning={isShowLoading}
          >
            <img
              src={require("../../../assets/images/applyloan/unggah-dokumen.png").default}
              alt=""
            />
            <h3 style={{ marginTop: 20 }}>
              {language.loanUnggahDocument.paragraphHeadingOne}
            </h3>
            <p style={{ marginTop: 20, marginBottom: 20 }}>
              {language.loanUnggahDocument.paragraphLms}
            </p>
            <p style={{ marginTop: 20 }}>
              {language.loanUnggahDocument.documentLMS}
            </p>
            {documentRequired.length > 0 &&
              documentRequired.map((list, index) => {
                return (
                  <p key={index}>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ marginRight: 10 }}
                    />
                    {capitalizeFirstLetter(list.key || list.name)}
                  </p>
                );
              })}
            <Row>
              <Col xs={24}>
                <Button
                  size="large"
                  style={{
                    width: "100%",
                    marginTop: 20,
                    border: "1px solid blue",
                    color: "blue",
                  }}
                  onClick={() => actionGoToBackoffice()}
                >
                  { language.button.toBackoffice }
                </Button>
              </Col>
            </Row>
          </Spin>
          <DrawerSuccessLink
            actionShowDrawerSucsessResendLink={
              actionShowDrawerSucsessResendLink
            }
            language={language}
            visible={isShowSucessResendEmail}
          />
        </div>
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionPostResendEmail,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitResultPage);
