import appTranslation from "../stores/utils/appTranslation.json";

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).replace(/_/g, ' ');
}

export function checkTranslate(string, language) {
    if(language.text[string]) {
      return language.text[string];
    }
    return capitalizeFirstLetter(string);
}