import styled from "styled-components";

export const FilterKoinInvoiceScheduleStyle = styled.div`
  .kw-filterKoinInvoiceSchedule-wrapper {
    display: flex;
    justify-content: flex-start;
    .kw-card-menu {
      background: #ffffff;
      padding: 25px;
    }
    &__button-filter {
      cursor: pointer;
      margin-right: 25px;
    }
  }
`;
