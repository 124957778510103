import { createGlobalStyle } from "styled-components";
// import fontBold from "../fonts/HelveticaLTStd-Bold.WOFF";
// import fontCond from "../fonts/HelveticaLTStd-BoldCond.WOFF";
// import fontLight from "../fonts/HelveticaLTStd-Light.WOFF";
// import fontRoman from "../fonts/HelveticaNeueLTStd-Roman.WOFF";

// import bgcontent from "../images/bgcontent.png";

const GlobalStyle = createGlobalStyle`

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  html, body {
    height: 100%;
    min-height: 100%;
  }
  a {
    text-decoration: none;
    outline: none !important;
  }
  input {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }
  h2 {
    &.title-head {
      font-family: 'Source Sans Pro';
      font-weight: 600;
      font-size: 34px;
      color: #2B486D !important;
    }
  }
  .chooseLanguage {
    .nav-item {
      a {
        width: 55px;
        height: 32px;
        text-align: center;
        line-height: 16px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;

        &.active {
          background: #2B486D;
          font-weight: bold;
          color: #fff !important;
        }
      }
    }
  }
  .main-navbar {
    box-shadow: 0px 16px 24px rgba(8,35,48,0.16);
    -webkit-box-shadow: 0px 16px 24px rgba(8,35,48,0.16);
    -moz-box-shadow: 0px 16px 24px rgba(8,35,48,0.16);
    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    z-index: 999999 !important;
  }
  .dashboard-sidebar {
    top: 64px !important;
    background: #fff !important;
    
    h3 {
      margin-left: 15px;
      margin-right: 15px;
      font-family: 'Source Sans Pro';
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      color: #8E8E8E;
    }
    .sidenav---sidenav-toggle---1KRjR {
      .sidenav---icon-bar---u1f02 {
        background-color: #566068 !important; 
      }
    }
    .sidenav---navitem---9uL5T {
      background: #D9EFDF;

      .fa {
        line-height: 48px !important;
        color: #566068 !important; 
      }
      
      .sidenav---navtext---1AE_f {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 18px;
        color: #566068 !important; 
      }
    }
  }
  .main-content {
    width: 100%;
    padding-left: 240px;
    min-height: calc(100vh - 64px);
    background: #f2f2f2;
    overflow: scroll;

    .inner-content {
      padding: 50px 25px;
    }
  }
  .profile-dropdown {
    top: -5px;
    padding: 0 50px;
    position: relative;

    .dropdown-toggle {
      &:after {
        top: 5px;
        margin: 0 10px;
        font-family:"Font Awesome 5 Free";
        font-weight: 900;
        border-top: none;
        display: inline-block;
        content: '\f078';
        position: relative;
      }
    }

    .dropdown-menu {
      right: 0;
      padding: 0 !important;
      background: #FFFFFF;
      font-family: 'Source Sans Pro';
      font-weight: 300;
      font-size: 17px;
      border: 1px solid rgba(0, 0, 0, 0.100241);
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);

      a {
        height: 50px;
        font-family: 'Source Sans Pro';
        font-weight: 300;
        font-size: 17px;
        line-height: 38px;
        text-align: center;
        
        &:active, &:hover, &:focus {
          background: #2B486D;
          color: #fff;
          border: 1px solid #2B486D;
        }
      }
    }
  }
  .koinworksLogo {
      width: 245px;
      height: 38px;
      margin: 0 auto;
      display: block;
  }
  .btn {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
    }
  }
  /* .btn-primary {
      font-family: 'Source Sans Pro';
      font-weight: 600;
      font-size: 17px;
      background: #2B486D !important;
      border-color: #2B486D !important;
      padding: 10px 25px !important;

      &:hover, &:focus, &:active {
        background: #2B486D !important;
        border-color: #2B486D !important;
      }
  } */
  @media only screen and (max-width: 991px) {
    .main-content {
      padding-left: 0;

      .inner-content {
        padding: 50px 5px;

        .fluid-container {
          margin: 0 15px !important;
        }
      }
    }
    .profile-dropdown {
      padding: 0 !important;

      .dropdown-menu {
        margin: 20px 0;
        
        a {
          text-align: left;
        }
      }
    }
    .chooseLanguage {
      display: inline-block;

      .nav-item {
        display: inline-block;
      }
    }
  }

















`;

export default GlobalStyle;
