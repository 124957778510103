import React, { useState } from "react";
import { Radio, Drawer, List, Button } from "antd";
import { DrawerShortKoinInvoiceStyle } from "../DrawerShortKoinInvoice/style";

function DrawerFilterKoinInvoice({
  onClose,
  visible,
  isShow = false,
  documentLoanType,
}) {
  const [parameterFilter, setParameterFilter] = useState({
    filter: documentLoanType ? documentLoanType : undefined,
  });
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const actionChangeFilter = (e) => {
    setParameterFilter({
      ...parameterFilter,
      filter: e.target.value,
    });
  };

  return (
    <Drawer
      title=" "
      placement={"bottom"}
      closable={false}
      onClose={onClose}
      height={444}
      visible={visible}
    >
      <DrawerShortKoinInvoiceStyle>
        <div className="kw-filterKoinInvoice-wrapper">
          <List
            size="large"
            header={
              <h3 style={{ display: "block", textAlign: "center" }}>FILTER</h3>
            }
            bordered
          >
            <Radio.Group
              onChange={actionChangeFilter}
              value={parameterFilter.filter}
            >
              {" "}
              <List.Item>
                <Radio style={radioStyle} value={undefined}>
                  All Status
                </Radio>{" "}
              </List.Item>{" "}
              {isShow === false && (
                <List.Item>
                  <Radio style={radioStyle} value={"waiting_for_document"}>
                    Waiting For Document
                  </Radio>{" "}
                </List.Item>
              )}
              <List.Item>
                <Radio
                  style={radioStyle}
                  value={isShow === false ? "funding_process" : "due_to_day"}
                >
                  {isShow === false ? "Funding Process" : "Due to day"}
                </Radio>
              </List.Item>
              <List.Item>
                <Radio
                  style={radioStyle}
                  value={
                    isShow === false ? "waiting_for_disbursement" : "upcoming"
                  }
                >
                  {isShow === false ? "  Disbursement Process" : "Upcoming"}
                </Radio>
              </List.Item>
              <List.Item>
                <Radio
                  style={radioStyle}
                  value={isShow === false ? "under_review" : "late"}
                >
                  {isShow === false ? "Under Review" : "Late"}
                </Radio>{" "}
              </List.Item>
              <List.Item>
                <Radio
                  style={radioStyle}
                  value={isShow === false ? "choose_offer" : "partially_paid"}
                >
                  {isShow === false ? "Choose Offer" : "Partially Paid"}
                </Radio>{" "}
              </List.Item>
              <List.Item>
                <Radio
                  style={radioStyle}
                  value={isShow === false ? "waiting_for_contract" : "paid"}
                >
                  {isShow === false ? "Waiting For Contract" : "Paid"}
                </Radio>
              </List.Item>{" "}
              {isShow === false && (
                <React.Fragment>
                  <List.Item>
                    <Radio style={radioStyle} value={"go_to_disbursement"}>
                      Go to disbursement
                    </Radio>
                  </List.Item>{" "}
                  <List.Item>
                    <Radio style={radioStyle} value={"on_going"}>
                      On Going
                    </Radio>
                  </List.Item>{" "}
                  <List.Item>
                    <Radio style={radioStyle} value={"due_this_month"}>
                      Due this month
                    </Radio>
                  </List.Item>{" "}
                  <List.Item>
                    <Radio style={radioStyle} value={"paid_off"}>
                      Paid Off
                    </Radio>
                  </List.Item>
                  <List.Item>
                    <Radio style={radioStyle} value={"rejected"}>
                      Rejected
                    </Radio>
                  </List.Item>{" "}
                  <List.Item>
                    <Radio style={radioStyle} value={"funding_not_eligible"}>
                      Funding Not Eligible
                    </Radio>
                  </List.Item>{" "}
                  <List.Item>
                    <Radio style={radioStyle} value={"canceled"}>
                      Canceled
                    </Radio>
                  </List.Item>
                </React.Fragment>
              )}
            </Radio.Group>
          </List>
          <Button
            className="kw-button"
            style={{ marginTop: 15 }}
            size="large"
            onClick={() => onClose("apply", parameterFilter)}
          >
            APPLY
          </Button>
          <p onClick={() => onClose("cancel")}>CANCEL</p>
        </div>
      </DrawerShortKoinInvoiceStyle>
    </Drawer>
  );
}

export default DrawerFilterKoinInvoice;
