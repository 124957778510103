import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPrimaryBank, putPrimaryBank } from "../../../stores/actions/account";
import Select from "@appgeist/react-select-material-ui";
// import { Table } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";

import DisburseSubmit from "../../../assets/images/dashboard/disbursesubmit.png"


class DisburseComp extends Component {
  state = {
    name: '',
    code: '',
    branch: '',
    account_number: '',
    account_under_name: ''
  }

  handleChange = ({ target }) => {
    let _state = this.state;
    _state[target.name] = target.value;
    this.setState(_state);
  }

  handleSelectChange = option => {
    this.setState({
      name: option.label,
      code: option.value
    });
  }

  handleSubmit = () => {
    let { auth, account:{ bankInfo } } = this.props;
    let data = {
      id: bankInfo ? bankInfo.id : '',
      name: this.state.name,
      code: this.state.code,
      branch: this.state.branch,
      account_number: this.state.account_number,
      account_under_name: this.state.account_under_name
    }
    this.props.putPrimaryBank(data, auth.token);
  }

  componentDidMount() {
    let { auth } = this.props;
    this.props.getPrimaryBank(auth.token);
  }

  render() {
    let { account, onClose, init:{ language } } = this.props;
    let bankInfo = this.state;
    if (account.bankInfo) {
      bankInfo.name = bankInfo.name || account.bankInfo.name;
      bankInfo.code = bankInfo.code || account.bankInfo.code;
      bankInfo.branch = bankInfo.branch || account.bankInfo.branch;
      bankInfo.account_number = bankInfo.account_number || account.bankInfo.account_number;
      bankInfo.account_under_name = bankInfo.account_under_name || account.bankInfo.account_under_name;
    }

    return (
      <>
        {/* success */}
        {account.isPutBankSuccess ? (
          <div className="side-modal-wrapper offer">
            <div className="top">
              <div className="heading">
                <div className="title">{language.text.successfullySubmitted}</div>
              </div>
              <div className="modal-img-wrapper">
                <figure className="modal-img mb-4">
                  <img src={DisburseSubmit} alt="offer" />
                </figure>
                <p className="side-modal-text w-75">{language.text.thankForSubmission}</p>
              </div>
            </div>
            <div className="bottom text-center">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClose}>{language.button.close}</div>
            </div>
          </div>
        ) : (
          <div className="side-modal-wrapper offer disburse">
            <div className="top">
              <div className="heading">
                <div className="title">{language.title.disbursementBankAccount}</div>
                <div className="text">{language.subtitle.disbursementBankAccount}</div>
              </div>

              <div className="disburse-form">
                <form noValidate autoComplete="off">
                  <TextField
                    id="account_owner"
                    name="account_under_name"
                    label="account owner"
                    value={bankInfo.account_under_name}
                    onChange={this.handleChange}
                    variant="outlined" />
                  <Select
                    id="name"
                    name="name"
                    label="Bank"
                    value={{ value:bankInfo.code, label:bankInfo.name}}
                    onChange={this.handleSelectChange}
                    options={[
                      { value:"BCA", label: "BANK CENTRAL ASIA (BCA)"},
                      { value: "MANDIRI", label: "BANK MANDIRI" }
                    ]}
                    variant="outlined" />
                  <TextField
                    id="branch"
                    name="branch"
                    label="Bank Branch"
                    value={bankInfo.branch}
                    onChange={this.handleChange}
                    variant="outlined" />
                  <TextField
                    id="account_number"
                    name="account_number"
                    label="Account Number"
                    value={bankInfo.account_number}
                    onChange={this.handleChange}
                    variant="outlined" />
                </form>
              </div>

            </div>
            <div className="bottom text-right">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClose}>{language.button.back}</div>
              <div className="btn-modal-proceed" onClick={this.handleSubmit}>{language.button.selectContinue}</div>
            </div>
          </div>
        )}

      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getPrimaryBank, putPrimaryBank
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DisburseComp);