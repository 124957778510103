import styled from "styled-components";

export const YourLoanStyle = styled.div``;

export const CardYourLoanStyle = styled.div`
  .kw-cards-wrapper {
    background: #8e8e8e;
    border-radius: 3px;
    padding-left: 10px;
  }
  .kw-cardYourLoans-wrapper {
    margin-top: 15px;
    background: #ffffff;
    border: 0.25px solid #cccccc;
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
    border-radius: 3px;
  }
`;
