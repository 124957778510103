import React, { useState, useEffect } from "react";
import { Alert, Spin, Divider, Radio } from "antd";
import TextField from "@material-ui/core/TextField";
import Select from "@appgeist/react-select-material-ui";
import CurrencyFormatInput from "../../../Components/Form/currencyFormatInput";

export default function PrincipalCodeLoan({
  flag,
  language,
  handleChangeCodeLoan,
  handleChangeSubmitPrincipal,
  koinvoice,
  principalCode,
  isShowLoading,
  isDisabledButton,
  userId,
  code,
  isMobile,
  laCode,
  errlaCode
}) {
  let businessInformation =
    JSON.parse(localStorage.getItem(`bisInfo-${userId}`)) !== null &&
    JSON.parse(localStorage.getItem(`bisInfo-${userId}`));

  let businessType = 
    businessInformation?.details?.company_detail?.business_type?.value || '';

  const addData = JSON.parse(localStorage.getItem(`add-data-${userId}`)) || null;
  const [requestType, setRequestType] = useState("normal");
  const [loanTerm, setLoanTerm] = useState(addData ? addData.loan_term : 0);
  const [loanPurpose, setLoanPurpose] = useState(addData ? addData.loan_purpose : "");
  const [limitRequest, setLimitRequest] = useState(addData ? addData.limit_amount : 0);
  const [errLoanTerm, setErrLoanTerm] = useState(!loanTerm && true);
  const [errLoanPurpose, setErrLoanPurpose] = useState(!loanPurpose && true);
  const [errLimitRequest, setErrLimitRequest] = useState(!limitRequest && true);
  const [errLimitMessage, setErrLimitMessage] = useState(language.text.cantBeEmpty);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [borrowerName, setBorrowerName] = useState(
    addData?.borrower_name ||
    businessInformation?.details?.business.company_detail?.president_director_name?.value ||
    businessInformation?.details?.business.company_detail?.active_persero_name?.value ||
    businessInformation?.details?.business.company_detail?.business_principal_fullname?.value ||
    businessInformation?.details?.business.company_detail?.owner_name?.value ||
    businessInformation?.fullname
  ); 
  const [errBorrowerName, setErrBorrowerName] = useState(!borrowerName && true);

  const optTerm = [
    {value: 6, label: `6 ${language.label.months}`},
    {value: 12, label: `12 ${language.label.months}`}
  ];

  const optPurpose = [
    {value: "bridging_loan", label: language.label.bridgingLoan},
    {value: "working_capital", label: language.label.workingCapital},
    {value: "expansion", label: language.label.expansion},
    {value: "other", label: language.label.other}
  ];

  useEffect(() => {
    if(isFirstTime && businessInformation) {
      setBorrowerName(
        addData?.borrower_name  ||
        businessInformation?.details?.business.company_detail?.president_director_name?.value ||
        businessInformation?.details?.business.company_detail?.active_persero_name?.value ||
        businessInformation?.details?.business.company_detail?.business_principal_fullname?.value ||
        businessInformation?.details?.business.company_detail?.owner_name?.value ||
        businessInformation?.fullname
      )

      setIsFirstTime(false);
    }
  }, [businessInformation]);

  function handleChangeType(e) {
    if(e.target.value) {
      setRequestType(e.target.value);
    }
    if (e.target.value === 'normal' && 
      limitRequest > 2000000000) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.maximumLimitRequest);
    } else if (e.target.value === 'special' && 
      limitRequest > 2000000000) {
      setErrLimitRequest(false);
      setErrLimitMessage("");
    }
  }
  
  function handleChangeTerm(val) {
    if(val) {
      setLoanTerm(val.value);
      setErrLoanTerm(false);
    } else {
      setLoanTerm(null);
    }
  }

  function handleChangePurpose(val) {
    if (val) {
      setLoanPurpose(val.value);
      setErrLoanPurpose(false);
    } else {
      setLoanPurpose("");
    }
  }

  function handleChangeLimit(value) {
    setLimitRequest(value);
    if(!value|| value === 0){
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.cantBeEmpty);
    } else if (value < 0) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.minimumLimitRequest);
    } else if (value % 100000 !== 0) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.moduloHundredThousand);
    } else if (requestType === 'normal' && value > 2000000000) {
      setErrLimitRequest(true);
      setErrLimitMessage(language.text.maximumLimitRequest);
    } else {
      setErrLimitRequest(false);
      setErrLimitMessage("");
    }
  }

  function handleChangeBorrower(val) {
    setBorrowerName(val.target.value);
    if(val.target.value === "")
      setErrBorrowerName(true);
    else
    setErrBorrowerName(false);
  }

  function handleSubmit() {
    if(process.env.REACT_APP_LA_CODE_SWITCHER === "true") {
      if (!errLoanPurpose && !errLoanTerm && !errLimitRequest && !errlaCode) {
        localStorage.setItem(`add-data-${userId}`, 
          JSON.stringify({
            loan_term: loanTerm,
            loan_purpose: loanPurpose,
            limit_amount: limitRequest,
            borrower_name: borrowerName,
            type: requestType,
            sub_product: "Supply Chain Financing",
            code: code,
            referral: principalCode.principal_name,
            principal_name: principalCode.principal_name,
            pic_position: principalCode.principal_contact_position,
            contact_person: principalCode.principal_contact_name,
            la_code: laCode
          })
        );
        handleChangeSubmitPrincipal();
      }
    } else {
      if (!errLoanPurpose && !errLoanTerm && !errLimitRequest) {
        localStorage.setItem(`add-data-${userId}`, 
          JSON.stringify({
            loan_term: loanTerm,
            loan_purpose: loanPurpose,
            limit_amount: limitRequest,
            borrower_name: borrowerName,
            type: requestType,
            sub_product: "Supply Chain Financing",
            code: code,
            referral: principalCode.principal_name,
            principal_name: principalCode.principal_name,
            pic_position: principalCode.principal_contact_position,
            contact_person: principalCode.principal_contact_name,
            la_code: laCode
          })
        );
        handleChangeSubmitPrincipal();
      }
    }
  }

  return (
    <React.Fragment>
      <h5>{language.loanKoinVoice.labelLoanCodePrincipal}</h5>
      <div>
        {" "}
        <TextField
          label={language.loanKoinVoice.principalCode}
          variant="outlined"
          name="principal_code"
          onChange={handleChangeCodeLoan}
          type="text"
          style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
        />
      </div>
      {koinvoice.isShowPrincipalAlert ? (
        <React.Fragment>
          <div>
            <TextField
              disabled
              label={language.loanKoinVoice.principalName}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={principalCode && principalCode.principal_name}
              name="principal_name"
              variant="outlined"
            />
          </div>{" "}
          <div>
            <TextField
              disabled
              label={"Principal PIC JOB POSITION"}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={principalCode && principalCode.principal_contact_position}
              name="principal_contact_position"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              disabled
              label={language.loanFormKoinvoice.contactPersonDetails}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={
                principalCode && principalCode.principal_contact_name
              }
              name="principal_contact_name"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              disabled
              label={language.loanFormKoinvoice.phoneNumberDetails}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={
                principalCode && principalCode.principal_contact_phone
              }
              name="principal_contact_phone"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              disabled
              label={language.loanFormKoinvoice.emailAddressDetails}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 20 }}
              value={
                principalCode && principalCode.principal_contact_email
              }
              name="principal_contact_email"
              variant="outlined"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              disabled={businessType === 'home_business' || businessType === 'individual'}
              label={language.form.borrowerName}
              value={businessInformation && borrowerName}
              onChange={handleChangeBorrower}
              variant="outlined"
              type="text"
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
              error={!borrowerName && errBorrowerName}
              helperText={errBorrowerName && language.text.cantBeEmpty}
            />
          </div>
          <Divider orientation="left">{language.label.limitInfo}</Divider>
          <div className="type-wrapper" style={{ marginTop: 20 }}>
            <Radio.Group 
              value={requestType}
              buttonStyle="solid"
              size="large"
              onChange={handleChangeType}
            >
              <Radio.Button value="normal">Normal</Radio.Button>
              <Radio.Button value="special">Special</Radio.Button>
            </Radio.Group>
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              label={language.form.limitRequest}
              value={limitRequest}
              variant="outlined"
              type="text"
              onChange={handleChangeLimit}
              error={!limitRequest || errLimitRequest}
              helperText={errLimitRequest ? errLimitMessage : ""}
              style={{ width: isMobile() ? "50%" : "100%", marginTop: 10 }}
              InputProps={{
                inputComponent: CurrencyFormatInput
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              id="loanTerm"
              name="loanTerm"
              label={language.form.loanTermLms}
              placeholder={language.form.chooseTermLms}
              value={
                optTerm.filter(option => 
                   option.value === loanTerm)
              }
              options={optTerm}
              onChange={handleChangeTerm}
              error={!loanTerm && errLoanTerm}
              helperText={errLoanTerm ? language.text.cantBeEmpty : ""}
              style={{ width: "50%", marginTop: 10 }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              id="loanPurpose"
              name="loanPurpose"
              label={language.form.loanPurpose}
              placeholder={language.form.chooseLoanPurpose}
              value={
                optPurpose.filter(option => 
                   option.value === loanPurpose)
              }
              options={optPurpose}
              onChange={handleChangePurpose}
              error={!loanPurpose && errLoanPurpose}
              helperText={errLoanPurpose ? language.text.cantBeEmpty : ""}
              style={{ width: "50%", marginTop: 10 }}
            />
          </div>
        </React.Fragment>
      ) : (
        koinvoice.principalError && (
          <Alert
            message={koinvoice.principalError.data.message[flag]}
            style={{ width: isMobile() ? "40%" : "100%", marginTop: 20 }}
            type="error"
          />
        )
      )}
      <Spin spinning={isShowLoading}>
        <button
          className="btn btn-primary w-100"
          style={{ marginTop: 20 }}
          onClick={handleSubmit}
          disabled={koinvoice.isEnabledButton}
        >
          {language.button.next}
        </button>
      </Spin>
    </React.Fragment>
  );
}
