import { scheduleService } from "../../services/schedule";

export const filterSchedule = action => {
  return {
    type: "FILTER_SCHEDULE",
    ...action
  }
}

export const showScheduleDetail = action => {
  return {
    type: "SHOW_SCHEDULE_DETAIL",
    ...action
  }
}

const getScheduleSuccess = data => {
  return {
    type: "GET_SCHEDULE_SUCCESS",
    payload: data
  }
}

const getScheduleFailed = errors => {
  return {
    type: "GET_SCHEDULE_FAILED",
    payload: errors
  }
}

export const getSchedule = (page, sort, token) => (dispatch) => {
  scheduleService.getSchedule(page, sort,token)
    .then(res => {
      if (res.data) {
        dispatch(getScheduleSuccess(res.data));
      } else {
        dispatch(getScheduleFailed(res.message));
      }
    },
    error => {
      dispatch(getScheduleFailed(error));
  });
}

export const getLoanSchedule = (id, token) => (dispatch) => {
  scheduleService.getLoanSchedule(id,token)
    .then(res => {
      dispatch(getLoanScheduleSuccess(res.data));
    },
    error => {
      dispatch(getLoanScheduleFailed(error));
  });
}

const getLoanScheduleSuccess = data => {
  return {
    type: "GET_LOAN_SCHEDULE_SUCCESS",
    payload: data
  }
}

const getLoanScheduleFailed = errors => {
  return {
    type: "GET_LOAN_SCHEDULE_FAILED",
    payload: errors
  }
}

export const schedulePayment = (loanId, scheduleId, status, token) => (dispatch) => {
  scheduleService.checkPaymentBalance(loanId,token)
    .then(res => {
      if (res.data.enough_cash) {
        dispatch(requestPayment(
          loanId,
          scheduleId,
          res.data.loan_remaining_balance,
          status,
          token
        ));
      } else {
        dispatch(balanceInsufficient(res.data.loan_remaining_balance))
      }
    },
    error => {
      dispatch(paymentBalanceCheckFailed(error));
  });
}

export const requestPayment = (loanId, scheduleId, amount, status, token) => (dispatch) => {
  scheduleService.requestPayment(loanId, scheduleId, amount, status, token)
    .then(res => {
      if (res.status === 200) {
        dispatch(paymentSuccess());
      } else {
        dispatch(paymentFailed(res.message))
      }
    },
    error => {
      dispatch(paymentFailed(error));
  });
}

export const confirmPayment = action => {
  return { type: "CONFIRM_PAYMENT", ...action }
}

const balanceInsufficient = data => {
  return { type: "PAYMENT_BALANCE_INSUFFICIENT", payload: data }
}

const paymentBalanceCheckFailed = error => {
  return { type: "PAYMENT_BALANCE_INSUFFICIENT", payload: error }
}

const paymentSuccess = () => {
  return { type: "SCHEDULE_PAYMENT_SUCCESS" }
}

const paymentFailed = errors => {
  return { type: "SCHEDULE_PAYMENT_FAILED", payload: errors }
}