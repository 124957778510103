import React, { useCallback } from "react";
import { Modal, Button, Spin } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { UploadKtpStyle } from "./style";
import {
  uploadSelfieKtpBusinessKoinVoice,
  uploadSelfieKtpShareHolderBusinessKoinVoice,
} from "../../stores/actions/lmsLimit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const allowedFiles = ["image/png", "image/jpeg", "image/jpg"];

function UploadSelfieKtpModal({
  visible,
  handleChangeShowUploadSelfieKtp,
  uploadSelfieKtpBusinessKoinVoice,
  uploadSelfieKtpShareHolderBusinessKoinVoice,
  init: { language },
  koinvoice: { upload, uploadShareHolder },
  auth,
  setIsShowAlertMatchKtp,
  setIsShowAlertUploadMatchKtp,
  setFieldValue,
  setSelfieKtp,
  isLoading,
  shareHolder = false,
  validationMatchImageKtp,
  businessInformation,
  setValidationMatchImageKtp,
  validationMatchImageKtpFailed,
  setValidationMatchImageKtpFailed,
  token
}) {
  // eslint-disable-next-line
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      actionChangeSelfieKtpUpload(file, setFieldValue);
      setSelfieKtp(file.path);
    });
  });

  function actionChangeSelfieKtpUpload(values) {
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email:
          auth.email ||
          (businessInformation && businessInformation.email),
      };
      if (shareHolder) {
        uploadSelfieKtpShareHolderBusinessKoinVoice(
          params,
          uploadShareHolder.data && uploadShareHolder.data[0],
          handleChangeShowUploadSelfieKtp,
          setFieldValue,
          setValidationMatchImageKtp,
          validationMatchImageKtp,
          setValidationMatchImageKtpFailed,
          validationMatchImageKtpFailed,
          setIsShowAlertUploadMatchKtp,
          setIsShowAlertMatchKtp,
          token
        );
      } else {
        uploadSelfieKtpBusinessKoinVoice(
          params,
          upload.data && upload.data[0],
          handleChangeShowUploadSelfieKtp,
          setFieldValue,
          setValidationMatchImageKtp,
          validationMatchImageKtp,
          setValidationMatchImageKtpFailed,
          validationMatchImageKtpFailed,
          setIsShowAlertUploadMatchKtp,
          setIsShowAlertMatchKtp,
          token
        );
      }
    };
    fileReader.readAsDataURL(values);
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    onDrop: onDrop,
    accept: allowedFiles,
  });

  let validationImage = require("../../assets/images/applyloan/selfie-picture-ktp.png").default;
  let validationHeading = language.modalUpload.headingKtp;
  let validationParagraph = language.modalUpload.paragraphKtpOne;
  let validationButtonUpload = language.modalUpload.uploadButton;

  if (validationMatchImageKtp === 1 || validationMatchImageKtp === 2) {
    validationHeading = language.modalUpload.headingSelifeKtpError;
    validationImage = require("../../assets/images/applyloan/selfie-img.png");
    validationParagraph = language.modalUpload.paragraphSelfieKtpError;
    validationButtonUpload = language.modalUpload.uploadButtonSelfieKtp;
  } else if (validationMatchImageKtp === 3) {
    validationHeading = language.modalUpload.headingSelfieKtpFailed;
    validationParagraph = language.modalUpload.paragraphSelfieFailed;
    validationImage = require("../../assets/images/notfound.png");
  }
  let buttonUploadEndpoint;
  if (
    validationMatchImageKtp === 0 ||
    validationMatchImageKtp === 1 ||
    validationMatchImageKtp === 2
  ) {
    buttonUploadEndpoint = (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Spin spinning={isLoading}>
          <Button type="button" className="kw-button" onClick={open}>
            {validationButtonUpload}
          </Button>
        </Spin>
      </div>
    );
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleChangeShowUploadSelfieKtp}
      footer={null}
    >
      <UploadKtpStyle>
        <div className="kw-uploadKtp">
          <img
            src={validationImage}
            style={{ width: 150, margin: "auto" }}
            alt=""
          />
          <h5>
            {
              /* {validationMatchImageKtp === 1 ||
            validationMatchImageKtp === 2 ||
            validationMatchImageKtp === 3
              ? `${validationHeading} (${validationMatchImageKtp})`
              : validationHeading} */ validationHeading
            }
          </h5>
          <p>{validationParagraph}</p>
          {validationMatchImageKtp === 0 && (
            <React.Fragment>
              <p>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {language.modalUpload.paragraphSelfieKtpTwo}
              </p>
              <p>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {language.modalUpload.paragraphSelfieKtpThree}
              </p>
              <p>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 10 }}
                />
                {language.modalUpload.paragraphSelfieKtpFour}
              </p>
            </React.Fragment>
          )}
          {/* {uploadKtpError && (
            <Alert
              message={`${uploadKtpError[0]} (${validationMatchImageKtp})`}
              style={{ marginBottom: 10 }}
              type="error"
            />
          )} */}
          {validationMatchImageKtp === 3 && (
            <Button type="button" onClick={handleChangeShowUploadSelfieKtp}>
              {language.modalUpload.uploadButtonClose}
            </Button>
          )}
          {buttonUploadEndpoint}
        </div>
      </UploadKtpStyle>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  auth: state.auth,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadSelfieKtpBusinessKoinVoice,
      uploadSelfieKtpShareHolderBusinessKoinVoice,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadSelfieKtpModal);
