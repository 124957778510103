import React, { useEffect } from 'react';

function SettingsBank(props) {
    console.log(window.location.href);
    useEffect(()=>{
        const urlObj = new URL(window.location.href);
      console.log(urlObj);
        const loginLinkCode = urlObj.searchParams.get("access");
        const loginLinkLoanId = urlObj.searchParams.get("loan_id");
        console.log(loginLinkCode);
        console.log(loginLinkLoanId);
        if (!loginLinkCode) {
          return;
        }
        const authInfo = atob(atob(loginLinkCode));   
        console.log("authInfo",authInfo);
    })
    return (
        <div>
            asds
        </div>
    );
}

export default SettingsBank;