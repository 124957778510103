let currencyFormatter = require("currency-formatter");

const currencyRupiah = (price,currency,symbol = ".") => {
  return currencyFormatter.format(price, {
    symbol: currency,
    decimal: ',',
    thousand: symbol,
    precision: 2,
    //format: "%v %s" // %s is the symbol and %v is the value
  });
};

export default currencyRupiah;
