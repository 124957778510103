import React, { Component } from "react";
import referImg from "../../assets/images/dashboard/refer.png";

class ReferFriend extends Component {
  render() {
    let { language } = this.props;

    return (
      <>
        <div className="card dash-card">
          <h3 className="title-bar">{language.title.referFriend}</h3>
          <div className="card-body">
            <div className="card-content">
              <div className="top">
                <div className="notfound-wrapper">
                  <figure className="dashboard-img" style={{ marginTop: "60px" }}>
                    <img src={referImg} alt="refer a friend" />
                  </figure>
                  <p className="text">{language.subtitle.referFriend}</p>
                </div>
              </div>
              <div className="bottom">
                <div className="btn btn-primary-outline w-100">{language.button.referNow}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReferFriend;