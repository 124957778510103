import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import FileBase64 from "react-file-base64";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Spinner } from "react-bootstrap";
import "react-phone-input-2/lib/bootstrap.css";
import DatePickerInput from "../../Components/Form/datePickerInput";
import SimpleTextInput from "../../Components/Form/simpleTextInput";
import SimpleSelectInput from "../../Components/Form/simpleSelectInput";
import PhoneNumberInput from "../../Components/Form/phoneNumberInput";
import { StepInfoWrapper } from "./StepInfo.styles";
import KtpModal from "./Components/KtpModal";
import SelfieKtpModal from "./Components/SelfieKtpModal";
import ConfirmationModal from "./Components/ConfirmationModal";
import {
  getDistrict,
  getSubdistrict,
  clearDistrictList,
  clearSubdistrictList
} from "../../stores/actions/loan";
import {  
  uploadKTP,
  uploadSelfie,
  uploadSpouseKTP,
  putPersonalProfile,
  putLegalProfile,
  resetUploadFile
} from "../../stores/actions/account"
import { personalParams, legalParams } from "../common/convertParams";
import * as validate from "../../services/validate";

const SelectContainer = props => {
  return <div onKeyUp={props.inputChange}>{props.children}</div>;
};

class StepInfo extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    personal: {
      ktp: "",
      selfi_ktp: "",
      spouse_ktp: "",
      nik: "",
      name: "",
      pob: "",
      dob: "",
      gender: "",
      marital_status: "",
      religion: "",
      npwp: "",
      address: "",
      district: "",
      subdistrict: "",
      current_address: "",
      current_district: "",
      current_subdistrict: "",
      phone: "",
      email: ""
    },
    filename: {
      ktp: "",
      selfi_ktp: "",
      spouse_ktp: ""
    },
    checkedBox: false,
    district_code: "",
    city_code: "",
    show: false,
    show1: false,
    showConfirm: false,
  };

  // modal
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  handleClose1 = () => this.setState({ show1: false });
  handleShow1 = () => this.setState({ show1: true });
  handleCloseConfirm = () => this.setState({ showConfirm: false });

  //image uploader
  imageChange = name => file => {
    let { personal, filename } = this.state;
    let { upload, auth } = this.props;
    this.props.resetUploadFile(name);
    let data = {
      base64: [file.base64],
      email: auth.email
    };
    personal[name] = URL.createObjectURL(file.file);
    filename[name] = file.name;
    this.setState({ personal, filename });

    if (name === "ktp") {
      this.setState({ show: false });
      this.props.uploadKTP(data);
    } else if (name === "selfi_ktp") {
      this.setState({ show1: false });
      this.props.uploadSelfie(data, upload.ktp[0]);
    } else if (name === "spouse_ktp") {
      this.props.uploadSpouseKTP(data);
    }
  };

  resetFile = name => event => {
    event.preventDefault();
    let { personal, filename } = this.state;
    personal[name] = filename[name] = null;
    this.setState({ personal, filename });
    this.props.resetUploadFile(name);
    if(name === "selfi_ktp") this.handleShow1();
    else this.handleShow();
  };

  capitalize = str => {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  handleSubmit = () => {
    this.setState({ showConfirm: true })
  };

  handleConfirmed = () => {
    let { auth } = this.props;
    let personalData = personalParams(this.state);
    let legalData = legalParams(this.state, this.props);
    this.props.putPersonalProfile(personalData, auth.token);    
    this.props.putLegalProfile(legalData, auth.token);
  }

  // checkbox
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleRadioChange = name => ({ target }) => {
    let { personal } = this.state;
    personal[name] = target.value;
    this.setState({ personal });
  };

  onInputChange = name => e => {
    let { personal } = this.state;
    personal[name] = e.target.value;
    this.setState({ personal });
  };

  onPhoneChange = name => (data, value) => {
    let { personal } = this.state;
    personal[name] = value;
    this.setState({ personal });
  };

  handleDateChange = name => date => {
    let { personal } = this.state;
    personal[name] = date;
    this.setState({ personal });
  };

  updateSelectVal = name => val => {
    let { personal, district_code } = this.state;
    if (val) {
      personal[name] = val.value;
      if (name === "district" || name === "current_district") {
        district_code = val.data;
        this.props.clearDistrictList();
      } else {
        this.props.clearSubdistrictList();
      }
    }
    this.setState({ personal, district_code });
  };

  inputValueChange = ({ target }) => {
    let term = target.value;
    let { district_code } = this.state;
    if (target.id === "district" || target.id === "current_district") {
      if (term.length >= 2) this.props.getDistrict(term);
    } else {
      if (term.length >= 1) this.props.getSubdistrict(district_code, term);
    }
  };

  static getDerivedStateFromProps(props, state) {
    let { personal, location, currentLoc, contact, ktp, selfieKtp } = props.account.profile;
    let _personal = state.personal;
    return {
      personal: {
        nik: !_personal.nik && personal.nik ? personal.nik.value : _personal.nik,
        name: !_personal.name && personal.name ? personal.fullname.value : _personal.name,
        pob: !_personal.pob && personal.pob ? personal.place_of_birth.value : _personal.pob,
        dob: !_personal.dob && personal.dob ? personal.date_of_birth.value : _personal.dob,
        gender: !_personal.gender && personal.gender ? personal.gender.value : _personal.gender,
        marital_status: !_personal.marital_status && personal.marital_status ?
          personal.marital_status.value : _personal.marital_status,
        religion: !_personal.religion && personal.religion ? personal.religion.value : _personal.religion,
        address: !_personal.address && location ? location.address.value : _personal.address,
        npwp: state.personal.npwp,
        district: !_personal.district && location && location.district ?
          location.district.value : _personal.district,
        subdistrict: !_personal.subdistrict && location && location.sub_district ? 
          location.sub_district.value : _personal.subdistrict,
        phone: !_personal.phone ? contact.phone : _personal.phone,
        email: !_personal.email ? contact.email : _personal.email,
        ktp: !_personal.ktp ? ktp : _personal.ktp,
        selfi_ktp: !_personal.selfi_ktp ? selfieKtp : _personal.selfi_ktp,
        current_address: !_personal.current_address && currentLoc ?
          currentLoc.address.value : _personal.current_address,
        // current_district: "",
        // current_subdistrict: "",
      },
      checkedBox: !state.checkedBox && location ? 
        Boolean(Number(location.currently_live_here.value)) : state.checkedBox
    };

  }

  componentDidUpdate(prevprops, prevstate) {
    let { account, upload } = this.props;
    let { personal } = this.state;
    if (upload.ktpDetail && prevstate.personal.nik !== upload.ktpDetail.nik) {
      personal.nik = upload.ktpDetail.nik;
      personal.name = this.capitalize(upload.ktpDetail.name);
      personal.pob = this.capitalize(upload.ktpDetail.place_of_birth);
      personal.dob = upload.ktpDetail.date_of_birth;
      personal.address = upload.ktpDetail.address + " RT/RW " + upload.ktpDetail.rt_rw;
      personal.district = this.capitalize(upload.ktpDetail.kecamatan + ', ' + upload.ktpDetail.district);
      personal.subdistrict = this.capitalize(upload.ktpDetail.desa);
      personal.religion = this.capitalize(upload.ktpDetail.religion);
      personal.gender = upload.ktpDetail.gender === 'PEREMPUAN' ? 'female' : 'male';
      personal.marital_status = upload.ktpDetail.marital_status === 'KAWIN' ? 'married' : 'single';
      this.setState({ personal });
    }

    if (account.isPutPersonal && account.isPutLegal) {
      prevprops.onSubmit();
    }
  }

  render() {
    const { personal, filename, checkedBox } = this.state;
    const { 
      account,
      upload,
      loan,
      init: { language, flag },
      handleSubmit,
      invalid,
      submitting
    } = this.props;

    let setDistrict, setSubdistrict;
    if (loan.listDistrict) {
      setDistrict = loan.listDistrict.filter(
        opt => opt.value.toLowerCase().includes(personal.district.toLowerCase()))[0];
    } else if (!loan.listDistrict && account.profile.location) {
      let { location } = account.profile;
      setDistrict = !location.district.value ? '' :
        { value: personal.district, label: personal.district};
    } else setDistrict = '';

    if(loan.listSubdistrict) {
      setSubdistrict = loan.listSubdistrict.filter(
        opt => opt.value.toLowerCase().includes(personal.subdistrict.toLowerCase()))[0];
    } else {
      setSubdistrict = { value: personal.subdistrict, label: personal.subdistrict}
    }

    this.props.change('nik', personal.nik);
    this.props.change('dob', personal.dob);
    this.props.change('name', personal.name);
    this.props.change('pob', personal.pob);    
    this.props.change('address', personal.address);
    this.props.change('district',setDistrict);
    this.props.change('subdistrict', setSubdistrict);
    this.props.change('religion', personal.religion);
    this.props.change('email', personal.email);
    this.props.change('phone', personal.phone);
    this.props.change('npwp', personal.npwp);
    this.props.change('current_address', personal.current_address);

    return (
      <StepInfoWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title mb-4">{language.title.stepInfo}</h2>

          <form>
            <div className="confirm-adv">
              <h5 className="form-sec-title">KTP</h5>
              <div className="input-wrapper">
                <div 
                  className="btn btn-upload"
                  onClick={this.handleShow}
                >
                  {language.button.uploadImage}
                </div>
                {personal.ktp && (
                  <div className="img-preview-wrapper">
                    <div className="img-preview">
                      <img src={personal.ktp} alt=""/>
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                    >
                      <div className="img-link-wrapper">
                        <div className="btn-wrapper">
                          <button className="link-file" name="ktp">
                            {language.label.view}
                          </button>
                          <button
                            className="link-file"
                            name="ktp"
                            onClick={this.resetFile("ktp")}
                          >
                            {language.label.reupload}
                          </button>
                        </div>
                        <span className="img-name">{filename.ktp}</span>
                      </div>
                      {upload.isLoading && (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      )}
                      {(upload.isKTPVerified || account.profile.ktp) && 
                        !upload.isLoading && !upload.isKTPInvalid && (
                        <span className="verified-file" name="ktp">
                          <i className="fa fa-check"></i> {language.label.verified}
                        </span>
                      )}
                      {!upload.isLoading && upload.isKTPInvalid && (
                        <span className="invalid-file" name="ktp">
                          <i className="fa fa-times"></i> {language.label.invalid}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <h5 className="form-sec-title">SELFIE + KTP</h5>
              <div className="input-wrapper">
                <div 
                  className="btn btn-upload"
                  onClick={this.handleShow1}
                >
                  {language.button.uploadImage}
                </div>
                {personal.selfi_ktp && (
                  <div className="img-preview-wrapper">
                    <div className="img-preview">
                      <img src={personal.selfi_ktp} alt=""/>
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                    >
                      <div className="img-link-wrapper">
                        <div className="btn-wrapper">
                          <button className="link-file" name="ktp">
                            {language.label.view}
                          </button>
                          <button
                            className="link-file"
                            name="selfi_ktp"
                            onClick={this.resetFile("selfi_ktp")}
                          >
                            {language.label.reupload}
                          </button>
                        </div>
                        <span className="img-name">{filename.selfi_ktp}</span>
                      </div>
                      {upload.isSelfieLoading && (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      )}
                      {(upload.isSelfieVerified || account.profile.selfieKtp) && 
                      !upload.isSelfieLoading && !upload.isSelfieInvalid && (
                        <span className="verified-file" name="selfi_ktp">
                          <i className="fa fa-check"></i> {language.label.verified}
                        </span>
                      )}
                      {!upload.isSelfieLoading && upload.isSelfieInvalid && (
                        <span className="invalid-file" name="selfi_ktp">
                          <i className="fa fa-times"></i> {language.label.invalid}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              { (upload.isKTPVerified || account.profile.personal) && (
                <React.Fragment>
                  <div className="input-wrapper">
                    <Field
                      id="nik"
                      label={language.form.nik}
                      variant="outlined"
                      className="w-100"
                      name="nik"
                      component={SimpleTextInput}
                      onChange={this.onInputChange("nik")}
                      validate={[validate.required, validate.exactLengthNumber(16)]}
                    />
                  </div>
                  <div className="input-wrapper">
                    <Field
                      id="name"
                      label={language.form.fullname}
                      variant="outlined"
                      className="w-100"
                      name="name"
                      component={SimpleTextInput}
                      value={personal.name}
                      onChange={this.onInputChange("name")}
                    />
                    <small>{language.label.fullname}</small>
                  </div>
                  <div className="input-wrapper">
                    <Field
                      id="pob"
                      label={language.form.pob}
                      variant="outlined"
                      className="w-100"
                      name="pob"
                      component={SimpleTextInput}
                      value={personal.pob}
                      onChange={this.onInputChange("pob")}
                    />
                  </div>
                  <div className="input-wrapper">
                    <Field
                      id="dob"
                      name="dob"
                      label={language.form.dob}
                      selected={personal.dob}
                      value={personal.dob}
                      onChange={this.handleDateChange("dob")}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      className="w-100 dataob"
                      component={DatePickerInput}
                      validate={[validate.required, validate.restrictAge(21)]}
                    />
                  </div>
                  <div className="input-wrapper gender-wrapper">
                    <p className="gender-title">{language.form.gender}</p>
                    <RadioGroup
                      aria-label="position"
                      name="gender"
                      value={personal.gender}
                      onChange={this.handleRadioChange("gender")}
                      row
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio color="primary" />}
                        label={language.label.male}
                        labelPlacement="end"
                        className="gender-box"
                        checked={personal.gender === 'male'}
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio color="primary" />}
                        label={language.label.female}
                        labelPlacement="end"
                        className="gender-box"
                        checked={personal.gender === 'female'}
                      />
                    </RadioGroup>
                  </div>
                  <div className="input-wrapper gender-wrapper">
                    <p className="gender-title">{language.form.maritalStatus}</p>
                    <RadioGroup
                      aria-label="position"
                      name="marital_status"
                      value={personal.marital_status}
                      onChange={this.handleRadioChange("marital_status")}
                      row
                    >
                      <FormControlLabel
                        value="single/divorce"
                        control={<Radio color="primary" />}
                        label={language.label.singleDivorce}
                        labelPlacement="end"
                        className="gender-box"
                        checked={personal.marital_status === 'single'}
                      />
                      <FormControlLabel
                        value="married"
                        control={<Radio color="primary" />}
                        label={language.label.married}
                        labelPlacement="end"
                        className="gender-box"
                        checked={personal.marital_status === 'married'}
                      />
                    </RadioGroup>
                  </div>
                  
                  { loan.data.loan_amount >= 15000000
                    && loan.data.offer === 'Regular'
                    && upload.ktpDetail.marital_status === 'KAWIN' (
                    <React.Fragment>
                      <h5 className="form-sec-title">{language.label.spouseKtp}</h5>
                      <div className="input-wrapper">
                        <label htmlFor="spouse_ktp" className="btn btn-upload">
                          {language.button.uploadImage}
                        </label>
                        <div className="file-upload">
                          <FileBase64
                            type="file"
                            multiple={false}
                            onDone={this.imageChange("spouse_ktp")}
                          />
                        </div>
                        {personal.spouse_ktp && (
                          <div className="img-preview-wrapper">
                            <div className="img-preview">
                              <img src={personal.spouse_ktp} alt=""/>
                            </div>

                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%"
                              }}
                            >
                              <div className="img-link-wrapper">
                                <div className="btn-wrapper">
                                  <button className="link-file" name="ktp">
                                    {language.label.view}
                                  </button>
                                  <button
                                    className="link-file"
                                    name="spouse_ktp"
                                    onClick={this.resetFile("spouse_ktp")}
                                  >
                                    {language.label.reupload}
                                  </button>
                                </div>
                                <span className="img-name">{filename.spouse_ktp}</span>
                              </div>
                              {upload.isSpouseLoading && (
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              )}
                              {upload.isSpouseVerified && (
                                <span className="verified-file" name="spouse_ktp">
                                  <i className="fa fa-check"></i> {language.label.verified}
                                </span>
                              )}
                              {!upload.isSpouseLoading && upload.isSpouseVerified === false && (
                                <span className="invalid-file" name="spouse_ktp">
                                  <i className="fa fa-times"></i> {language.label.invalid}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}

                  <div className="input-wrapper">
                    <Field
                      id="religion"
                      label={language.form.religion}
                      variant="outlined"
                      className="w-100"
                      name="religion"
                      component={SimpleTextInput}
                      value={personal.religion}
                      onChange={this.onInputChange("religion")}
                      validate={[
                        validate.required
                      ]}
                    />
                  </div>
                  <div className="input-wrapper">
                    <Field
                      id="npwp"
                      label={language.form.npwp}
                      variant="outlined"
                      className="w-100"
                      name="npwp"
                      component={SimpleTextInput}
                      value={personal.npwp}
                      onChange={this.onInputChange("npwp")}
                      validate={[
                        // validate.exactLengthNumber(15),
                        validate.requiredByAmount(loan.data.loan_amount, 50000000)
                      ]}
                    />
                  </div>
                  <div className="line my-4"></div>
                  <h5 className="form-sec-title">{language.form.idAddress}</h5>
                  <div className="input-wrapper">
                    <Field
                      id="address"
                      label={language.form.idAddress}
                      variant="outlined"
                      className="w-100"
                      name="address"
                      component={SimpleTextInput}
                      value={personal.address}
                      onChange={this.onInputChange("address")}
                    />
                  </div>
                  <div className="input-wrapper">
                    <SelectContainer inputChange={this.inputValueChange}>
                      <Field
                        className="input-search"
                        label={language.form.district}
                        placeholder={language.form.district}
                        id="district"
                        name="district"
                        component={SimpleSelectInput}
                        options={this.props.loan.listDistrict}
                        onChange={this.updateSelectVal("district")}
                        noOptionsMessage={() => "searching..."}
                      />
                    </SelectContainer>
                  </div>
                  <div className="input-wrapper">
                    <SelectContainer inputChange={this.inputValueChange}>
                      <Field
                        className="input-search"
                        label={language.form.subdistrict}
                        placeholder={language.form.subdistrict}
                        id="subdistrict"
                        name="subdistrict"
                        component={SimpleSelectInput}
                        options={this.props.loan.listSubdistrict}
                        onChange={this.updateSelectVal("subdistrict")}
                        noOptionsMessage={() => "searching..."}
                      />
                    </SelectContainer>
                  </div>
                  <div className="input-wrapper">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedBox}
                          onChange={this.handleChange("checkedBox")}
                          value="checkedBox"
                          color="primary"
                        />
                      }
                      label={language.label.currentlyLiveHere}
                      className="remember-text"
                    />
                  </div>

                  {!checkedBox && (
                    <div>
                      <h5 className="form-sec-title">{language.form.currentAddress}</h5>
                      <div className="input-wrapper">
                        <Field
                          id="c_address"
                          label={language.form.currentAddress}
                          variant="outlined"
                          className="w-100"
                          name="current_address"
                          component={SimpleTextInput}
                          value={personal.current_address}
                          onChange={this.onInputChange("current_address")}
                        />
                      </div>
                      <div className="input-wrapper">
                        <SelectContainer inputChange={this.inputValueChange}>
                          <Field
                            className="input-search"
                            label={language.form.currentDistrict}
                            placeholder={language.form.currentDistrict}
                            id="current_district"
                            name="current_district"
                            component={SimpleSelectInput}
                            options={this.props.loan.listDistrict}
                            onChange={this.updateSelectVal("current_district")}
                            noOptionsMessage={() => "searching..."}
                          />
                        </SelectContainer>
                      </div>
                      <div className="input-wrapper">
                        <SelectContainer inputChange={this.inputValueChange}>
                          <Field
                            className="input-search"
                            label={language.form.currentSubdistrict}
                            placeholder={language.form.currentSubdistrict}
                            isClearable
                            id="current_subdistrict"
                            name="current_subdistrict"
                            component={SimpleSelectInput}
                            options={this.props.loan.listSubdistrict}
                            onChange={this.updateSelectVal("current_subdistrict")}
                            noOptionsMessage={() => "searching..."}
                          />
                        </SelectContainer>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}

              <h2 className="step-title mt-5 mb-3 text-left">
                {language.title.personalContact}
              </h2>
              <div className="input-wrapper">
                <Field
                  placeholder={language.form.mobileNumber}
                  className="form-control w-100"
                  country={"id"}
                  type="text"
                  name="phone"
                  autoFormat={false}
                  enableSearch={false}
                  component={PhoneNumberInput}
                  value={personal.phone}
                  handleChange={this.onPhoneChange("phone")}
                  validate={[
                    validate.required,
                    validate.minLengthNumber(7),
                    validate.maxLengthNumber(15)
                  ]}
                />
              </div>
              <div className="input-wrapper">
                <Field
                  id="email"
                  label={language.form.businessEmail}
                  variant="outlined"
                  className="w-100"
                  name="email"
                  component={SimpleTextInput}
                  value={personal.email}
                  onChange={this.onInputChange("email")}
                  validate={[validate.required, validate.email]}
                />
              </div>
              <div className="input-wrapper">
                <button
                  className={"step-next btn btn-primary w-100"}
                  onClick={handleSubmit(this.handleSubmit)}
                  disabled={
                    invalid ||
                    submitting ||
                    upload.isKTPInvalid ||
                    upload.isSelfieInvalid ||
                    (loan.data.loan_amount >= 15000000 && !upload.isSpouseVerified)
                  }
                >
                  {language.button.next}
                </button>
                { account.errors && (
                  <div className="invalid-feedback"> {account.errors[flag]} </div>
                )}
              </div>
            </div>
          </form>
          {/* modal upload KTP */}
          <KtpModal 
            show={this.state.show}
            handleClose={this.handleClose}
            handleDone={this.imageChange("ktp")}
            language={language} />

          {/* modal upload selfie + KTP */}
          <SelfieKtpModal
            show={this.state.show1}
            handleClose={this.handleClose1}
            handleDone={this.imageChange("selfi_ktp")}
            language={language} />

          {/* modal Confirmation */}
          <ConfirmationModal
            show={this.state.showConfirm}
            handleClose={this.handleCloseConfirm}
            handleContinue={this.handleConfirmed}
            language={language} />
        </div>
      </StepInfoWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  loan: state.loan,
  upload: state.upload,
  account: state.account
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      uploadKTP,
      uploadSelfie,
      uploadSpouseKTP,
      putPersonalProfile,
      putLegalProfile,
      getDistrict,
      getSubdistrict,
      clearDistrictList,
      clearSubdistrictList,
      resetUploadFile
    },
    dispatch
  );
};

const component = connect(mapStateToProps, mapDispatchToProps)(StepInfo);

export default reduxForm({
  form: "StepInfo",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(component);
