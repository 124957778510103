import React, { useState } from "react";
import { CalculatorKoinVoiceStyle } from "./style.js";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { postCalculatorLoan } from "../../stores/actions/koinvoiceLoan";
import { bindActionCreators } from "redux";
import { TextField } from "@material-ui/core";
import { Radio, Spin, Button } from "antd";
import currencyRupiah from "../../library/currency.js";
import * as validate from "../../services/validate";
import { FormGroup } from "reactstrap";
import history from "../../util/history";
import cookie from "react-cookies";
import Background from "../../assets/images/calcbg.png";
import "./style.css";
import NumberFormat from "react-number-format";

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

const numberFormatCustom = ({ inputRef, onChange, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.floatValue);
      }}
      thousandSeparator
      isNumericString
      prefix="Rp"
    />
  );
};

const CurrencyFormatDetailsLoan = ({
  input,
  label,
  variant,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormGroup
    style={{ marginBottom: 0 }}
    className={`${touched && error && "invalid"}`}
  >
    <TextField
      label={label}
      error={touched && invalid}
      helperText={touched && error}
      variant={variant}
      {...input}
      {...custom}
    />
  </FormGroup>
);

function CalculatorKoinVoice({
  init: { language },
  koinvoice: { calculator, isShowLoading },
  postCalculatorLoan,
  handleSubmit,
}) {
  const [formValues, setFormValues] = useState({
    invoice: "10000000",
  });
  const [loanTerm, setLoanTerm] = useState([{ tenor: 1 }]);
  const [loanTermHold, setLoanTermHold] = useState(false);
  const [conditionMaxLoan, setConditionMaxLoan] = useState(false);

  const minLoan = 5000000;
  const maxLoan = 2000000000;

  function handleChangeCalculatorLoan(e) {
    if (e <= minLoan) {
      setConditionMaxLoan(true);
      // alert("Lebih maksimal");
    } else {
      if (e > maxLoan) {
        setConditionMaxLoan(true);
      } else if (e < maxLoan) {
        if (e) {
          setConditionMaxLoan(false);
          setFormValues({ ...formValues, invoice: e.toString() });
          postCalculatorLoan({ amount: e.toString() });
        }
      }
    }
  }

  const calculatorSort = calculator.data && sortList(calculator.data, "ASC");

  function sortList(list, order) {
    if (order === "ASC") {
      return list.sort((a, b) => {
        return parseFloat(a.tenor) - parseFloat(b.tenor);
      });
    } else {
      return list.sort((a, b) => {
        return parseFloat(b.tenor) - parseFloat(a.tenor);
      });
    }
  }

  const calculatorFirstRespon =
    calculator.data && calculatorSort.filter((e) => e.tenor === 1);

  function handleChangeTermLoan(e) {
    let filterLoan = calculatorSort.filter(
      (cal) => cal.tenor === e.target.value
    );
    setLoanTermHold(true);
    setLoanTerm(filterLoan);
  }

  function handleChangeSubmit(e) {
    let loanTermAmount = {
      amount: e.amount,
      loanTerm,
      calculatorSort,
    };
    //const indexesToLocalstorage = JSON.stringify(loanTermAmount);
    cookie.save("loanApply", loanTermAmount, {
      path: "/",
      maxAge: 3600,
    });
    if (cookie.load("token")) {
      history.push("/koininvoice/apply-limit");
    } else {
      if (history.location.pathname === "/") {
        history.push({
          pathname: "/register",
          state: {
            calCulator: true,
          },
        });
      } else {
        history.push({
          pathname: "/login",
          state: {
            nextPage: "/koininvoice/apply-limit",
          },
        });
      }
    }
  }

  return (
    <section className="kw-calculatorKoinInvoice" id="calculator">
      <CalculatorKoinVoiceStyle>
        <div
          className="kw-calCulatorKoinVoice-wrapper calculator container"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "90% 100%",
          }}
        >
          <form onSubmit={handleSubmit(handleChangeSubmit)}>
            <h3>{language.loanKoinVoice.labelheadingCalculator}</h3>
            <Spin spinning={isShowLoading}>
              <Field
                name="amount"
                id="amount"
                variant="outlined"
                component={CurrencyFormatDetailsLoan}
                style={{ width: isMobile() ? "50%" : "100%", marginTop: 25 }}
                label={language.loanKoinVoice.landingPageLabelAmount}
                onChange={handleChangeCalculatorLoan}
                validate={[
                  validate.required,
                  validate.amountMin,
                  validate.amountMax,
                ]}
                InputProps={{
                  inputComponent: numberFormatCustom,
                }}
              />
            </Spin>
            <React.Fragment>
              {conditionMaxLoan === false && calculator.data && (
                <strong
                  style={{
                    display: "block",
                    color: "#2D2D2D",
                    fontWeight: 600,
                    marginTop: 20,
                  }}
                >
                  {language.label.loanTermMounth}
                </strong>
              )}
              <Radio.Group
                onChange={handleChangeTermLoan}
                defaultValue={loanTerm[0].tenor}
                style={{ marginTop: 20, marginBottom: 25 }}
                buttonStyle="solid"
              >
                {conditionMaxLoan === false &&
                  calculator.data &&
                  calculatorSort.map((cal, index) => {
                    return (
                      <Radio.Button
                        key={index}
                        style={{ marginRight: 15 }}
                        value={cal.tenor}
                      >
                        {cal.tenor}
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>{" "}
              <div className="kw-calCulatorKoinVoice-wrapper__card-labelDetails">
                <h5>{language.loanKoinVoice.calculationDeailsLabel}</h5>
              </div>
              <div className="kw-calCulatorKoinVoice-wrapper__card-detailsCalculator">
                <span>{language.loanKoinVoice.totalDisbursed}</span>
                {calculator.data &&
                  loanTermHold === true &&
                  loanTerm.map((loan, index) => {
                    return (
                      <strong key={index}>
                        {currencyRupiah(loan.disbursed_amount, "IDR ")}
                      </strong>
                    );
                  })}
                {calculator.data && loanTermHold === false ? (
                  calculatorFirstRespon.map((loan, index) => {
                    return (
                      <strong key={index}>
                        {currencyRupiah(loan.disbursed_amount, "IDR ")}
                      </strong>
                    );
                  })
                ) : (
                  <strong>
                    {loanTermHold === false && currencyRupiah(0, "IDR ")}
                  </strong>
                )}
                <span>{language.loanKoinVoice.totalRepayment}</span>
                {calculator.data &&
                  loanTermHold === true &&
                  loanTerm.map((loan, index) => {
                    return (
                      <strong key={index}>
                        {currencyRupiah(loan.repayment_amount, "IDR ")}
                      </strong>
                    );
                  })}
                {calculator.data && loanTermHold === false ? (
                  calculatorFirstRespon.map((loan, index) => {
                    return (
                      <strong key={index}>
                        {currencyRupiah(loan.repayment_amount, "IDR ")}
                      </strong>
                    );
                  })
                ) : (
                  <strong>
                    {loanTermHold === false && currencyRupiah(0, "IDR ")}
                  </strong>
                )}
                <p>{language.loanKoinVoice.calculatorParagraphOne}</p>
                <p>{language.loanKoinVoice.calculatorParagraphTwo}</p>
              </div>
              <Button
                htmlType="submit"
                className="kw-button"
                size="large"
                onSubmit={handleSubmit}
              >
                APPLY LOAN
              </Button>
            </React.Fragment>
          </form>
          {/* <img src={require("../../assets/images/calcbg.png").default} alt="" /> */}
        </div>
      </CalculatorKoinVoiceStyle>
    </section>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ postCalculatorLoan }, dispatch);
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorKoinVoice);

export default reduxForm({
  form: "CalculatorKoinVoice",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(component);
