import React, { useEffect, useState } from "react";
import koinvoiceServices from "../services/koinvoice";
import { Card, Input, Modal, Button, Spin } from "antd";
import { DocumentContractStyle } from "./style";
import { capitalizeFirstLetter } from "../library/checkCapitalize";
import moment from "moment";
import "./style.css";
import {
  validationImagePdf,
  validationImageZip,
} from "../library/checkPdfOrZipOrJpg";

const { Search } = Input;

function DocumentApproved({ actionChangeShowDashboard }) {
  const [listDocumentApproved, setListDocumentApproved] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [filters, setFilters] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const lowercasedFilter = filters.toLowerCase();
  const filteredData =
    listDocumentApproved !== undefined &&
    listDocumentApproved.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });

  function actionChangeSearch(event) {
    setFilters(event.target.value);
  }

  useEffect(() => {
    getDocumentListApproved();
  }, []);

  async function getDocumentListApproved() {
    let params = {
      status: "approved",
    };
    let documentList = await koinvoiceServices.documentList({
      params: params,
      loading: setIsShowLoading,
    });
    setListDocumentApproved(documentList && (documentList.data || []));
  }

  function handleChangeShowView(item) {
    let validationPngJpgJpeg = item?.value.includes("gif") || item?.value.includes("jpg") ||
                              item?.value.includes("jpeg") || item?.value.includes("tiff") || 
                              item?.value.includes("png")
    let validationPdf = item?.value.includes("pdf");
    let validationZip = item?.value.includes("zip") || item?.value.includes("x-zip-compressed");
    
    if (validationZip) {
      downloadZipFile(item?.key,item?.value);
    } else if (validationPdf) {
      actionViewPdf(item?.value);
    } else {
      setImageUrl(item?.value);
      setVisible(true);
    }

    // validationPngJpgJpeg === true && setImageUrl(item.value);
    // validationPdf === true && actionViewPdf(item.value);
    // validationZip === true && downloadZipFile(item.key,item.value);
    // validationPngJpgJpeg === true &&
    //   validationPdf === false &&
    //   validationZip === false &&
    //   setVisible(true);
  }

  function actionChangeClose() {
    setVisible(false);
  }

  // async function downloadZipFileTwo(filename, content) {
  //   const zip = new JSZip();
  //   await zip.loadAsync(content, { base64: true });
  //   const blob = await zip.generateAsync({ type: "blob" });
  //   const element = document.createElement("a");
  //   element.setAttribute("href", window.URL.createObjectURL(blob));
  //   element.setAttribute("download", filename);
  //   element.style.display = "none";
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // }

  function downloadZipFile(fileName,fileUrl) {
    let link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    link.download = fileName
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function actionViewPdf(fileURL) {
    let link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Spin spinning={isShowLoading}>
      <DocumentContractStyle>
        <Card
          style={{
            height:
              listDocumentApproved !== undefined &&
              listDocumentApproved.length > 5
                ? "auto"
                : "100vh",
          }}
        >
          <Button
            style={{ marginBottom: 25 }}
            size="large"
            onClick={() => actionChangeShowDashboard("")}
          >
            Back
          </Button>
          <div className="kw-documentContract-wrapper">
            <Search
              placeholder="Search document..."
              onChange={actionChangeSearch}
              size="large"
            />
            {listDocumentApproved !== undefined &&
            listDocumentApproved.length > 0 ? (
              filteredData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="kw-documentContract-wrapper__cards">
                      <strong>
                        {capitalizeFirstLetter(item.key || item.name)}
                      </strong>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span className="kw-documentContract-wrapper__labelId">
                          Last updated on{" "}
                          {item.submitted_at.length > 0 &&
                            moment(item.submitted_at).format("DD/MM/YYYY")}
                        </span>
                        <span className="kw-documentContract-wrapper__expired-at">
                          {item.expired_at.length > 0 &&
                            ` Expired on  ${moment(item.expired_at).format(
                              "DD/MM/YYYY"
                            )}`}
                        </span>
                        {item.value.length > 0 && (
                          <span
                            className="kw-documentContract-wrapper__view-list"
                            onClick={() => handleChangeShowView(item)}
                          >
                            View
                          </span>
                        )}
                      </div>
                    </div>
                    <Modal
                      title=" "
                      footer={null}
                      visible={visible}
                      //onOk={handleChangeShowView}
                      onCancel={actionChangeClose}
                    >
                      <img
                        src={imageUrl}
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Modal>
                  </React.Fragment>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50%",
                }}
              >
                <img src={require("../assets/images/notfound.png").default} alt="" />
              </div>
            )}
          </div>
        </Card>
      </DocumentContractStyle>
    </Spin>
  );
}

export default DocumentApproved;
