import React from "react";
import { Modal } from "react-bootstrap";
import confirmImg from "../../../assets/images/applyloan/step-info-confirm.png";

const ConfirmationModal = ({
  show,
  handleClose,
  handleContinue,
  language
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton className="px-4 pb-0">
      <Modal.Title></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="modal-wrapper px-3">
        <div className="uploadins-wrapper">
          <figure className="uploadins-img">
            <img src={confirmImg} alt="" />
          </figure>
          <div className="uploadins-text">
            <h2 className="uploadins-title">{language.title.stepInfoConfirmModal}</h2>
          </div>
          <div className="uploadins-text">
            <p className="uploadins-desc">
              {language.text.stepInfoConfirmModal}
            </p>
            <div className="modal-footer-button">
              <div className="btn btn-primary-outline" onClick={handleClose}>
                {language.button.checkAgain}
              </div>
              <div className=" btn btn-primary" onClick={handleContinue}>
                {language.button.yesContinue}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)

export default ConfirmationModal;