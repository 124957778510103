import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from "react-number-format";
import { StepConfirmationWrapper } from "./StepConfirmation.styles";
import { applyLoan } from "../../stores/actions/loan";
import history from "../../util/history";
import { Offers } from "../common/data";
import { calculation } from "../common/calculation";

class StepConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    open: false,
    value: {
      offer: "",
      amount: null,
      purpose: "",
      term: null
    },
    disable: {
      offer: true,
      amount: true,
      purpose: true,
      term: true
    },
    resume: {
      fee: null,
      disbursement: null,
      installment: null
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    history.push("/loan-app");
  };

  inputChange = e => {
    let { value, resume } = this.state;
    let loan = this.props.loan.data;
    value[e.target.name] = e.target.value; 
    this.setState({ value });
    if (e.target.name === "amount") {
      if (value.offer === "Salary Advance") {
        [
          resume.fee,
          resume.disbursement
        ] = calculation.salaryAdvance(e.target.value);
      } else {
        [
          resume.disbursement,
          resume.installment,
          resume.fee
        ] = calculation.regular(
          loan.loan_status,
          loan.e_status,
          e.target.value,
          value.term,
          loan.vendor_data
        );
      }
    }
    if (e.target.name === "term") {
      [
        resume.disbursement,
        resume.installment,
        resume.fee
      ] = calculation.regular(
        loan.loan_status,
        loan.e_status,
        value.amount,
        e.target.value,
        loan.vendor_data
      )
    }
    this.setState({ resume })
  }

  handleSubmit = () => {
    let { auth, loan } = this.props;
    let { value, resume } = this.state;
    let offer = value.offer ? value.offer : loan.offer;
    let loan_amount = value.amount ? 
      value.amount : loan.data.loan_amount;
    let loan_purpose = value.purpose ?
      value.purpose : loan.data.loan_purpose;
    let loan_term = value.term ?
      value.term : loan.data.tenure;
    let loan_installment = resume.installment ?
      resume.installment : loan.data.installment;
    if (loan.data.loan_status === "internal") {
      let data = {
        name: auth.name,
        nik: loan.data.nik,
        employee_status: loan.status,
        ktp_url: loan.data.ktp_url,
        loan_amount: parseFloat(loan_amount),
        tenure: loan_term,
        loan_purpose: loan_purpose
      };
      let url = offer === "Salary Advance" ?
        "/v1/koinemployee/salary-advance/apply" :
        "/v1/koinemployee/internal-employee/apply";
  
      this.props.applyLoan(url, data, auth);

    } else {
      let url = "/v1/koinemployee/loan/apply";
      let data = {
        amount: parseFloat(loan_amount),
        term: loan_term,
        purpose: loan_purpose,
        monthly_installment: parseFloat(loan_installment),
        company_referal: loan.data.company_referal,
        document: loan.data.document
      };
      this.props.applyLoan(url, data, auth);
    }
  };

  handleEdit = () => {
    let { disable } = this.state;
    disable["offer"] = false;
    disable["purpose"] = false;
    disable["term"] = false;
    disable["amount"] = false;
    this.setState({ disable });
  };

  render() {
    let loan = this.props.loan.data;
    let { handleSubmit, submitting, init: {language} } = this.props;
    let { disable, value, resume } = this.state;
    let tenures = JSON.parse(loan.vendor_data.tenure);
    value.offer = value.offer || loan.offer;
    value.amount = value.amount || loan.loan_amount;
    value.purpose = value.purpose || loan.loan_purpose;
    value.term = value.term || loan.tenure;
    resume.disbursement = resume.disbursement || loan.disbursement;
    resume.fee = resume.fee || loan.fee;
    resume.installment = resume.installment || loan.installment;

    return (
      <StepConfirmationWrapper>
        <div className="card-body register-body pb-0">
          <h2 className="step-title">{language.title.stepConfirmation}</h2>
          <div className="input-wrapper mb-4">
            <p className="conf-text">
              {language.subtitle.stepConfirmation}
            </p>
          </div>
          <form>
            <div className="confirm-adv">
              <div className="input-wrapper">
                <TextField
                  id="offer"
                  select
                  label={language.form.choosenOffer}
                  variant="outlined"
                  className="w-100"
                  name="offer"
                  value={value.offer}
                  onChange={this.inputChange}
                  disabled={disable.offer}
                >
                  {Offers.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="input-wrapper">
                <TextField
                  id="amount-type"
                  name="amount"
                  label={language.form.amount}
                  value={value.amount}
                  variant="outlined"
                  className="w-100"
                  onChange={this.inputChange}
                  disabled={disable.amount}
                />
              </div>
              {value.offer === 'Regular' && (
                <div className="input-wrapper">
                  <TextField
                    id="term"
                    select
                    label={language.form.term
                    }
                    variant="outlined"
                    className="w-100"
                    name="term"
                    value={value.term}
                    onChange={this.inputChange}
                    disabled={disable.term}
                  >
                    {tenures.map(data => (
                      <MenuItem key={data} value={data}>
                        {data} {language.label.months}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              <div className="input-wrapper">
                <TextField
                  id="input-purpose"
                  name="purpose"
                  label={language.form.purpose}
                  value={value.purpose}
                  variant="outlined"
                  className="w-100"
                  onChange={this.inputChange}
                  disabled={disable.purpose}
                />
              </div>
              <div className="input-wrapper">
                <div className="btn-edit">
                  <span onClick={this.handleEdit}>Edit</span>
                </div>
              </div>
              <div className="input-wrapper">
                <div className="line"></div>
              </div>

              {loan.offer === "Salary Advance" && (
                <div className="input-wrapper">
                  <div className="box-total">
                    <div className="box-list camount-req">
                      <span className="text-left">{language.label.amountRequested}</span>
                      <span className="text-right">
                        <NumberFormat
                          value={value.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </span>
                    </div>
                    <div className="box-list cfee">
                      <span className="text-left">{language.label.fee}</span>
                      <span className="text-right">
                        -
                        <NumberFormat
                          value={resume.fee}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </span>
                    </div>
                    <div className="box-list ctotal">
                      <span className="text-left">{language.label.totalDisburse}</span>
                      <span className="text-right">
                        <span className="curency">IDR </span>
                        <NumberFormat
                          value={resume.disbursement}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {loan.offer === "Regular" && (
                <div className="input-wrapper m-installment">
                  <h5 className="title">{language.label.monthlyInstallment}</h5>
                  <p className="text">
                    <NumberFormat
                      value={resume.installment}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </p>
                </div>
              )}

              <div className="input-wrapper">
                <button
                  className={
                    "step-next btn btn-primary btn-disable-hover w-100"
                  }
                  disabled={submitting}
                  onSubmit={handleSubmit(this.handleSubmit)}
                >
                  {language.button.confirm}
                </button>
              </div>
            </div>
          </form>
        </div>
      </StepConfirmationWrapper>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  loan: state.loan,
  init: state.init
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ applyLoan }, dispatch);
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(StepConfirmation);

export default reduxForm({
  form: "StepConfirmation",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(component);
