import TYPE from "../actions/type";
import cookie from "react-cookies";

const initialState = {
  dataPersonalInformationLocal: {},
  dataOwnerDirectorInformationLocal: {},
  dataShareHolderInformationLocal: {},
  principal: {},
  principalError: "",
  financing: {},
  financingError: "",
  isShowFinancingAlert: "",
  isShowPrincipalAlert: "",
  isEnabledButton: true,
  isEnabledButtonFinancing: true,
  isShowLoading: false,
  purpose: {},
  purposeError: "",
  loanDetails: [],
  loanDetailsError: "",
  putLoanDetails: [],
  putLoanDetailsError: "",
  businessInfo: {},
  businessInfoError: "",
  dataBusinessInfoLocal: {},
  dataBusinessInfoLocalSubmit: {},
  putBusinessInfo: [],
  putBusinessInfoError: "",
  putBusinessInfoProfile: [],
  isShowPutBusinessInfo: false,
  isShowPutBusinessInfoProfile: false,
  putBusinessInfoProfileError: "",
  putPersonalInfo: [],
  putPersonalInfoError: "",
  putPersonalInfoProfile: [],
  putPersonalInfoProfileError: "",
  isNextBusinessTracking: false,
  isNextPersonalProfileTracking: false,
  displayPage: 1,
  displayPageBussinesInfoConfirmation: 0,
  calculator: [],
  calculatorError: "",
  upload: {},
  uploadError: "",
  uploadKtpSucess: {},
  uploadKtpError: "",
  uploadShareHolder: {},
  uploadShareHolderError: "",
  uploadKtpShareHolderSucess: {},
  uploadKtpShareHolderError: "",
  uploadNpwpShareHolderSucess: {},
  uploadNpwpShareHolderError: "",
  uploadSelfie: {},
  uploadSelfieError: "",
  uploadMatchKtpSucess: {},
  uploadMatchKtpError: "",
  uploadSelfieShareHolder: {},
  uploadSelfieShareHolderError: "",
  uploadMatchKtpShareHolderSucess: {},
  uploadMatchKtpShareHolderError: "",
  uploadKK: {},
  uploadKKError: "",
  uploadKKValidation: {},
  uploadKKValidationError: "",
  documentRequestSucess: {},
  documentRequestError: "",
  documentRequestLoanSucess: {},
  documentRequestLoanError: "",
  documentUploadSucess: {},
  documentUploadError: "",
  resendEmail: "",
  resendEmailError: "",
  applicationList: {},
  applicationListError: "",
  province: {},
  provinceError: "",
  city: {},
  cityError: "",
  district: {},
  districtError: "",
  subDistrict: {},
  subDistrictError: "",
  country: {},
  countryError: "",
  districtShareHolder: {},
  districtShareHolderError: "",
  subDistrictShareHolder: {},
  subDistrictShareHolderError: "",
  businessInformation: {},
  businessInformationTwo: {},
  businessInformationError: "",
  appCreateGuarantor: {},
  appCreateGuarantorError: "",
  appCreateOwnerDirectorGuarantor: {},
  appCreatOwnerDirectoreGuarantorError: "",
  nextUpdateGuarantor: false,
  nextUpdateOwnerDirectorGuarantor: false,
  nextCreateGuarantor: false,
  appUpdateGuarantor: {},
  appUpdateGuarantorError: "",
};

const invoiceLoanReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.DATA_BUSINESS_INFO_LOCAL:
      return {
        ...state,
        dataBusinessInfoLocal: action.payload,
      };
    case TYPE.BUTTON_BACK_NEXT_SHARE_HOLDER:
      return {
        ...state,
        // displayPage: cookie.save("displayPage", 3, {
        //   // path: "/",
        //   maxAge: 3600,
        // }),
      };
    case TYPE.UPLOAD_BACK_ACTION_REFRESH:
      return {
        ...state,
        upload: {},
        uploadSelfie: {},
      };
    case TYPE.UPLOAD_KTP_SELFIE_KTP:
      return {
        ...state,
        uploadShareHolder: {},
        uploadShareHolderError: "",
        uploadSelfieShareHolder: {},
        uploadSelfieShareHolderError: "",
        uploadKtpShareHolderSucess: {},
        uploadKtpShareHolderError: "",
        uploadMatchKtpShareHolderSucess: {},
        uploadMatchKtpShareHolderError: "",
      };
    case TYPE.BUTTON_BACK_UNGGAH_DOKUMENT:
      return {
        ...state,
        calculator: [],
        calculatorError: "",
        displayPage: 1,
        principal: {},
        principalError: "",
        financing: {},
        financingError: "",
        isEnabledButton: true,
        isEnabledButtonFinancing: true,
      };
    case TYPE.BUTTON_BACK_PERSONAL_INFO:
      return {
        ...state,
        upload: {},
        uploadKtpSucess: {},
        uploadSelfie: {},
        uploadMatchKtpSucess: {},
        uploadKK: {},
      };
    case TYPE.DATA_BUSINESS_INFO_LOCAL_SUBMIT:
      return {
        ...state,
        dataBusinessInfoLocalSubmit: action.payload,
      };
    case TYPE.DATA_PERSONAL_BUSINESS_INFO_LOCAL:
      return {
        ...state,
        dataPersonalInformationLocal: action.payload,
      };
    case TYPE.DATA_OWNER_DIRECTOR_BUSINESS_INFO_LOCAL:
      return {
        ...state,
        dataOwnerDirectorInformationLocal: action.payload,
      };
    case TYPE.DATA_SHARE_HOLDER_LOCAL:
      return {
        ...state,
        dataShareHolderInformationLocal: action.payload,
      };
    case TYPE.DATA_SHARE_HOLDER_LOCAL_RESET:
      return {
        ...state,
        dataShareHolderInformationLocal: {},
      };
    case TYPE.BUTTON_BACK:
      return {
        ...state,
        displayPage: action.payload,
      };
    case TYPE.BUTTON_BACK_SELECT_LOAN:
      return {
        ...state,
        financing: {},
        financingError: "",
        isShowPrincipalAlert: false,
        isShowFinancingAlert: false,
        isEnabledButton: true,
        isEnabledButtonFinancing: true,
        principal: {},
        principalError: "",
      };
    case TYPE.BUTTON_BACK_BUSINESS_INFO:
      return {
        ...state,
        isShowPutBusinessInfo: false,
        nextUpdateOwnerDirectorGuarantor: false,
        isShowPutBusinessInfoProfile: false,
      };
    case TYPE.BUTTON_BACK_SHARE_HOLDER:
      return {
        ...state,
        nextUpdateGuarantor: false,
        nextCreateGuarantor: false,
      };
    case TYPE.SEND_DOCUMENT_REQUEST_SUCESS:
      return {
        ...state,
        documentRequestSucess: action.payload,
        dataPersonalInformationLocal: {},
        dataOwnerDirectorInformationLocal: {},
        dataBusinessInfoLocalSubmit: {},
        dataBusinessInfoLocal: {},
        putPersonalInfo: {},
        putBusinessInfo: [],
        putLoanDetails: [],
        isShowPutBusinessInfo: false,
        applicationListError: "",
        isShowPutBusinessInfoProfile: false,
        // displayPage: cookie.save("displayPage", 4, {
        //   // path: "/",
        //   maxAge: 3600,
        // }),
      };
    case TYPE.SEND_DOCUMENT_REQUEST_ERROR:
      return {
        ...state,
        documentRequestError: action.payload,
      };
    case TYPE.SEND_DOCUMENT_LOAN_REQUEST_SUCESS:
      return {
        ...state,
        documentRequestLoanSucess: action.payload,
        dataPersonalInformationLocal: {},
        dataOwnerDirectorInformationLocal: {},
        dataBusinessInfoLocalSubmit: {},
        dataBusinessInfoLocal: {},
        putPersonalInfo: {},
        putBusinessInfo: [],
        putLoanDetails: [],
        isShowPutBusinessInfo: false,
        applicationListError: "",
        isShowPutBusinessInfoProfile: false,
        // displayPage: cookie.save("displayPage", 4, {
        //   // path: "/",
        //   maxAge: 3600,
        // }),
      };
    case TYPE.SEND_DOCUMENT_LOAN_REQUEST_ERROR:
      return {
        ...state,
        documentRequestLoanError: action.payload,
      };
    case TYPE.APP_GUARANTOR_CREATE_SUCSESS:
      return {
        ...state,
        appCreateGuarantor: action.payload,
        nextCreateGuarantor: true,
      };
    case TYPE.BUTTON_SHARE_HOLDER_NEW_FORM_LIMIT:
      return {
        ...state,
        appCreateOwnerDirectorGuarantor: {},
        nextUpdateOwnerDirectorGuarantor: false,
      };
    case TYPE.APP_GUARANTOR_CREATE_ERROR:
      return {
        ...state,
        appCreateGuarantorError: action.payload,
      };
    case TYPE.APP_GUARANTOR_CREATE_OWNER_DIRECTOR_SUCSESS:
      return {
        ...state,
        appCreateOwnerDirectorGuarantor: action.payload,
        nextUpdateOwnerDirectorGuarantor: true,
      };
    case TYPE.APP_GUARANTOR_CREATE_OWNER_DIRECTOR_ERROR:
      return {
        ...state,
        appCreatOwnerDirectoreGuarantorError: action.payload,
        nextUpdateOwnerDirectorGuarantor: false,
      };
    case TYPE.APP_GUARANTOR_UPDATE_SUCSESS:
      return {
        ...state,
        appUpdateGuarantor: action.payload,
        nextUpdateGuarantor: true,
      };
    case TYPE.APP_GUARANTOR_UPDATE_ERROR:
      return {
        ...state,
        appUpdateGuarantorError: action.payload,
      };
    case TYPE.CITY_SUCSESS:
      return {
        ...state,
        city: action.payload,
      };
    case TYPE.CITY_ERROR:
      return {
        ...state,
        cityError: action.payload,
      };
    case TYPE.DISTRICT_SHARE_HOLDER_SUCESS:
      return {
        ...state,
        districtShareHolder: action.payload,
      };
    case TYPE.DISTRICT_SHARE_HOLDER_ERROR:
      return {
        ...state,
        districtShareHolderError: action.payload,
      };
    case TYPE.SUB_DISTRICT_SHARE_HOLDER_SUCESS:
      return {
        ...state,
        subDistrictShareHolder: action.payload,
      };
    case TYPE.SUB_DISTRICT_SHARE_HOLDER_ERROR:
      return {
        ...state,
        subDistrictShareHolderError: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_SUCESS:
      return {
        ...state,
        uploadSelfieShareHolder: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_KTP_SHARE_HOLDER_VALIDATION_ERROR:
      return {
        ...state,
        uploadSelfieShareHolderError: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_SUCESS:
      return {
        ...state,
        uploadMatchKtpShareHolderSucess: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_MATCH_KTP_SHARE_HOLDER_VALIDATION_ERROR:
      return {
        ...state,
        uploadMatchKtpShareHolderError: action.payload,
      };
    case TYPE.UPLOAD_NEW_SHARE_HOLDER_SUCESS:
      return {
        ...state,
        uploadShareHolder: action.payload,
      };
    case TYPE.UPLOAD_NEW_SHARE_HOLDER_ERROR:
      return {
        ...state,
        uploadShareHolderError: action.payload,
      };
    case TYPE.UPLOAD_KTP_VALIDATION_SHARE_HOLDER_SUCESS:
      return {
        ...state,
        uploadKtpShareHolderSucess: action.payload,
      };
    case TYPE.UPLOAD_KTP_VALIDATION_SHARE_HOLDER_ERROR:
      return {
        ...state,
        uploadKtpShareHolderError: action.payload,
      };
    case TYPE.PROVINCE_SUCSESS:
      return {
        ...state,
        province: action.payload,
      };
    case TYPE.PROVINCE_ERROR:
      return {
        ...state,
        provinceError: action.payload,
      };
    case TYPE.DISTRICT_SUCESS:
      return {
        ...state,
        district: action.payload,
      };
    case TYPE.DISTRICT_ERROR:
      return {
        ...state,
        districtError: action.payload,
      };
    case TYPE.SUB_DISTRICT_SUCESS:
      return {
        ...state,
        subDistrict: action.payload,
      };
    case TYPE.SUB_DISTRICT_ERROR:
      return {
        ...state,
        subDistrictError: action.payload,
      };
    case TYPE.COUNTRY_SUCESS:
      return {
        ...state,
        country: action.payload,
      };
    case TYPE.COUNTRY_ERROR:
      return {
        ...state,
        countryError: action.payload,
      };
    case TYPE.PRINCIPAL_SUCESS:
      return {
        ...state,
        principal: action.payload,
        isShowPrincipalAlert: true,
        isEnabledButton: false,
        isEnabledButtonFinancing: true,
      };
    case TYPE.PRINCIPAL_ERROR:
      return {
        ...state,
        principalError: action.payload,
        principal: {},
        isShowPrincipalAlert: false,
        isEnabledButton: true,
        isEnabledButtonFinancing: true,
      };
    case TYPE.FINANCING_SUCESS:
      return {
        ...state,
        financing: action.payload,
        isShowFinancingAlert: true,
        isEnabledButton: true,
        isEnabledButtonFinancing: false,
      };
    case TYPE.FINANCING_ERROR:
      return {
        ...state,
        financingError: action.payload,
        financing: {},
        isShowFinancingAlert: false,
        isEnabledButton: true,
        isEnabledButtonFinancing: true,
      };
    case TYPE.PURPOSE_SUCESS:
      return {
        ...state,
        purpose: action.payload,
        displayPage: 2,
      };
    case TYPE.PURPOSE_ERROR:
      return {
        ...state,
        purposeError: action.payload,
        purpose: {},
        displayPage: 1,
      };
    case TYPE.LOAN_DETAILS_SUCESS:
      return {
        ...state,
        loanDetails: action.payload,
      };
    case TYPE.LOAN_DETAILS_ERROR:
      return {
        ...state,
        loanDetailsError: action.payload,
      };
    case TYPE.PUT_LOAN_DETAILS_SUCESS:
      return {
        ...state,
        putLoanDetails: action.payload,
        // displayPage: cookie.save("displayPage", 3, {
        //   // path: "/",
        //   maxAge: 3600,
        // }),
      };
    case TYPE.PUT_LOAN_DETAILS_ERROR:
      return {
        ...state,
        putLoanDetailsError: action.payload,
        displayPage: 2,
      };
    case TYPE.UPLOAD_NEW_SUCESS:
      return {
        ...state,
        upload: action.payload,
      };
    case TYPE.UPLOAD_NEW_ERROR:
      return {
        ...state,
        uploadError: action.payload,
      };
    case TYPE.UPLOAD_KTP_VALIDATION_SUCESS:
      return {
        ...state,
        uploadKtpSucess: action.payload,
      };
    case TYPE.UPLOAD_KTP_VALIDATION_ERROR:
      return {
        ...state,
        uploadKtpError: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_KTP_VALIDATION_SUCESS:
      return {
        ...state,
        uploadSelfie: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_KTP_VALIDATION_ERROR:
      return {
        ...state,
        uploadSelfieError: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_MATCH_KTP_VALIDATION_SUCESS:
      return {
        ...state,
        uploadMatchKtpSucess: action.payload,
      };
    case TYPE.UPLOAD_SELFIE_MATCH_KTP_VALIDATION_ERROR:
      return {
        ...state,
        uploadMatchKtpError: action.payload,
      };
    case TYPE.BUSINESS_INFO_SUCESS:
      return {
        ...state,
        businessInfo: action.payload,
      };
    case TYPE.BUSINESS_INFO_ERROR:
      return {
        ...state,
        businessInfoError: action.payload,
      };
    case TYPE.BUSINESS_INFORMATION_PROFILE_SUCESS:
      return {
        ...state,
        putBusinessInfoProfile: action.payload,
      };
    case TYPE.BUSINESS_INFORMATION_PROFILE_ERROR:
      return {
        ...state,
        putBusinessInfoProfileError: action.payload,
      };
    case TYPE.BUSINESS_INFORMATION_SUCESS:
      return {
        ...state,
        businessInformation: action.payload,
      };
    case TYPE.BUSINESS_INFORMATION_ERROR:
      return {
        ...state,
        businessInformationError: action.payload,
      };
    case TYPE.UPLOAD_KK_SUCESS:
      return {
        ...state,
        uploadKK: action.payload,
      };
    case TYPE.UPLOAD_KK_ERROR:
      return {
        ...state,
        uploadKKError: action.payload,
      };
    case TYPE.UPLOAD_KK_VALIDATION_SUCESS:
      return {
        ...state,
        uploadKKValidation: action.payload,
      };
    case TYPE.UPLOAD_KK_VALIDATION_ERROR:
      return {
        ...state,
        uploadKKValidationError: action.payload,
      };
    case TYPE.PUT_BUSINESS_INFO_SUCESS:
      return {
        ...state,
        putBusinessInfo: action.payload,
        isNextBusinessTracking: true,
      };
    case TYPE.PUT_BUSINESS_INFO_ERROR:
      return {
        ...state,
        putBusinessInfoError: action.payload,
        isNextBusinessTracking: false,
      };
    case TYPE.PUT_PERSONAL_INFO_SUCESS:
      return {
        ...state,
        putPersonalInfo: action.payload,
        isShowPutBusinessInfo: true,
        isNextPersonalProfileTracking: true,
      };
    case TYPE.PUT_PERSONAL_INFO_ERROR:
      return {
        ...state,
        putPersonalInfoError: action.payload,
        isShowPutBusinessInfo: false,
        isNextPersonalProfileTracking: false,
      };
    case TYPE.PUT_PERSONAL_INFO_PROFILE_SUCESS:
      return {
        ...state,
        putBusinessInfoProfile: action.payload,
        isShowPutBusinessInfoProfile: true,
      };
    case TYPE.PUT_PERSONAL_INFO_PROFILE_ERROR:
      return {
        ...state,
        putPersonalInfoProfileError: action.payload,
        isShowPutBusinessInfoProfile: false,
      };
    case TYPE.CALCULATOR_LANDING_PAGE_SUCESS:
      return {
        ...state,
        calculator: action.payload,
      };
    case TYPE.CALCULATOR_LANDING_PAGE_ERROR:
      return {
        ...state,
        calculatorError: action.payload,
      };
    case TYPE.DOCUMENT_UPLOAD_SUCESS:
      return {
        ...state,
        documentUploadSucess: action.payload,
      };
    case TYPE.DOCUMENT_UPLOAD_ERROR:
      return {
        ...state,
        documentUploadError: action.payload,
      };
    case TYPE.RESEND_EMAIL_SUCESS:
      return {
        ...state,
        resendEmail: action.payload,
      };
    case TYPE.RESEND_EMAIL_ERROR:
      return {
        ...state,
        resendEmailError: action.payload,
        displayPage: 4,
      };
    case TYPE.APPLICATION_LIST_SUCESS:
      return {
        ...state,
        applicationList: action.payload,
      };
    case TYPE.APPLICATION_LIST_ERROR:
      return {
        ...state,
        applicationListError: action.payload,
      };
    case TYPE.LOADING_SHOW:
      return {
        ...state,
        isShowLoading: true,
      };
    case TYPE.LOADING_NO_SHOW:
      return {
        ...state,
        isShowLoading: false,
      };
    default:
      return state;
  }
};

export default invoiceLoanReducer;