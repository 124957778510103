// Reducer utilities

import { handleDecrypets } from "./decryptes";

/**
 * Appends loading state properties to the initial state.
 *
 * @param  {Object} state Current state.
 * @return {Object}       Expanded state.
 */
const appendLoadingStates = state => (
  Object.assign(state, {
    isLoading: false,
    hasError: false,
    error: null,
  })
);

/**
 * Resets loading state of the reducer state.
 *
 * @param  {Object} state Current state.
 * @return {Object}       New state.
 */
const resetLoading = state => ({
  ...state,
  isLoading: false,
  hasError: false,
  error: null,
});

/**
 * Starts loading state of the reducer state.
 *
 * @param  {Object} state Current state.
 * @return {Object}       New state.
 */
const startLoading = state => ({
  ...state,
  isLoading: true,
  hasError: false,
  error: null,
});

/**
 * Finishes loading state of the reducer state.
 *
 * @param  {Object} state Current state.
 * @return {Object}       New state.
 */
const finishLoading = state => ({
  ...state,
  isLoading: false,
  hasError: false,
  error: null,
});

/**
 * Throws an error to the reducer state.
 *
 * @param  {Object} state Current state.
 * @return {Object}       New state.
 */
const errorLoading = (state, error) => ({
  ...state,
  isLoading: false,
  hasError: true,
  error,
});

/**
 * Throws decrpt data to the reducer state.
 *
 * @param  {Object} state respone endpoint.
 * @return {Object} result decrpt state.
 */
const decrypetsData = (state) => ({
  fullname: handleDecrypets(state.satu),
  phone_area: handleDecrypets(state.dua),
  phone_number: handleDecrypets(state.tiga),
  email: handleDecrypets(state.empat),
  account_type: process.env.REACT_APP_ENCRYPT_ACCOUNT_TYPE_FIELD_ENABLED === 'true' ? handleDecrypets(state.lima) : state.lima,
  empty_password: state.enam,
  empty_pin: state.tujuh,
  is_old_user: state.delapan,
  user_parent: state.sembilan,
  is_account_freezed: state.sepuluh,
  role_type: handleDecrypets(state.sebelas),
  lender_type: handleDecrypets(state.duabelas),
  is_phone_confirmed: state.tigabelas,
  is_email_confirmed: state.empatbelas,
  account_status: handleDecrypets(state.limabelas)
});


export {
  appendLoadingStates,
  resetLoading,
  startLoading,
  finishLoading,
  errorLoading,
  decrypetsData,
}
