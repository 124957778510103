import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "react-bootstrap";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NumberFormat from "react-number-format";

import {
  getOffer,
  chooseOffer,
  confirmRejection,
  rejectOffer,
  resetOffer
} from "../../../stores/actions/offer";

import OfferReject from "../../../assets/images/dashboard/offerreject.png"
import OfferRejected from "../../../assets/images/dashboard/offerrejected.png"


class OffersComp extends Component {

  state = {
    offer: ""
  }

  handleClickOffer = ({ target }) => {
    this.setState({ offer: target.value });
  }

  handleReject = () => {
    let { auth, balance } = this.props;
    this.props.rejectOffer(balance.data.loan_id, auth.token);
  }

  handleRejectConfirmation = () => {
    this.props.confirmRejection();
  }

  handleChooseOffer = () => {
    let { auth } = this.props;
    if (this.state.offer !== '') {
      this.props.chooseOffer(parseInt(this.state.offer), auth.token);
      // this.props.onClick();
    }
  }

  componentDidMount() {
    let { auth, balance: { data } } = this.props;
    this.props.getOffer(data.loan_id, auth.token);
  }

  render() {
    let { offer, onClick, init: { language } } = this.props;
    
    return (
      <>
        {/* reject */}
        {offer.confirmRejection && (
          <div className="side-modal-wrapper offer">
            <div className="top">
              <div className="heading">
                <div className="title">{language.title.chooseOfferSidepane}</div>
              </div>
              <div className="modal-img-wrapper">
                <figure className="modal-img mb-4">
                  <img src={OfferReject} alt="offer" />
                </figure>
                <p className="side-modal-text w-75">{language.subtitle.rejectOfferConfirmation1}</p>
                <p className="side-modal-text w-75">{language.subtitle.rejectOfferConfirmation2}</p>
              </div>
            </div>
            <div className="bottom text-right">
              <div className="line my-3"></div>
              <div className="btn-modal-proceed mr-3" onClick={onClick}>{language.button.back}</div>
              <div className="btn-modal-reject" onClick={this.handleReject}>{language.button.yesRejectAll}</div>
            </div>
          </div>
        )}

        {/* reject confirm */}
        {offer.isRejectOfferSuccess && (
          <div className="side-modal-wrapper offer">
            <div className="top">
              <div className="heading">
                <div className="title">{language.title.chooseOfferSidepane}</div>
              </div>
              <div className="modal-img-wrapper">
                <figure className="modal-img mb-4">
                  <img src={OfferRejected} alt="offer" />
                </figure>
                <p className="side-modal-text w-75">{language.subtitle.offerRejected}</p>
              </div>
            </div>
            <div className="bottom text-center">
              <div className="line my-3"></div>
              <div className="btn-modal-cancel" onClick={onClick}>{language.button.close}</div>
            </div>
          </div>
        )}
        
        {(offer.data || offer.isRejectOfferFailed) && !offer.confirmRejection && (
          <div className="side-modal-wrapper offer">
            <div className="top">
              <div className="heading">
                <div className="title">{language.title.chooseOfferSidepane}</div>
                <div className="text">{language.subtitle.chooseOfferSidepane}</div>
              </div>

              <div className="offer-list">
                <Form>
                  <div className="input-wrapper offer-wrapper">

                    <RadioGroup
                      aria-label="position"
                      name="offer"
                      value={this.state.offer}
                      onChange={this.handleClickOffer}
                      row
                    >
                      {offer.data.map(data => (
                        <FormControlLabel
                          value={data.offer_id}
                          key={data.offer_id}
                          control={<Radio color="primary" />}
                          label={
                            <>
                              <div className="item">
                                <div className="title">Amount</div>
                                <div className="text">
                                  <NumberFormat
                                    value={data.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </div>
                              </div>
                              <div className="item">
                                <div className="title">Rate</div>
                                <div className="text">
                                  <NumberFormat
                                    value={data.interest}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalSeparator="."
                                    decimalScale={2}
                                    suffix="%"
                                  />
                                </div>
                              </div>
                              <div className="item">
                                <div className="title">month</div>
                                <div className="text">{data.tenure}</div>
                              </div>
                              {/* <div className="recomended">
                                <div className="badge badge-success"><i className="fa fa-thumbs-up"></i>Recommended</div>
                              </div> */}
                            </>
                          }
                          labelPlacement="start"
                          className="offer-box"
                          checked={this.state.offer === data.offer_id.toString()}
                        />
                      ))}
                    </RadioGroup>
                  </div>

                </Form>
              </div>

            </div>
            <div className="bottom text-right">
              <div className="line my-3"></div>
              <div
                className="btn-modal-reject mr-3"
                onClick={this.handleRejectConfirmation}
              >
                {language.button.yesRejectAll}
              </div>
              <div
                className="btn-modal-proceed"
                onClick={this.handleChooseOffer}
              >
                {language.button.selectContinue}
              </div>
            </div>
          </div>
        )}

      </>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  offer: state.offer,
  balance: state.balance
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    chooseOffer,
    getOffer,
    rejectOffer,
    confirmRejection,
    resetOffer
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersComp);