import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import "./style.css";
import { connect } from "react-redux";
import {
  Select,
  InputNumber,
  Form,
  Button,
  Input,
  DatePicker,
  Spin,
} from "antd";
import {
  businessInfoKoinvoice,
  putBusinessInfoKoinvoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  actionGetBusinessInfoLocalSubmit,
} from "../../../stores/actions/koinvoiceLoan";
import { putBusinessInfoProfileKoinvoice } from "../../../stores/actions/lmsLimit";
import { Formik } from "formik";
import NumberFormat from "react-number-format";
import cookie from "react-cookies";
import moment from "moment";
import _ from "lodash";
import koinvoiceServices from "../../../services/koinvoice";
import * as yup from "yup";

const { Option } = Select;

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

function BusinessInfo({
  koinvoice: { district, dataBusinessInfoLocalSubmit, businessInfo },
  init: { language, flag },
  actionGetBusinessInfoLocalSubmit,
  // businessInformation,
  business_type,
  putBusinessInfoProfileKoinvoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  businessInfoKoinvoice,
  nextPage,
  nameStore,
  token,
  userId,
}) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const source_of_income = businessInfo?.data?.source_of_income;
  const main_business_category = businessInfo?.data?.business_category;
  const businessInformation =
    JSON.parse(localStorage.getItem(`bisInfo-${userId}`)) !== null &&
    JSON.parse(localStorage.getItem(`bisInfo-${userId}`));
  const businessInfoCookie =
    cookie.load("business-info") !== undefined && cookie.load("business-info");
  const dateInfoProfile =
    JSON.parse(localStorage.getItem("businessInfoDate")) !== null &&
    JSON.parse(localStorage.getItem("businessInfoDate"));
  const [subDistrict, setSubDistrict] = useState([]);
  const [businessCategoryOption, setBusinessCategoryOption] = useState([]);
  const [renderIntialBusinessCategory, setRenderIntialBusinessCategory] = useState(true);
  const businessType =
    business_type === "home_business" ||
    business_type === "individual" ||
    business_type === "industri_rumah_tangga";
  const [npwpValidation, setNpwpValidation] = useState(false);

  const addData = JSON.parse(localStorage.getItem(`add-data-${userId}`));
  const borrower_name = addData?.borrower_name || "";

  const schemaBusinessInfo = yup.object().shape({
    store_name: yup.string().required("Required Store Name"),
    business_type_form: !business_type ? yup.string().required("Required Business Type") : "",
    business_category: yup.string().required("Required Business Category"),
    established_in: yup
      .string()
      .typeError("You must specify Established In")
      .when("business_duration", {
        is: (business_duration) => {
          return (business_duration === false || business_duration === undefined || business_duration === null || business_duration === "")
        },
        then: yup
          .string()
          .required("Required Established Date"),
        otherwise: yup
          .string()
          .typeError("You must specify Established In")
      }
    ),
    business_duration: yup
      .string()
      .when("established_in", {
        is: (established_in) => {
          return (established_in === undefined || established_in === null || established_in === "")
        },
        then: yup
          .string()
          .required("Required Business Duration")
          .max(2, "Business duration cannot exceed 2 digits"),
        otherwise: yup
          .string()
          .max(2, "Business duration cannot exceed 2 digits")
      }
    ),
    financial_information_type: yup.string().required("Required Financial Information Type"),
    average_sales: yup
      .number()
      .typeError("You must specify a number")
      .required("Required Average Sales")
      .min(0, "Average sales cannot be less than 0."),
    npwp: yup.string().required("Required Npwp").max(16,"Npwp Must be 15 or 16 Digits").min(15,"Npwp Must be 15 or 16 Digits"),
    postal_code: yup.string().required("Required Postal Code").length(5,"Must be 5 Digits"),
    business_address: yup.string().required("Required Business Address"),
    business_district: yup.string().required("Required Business District"),
    business_sub_district: yup
      .string()
      .required("Required Business Sub District"),
    source_of_income: yup.string(),
    business_province: yup.string(),
    total_other_income: yup.number().typeError("You must specify a number")
  }, ['established_in', 'business_duration']);

  useEffect(() => {
    window.scrollTo(0, 0);
    businessInfoKoinvoice(token); // eslint-disable-next-line
  }, []);

  function handleChangeEstablishedIn(value, option, setFieldValue) {
    setFieldValue("established_in", option);
  }

  function handleBusinessDurationInputChange(value, option, setFieldValue) {
    const regExp = /[a-zA-Z]/g;

    if(value === null) {
      setFieldValue("business_duration", "");
    } else {
      if(regExp.test(value)){
        setFieldValue("business_duration", "");
      }
      setFieldValue("business_duration", value);
    }
  }

  function actionChangeFinancialInformationType(value, option, setFieldValue) {
    setFieldValue("financial_information_type", option.value);
  }

  function handleAverageSalesInputChange(value, option, setFieldValue) {
    setFieldValue("average_sales", value);
  }

  function handleTotalOtherIncomeInputChange(value, option, setFieldValue) {
    setFieldValue("total_other_income", value);
  }

  function handleChangeSourceOfIncome(value, option, setFieldValue) {
    setFieldValue("source_of_income", value);
  }

  function handleNpwpInputChange(value, option, setFieldValue) {
    setFieldValue(
      "npwp",
      value.target.value.toString().replace(/[.*+?^_${}()\s|[\]\\-]/g, "")
    );
  }

  function handleNibInputChange(value, option, setFieldValue) {
    setFieldValue("nib", value.target.value.toString());
  }

  function actionChangeSearchDistrict(value) {
    // getDistrictKoinvoice(value);
  }

  function actionChangeDistrict(value, option, setFieldValue) {
    // const districtValidation =
    //   district.data && district.data.find((dis) => dis.name === value);
    // setCode(districtValidation.code);
    //setFieldValue("business_district", value);
  }

  function actionChangeSearchSubDistrict(value) {
    // getSubDistrictKoinvoice(`${value}&district_code=${code}`);
    koinvoiceServices
      .actionGetLocationThirdParty({ params: value, token: token })
      .then((res) => {
        if (res?.status === 200 && res?.data !== null) {
          setSubDistrict(res.data);
        }
      });
  }

  function actionChangeSubDistrict(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value
    );
    setFieldValue("business_district", districtSubValidation.district);
    setFieldValue("business_sub_district", districtSubValidation.sub_district);
    setFieldValue("business_province", districtSubValidation.province);
    setFieldValue("business_city", districtSubValidation.city);
    setFieldValue("postal_code", districtSubValidation.postal_code);
  }

  function actionChangeBusinessType(value, option, setFieldValue) {
    setFieldValue("business_type_form", value);
  }

  function actionChangeSearchBusinessCategory(value) {
    koinvoiceServices
      .getBusinessCategoryKoininvoice({ params: value, token: token})
      .then((res) => {
        if (res?.status === 200 && res?.data !== null) {
          setBusinessCategoryOption(res.data);
        }
      });
  }

  function actionChangeBusinessCategory(value, option, setFieldValue) {
    const businessCategoryValidation = businessCategoryOption?.business_category.find(
      (dis) => {
        if(dis.value === value) {
          return dis.value
        }
        return null
      }
    );
    setFieldValue("business_category", businessCategoryValidation);
  }

  function actionMouseEnterBusinessCategory(isFlagChange) {
    if(renderIntialBusinessCategory) {
      koinvoiceServices
        .getBusinessCategoryKoininvoice({ params: "", token: token})
        .then((res) => {
          if (res?.status === 200 && res?.data !== null) {
            setBusinessCategoryOption(res.data);
          }
      });

      setRenderIntialBusinessCategory(false);
    } else if(isFlagChange) {
      koinvoiceServices
        .getBusinessCategoryKoininvoice({ params: "", token: token})
        .then((res) => {
          if (res?.status === 200 && res?.data !== null) {
            setBusinessCategoryOption(res.data);
          }
      });
    }
  }

  function handleSubmit(value) {
    const {
      store_name,
      business_type_form,
      business_category,
      established_in,
      business_duration,
      financial_information_type,
      average_sales,
      npwp,
      nib,
      postal_code,
      business_address,
      business_district,
      business_sub_district,
      business_province,
      business_city,
    } = value;
    if (npwp.length < 15) {
      setNpwpValidation(true);
      return;
    } else {
      setNpwpValidation(false);
    }
    let addBorrower = borrower_name
      ? {
          "business.company_detail.president_director_name": {
            value: borrower_name,
          },
          "business.company_detail.active_persero_name": {
            value: borrower_name,
          },
          "business.company_detail.business_principal_fullname": {
            value: borrower_name,
          },
          "business.company_detail.owner_name": {
            value: borrower_name,
          },
        }
      : {};
    let businessInfoProfile = {
      product: "koin_invoice",
      company_name: {
        value: store_name,
      },
      established_in: {
        value: !established_in 
          ? reformatDate(businessInformation.details.business.company_detail?.established_in
            ?.value) || 
            dateInfoProfile.date
          : established_in.split("-").reverse().join("-"),
      },
      business_duration: {
        value: !business_duration 
          ? businessInformation.details.business.company_detail
              ?.business_age?.value ||
            businessInfoCookie.business_age 
          : business_duration.toString(),
      },
      financial_information_type: {
        value: financial_information_type
      },
      average_sales: {
        value: average_sales.toString(),
      },
      npwp_number: {
        value: npwp,
      },
      address: {
        value: business_address,
      },
      district: {
        value: business_district,
      },
      sub_district: {
        value: business_sub_district,
      },
      province: {
        value: business_province,
      },
      metas: {
        "business.company_detail.business_category": {
          value: business_category.value
        },
        "business.company_detail.postal_code": {
          value: postal_code.toString(),
        },
        "business.company_detail.city": {
          value: business_city,
        },
        "business.company_detail.province": {
          value: business_province,
        },
        "business.company_detail.siup_or_nib": {
          value: nib,
        },
      },
    };
    if(!business_type) {
      let businessTypePutProperty = { value: business_type_form };
      businessInfoProfile.business_type = businessTypePutProperty;
    }
    if (borrower_name) {
      Object.assign(businessInfoProfile.metas, addBorrower);
    }
    Object.assign(
      businessInfoProfile,
      value.source_of_income &&
        value.total_other_income && {
          source_of_income: {
            value: value.source_of_income,
          },
          total_other_income: {
            value: value.total_other_income.toString(),
          },
        }
    );
    putBusinessInfoProfileKoinvoice(
      businessInfoProfile,
      setIsShowLoading,
      nextPage,
      token
    );
    cookie.save("business-info", value, {
      path: "/",
      maxAge: 3600,
    });
    localStorage.setItem(
      "businessInfoDate",
      JSON.stringify({ date: established_in })
    );
    actionGetBusinessInfoLocalSubmit(value);
  }

  function reformatDate(target) {
    if (target) {
      const temp = target.split("-");
      const newVal = temp[2] + "-" + temp[1] + "-" + temp[0];
      return newVal;
    }

    return target
  }

  const options =
    district.data &&
    _.uniqBy(district.data, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));

  const optionsSubDistrict =
    subDistrict &&
    _.uniqBy(subDistrict, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));
  
  let optionsBusinessCategory =
    businessCategoryOption &&
    _.uniqBy(businessCategoryOption.business_category, "value").map((data, index) => {
      if(data.value !== 'Lainnya'){
        return <Option key={data.value}>{data["desc_" + flag]}</Option>
      }
    }
  );

  useEffect(() => {
    actionMouseEnterBusinessCategory(true)
  }, [flag])

  return (
    <div className="kw-businessInfo-wrapper">
      <h3>{language.text.businessInfo}</h3>
      <Formik
        initialValues={{
          store_name: businessInformation && businessInformation.fullname,
          business_category:
            businessInformation.details.business.company_detail
              ?.business_category?.value ||
            businessInfoCookie.business_category,
          established_in:
            reformatDate(businessInformation.details.business.company_detail?.established_in
              ?.value) || dateInfoProfile.date,
          business_duration: businessInformation.details.business.company_detail
              ?.business_age?.value ||
            businessInfoCookie.business_age,
          financial_information_type: businessInformation.details.business.company_detail
              ?.financial_information_type?.value ||
            businessInfoCookie.financial_information_type,
          average_sales:
            businessInformation?.details?.business.business_performace
              ?.average_sales?.value ||
            dataBusinessInfoLocalSubmit.average_sales ||
            businessInfoCookie.average_sales,
          npwp:
            businessInformation.details.business.company_detail?.npwp_number
              ?.value || businessInfoCookie.npwp,
          nib:
            businessInformation.details.business.company_detail?.siup_or_nib
              ?.value || businessInfoCookie.nib,
          business_address:
            businessInformation.details.business.company_detail?.address
              ?.value || businessInfoCookie.business_address,
          business_district:
            businessInformation.details.business.company_detail?.district
              ?.value || businessInfoCookie.business_district,
          business_sub_district:
            businessInformation.details.business.company_detail?.sub_district
              ?.value || businessInfoCookie.business_sub_district,
          business_city:
            businessInformation.details.business.company_detail?.city?.value ||
            businessInfoCookie.business_province,
          business_province:
            businessInformation.details.business.company_detail?.province
              ?.value || businessInfoCookie.business_province,
          postal_code:
            businessInformation.details.business.company_detail?.postal_code
              ?.value || businessInfoCookie.postal_code,
          source_of_income: dataBusinessInfoLocalSubmit.business_sub_district,
          total_other_income: dataBusinessInfoLocalSubmit.total_other_income,
        }}
        validationSchema={schemaBusinessInfo}
        validateOnChange={false}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {" "}
            <Form.Item
              label={language.loanFormBusinessInfo.storeName}
              validateStatus={
                touched.store_name && errors.store_name && "error"
              }
              help={touched.store_name && errors.store_name}
              onBlur={handleBlur}
            >
              {" "}
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                disabled={
                  nameStore ||
                  (businessInformation && businessInformation.fullname)
                    ? true
                    : false
                }
                type="text"
                size="large"
                placeholder="STORE NAME"
                name="store_name"
                onChange={handleChange}
                value={values.store_name}
              />
            </Form.Item>
            {!business_type && (
              <Form.Item
                label={language.loanFormBusinessInfo.businessType}
                validateStatus={
                  errors.business_type_form && "error"
                }
                help={errors.business_type_form}
                onBlur={handleBlur}
              >
                {" "}
                <Select
                  showSearch
                  name="business_type_form"
                  size={"large"}
                  style={{ width: isMobile() ? 500 : "100%" }}
                  value={values.business_type_form}
                  placeholder="PT"
                  onChange={(value, option) =>
                    actionChangeBusinessType(
                      value,
                      option,
                      setFieldValue
                    )
                  }
                >
                  <Option value="pt">PT</Option>
                  <Option value="cv">CV</Option>
                  <Option value="individual">Individual</Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={language.loanFormBusinessInfo.businessCategory}
              validateStatus={
                touched.business_category && errors.business_category && "error"
              }
              help={touched.business_category && errors.business_category}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                defaultValue={values.business_category}
                name="business_category"
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                placeholder={"Event Organizer"}
                onSearch={actionChangeSearchBusinessCategory}
                onMouseEnter={actionMouseEnterBusinessCategory(false)}
                onChange={(value, option) =>
                  actionChangeBusinessCategory(value, option, setFieldValue)
                }
              >
                {optionsBusinessCategory}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.establishedIn}
              validateStatus={
                touched.established_in && errors.established_in && "error"
              }
              help={touched.established_in && errors.established_in}
              onBlur={handleBlur}
            >
              <DatePicker
                name="established_in"
                defaultPickerValue={
                  values.established_in
                    ? moment(values.established_in, "DD-MM-YYYY")
                    : moment("01-01-1990", "DD-MM-YYYY")
                }
                value={
                  values.established_in
                    ? moment(values.established_in, "DD-MM-YYYY")
                    : ""
                }
                format="DD-MM-YYYY"
                onChange={(value, option) =>
                  handleChangeEstablishedIn(value, option, setFieldValue)
                }
                size="large"
                style={{ width: isMobile() ? 500 : "100%" }}
              />
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.businessDuration}
              validateStatus={
                touched.business_duration && errors.business_duration && "error"
              }
              help={touched.business_duration && errors.business_duration}
              onBlur={handleBlur}
            >
              {" "}
              <InputNumber
                id="business_duration"
                name="business_duration"
                placeholder="5"
                min="0"
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                value={values.business_duration}
                onChange={(value, option) => {
                  handleBusinessDurationInputChange(value, option, setFieldValue);
                }}
              />
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.financialInformationType}
              validateStatus={errors.financial_information_type && "error"}
              help={errors.financial_information_type}
            >
              {" "}
              <Select
                size={"large"}
                style={{ width: isMobile() ? 500 : "100%" }}
                value={values.financial_information_type}
                placeholder={language.label.averageMonthlySales}
                onChange={(value, option) =>
                  actionChangeFinancialInformationType(
                    value,
                    option,
                    setFieldValue
                  )
                }
              >
                <Option value="Average Monthly Sales">{language.label.averageMonthlySales}</Option>
                <Option value="Average Purchase Report"> {language.label.averagePurchaseReport}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.everageSalesPerMonth}
              validateStatus={
                touched.average_sales && errors.average_sales && "error"
              }
              help={touched.average_sales && errors.average_sales}
              onBlur={handleBlur}
            >
              <InputNumber
                id="average_sales"
                name="average_sales"
                placeholder="32,333,333"
                min="0"
                style={{ width: isMobile() ? 500 : "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\[Rp]\s?|(,*)/g, "")}
                size="large"
                value={values.average_sales}
                onChange={(value, option) => {
                  handleAverageSalesInputChange(value, option, setFieldValue);
                }}
              />
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.personalNpwp}
              validateStatus={
                (touched.npwp && errors.npwp && "error") ||
                (npwpValidation && "error")
              }
              help={
                (touched.npwp && errors.npwp) ||
                (npwpValidation && "Npwp Must be 15 or 16 Digits")
              }
              onBlur={handleBlur}
            >
              <NumberFormat
                style={{
                  width: isMobile() ? 500 : "100%",
                  height: 40,
                  border: "1px solid #d9d9d9",
                  padding: 10,
                }}
                name="npwp"
                type="tel"
                placeholder="23.233.323.4-323.3234"
                format="##.###.###.#-###.####"
                onChange={(value, option) =>
                  handleNpwpInputChange(value, option, setFieldValue)
                }
                value={values.npwp}
              />
            </Form.Item>
            <Form.Item
              label={`No. SIUP/NIB (Optional)`}
              validateStatus={touched.nib && errors.nib && "error"}
              help={touched.nib && errors.nib}
              onBlur={handleBlur}
            >
              <NumberFormat
                style={{
                  width: isMobile() ? 500 : "100%",
                  height: 40,
                  border: "1px solid #d9d9d9",
                  padding: 10,
                }}
                name="nib"
                type="tel"
                placeholder="1231234"
                onChange={(value, option) =>
                  handleNibInputChange(value, option, setFieldValue)
                }
                value={values.nib}
              />
            </Form.Item>
            <p style={{ color: "#8E8E8E" }}>
              {language.loanFormBusinessInfo.businessAddress}
            </p>
            <Form.Item
              label={language.loanFormBusinessInfo.address}
              validateStatus={
                touched.business_address && errors.business_address && "error"
              }
              help={touched.business_address && errors.business_address}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder={"Jl.Peningakatan Raya xx - Sebelah Toko"}
                type="text"
                size="large"
                name="business_address"
                onChange={handleChange}
                value={values.business_address}
              />
            </Form.Item>
            <Form.Item
              validateStatus={
                touched.business_sub_district &&
                errors.business_sub_district &&
                "error"
              }
              help={
                touched.business_sub_district && errors.business_sub_district
              }
              label={language.form.subdistrict}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.business_sub_district}
                name="business_sub_district"
                //placeholder={"Bojong menteng"}
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchSubDistrict}
                onChange={(value, option) =>
                  actionChangeSubDistrict(value, option, setFieldValue)
                }
              >
                {optionsSubDistrict}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.district}
              validateStatus={
                touched.business_district && errors.business_district && "error"
              }
              help={touched.business_district && errors.business_district}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.business_district}
                // placeholder={"TEBET"}
                name={"business_district"}
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                disabled={true}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchDistrict}
                onChange={(value, option) =>
                  actionChangeDistrict(value, option, setFieldValue)
                }
              >
                {options}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.city}
              validateStatus={
                touched.business_city && errors.business_city && "error"
              }
              help={touched.business_city && errors.business_city}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                disabled={true}
                size="large"
                name="business_city"
                onChange={handleChange}
                value={values.business_city}
              />
            </Form.Item>
            <Form.Item
              label={language.form.province}
              validateStatus={
                touched.business_province && errors.business_province && "error"
              }
              help={touched.business_province && errors.business_province}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                //placeholder={"DKI JAKARTA"}
                disabled={true}
                size="large"
                name="business_province"
                onChange={handleChange}
                value={values.business_province}
              />
            </Form.Item>
            <Form.Item
              label={language.form.postalCode}
              validateStatus={
                touched.postal_code && errors.postal_code && "error"
              }
              help={touched.postal_code && errors.postal_code}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder={"30153"}
                type="number"
                size="large"
                name="postal_code"
                onChange={handleChange}
                value={values.postal_code}
              />
            </Form.Item>
            {businessType && (
              <>
                <p style={{ color: "#8E8E8E" }}>
                  {language.loanFormBusinessInfo.otherSourceOfIncome}
                </p>
                <Form.Item
                  label={language.loanFormBusinessInfo.sourceOfIncome}
                  validateStatus={errors.source_of_income && "error"}
                  help={errors.source_of_income}
                  onBlur={handleBlur}
                >
                  <Select
                    name="source_of_income"
                    size={"large"}
                    placeholder={language.label.salary}
                    onChange={(value, option) =>
                      handleChangeSourceOfIncome(value, option, setFieldValue)
                    }
                    style={{ width: isMobile() ? 500 : "100%" }}
                  >
                    {source_of_income &&
                      source_of_income.map((list, index) => {
                        return (
                          <Option value={list.value} key={index}>
                            {flag === "en" ? list.desc_en : list.desc_id}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={language.loanFormBusinessInfo.totalOtherIncome}
                  validateStatus={errors.total_other_income && "error"}
                  help={errors.total_other_income}
                  onBlur={handleBlur}
                >
                  <InputNumber
                    id="total_other_income"
                    name="total_other_income"
                    placeholder="43,000,000"
                    style={{ width: isMobile() ? 500 : "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\[Rp]\s?|(,*)/g, "")}
                    size="large"
                    value={values.total_other_income}
                    onChange={(value, option) => {
                      handleTotalOtherIncomeInputChange(
                        value,
                        option,
                        setFieldValue
                      );
                    }}
                  />
                </Form.Item>
              </>
            )}
            <Spin
              spinning={isShowLoading}
              style={{ width: isMobile() ? "70%" : "100%" }}
            >
              <Button
                htmlType="submit"
                className="kw-button"
                size="large"
                type="primary"
              >
                {language.button.next}
              </Button>
            </Spin>
          </form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInfoKoinvoice,
      putBusinessInfoKoinvoice,
      putBusinessInfoProfileKoinvoice,
      getDistrictKoinvoice,
      getSubDistrictKoinvoice,
      actionGetBusinessInfoLocalSubmit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfo);
