import React, { Component } from "react";
import { connect } from "react-redux";
import { FooterWrapper } from "./Footer.styles";

// images
import appstore from "../../../assets/images/appstore.png";
import gplay from "../../../assets/images/gplay.png";
import qrcode from "../../../assets/images/qr.svg";

class Footer extends Component {
  render() {
    let { init: { language } } = this.props;
    return (
      <FooterWrapper>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="left">
                  <h2 className="heading">
                    {language.homepage.yourFinanceAllinOnePlace} <br />
                    {language.homepage.letsGrowBig}
                  </h2>
                  <h4 className="text">
                    {language.homepage.startNowForFree}
                  </h4>
                  <div className="download-wrapper">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.koinworks.app&hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="store">
                        <img src={gplay} alt="google play" />
                      </figure>
                    </a>
                    <a
                      href="https://apps.apple.com/id/app/koinworks/id1457026579"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="store">
                        <img src={appstore} alt="app store" />
                      </figure>
                    </a>
                    <span className="text">{language.homepage.or}</span>
                    <figure className="qrcode">
                      <img src={qrcode} alt="qr code" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="row right">
                  <div className="col-4">
                    <h3 className="col-heading">{language.homepage.products}</h3>
                    <div className="footer-nav">
                      <h4 className="heading">{language.homepage.invest}</h4>
                      <a href="https://koinworks.com/super-app/koinrobo/" target="_blank" rel="noopener noreferrer" className="text">KoinRobo</a>
                      <a href="https://koinworks.com/super-app/koinp2p/" target="_blank" rel="noopener noreferrer" className="text">KoinP2P</a>
                    </div>
                    <div className="footer-nav">
                      <h4 className="heading">{language.homepage.borrow}</h4>
                      <a href="https://koinworks.com/super-app/koinbisnis/" target="_blank" rel="noopener noreferrer" className="text">KoinBisnis</a>
                      <a href="!#" target="_blank" rel="noopener noreferrer" className="text">KoinEmployee</a>
                    </div>
                  </div>
                  <div className="col-4">
                    <h3 className="col-heading">{language.homepage.company}</h3>
                    <div className="footer-nav">

                      <a href="https://koinworks.com/super-app/tentang-kami/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.aboutUs}</a>
                      <a href="https://koinworks.com/super-app/en/career/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.career}</a>
                      <a href="/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.mediaCenter}</a>
                      <a href="https://koinworks.com/super-app/faq/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.faq}</a>
                      <a href="https://koinworks.com/blog/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.blog}</a>
                      <a href="https://koinworks.com/super-app/hubungi-kami/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.contactUs}</a>
                    </div>
                  </div>
                  <div className="col-4">
                    <h3 className="col-heading">{language.homepage.legal}</h3>
                    <div className="footer-nav">

                      <a href="https://koinworks.com/super-app/kebijakan-privasi/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.privacyPolicy}</a>
                      <a href="https://koinworks.com/super-app/syarat-penggunaan/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.tou}</a>
                      <a href="https://koinworks.com/super-app/syarat-ketentuan/" target="_blank" rel="noopener noreferrer" className="text">{language.homepage.toc}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="line"></div>
          </div>
        </footer>
      </FooterWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init
});

export default connect(mapStateToProps, null)(Footer);
