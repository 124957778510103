import React from "react";
import { OtpPageWrapper } from "./OtpPage.styles";
import OTPInput from "otp-input-react";

class OtpPage extends React.Component {
  state = {
    formValues: {
      otp: ""
    },
    formErrors: "",
    isMatch: 0,
    resp: {}
  };

  handleCodeChange = async e => {
    this.state.formValues.otp = e;
    this.state.isMatch = 0;
    let msg = "";

    this.setState({
      formErrors: msg
    });
  };

  renderTime = () => this.Fragment;

  renderButton = buttonProps => {
    return (
      <p className="mb-0" {...buttonProps}>
        {buttonProps.remainingTime !== 0
          ? `Resend code in ${buttonProps.remainingTime} sec`
          : "Resend"}
      </p>
    );
  };

  handleClick = e => {
    e.preventDefault();
  };

  render() {
    const { formValues, formErrors, isMatch } = this.state;
    return (
      <OtpPageWrapper>
        <div className="page-content-wrapper">
          <div className="card">
            <figure className="logo-wrapper">
              {/* <img src={logoimg} alt="" className="logo-img" /> */}
            </figure>
            <div className="back-button">
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
              <span>Back</span>
            </div>
            <div className="card-body register-body">
              <h2 className="register-title">Enter OTP from Your Phone</h2>
              <p className="text-center text-note">
                We have sent a verification code to{" "}
                <span className="phone-number">+6287883416895</span>. Please
                enter the code we sent to continue.
              </p>
              <form className="" noValidate autoComplete="off">
                <div className="input-wrapper">
                  <OTPInput
                    value={formValues.otp}
                    onChange={this.handleCodeChange}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    className={`otp-input ${formErrors ? "invalid" : ""}`}
                  />
                  <div className={`${formErrors ? "invalid-feedback" : ""}`}>
                    {formErrors}
                  </div>
                  <div className="resend-wrapper">
                    {/* <ResendOTP
                              handelResendClick={() => console.log("Resend clicked")}
                              className="resend-btn"
                          /> */}
                    {/* <ResendOTP
                              renderButton={this.renderButton}
                              renderTime={this.renderTime}
                              className="resend-btn"
                          /> */}
                  </div>
                </div>
                <div className="input-wrapper">
                  {/* note: remove btn-disable saat opt di input */}
                  {/* <Link to={'/security-code'}> */}
                  <button
                    disabled={!isMatch}
                    className={`btn btn-primary ${
                      isMatch ? "" : "btn-disable"
                    } w-100`}
                    onClick={this.handleClick}
                  >
                    NEXT
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </OtpPageWrapper>
    );
  }
}

export default OtpPage;
