import React, { Component } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./Layout/PrivateRoutes";
import PublicRoute from "./Layout/PublicRoutes";
import { DashboardRoute } from "./Layout/DashboardRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import history from "./util/history";
import FlagsProvider from "./util/FlagsProvider";
import { getDecodedToken } from "./services/token";
import { signOut } from "./services/auth";

import CreateAccount from "./CreateAccount";
import OtpPage from "./OtpPage";
import SecurityCodePage from "./SecurityCodePage";
import SecurityCodeConfirmPage from "./SecurityCodeConfirmPage";
import Login from "./Login";
import LoanApp from "./LoanApp";
import ApplyLoanGaji from "./ApplyLoanGaji";
import ReferCompany from "./ReferCompany";
import GlobalStyles from "./assets/js/globalstyles";

import Homepage from "./Pages/Homepage";
import Dashboard from "./Pages/Dashboard";
import Schedule from "./Pages/Schedule";
import Document from "./Pages/Document";
import Education from "./Pages/Education";

import PageRejected from "./Components/Dashboard/Rejected";
import ViewLoan from "./Components/Dashboard/ViewLoan";
import WaitngDocs from "./Components/Dashboard/WaitngDocs";
import ReqDocs from "./Components/Dashboard/ReqDocs";
import CalculatorKoinVoice from "./Components/CalculatorKoinVoice";
import { hostNameCheckCondition } from "./library/checkHostNameWeb";
import ScheduleKoinInvoice from "./Pages/DashboardKoinInvoice/Schedule";
import EducationWrapper from "./Education";
import SettingsBank from "./SettingsBank";
import DashboardKoinInvoiceApplyLoan from "./Pages/DashboardKoinInvoice/DashboardKoinInvoiceApplyLoan";
import ApplyLimitKoinVoice from "./AppyLimitKoinVoice";
import DocumentListPageLimit from "./DocumentList/DocumentListPageLimit";
import DocumentListPage from "./DocumentList/DocumentListPage";
import ApplyLoanKoinVoice from "./AppyLoanKoinVoice";
import DashboardKoinInvoiceLoanList from "./Pages/DashboardKoinInvoice/DashboardKoinInvoiceLoanList";
import DashboardKoinInvoiceLimit from "./Pages/DashboardKoinInvoice/DashboardKoinInvoiceLimit";
import BusinessInfoConfrimation from "./AppyLimitKoinVoice/components/BusinessInfoConfrimation";
import DashboardUploadLimitApplication from "./Pages/DashboardKoinInvoice/DashboardUploadLimitApplication";
import UnggahDokumen from "./AppyLoanKoinVoice/components/UnggahDokumen";
import LMSApplyLimit from "./LMSApplyLimit";
import LMSBusinessInfo from "./LMSApplyLimit/components/LMSBusinessInfo";
import LMSApplyLoan from "./LMSApplyLoan";
import SubmitResultPage from "./LMSApplyLoan/components/SubmitResultPage";

class App extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
    this.renderLogout = this.renderLogout.bind(this);

    this.state = {
      currentUser: null,
      signedIn: null,
      loggedIn: false,
    };
  }

  componentDidMount() {
    if (getDecodedToken()) {
      this.setState({ loggedIn: true });
    } else {
      this.setState({ loggedIn: false });
    }
  }

  logout() {
    signOut();
    this.setState({ signedIn: null, loggedIn: false });
    history.push("/");
  }

  login(status) {
    this.setState({ loggedIn: status });
  }

  renderLogout() {
    signOut();
    this.setState({ signedIn: null, loggedIn: false });
    return <Redirect to="/" />;
  }

  render() {
    // const userToken = getDecodedToken();
    return (
      <FlagsProvider
        defaults={{
          ki_is_can_apply_loan: true,
        }}
      >
      <Router history={history}>
        <GlobalStyles />
        <Switch>
          <Route path="/" exact component={Homepage} />
          <PublicRoute path="/register" component={CreateAccount} exact />
          <PublicRoute path="/setting/bank" component={SettingsBank} exact />
          <PublicRoute
            restricted={true}
            component={Login}
            login={this.login}
            path="/login"
            exact
          />
          <PublicRoute path="/otp" exact component={OtpPage} />
          <PublicRoute
            path="/security-code"
            exact
            component={SecurityCodePage}
          />
          <PublicRoute
            path="/confirm-security-code"
            exact
            component={SecurityCodeConfirmPage}
          />
          <PrivateRoute path="/loan-app" exact component={LoanApp} />
          <PrivateRoute
            path="/gaji/apply-loan"
            exact
            component={ApplyLoanGaji}
          />
          <PrivateRoute
            path="/koininvoice/calculator-loan"
            exact
            component={CalculatorKoinVoice}
          />
          <PrivateRoute
            path="/koininvoice/apply-limit"
            exact
            component={ApplyLimitKoinVoice}
          />
          <PrivateRoute
            path="/koininvoice/apply-limit/business-info"
            exact
            component={BusinessInfoConfrimation}
          />
          <PrivateRoute
            path="/koininvoice/apply-loan/upload-document"
            exact
            component={UnggahDokumen}
          />
          <DashboardRoute
            path="/dashboard-upload-limit-application"
            exact
            component={DashboardUploadLimitApplication}
          />
          <PrivateRoute
            path="/koininvoice/apply-loan"
            exact
            component={ApplyLoanKoinVoice}
          />{" "}
          <PublicRoute path="/refer-company" exact component={ReferCompany} />
          <PrivateRoute path="/refer-company" exact component={ReferCompany} />
          {hostNameCheckCondition && (
            <DashboardRoute
              path="/dashboard-loan-application"
              exact
              component={DashboardKoinInvoiceApplyLoan}
            />
          )}
          {hostNameCheckCondition && (
            <DashboardRoute
              path="/dashboard-loan-application-list"
              exact
              component={DashboardKoinInvoiceLoanList}
            />
          )}
          {hostNameCheckCondition ? (
            <DashboardRoute
              path="/dashboard-limit-application"
              exact
              component={DashboardKoinInvoiceLimit}
            />
          ) : (
            <DashboardRoute path="/dashboard" exact component={Dashboard} />
          )}
          {hostNameCheckCondition ? (
            <DashboardRoute
              path="/schedule"
              exact
              component={ScheduleKoinInvoice}
            />
          ) : (
            <DashboardRoute path="/schedule" exact component={Schedule} />
          )}
          {hostNameCheckCondition && (
            <DashboardRoute
              path="/document-list-limit"
              exact
              component={DocumentListPageLimit}
            />
          )}
          {hostNameCheckCondition && (
            <DashboardRoute
              path="/document-list-loan"
              exact
              component={DocumentListPage}
            />
          )}
          {hostNameCheckCondition ? (
            <DashboardRoute
              path="/education"
              exact
              component={EducationWrapper}
            />
          ) : (
            <DashboardRoute path="/education" exact component={Education} />
          )}
          <DashboardRoute path="/document" exact component={Document} />
          <DashboardRoute path="/rejected" exact component={PageRejected} />
          <DashboardRoute path="/viewloan" exact component={ViewLoan} />
          <DashboardRoute path="/waitingdocs" exact component={WaitngDocs} />
          <DashboardRoute path="/reqdocs" exact component={ReqDocs} />
          <PublicRoute
            path="/lms/apply-limit/:id/:token([\\w&.-\\/+=]+)?"
            exact
            component={LMSApplyLimit}
          />
          <PublicRoute
            path="/lms/limit/business-info/:id"
            exact
            component={LMSBusinessInfo}
          />          
          <PublicRoute
            path="/lms/apply-loan/submit-result/:id"
            exact
            component={SubmitResultPage}
          />
          <PublicRoute
            path="/lms/apply-loan/:id/:token([\\w&.-\\/+=]+)?"
            exact
            component={LMSApplyLoan}
          />
        </Switch>
      </Router>
      </FlagsProvider>
    );
  }
}

export default App;