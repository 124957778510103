import React, { useState, useEffect } from "react";
import { Button, Card, Spin } from "antd";
import koinvoiceServices from "../services/koinvoice";
import CardDocumentList from "../Components/CardDocumentList";
import { DocumentListStyle } from "./style";
import DrawerAllert from "./DrawerAllert";
import DrawerAllertError from "./DrawerAllert";
import { getApplicationList } from "../stores/actions/koinvoiceLoan";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../config";
import FilerServer from "file-saver";
import ModalImagePreview from "./ModalImagePreview";
import {
  validationImage,
  validationImagePdf,
  validationImageZip,
} from "../library/checkPdfOrZipOrJpg";
import history from "../util/history";
import cookie from "react-cookies";

function DocumentListWrapper({
  actionChangeShowDocumentList = function () { },
  actionChangeKoinInvoiceDashboardLimit = function () { },
  init: { language },
  documentList,
  creditLimitLoan
}) {
  const [reverseAuthDocument, setReverseAuthDocument] = useState([]);
  const [isShowAlertSucsess, setIsShowAlertSucsess] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowAlertError, setIsShowAlertError] = useState(false);
  const [isShowModalImagePreview, setIsShowModalImagePreview] = useState(false);
  const [lisdDocument, setListDocument] = useState(undefined);
  const baseUrl = config.document.url;

  useEffect(() => {
    setReverseAuthDocument(documentList);
  }, []);

  function actionChangeShowAlerDrawer() {
    setIsShowAlertSucsess(!isShowAlertSucsess);
  }

  function actionHandleBackShow() {
    cookie.remove("upload-limitApplication-sucsess", { path: "/" });
    actionChangeShowDocumentList();
    actionChangeKoinInvoiceDashboardLimit();
  }

  function actionChangeShowAlerDrawerError() {
    setIsShowAlertError(!isShowAlertError);
  }

  function download_file(fileURL, fileName, fileType) {
    var oReq = new XMLHttpRequest();
    // The Endpoint of your server
    var URLToPDF = fileURL;

    // Configure XMLHttpRequest
    oReq.open("GET", URLToPDF, true);

    // Important to use the blob response type
    oReq.responseType = "blob";

    //let validationImage = /\.(gif|jpg|jpeg|tiff|png)$/i.test(imagePending);
    // When the file request finishes
    // Is up to you, the configuration for error events etc.
    oReq.onload = function () {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser
      var file = new Blob([oReq.response], {
        type: fileType,
      });

      // Generate file download directly in the browser !
      FilerServer.saveAs(file, fileName);
    };

    oReq.send();
  }

  function getLoginLink(baseUrl) {
    // const timestamp = new Date().getTime() / 1000;
    //const loginCode = btoa(btoa(`${cookie.load("token")}||${timestamp}`));
    //return `${baseUrl}/upload/koininvoice`;
    history.push("/dashboard-upload-limit-application");
  }

  function actionChangeModalImagePreview(params) {
    setListDocument(params);
    if (validationImage(params?.url)) {
      setIsShowModalImagePreview(true);
    } else {
      let link = document.createElement("a");
      if (validationImageZip(params?.url)) {
        download_file(
          params?.url,
          params.name || params.key,
          "application/zip"
        );
      }
      if (!validationImagePdf(params?.url)) {
        link.href = params?.url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  function handleChangeShowImagePreview() {
    setIsShowModalImagePreview(!isShowModalImagePreview);
  }

  return (
    <Spin spinning={isShowLoading}>
      <Card>
        <div>
          <Button size="large" onClick={() => actionHandleBackShow()} disabled={(creditLimitLoan && creditLimitLoan.status === "under_review") ? true : false}>
            {language.button.back}
          </Button>
          <h2
            style={{
              textAlign: "center",
              fontSize: 20,
              color: " #2B486D",
            }}
          >
            {language.loanDashboard.requiredDocument}
          </h2>
        </div>
        {reverseAuthDocument && reverseAuthDocument.length < 1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50%",
            }}
          >
            <img src={require("../assets/images/notfound.png").default} alt="" />
          </div>
        ) : (
          <React.Fragment>
            <DocumentListStyle>
              <div className="kw-documentList">
                {/* <strong>
                  Please upload the document(s) through &nbsp;
                  <a
                    href={getLoginLink(baseUrl)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    this link
                  </a>
                  &nbsp; or through the link sent to your email.
                </strong> */}
                {/* <Button
                  size="large"
                  className="kw-documentList__send-link"
                  onClick={() => actionChangeDocumentSendLink()}
                >
                  {language.loanDashboard.resendEmail}
                </Button> */}
                <Button
                  size="large"
                  style={{ marginTop: 15 }}
                  className="kw-documentList__redirect-submission"
                  onClick={() => getLoginLink(baseUrl)}
                >
                  {language.loanDashboard.goToDocumentSubmission}
                </Button>
              </div>
            </DocumentListStyle>
            {reverseAuthDocument && reverseAuthDocument.length > 0 &&
              reverseAuthDocument.map((list, index) => {
                if(list.document_id !== 0) {
                  return (
                    <React.Fragment key={index}>
                      <CardDocumentList
                        language={language}
                        list={list}
                        label={list.key || list.name}
                        labelUploaded={list.status}
                        actionChangeModalImagePreview={
                          actionChangeModalImagePreview
                        }
                      />
                    </React.Fragment>
                  );
                }
              })}
          </React.Fragment>
        )}
        <DrawerAllert
          visible={isShowAlertSucsess}
          image={require("../assets/images/dashboard/mailresend.png").default}
          onClose={actionChangeShowAlerDrawer}
          labelText={"We Have Sent You An Email"}
          paragraphText={
            "Please check your email and upload all the documents required."
          }
        />
        <ModalImagePreview
          isModalVisible={isShowModalImagePreview}
          list={lisdDocument}
          handleCancel={handleChangeShowImagePreview}
        />
        <DrawerAllertError
          visible={isShowAlertError}
          image={require("../assets/images/dashboard/mailnotsend.png").default}
          onClose={actionChangeShowAlerDrawerError}
          labelText={"We Were Unable to Send An Email to You "}
          paragraphText={
            "Please make sure your device is connected to the internet to continue. "
          }
        />
      </Card>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
  init: state.init,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getApplicationList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentListWrapper);
