import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { 
  getRequiredDocuments,
  getLoanContracts,
  getApprovedDocuments
} from "../stores/actions/document";
import { Breadcrumb, Pagination, Tab, Nav, OverlayTrigger, Popover, Modal } from "react-bootstrap";

// import Modal from 'react-modal';
// import SlidingPane from 'react-sliding-pane';
// import 'react-sliding-pane/dist/react-sliding-pane.css';

import imgDocument from "../assets/images/dashboard/document.png"
import imgContract from "../assets/images/dashboard/contract.png";
import imgApprove from "../assets/images/dashboard/approved.png";

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      url: null,
      isNotImage: false
    };
  }

  getStatusClass = status => {
    switch (status) {
      case "pending":
        return "badge-success";
      case "not_uploaded":
        return "badge-secondary";
      case "rejected":
        return "badge-danger";
      default:
        return "badge-primary";
    }
  }

  viewDocument = (name,url) => {
    this.setState({
      show: true,
      url: url,
      name: name
    })
  }

  // modal
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  componentDidMount() {
    let { auth } = this.props;
    this.props.getRequiredDocuments(auth.token, '&status=pending%2Crejected%2Cnot_uploaded');
    this.props.getApprovedDocuments(auth.token);
    this.props.getLoanContracts(auth.token);
  }

  render() {
    let {
      document,
      init: { language }
    } = this.props;
    let active = 2;
    let items = [];
    for (let number = 1; number <= 4; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active}>
          {number}
        </Pagination.Item>,
      );
    }

    return (
      <div className="dashboard" ref={ref => this.el = ref}>
        <div className="dashboard-title">
          <Breadcrumb>
            <Breadcrumb.Item href="#">{language.label.home}</Breadcrumb.Item>
            <Breadcrumb.Item active href="#">{language.label.document}</Breadcrumb.Item>
          </Breadcrumb>
          <h3 className="title">
            {language.label.document}
          </h3>
        </div>

        <Tab.Container id="left-tabs-example" defaultActiveKey="doc">
          <div className="row document-tab">
            <div className="col-sm-3">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="doc">
                    <span>{language.title.requiredDocument}
                      <span className="badge badge-notification">&nbsp;</span>
                    </span>
                    <i className="fa fa-chevron-right"></i>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="contract">
                    <span>{language.title.loanContract}</span>
                    <i className="fa fa-chevron-right"></i>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="approved">
                    <span>{language.label.approved}</span>
                    <i className="fa fa-chevron-right"></i>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-sm-9">
              <Tab.Content>
                <Tab.Pane eventKey="doc">
                  <div className="row">
                    <div className="col-12">
                      <div className="card dash-card">
                        <div className="card-body">
                          <h3 className="title-body">{language.title.requiredDocument}</h3>

                          {/* notfound */}
                          {document.getRequiredFailed && (
                            <div className="notfound-wrapper">
                              <figure className="dashboard-img" style={{ marginTop: "35px" }}>
                                <img src={imgDocument} alt="active loan" />
                              </figure>
                              <h5 className="title">{language.title.noNewReqDocument}</h5>
                              <p className="text" style={{ marginBottom: "35px" }}>
                                {language.subtitle.noNewReqDocument}
                              </p>
                            </div>
                          )}

                          {document.getRequiredDocs && (
                            document.requiredDocs.map(doc =>(
                              <div className="card card-list" key={doc.name}>
                                <div className="row">
                                  <div className="col-5">
                                    <div className="wrapper">
                                      <span className="name">{doc.name.replace(/_/g, ' ')}</span>
                                      <OverlayTrigger
                                        trigger="click"
                                        key="bottom"
                                        placement="bottom"
                                        overlay={
                                          <Popover id="uploaded">
                                            <Popover.Content>
                                              {doc.notes}
                                            </Popover.Content>
                                          </Popover>
                                        }
                                      >
                                        {doc.status !== 'not_uploaded' ? (
                                          <span className={`badge ${this.getStatusClass(doc.status)}`}>
                                            {doc.tag}
                                          </span>
                                        ) : (
                                          <span className={`badge ${this.getStatusClass(doc.status)}`}>
                                            {doc.status}
                                          </span>
                                        )}
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <span className="status">{language.label.uploadedOn} 17/05/2019</span>
                                  </div>
                                  <div className="col-3 text-right pr-4">
                                    <span
                                      className="action"
                                      onClick={() => this.viewDocument(doc.name,doc.url)}
                                    >
                                      {language.label.view}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="contract">
                  <div className="row">
                    <div className="col-12">
                      <div className="card dash-card">
                        <div className="card-body">
                          <h3 className="title-body">{language.title.loanContract}</h3>

                          {/* notfound */}
                          {document.getContractFailed && (
                            <div className="notfound-wrapper">
                              <figure className="dashboard-img" style={{ marginTop: "35px" }}>
                                <img src={imgContract} alt="active loan" />
                              </figure>
                              <h5 className="title">{language.title.noContractGenerated}</h5>
                              <p 
                                className="text" style={{ marginBottom: "35px" }}
                                dangerouslySetInnerHTML={ {__html: language.subtitle.noContractGenerated} }
                              />
                                
                            </div>
                          )}

                          {document.getContract && (
                            document.loanContract.map(contract => (
                              <div className="card card-list" key={contract.id}>
                                <div className="row">
                                  <div className="col-6">
                                    <div className="wrapper">
                                      <span className="name">
                                        <span className="title">
                                          {language.title.loanContract} {contract.code}
                                        </span> <br />
                                        <span className="text">
                                          {language.label.loan} ID : {contract.code}
                                        </span>
                                      </span>
                                      <OverlayTrigger
                                        trigger="click"
                                        key="bottom"
                                        placement="bottom"
                                        overlay={
                                          <Popover id="uploaded">
                                            {/* <Popover.Content>
                                            Rejection reason here
                                          </Popover.Content> */}
                                          </Popover>
                                        }
                                      >
                                        <span className="badge badge-success">new</span>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    {contract.status === 'unsigned' && (
                                      <span className="status">Not signed yet</span>
                                    )}
                                    {contract.status === 'signed' && (
                                      <span className="status">Signed</span>
                                    )}
                                  </div>
                                  <div className="col-2 text-right pr-4">
                                    {contract.action === 'Download' ? (
                                      <a target="_blank" rel="noopener noreferrer" href={contract.link}>
                                        <span className="action">{contract.action}</span>
                                      </a>
                                    ) : (
                                      <span 
                                        className="action"
                                        onClick={()=>this.viewDocument(contract.code,contract.link)}
                                      >
                                        {contract.action}
                                      </span>
                                    )}                                    
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="approved">
                  <div className="row">
                    <div className="col-12">
                      <div className="card dash-card">
                        <div className="card-body">
                          {document.getApprovedFailed && (
                            <div className="notfound-wrapper">
                              <h3 className="title-body">{language.label.approved}</h3>
                              <figure className="dashboard-img" style={{ marginTop: "35px" }}>
                                <img src={imgApprove} alt="active loan" />
                              </figure>
                              <h5 className="title">{language.title.noDocumentYet}</h5>
                              <p className="text" style={{ marginBottom: "35px" }}>
                                {language.subtitle.noDocumentYet}
                              </p>
                            </div>
                          )}                          
                          {document.getApprovedDocs && (
                            document.approvedDocs.map(doc =>(
                              <div className="card card-list" key={doc.name}>
                                <div className="row">
                                  <div className="col-5">
                                    <div className="wrapper">
                                      <span className="name">{doc.name.replace(/_/g, ' ')}</span>
                                      <OverlayTrigger
                                        trigger="click"
                                        key="bottom"
                                        placement="bottom"
                                        overlay={
                                          <Popover id="uploaded">
                                            {/* <Popover.Content>
                                            Rejection reason here
                                          </Popover.Content> */}
                                          </Popover>
                                        }
                                      >
                                        <span className={`badge ${this.getStatusClass(doc.status)}`}>
                                          {doc.tag}
                                        </span>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <span className="status">{language.label.expiredOn} 17/05/2019</span>
                                  </div>
                                  <div className="col-3 text-right pr-4">
                                    <span
                                      className="action"
                                      onClick={() => this.viewDocument(doc.name, doc.url)}
                                    >
                                      {language.label.view}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>

        <Modal 
          show={this.state.show}
          onHide={this.handleClose}
          size='lg'
        >
          <Modal.Body>
            <iframe 
              className="w-100"
              title={this.state.name}
              style={{ height: '460px'}}
              src={this.state.url} 
            />
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  document: state.document
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getRequiredDocuments,
    getLoanContracts,
    getApprovedDocuments
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);