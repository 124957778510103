const initState = {
  data: null,
  detail: null
};

const clearanceReducer = (state = initState, action) => {
	switch (action.type) {
    case "RESET_CLEARANCE_DETAIL": 
      return { ...state, getDetail: false, detail: null }
    case "GET_CLEARANCE_SUCCESS":
      return { ...state, data: action.payload }
    case "GET_CLEARANCE_FAILED":
      return { ...state, isGetClearanceFailed: true }
    case "GET_DETAIL_LOADING":
      return { ...state, getDetailLoading: true }
    case "GET_DETAIL_SUCCESS":
      return { ...state, getDetailLoading: false, getDetail: true, detail: action.payload }
    case "GET_DETAIL_FAILED":
      return { ...state, detailErrors: action.payload }
    case "NOT_ENOUGH_BALANCE":
      return { ...state, isNotEnoughBalance: true, clearanceAmount: action.payload }
    case "REQUEST_CLEARANCE_FAILED":
      return { ...state, isRequestFailed: true, requestError: action.payload }
    case "REQUEST_PAYMENT_SUCCESS":
      return { ...state, isPaymentSuccess: true }
    case "REQUEST_PAYMENT_FAILED":
      return { ...state, isPaymentFailed: true, paymentError: action.payload }
    default:
      return state;
  }
};

export default clearanceReducer;
