import React, { useEffect } from "react";
import { Route } from "react-router-dom";

import bgimg from "../assets/images/bgimg.png";
import imgAcc from "../assets/images/img-acc1.png";
import imgAccKoinInvoice from "../assets/images/Web2.0-RegisterPage-First.png";
import imgDot from "../assets/images/dot.png";
import Navigation from "../Navigation/Navigation";
import { hostNameCheckCondition } from "../library/checkHostNameWeb";
import { openNotificationWithIcon } from "../library/notifications";
import koinvoiceServices from "../services/koinvoice";
import history from "../util/history";
import cookie from 'react-cookies'

const PublicRoute = ({ component: Component, restricted, login, ...rest }) => {

  useEffect(() => {
    if (cookie.load("token")) {
      koinvoiceServices.actionGetCreditLimitLoan({}).then((res) => {
        if (res?.status === 200) {
          if (res?.data?.status === "") {
            history.push("/koininvoice/apply-limit");
          } else {
            if (res?.data?.status !== "") {
              history.push("/dashboard-limit-application");
            }
          }
        } else {
          openNotificationWithIcon("error", res?.message?.en);
        }
      });
    }
  }, [])

  return (
    <Route
      {...rest}
      render={(props) =>
        <div className="container-fluid layout">
          <div className="row">
            <div
              className={
                hostNameCheckCondition
                  ? "col-5 p-0 .d-none .d-sm-block"
                  : "col-6 p-0 .d-none .d-sm-block"
              }
            >
              <figure className="bg-wrapper">
                <img
                  className={hostNameCheckCondition ? "" : "img-acc"}
                  src={hostNameCheckCondition ? imgAccKoinInvoice : imgAcc}
                  alt=""
                />
                {hostNameCheckCondition ? (
                  ""
                ) : (
                  <img className="img-bg" src={bgimg} alt="" />
                )}
              </figure>
            </div>
            <div
              className={
                hostNameCheckCondition
                  ? "col-12 col-sm-7 p-0"
                  : "col-12 col-sm-6 p-0"
              }
            >
              <Navigation />
              <div className="bgcontent-wrapper">
                <figure className="bg-dot">
                  <img className="img-dot" src={imgDot} alt="" />
                </figure>
                <div className="bg-content">
                  <Component {...props} login={login} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default PublicRoute;
