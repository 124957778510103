import React, { useEffect, useState } from "react";
import { Steps, Button } from "antd";
import cookie from "react-cookies";
import BusinessInfo from "./BusinessInfo";
import { BusinessInfoStyle } from "./style";
import Personalnfo from "./Personalnfo";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShareHolder from "./ShareHolder";
import OwnerDirectorInfo from "./OwnerDirectorInfo";
import UnggahDokumen from "../UnggahDokumen";
import history from "../../../util/history";
import { ApplyLoanKoinVoiceStyle } from "../../style";

const { Step } = Steps;

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 1180) return true;
  return false;
};

function LMSBusinessInfo({
    init: { language },
    match
  }
) {
  const userId = match.params.id;
  const paramBO = userId ? JSON.parse(localStorage.getItem(userId)) : {};
  const businessInformation =
    JSON.parse(localStorage.getItem(`bisInfo-${userId}`)) !== null &&
    JSON.parse(localStorage.getItem(`bisInfo-${userId}`));
  const nameStore = businessInformation?.fullname;
  const [current, setCurrent] = useState(
    cookie.load("cl") !== undefined ? parseInt(cookie.load("cl")) : 0
  );
  const [guarantorShareHolder, setGuarantorShareHolder] =
    useState("Shareholder");
  // let { business_type } = businessInformation?.details?.business?.company_detail;
  let business_type = businessInformation?.details?.business?.company_detail?.business_type || {};
  let marital_status = businessInformation?.details?.personal?.user_information?.marital_status || ""; 

  // useEffect(() => {
    
    
  //   // if (parseInt(cookie.load("displayPage")) === 2) {
  //   //   return;
  //   // } else {
  //   //   history.push("/lms/apply-limit");
  //   // }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (businessInformation) {
      if (
        (business_type?.value === "individual" ||
        business_type?.value === "home_business") &&
        ["KAWIN", "MENIKAH"].includes(marital_status.value)
      ) {
        setGuarantorShareHolder("Spouse");
      } else if (
        (business_type?.value === "individual" ||
        business_type?.value === "home_business") &&
        ["BELUM KAWIN", "CERAI MATI", "CERAI HIDUP"].includes(
          marital_status.value
        )
      ) {
        setGuarantorShareHolder("Guarantor");
      }
      if (business_type?.value === "pt" || business_type?.value === "pt/cv") {
        setGuarantorShareHolder("Share Holder");
      }
    } // eslint-disable-next-line
  }, [businessInformation]);

  function next() {
    const currents = current + 1;
    cookie.save("cl", currents, {
      path: "/",
      maxAge: 3600,
    });
    setCurrent(
      parseInt(cookie.load("cl")) !== undefined &&
        parseInt(cookie.load("cl"))
    );
  }

  function prev() {
    const currents = current - 1;
    cookie.save("cl", currents, {
      path: "/",
      maxAge: 3600,
    });
    setCurrent(
      parseInt(cookie.load("cl")) !== undefined &&
        parseInt(cookie.load("cl"))
    );
  }

  const steps = [
    {
      title: "Business",
      content: (
        <BusinessInfo
          nextPage={next}
          business_type={business_type?.value}
          businessInformation={businessInformation}
          nameStore={nameStore}
          token={paramBO.token}
          userId={paramBO.userId}
        />
      ),
    },
    {
      title: "Personal",
      content: (
        <Personalnfo
          nextPage={next}
          businessInformation={businessInformation}
          prevPage={prev}
          token={paramBO.token}
          userId={paramBO.userId}
        />
      ),
    },
    {
      title:
        business_type?.value === "individual" ||
        business_type?.value === "home_business"
          ? "Owner"
          : "Director",
      content: (
        <OwnerDirectorInfo
          marital_status={marital_status.value}
          business_type={business_type?.value}
          nextPage={next}
          businessInformation={businessInformation}
          prevPage={prev}
          token={paramBO.token}
          userId={paramBO.userId}
        />
      ),
    },
    {
      title:
        business_type?.value === "cv" ||
        business_type?.value === "pt" ||
        business_type?.value === "pt/cv"
          ? "Shareholder"
          : "Guarantor",
      content: (
        <ShareHolder
          nextPage={next}
          guarantorShareHolder={guarantorShareHolder}
          marital_status={marital_status.value}
          business_type={business_type?.value}
          prevPage={prev}
          businessInformation={businessInformation}
          token={paramBO.token}
          userId={paramBO.userId}
        />
      ),
    },
    {
      title: "Done",
      content: (
        <UnggahDokumen 
          business_type={business_type?.value}
          token={paramBO.token}
          userId={paramBO.userId}
        />
      ),
    },
  ];
  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <BusinessInfoStyle>
          <Steps
            progressDot
            direction={isMobile() ? "horizontal" : "vertical"}
            current={current}
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>{" "}
          {current === 0 && (
            <Button
              size="large"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                marginTop: 15,
              }}
              onClick={() => history.goBack()}
            >
              <ArrowLeftOutlined /> {language.button.back}
            </Button>
          )}
          {steps[current].content}
        </BusinessInfoStyle>
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LMSBusinessInfo);
