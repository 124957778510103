import React from "react";
import { connect } from "react-redux";
import { BannerMainWrapper } from "./BannerMain.styles";
import { Link } from "react-router-dom";
import history from "../../../util/history";
// images
import ojkLogo from "../../../assets/images/ojk.svg";
import koinworksLogo from "../../../assets/images/koinemployee.svg";
import mainBannerImg from "../../../assets/images/main-banner.png";
import mainBannerKoinInvoice from "../../../assets/images/main-banner-koininvoice.png";
import gplay from "../../../assets/images/gplay.png";
import appstore from "../../../assets/images/appstore.png";
import { hostNameCheckCondition } from "../../../library/checkHostNameWeb";
import { Modal, Button } from "antd";
import "./style.css";
import cookie from "react-cookies";

class BannerMain extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  state = {
    showGalleryVideo: false,
  };

  showHideVideo = () => {
    this.setState({ showGalleryVideo: !this.state.showGalleryVideo });
  };

  itemVideo = () => {
    return (
      <React.Fragment>
        {this.state.showGalleryVideo ? (
          <Modal
            wrapClassName="modal-video-slider"
            title=" "
            visible={this.state.showGalleryVideo}
            onCancel={this.showHideVideo}
            centered
          >
            <div className="video-wrapper">
              <iframe
                title="koinworks-video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GRCZRqEy2_Q"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Modal>
        ) : (
          <figure
            className="main-banner-img"
            onClick={() => this.showHideVideo()}
          >
            <img src={mainBannerKoinInvoice} alt="koinworks" />
            <div className="kw-btn-thumbnail-play" />
          </figure>
        )}
      </React.Fragment>
    );
  };

  render() {
    let {
      init: { language },
    } = this.props;
    const handleScrollTo = (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 1333,
        left: 0,
        behavior: "instant",
      });
    };

    const redirectConditionFunction = () => {
      history.push({
        pathname: "/register",
        state: {
          noLoan: "no-calculator",
        },
      });
      cookie.remove("loanApply", { path: "/dashboard-limit-application" });
    };

    return (
      <BannerMainWrapper>
        <section
          className="container main-banner"
          style={{ marginTop: "40px" }}
        >
          <div className="row">
            <div className="col-12 col-sm-6">
              <figure className="main-banner-ojk">
                <img src={ojkLogo} alt="trusted ojk" />
              </figure>
              {hostNameCheckCondition ? (
                ""
              ) : (
                <figure className="main-banner-logo">
                  <img src={koinworksLogo} alt="koinworks" />
                </figure>
              )}
              <div className="content-wrapper">
                <h2 className="main-heading">
                  {hostNameCheckCondition
                    ? language.title.tittleKoinInvoice
                    : language.homepage.makeTheMostTitle}
                </h2>
                <div className="main-text">
                  {hostNameCheckCondition ? (
                    <React.Fragment>
                      <p>{language.homepage.koinInvoiceBannerMainSkema}</p>
                      {/* <ul>
                        <li>
                          {language.homepage.koinInvoiceBannerMainListOne}
                        </li>
                      <li>
                          {language.homepage.koinInvoiceBannerMainListTwo}
                        </li>
                        <li>
                          {language.homepage.koinInvoiceBannerMainListThree}
                        </li>
                      </ul>
                      <span style={{ fontSize: 12, lineHeight: 0 }}>
                        {language.homepage.koinInvoiceBannerMainBiaya}
                      </span> */}
                    </React.Fragment>
                  ) : (
                    language.homepage.makeTheMostText
                  )}
                </div>
                {hostNameCheckCondition ? (
                  <Button
                    onClick={redirectConditionFunction}
                    className="kw-button-applyNowHome"
                    size="large"
                  >
                    {language.homepage.applyNowKoinInvoice}
                  </Button>
                ) : (
                  <Link to="/login">
                    <button className="btn btn-primary my-4">
                      {language.homepage.applyNow}
                    </button>
                  </Link>
                )}
                {hostNameCheckCondition ? (
                  <a href="#calculator">
                    {/* <Button
                      className="kw-button-simulation"
                      size="large"
                    >
                      {language.homepage.loanSimulationKoinInvoice}
                    </Button> */}
                  </a>
                ) : (
                  <button
                    className="btn btn-primary-outline btn-simulate my-4"
                    onClick={handleScrollTo}
                  >
                    {language.homepage.loanSimulation}
                  </button>
                )}
                <div className="store">
                  <p className="text">{language.homepage.applyThroughApp}</p>
                  <div className="store-link">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.koinworks.app&hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="store-wrapper">
                        <img src={gplay} alt="google play" />
                      </figure>
                    </a>
                    <a
                      href="https://apps.apple.com/id/app/koinworks/id1457026579"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="store-wrapper">
                        <img src={appstore} alt="app store" />
                      </figure>
                    </a>
                  </div>
                </div>

                <div className="reg-wrapper">
                  <h5 className="heading">
                    {hostNameCheckCondition
                      ? language.homepage.areYouKoinInvocieProject
                      : language.homepage.areYouEmployer}
                  </h5>
                  <Link to="/register">
                    <span className="text">
                      {language.homepage.registerHere}{" "}
                      <i className="fa fa-long-arrow-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 d-none d-sm-block">
              {hostNameCheckCondition ? (
                this.itemVideo()
              ) : (
                <figure className="main-banner-img">
                  <img src={mainBannerImg} alt="koinworks" />
                </figure>
              )}
            </div>
          </div>
        </section>
      </BannerMainWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
});

export default connect(mapStateToProps, null)(BannerMain);
