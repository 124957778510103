import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Breadcrumb } from "react-bootstrap";
import { getLoans } from "../../stores/actions/loan";

// import DashTitle from "./DashTitle";
import SortFilterLoan from "../SortFilterLoan";
import TableLoan from "../TableLoan";

class ViewLoan extends Component {
  state = {
    page: 1,
    sortType: 'desc',
    sortBy: 'request_date',
    filter: [],
    filtered: []
  }

  changeSortType = ({ target }) => {
    let { auth } = this.props;
    this.setState({ sortType: target.value });
    this.props.getLoans(auth.token, this.state.page, target.value, this.state.sortBy);
  }

  changeSortBy = ({ target }) => {
    let { auth } = this.props;
    this.setState({ sortBy: target.value });
    this.props.getLoans(auth.token, this.state.page, this.state.sortType, target.value);
  }

  changeFilter = ({target}) => {
    let { filter } = this.state;
    if (target.checked === true) {
      filter.push(target.value)
    } else {
      let index = filter.indexOf(target.value);
      if (index !== -1) filter.splice(index, 1);
    }
    this.setState({ filter })
    this.filterLoanByStatus(filter);
  }

  filterLoanByStatus = status => {
    let data = this.props.loan.loans;
    let { filtered } = this.state;
    filtered = data.filter(obj => status.includes(obj.status))
    this.setState({filtered})
  }

  componentDidMount() {
    let { auth } = this.props;
    let { page, sortType, sortBy } = this.state;

    this.props.getLoans(auth.token, page, sortType, sortBy);
  }
  
  render() {
    let { init: { language }, loan } = this.props;
    let { filter, filtered } = this.state;
    let loanData = filtered.length === 0 && filter.length === 0 ? 
        loan.loans : filtered;

    return (
      <div className="dashboard">
        <div className="dashboard-title">
          <Breadcrumb>
            <Breadcrumb.Item href="#">{language.label.home}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{language.label.viewReqLoan}</Breadcrumb.Item>
            <Breadcrumb.Item active href="#">{language.label.yourLoan}</Breadcrumb.Item>
          </Breadcrumb>
          <h3 className="title">
            {language.label.yourLoan}
          </h3>
        </div>


        <div className="row">

          <div className="col-sm-12">
            <div className="card dash-card">
              <div className="card-body">
                <div className="card-content">
                  <div className="top">
                    <SortFilterLoan 
                      language={language}
                      handleSortType={this.changeSortType}
                      handleSortBy={this.changeSortBy}
                      handleFilter={this.changeFilter}
                      sortInput={{
                        sortType: this.state.sortType,
                        sortBy: this.state.sortBy
                      }}
                    />
                    <TableLoan loanData={loanData} />
                  </div>
                  <div className="bottom text-right">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  init: state.init,
  auth: state.auth,
  loan: state.loan
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getLoans
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLoan);