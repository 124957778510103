import React, { useEffect } from "react";
import { ApplyLoanKoinVoiceStyle } from "./style";
import { connect } from "react-redux";
import DetailsLoan from "./components/DetailsLoan/DetailsLoan";
import {
  businessInformationKoinvoice,
  getApplicationList,
} from "../stores/actions/koinvoiceLoan";
import { getLoanLimit } from "../stores/actions/koinvoiceLoanDashboard";
import { bindActionCreators } from "redux";

function ApplyLoanKoinVoice({
  getLoanLimit,
  getApplicationList,
  businessInformationKoinvoice,
}) {

  useEffect(() => {
    businessInformationKoinvoice();
    getLoanLimit();
    getApplicationList(); // eslint-disable-next-line
  }, []);

  return (
    <ApplyLoanKoinVoiceStyle>
      <div className="kw-applyLoanKoinVoice">
        <DetailsLoan />
      </div>
    </ApplyLoanKoinVoiceStyle>
  );
}

const mapStateToProps = (state) => ({
  koinvoice: state.koinvoice,
  koinvoiceDashboard: state.koinvoiceDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      businessInformationKoinvoice,
      getLoanLimit,
      getApplicationList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyLoanKoinVoice);
