import styled from "styled-components";

export const Section = styled.div`
  .kw-mainLayout-wrapper {
    background-color: white;
    position: relative;
    top: 60px;
    max-width: 480px;
    width: 100%;
    box-sizing: border-box;
    min-height: calc(100vh - 60px);
    margin: 0px auto;
  }
`;
