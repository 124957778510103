
import { PATH_AUTHENTICATION } from "../../services/path/koinvoice/authentication";
import { apiPostWithoutToken } from "../../services";
import { serviceWithSignature, serviceWithoutToken } from "../../services/httpClient";

async function checkAuthPreparation(props) {
  const actionChangeLoadingShow = props.actionChangeLoadingShow
    ? props.actionChangeLoadingShow
    : function() {};
  const actionChangeAlert = props.actionChangeAlert ? props.actionChangeAlert : function() {};
  let params = props.params;
  let response = "";
  actionChangeLoadingShow(true);
  try {
    response = await apiPostWithoutToken(
      PATH_AUTHENTICATION.AUTH_PREPARATION,
      params
    );
    actionChangeLoadingShow(false);
    actionChangeAlert(false);
  } catch (error) {
    actionChangeAlert(true);
    actionChangeLoadingShow(false);
    response = error.response;
  }
  return response && response.data;
}

const sendOtp = request => {
  return new Promise((resolve, reject) => {
    serviceWithSignature()
      .request({
        method: "POST",
        url: PATH_AUTHENTICATION.SEND_OTP,
        data: request
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.response);
      });
  });
};

const confirmSendOtp = request => {
  return new Promise((resolve, reject) => {
    serviceWithoutToken()
      .request({
        method: "POST",
        url: PATH_AUTHENTICATION.CONFIRM_OTP,
        data: request
      })
      .then(response => {
        const { token } = response.data.data;
        resolve(localStorage.setItem("accessToken", token))
        resolve(response.data);
      })
      .catch(error => {
        reject(error.response);
      });
  });
};

const authentication = {
  checkAuthPreparation: checkAuthPreparation,
  sendOtp: sendOtp,
  confirmSendOtp : confirmSendOtp
};

export default authentication;
