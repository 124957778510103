const BASE_URL = process.env.REACT_APP_API_MAIN_SERVICE;
const OCR_URL = process.env.REACT_APP_OCR_URL;

function imgUpload(data) {
  let path = '/common/v1/upload-new';
  const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
  };

  return fetch(BASE_URL + path, requestOptions)
      .then(handleResponse)
      .then(resp => {
          return resp;
      });
}

function ktpValidation(img) {
  const url = OCR_URL + '/v2/ktp_validation';
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  const requestOptions = {
    method: "POST",     
    headers: new Headers({
      'x-api-key': 'nd1CvmAfMR3qo5gkFhSr9x5gr1y7PvlabjFHXmvi'
    }),
    body: JSON.stringify({ktp_link : img[0]})
  };

  return fetch(proxyUrl + url, requestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    });
}

function ktpValidationKoinVoice(img) {
  const url = OCR_URL + 'ktp-validation';
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  const requestOptions = {
    method: "POST",     
    headers: new Headers({
      'x-api-key': 'nd1CvmAfMR3qo5gkFhSr9x5gr1y7PvlabjFHXmvi'
    }),
    body: JSON.stringify({ktp_link : img})
  };

  return fetch(proxyUrl + url, requestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    });
}

function facePhotoValidation(img, ktp) {
  const url = OCR_URL + '/face_matching';
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  const requestOptions = {
    method: "POST",
    headers: { 
      "x-api-key": "nd1CvmAfMR3qo5gkFhSr9x5gr1y7PvlabjFHXmvi"
    },
    body: JSON.stringify({
      ktp_link : ktp,
      selfie: img
    })    
  };
  
  return fetch(proxyUrl + url, requestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    });
}

function kkValidation(params) {
  const url = OCR_URL + '/ocr_kk';
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  const requestOptions = {
    method: "POST",
    headers: { 
      "x-api-key": "nd1CvmAfMR3qo5gkFhSr9x5gr1y7PvlabjFHXmvi"
    },
    body: JSON.stringify({
      url : params
    })    
  };
  
  return fetch(proxyUrl + url, requestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          if (response.status === 401) {
              window.location.reload(true);
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export const upload = {
  kkValidation,
  imgUpload,
  ktpValidation,
  facePhotoValidation,
  ktpValidationKoinVoice
};