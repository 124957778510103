import styled from "styled-components";

export const DashboardLayoutKoinInvoiceStyle = styled.div`
  .ant-menu-horizontal > .ant-menu-item-selected {
    color: #071e35;
    border-bottom: unset;
    font-weight: bold;
  }
  .kw-dashboardLayout-close {
    text-align: right;
    display: block;
    font-weight: bold;
    cursor: pointer;
  }

  @media screen and (max-width: 900px) {
    .kw-dashboardLayout-close {
      text-align: left;
    }
  }
  .kw-mainLayout-wrapper {
    .ant-menu-item,
    .ant-menu-submenu-title {
      font-weight: bolder;
      font-size: 15px;
    }
    &__second-wrapper-hideDesktop {
      display: flex;
      justify-content: space-around;
      background: rgb(255, 255, 255);
      width: 100%;
      text-align: center;
    }
    &__active-link {
      color: #2b486d !important;
      cursor: pointer;
    }
    &__not_active-link {
      color: #8e8e8e !important;
      cursor: pointer;
    }
    background-color: transparent;
    position: relative;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    min-height: calc(110vh - 60px);
    margin: 0px auto;
    padding: 0px 16px 80px;
    &__second-wrapper {
      position: fixed;
      padding: 20px;
      bottom: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      background: #ffffff;
      max-width: 520px;
      margin-top: 20px;
    }
    @media screen and (max-width: 700px) {
      padding: 0px 0px 0px;
      max-width: 100%;
      &__second-wrapper {
        max-width: 100%;
      }
      &__second-wrapper-hideDesktop {
        justify-content: space-between;
        align-items: center;
      }
      .menu-dashboard-layout {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
