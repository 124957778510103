import React from "react";
import ShareHolderInformation from "./ShareHolderInformation";
import { useState } from "react";
import { useEffect } from "react";
import koinvoiceServices from "../../../../services/koinvoice";
import {
  buttonBackBusinessInformation,
  actionButtonBackShareHolderPhaseOne,
  actionShareHolderAddNewFormRedirect,
  businessInformationKoinvoice,
  actionGetPersonalShareHolderInfoLocalReset,
} from "../../../../stores/actions/koinvoiceLoan";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ShareHolderListCard from "./ShareHolderListCard";
import { Button, Spin } from "antd";
import ShareHolderNotFound from "./ShareHolderNotFound";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";
import UserConsent from "../../../ModalView/UserConsent";

function ShareHolder({
  businessInformation,
  buttonBackBusinessInformation,
  actionShareHolderAddNewFormRedirect,
  actionGetPersonalShareHolderInfoLocalReset,
  businessInformationKoinvoice,
  guarantorShareHolder,
  koinvoice: { nextUpdateOwnerDirectorGuarantor },
  marital_status,
  business_type,
  prevPage,
  nextPage,
  init: { language },
}) {
  const [information, setInformation] = useState([]);
  const [infromationEdit, setInformationEdit] = useState({});
  const [actionCreateUpdate, setActionCreateUpdate] = useState("");
  const [isShowEditFormInformation, setIsShowEditFormInformation] =
    useState(false);
  const [isShowNotFoundList, setIsShowNotFoundList] = useState(true);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowDrawerDeleteSucsess, setIsShowDrawerDeleteSucsess] =
    useState(false);
  const { limit_application_id } =
    cookie.load("limit-application") !== undefined &&
    cookie.load("limit-application").data;
  const businessType =
    business_type === "individual" ||
    business_type === "home_business" ||
    business_type === "industri_rumah_tangga";
  const [isVisibleUserConsent, setIsVisibleUserConsent] = useState(false);

  useEffect(() => {
    businessInformationKoinvoice();
    actionGetAppGuarantorList(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (nextUpdateOwnerDirectorGuarantor || information.length < 1) {
      information.length < 1 && setIsShowEditFormInformation(true);
      information.length < 1 && setActionCreateUpdate("add-new");
      setTimeout(() => {
        actionShareHolderAddNewFormRedirect();
      }, 500);
    } // eslint-disable-next-line
  }, [nextUpdateOwnerDirectorGuarantor]);

  useEffect(() => {
    if (information.length > 1) {
      setIsShowEditFormInformation(false);
    }
  }, [information]);

  function actionChangeShowDrawerDelete() {
    setIsShowDrawerDeleteSucsess(!isShowDrawerDeleteSucsess);
  }

  function actionBack() {
    buttonBackBusinessInformation();
    prevPage();
  }

  async function actionGetAppGuarantorList() {
    let params = {
      // user_id: businessInformation.data && businessInformation.data.user_id,
      product: "koin_invoice",
      sibling_type: businessType
        ? ["KAWIN", "MARRIED"].includes(marital_status)
          ? "guarantor,spouse"
          : "guarantor"
        : "shareholder",
    };
    let getAppGuarantor =
      await koinvoiceServices.actionGetAssesmentsAppGuarantor({
        params: params,
        loading: setIsShowLoading,
      });
    if (getAppGuarantor?.status === 200 && getAppGuarantor?.data?.length >= 1) {
      const tempReversedAuthDocument = [];
      for (let i = getAppGuarantor?.data?.length - 1; i >= 0; i--) {
        tempReversedAuthDocument.push(getAppGuarantor.data[i]);
      }
      setInformation(tempReversedAuthDocument);
      setIsShowNotFoundList(false);
      setIsShowEditFormInformation(false);
    } else {
      setInformation([]);
      setIsShowNotFoundList(true);
    }
  }

  async function actionChangeDeleteList(paramsId) {
    let deleteList = await koinvoiceServices.putDeleteShareHolderLoanKoinVoice({
      paramId: paramsId,
    });
    if (deleteList.status === 200) {
      setIsShowEditFormInformation(!isShowEditFormInformation);
      actionGetAppGuarantorList();
      actionChangeShowDrawerDelete();
    }
  }

  function actionChangeListCardData(e, action) {
    setActionCreateUpdate(action);
    setIsShowEditFormInformation(!isShowEditFormInformation);
    setInformationEdit(e);
  }

  function actinChangeShowHideAddNew(e) {
    if (e === "add-new") {
      cookie.remove("paramsSubmitShareHolderInformation", { path: "/" });
      actionGetPersonalShareHolderInfoLocalReset();
      setInformationEdit({});
    }
    setActionCreateUpdate(e);
    (e === "update-data" || e === "create-data") && actionGetAppGuarantorList();
    setIsShowEditFormInformation(!isShowEditFormInformation);
  }

  function actionConfirmRequest() {
    let userCode = cookie.load("uCode");
    let add_data = JSON.parse(localStorage.getItem(`add-data-${userCode}`));
    koinvoiceServices.actionPostLimitConfirm({
      params: {
        id: parseInt(limit_application_id),
        body: {
          request_limit: add_data.limit_amount,
          term: add_data.loan_term,
          loan_purpose: add_data.loan_purpose,
        },
      },
      nextPage: nextPage,
      loading: setIsShowLoading,
    });
  }

  function actionChangeVisibleUserConsent() {
    setIsVisibleUserConsent(!isVisibleUserConsent);
  }

  return (
    <Spin
      spinning={businessInformation.data === undefined ? true : isShowLoading}
    >
      {isShowEditFormInformation === false ? (
        <React.Fragment>
          <Button
            size="large"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
              marginTop: 15,
            }}
            onClick={() => actionBack()}
          >
            <ArrowLeftOutlined /> {language.button.back}
          </Button>
          <React.Fragment>
            {isShowNotFoundList ? (
              <ShareHolderNotFound />
            ) : (
              information.length > 0 &&
              information.map((list, index) => {
                return (
                  <React.Fragment key={index}>
                    {" "}
                    <ShareHolderListCard
                      language={language}
                      list={list}
                      actionChangeListCardData={actionChangeListCardData}
                    />
                  </React.Fragment>
                );
              })
            )}
          </React.Fragment>
          <Button
            className="kw-button"
            style={{ width: "100%", marginTop: 20 }}
            size="large"
            type="primary"
            onClick={() => actinChangeShowHideAddNew("add-new")}
          >
            {language.button.addNew}
          </Button>
          {information.length > 0 && (
            <Button
              className="kw-button"
              style={{ width: "100%", marginTop: 20 }}
              loading={isShowLoading}
              size="large"
              type="primary"
              onClick={() => actionChangeVisibleUserConsent()}
            >
              {language.button.confirmRequest}
            </Button>
          )}
          <UserConsent
            visible={isVisibleUserConsent}
            handleChangeVisible={actionChangeVisibleUserConsent}
            businessInformation={businessInformation}
            actionConfirmRequest={actionConfirmRequest}
          />
        </React.Fragment>
      ) : (
        <ShareHolderInformation
          infromationEdit={infromationEdit}
          guarantorShareHolder={guarantorShareHolder}
          actionChangeDeleteList={actionChangeDeleteList}
          marital_status={marital_status}
          business_type={business_type}
          isShowDrawerDeleteSucsess={isShowDrawerDeleteSucsess}
          actionChangeShowDrawerDelete={actionChangeShowDrawerDelete}
          action={actionCreateUpdate}
          actionGetAppGuarantorList={actionGetAppGuarantorList}
          businessInformation={businessInformation}
          actinChangeShowHideAddNew={actinChangeShowHideAddNew}
        />
      )}
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      buttonBackBusinessInformation,
      actionButtonBackShareHolderPhaseOne,
      actionShareHolderAddNewFormRedirect,
      actionGetPersonalShareHolderInfoLocalReset,
      businessInformationKoinvoice,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareHolder);
