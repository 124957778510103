import styled from "styled-components";
import bgHowto from "../../../assets/images/bg-howto.png";
import bgHeading from "../../../assets/images/bg-heading.png";

export const HowtoWrapper = styled.div`
  background-color: #2b486d;
  background-image: url(${bgHowto});
  background-repeat: no-repeat;
  background-size: cover;

  .howto {
    padding-top: 60px;
    padding-bottom: 40px;
    .howto-wrapper {
      display: flex;
      @media (max-width: 575.98px) {
        flex-direction: column;
      }
      .section-heading-wrapper {
        background-image: url(${bgHeading});
        background-repeat: no-repeat;
        background-position: 100px 50px;
        height: 150px;
        @media (max-width: 575.98px) {
          display: none;
        }
        .section-heading {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.41px;
          color: #ffffff;
        }
      }
      .phone-frame {
        position: absolute;
        height: 582px;
        width: 281px;
        z-index: 9;
        left: 400px;
        margin-bottom: 0;
        &:before,
        &:after {
          width: 150px;
          background-color: red;
        }
      }
      .slide {
        background: transparent;
        > div {
          display: flex;
          @media (max-width: 575.98px) {
            flex-direction: column;
          }
          .slide-img-wrapper {
            position: relative;
            width: 50%;
            @media (max-width: 575.98px) {
              width: 100%;
            }
            .slide-img {
              height: 580px;
              /* width: 281px; */
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .pop-item {
              .item {
                position: absolute;
                z-index: 99;
                margin-bottom: 0;
                transform: scale(0);
                transition-delay: 0.25s;
                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }
          }

          .slide-text {
            width: 50%;
            text-align: left;
            color: #fff;
            margin-top: 130px;
            @media (max-width: 575.98px) {
              width: 100%;
              margin-top: 30px;
              margin-bottom: 30px;
            }
            .heading {
              font-family: Source Sans Pro;
              font-style: normal;
              font-weight: bold;
              font-size: 22px;
              line-height: 28px;
              letter-spacing: 0.35px;
              color: #ffffff;
              margin-bottom: 8px;
            }
            .text {
              font-family: Source Sans Pro;
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              line-height: 25px;
              letter-spacing: 0.38px;
              color: #ffffff;
            }
            .download {
              margin-top: 40px;
              display: flex;
              .store {
                margin-right: 20px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
        &.selected {
          .pop-item {
            .item {
              transform: scale(1) !important;
              transition: ease-in-out 0.5s;
            }
          }
        }
      }
    }
  }
  .carousel.carousel-slider {
    .control-arrow {
      font-size: 36px;
      &::before {
        border: none;
      }
      &:hover {
        background: none;
      }
    }
    .control-prev {
      &::before {
        font-family: FontAwesome; 
      content: "\f137"
      }
    }
    .control-next {
    &::before {
      font-family: FontAwesome; 
      content: "\f138"
    }
    }
  }
`;
