import cookie from "react-cookies";

export default function removeLocalStorageAndCookieLimit() {
  cookie.remove("paramsSubmit");
  cookie.remove("business-info");
  cookie.remove("details-loan");
  cookie.remove("put-loan-detail-sucsess");
  cookie.remove("paramsSubmitDirectorOwner", { path: "/" });
  localStorage.removeItem("paramsSubmitDate");
  localStorage.removeItem("paramsIsCurrentlyAddress");
  localStorage.removeItem("businessInfoDate");
}
