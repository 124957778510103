import React, { useEffect, useState } from "react";
import { Radio, Button, Row, Col, Spin, Alert } from "antd";
import koinvoiceServices from "../services/koinvoice";
import currencyRupiah from "../library/currency";
import DrawerConfirmationReject from "./DrawerConfirmationReject";
import NotFound from "../Components/NotFound";

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

function ChooseOfferKoinInvoice({
  dataRepayment,
  actionChangeShowHideSucsess,
  actionChangeShowYourLoan,
  actionChangeShowDocumentList,
}) {
  const [chooseOfferList, setChooseOfferList] = useState([]);
  const [isShowRejectModalAlert, setIsShowRejectModalAlert] = useState(false);
  const [isShowRejecetSucsess, setIsShowRejecetSucsess] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [isShowMessageError, setIsShowMessageError] = useState(false);
  const [messageErrorReject, setMessageErrorReject] = useState("");
  const [isShowMessageErrorReject, setIsShowMessageErrorReject] = useState(
    false
  );
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [offer_id, setOffer_id] = useState(undefined);

  useEffect(() => {
    actionGetChooseOffer(); // eslint-disable-next-line
  }, []);

  async function actionGetChooseOffer() {
    let response = await koinvoiceServices.actionGetChooseOfferList({
      paramsId: dataRepayment.id,
      loading: setIsShowLoading,
    });
    if (response.status === 200) {
      setChooseOfferList(response.data);
      setIsShowNotFound(false);
    } else {
      setChooseOfferList([]);
      setIsShowNotFound(true);
    }
  }

  async function actionRejectChooseOffer() {
    let response = await koinvoiceServices.actionRejectChooseOfferList({
      paramsId: {
        // offer_id: offer_id,
        loan_id: dataRepayment.id,
      },
      loading: setIsShowLoading,
    });
    if (response.status === 200) {
      setIsShowRejecetSucsess(true);
      setIsShowMessageErrorReject(false);
    } else {
      setIsShowRejecetSucsess(false);
      setIsShowMessageErrorReject(true);
      setMessageErrorReject(`${response.message.id} / ${response.message.en}`);
    }
  }

  async function actionChooseOffer() {
    let response = await koinvoiceServices.actionChooseOfferList({
      paramsId: {
        offer_id: offer_id,
        loan_id: dataRepayment.id,
      },
      loading: setIsShowLoading,
    });
    if (response.status === 200) {
      actionChangeShowHideSucsess();
      actionChangeShowDocumentList();
      setIsShowMessageError(false);
      actionChangeShowYourLoan();
    } else {
      setIsShowMessageError(true);
      setMessageError(`${response.message.id} / ${response.message.en}`);
    }
  }

  async function actionChangeShowHideConfirmationReject() {
    setIsShowRejectModalAlert(!isShowRejectModalAlert);
  }

  function onChange(e) {
    setOffer_id(e.target.value);
  }

  return (
    <div>
      <h3>Which offer do you prefer?</h3>
      <p>Please select one of these offer(s):</p>
      <div>
        {isShowNotFound ? (
          <NotFound
            image={require("../assets/images/dashboard/norepayment.png").default}
            label={"Choose offer not found"}
            paragraph={""}
            isShow={true}
          />
        ) : (
          <React.Fragment>
            <Radio.Group
              onChange={onChange}
              style={{ width: "100%" }}
              size="large"
              value={offer_id}
            >
              {chooseOfferList.map((list, index) => {
                return (
                  <Radio.Button
                    style={{
                      width: "100%",
                      height: "auto",
                      padding: 15,
                      marginTop: 15,
                    }}
                    key={index}
                    value={list.offer_id}
                  >
                    <Row>
                      <Col xs={10}>
                        {" "}
                        <p>AMOUNT</p>
                        <strong
                          style={{
                            color:
                              offer_id === list.offer_id
                                ? "#566068"
                                : "#566068",
                          }}
                        >
                          {" "}
                          {currencyRupiah(list.amount)}
                        </strong>
                      </Col>
                      <Col xs={4}>
                        <p>RATE</p>
                        <strong
                          style={{
                            color:
                              offer_id === list.offer_id
                                ? "#566068"
                                : "#566068",
                          }}
                        >
                          {list.interest} %
                        </strong>
                      </Col>
                      <Col xs={4}>
                        <p>MONTH</p>
                        <strong
                          style={{
                            color:
                              offer_id === list.offer_id
                                ? "#566068"
                                : "#566068",
                          }}
                        >
                          {list.tenure}
                        </strong>
                      </Col>
                      <Col xs={6}>
                        {" "}
                        {offer_id === list.offer_id ? (
                          <img
                            src={require("../assets/images/icon/checklist.png").default}
                            style={{
                              float: "right",
                              marginTop: isMobile() ? "25%" : "40%",
                            }}
                            alt=""
                          />
                        ) : undefined}
                      </Col>
                    </Row>
                  </Radio.Button>
                );
              })}
            </Radio.Group>
            {isShowMessageError && (
              <Alert
                message={messageError}
                style={{ marginTop: 15 }}
                type="error"
                showIcon
              />
            )}
            {isShowMessageErrorReject && (
              <Alert
                message={messageErrorReject}
                style={{ marginTop: 15 }}
                type="error"
                showIcon
              />
            )}
            <Spin spinning={isShowLoading}>
              <Button
                size="large"
                disabled={offer_id === undefined ? true : false}
                onClick={() => actionChooseOffer()}
                className={
                  offer_id === undefined ? "kw-button-disabled" : "kw-button"
                }
              >
                SUBMIT
              </Button>
            </Spin>
            <Button
              size="large"
              style={{
                backgroundColor: "#EFEFEF",
                marginTop: 20,
                color: "#C53741",
                borderColor: "#EFEFEF",
                width: "100%",
              }}
              onClick={() => actionChangeShowHideConfirmationReject()}
            >
              REJECT OFFER
            </Button>
          </React.Fragment>
        )}
      </div>
      <DrawerConfirmationReject
        isShowRejecetSucsess={isShowRejecetSucsess}
        actionRejectChooseOffer={actionRejectChooseOffer}
        actionChangeShowHideSucsess={actionChangeShowHideSucsess}
        actionChangeShowYourLoan={actionChangeShowYourLoan}
        actionChangeShowDocumentList={actionChangeShowDocumentList}
        onClose={actionChangeShowHideConfirmationReject}
        visible={isShowRejectModalAlert}
      />
    </div>
  );
}

export default ChooseOfferKoinInvoice;
