import React, { useEffect, useState } from "react";
import { Motion, StaggeredMotion, spring } from "react-motion";
import range from "lodash.range";
import { Link } from "react-router-dom";

// Components

//Constants

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

// Diameter of the main button in pixels
const MAIN_BUTTON_DIAM = 46;
const CHILD_BUTTON_DIAM = 55;
// The number of child buttons that fly out from the main button
const NUM_CHILDREN = 1;
// Hard code the position values of the mainButton
const M_X = isMobile() ? 400 : 283;
const M_Y = 575;

//should be between 0 and 0.5 (its maximum value is difference between scale in finalChildButtonStyles a
// nd initialChildButtonStyles)
const OFFSET = 0.05;

const SPRING_CONFIG = { stiffness: 400, damping: 28 };

// How far away from the main button does the child buttons go
const FLY_OUT_RADIUS = 130,
  SEPARATION_ANGLE = 40, //degrees
  FAN_ANGLE = (NUM_CHILDREN - 1) * SEPARATION_ANGLE, //degrees
  BASE_ANGLE = (180 - FAN_ANGLE) / 2; // degrees

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function finalChildDeltaPositions(index) {
  let angle = BASE_ANGLE + index * SEPARATION_ANGLE;
  return {
    deltaX: FLY_OUT_RADIUS * Math.cos(toRadians(angle)) - CHILD_BUTTON_DIAM / 2,
    deltaY: FLY_OUT_RADIUS * Math.sin(toRadians(angle)) + CHILD_BUTTON_DIAM / 2,
  };
}

export const ButtonMenuKoinInvoice = ({
  condition = false,
  setIsShowTermConditionExpDate,
  creditLimitUcl
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, []);

  const mainButtonStyles = () => {
    return {
      width: MAIN_BUTTON_DIAM,
      height: MAIN_BUTTON_DIAM,
      top: M_Y - MAIN_BUTTON_DIAM / 2,
      left: M_X - MAIN_BUTTON_DIAM / 2,
    };
  };

  const initialChildButtonStyles = () => {
    return {
      width: CHILD_BUTTON_DIAM,
      height: CHILD_BUTTON_DIAM,
      top: spring(M_Y - CHILD_BUTTON_DIAM / 2, SPRING_CONFIG),
      left: spring(M_X - CHILD_BUTTON_DIAM / 2, SPRING_CONFIG),
      rotate: spring(-180, SPRING_CONFIG),
      scale: spring(0.5, SPRING_CONFIG),
    };
  };

  const initialChildButtonStylesInit = () => {
    return {
      width: CHILD_BUTTON_DIAM,
      height: CHILD_BUTTON_DIAM,
      top: M_Y - CHILD_BUTTON_DIAM / 1,
      left: M_X - CHILD_BUTTON_DIAM / 2,
      rotate: -180,
      scale: 0.5,
    };
  };

  const finalChildButtonStylesInit = (childIndex) => {
    let { deltaX, deltaY } = finalChildDeltaPositions(childIndex);
    return {
      width: CHILD_BUTTON_DIAM,
      height: CHILD_BUTTON_DIAM,
      top: M_Y - deltaY,
      left: M_X + deltaX,
      rotate: 0,
      scale: 1,
    };
  };

  const finalChildButtonStyles = (childIndex) => {
    let { deltaX, deltaY } = finalChildDeltaPositions(childIndex);
    return {
      width: CHILD_BUTTON_DIAM,
      height: CHILD_BUTTON_DIAM,
      top: spring(M_Y - deltaY, SPRING_CONFIG),
      left: spring(M_X + deltaX, SPRING_CONFIG),
      rotate: spring(0, SPRING_CONFIG),
      scale: spring(1, SPRING_CONFIG),
    };
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const renderChildButtons = () => {
    const targetButtonStylesInitObject = range(NUM_CHILDREN).map((i) => {
      return isOpen
        ? finalChildButtonStylesInit(i)
        : initialChildButtonStylesInit();
    });

    //StaggeredMotion now takes an Array of object
    const targetButtonStylesInit = Object.keys(
      targetButtonStylesInitObject
    ).map((key) => targetButtonStylesInitObject[key]);

    const targetButtonStyles = range(NUM_CHILDREN).map((i) => {
      return isOpen ? finalChildButtonStyles(i) : initialChildButtonStyles();
    });

    const scaleMin = initialChildButtonStyles().scale.val;
    const scaleMax = finalChildButtonStyles(0).scale.val;

    let calculateStylesForNextFrame = (prevFrameStyles) => {
      prevFrameStyles = isOpen ? prevFrameStyles : prevFrameStyles.reverse();

      let nextFrameTargetStyles = prevFrameStyles.map(
        (buttonStyleInPreviousFrame, i) => {
          //animation always starts from first button
          if (i === 0) {
            return targetButtonStyles[i];
          }

          const prevButtonScale = prevFrameStyles[i - 1].scale;
          const shouldApplyTargetStyle = () => {
            if (isOpen) {
              return prevButtonScale >= scaleMin + OFFSET;
            } else {
              return prevButtonScale <= scaleMax - OFFSET;
            }
          };

          return shouldApplyTargetStyle()
            ? targetButtonStyles[i]
            : buttonStyleInPreviousFrame;
        }
      );

      return isOpen ? nextFrameTargetStyles : nextFrameTargetStyles.reverse();
    };

    return (
      <StaggeredMotion
        defaultStyles={targetButtonStylesInit}
        styles={calculateStylesForNextFrame}
      >
        {(interpolatedStyles) => (
          <div>
            {interpolatedStyles.map(
              ({ height, left, rotate, scale, top, width }, index) => (
                <div
                  className="child-button"
                  key={index}
                  style={{
                    left,
                    height,
                    top,
                    transform: `rotate(${rotate}deg) scale(${scale})`,
                    width,
                  }}
                >
                  {condition ? (
                    <img
                      src={require("../assets/images/Frame.png").default}
                      style={{ padding: 10, cursor: "pointer" }}
                      onClick={() => setIsShowTermConditionExpDate(true)}
                      alt=""
                    />
                  ) : (
                    <Link to="/koininvoice/apply-loan">
                      <img
                        src={require("../assets/images/Frame.png").default}
                        style={{ padding: 10 }}
                        alt=""
                      />
                    </Link>
                  )}
                </div>
              )
            )}
          </div>
        )}
      </StaggeredMotion>
    );
  };

  let mainButtonRotation = isOpen
    ? { rotate: spring(0, { stiffness: 500, damping: 30 }) }
    : { rotate: spring(-135, { stiffness: 500, damping: 30 }) };

  return (
    <React.Fragment>
      {(creditLimitUcl && !creditLimitUcl.is_parent_ucl && creditLimitUcl.current_limit >= 100000) ? (
        <div>
          {renderChildButtons()}
          <Motion style={mainButtonRotation}>
            {({ rotate }) => (
              <div
                className="main-button"
                style={{
                  ...mainButtonStyles(),
                  transform: `rotate(${rotate}deg)`,
                }}
                onClick={toggleMenu}
              >
                {/*Using fa-close instead of fa-plus because fa-plus doesn't center properly*/}
                <i className="fa fa-close fa-1x" />
              </div>
            )}
          </Motion>
        </div>
      ) : null }
    </React.Fragment>
  );
};
