import styled from "styled-components";

export const ShareHolderStyle = styled.div`
  .button-delete {
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #c53741;
    border-radius: 3px;
  }
  @media screen and (max-width: 900px) {
    .button-delete {
      width: 100%;
    }
  }
  .kw-shareHolder-notFound-wrapper {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 55px;
    }
    h4 {
      margin-top: 25px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      align-items: center;
      margin-top: 15px;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #566068;
    }
  }
  .kw-ShareHolderListCard-wrapper {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(8, 35, 48, 0.24);
    border-radius: 3px;
    padding: 25px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    strong {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.38px;
      display: block;
      color: #566068;
    }
    span {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #cccccc;
    }
    &__edit {
      background: #378df4;
      border-radius: 100px;
      float: right;
      cursor: pointer;
      color: #ffffff;
      font-size: 13px;
      width: 100px;
      text-align: center;
    }
  }
`;
