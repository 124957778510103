import koinvoiceServices from "../../services/koinvoice";
import dispatchType from "./dispatchType";
import { apiGetWithToken, apiPutWithToken } from "../../services";
import { PATH_KOINVOICE_LOAN } from "../../services/path/koinvoice/koinvoice";

export const getAplicationProsess = (setIsShowLoading = function(){}) => async (dispatch) => {
  setIsShowLoading(true)
  try {
    const responseAplicationProsess = await koinvoiceServices.getLoanApplicationProgress();
    setIsShowLoading(false)
    dispatch(
      dispatchType.loanAplicationProsessSucess(responseAplicationProsess)
    );
  } catch (error) {
    setIsShowLoading(false)
    dispatch(dispatchType.loanAplicationProsessError(error));
  }
};

export const getLoanLimit = (token = null) => async (dispatch) => {
  try {
    const responseLoanLimit = token ? 
      await koinvoiceServices.getLoanLimit(token) : await koinvoiceServices.getLoanLimit();
    dispatch(dispatchType.loanLimitSucess(responseLoanLimit));
  } catch (error) {
    dispatch(dispatchType.loanLimitError(error));
  }
};

export const getLoanBalance = () => async (dispatch) => {
  try {
    const responseBalance = await koinvoiceServices.getLoanBalance();
    dispatch(dispatchType.loanBalanceSucsess(responseBalance));
  } catch (error) {
    dispatch(dispatchType.loanBalanceError(error));
  }
};

export const getDocumentListDashboard = (params) => async (dispatch) => {
  try {
    const documentListDashboard = await apiGetWithToken(
      PATH_KOINVOICE_LOAN.DOCUMENT_LIST,
      params
    );
    dispatch(dispatchType.documentListSucsess(documentListDashboard));
  } catch (error) {
    dispatch(dispatchType.documentListError(error));
  }
};

export const getApplicationListLoanDashboard = (params,setIsShowLoading) => async (dispatch) => {
  setIsShowLoading(true)
  try {
    const responseApplicationList = await apiGetWithToken(
      PATH_KOINVOICE_LOAN.YOUR_LOAN_DETAILS,
      params
    );
    dispatch(
      dispatchType.applicationListLoanDashboardSucsess(responseApplicationList)
    );
    setIsShowLoading(false)
  } catch (error) {
    dispatch(dispatchType.applicationListLoanDashboardError(error));
    setIsShowLoading(false)
  }
};

export const getActionProfileUsers = () => async (dispatch) => {
  try {
    const responseProfileUsers = await apiGetWithToken(
      PATH_KOINVOICE_LOAN.USER_PROFILE
    );
    dispatch(dispatchType.userProfileSucsess(responseProfileUsers));
  } catch (error) {
    dispatch(dispatchType.userProfileError(error));
  }
};

export const putActionPrimaryBank = (
  params,
  setIsShowloading,
  actionChangeShowHideSucsess,
  setIsShowChooseOffer
) => async (dispatch) => {
  setIsShowloading(true);
  try {
    const responsePrimaryBank = await apiPutWithToken(
      PATH_KOINVOICE_LOAN.DOCUMENT_PRIMARY_BANK,
      params
    );
    dispatch(dispatchType.putPrimaryBankSucsess(responsePrimaryBank));
    setIsShowloading(false);
    setIsShowChooseOffer(true)
    actionChangeShowHideSucsess()
  } catch (error) {
    dispatch(dispatchType.putPrimaryBankError(error));
    setIsShowloading(false);
    setIsShowChooseOffer(false)
  }
};