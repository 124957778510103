import React from "react";
import { CardYourLoanStyle } from "../../YourLoan/style";
import { List, Row, Col } from "antd";
import { capitalizeFirstLetter } from "../../library/checkCapitalize";
import currencyRupiah from "../../library/currency";
import { labelCondition } from "./labelCondition";

export default function CardScheduleKoinInvoice({
  listData,
  actionChangeShowRepayment,
}) {
  return (
    <React.Fragment>
      {listData.map((item, index) => {
        return (
          <CardYourLoanStyle key={index}>
            <div
              className="kw-cards-wrapper"
              style={{ cursor: "pointer" }}
              onClick={() => actionChangeShowRepayment(item)}
            >
              <div className="kw-cardYourLoans-wrapper">
                <List itemLayout="horizontal">
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <img
                          src={require("../../assets/images/dashboard-icon-koininvoice/your-loands.png").default}
                          alt=""
                        />
                      }
                      title={
                        <React.Fragment>
                          <strong
                            style={{ display: "block", color: "#378DF4" }}
                          >
                            {`${"#BL"} : ${item.id} - ${item.code} >`} &nbsp;
                            <span
                              style={{
                                background: labelCondition(item).background,
                                borderRadius: 100,
                                width: labelCondition(item).width,
                                height: labelCondition(item).height,
                                color: labelCondition(item).color,
                                display: "inline-flex",
                                justifyContent: "center",
                              }}
                            >{`${item.status
                              .toUpperCase()
                              .replace(/_/g, " ")}`}</span>
                          </strong>
                          <strong
                            style={{
                              fontSize: 14,
                              color: "#566068",
                              fontWeight: 555,
                            }}
                          >{`${"KoinInvoice"} : ${capitalizeFirstLetter(
                            item.description
                          )}`}</strong>
                        </React.Fragment>
                      }
                    />
                  </List.Item>
                </List>
                <Row>
                  <Col xs={8}>
                    <span style={{ display: "block" }}>TOTAL DUE</span>{" "}
                    <strong>{currencyRupiah(item.amount, " ")}</strong>
                  </Col>
                  <Col xs={8}>
                    <span style={{ display: "block" }}>TERM</span>{" "}
                    <strong>{item.term} Month</strong>{" "}
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col xs={18}>
                    {item.status === "partially_paid" ||
                      item.status === "late" ||
                      (item.status === "due_today" && (
                        <span
                          style={{
                            color: "#566068",
                            fontSize: 15,
                          }}
                        >
                          <strong>{item.total_lender}</strong> lenders{" "}
                          {item.status === "paid"
                            ? "are happy by your payment"
                            : "are waiting for your payment"}
                        </span>
                      ))}
                    {item.status === "upcoming" && (
                      <strong
                        style={{
                          color: "#566068",
                          fontWeight: "normal",
                          fontSize: 15,
                        }}
                      >
                        This is your next payment{" "}
                      </strong>
                    )}
                  </Col>
                  <Col xs={6}>
                    {item.status === "paid"
                      ? undefined
                      : undefined
                        // <Button
                        //   onClick={() => actionChangeShowRepayment(item)}
                        //   style={{
                        //     width: "100%",
                        //     background: "#2B486D",
                        //     color: "#FFFFFF",
                        //   }}
                        //   size="large"
                        // >
                        //   PAY
                        // </Button>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </CardYourLoanStyle>
        );
      })}
    </React.Fragment>
  );
}
