import React, { useState, useEffect } from "react";
import { Radio, Spin } from "antd";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  getLoanPrincipal,
  getLoanFinancing,
  purposeLoanKoinVoice,
  buttonBackSelectLoan,
} from "../../../stores/actions/koinvoiceLoan";
import { actionPostLimitApplicationRedux } from "../../../stores/actions/koininvoiceLimit";
import { bindActionCreators } from "redux";
import PrincipalCodeLoan from "./PrincipalCodeLoan";
import ProjectFinancingLoan from "./ProjectFinancingLoan";
import cookie from "react-cookies";

function SelectLoanYourPurpose({
  init,
  getLoanPrincipal,
  getLoanFinancing,
  purposeLoanKoinVoice,
  actionPostLimitApplicationRedux,
  koinvoice,
  buttonBackSelectLoan,
  setPage,
}) {
  const { language, flag } = init;
  const [labelLoanCode, setLabelLoanCode] = useState("Supply Chain Financing"); //supply_chain_financing
  // const [loanCodePrincipal, setLoanCodePrincipal] = useState(undefined);
  // const [loanCodeFinancing, setLoanCodeFinancing] = useState(undefined);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  let principalCode = koinvoice.principal;
    // cookie.load("principal") !== undefined && cookie.load("principal").data;
  let financingCode = koinvoice.financing;
    // cookie.load("financing") !== undefined && cookie.load("financing").data;

  useEffect(() => {
    window.scrollTo(0, 0);
    buttonBackSelectLoan();// eslint-disable-next-line
  }, []);

  function handleChangeFinancingLoan(value) {
    getLoanFinancing({ reference_code: value.target.value });
    //setLoanCodeFinancing(value.target.value);
  }

  function handleChangeCodeLoan(value) {
    getLoanPrincipal({ reference_code: value.target.value });
    //setLoanCodePrincipal(value.target.value);
  }

  function handleChangeTypeSelectLoan(value) {
    setLabelLoanCode(value.target.value);
    buttonBackSelectLoan();
    setIsDisabledButton(true);
  }

  function handleChangeSubmitPrincipal() {
    actionPostLimitApplicationRedux(
      {
        vendor_uuid: principalCode?.vendor_uuid,
        principal_uuid: principalCode?.principal_uuid,
      },
      setIsShowLoading,
      setPage
    );
    const indexesToLocalstorage = JSON.stringify(labelLoanCode);
    localStorage.setItem("selectLoan", indexesToLocalstorage);
  }
  function handleChangeSubmitFinancing() {
    actionPostLimitApplicationRedux(
      {
        vendor_uuid: financingCode?.vendor_uuid,
        referral_uuid: financingCode?.referral_uuid,
      },
      setIsShowLoading,
      setPage
    );
    const indexesToLocalstorage = JSON.stringify(labelLoanCode);
    localStorage.setItem("selectLoan", indexesToLocalstorage);
  }
  return (
    <React.Fragment>
      <h4>{language.loanKoinVoice.selecetLoan}</h4>
      <div className="kw-selectLoanYourPurpose">
        <Radio.Group
          onChange={handleChangeTypeSelectLoan}
          defaultValue="Supply Chain Financing"
          size="large"
        >
          <div id="supply-chain-financing">
            <Radio.Button
              className="kw-selectLoanYourPurpose__selectLoanWrapper"
              value="Supply Chain Financing"
            >
              {" "}
              <img
                src={require("../../../assets/images/applyloan/koinvoice/icon-supply-chain-financing.png").default}
                alt=""
                style={{ height: 70 }}
              />
              <br />
              <p className="kw-selectLoanYourPurpose__label">
                {language.loanKoinVoice.supplyChain}
              </p>
              <p className="kw-selectLoanYourPurpose__text">
                {language.loanKoinVoice.supplyChainText}
              </p>
            </Radio.Button>
          </div>
          <div id="invoice-financing">
            <Radio.Button
              className="kw-selectLoanYourPurpose__selectLoanWrapper"
              value="Invoice Financing"
            >
              <img
                src={require("../../../assets/images/applyloan/koinvoice/icon-project-financing.png").default}
                alt=""
                style={{ height: 70 }}
              />
              <br />
              <p className="kw-selectLoanYourPurpose__label">
                {language.loanKoinVoice.projectFinancing}
              </p>
              <p className="kw-selectLoanYourPurpose__text">
                {language.loanKoinVoice.projectFinancingText}
              </p>
            </Radio.Button>
          </div>
        </Radio.Group>
        <Spin spinning={koinvoice.isShowLoading}>
          {labelLoanCode === "Supply Chain Financing" ? (
            <div style={{ marginTop: 20 }}>
              <PrincipalCodeLoan
                flag={flag}
                language={language}
                handleChangeCodeLoan={handleChangeCodeLoan}
                koinvoice={koinvoice}
                isDisabledButton={isDisabledButton}
                isShowLoading={isShowLoading}
                handleChangeSubmitPrincipal={handleChangeSubmitPrincipal}
                principalCode={principalCode}
              />
            </div>
          ) : (
            <div style={{ marginTop: 20 }}>
              <ProjectFinancingLoan
                flag={flag}
                language={language}
                handleChangeFinancingLoan={handleChangeFinancingLoan}
                koinvoice={koinvoice}
                isShowLoading={isShowLoading}
                handleChangeSubmitFinancing={handleChangeSubmitFinancing}
                financingCode={financingCode}
              />
            </div>
          )}
        </Spin>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  koinvoiceLimit: state.koinvoiceLimit,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLoanPrincipal,
      getLoanFinancing,
      purposeLoanKoinVoice,
      buttonBackSelectLoan,
      actionPostLimitApplicationRedux,
    },
    dispatch
  );
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectLoanYourPurpose);

export default reduxForm({
  form: "SelectLoanYourPurpose",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(component);
