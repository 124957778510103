import styled from "styled-components";

export const ApplyLoanKoinVoiceStyle = styled.div`
  .kw-applyLoanKoinVoice {
    background: #ffffff;
    padding: 20px;
    h4 {
      text-align: center;
      font-weight: 555;
      font-size: 28px;
      color: #2b486d;
    }
  }

  .kw-documentUnggah-wrapper {
    .kw-button {
      background-color: #2b476c;
      border-color: #2b476c;
      color: #ffffff;
      width: 500px;
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 20%;
    }
    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #2b486d;
    }
    h3 {
      font-weight: bold;
      font-size: 28px;
      text-align: center;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
    &__button-sendLink {
      background: #ffffff;
      border: 2px solid #2b486d;
      box-sizing: border-box;
      border-radius: 3px;
      margin-left: 1px;
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      &__button-sendLink {
        margin-left: 0px;
        margin-top: 15px;
      }
    }
    p {
      display: flex;
      align-items: center;
    }
  }

  .kw-button {
    background-color: #2b476c;
    border-color: #2b476c;
    color: #ffffff;
    width: 500px;
  }

  @media screen and (max-width: 500px) {
    .kw-button {
      width: 100%;
    }
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    min-height: 0px !important;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .ant-form-item {
    display: block;
  }

  .kw-selectLoanYourPurpose {
    margin-top: 33px;
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #ecf5ff;
    }
    .ant-radio-group-large .ant-radio-button-wrapper {
      height: unset;
    }
    .ant-radio-button-wrapper {
      height: unset;
    }
    .ant-radio-group.ant-radio-group-outline.ant-radio-group-large {
      display: flex;
      flex-direction: row;
    }
    @media screen and (max-width: 1000px) {
      .ant-radio-group.ant-radio-group-outline.ant-radio-group-large {
        flex-direction: column;
      }
    }
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.38px;
      color: #2b486d;
    }
    &__label {
      font-weight: 555;
      font-size: 17px;
      line-height: 25px;
      color: #566068;
    }
    &__text {
      font-size: 15px;
      letter-spacing: -0.24px;
      line-height: 23px;
      color: #566068;
    }
    &__selectLoanWrapper {
      padding: 15px;
    }
  }

  .kw-detailsLoan {
    h2 {
      font-weight: 555;
      font-size: 28px;
      line-height: 34px;
      align-items: center;
      letter-spacing: 0.337647px;
      text-align: center;
      margin-bottom: 20px;
      color: #2b486d;
    }
    h3 {
      font-weight: 555;
      font-size: 20px;
      line-height: 34px;
      align-items: center;
      letter-spacing: 0.337647px;
      color: #2b486d;
    }
  }
`;
