import React from "react";
import { Card, Button } from "antd";
import { WaitingForContractStyle } from "../../WaitingForContract/style";
import successImg from "../../assets/images/success.png";
import currencyRupiah from "../../library/currency";

function LimitOfferingParent({
  language,
  limitInformation,
  actionChangeShowDocumentList
}) {
  return (
    <Card>
      <div
        style={{
          borderBottom: "1px solid #CCCCCC",
          marginBottom: 20,
        }}
      >
        <div>
          {" "}
          <Button size="large" onClick={() => actionChangeShowDocumentList()}>
            {language.button.back}
          </Button>
        </div>
        <h4 style={{ textAlign: "center" }}>{language.title.congratulation}</h4>
      </div>
      <WaitingForContractStyle>
          <div className="kw-waitingFor-contract">
            <figure style={{ textAlign: 'center' }}>
              <img src={successImg} alt="" />
            </figure>
            <strong className="kw-waitingFor-contract__label">
              <p style={{ marginBottom: 0, lineHeight: 1.5, textAlign: 'center'}}>
                {language.text.offeringParent1}
              </p>
              <table className="tbChild">
                <tbody>
                  {limitInformation.ucl_childs && limitInformation.ucl_childs.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ width: '60%'}}>
                          <strong>{row.properties.user_profile.fullname}</strong><br/>
                          <span>{row.properties.user_profile.email}</span>
                        </td>
                        <td>{currencyRupiah(row.initial_limit)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </strong>
            
          </div>
        </WaitingForContractStyle>
    </Card>
  );
}

export default LimitOfferingParent;
