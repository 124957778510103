const required = value => (value ? undefined : "Required");
const requiredByAmount = (amount, minAmount) => value => 
  amount > minAmount && !value ? "Required" : undefined;
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength15 = maxLength(15);

const maxLengthNumber = max => value =>
  value && value.length > max
    ? `Max. digit is ${max} `
    : undefined;

const exactLengthNumber = length => value =>
  value && value.length !== length
  ? `Must be ${length} digits number`
  : undefined;

const minLength = min => value =>
  value && value.length < min
    ? `Must be at least ${min} characters`
    : undefined;
const minLengthPassword = minLength(8);

const minLengthPin = minLength(6);

const minLengthNumber = min => value =>
  value && value.length < min
    ? `Must be at least ${min} digits number`
    : undefined;

const number = value =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;
const minValue18 = minValue(18);
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
const tooOld = value =>
  value && value > 65 ? "You might be too old for this" : undefined;
const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? "Really? You still use AOL for your email?"
    : undefined;
const count = (value = "") => {
  const minimumLength = 100 - value.length;
  if (minimumLength > 0) return minimumLength;
};
const minLengthPhoneNumber = value =>
  value && value.length < 6 ? `Must be at least 6 character` : undefined;

const removeCommas = value => {
  let numb;

  if (value) numb = value.toString().replace(/,/g, "");

  return Number(numb);
};

const formatCurrency = value => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const requiredAmount = value => (value ? undefined : { value: 0, label: ` ` });

const floatAmount = value =>
  removeCommas(value) >= 100000 &&
  Number.isInteger(removeCommas(value) / 100000)
    ? undefined
    : { value: 1, label: `Must Be A Multiple of 100,000` };

const maxAmount = max => value =>
  removeCommas(value) > max
    ? { value: 2, label: `Amount Insufficient` }
    : undefined;

const zeroAmount = value =>
  value && Number(value) <= 0
    ? { value: 3, label: `Amount Cannot Be Zero or Less Than Zero` }
    : undefined;

const validatePhoneNumber = value => {
  let status;
  var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  status = undefined;

  if (!regex.test(value)) {
    status = `invalid phone number`;
  }

  return status;
};

const restrictAge = age => value => {
  let todate = new Date();
  let yearnow = todate.getYear();
  let dob = new Date(value);
  let yeardob = dob.getYear();
  return yearnow - yeardob < age ? `You must be at least ${age} years old.` : undefined;
}

const advAmountExternal = amount => value => {
  let min = 500000;
  let max = 75 / 100 * amount;

  return removeCommas(value) < min || removeCommas(value) > max ?
    `Advance amount should be between ${formatCurrency(min)} - ${formatCurrency(max)}` : undefined;
}

const maxAdvanceAmount = amount => value => {
  let max = 75/100 * amount;
  return removeCommas(value) > max ? `Max. advance amount is ${formatCurrency(max)}` : undefined;
}

const advAmountInternal = value => {
  return removeCommas(value) < 500000 ? `Min. advance amount is 500,000` : undefined;
}

const amountMin = value => {
  return removeCommas(value) < 10000000 ? `Minimum invoice/PO/SPK amount is 10,000,000` : undefined;
}

const amountMax = value => {
  return removeCommas(value) > 2000000000 ? `Maximum invoice/PO/SPK amount is 2,000,000,000` : undefined;
}

const regularAmount = amount => value => {
  let min = 3000000;
  let max = 6 * amount;

  return removeCommas(value) < min || removeCommas(value) > max ?
    `Loan amount should be between ${min} - ${max}` : undefined;
}

const isNumberOrEmail = value =>
  value && isNaN(Number(value)) ? email(value) : minLengthPhoneNumber(value);

export {
  required,
  requiredByAmount,
  requiredAmount,
  maxLength,
  minLength,
  minLengthPassword,
  minLengthPin,
  minLengthNumber,
  maxLengthNumber,
  maxLength15,
  exactLengthNumber,
  number,
  minValue,
  minValue18,
  email,
  tooOld,
  aol,
  count,
  minLengthPhoneNumber,
  validatePhoneNumber,
  isNumberOrEmail,
  floatAmount,
  maxAmount,
  zeroAmount,
  restrictAge,
  advAmountExternal,
  advAmountInternal,
  maxAdvanceAmount,
  regularAmount,
  amountMin,
  amountMax
};
