import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { UpdateBankWrapper } from "./UpdateBank.styles";
import * as yup from "yup";
import { Form, Input, Button, Spin, Select, notification, Card } from "antd";
import bankAccount from "./bank.json";
import DrawerAllert from "./DrawerAllert";
import { capitalizeFirstLetter } from "../../library/checkCapitalize";
import koinvoiceServices from "../../services/koinvoice";
import ConfirmBankAllert from "./ConfirmBankAllert";
import currencyRupiah from "../../library/currency";

const { Option } = Select;

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
  });
};

export default function UpdateBank({
  setIsShowYourLoanDetails,
  setIsShowDocumentList,
  language,
  getAplicationProsess,
  handleChangeActionServiceApi,
  documentLoanTypes,
  creditLimitLoan
}) {
  const [isShowLoading, setIsShowloading] = useState(false);
  const [nameBank, setNameBank] = useState("");
  const [nameCode, setNameCode] = useState("");
  const [isShowSucsessSubmitBank, setIsShowSucsessSubmitBank] = useState(false);
  const [messageError] = useState("");
  const [messageSucsess, setMessageSucsess] = useState("");
  const [messageTwo, setMessageTwo] = useState("");
  const [isShowModalPreviewImage, setIsShowModalPreviewImage] = useState(false);
  const [isShowConfirmBank, setIsShowConfirmBank] = useState(false);
  const [dataBank, setDataBank] = useState({});
  const [thidrPartyOy, setThidrParyOy] = useState({});
  const [documentLoanType, setDocumentLoanType] = useState(documentLoanTypes);
  const schema = yup.object().shape({
    recipient_bank: yup.string().required("Required"),
    recipient_name: yup.string(),
    recipient_account: yup.string().required("Required"),
  });

  const dataOffering = [
    {
      label: "Limit amount",
      value: `Rp. ${currencyRupiah(creditLimitLoan.current_limit)}`,
    },
    {
      label: "Duration",
      value: creditLimitLoan.max_tenur,
    },
    {
      label: "Rate",
      value: `${creditLimitLoan.rate} %`,
    },
    {
      label: "Grade",
      value: `${creditLimitLoan.grade}`,
    },
    // {
    //   label: "Interest",
    //   value: `${creditLimitLoan.interest} %`,
    // },
    {
      label: "Origination 1 Month",
      value: `${creditLimitLoan.origination_fee} %`,
    },
    {
      label: "Origination 2 Month",
      value: `${creditLimitLoan.additional_fee} %`,
    },
    {
      label: "Origination 3 Month",
      value: `${creditLimitLoan.other_fee} %`,
    },
  ];

  useEffect(() => {
    koinvoiceServices.getBankValidation({
      id: "0"
    }).then((data) => {
      if (data?.status === 200) {
        setDataBank(data?.data);
        let findBankName = bankAccount.find(
          (bank) => bank?.name === data?.data?.name
        );
        setNameBank(findBankName?.name);
        setNameCode(findBankName?.key);
      }
      if (data?.status === 401) {
        openNotificationWithIcon("error", "Token Expired");
      }
    });
  }, []);

  function handleChangeActionBank(value, option, setFieldValue) {
    let findBankName = bankAccount.find((bank) => bank.name === value);
    setNameBank(findBankName?.name);
    setNameCode(findBankName?.key);
    setFieldValue("recipient_bank", findBankName?.key);
  }

  function handleChangeAccountNumber(value, option, setFieldValue) {
    setFieldValue("recipient_account", value.target.value.toString());
  }

  function handleSubmit(e) {
    koinvoiceServices
        .actionThirdPartyOy({
          params: e,
          loading: setIsShowloading,
        })
        .then((thirdParty) => {
          if (
              thirdParty?.status === 200 &&
              thirdParty?.data?.oy?.status?.code === "000"
          ) {
            setThidrParyOy(thirdParty?.data);
            setIsShowConfirmBank(true);
            setIsShowSucsessSubmitBank(false);
          } else {
            setThidrParyOy({});
            setMessageSucsess((thirdParty.errors && thirdParty?.errors[0]?.moreInfo) || 'failed');
            setIsShowSucsessSubmitBank(true);
            setMessageTwo(
                "Kami tidak menemukan rekening bankmu. Mohon coba lagi atau masukan akun yang berbeda."
            );
            setIsShowConfirmBank(false);
          }
        });
  }

  function handleChangeSubmitPostBankValidation() {
    koinvoiceServices
      .postBankValidation({
        params: {
          bank_name_code: nameCode,
          bank_name: nameBank,
          bank_branch: dataBank?.branch,
          account_number: thidrPartyOy?.oy?.recipient_account || "",
          account_under_name: thidrPartyOy?.oy?.recipient_name || "",
          similarity: parseInt(thidrPartyOy?.similarity) || 0,
          result: thidrPartyOy?.result || "",
          loan_id: 0,
          loan_code: "",
          ocr_name: dataBank?.account_under_name,
          additional_document: dataBank?.additional_document,
          status: "pending",
          bank_account_owner_name: dataBank?.bank_account_owner_name
        },
        loading: setIsShowloading,
      })
      .then((postBank) => {
        if (postBank.status === 200) {
          setIsShowSucsessSubmitBank(true);
          setMessageTwo("Bank Information Updated Successfully");
        } else {
          setMessageTwo(`${postBank?.message?.en} Bank Validation Failed`);
          setIsShowSucsessSubmitBank(true);
        }
      });
  }

  function actionChangeShowHideSucsess() {
    setIsShowSucsessSubmitBank(!isShowSucsessSubmitBank);
  }

  function handleChangeShowPreviewImage() {
    setIsShowModalPreviewImage(!isShowModalPreviewImage);
  }

  function handleShowConfirmBank() {
    setIsShowConfirmBank(!isShowConfirmBank);
  }

  function nextContinueUpdateBank(params) {
    setDocumentLoanType(params);
    setIsShowYourLoanDetails(true)
    setIsShowDocumentList(false)
  }

  function contentLimitOfferApproved() {
    return (
      <React.Fragment>
        <h5>{language.title.congratulation}!</h5>
        <strong
          style={{
            color: "#2B486D",
            fontSize: 17,
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          {language.title.limitReady}!
        </strong>
        <p style={{ marginBottom: 10, marginTop: 20 }}>
          {language.title.detailLimit}:
        </p>
        <table>
          <tbody>
            {dataOffering.map((list, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: "50%" }}>{list.label}</td>
                  <td style={{ width: "50%" }}>
                    : <strong>{list.value}</strong>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p style={{ marginBottom: 10, marginTop: 20 }}>
          {language.title.additionalDesibursement}
        </p>
        <ol style={{ paddingInlineStart: 17 }}>
          {creditLimitLoan?.properties?.disbursement_add_on_req?.map(
            (e, index) => {
              return <li key={index}>{e}</li>;
            }
          )}
        </ol>
        <Button
          style={{ width: "100%", background: "#2B486D" }}
          size="large"
          type="primary"
          loading={isShowLoading}
          onClick={() => nextContinueUpdateBank("update-bank")}
        >
          {language.button.next}
        </Button>
      </React.Fragment>
    );
  }

  const options = bankAccount.map((dis) => (
    <Option key={dis.name}>{dis.name}</Option>
  ));

  function handleBackButtonCondition(){
    handleChangeActionServiceApi();
    setIsShowYourLoanDetails(true);
    setIsShowDocumentList(false)
  }

  return (
    <Card>
      <Button
        style={{ marginBottom: 25 }}
        size="large"
        onClick={() => handleBackButtonCondition()}
      >
        {language.button.back}
      </Button>
      <UpdateBankWrapper>
        {documentLoanType === "user_approved" ? (
          contentLimitOfferApproved()
        ) : (
          <div className="kw-updateBank-wrapper">
            <h4>{language.title.disbursementBankAccount}</h4>
            <span>
              {language.text.bankDisbursementText}
            </span>
            <Formik
              initialValues={{
                recipient_bank: dataBank?.code,
                recipient_name: dataBank?.account_under_name,
                recipient_account: dataBank?.account_number,
              }}
              validationSchema={schema}
              enableReinitialize
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                handleSubmit,
                handleBlur,
                values,
                errors,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Form.Item
                    label={language.label.bankName}
                    validateStatus={errors.recipient_bank && "error"}
                    help={errors.recipient_bank}
                    onBlur={handleBlur}
                  >
                    <Select
                      showSearch
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="BANK CENTRAL ASIA (BCA)"
                      value={values.recipient_bank}
                      onChange={(value, option) =>
                        handleChangeActionBank(value, option, setFieldValue)
                      }
                    >
                      {options}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={language.label.accountNumber}
                    validateStatus={errors.recipient_account && "error"}
                    help={errors.recipient_account}
                    onBlur={handleBlur}
                  >
                    <Input
                      size="large"
                      type="number"
                      name="recipient_account"
                      placeholder="12345678910"
                      onChange={(value, option) =>
                        handleChangeAccountNumber(value, option, setFieldValue)
                      }
                      value={values.recipient_account}
                    />
                  </Form.Item>
                  <Spin spinning={isShowLoading}>
                    <Button
                      htmlType="submit"
                      className="kw-button"
                      size="large"
                      type="primary"
                    >
                      {language.button.bankSubmit}
                    </Button>
                  </Spin>
                  {["update-bank","user_approved", "choose_offer"].includes(documentLoanType) && (
                    <Button
                      size="large"
                      type="primary"
                      style={{width:"100%",marginTop:10}}
                      onClick={() => nextContinueUpdateBank("choose_offer")}
                    >
                      {language.button.backLimitOffer}
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          </div>
        )}
        <DrawerAllert
          language={language}
          visible={isShowSucsessSubmitBank}
          image={
            messageTwo === "Bank Information Updated Successfully"
              ? require("../../assets/images/Frame.png").default
              : require("../../assets/images/contract.png").default
          }
          onClose={actionChangeShowHideSucsess}
          setIsShowDocumentList={setIsShowDocumentList}
          setIsShowYourLoanDetails={setIsShowYourLoanDetails}
          iShowButtonClose={false}
          labelText={
            messageTwo === "Bank Information Updated Successfully"
              ? language.text.bankUpdateSuccessfuly
              : capitalizeFirstLetter(messageSucsess)
          }
          paragraphTextTwo={
            messageTwo !== "Bank Information Updated Successfully" && messageTwo
          }
          getAplicationProsess={getAplicationProsess}
          handleChangeActionServiceApi={handleChangeActionServiceApi}
          setIsShowloading={setIsShowloading}
          closeFunction={
            messageTwo === "Bank Information Updated Successfully" && true
          }
          thidrPartyOy={thidrPartyOy}
        />
        <ConfirmBankAllert
          language={language}
          visible={isShowConfirmBank}
          thidrPartyOy={thidrPartyOy}
          isShowLoading={isShowLoading}
          dataBank={dataBank}
          onClose={handleShowConfirmBank}
          nameBank={nameBank}
          messageError={messageError}
          handleChangeShowPreviewImage={handleChangeShowPreviewImage}
          handleChangeSubmitPostBankValidation={
            handleChangeSubmitPostBankValidation
          }
        />
      </UpdateBankWrapper>
    </Card>
  );
}
