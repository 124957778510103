import { docService } from "../../services/document";
import { loanService } from "../../services/loan";

export const getRequiredDocuments = (token, status) => (dispatch) => {
  docService.getRequiredDocument(token, status)
    .then(res => {
      if (res.status === 200 && res.data) {
        dispatch(getRequiredSuccess(res.data));
      } else {
        dispatch(getRequiredFailed(res.message));
      }
    },
    error => {
      dispatch(getRequiredFailed(error));
  });
}

export const getApprovedDocuments = (token) => (dispatch) => {
  docService.getApprovedDocument(token)
    .then(res => {
      if (res.status === 200 && res.data) {
        dispatch(getApprovedSuccess(res.data));
      } else {
        dispatch(getApprovedFailed(res.message));
      }
    },
    error => {
      dispatch(getApprovedFailed(error));
  });
}

export const getLoanContracts = (token) => (dispatch) => {
  docService.getLoanContract(token)
    .then(res => {
      if (res.status === 200 && res.data) {
        dispatch(getContractSuccess(res.data));
      } else {
        dispatch(getContractFailed(res.message));
      }
    },
    error => {
      dispatch(getContractFailed(error));
  });
}

export const sendDocumentLink = (document, loan, auth) => (dispatch) => {
  loanService.sendDocumentLink(document, loan, auth)
    .then(res => {
      if (res.status === 200 )
        dispatch(sendDocumentLinkSuccess(res.data));
      else
        dispatch(sendDocumentLinkFailed(res.message));
    },
    error => {
      dispatch(sendDocumentLinkFailed(error));
  });
}

export const resetSendLink = action => {
  return { type: "RESET_SEND_LINK", ...action }
}

const getRequiredSuccess = data => {
  return { type: "GET_REQUIRED_SUCCESS", payload: data }
}

const getRequiredFailed = errors => {
  return { type: "GET_REQUIRED_FAILED", payload: errors }
}

const getContractSuccess = data => {
  return { type: "GET_CONTRACT_SUCCESS", payload: data }
}

const getContractFailed = errors => {
  return { type: "GET_CONTRACT_FAILED", payload: errors }
}

const getApprovedSuccess = data => {
  return { type: "GET_APPROVED_SUCCESS", payload: data }
}

const getApprovedFailed = errors => {
  return { type: "GET_APPROVED_FAILED", payload: errors }
}

const sendDocumentLinkSuccess = data => {
  return { type: "SEND_LINK_SUCCESS", payload: data }
}

const sendDocumentLinkFailed = errors => {
  return { type: "SEND_LINK_FAILED", payload: errors }
}