import styled from "styled-components";

export const SecurityCodePageWrapper = styled.div`
  .kw-button {
    background-color: #2b476c !important;
    border-color: #2b476c !important;
    color: #ffffff !important;
  }
  
  .reset-password {
    h3 {
      font-size: 24px;
      text-align: center;
      color: #2b486d;
    }

    p {
      color: #566068;
      text-align: center;
      font-size: 17px;
    }
  }

  .password-validator {
    &__inValid {
      display: flex;
    }

    &__valid {
      display: flex;
    }

    &__valid .error_message {
      color: #41af60;
      font-weight: 400;
    }

    &__inValid .error_message {
      color: #cccccc;
      font-weight: 400;
    }

    &__valid .icon_valid {
      top-left: 1px, 0;
      size: 19px, 19px;
      transition: all 0.2s ease-in-out;
    }

    &__inValid .icon_invalid {
      color: red;
    }
  }
  background: #f2f2f2;

  a {
    text-decoration: underline;
  }

  .page-content-wrapper {
    /* width: 60%; */
    margin: 0 auto;
    padding-top: 30px;
  }
  .logo-wrapper {
    position: relative;
    top: -65px;
    width: 131px;
    margin: 0 auto;
    .logo-img {
      position: absolute;
    }
  }
  .register-body {
    padding: 30px 35px;
    .register-title {
      text-align: center;

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 33px;
      line-height: 41px;
      /* or 121% */

      text-align: center;
      letter-spacing: 0.41px;

      color: #2b486d;
    }
    .text-note {
      font-size: 15px;
    }
  }

  .input-wrapper {
    margin-bottom: 15px;
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 80%;
    color: #c53741;
    text-align: center;
  }
  .back-button {
    color: #c1c1c1;
    padding: 15px;
    font-size: 18px;
    i {
      margin-right: 7px;
    }
  }
  .otp-input {
    justify-content: center;
    input {
      height: 50px !important;
      width: 50px !important;
      border: none;
      border: 1.5px solid #dcdcdc;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 10px !important;
    }
  }
  .otp-input.invalid {
    input {
      border-color: #c53741;
    }
  }
  .resend-wrapper {
    padding: 25px;
    padding-bottom: 15px;
    .resend-btn {
      justify-content: center !important;
      color: #c4c4c4;
    }
  }
`;
