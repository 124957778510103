import React from "react";
import { Section } from "./style";

function MobileLayout(props) {
  return (
    <Section>
      <div className="kw-mainLayout-wrapper">{props.children}</div>
    </Section>
  );
}

export default MobileLayout;
