import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from "./reducers";
import compose from 'lodash/fp/compose';

// const store = (window.devToolsExtension
//   ? window.devToolsExtension()(createStore)
//   : createStore)(
//     rootReducer, 
//     applyMiddleware(thunkMiddleware)
//   );

const store = createStore(
  rootReducer, 
  compose(
    applyMiddleware(thunkMiddleware),
    window.REDUX_DEVTOOLS_EXTENSION ? window.REDUX_DEVTOOLS_EXTENSION : f => f
  )
);

export default store;