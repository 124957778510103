const initState = {
  data: null,
  detail: null
};

const scheduleReducer = (state = initState, action) => {
	switch (action.type) {
    case "GET_SCHEDULE_SUCCESS":
      return { ...state, data: action.payload, getSchedule: true }
    case "GET_SCHEDULE_FAILED":
      return { ...state, errors: action.payload, getScheduleFailed: true }
    case "GET_LOAN_SCHEDULE_SUCCESS":
      return { ...state, schedule: action.payload}
    case "GET_LOAN_SCHEDULE_FAILED":
      return { ...state, errors: action.payload}
    case "GET_DETAIL_LOADING":
      return { ...state, getDetailLoading: true }
    case "GET_DETAIL_SUCCESS":
      return { ...state, getDetailLoading: false, getDetail: true, detail: action.payload }
    case "GET_DETAIL_FAILED":
      return { ...state, detailErrors: action.payload }
    case "PAYMENT_BALANCE_INSUFFICIENT":
      return { ...state, isNotEnoughCash: true, amountTransfer: action.payload }
    case "SCHEDULE_PAYMENT_SUCCESS":
      return { ...state, isPaymentSuccess: true }
    case "SCHEDULE_PAYMENT_FAILED":
      return { ...state, isPaymentFailed: true, paymentError: action.payload }
    case "CONFIRM_PAYMENT":
      return { ...state, detail: action.data }
    case "SHOW_SCHEDULE_DETAIL":
      return { ...state, detail: action };
    case "FILTER_SCHEDULE":
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default scheduleReducer;
