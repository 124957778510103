import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CalculatorWrapper } from "./Calculator.styles";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import { Accordion } from "react-bootstrap";

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    placeholder="E.g.: 10,000,000"
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    isNumericString
  />
);

class Calculator extends Component {
  state = {
    advance: "",
    minAdvance: 0,
    maxAdvance: 0,
    regular: "",
    minRegular: 0,
    maxRegular: 0
  }

  advanceCalculation = ({ target }) => {
    let { advance, minAdvance, maxAdvance } = this.state;
    advance = target.value;
    minAdvance = advance * (30 / 100);
    maxAdvance = advance * (70 / 100);
    this.setState({ advance, minAdvance, maxAdvance });
  }

  regularCalculation = ({ target }) => {
    let { regular, minRegular, maxRegular } = this.state;
    regular = target.value;
    minRegular = 3000000;
    maxRegular = regular * 6 > 100000000 ? 100000000 : regular * 6;
    this.setState({ regular, minRegular, maxRegular });
  }

  render() {
    let { init: { language } } = this.props;
    return (
      <CalculatorWrapper>
        {/* calculator */}
        <section className="calculator">
          <div className="container">
            <h2 className="heading">
              {language.homepage.tryItYourself}
            </h2>
            <div className="row">
              <div className="col-12 col-sm-12">
                <div className="card card-adv">
                  <div className="card-body">
                    <h5 className="card-title">
                      {language.homepage.salaryAdvanceTitle}
                    </h5>
                    <p className="card-text">
                      {language.homepage.salaryAdvanceText}{" "}
                      <span>{language.homepage.learnMore}</span>
                    </p>

                    <Accordion defaultActiveKey="0">
                      <Accordion.Collapse eventKey="0">
                        <form noValidate autoComplete="off">
                          <div className="input-wrapper">
                            <TextField
                              className="salary-info w-100"
                              label={language.homepage.netSalary}
                              variant="outlined"
                              value={this.state.advance}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: (
                                  <InputAdornment className="adorement-edit">
                                    / {language.homepage.month}
                                  </InputAdornment>
                                )
                              }}
                              onChange={this.advanceCalculation}
                            />
                          </div>
                          <div className="input-wrapper">
                            <div className="box-total">
                              <div className="box-list ctotal">
                                <span className="text-left">
                                  <span className="label">
                                    {language.homepage.availableToBorrow} <br />
                                  </span>
                                  <span className="value">
                                    IDR &nbsp;
                                    <NumberFormat
                                      value={this.state.minAdvance}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    /> &nbsp; - &nbsp;
                                    <NumberFormat
                                      value={this.state.maxAdvance}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </span>
                                </span>
                                <span className="text-right">
                                  <span className="end-text">/ month</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <Link to="/loan-app">
                            <button className="btn btn-apply">
                              {language.homepage.applyNow}
                            </button>
                          </Link>
                        </form>
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CalculatorWrapper>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init
});

export default connect(mapStateToProps, null)(Calculator);
