import React from "react";
import AsyncSelect from "react-select/async";

const AsyncSelectInput = ({
  input,
  defaultValue,
  placeholder,
  loadOptions
}) => (
  <AsyncSelect
    cacheOptions
    defaultOptions
    isClearable
    placeholder={placeholder}
    className="basic-single"
    classNamePrefix="select"
    name={input.name}
    defaultValue={defaultValue}
    value={input.value}
    onChange={input.onChange}
    loadOptions={loadOptions}
    noOptionsMessage={() => null}
  />
);

export default AsyncSelectInput;