// import "./polyfills.js";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./stores";
import { Helmet } from "react-helmet";
import { hostNameCheckCondition } from "./library/checkHostNameWeb.js";
import ClevertapReact from "./util/clevertap";
import { datadogRum } from '@datadog/browser-rum';

const tittleKoinEmployee = "KoinWorks - Koin Employee";
const tittleKoinInvoice = "Koinworks - Koin Invoice";

ClevertapReact.initialize(process.env.REACT_APP_CLEVERTAP_ID);

datadogRum.init({
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    clientToken: process.env.REACT_APP_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.REACT_APP_SERVICE,
    
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Helmet>
            <title>{hostNameCheckCondition ? tittleKoinInvoice : tittleKoinEmployee}</title>
        </Helmet>
        <App />
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
