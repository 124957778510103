import koinvoiceServices from "../../services/koinvoice";
import history from "../../util/history";
import cookie from "react-cookies";
import TYPE from "./type";
import { openNotificationWithIcon } from "../../library/notifications";

const limitApplicationSucsess = (authData) => {
  return {
    type: TYPE.LIMIT_APPLICATION_SUCSESS,
    payload: authData,
  };
};

const limitApplicationError = (authData) => {
  return {
    type: TYPE.LIMIT_APPLICATION_ERROR,
    payload: authData,
  };
};

export const actionPostLimitApplicationRedux = (
  request,
  setIsShowLoading = function () {},
  setPage,
  init
) => async (dispatch) => {
  setIsShowLoading(true);

  const responseLimitApplication = await koinvoiceServices.actionPostLimitApplication(
    { params: request }
  );
  if (responseLimitApplication?.status === 200) {
    dispatch(limitApplicationSucsess(responseLimitApplication));
    cookie.save("displayPage", 2, {
      //path: "/",
      maxAge: 3600,
    });
    
    if (request.userId) {
      localStorage.setItem(`limit-id-${request.userId}`, JSON.stringify(responseLimitApplication.data));
    } else {
      cookie.save("limit-application", responseLimitApplication, {
        //path: "/",
        maxAge: 3600,
      });
      setIsShowLoading(false);
    }
    
    if(request.origin && request.origin === 'backoffice') {      
      history.push("/lms/limit/business-info/" + request.userId);
    } else {
      history.push("/koininvoice/apply-limit/business-info");
    }
  } else {
    dispatch(limitApplicationError(responseLimitApplication));
    const temp = responseLimitApplication.errors[0].userMessage.split(",");
    const tempEmail = responseLimitApplication.errors[0].userMessage.split("1 LA code for 1 borrower, you inputed LA code of");
  
    if(temp[0] === "1 LA code for 1 borrower") {
      openNotificationWithIcon("error", init.language.loanKoinVoice.laCodeErrorTwo + tempEmail[1]);
    } else if(responseLimitApplication.errors[0].userMessage === "La Code Duplicate") {
      openNotificationWithIcon("error", init.language.loanKoinVoice.laCodeErrorOne);
    } else {
      openNotificationWithIcon("error", responseLimitApplication.errors[0].userMessage);
    }
    setIsShowLoading(false);
    cookie.remove("limit-application", { path: "/" });
    cookie.save("displayPage", 1);
    if (responseLimitApplication?.status === 401) {
      history.push("/login");
    }
  }
};
