import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeLanguage } from "../stores/actions/account";
import { Navbar, Dropdown } from "react-bootstrap";
import { NavigationWrapper } from "./Navigation.styles";
import cookie from "react-cookies";

import koinworksLogo from "../assets/images/koinworksLogo.png";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage = ({ target }) => {
    localStorage.setItem("language", target.value);
    this.props.changeLanguage(target.value);
  };

  handleLogout() {
    cookie.remove("token", { path: "/" });
    cookie.remove("name", { path: "/" });
    cookie.remove("email", { path: "/" });
    localStorage.removeItem("creditLimitLoan")
    window.location.reload();
  }

  render() {
    let {
      init: { flag },
    } = this.props;
    return (
      <NavigationWrapper>
        <Navbar>
          <Navbar.Brand href="#home">
            <img
              src={koinworksLogo}
              className="koinworksLogo"
              alt="koinworks logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {window.location.pathname.split("/")[1] === "login" ||
            window.location.pathname.split("/")[1] === "register" ? (
              ""
            ) : (
              <div className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    id="dropdown-profile"
                    className="btn-profile"
                  >
                    <i className="icon-user"></i>{" "}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.handleLogout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <Navbar.Text>
              <div className="lang-selector" onChange={this.changeLanguage}>
                <input
                  id="lang-id"
                  className="lang"
                  type="radio"
                  value="id"
                  name="language"
                  defaultChecked={flag === "id"}
                />
                <label htmlFor="lang-id">ID</label>
                <input
                  id="lang-en"
                  className="lang"
                  type="radio"
                  value="en"
                  name="language"
                  defaultChecked={flag === "en"}
                />
                <label htmlFor="lang-en">EN</label>
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </NavigationWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeLanguage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
