import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Spin,
  Radio,
  Checkbox,
} from "antd";
import ModalViewKtp from "../../../ModalView";
import ModalViewMatchKtp from "../../../ModalView";
import ModalViewNpwp from "../../../ModalView";
import ModalViewKK from "../../../ModalView";
import UploadKtpModal from "../../../ModalUpload/UploadKtpModal";
import UploadSelfieKtpModal from "../../../ModalUpload/UploadSelfieKtpModal";
import moment from "moment";
import {
  getProvinceKoinvoice,
  getDistrictShareHolderKoinvoice,
  getSubDistrictShareHolderKoinvoice,
  actionButtonBackShareHolder,
  actionGetPersonalShareHolderInfoLocal,
  actionGetPersonalShareHolderInfoLocalReset,
  actionUploadKtpSelfieKtp,
  actionPutAppGuarantorUpdate,
  getCityKoinvoice,
  businessInformationKoinvoice,
} from "../../../../stores/actions/koinvoiceLoan";
import { actionPutAppGuarantorCreate } from "../../../../stores/actions/lmsLimit";
import { uploadBusinessShareHolderKoinVoice } from "../../../../stores/actions/lmsLimit";
import { CheckCircleTwoTone, ArrowLeftOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { bindActionCreators } from "redux";
import { ShareHolderStyle } from "./ShareHolder.style";
import DrawerConfirmationDelete from "./DrawerConfirmationDelete";
import "./style.css";
import { convertToCategoryName } from "../../../../library/regex";
import UploadKkModal from "../../../ModalUpload/UploadKkModal";
import UploadNpwpModal from "../../../ModalUpload/UploadNpwpModal";
import _ from "lodash";
import cookie from "react-cookies";
import koinvoiceServices from "../../../../services/koinvoice";
import { trimOssUrl } from "../../../../library/fileHelper";
import { checkTranslate } from "../../../../library/checkCapitalize";
import { PercentageOutlined } from '@ant-design/icons';

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

const { Option } = Select;

function ShareHolderInformation({
  actionChangeDeleteList,
  init: { language },
  infromationEdit,
  action,
  uploadBusinessShareHolderKoinVoice,
  getDistrictShareHolderKoinvoice,
  actionUploadKtpSelfieKtp,
  auth,
  actinChangeShowHideAddNew,
  actionPutAppGuarantorCreate,
  actionPutAppGuarantorUpdate,
  actionButtonBackShareHolder,
  actionGetPersonalShareHolderInfoLocal,
  businessInformationKoinvoice,
  isShowDrawerDeleteSucsess,
  actionChangeShowDrawerDelete,
  guarantorShareHolder,
  koinvoice: {
    uploadKK,
    uploadShareHolder,
    uploadKtpShareHolderSucess,
    uploadKtpShareHolderError,
    uploadNpwpShareHolderSucess,
    uploadNpwpShareHolderError,
    uploadSelfieShareHolder,
    uploadMatchKtpShareHolderError,
    districtShareHolder,
    nextCreateGuarantor,
    nextUpdateGuarantor,
    isShowLoading,
  },
  token,
  userId
}) {
  const addData = JSON.parse(localStorage.getItem(`add-data-${userId}`));

  const [businessInformation, setBusinessInformation] = useState({});
  const [shareholderTypeCorp, setShareholderTypeCorp] = useState("personal");
  const [businessType, setBusinessType] = useState("");
  const [marital_status, setMarital_Status] = useState("");
  const [visibleUploadKtp, setVisibleUploadKtp] = useState(false);
  const [visibleUploadSelfieKtp, setVisibleUploadSelfieKtp] = useState(false);
  const [visibleUploadNpwp, setVisibleUploadNpwp] = useState(false);
  const [isShowViewKtpModal, setIsShowViewKtpModal] = useState(false);
  const [isShowViewNpwpModal, setIsShowViewNpwpModal] = useState(false);
  const [isShowViewMatchKtpModal, setIsShowViewMatchKtpModal] = useState(false);
  const [validationImageKtp, setValidationImageKtp] = useState(parseInt(0));
  const [validationImageKtpPersonal, setValidationImageKtpPersonal] = useState(
    parseInt(0)
  );
  const [
    isShowvalidationImageKtpPersonal,
    setIsShowValidationImageKtpPersonal,
  ] = useState(false);
  const [validationMatchImageKtp, setValidationMatchImageKtp] = useState(0);
  const [validationMatchImageKtpFailed, setValidationMatchImageKtpFailed] =
    useState(parseInt(0));
  const [
    isShowvalidationMatchImageKtpFailed,
    setIsShowValidationMatchImageKtpFailed,
  ] = useState(false);
  const [isShowLoadingShareHolder, setIsShowLoadingShareHolder] =
    useState(false);
  const [pathKtp, setPathKtp] = useState("");
  const [pathNpwp, setPathNpwp] = useState("");
  const [isShowHideAlertKtp, setIsShowAlertKtp] = useState("");
  const [isShowHideAlertUploadKtp, setIsShowAlertUploadKtp] = useState("");
  const [homeAddress, setHomeAddress] = useState("different-address");
  const [isShowCurrentlyAddress, setIsShowCurrentlyAddress] = useState(true);
  const [isShowViewKKModal, setIsShowViewKKModal] = useState(false);
  const [visibleUploadKk, setVisibleUploadKk] = useState(false);
  const [validationImageKk, setValidationImageKk] = useState(parseInt(0));
  const [checkBusinessTypeInformation, setCheckBusinessTypeInformation] =
    useState(false);
  const [pathKK, setPathKK] = useState("");
  //const [code, setCode] = useState();
  const [subDistrict, setSubDistrict] = useState([]);
  const mounted = useRef();
  const schemaShareHolder = yup.object().shape({
    full_name: yup.string().required(language.loanShareHolder.requiredFullName),
    is_corporate: yup.string(),
    nik: shareholderTypeCorp === 'personal' ?
      yup.string().required(language.loanShareHolder.requiredNik) :
      yup.string(),
    npwp_number: shareholderTypeCorp === 'corporate' ?
      yup.string().required(language.loanShareHolder.requiredNpwp) :
      yup.string(),
    pob: shareholderTypeCorp === 'personal' ?
      yup.string().required(language.loanShareHolder.requiredPob) :
      yup.string(),
    dob: shareholderTypeCorp === 'personal' ?
      yup.string().required(language.loanShareHolder.requiredDob) :
      yup.string(),
    address: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredAddress)
      : yup.string(),
    district: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredDistrict)
      : yup.string(),
    sub_district: isShowCurrentlyAddress
      ? yup.string().required("Sub District Required")
      : yup.string(),
    province: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredProvince)
      : yup.string(),
    province_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredProvince),
    city: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredCity)
      : yup.string(),
    postal_code: isShowCurrentlyAddress
      ? yup
          .string()
          .required(language.loanShareHolder.requiredPostalCode)
          .length(5, "Postal Code Must 5")
      : yup.string(),
    address_duplicate: isShowCurrentlyAddress
      ? yup.string()
      : yup.string().required(language.loanShareHolder.requiredAddress),
    sub_district_duplicate: isShowCurrentlyAddress
      ? yup.string()
      : yup.string().required("Sub District Required"),
    district_duplicate: isShowCurrentlyAddress
      ? yup.string()
      : yup.string().required(language.loanShareHolder.requiredDistrict),
    postal_code_duplicate: isShowCurrentlyAddress
      ? yup.string()
      : yup
          .string()
          .required(language.loanShareHolder.requiredPostalCode)
          .length(5, "Postal Code Must 5"),
    gender: shareholderTypeCorp === 'personal' ?
      yup.string().required(language.loanShareHolder.requiredGender) :
      yup.string(),
    marital_status: shareholderTypeCorp === 'personal' ?
      yup.string().required(language.loanShareHolder.requiredMaritalStatus) :
      yup.string(),
    // mobile_number: yup
    //   .string()
    //   .required(language.loanShareHolder.requiredMobileNumber),
    home_ownership_status: yup.string(),
    email: yup.string().required(language.loanShareHolder.requiredEmail).email(language.loanShareHolder.wrongEmail),
    sibling_type: yup.string(),
    ktp: shareholderTypeCorp === 'personal' ?
      yup.string().required(language.loanShareHolder.requiredKtp) :
      yup.string(),
    npwp_url: yup.string(),
    kk_image:
      ["individual", "industri_rumah_tangga","home_business"].includes(businessType) &&
      checkBusinessTypeInformation
        ? yup.string().required("Required KK Home Business")
        : yup.string(),
    sibling_relation: yup
      .string()
      .required(language.loanShareHolder.requiredSiblingRelation),
    saham: ["pt/cv", "pt", "cv"].includes(businessType)
      ? yup.string().required(language.loanShareHolder.requiredShare)
      : yup.string(),
    jabatan: ["pt/cv", "pt", "cv"].includes(businessType)
      ? yup.string().required(language.loanShareHolder.position)
      : yup.string(),
  });
  const paramsSubmitShareHolderInformation =
    cookie.load("paramsSubmitShareHolderInformation") !== undefined &&
    cookie.load("paramsSubmitShareHolderInformation");

  useEffect(() => {
    window.scrollTo(0, 0);
    actionUploadKtpSelfieKtp();
    koinvoiceServices.getBusinessInfo(token).then((res) => {
      if (res?.status === 200) {
        setBusinessInformation(res);
        setBusinessType(
          res?.data?.details?.business?.company_detail?.business_type?.value
        );
        setMarital_Status(
          res?.data?.details?.personal?.user_information?.marital_status?.value
        );
      }
    }); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(infromationEdit?.marital_status === "married") {
      setCheckBusinessTypeInformation(true);
    }
  }, [infromationEdit])

  function actionChangeKtpUpload(values, setFieldValue) {
    setPathKtp(values.path);
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email:
          auth.email || (businessInformation && businessInformation.data.email),
      };
      uploadBusinessShareHolderKoinVoice(
        params,
        handleChangeShowUploadKtp,
        setFieldValue,
        setValidationImageKtp,
        validationImageKtp,
        setValidationImageKtpPersonal,
        validationImageKtpPersonal,
        setIsShowAlertKtp,
        setIsShowAlertUploadKtp,
        token
      );
    };
    fileReader.readAsDataURL(values);
  }

  function actionChangeNpwpUpload(values, setFieldValue) {
    setPathKtp(values.path);
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email:
          auth.email || (businessInformation && businessInformation.data.email),
      };
      uploadBusinessShareHolderKoinVoice(
        params,
        handleChangeShowUploadKtp,
        setFieldValue,
        setValidationImageKtp,
        validationImageKtp,
        setValidationImageKtpPersonal,
        validationImageKtpPersonal,
        setIsShowAlertKtp,
        setIsShowAlertUploadKtp,
        token
      );
    };
    fileReader.readAsDataURL(values);
  }

  function handleChangeType(e) {
    if (e.target.value) {
      setShareholderTypeCorp(e.target.value);
    }
  }

  function handleChangeShowUploadKtp(value) {
    setVisibleUploadKtp(!visibleUploadKtp);
    if (value === "reUploadKtp") {
      setIsShowValidationImageKtpPersonal(true);
      setValidationImageKtpPersonal(0);
    }
  }

  function handleChangeShowUploadNpwp(value) {
    setVisibleUploadNpwp(!visibleUploadNpwp);
  }

  function handleChangeShowViewMatchKtp() {
    setIsShowViewMatchKtpModal(!isShowViewMatchKtpModal);
  }

  function handleChangeShowViewKtp() {
    setIsShowViewKtpModal(!isShowViewKtpModal);
  }

  function handleChangeShowViewNpwp() {
    setIsShowViewNpwpModal(!isShowViewNpwpModal);
  }

  function actionChangeBirthDate(value, option, setFieldValue) {
    setFieldValue("dob", option);
  }

  function handleChangeShowViewKK() {
    setIsShowViewKKModal(!isShowViewKKModal);
  }

  function handleChangeShowUploadKk() {
    setVisibleUploadKk(!visibleUploadKk);
  }

  // function handleChangePhoneInput(value, option, setFieldValue) {
  //   setFieldValue("phone_number", value);
  // }

  function handleChangeMobileInput(value, option, setFieldValue) {
    setFieldValue("mobile_number", value);
  }

  function handleChangeShowUploadSelfieKtp(value) {
    setVisibleUploadSelfieKtp(!visibleUploadSelfieKtp);
    if (value === "reUploadKtp") {
      setIsShowValidationMatchImageKtpFailed(true);
      setValidationMatchImageKtpFailed(0);
    }
  }

  function actionChangeSearchDistrict(value) {
    //getDistrictShareHolderKoinvoice(`${value}&city_code=${code}`);
    getDistrictShareHolderKoinvoice(`${value}`);
  }

  function actionChangeDistrict(value, option, setFieldValue) {
    // const districtValidation =
    //   districtShareHolder.data &&
    //   districtShareHolder.data.find((dis) => dis.name === value);
    // setCode(districtValidation.code);
    setFieldValue("district", value);
  }

  function actionChangeDistrictDuplicate(value, option, setFieldValue) {
    setFieldValue("district_duplicate", value);
  }

  // function actionChangeCity(value, option, setFieldValue) {
  //   const cityValidation =
  //     city.data && city.data.find((dis) => dis.name === value);
  //   setCode(cityValidation.code);
  //   setFieldValue("city", value);
  // }

  // function actionChangeCityDuplicate(value, option, setFieldValue) {
  //   const cityValidation =
  //     city.data && city.data.find((dis) => dis.name === value);
  //   setCode(cityValidation.code);
  //   setFieldValue("city_duplicate", value);
  // }

  // function actionChangeProvince(value, option, setFieldValue) {
  //   const provinceValidation =
  //     province.data && province.data.find((dis) => dis.name === value);
  //   setCode(provinceValidation.code);
  //   setFieldValue("province", value);
  // }

  // function actionChangeProvinceDuplicate(value, option, setFieldValue) {
  //   const provinceValidation =
  //     province.data && province.data.find((dis) => dis.name === value);
  //   setCode(provinceValidation.code);
  //   setFieldValue("province_duplicate", value);
  // }

  // function actionChangeLivedFor(value, option, setFieldValue) {
  //   setFieldValue("has_lived_for", value);
  // }

  // function actionChangeSearchCity(value) {
  //   getCityKoinvoice(`${value}&province_code=${code}`);
  // }

  // function actionChangeSearchProvince(value) {
  //   getProvinceKoinvoice(value);
  // }

  // function handleChangeOwnerShip(value, option, setFieldValue) {
  //   setFieldValue("home_ownership_status", value);
  // }

  // function handleChangeSiblingType(value, option, setFieldValue) {
  //   setFieldValue("sibling_type", value);
  // }

  // function handleChangeGender(value, option, setFieldValue) {
  //   setFieldValue("gender", value.target.value);
  // }

  // function handleChangeNationality(value, option, setFieldValue) {
  //   setFieldValue("nationality", value.target.value);
  // }

  function actionChangeSaham(value, option, setFieldValue) {
    let tempValue;

    if(
        (value["target"]["value"].includes(",") && value["target"]["value"] !== "") || 
        (value["target"]["value"].includes(".") && value["target"]["value"] !== "")
      ) {
      tempValue = parseFloat(value["target"]["value"].replace(",", ".")).toFixed(2);
      if(tempValue > 100.00) {
        tempValue = 100.00;
        setFieldValue("saham", tempValue.toString());
      } else {
        let tempStringArr = value["target"]["value"].replace(",", ".").split(".");
        let cutTwo = tempStringArr[1].slice(0, 2);
        setFieldValue("saham", tempStringArr[0] + "." + cutTwo);
      }
    } else if(
        (!value["target"]["value"].includes(",") && value["target"]["value"] !== "") || 
        (!value["target"]["value"].includes(".") && value["target"]["value"] !== "")
      ) {
      tempValue = parseInt(value["target"]["value"]);
      if(tempValue > 100) {
        tempValue = 100.00;
        setFieldValue("saham", tempValue.toString());
      } else {
        setFieldValue("saham", value["target"]["value"]);
      }
    } else {
      setFieldValue("saham", "");
    }
  }

  function handleChangeRelationship(value, option, setFieldValue) {
    setFieldValue("sibling_relation", value);
  }

  function handleChangePosition(value, option, setFieldValue) {
    setFieldValue("jabatan", value);
  }

  function handleSubmit(e) {
    const {
      full_name,
      nik,
      pob,
      dob,
      address,
      province,
      sibling_type,
      city,
      district,
      postal_code,
      sub_district,
      address_duplicate,
      province_duplicate,
      city_duplicate,
      postal_code_duplicate,
      mobile_number,
      email,
      ktp,
      kk_image,
      gender,
      home_ownership_status,
      sibling_relation,
      marital_status,
      saham,
      jabatan,
      owner_sub_district_duplicate,
      npwp_url,
      npwp_number
    } = e;
    let splitTemp = shareholderTypeCorp === 'personal' ?
      dob.split("-") :
      [];
    let paramsSubmit = {
      full_name,
      nik: nik || "",
      pob: shareholderTypeCorp === 'personal' ? pob : "",
      dob: shareholderTypeCorp === 'personal' ?
        splitTemp[0].length === 4 ? dob : dob.split("-").reverse().join("-") :
        "",
      address,
      province,
      city,
      district,
      subdistrict: sub_district,
      postal_code: isShowCurrentlyAddress
        ? postal_code.toString()
        : "" || postal_code
        ? postal_code.toString()
        : "",
      mobile_number,
      email,
      ktp: trimOssUrl(ktp) || "",
      gender: shareholderTypeCorp === 'personal' ? gender : "",
      sibling_relation,
      marital_status: shareholderTypeCorp === 'personal' ? marital_status : "00000000-0000-0000-0000-000000000000",
      home_ownership_status,
      sibling_type,
      product: "a1fb40e7-e9c5-11e9-97fa-00163e010bca",
    };
    Object.assign(paramsSubmit, {
      user_id: businessInformation.data && businessInformation.data.user_id,
      properties: {
        saham: saham,
        jabatan: jabatan,
        address: address_duplicate || address,
        province: province_duplicate || province,
        city: city_duplicate || city,
        district: district,
        subdistrict: owner_sub_district_duplicate || sub_district,
        postal_code: isShowCurrentlyAddress
          ? ""
          : postal_code_duplicate.toString() || postal_code_duplicate
          ? postal_code_duplicate.toString()
          : "",
        is_corporate: shareholderTypeCorp === 'corporate' ? true : false,
        npwp_url: trimOssUrl(npwp_url),
        npwp_number: npwp_number.replace(/\D/g, ""),
        family_card: checkBusinessTypeInformation ? trimOssUrl(kk_image) : "",
      },
      is_address_same: isShowCurrentlyAddress ? 1 : 0,
    });
    action === "add-new" &&
      actionPutAppGuarantorCreate(paramsSubmit, setIsShowLoadingShareHolder, token);
    action === "edit" &&
      actionPutAppGuarantorUpdate(
        infromationEdit.user_sibling_id,
        paramsSubmit,
        setIsShowLoadingShareHolder,
        token
      );
    actionGetPersonalShareHolderInfoLocal(paramsSubmit);
    localStorage.setItem(
      "paramsSubmitShareHolderInformation",
      JSON.stringify(paramsSubmit)
    );
    cookie.save("paramsSubmitShareHolderInformation", paramsSubmit, {
      path: "/",
      maxAge: 3600,
    });
  }

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (nextUpdateGuarantor) {
        handleChangeShareHolder("update-data");
      }
      if (nextCreateGuarantor) {
        handleChangeShareHolder("create-data");
      }
    }
  });

  useEffect(() => {
    if (infromationEdit.properties && infromationEdit.properties.address) {
      setIsShowCurrentlyAddress(false);
    }
  }, [infromationEdit]);

  function handleChangeShareHolder(e) {
    actionButtonBackShareHolder();
    actinChangeShowHideAddNew(e);
  }

  function onChangeAddressCurrentlyLive(e) {
    setIsShowCurrentlyAddress(e.target.checked);
  }

  function handleChangeAddressDuplicate(value, option, setFieldValue) {
    setFieldValue("address_duplicate", value.target.value);
  }

  function handleChangeMaritalStatus(value, option, setFieldValue, check) {
    setFieldValue("marital_status", option.value);
    if (option.value === "39a35288-f562-11e9-97fa-00163e010bca") {
      setCheckBusinessTypeInformation(true);
    } else {
      setCheckBusinessTypeInformation(false);
    }
  }

  function handleChangeAddress(value, option, setFieldValue) {
    setFieldValue("address", value.target.value);
  }

  function actionChangeSearchSubDistrict(value) {
    //getSubDistrictShareHolderKoinvoice(`${value}&district_code=${code}`);
    koinvoiceServices
      .actionGetLocationThirdParty({ params: value, token:token })
      .then((res) => {
        if (res?.status === 200 && res?.data !== null) {
          setSubDistrict(res.data);
        }
      });
  }

  function actionChangeSubDistrict(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value
    );
    setFieldValue("district", districtSubValidation.district);
    setFieldValue("sub_district", districtSubValidation.sub_district);
    setFieldValue("province", districtSubValidation.province);
    setFieldValue("city", districtSubValidation.city);
    setFieldValue("postal_code", districtSubValidation.postal_code);
  }

  function actionChangeSubDistrictDuplicate(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value
    );
    setFieldValue("district_duplicate", districtSubValidation.district);
    setFieldValue("province_duplicate", districtSubValidation.province);
    setFieldValue("sub_district_duplicate", districtSubValidation.sub_district);
    setFieldValue("postal_code_duplicate", districtSubValidation.postal_code);
  }

  function onChangeCurrentHomeAddress(e) {
    setHomeAddress(e.target.value);
    if (e.target.value === "same-with-business-address") {
      setIsShowCurrentlyAddress(true);
    }
  }

  const options =
    districtShareHolder.data &&
    _.uniqBy(districtShareHolder.data, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));

  const optionsSubDistrict = _.uniqBy(subDistrict, "name").map(
    (data, index) => <Option key={data.name}>{data.name}</Option>
  );

  const FormShareHolderInformation = (
    errors,
    handleBlur,
    handleChange,
    values,
    setFieldValue,
    shareholderType
  ) => {
    return (
      <React.Fragment>
        {shareholderType === 'personal' ? (
          <Form.Item
          label={language.form.nik}
          validateStatus={errors.nik && "error"}
          help={errors.nik}
          onBlur={handleBlur}
        >
          <NumberFormat
            style={{
              width: isMobile() ? 500 : "100%",
              height: 40,
              border: "1px solid #d9d9d9",
              padding: 10,
            }}
            name="nik"
            type="tel"
            format="####-####-####-####"
            placeholder="16XX-43XX-34XX-34XX"
            onChange={handleChange}
            value={values.nik}
          />
        </Form.Item>
        ): (
          <Form.Item
          label={language.form.npwp}
          validateStatus={errors.npwp_number && "error"}
          help={errors.npwp_number}
          onBlur={handleBlur}
        >
          <NumberFormat
            style={{
              width: isMobile() ? 500 : "100%",
              height: 40,
              border: "1px solid #d9d9d9",
              padding: 10,
            }}
            name="npwp_number"
            type="tel"
            format="##.###.###.#-###.###"
            placeholder="09.2XX.2XX.3-4XX.000"
            onChange={handleChange}
            value={values.npwp_number}
          />
        </Form.Item>
        )}
        <Form.Item
          label={language.form.fullname}
          validateStatus={errors.full_name && "error"}
          help={errors.full_name}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            placeholder="Jhon Doe"
            name="full_name"
            onChange={handleChange}
            value={values.full_name}
          />
        </Form.Item>
        {shareholderType === 'personal' ? (
          <React.Fragment>
            <Form.Item
              label={language.form.pob}
              validateStatus={errors.pob && "error"}
              help={errors.pob}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                type="text"
                placeholder="Jakarta"
                size="large"
                name="pob"
                onChange={handleChange}
                value={values.pob}
              />
            </Form.Item>
            <Form.Item
              label={language.form.dob}
              validateStatus={errors.dob && "error"}
              help={errors.dob}
              onBlur={handleBlur}
            >
              <DatePicker
                name="dob"
                defaultPickerValue={
                  values.dob
                    ? moment(values.dob, "DD-MM-YYYY")
                    : moment("01-01-1990", "DD-MM-YYYY")
                }
                value={values.dob ? moment(values.dob, "DD-MM-YYYY") : ""}
                format="DD-MM-YYYY"
                onChange={(value, option) =>
                  actionChangeBirthDate(value, option, setFieldValue)
                }
                size="large"
                style={{ width: isMobile() ? 500 : "100%" }}
              />
            </Form.Item>
            <Form.Item
              label={language.form.gender}
              validateStatus={errors.gender && "error"}
              help={errors.gender}
            >
              <Radio.Group
                value={values.gender}
                size="large"
                name="gender"
                onChange={handleChange}
                buttonStyle="solid"
              >
                <Radio.Button value="male">{language.label.male}</Radio.Button>
                <Radio.Button value="female">{language.label.female}</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={language.form.maritalStatus}
              validateStatus={errors.marital_status && "error"}
              help={errors.marital_status}
            >
              <Select
                size={"large"}
                showSearch
                style={{ width: isMobile() ? 500 : "100%" }}
                value={values.marital_status}
                placeholder="KAWIN"
                onChange={(value, option) =>
                  handleChangeMaritalStatus(
                    value,
                    option,
                    setFieldValue,
                    ["individual", "industri_rumah_tangga","home_business"].includes(
                      businessType
                    )
                  )
                }
              >
                <Option value="39a35289-f562-11e9-97fa-00163e010bca">
                  {language.label.singleDivorce}
                </Option>
                <Option value="39a35288-f562-11e9-97fa-00163e010bca">
                  {language.label.married}
                </Option>
                <Option value="947c08d9-4588-4eb8-8efb-9892b0c66a7e">
                  {language.label.deathDivorce}
                </Option>
                <Option value="6d1efcbe-0c47-40a6-aa2f-9b28b5897e86">
                  {language.label.divorced}
                </Option>
              </Select>
            </Form.Item>
            </React.Fragment>
          ) : (null)
        }
        {checkBusinessTypeInformation && (
          <Form.Item
            label={language.form.kartuKeluarga}
            validateStatus={errors.kk_image && "error"}
            help={errors.kk_image}
          >
            {values.kk_image ? (
              <div className="kw-personalInfo__verifiy-ktp">
                <img
                  src={values.kk_image}
                  alt=""
                  className="kw-personalInfo__image-upload"
                />
                <div className="kw-personalInfo__verifiy-ktp__itemOne">
                  <p onClick={handleChangeShowViewKK}>View</p>
                  {pathKK}
                </div>
                <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                  <p>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ marginRight: 5 }}
                    />{" "}
                    Verified
                  </p>
                </div>
              </div>
            ) : (
              <Button
                className="kw-personalInfo__button-upload"
                onClick={handleChangeShowUploadKk}
                style={{
                  border: errors.kk_image
                    ? "2px solid red"
                    : "2px solid #2b486d",
                }}
                size="large"
              >
                TAKE A PHOTO
              </Button>
            )}
            <p>{language.loanPersonalInfo.textKartuKeluarga}</p>
          </Form.Item>
        )}
        <Form.Item
          label={language.loanFormBusinessInfo.address}
          validateStatus={errors.address && "error"}
          help={errors.address}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            placeholder="Jl xxxx Blok/ No xxxx "
            name={"address"}
            onChange={(value, option) =>
              handleChangeAddress(value, option, setFieldValue)
            }
            value={values.address}
          />
        </Form.Item>
        <Form.Item
          label={language.form.subdistrict}
          validateStatus={errors.sub_district && "error"}
          help={errors.sub_district}
          onBlur={handleBlur}
        >
          <Select
            showSearch
            value={values.sub_district}
            name={isShowCurrentlyAddress ? "sub_district" : ""}
            style={{ width: isMobile() ? 500 : "100%" }}
            placeholder="TEBET TIMUR"
            size="large"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={actionChangeSearchSubDistrict}
            onChange={(value, option) =>
              actionChangeSubDistrict(value, option, setFieldValue)
            }
          >
            {optionsSubDistrict}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.district}
          validateStatus={errors.district && "error"}
          help={errors.district}
          onBlur={handleBlur}
        >
          <Select
            showSearch
            value={values.district}
            // placeholder="Tebet"
            disabled={true}
            name={"district"}
            style={{ width: isMobile() ? 500 : "100%" }}
            size="large"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={actionChangeSearchDistrict}
            onChange={(value, option) =>
              actionChangeDistrict(value, option, setFieldValue)
            }
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.city}
          validateStatus={errors.province && "error"}
          help={errors.city}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            // placeholder={"DKI JAKARTA"}
            disabled={true}
            size="large"
            name="city"
            onChange={handleChange}
            value={values.city}
          />
        </Form.Item>
        <Form.Item
          label={language.form.province}
          validateStatus={errors.province && "error"}
          help={errors.province}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            // placeholder={"DKI JAKARTA"}
            disabled={true}
            size="large"
            name="province"
            onChange={handleChange}
            value={values.province}
          />
        </Form.Item>
        <Form.Item
          label={language.form.postalCode}
          validateStatus={errors.postal_code && "error"}
          help={errors.postal_code}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="number"
            size="large"
            placeholder="34xxx"
            name={"postal_code"}
            onChange={handleChange}
            value={values.postal_code}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isShowCurrentlyAddress}
            onChange={onChangeAddressCurrentlyLive}
          >
            {language.loanPersonalInfo.checklistCurrently}
          </Checkbox>
        </Form.Item>
        {isShowCurrentlyAddress === false && (
          <React.Fragment>
            <p>{language.loanPersonalInfo.currentHomeAddress}</p>
            <Form.Item>
              <Radio.Group
                onChange={onChangeCurrentHomeAddress}
                value={homeAddress}
              >
                <Radio
                  value="same-with-business-address"
                  name="business-address"
                >
                  {language.loanPersonalInfo.sameWithAddress}
                </Radio>
                <Radio value="different-address" name="address-different">
                  {language.loanPersonalInfo.differentAddress}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.address}
              validateStatus={errors.address_duplicate && "error"}
              help={errors.address_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                type="text"
                size="large"
                name={"address_duplicate"}
                onChange={(value, option) =>
                  handleChangeAddressDuplicate(value, option, setFieldValue)
                }
                value={values.address_duplicate}
              />
            </Form.Item>
            <Form.Item
              label={language.form.subdistrict}
              validateStatus={errors.sub_district_duplicate && "error"}
              help={errors.sub_district_duplicate}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.sub_district_duplicate}
                name={"sub_district_duplicate"}
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder="TEBET TIMUR"
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchSubDistrict}
                onChange={(value, option) =>
                  actionChangeSubDistrictDuplicate(value, option, setFieldValue)
                }
              >
                {optionsSubDistrict}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.district}
              validateStatus={errors.district_duplicate && "error"}
              help={errors.district_duplicate}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.district_duplicate}
                name={"district_duplicate"}
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                disabled={true}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchDistrict}
                onChange={(value, option) =>
                  actionChangeDistrictDuplicate(value, option, setFieldValue)
                }
              >
                {options}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.province}
              validateStatus={errors.province_duplicate && "error"}
              help={errors.province_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                // placeholder={"DKI JAKARTA"}
                disabled={true}
                size="large"
                name="province_duplicate"
                onChange={handleChange}
                value={values.province_duplicate}
              />
            </Form.Item>
            <Form.Item
              label={language.form.postalCode}
              validateStatus={errors.postal_code_duplicate && "error"}
              help={errors.postal_code_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                type="number"
                size="large"
                placeholder="34xxx"
                name={"postal_code_duplicate"}
                onChange={handleChange}
                value={values.postal_code_duplicate}
              />
            </Form.Item>
          </React.Fragment>
        )}
        {/* {["individual", "industri_rumah_tangga","home_business"].includes(businessType) && (
          <Form.Item
            label={language.form.kartuKeluarga}
            validateStatus={errors.kk_image && "error"}
            help={errors.kk_image}
          >
            {uploadKK.data ? (
              <div className="kw-personalInfo__verifiy-ktp">
                <img
                  src={values.kk_image}
                  alt=""
                  className="kw-personalInfo__image-upload"
                />
                <div className="kw-personalInfo__verifiy-ktp__itemOne">
                  <p onClick={handleChangeShowViewKK}>View</p>
                  {pathKK}
                </div>
                <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                  <p>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ marginRight: 5 }}
                    />{" "}
                    Verified
                  </p>
                </div>
              </div>
            ) : (
              <Button
                className="kw-personalInfo__button-upload"
                onClick={handleChangeShowUploadKk}
                style={{
                  border: errors.kk_image
                    ? "2px solid red"
                    : "2px solid #2b486d",
                }}
                size="large"
              >
                {language.button.takeAPhoto}
              </Button>
            )}
            <p>{language.loanPersonalInfo.textKartuKeluarga}</p>
          </Form.Item>
        )} */}
        {/* <Form.Item
          label={language.loanShareHolder.haslivedLabel}
          validateStatus={errors.has_lived_for && "error"}
          help={errors.has_lived_for}
          onBlur={handleBlur}
        >
          {" "}
          <Select
            value={values.has_lived_for}
            style={{ width: isMobile() ? 500 : "100%" }}
            size="large"
            name="has_lived_for"
            placeholder="1-3 Years"
            onChange={(value, option) =>
              actionChangeLivedFor(value, option, setFieldValue)
            }
          >
            <Option key={"5142a8b4-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor1Years}
            </Option>
            <Option key={"5142a8b5-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor13Years}
            </Option>
            <Option key={"5142a8b6-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor35Years}
            </Option>
            <Option key={"5142a8b7-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.livedFor5Years}
            </Option>
          </Select>
        </Form.Item> */}
        {/* <Form.Item
          label={language.loanShareHolder.homeStatusOwnerShip}
          validateStatus={errors.home_ownership_status && "error"}
          help={errors.home_ownership_status}
          onBlur={handleBlur}
        >
          <Select
            size={"large"}
            value={values.home_ownership_status}
            onChange={(value, option) =>
              handleChangeOwnerShip(value, option, setFieldValue)
            }
            placeholder={language.loanShareHolder.homeStatusOwner}
            style={{ width: isMobile() ? 500 : "100%" }}
          >
            <Option key={"5142a8b0-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusOwner}
            </Option>
            <Option key={"5142a8b1-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusParents}
            </Option>
            <Option key={"5142a8b2-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusRent}
            </Option>
            <Option key={"5142a8b3-e682-11e9-97fa-00163e010bca"}>
              {language.loanShareHolder.homeStatusBoardingHouse}
            </Option>
          </Select>
        </Form.Item> */}
        {/* <Form.Item
          label={"SIBLING TYPE"}
          validateStatus={errors.sibling_type && "error"}
          help={errors.sibling_type}
          onBlur={handleBlur}
        >
          <Select
            size={"large"}
            value={values.sibling_type}
            onChange={(value, option) =>
              handleChangeSiblingType(value, option, setFieldValue)
            }
            style={{ width: isMobile() ? 350 : "100%" }}
          >
            <Option key={"cabc5b34-101c-11ea-b437-00163e016d4c"}>
              Guarantor Spouse
            </Option>
            <Option key={"57fd214e-180f-11ea-a34f-00163e016d4c"}>
              Guarantor
            </Option>
            <Option key={"57fd214f-180f-11ea-a34f-00163e016d4c"}>Spouse</Option>
          </Select>
        </Form.Item> */}
        {["pt", "cv", "pt/cv"].includes(businessType) && (
          <React.Fragment>
            {" "}
            <Form.Item
              label={language.form.position}
              validateStatus={errors.jabatan && "error"}
              help={errors.jabatan}
              onBlur={handleBlur}
            >
              <Select
                size={"large"}
                value={values.jabatan || undefined}
                placeholder="Director"
                onChange={(value, option) =>
                  handleChangePosition(value, option, setFieldValue)
                }
                style={{ width: isMobile() ? 500 : "100%" }}
              >
                <Option key={"president_director"}>{language.form.pres_director}</Option>
                <Option key={"director"}>{language.form.director}</Option>
                <Option key={"president_commissioner"}>{language.form.pres_commissioner}</Option>
                <Option key={"commissioner"}>{language.form.commissioner}</Option>
                <Option key={"other"}>{language.form.otherPosition}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={language.loanShareHolder.shareHolder}
              validateStatus={errors.saham && "error"}
              help={errors.saham}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                suffix={<PercentageOutlined/>}
                type="text"
                size="large"
                name="saham"
                placeholder="54,30"
                onChange={(value, option) =>
                  actionChangeSaham(value, option, setFieldValue)
                }
                value={values.saham}
              />
            </Form.Item>
          </React.Fragment>
        )}
        <Form.Item
          label={language.loanShareHolder.relationshipToBorrower}
          validateStatus={errors.sibling_relation && "error"}
          help={errors.sibling_relation}
        >
          <Select
            size={"large"}
            value={values.sibling_relation}
            disabled={
              ["KAWIN", "MENIKAH"].includes(marital_status) &&
              ["individual", "industri_rumah_tangga","home_business"].includes(businessType)
                ? true
                : false
            }
            placeholder={language.form.parent}
            onChange={(value, option) =>
              handleChangeRelationship(value, option, setFieldValue)
            }
            style={{ width: isMobile() ? 500 : "100%" }}
          >
            <Option key={"9f015d32-de00-11ea-87d0-0242ac130003"}>
              {language.form.parent}
            </Option>
            <Option key={"9f015d32-de00-11ea-87d0-0242ac130004"}>
              {language.form.sibling}
            </Option>
            <Option key={"9f015d32-de00-11ea-87d0-0242ac130005"}>
              {language.form.spouse}
            </Option>
            <Option key={"9f015d32-de00-11ea-87d0-0242ac130006"}>
              {language.form.colleague}
            </Option>
            <Option key={"9f015d32-de00-11ea-87d0-0242ac130007"}>
              {language.form.profesional}
            </Option>
            <Option key={"9f015d32-de00-11ea-87d0-0242ac130008"}>
              {language.form.other}
            </Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  // function uuIdSiblingTypeCondition() {
  //   let sibling;
  //   if (infromationEdit.sibling_type === "guarantor") {
  //     sibling = "57fd214e-180f-11ea-a34f-00163e016d4c";
  //   } else if (infromationEdit.sibling_type === "guarantor_spouse") {
  //     sibling = "cabc5b34-101c-11ea-b437-00163e016d4c";
  //   } else if (infromationEdit.sibling_type === "spouse") {
  //     sibling = "57fd214f-180f-11ea-a34f-00163e016d4c";
  //   }
  //   return sibling;
  // }

  function uuIdMaritalStatusCondition() {
    let sibling;
    if (
      ["married", "MENIKAH"].includes(
        infromationEdit?.marital_status ||
          paramsSubmitShareHolderInformation?.marital_status
      )
    ) {
      sibling = "39a35288-f562-11e9-97fa-00163e010bca";
    } else if (
      ["single", "BELUM MENIKAH"].includes(
        infromationEdit?.marital_status ||
          paramsSubmitShareHolderInformation?.marital_status
      )
    ) {
      sibling = "39a35289-f562-11e9-97fa-00163e010bca";
    } else if (
      ["CERAI MATI"].includes(
        infromationEdit?.marital_status ||
          paramsSubmitShareHolderInformation?.marital_status
      )
    ) {
      sibling = "947c08d9-4588-4eb8-8efb-9892b0c66a7e";
    } else if (
      ["CERAI HIDUP"].includes(
        infromationEdit?.marital_status ||
          paramsSubmitShareHolderInformation?.marital_status
      )
    ) {
      sibling = "6d1efcbe-0c47-40a6-aa2f-9b28b5897e86";
    }
    return sibling;
  }

  return (
    <React.Fragment>
      <ShareHolderStyle>
        <Button
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 15,
            marginTop: 15,
          }}
          onClick={() => actinChangeShowHideAddNew()}
        >
          <ArrowLeftOutlined /> {language.button.back}
        </Button>
        <h3>{checkTranslate(`${guarantorShareHolder}_Info`, language)}</h3>
        <Formik
          initialValues={{
            nik:
              infromationEdit?.nik || paramsSubmitShareHolderInformation?.nik,
            full_name:
              infromationEdit?.full_name ||
              paramsSubmitShareHolderInformation?.full_name,
            sibling_type: ["individual","home_business", "industri_rumah_tangga"].includes(
              businessType
            )
              ? ["KAWIN", "MARRIED"].includes(marital_status)
                ? "57fd214f-180f-11ea-a34f-00163e016d4c"
                : "57fd214e-180f-11ea-a34f-00163e016d4c"
              : "57fd214f-180f-11ea-a34f-00163e016d4d",
            ktp:
              infromationEdit?.ktp || paramsSubmitShareHolderInformation?.ktp,
            kk_image: 
              infromationEdit?.properties?.family_card || paramsSubmitShareHolderInformation?.properties?.family_card,
            pob:
              infromationEdit?.pob || paramsSubmitShareHolderInformation?.pob,
            dob:
              infromationEdit?.dob || paramsSubmitShareHolderInformation?.dob,
            province:
              infromationEdit?.province ||
              paramsSubmitShareHolderInformation?.province,
            city:
              infromationEdit?.city || paramsSubmitShareHolderInformation?.city,
            district:
              infromationEdit?.district ||
              paramsSubmitShareHolderInformation?.district,
            sub_district:
              infromationEdit?.sub_district ||
              paramsSubmitShareHolderInformation?.sub_district,
            jabatan:
              infromationEdit?.properties?.jabatan ||
              paramsSubmitShareHolderInformation?.properties?.jabatan,
            saham:
              infromationEdit?.properties?.saham ||
              paramsSubmitShareHolderInformation?.properties?.saham,
            address:
              infromationEdit?.addres ||
              paramsSubmitShareHolderInformation?.address,
            email:
              infromationEdit?.email ||
              paramsSubmitShareHolderInformation?.email,
            postal_code:
              infromationEdit?.postal_code ||
              paramsSubmitShareHolderInformation?.postal_code,
            sibling_relation:
              infromationEdit?.sibling_relation ||
              paramsSubmitShareHolderInformation?.sibling_relation ||
              (["KAWIN", "MENIKAH"].includes(marital_status) &&
              ["individual", "industri_rumah_tangga","home_businsess"].includes(businessType)
                ? "9f015d32-de00-11ea-87d0-0242ac130005"
                : undefined),
            mobile_number:
              infromationEdit?.mobile_number ||
              paramsSubmitShareHolderInformation?.mobile_number,
            nationality:
              infromationEdit?.nationality ||
              paramsSubmitShareHolderInformation?.nationality,
            gender:
              infromationEdit?.gender ||
              paramsSubmitShareHolderInformation?.gender,
            home_ownership_status:
              infromationEdit?.home_ownership_status ||
              paramsSubmitShareHolderInformation?.home_ownership_status,
            marital_status: uuIdMaritalStatusCondition(),
            province_duplicate:
              infromationEdit?.properties?.province ||
              paramsSubmitShareHolderInformation?.properties?.province,
            address_duplicate:
              infromationEdit?.properties?.address ||
              paramsSubmitShareHolderInformation?.properties?.address,
            city_duplicate:
              infromationEdit?.properties?.city ||
              paramsSubmitShareHolderInformation?.properties?.city,
            district_duplicate:
              infromationEdit?.properties?.district ||
              paramsSubmitShareHolderInformation?.properties?.district,
            postal_code_duplicate:
              infromationEdit?.properties?.postal_code ||
              paramsSubmitShareHolderInformation?.properties?.postal_code,
            is_corporate: paramsSubmitShareHolderInformation?.properties?.is_corporate || false,
            npwp_number: infromationEdit?.npwp_number || 
              paramsSubmitShareHolderInformation?.properties?.npwp_number || "",
            npwp_url: infromationEdit?.npwp_url || 
              paramsSubmitShareHolderInformation?.properties?.npwp_url || "",
          }}
          validationSchema={schemaShareHolder}
          validateOnChange={false}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Item
                label={language.form.shareholderType}
                validateStatus={errors.is_corporate && "error"}
                help={errors.is_corporate}
              >
                <Radio.Group 
                  onChange={handleChangeType} 
                  value={shareholderTypeCorp}
                >
                  <Radio value="personal">Personal</Radio>
                  <Radio value="corporate">Corporate</Radio>
                </Radio.Group>
              </Form.Item>
              {/** show upload field of ktp or npwp */}
              {shareholderTypeCorp === "corporate" ? (
                <Form.Item
                  label={language.loanShareHolder.npwp}
                  validateStatus={errors.npwp_url && "error"}
                  help={errors.npwp_url}
                >
                  {infromationEdit.npwp_url ||
                  uploadShareHolder.data ||
                  values.npwp_url ? (
                    <div className="kw-personalInfo__verifiy-ktp">
                      <img
                        src={values.npwp_url}
                        alt=""
                        className="kw-personalInfo__image-upload"
                      />
                      <div className="kw-personalInfo__verifiy-ktp__itemOne">
                        <p onClick={handleChangeShowViewNpwp}>View</p>
                        {pathNpwp}
                      </div>
                      <div className="kw-personalInfo__verifiy-ktp__itemOne">
                        <p
                          onClick={() =>
                            handleChangeShowUploadNpwp()
                          }
                        >
                          Reupload
                        </p>
                      </div>
                    </div>
                  ) : (
                    <Button
                      className="kw-personalInfo__button-upload"
                      onClick={handleChangeShowUploadNpwp}
                      style={{
                        border: errors.npwp_url
                          ? "2px solid red"
                          : "2px solid #2b486d",
                      }}
                      size="large"
                    >
                      UPLOAD NPWP
                    </Button>
                  )}
                </Form.Item>
              ) : (
                <Form.Item
                  label={language.loanShareHolder.ktp}
                  validateStatus={errors.ktp && "error"}
                  help={errors.ktp}
                >
                  {infromationEdit.ktp ||
                  validationImageKtp === 3 ||
                  uploadShareHolder.data ? (
                    <div className="kw-personalInfo__verifiy-ktp">
                      <img
                        src={values.ktp}
                        alt=""
                        className="kw-personalInfo__image-upload"
                      />
                      <div className="kw-personalInfo__verifiy-ktp__itemOne">
                        <p onClick={handleChangeShowViewKtp}>View</p>
                        {pathKtp}
                      </div>
                      {isShowHideAlertUploadKtp && isShowHideAlertKtp
                        ? ""
                        : uploadKtpShareHolderError
                        ? ""
                        : infromationEdit.ktp && (
                            <div className="kw-personalInfo__verifiy-ktp__itemOne">
                              <p
                                onClick={() =>
                                  handleChangeShowUploadKtp("reUploadKtp")
                                }
                              >
                                Reupload
                              </p>
                            </div>
                          )}
                      {uploadShareHolder.data &&
                      uploadKtpShareHolderSucess.data ? (
                        <React.Fragment>
                          {isShowHideAlertUploadKtp && isShowHideAlertKtp && (
                            <React.Fragment>
                              {uploadShareHolder.data &&
                                uploadKtpShareHolderSucess.data && (
                                  <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                                    <p>
                                      <CheckCircleTwoTone
                                        twoToneColor="#52c41a"
                                        style={{ marginRight: 5 }}
                                      />{" "}
                                      Verified
                                    </p>
                                  </div>
                                )}
                              <div className="kw-personalInfo__verifiy-ktp__itemOne">
                                <p
                                  onClick={() =>
                                    handleChangeShowUploadKtp("reUploadKtp")
                                  }
                                >
                                  Reupload
                                </p>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        uploadShareHolder.data &&
                        uploadKtpShareHolderError && (
                          <div className="kw-personalInfo__verifiy-ktp__itemOne">
                            <p
                              onClick={() =>
                                handleChangeShowUploadKtp("reUploadKtp")
                              }
                            >
                              Reupload
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <Button
                      className="kw-personalInfo__button-upload"
                      onClick={handleChangeShowUploadKtp}
                      style={{
                        border: errors.ktp_image
                          ? "2px solid red"
                          : "2px solid #2b486d",
                      }}
                      size="large"
                    >
                      UPLOAD PHOTO KTP
                    </Button>
                  )}
                </Form.Item>
              )}
              {/** end of show upload field */}
              
              {FormShareHolderInformation(
                errors,
                handleBlur,
                handleChange,
                values,
                setFieldValue,
                shareholderTypeCorp
              )}
              <Form.Item
                label={addData?.type === 'normal' ?
                  language.form.businessEmailShareHolder :
                  language.form.businessEmailShareHolder + language.label.optional}
                validateStatus={errors.email && "error"}
                help={errors.email}
                onBlur={handleBlur}
              >
                <Input
                  style={{ width: isMobile() ? 500 : "100%" }}
                  type="email"
                  placeholder="john@doe.com"
                  size="large"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
              </Form.Item>
              <Form.Item
                label={language.form.mobileNumber + language.label.optional}
                validateStatus={errors.mobile_number && "error"}
                help={errors.mobile_number}
              >
                <PhoneInput
                  value={values.mobile_number}
                  prefix={""}
                  name="mobile_number"
                  country={"id"}
                  onChange={(value, option) =>
                    handleChangeMobileInput(value, option, setFieldValue)
                  }
                />
              </Form.Item>
              <Spin
                spinning={isShowLoadingShareHolder}
                style={{
                  width: /*isMobile() ? "50%" : */ "100%",
                }}
              >
                <Button
                  htmlType="submit"
                  className="kw-button"
                  style={{ width: "100%" }}
                  size="large"
                  type="primary"
                >
                  {language.button.applyLimitSubmit}
                </Button>
              </Spin>
              {action === "edit" && (
                <p
                  className="button-delete"
                  onClick={() => actionChangeShowDrawerDelete()}
                >
                  {language.button.delete}
                </p>
              )}
              <ModalViewKtp
                visible={isShowViewKtpModal}
                imageModalView={
                  (uploadShareHolder.data && uploadShareHolder.data[0]) ||
                  infromationEdit.ktp
                }
                handleCancel={handleChangeShowViewKtp}
              />
              <ModalViewMatchKtp
                visible={isShowViewMatchKtpModal}
                imageModalView={
                  (uploadSelfieShareHolder.data &&
                    uploadSelfieShareHolder.data[0]) ||
                  infromationEdit.additional_document
                }
                handleCancel={handleChangeShowViewMatchKtp}
              />
              <ModalViewNpwp
                visible={isShowViewNpwpModal}
                imageModalView={
                  (uploadShareHolder.data && uploadShareHolder.data[0]) ||
                  infromationEdit.npwp_url
                }
                handleCancel={handleChangeShowViewNpwp}
              />
              <UploadKtpModal
                visible={visibleUploadKtp}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                uploadKtpError={uploadKtpShareHolderError}
                validationImageKtp={
                  isShowvalidationImageKtpPersonal
                    ? validationImageKtpPersonal
                    : validationImageKtp
                }
                handleChangeShowUploadKtp={handleChangeShowUploadKtp}
                actionChangeKtpUpload={actionChangeKtpUpload}
              />
              <UploadSelfieKtpModal
                visible={visibleUploadSelfieKtp}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                shareHolder={true}
                businessInformation={businessInformation}
                uploadKtpError={uploadMatchKtpShareHolderError}
                // setSelfieKtp={setSelfieKtp}
                validationMatchImageKtp={
                  isShowvalidationMatchImageKtpFailed
                    ? validationMatchImageKtpFailed
                    : validationMatchImageKtp
                }
                setValidationMatchImageKtp={setValidationMatchImageKtp}
                validationMatchImageKtpFailed={validationMatchImageKtpFailed}
                //  setIsShowAlertMatchKtp={setIsShowAlertMatchKtp}
                //  setIsShowAlertUploadMatchKtp={setIsShowAlertUploadMatchKtp}
                setValidationMatchImageKtpFailed={
                  setValidationMatchImageKtpFailed
                }
                handleChangeShowUploadSelfieKtp={
                  handleChangeShowUploadSelfieKtp
                }
              />
              <UploadKkModal
                visible={visibleUploadKk}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                businessInformation={businessInformation}
                setPathKK={setPathKK}
                validationImageKk={validationImageKk}
                setValidationImageKk={setValidationImageKk}
                handleChangeShowUploadKk={handleChangeShowUploadKk}
              />
              <UploadNpwpModal
                visible={visibleUploadNpwp}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                setPathNpwp={setPathNpwp}
                uploadKtpError={uploadNpwpShareHolderError}
                handleChangeShowUploadNpwp={handleChangeShowUploadNpwp}
                actionChangeNpwpUpload={actionChangeNpwpUpload}
                businessInformation={businessInformation}
              />
            </form>
          )}
        </Formik>
      </ShareHolderStyle>
      <DrawerConfirmationDelete
        visible={isShowDrawerDeleteSucsess}
        onClose={actionChangeShowDrawerDelete}
        infromationEdit={infromationEdit}
        actionChangeDeleteList={actionChangeDeleteList}
      />
      <ModalViewKK
        visible={isShowViewKKModal}
        imageModalView={uploadKK.data && uploadKK.data[0]}
        handleCancel={handleChangeShowViewKK}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadBusinessShareHolderKoinVoice,
      actionButtonBackShareHolder,
      actionPutAppGuarantorUpdate,
      actionPutAppGuarantorCreate,
      actionGetPersonalShareHolderInfoLocal,
      actionGetPersonalShareHolderInfoLocalReset,
      getProvinceKoinvoice,
      getDistrictShareHolderKoinvoice,
      getSubDistrictShareHolderKoinvoice,
      actionUploadKtpSelfieKtp,
      getCityKoinvoice,
      businessInformationKoinvoice,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareHolderInformation);
