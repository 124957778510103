import React from "react";
import { Row, Col, Card } from "antd";
import { SubmissionTypeStyle } from "./style";
// images
import fabelio from "../../assets/images/partners/Logo_BeritaSatu_TV.png";
import gadjian from "../../assets/images/partners/kompas-logo-black-transparent.png";
import indosat from "../../assets/images/partners/marketeers.png";
import kopikenangan from "../../assets/images/partners/SWA.png";
import talenta from "../../assets/images/partners/tokoplas.png";
import { connect } from "react-redux";

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 991) return true;
  return false;
};

function SubmissionType({ init: { language } }) {
  return (
    <section id="typeproduct">
      <SubmissionTypeStyle>
        <div className="kw-submissionType-wrapper">
          <div  className="container" style={{ paddingTop: 35 }}>
            <Row style={{ marginBottom: 25 }}>
              <Col xs={24}>
                <div className="kw-submissionType-wrapper__heading-label">
                  <h2>{language.homepage.sectionTwoTittle}</h2>
                  <span>{language.homepage.sectionTwoTittleLabel}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <div className="kw-submissionType-wrapper__card-content">
                  <Card style={{ height: isMobile() ? 370 : "auto" }}>
                    <h4>{language.homepage.sectionTwoLabelOneProduct}</h4>
                    <strong>
                      {language.homepage.sectionTwoHeadingProductOne}
                    </strong>
                    <ul>
                      <li>{language.homepage.sectionTwoParagraphOne}</li>
                      <li>{language.homepage.sectionTwoParagraphTwo}</li>
                      <li>{language.homepage.sectionTwoParagraphThree}</li>
                    </ul>
                    <img
                      src={require("../../assets/images/applyloan/koinvoice/icon-supply-chain-financing.png").default}
                      alt=""
                    />
                  </Card>
                </div>
              </Col>
              <Col xs={24} md={12}>
                {" "}
                <div className="kw-submissionType-wrapper__card-content">
                  <Card>
                    <h4>{language.homepage.sectionTwoLabelTwoProduct}</h4>
                    <strong>
                      {language.homepage.sectionTwoHeadingProductTwo}
                    </strong>
                    <ul>
                      <li>{language.homepage.sectionTwoParagraphFour}</li>
                      <li>{language.homepage.sectionTwoParagraphFive}</li>
                      <li>{language.homepage.sectionTwoParagraphSix}</li>
                      <li>{language.homepage.sectionTwoParagraphSeven}</li>
                    </ul>
                    <img
                      src={require("../../assets/images/applyloan/koinvoice/icon-project-financing.png").default}
                      alt=""
                    />
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className="kw-submissionType-wrapper__card-content-paragraph">
                  <span>{language.homepage.sectionTwoAffilated}</span>
                  <div className="img-wrapper">
                    <figure className="single-partner">
                      <img src={fabelio} alt="" />
                    </figure>
                    <figure className="single-partner">
                      <img src={indosat} alt="" />
                    </figure>
                    <figure className="single-partner">
                      <img src={talenta} alt="" />
                    </figure>
                    <figure className="single-partner">
                      <img src={kopikenangan} alt="" />
                    </figure>
                    <figure className="single-partner">
                      <img src={gadjian} alt="" />
                    </figure>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </SubmissionTypeStyle>{" "}
    </section>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
});

export default connect(mapStateToProps)(SubmissionType);
