import styled from "styled-components";

export const FooterTextWrapper = styled.div`
  background: #f6f6f8;
  /* padding-top: 35px; */
  padding-bottom: 35px;
  .footer-text {
    .logo-wrapper {
      display: flex;
      .single-logo {
        display: flex;
        align-items: center;
        margin-right: 35px;
        @media (max-width: 575.98px) {
          flex-direction: column;
          align-items: start;
        }
        .text {
          margin-right: 15px;
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 28px;
          letter-spacing: -0.41px;
          color: #566068;
          @media (max-width: 575.98px) {
            margin-bottom: 5px;
          }
        }
        .logo-img {
          height: 51px;
          img {
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
    .attention-wrapper {
      .heading {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 28px;
        letter-spacing: -0.41px;
        color: #566068;
      }
      .text-list {
        .item {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 22px;
          text-align: justify;
          letter-spacing: -0.41px;
          color: #566068;
        }
      }
      .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 22px;
        text-align: justify;
        letter-spacing: -0.41px;
        color: #566068;
      }
    }
  }
`;
