import styled from "styled-components";

export const StepLoanWrapper = styled.div`
  background: #f2f2f2;

  .step-title {
    text-align: left;
    letter-spacing: 0.41px;
    color: #2b486d;
    margin-bottom: 20px;
  }
  .offer-content-wrapper {
    margin-bottom: 30px;
  }
  .fileContainer {
    box-shadow: none;
  }
  .offer-box-wrapper {
    margin-bottom: 30px;
    .offer-box,
    .nav-item {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(8, 35, 48, 0.1);
      border-radius: 5px;
      padding: 15px;
      margin: 0;
      width: 48%;
      text-decoration: none;
      @media (max-width: 575.98px) {
        width: 47.5%;
      }
      &:first-child {
        margin-right: 15px;
      }
      &.active {
        border: 1px solid #378DF4;
        background-color: #ECF5FF;
        .radio-title {
          color: #566068;
        }
        .radio-text {
          color: #566068;
        }
      }
      .MuiRadio-root {
        padding-right: 0;
        margin-top: -115px;
      }
      .radio-title {
        letter-spacing: -0.41px;
        color: #566068;
        color: #cccccc;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
      }
      .radio-text {
        letter-spacing: -0.24px;
        color: #566068;
        color: #cccccc;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
      }
    }
    .disabled {
      background: #f9f9f9;
    }
  }
  .adv-amount {
    p {
      margin-left: 0;
      span {
        margin-right: 5px;
      }
      i {
        font-size: 16px;
        color: #378df4;
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }
  .box-total {
    background: #f2f2f2;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
    .box-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      color: #8e8e8e;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .cfee {
      .text-right {
        color: #c53741;
      }
    }
    .ctotal {
      color: #566068;
    }
  }
  .line {
    margin: 30px 0;
  }
  .m-installment {
    margin-bottom: 30px;

    .title {
      font-size: 15px;
      letter-spacing: -0.24px;
      text-transform: uppercase;
      margin-bottom: 0;
      color: #8e8e8e;
    }
    .text {
      letter-spacing: 0.35px;
      font-size: 22px;
      color: #2d2d2d;
    }
  }
  .upload-info {
    span {
      margin-right: 5px;
    }
    i {
      font-size: 16px;
      color: #378df4;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .input-wrapper {
    position: relative;
  }
  .file-upload {
    display: block;
    opacity: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    width: 135px;
    height: 45px;
    input {
      width: 135px;
      height: 45px;
      top: 0;
      cursor: pointer;
    }
  }
  .img-preview-wrapper {
    display: flex;
    .img-preview {
      margin-right: 15px;
      width: 250px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .verified-file {
      color: #41af60;
      text-decoration: underline;
      align-self: center;
      /* reset */
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: default;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      &:focus {
        outline: 0;
      }
    }
    .invalid-file {
      color: #c53741;
      text-decoration: underline;
      align-self: center;
      /* reset */
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: default;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      &:focus {
        outline: 0;
      }
    }
    .img-link-wrapper {
      text-align: left;
      align-self: center;
    }
    .link-file {
      cursor: pointer;
      color: #378df4;
      text-decoration: underline;
      align-self: center;
      /* reset */
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      font-size: 17px;
      line-height: 22px;
      &:first-child {
        margin-right: 15px;
      }
      &:focus {
        outline: 0;
      }
    }
    .img-name {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #8e8e8e;
    }
  }

  .invalid-feedback {
    display: block;
    width: 100%;
    margin: 0.25rem 0 0.4rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dc3545;
    }
    label {
      color: #dc3545;
    }
  }
`;
